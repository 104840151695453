import React from 'react'

export default function GraphTab({isVob,handleGraphSwitch,stateChild}) {
  let activeClass='tw-bg-white tw-rounded-lg tw-shadow tw-text-[#0e7490]'
  return (
   <>
<span class="tw-grid tw-mr-3 tw-grid-flow-col tw-text-center tw-text-gray-500 items-center">
<span>
    <a class={`tw-flex tw-justify-center ${stateChild?.graphSwitchState=="1" ?activeClass:"tw-text-white"}  tw-py-1`} onClick={()=>handleGraphSwitch('1')}>Users/Worked Claims</a>
  </span>
  {!isVob ? <span>
    <a  class={` tw-flex  ${stateChild?.graphSwitchState=="2" ?activeClass:"tw-text-white"} tw-justify-center tw-py-1`} onClick={()=>handleGraphSwitch('2')}>Worked Amount</a>
  </span>:<span>
    <a  class={` tw-flex  ${stateChild?.graphSwitchState=="2" ?activeClass:"tw-text-white"} tw-justify-center tw-py-1`} ></a>
  </span>}
  
 
  
 
</span>



   </>
  )
}
