// import React, { useState } from "react";
// import ImageUploader from "react-images-upload";

// const ImageUploaderr = props => {


//   const onDrop = picture => {
   
//     props.handleInputChange(picture)
   
//   };
//   return (
//     <ImageUploader
//       {...props}
//       withIcon={true}
//       onChange={onDrop}
//       imgExtension={[".jpg", ".gif", ".png", ".gif",".pdf"]}
//       maxFileSize={5242880}
//     />
//   );
// };

// export default ImageUploaderr;




import React,{useMemo, useState,useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

function ImageUploaderr(props) {
 
  const {acceptedFiles, getRootProps, getInputProps, isFocused,
    isDragAccept,
    isDragReject} = useDropzone();
    const [acceptFile,setAcceptFile]=useState(acceptedFiles)

    const[toggleMsg,setToggleMsg]=useState('Drag n drop some files here, or click to select files')

    useEffect(() => {
     
      if (props.image) {
        
        if(props.image?.size){
          setToggleMsg('Drag n drop some files here, or click to select files')

        } 
        else{

               setToggleMsg('Drag n drop in order to update files here, or click to update files')
        }
      
      }
      else{
        setToggleMsg('Drag n drop some files here, or click to select files')

      }
      
    }, [props.image]);
    console.log('acceptedFiles',acceptedFiles)

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#0e7490'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      // Call the function from props
      props.handleInputChange(acceptedFiles);
    }
  }, [acceptedFiles,]);
  
 
  const files = acceptedFiles.map(file => (
    
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  let check='Drag n drop some files here, or click to select files'
 

  return (
    <section className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {props.image?.size?<p>{toggleMsg}</p>:<p>{toggleMsg}</p>}
       
      </div>
      <aside>
       
        <ul>{props.image?.size?<>{files}</>:<>{props.image} {toggleMsg!=check && <i className='fas fa-eye pl-2 ' style={{color:'#0e7490'}} onClick={()=>props.openImageViwerModel(true)}></i>}</> } </ul>
      </aside>
    </section>
  );
}

export default ImageUploaderr