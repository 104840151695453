import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "rsuite";
import { DatePicker, Stack,Radio } from "rsuite";
import { Modal, Placeholder } from "rsuite";
import UserAuditTable from "../Components/Audit/userTaskViewTable";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { IconButton, } from 'rsuite';
import {Panel} from "rsuite"

import { toast } from "react-toastify";
import { parse } from "browserslist";
import Select from "react-select";
import {
  Message,
  useToaster,
  ButtonToolbar,
  SelectPicker,
  Button,
} from "rsuite";
import DateoofserviceBF from "../Components/Operation/AllForms/BFClaims";
import Sweetalertdemo from "./NotifySwal";

export default function DateoofserviceGlobal({
  helpingState,
  onChangeDemo,
  handleKeyPress,
  totalWorkedAmount,
  onCleanDateCheckDate,
  onChangeDateCheck,
  recentChange,
  onHandlePartiallyPaidClaims,
  isAllowOpeneinalbreakup,
  realFlag,
  handleOnChangeeobRef,
  stateAmount,
  handleChangeAmount,
  partially,
  handleChangePartially,
  flagFooter,
  Footer,
  sid,
  spayer,
  setMinimize,
  minimize,
  eobRef,

  saveList,
  spayerName,
  spayerid,
  onChangeName,
  resultPayer,
  handlePracticeVal,
  products,
  bfVal,
  paymentstate,
  payments,

  claimHeading4,
  onChangeInternalNonVerify,
  internalNonVerify,

  claimHeading2,
  claimHeading3,
  datePickerVal,
  gridEdit,
  setGridEdit,

  setpendingAmountDos,
  pendingAmountDos,
  AmountDosRec,

  pendingAmount,
  handleInputdosCC,

  changeState,
  flag,
  dosArrayPaid,
  deductArray,

  showTableCheck,
  onlyShowTable,
  AllBreakupVal,
  pendingClaimss,
  setPendingClaimm,
  partiallyPaidClaims,
  setdosDate,
  onChangeBreakupClaim,

  handledosDate,
  recievedClaimArraydos,
  dosPendingBreakup,
  handleInputdos,
  AmountDos,
  allowPending,
  practiceId,
  setDosArray,
  errorModel,
  renderr,
  showPortal,
  showCalls,
  showAmount,
  onChangeAmount,
  onChangeInputCC,
  onChangeAmountCC,
  handleChange,
  handleEditState,
  onSaveDos,
  dosArray,
  onAdddos,
  onChangeInput,
  onChangeDate,
  onCleanDate,
  onChangeCalendarDate,
  handleClose,
  onChangePortals,
  onChangeCalls,
  claimHeading,
  claimHeading1,
  claimAmount1,
  claimAmount,
  renderEffect,
  isPendingSectionOpen,
  setisPendingSectionOpen,
  handleError,
}) {
  const [ActiveBanner, setActivateBanner] = useState(false);
  const [type, setType] = React.useState("info");
  let objSwal={
    isShow:false,
    caseType:null
  }
  const [placement, setPlacement] = React.useState(objSwal);

  const toaster = useToaster();

  const message = (
    <>
      {
        <Message showIcon type={type} closable>
          {`Warning`}: Your Have Some Previous b/f Claims Remaining.
        </Message>
      }
    </>
  );




  useEffect(() => {
    if (parseFloat(bfVal) > 0) {
      setActivateBanner(true);
    }
  }, [bfVal]);

  console.log("hey there", isPendingSectionOpen);
  let onChangeValues;
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  useEffect(() => {
    if (allowPending) {
      if (dosPendingBreakup) {
        let { isAllow, onChangeValues } = dosPendingBreakup;
        onChangeValues = onChangeValues;

        let check = isAllow && handleInputdos != null && AmountDos != null;

        if (check) {
          setisPendingSectionOpen(true);
        }
      }
      return;
    }
  }, [handleInputdos, AmountDos, handleInputdos]);

  useEffect(() => {
    if (gridEdit.isEdit) {
      handleDelete(gridEdit.rowDataa);
      //  setGridEdit((prev)=>({...prev,isEdit:false}))
    }
  }, [gridEdit.isEdit, gridEdit.isBfEdit]);
  console.log("gridEdit.isBfEdit", gridEdit.isBfEdit);

  useEffect(() => {
    if (allowPending) {
      // if (AmountDos == "ok") {
      //   if (
      //     handledosDate &&
      //     handleInputdos &&
      //     recievedClaimArraydos.length > 0
      //   ) {
      //     let pending;
      //     let matchedObj1 = deductArray.find(
      //       (item) => item.date === handledosDate
      //     );
      //     let matchedObj = recievedClaimArraydos.find(
      //       (item) => item.date === handledosDate
      //     );

      //     if (matchedObj && matchedObj1) {
      //       let value = matchedObj.recievedAmount;
      //       let value1 = matchedObj1.recievedAmount;

      //       let wholeVal = parseFloat(value);
      //       let additionVal = parseFloat(handleInputdos) + parseFloat(value1);

      //       pending = wholeVal -= additionVal;
      //       setPendingClaimm(pending);
      //     } else {
      //       toast.info("Please select correct date of service");
      //       setdosDate(null);
      //       return;
      //     }
      //   }
      //   return;
      // }
      if (flag == "vobb") {
        if (handleInputdosCC || AmountDos || AmountDosRec) {
          let pending;

          let value = parseFloat(AmountDosRec || 0);
          let worked =
            parseFloat(handleInputdosCC || 0) + parseFloat(AmountDos || 0);
          onChangeAmount(worked);
          setpendingAmountDos(worked);

          pending = value -= parseFloat(worked);
          setPendingClaimm(pending);
        }

        return;
      }
      
      if(flagFooter=='generalPayer'){
      
        if (handleInputdos ||partiallyPaidClaims) {
          let pending;
  
          let value = parseFloat(handleInputdosCC);
          let val=parseFloat(partiallyPaidClaims)+parseFloat(handleInputdos)
          
  
          pending = value -= val;
          setPendingClaimm(pending);
        }

        return
      }

      if (flag == "Eob") {
        if (
          handleInputdos ||
          AmountDosRec ||
          handleInputdosCC ||
          partially?.partially
        ) {
          let pending;

          let value = parseFloat(handleInputdosCC || 0);
          let worked =
            parseFloat(handleInputdos || 0) +
            parseFloat(AmountDosRec || 0) +
            parseFloat(partially?.partially || 0);
          setpendingAmountDos(worked);

          pending = value -= parseFloat(worked);
          setPendingClaimm(pending);
        }

        return;
      }
     

      if (handleInputdos) {
        let pending;

        let value = parseFloat(handleInputdosCC);
        

        pending = value -= parseFloat(handleInputdos);
        setPendingClaimm(pending);
      }
      if (AmountDos && AmountDosRec) {
      
       
        let pending;

        let value = parseFloat(AmountDosRec);

        pending = value -= parseFloat(AmountDos);
        setpendingAmountDos(pending?.toFixed(3));
      }
    }
  }, [
    handledosDate,
    handleInputdos,
    AmountDos,
    AmountDosRec,
    handleInputdosCC,
    partially?.partially,
    partiallyPaidClaims
  ]);


  const openInfoSwal=(val)=>{
    setPlacement((prev)=>({...prev,isShow:true,caseType:val}))
   

  }
  const handleCloseSwal=(val)=>{
    
    setPlacement((prev)=>({...prev,isShow:false,caseType:null}))

  }

  const handleDelete = (rowData) => {
  
    let arr =
    dosArray &&  dosArray.length > 0 && dosArray.filter((item) => item.id !== rowData?.id);
    const { flag, state } = setDosArray;
    if (flag == "1") {
      state(arr);
      return;
    }
    if (flag == "2") {
      state((prev) => ({
        ...prev,
        dosArray: arr,
      }));
      return;
    }
    if (flag == "3") {
      return;
    }
  };
  useEffect(() => {
    if (practiceId == null) {
      toast.info("Please Select Practice ");
    }

    const timeoutId = setTimeout(() => {
      renderr(!renderEffect);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  console.log("gridEdit.isEdit", gridEdit.isEdit);

  const arr = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading1}`, //received claims
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },

    {
      colName: `${claimAmount1} ($)`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `${claimHeading}`, //worked claims
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },

    {
      colName: `${claimAmount} ($)`, //worked Amount
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },

    // {
    //   colName: "Action",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
    // {
    //   colName: "Delete",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
  ];
  const arrVob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    // {
    //   colName: `${claimHeading1}`, //received claims
    //   flexGrow: 1.9,
    //   dataKey: "recievedAmount",
    //   rowSpan: 1,
    // },
    {
      colName: `${claimHeading}`, //recei
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },

    {
      colName: `Through Portal`, //portal
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `Through Calls`, //call
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },

    // {
    //   colName: "Action",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
    // {
    //   colName: "Delete",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
  ];

  if (flag != "vobb") {
    arr.push(
      {
        colName: `Pending Claims`,
        flexGrow: 1.9,
        dataKey: "total",
        rowSpan: 1,
      },
      {
        colName: `Pending Amount`,
        flexGrow: 1.9,
        dataKey: "pendingAmount",
        rowSpan: 1,
      },

      {
        colName: "Action",
        flexGrow: 1.6,
        dataKey: "id",
        rowSpan: 1,
        actions: [
          gridEdit.isEdit
            ? {}
            : { label: "E", onClick: handleEditState, btnType: "Edit" },
          { label: "D", onClick: handleDelete, btnType: "Delete" },
        ],
        editRow: true,
      }
    );
  }
  if (flag == "vobb") {
    arrVob.push(
      {
        colName: `Pending`,
        flexGrow: 1.9,
        dataKey: "pendingClaim",
        rowSpan: 1,
      },
      {
        colName: `Total`, //received claims
        flexGrow: 1.9,
        dataKey: "totalClaimWorked",
        rowSpan: 1,
      },
      {
        colName: "Action",
        flexGrow: 1.6,
        dataKey: "id",
        rowSpan: 1,
        actions: [
          gridEdit.isEdit
            ? {}
            : { label: "E", onClick: handleEditState, btnType: "Edit" },
          { label: "D", onClick: handleDelete, btnType: "Delete" },
        ],
        editRow: true,
      }
    );
  }
  const vobsArr = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Claims Received`,
      flexGrow: 2.5,
      dataKey: "portals",
      rowSpan: 1,
    },
    {
      colName: "Portalss",
      flexGrow: 1.7,
      dataKey: "portals",
      rowSpan: 1,
    },
    {
      colName: "Calls",
      flexGrow: 1.6,
      dataKey: "calls",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];
  const Eob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Check Date`,
      flexGrow: 1.7,
      dataKey: "checkDate",
      rowSpan: 1,
    },
   
    {
      colName: `RefNo/CheckNo`,
      flexGrow: 1.7,
      dataKey: "eobRef",
      rowSpan: 1,
    },

    {
      colName: `Recieved Claims`,
      flexGrow: 1.7,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    
    {
      colName: `Paid Claims`,
      flexGrow: 1.6,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },

    {
      colName: `Denied Claims`,
      flexGrow: 2.5,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Partially Paid Claims`,
      flexGrow: 2.5,
      dataKey: "partially",
      rowSpan: 1,
    },
    {
      colName: `Total Worked Claims`,
      flexGrow: 2.5,
      dataKey: "totalClaimWorked",
      rowSpan: 1,
    },
    {
      colName: `Total Worked Amount`,
      flexGrow: 1.7,
      dataKey: "totalWorkedAmount",
      rowSpan: 1,
    },

    {
      colName: `Total Payments ($)`,
      flexGrow: 2.5,
      dataKey: "paymentsTotal",
      rowSpan: 1,
    },

    {
      colName: `Partially Total Payments ($)`,
      flexGrow: 2.5,
      dataKey: "paymentsTotalPar",
      rowSpan: 1,
    },
    // {
    //   colName: `Pending Claims Amount`,
    //   flexGrow: 2.5,
    //   dataKey: "pendinGen",
    //   rowSpan: 1,
    // },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        gridEdit.isEdit
          ? {}
          : { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];
  const Payer = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Payer Type`,
      flexGrow: 2.5,
      dataKey: "PayerType",
      rowSpan: 1,
    },
    {
      colName: `Payers Id`,
      flexGrow: 1.6,
      dataKey: "payerId",
      rowSpan: 1,
    },
    {
      colName: `Payers Name`,
      flexGrow: 1.6,
      dataKey: "payerlabel",
      rowSpan: 1,
    },

    {
      colName: `${claimHeading1}`,
      flexGrow: 1.7,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Received Claims Amount ($)`,
      flexGrow: 2.5,
      dataKey: "recievedAmountgGen",
      rowSpan: 1,
    },

    {
      colName: `Worked Claims`,
      flexGrow: 1.6,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Collected Claims Amount ($)`,
      flexGrow: 2.5,
      dataKey: "collectedAmountgGen",
      rowSpan: 1,
    },
    {
      colName: `Partially Paid Claims`,
      flexGrow: 1.6,
      dataKey: "partiallyPaid",
      rowSpan: 1,
    },

    {
      colName: `Pending Claims`,
      flexGrow: 2.5,
      dataKey: "total",
      rowSpan: 1,
    },
   
    
    {
      colName: `Pending Claims Amount ($)`,
      flexGrow: 2.5,
      dataKey: "pendinGen",
      rowSpan: 1,
    },

    // {
    //   colName: `Worked Breakup Total`,
    //   flexGrow: 2.5,
    //   dataKey: "total",
    //   rowSpan: 1,
    // },
    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        gridEdit.isEdit
          ? {}
          : { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },

    // {
    //   colName: "Action",
    //   flexGrow: 1.6,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   actions: [
    //     gridEdit.isEdit?{}: { label: "E", onClick: handleEditState, btnType: "Edit" },
    //     { label: "D", onClick: handleDelete, btnType: "Delete" },
    //   ],
    //   editRow: true,
    // },
  ];

  const PendingClaimBreakupgrid = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Credientialing",
      flexGrow: 1.9,
      dataKey: "credientalingVal",
      rowSpan: 1,
    },
    { colName: "Edi ", flexGrow: 1.9, dataKey: "EdiVal", rowSpan: 1 },
    {
      colName: "Operation",
      flexGrow: 1.9,
      dataKey: "operationVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const PendingClaimBreakupgridForVob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Pending", flexGrow: 1.9, dataKey: "Pending", rowSpan: 1 },
  ];
  const PaidClaimBreakupgridForEob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    {
      colName: "Claims Paid",
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
  ];
  const PendingClaimBreakupgridForEob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Crediential",
      flexGrow: 1.9,
      dataKey: "credientialVal",
      rowSpan: 1,
    },
    { colName: "Patient End ", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    {
      colName: "Billing",
      flexGrow: 1.9,
      dataKey: "billingVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const PendingClaimBreakupgridForPatStat = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: "Non Verified",
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },

    { colName: "Patient End", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    {
      colName: "Operation End",
      flexGrow: 1.9,
      dataKey: "operationVal",
      rowSpan: 1,
    },
    {
      colName: "Payer End",
      flexGrow: 1.9,
      dataKey: "payerVal",
      rowSpan: 1,
    },
    { colName: "Other ", flexGrow: 1.9, dataKey: "otherVal", rowSpan: 1 },

    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const PendingClaimBreakupgridForGeneralPayer = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Crediential",
      flexGrow: 1.9,
      dataKey: "credientialVal",
      rowSpan: 1,
    },
    { colName: "Patient End ", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    {
      colName: "Billing",
      flexGrow: 1.9,
      dataKey: "billingVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const vobsArr1 = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    {
      colName: `Received`, //Pat Statement
      flexGrow: 2.5,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },

    {
      colName: `${claimHeading1}`, //Verifeid
      flexGrow: 2.5,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    // {
    //   colName: `Adjusted`, //Ajusted
    //   flexGrow: 2.5,
    //   dataKey: "Adjusted",
    //   rowSpan: 1,
    // },
    {
      colName: `${claimAmount1}`, //Rejected
      flexGrow: 2.5,
      dataKey: "internalNonVerify",
      rowSpan: 1,
    },
    {
      colName: `Pending`, //Rejected
      flexGrow: 2.5,
      dataKey: "pendingStat",
      rowSpan: 1,
    },
    {
      colName: `Received Amount ($)`, //Received Amount
      flexGrow: 2.5,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `Worked Amount ($)`,
      flexGrow: 2.5,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `Pending Amount ($)`,
      flexGrow: 2.5,
      dataKey: "pendingAmount",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        gridEdit.isEdit
          ? {}
          : { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];
  const generalpayer = [
    { colName: "DOS", flexGrow: 2.5, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.8,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Claims Paid`,
      flexGrow: 2.5,
      dataKey: "gcp",
      rowSpan: 1,
    },
    {
      colName: `ClaimsAdjusted`,
      flexGrow: 2.9,
      dataKey: "gca",
      rowSpan: 1,
    },
    {
      colName: `Resubmission`,
      flexGrow: 2.5,
      dataKey: "gcr",
      rowSpan: 1,
    },
    {
      colName: `Provider Sent`,
      flexGrow: 2.5,
      dataKey: "gct",
      rowSpan: 1,
    },
    {
      colName: `Appeals Sent`,
      flexGrow: 2.5,
      dataKey: "gcs",
      rowSpan: 1,
    },
    // {
    //   colName: `Total`,
    //   flexGrow: 1.9,
    //   dataKey: "total",
    //   rowSpan: 1,
    // },
    {
      colName: `Pending`,
      flexGrow: 1.9,
      dataKey: "Pending",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 2.5,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];

  const specialpayer = [
    { colName: "DOS", flexGrow: 2.5, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.8,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Claims Paid`,
      flexGrow: 2.5,
      dataKey: "scp",
      rowSpan: 1,
    },
    {
      colName: `ClaimsAdjusted`,
      flexGrow: 2.9,
      dataKey: "sca",
      rowSpan: 1,
    },
    {
      colName: `Resubmission`,
      flexGrow: 2.5,
      dataKey: "scr",
      rowSpan: 1,
    },
    {
      colName: `Provider Sent`,
      flexGrow: 2.5,
      dataKey: "sct",
      rowSpan: 1,
    },
    {
      colName: `Appeals Sent`,
      flexGrow: 2.5,
      dataKey: "scs",
      rowSpan: 1,
    },
    // {
    //   colName: `Total`,
    //   flexGrow: 1.9,
    //   dataKey: "total",
    //   rowSpan: 1,
    // },
    {
      colName: `Pending`,
      flexGrow: 1.9,
      dataKey: "Pending",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 2.5,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];

  let parsedArray = [];
  try {
    parsedArray = JSON.parse(dosArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = dosArray;
  }

  console.log(dosArray, "dosArray");
  let pendingSectionhtml = (
    <>
     <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >
      {/* <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Pending Claims Breakup
        </h4>
      </div> */}
      <div className="row mt-4">
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            value={AllBreakupVal?.providerVal}
            onKeyPress={handleKeyPress}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            value={AllBreakupVal?.codingVal}
            onChange={onChangeBreakupClaim}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
      {recentChange=='Auth'?null: <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientalingVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            value={AllBreakupVal?.credientalingVal}
            onKeyPress={handleKeyPress}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>}
      {recentChange=='Auth'?null:<div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            EDI
          </label>

          <input
            class="form-control inputField"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="EdiVal"
            placeholder=""
            value={AllBreakupVal?.EdiVal}
            onKeyPress={handleKeyPress}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>}
    
     
      
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Operation
          </label>

          <input
            class="form-control inputField"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="operationVal"
            placeholder=""
            value={AllBreakupVal?.operationVal}
            onKeyPress={handleKeyPress}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      </div>
      
     
      </Panel>
      
    </>
  );

  let pendingSectionPatStat = (
    <>
      {/* <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Rejected Breakup
        </h4>
      </div> */}
       <Panel defaultExpanded={true} header="Rejected Breakup" collapsible={true} >
        <div className="row mt-4">
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider End
          </label>

          <input
          onWheel={(e) => e.target.blur()}
          
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient End
          </label>

          <input
          onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="patVal"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.patVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Operation End
          </label>

          <input
          onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="operationVal"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Payer`s End
          </label>

          <input
          onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="payerVal"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.payerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Other
          </label>

          <input
          onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="otherVal"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.otherVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      </div>
      </Panel>
    </>
  );
  let pendingSectionhtmlEob = (
    <>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Denied Claims Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            //  value={providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            // value={codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientialVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            //   value={credientialVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="patVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            //   value={ediVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Billing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="billingVal"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            //   value={operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );

  let pendingSectionhtmlGenralPayer = (
    <>
     <Panel defaultExpanded={true} header="Worked Claims/Amount Breakup" collapsible={true} >
      <div className="row mt-4">
      {/* <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Worked Claims/Amount Breakup
        </h4>
      </div> */}
      <div className="col-sm-3">
        <div className="ebos">
          {" "}
          <div class="form-group mb-2 ">
            <label class="inputLabel" style={{ color: "black" }}>
              No of Claims Paid{" "}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              class="form-control inputField"
              type="text"
              name={changeState == "Sp" && flag == "payer" ? "scp" : "gcp"}
              placeholder=""
              onKeyPress={handleKeyPress}
              value={
                changeState == "Sp" ? AllBreakupVal?.scp : AllBreakupVal?.gcp
              }
              onChange={onChangeBreakupClaim}
            />
          </div>
          <div class="form-group ">
            <label class="inputLabel" style={{ color: "black" }}>
              No of Claims Paid Amount{" "}
            </label>
            <div  class="form-group input-icon">
            <input
             onWheel={(e) => e.target.blur()}
              class="form-control inputField"
              type="text"
              name={changeState == "Sp" && flag == "payer" ? "scpa" : "gcpa"}
              placeholder=""
              onKeyPress={handleKeyPress}
              value={
                changeState == "Sp" ? AllBreakupVal?.scpa : AllBreakupVal?.gcpa
              }
              onChange={onChangeBreakupClaim}
            />
             <i className="lightGreen">$</i>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="col-sm-6 col-lg-1">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            No. of Claims Paid
          </label>

          <input
            class="form-control inputField"
            type="text"
            name={changeState == "Sp" && flag == "payer" ? "scp" : "gcp"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scp : AllBreakupVal?.gcp
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-2">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            No. of Claims Paid Amount
          </label>

          <input
            class="form-control inputField"
            type="text"
            name={changeState == "Sp" && flag == "payer" ? "scp" : "gcp"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scp : AllBreakupVal?.gcp
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
      <div className="col-sm-3"><div className="ebos"><div className="form-group mb-2 ">
      <label class="inputLabel" style={{ color: "black" }}>
      No of Claims Adjusted{" "}
                  </label>
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            onKeyPress={handleKeyPress}
            name={changeState == "Sp" && flag == "payer" ? "sca" : "gca"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.sca : AllBreakupVal?.gca
            }
            onChange={onChangeBreakupClaim}
          />
         
        
        </div>
        <div class="form-group">
           <label class="inputLabel" style={{ color: "black" }}>
     No of Claims Adjusted Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            onKeyPress={handleKeyPress}
            name={changeState == "Sp" && flag == "payer" ? "scaa" : "gcaa"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scaa : AllBreakupVal?.gcaa
            }
            onChange={onChangeBreakupClaim}
          />
          <i className="lightGreen">$</i>
          </div>

           </div>
           
        </div></div>


        <div className="col-sm-3">
          <div className="ebos">
          <div class="form-group mb-2 ">
          <label class="inputLabel" style={{ color: "black" }}>
          Sent for Resubmission{" "}
                  </label>
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            onKeyPress={handleKeyPress}
            name={changeState == "Sp" && flag == "payer" ? "scr" : "gcr"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scr : AllBreakupVal?.gcr
            }
            onChange={onChangeBreakupClaim}
          />
            </div>
            <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            onKeyPress={handleKeyPress}
            name={changeState == "Sp" && flag == "payer" ? "scra" : "gcra"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scra : AllBreakupVal?.gcra
            }
            onChange={onChangeBreakupClaim}
          />
           <i className="lightGreen">$</i>
          </div>
                  </div>
          </div>
        </div>
  
      
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Sent for Resubmission
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "scr" : "gcr"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scr : AllBreakupVal?.gcr
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}


      <div className="col-sm-3"><div className="ebos"><div className="form-group mb-2">
      <label class="inputLabel" style={{ color: "black" }}>
      Sent to Provider{" "}
                  </label>
                  
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "sct" : "gct"}
            placeholder=""
            onKeyPress={handleKeyPress}
            value={
              changeState == "Sp" ? AllBreakupVal?.sct : AllBreakupVal?.gct
            }
            onChange={onChangeBreakupClaim}
          />
          
        </div>
        <div className="form-group">
        <label class="inputLabel" style={{ color: "black" }}>
        Sent to Provider Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "scta" : "gcta"}
            placeholder=""
            onKeyPress={handleKeyPress}
            value={
              changeState == "Sp" ? AllBreakupVal?.scta : AllBreakupVal?.gcta
            }
            onChange={onChangeBreakupClaim}
          />
           <i className="lightGreen">$</i>
          </div>
        </div>
        </div></div>
     
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Sent to Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "sct" : "gct"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.sct : AllBreakupVal?.gct
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
      <div className="col-sm-3"><div className="ebos">
        <div className="form-group mb-2">
      <label class="inputLabel" style={{ color: "black" }}>
      No. of Apeals Sent{" "}
                  </label>
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            onKeyPress={handleKeyPress}
            name={changeState == "Sp" && flag == "payer" ? "scs" : "gcs"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scs : AllBreakupVal?.gcs
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
        <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "scsa" : "gcsa"}
            placeholder=""
            onKeyPress={handleKeyPress}
            value={
              changeState == "Sp" ? AllBreakupVal?.scsa : AllBreakupVal?.gcsa
            }
            onChange={onChangeBreakupClaim}
          />
           <i className="lightGreen">$</i>
          </div>
                  
                  </div>

        </div></div>
      {/* <div className="col-sm-3"><div className="ebos"><div className="form-group mb-2">
      <label class="inputLabel" style={{ color: "black" }}>
      No. of Apeals Sent{" "}
                  </label>
                  <input
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "scs" : "gcs"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scs : AllBreakupVal?.gcs
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
        <div className="form-group">
        <label class="inputLabel" style={{ color: "black" }}>
                    Claims Denied{" "}
                  </label>
        </div>
        </div>
        </div> */}
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            No. of Apeals Sent
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={changeState == "Sp" && flag == "payer" ? "scs" : "gcs"}
            placeholder=""
            value={
              changeState == "Sp" ? AllBreakupVal?.scs : AllBreakupVal?.gcs
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}

<div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Total Claims{" "}
                  </label>
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={
              changeState == "Sp" ? AllBreakupVal?.total : AllBreakupVal?.total
            }
            onChange={onChangeBreakupClaim}
          />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                   Total Worked  Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
            class="form-control inputField"
            type="number"
            name="totala"
            placeholder=""
            disabled
            value={
              changeState == "Sp" ? AllBreakupVal?.totala : AllBreakupVal?.totala
            }
            onChange={onChangeBreakupClaim}
          />
          <i className="lightGreen">$</i>


</div>
                  </div>

                  </div>
                  </div>
                  </div>
                  </Panel>
                  {/* <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Partially Paid Claims Breakup
        </h4>
      </div> */}
      <div className="mt-4"></div>
       <Panel defaultExpanded={true} header="Partially Paid Claims Breakup" collapsible={true} >
        <div className="row mt-4">
      {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"No of Partially Paid Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              onKeyPress={handleKeyPress}
              name={changeState == "Sp" && flag == "payer" ? "sppc" : "gppc"}
              placeholder=""
              value={changeState == "Sp" ? AllBreakupVal?.sppc : AllBreakupVal?.gppc}
              onChange={onChangeBreakupClaim}
             
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}

{showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"No of Partially Denied Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name={changeState == "Sp" && flag == "payer" ? "sppc" : "gpdA"}
              placeholder=""
              onKeyPress={handleKeyPress}
              value={changeState == "Sp" ? AllBreakupVal?.sppc : AllBreakupVal?.gpdA}
              onChange={onChangeBreakupClaim}
             
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}
        </div>
        </Panel>




      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={
              changeState == "Sp" ? AllBreakupVal?.total : AllBreakupVal?.total
            }
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
    </>
  );
  let pendingSectionvob = (
    <>
    
      <div className="row mt-4">
      {/* <div className="col-lg-12 mb-2 mt-3 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Pending Claims Breakup
        </h4>
      </div> */}
      
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            min="0"
            onKeyPress={handleKeyPress}
            value={AllBreakupVal?.providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            value={AllBreakupVal?.codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientalingVal"
            placeholder=""
            value={AllBreakupVal?.credientalingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            EDI
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="EdiVal"
            placeholder=""
            value={AllBreakupVal?.EdiVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div> */}
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Operation
          </label>

          <input
            class="form-control inputField"
            type="number"
            onWheel={(e) => e.target.blur()}
            
            
            

            min="0"
            step="1"
            name="operationVal"
            onKeyPress={handleKeyPress}
            placeholder=""
            value={AllBreakupVal?.operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
          
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      </div>
      
    </>
  );

  console.log(
    "isPendingSectionOpen ",
    isPendingSectionOpen,
    "AmountDos",
    AmountDos,
    "flag",
    flag
  );

  let case1 = (
    <>
      <div className="row mt-3">
        {/* ****************DOS******************* */}
        <Panel defaultExpanded={true} header="Dos Wise Fields" collapsible={true} >
          <div className="row mt-4">
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br />

          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            oneTap
            value={datePickerVal}
            defaultValue={null}
          />
          <br></br>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        {/* ****************rECEVIED PAT STATA************** */}
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading} <span style={{ color: "red" }}>*</span>:
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            onKeyPress={handleKeyPress}
            value={handleInputdosCC}
            onChange={onChangeInputCC}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmountCC == true
              ? `${claimHeading} is Required`
              : null}
          </label>
        </div>
        {/* **********************verfeid************** */}

        <div className="col-1">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading1} <span style={{ color: "red" }}>*</span>:
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            value={handleInputdos}
            onChange={onChangeInput}
            onKeyPress={handleKeyPress}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? `${claimHeading1} is Required`
              : null}
          </label>
        </div>
        {/* *******************non verified************** */}

        <div className="col-1">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimAmount1} <span style={{ color: "red" }}>*</span>:
          </label>
          <input
           onWheel={(e) => e.target.blur()}
            className="form-control inputField"
            type="number"
            name="internalNonVerify"
            placeholder=""
            value={internalNonVerify}
            onKeyPress={handleKeyPress}
            onChange={onChangeInternalNonVerify}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.internalNonVerify == true
              ? `${claimAmount1} is Required`
              : null}
          </label>
        </div>
        {/* <div className="col-1">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Adjustments :
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="paymentstate"
            placeholder=""
             value={paymentstate}
            onChange={payments}
          />
          
        </div> */}

        {/* ******************Pendign************** */}
        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={pendingClaimss}
              disabled
            />
          </div>
        ) : null}

        {/* ****************Rec amount ************** */}

        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading2} <span style={{ color: "red" }}>*</span>:
          </label>
          <div  class="form-group input-icon">
          <input
           onWheel={(e) => e.target.blur()}
            className="form-control inputField"
            type="number"
            name="AmountDosRec"
            placeholder=""
            value={AmountDosRec}
            onKeyPress={handleKeyPress}
            onChange={onChangeAmountCC}
          />
           <i className="lightGreen">$</i>

          </div>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.AmountDosVal == true
              ? `${claimAmount} is Required`
              : null}
          </label>
        </div>
        {/* ********************Collected Amoount************** */}
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading3} <span style={{ color: "red" }}>*</span>:
          </label>
          <div  class="form-group input-icon">
          <input
           onWheel={(e) => e.target.blur()}
            className="form-control inputField"
            type="number"
            name="AmountDos"
            placeholder=""
            value={AmountDos}
            onKeyPress={handleKeyPress}
            onChange={onChangeAmount}
          />
           <i className="lightGreen">$</i>

          </div>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.AmountDosValCC == true
              ? `${claimHeading3} is Required`
              : null}
          </label>
        </div>

        {/* **********************Pending AMount************** */}
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading4} <span style={{ color: "red" }}>*</span>:
          </label>
          <div  class="form-group input-icon">
          <input
            disabled
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={pendingAmountDos}
          />
           <i className="lightGreen">$</i>
          </div>
        </div>
        </div>
        </Panel>
        <div className="mt-4"></div>

        {isPendingSectionOpen && AmountDos == "ok" && flag != "patStat"
          ? pendingSectionhtmlEob
          : isPendingSectionOpen && flag == "patStat"
          ? pendingSectionPatStat
          : isPendingSectionOpen && AmountDos != "ok" && flag == "generalPayer"
          ? pendingSectionhtmlGenralPayer
          : isPendingSectionOpen && AmountDos != "ok"
          ? pendingSectionhtml
          : null}
<div className="row">
<div className="col-3">
          <label className="inputLabel pb-3"></label>
          <br />
          <Button
            onClick={
              gridEdit.isBfEdit == true ? () => onAdddos("bfAdd") : onAdddos
            }
            style={{ backgroundColor: "#0e7490", color: "white" }}
          >
            {gridEdit.isEdit || gridEdit.isBfEdit ? "Update" : "Add"}
          </Button>
           <span className="ml-2 mt-2"><InfoOutlineIcon onClick={()=>openInfoSwal('PatStat')} style={{fill:'#0e7490'}} /></span>
          
        </div>
         
</div>
       
      </div>
    </>
  );

  //For Charge,rejection,Authorization

  let case2 = (
    <>
     <Panel defaultExpanded={true} header="Dos Wise Fields" collapsible={true} >
      <div className="row mt-4">
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            //  value={handledosDate}
            oneTap
            value={datePickerVal}
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        {/* *********************Bf Claims ****************** */}
        {/* <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
           B/F Claims<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={bfVal}
            onChange={onChangeInputCC}
            required
            disabled
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? "Received Claims is Required"
              : null}
          </label>
        </div> */}
        {/* ******************Received Amount***************** */}
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading1} <span style={{ color: "red" }}>*</span>
          </label>
         
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            onWheel={(e) => e.target.blur()}
            value={handleInputdosCC}
            onChange={onChangeInputCC}
            onKeyPress={handleKeyPress}
            required
            disabled={gridEdit.isBfEdit == true ? true : false}
          />
          
          
          
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? "Received Claims is Required"
              : null}
          </label>
        </div>

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount1} <span style={{ color: "red" }}>*</span>
            </label>
            <div  class="form-group input-icon">
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={AmountDosRec}
              onWheel={(e) => e.target.blur()}
              onKeyPress={handleKeyPress}
              onChange={onChangeAmountCC}
              disabled={gridEdit.isBfEdit == true ? true : false}
            />
            <i className="lightGreen">$</i>
            </div>
            
           
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.AmountDosVal == true ? "Amount is Required" : null}
            </label>
          </div>
        ) : null}

        {/* worked claism */}

        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="receivedClaims"
            placeholder=""
            value={handleInputdos}
            onKeyPress={handleKeyPress}
            onChange={onChangeInput}
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel?.recievedAmountCC == true
              ? "Worked Claims is Required"
              : null}
          </label>
        </div>
        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount} <span style={{ color: "red" }}>*</span>
            </label>
            <div  class="form-group input-icon">
            <input
              className="form-control inputField"
              type="number"
              onWheel={(e) => e.target.blur()}
              name="receivedClaims"
              placeholder=""
              value={AmountDos}
              onKeyPress={handleKeyPress}
              

              

              onChange={onChangeAmount}
            />
            <i className="lightGreen">$</i>
            </div>
            
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel?.AmountDosValCC == true
                ? "Worked Amount is Required"
                : null}
            </label>
          </div>
        ) : null}

        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Claims"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={pendingClaimss}
              // onChange={onChangeInput}
              disabled
            />
          </div>
        ) : null}

        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Amount"}
            </label>

            
<div  class="form-group input-icon"> 
<input
              className="form-control inputField"
              type="number"
              onWheel={(e) => e.target.blur()}
              name="pendingAmount"
              placeholder=""
              value={pendingAmountDos}
              disabled
            />
            <i className="lightGreen">$</i>
</div>
            
          </div>
        ) : null}


{flagFooter== 'charge' && (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Is DemoGraphic"}
            </label>

            
<div  class="form-group input-icon"> 
<input type="radio" id="css" className="mt-2" onClick={onChangeDemo} checked={helpingState?.isDemographic}></input>

           
</div>
            
          </div>
        ) }
       
        </div>
        </Panel>
        <div className="row mt-4"></div>
        

        {isPendingSectionOpen ? pendingSectionhtml : null}
        {!isPendingSectionOpen && (
          <>
            <div className="col-4"></div>
            <div className="col-4"></div>
          </>
        )}
        <div className={!isPendingSectionOpen ? "col-4" : "col-sm-6 col-lg-3"}>
          <div class="form-group ">
            <div
              className={
                !isPendingSectionOpen
                  ? "d-flex justify-content-end mt-4"
                  : "d-flex mt-4"
              }
            >
              <button
                type="button"
                className="btnClear mr-1 "
                onClick={onAdddos}
              >
                {gridEdit.isEdit || gridEdit.isBfEdit ? "Update" : "Add"}

              </button>
              <div className="d-flex  mt-2 ml-2"> <InfoOutlineIcon onClick={()=>openInfoSwal('Auth')} style={{fill:'#0e7490'}} /> </div>
              
            </div>
          </div>
          
        </div>
      
      
    </>
  );

  // For Eobs Form
  console.log('stateAmountglobal',stateAmount)

  let EobCase = (
    <>
      <div className="row">
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Check Date <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <DatePicker
            onChange={onChangeDateCheck}
           // onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDateCheckDate}
            style={{ width: 400 }}
            //  value={handledosDate}
            oneTap
           // value={datePickerVal}
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.checkDate == true ? "Check Date is Required" : null}
          </label>
        </div>
        {/* ***************Eob Ref***************** */}

        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {realFlag=='Era'?"RefNo/CheckNo":"RefNo/CheckNo"} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="text"
            name="EobRef"
            onWheel={(e) => e.target.blur()}
            placeholder=""
            onKeyPress={handleKeyPress}
            value={eobRef}
            onChange={handleOnChangeeobRef}
            on
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? "Eob CheckNo/Ref No is Required"
              : null}
          </label>
        </div>
        {/* *****************Received Amount****************** */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Posted Amount"} <span style={{ color: "red" }}>*</span>
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="recAmountGen"
              placeholder=""
              onKeyPress={handleKeyPress}
              value={stateAmount?.recAmountGen}
              onChange={handleChangeAmount}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.recievedAmountgGen == true
              ? "Posted Amount is Required"
              : null}
            </label>
          </div>
        ) : null}
        
         {/* *****************Collected Amount****************** */}

         {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Check Amount"} <span style={{ color: "red" }}>*</span>
            </label>
            <input
             onWheel={(e) => e.target.blur()}
             onKeyPress={handleKeyPress}
              className="form-control inputField"
              type="number"
              name="CollectAmountGen"
              placeholder=""
              value={stateAmount?.CollectAmountGen}
              onChange={handleChangeAmount}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
               {errorModel.collectedAmountgGen == true
              ? "Insurance Amount is Required"
              : null}

            </label>
          </div>
        ) : null}
        {/* **************Received claims*********88 */}
        <Panel defaultExpanded={true} header="Payment Posting Dos Wise Fields" collapsible={true} >
          <div className="row mt-4">
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            //  value={handledosDate}
            oneTap
            value={datePickerVal}
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {"Recieved Claims"} <span style={{ color: "red" }}>*</span>
          </label>
          <input
           onWheel={(e) => e.target.blur()}
           onKeyPress={handleKeyPress}
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={handleInputdosCC}
            onChange={onChangeInputCC}
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? "Claims Received is Required"
              : null}
          </label>
        </div>

       
        {/* ******************PAID************ */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Paid Claims"} <span style={{ color: "red" }}>*</span>
            </label>
            <input
             onWheel={(e) => e.target.blur()}
             onKeyPress={handleKeyPress}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={AmountDosRec}
              onChange={onChangeAmountCC}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.AmountDosValCC == true ? "Paid is Required" : null}
            </label>
          </div>
        ) : null}

        {/* ****************dENEID***************8 */}

        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {"Denied Claims"} <span style={{ color: "red" }}>*</span>
          </label>
          <input
           onWheel={(e) => e.target.blur()}
           onKeyPress={handleKeyPress}
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={handleInputdos}
            onChange={onChangeInput}
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmountCC == true ? "Denied is Required" : null}
          </label>
        </div>

        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Partially Paid Claims
          </label>
          <input
           onWheel={(e) => e.target.blur()}
            className="form-control inputField"
            type="number"
            name="partially"
            onKeyPress={handleKeyPress}
            placeholder=""
            value={partially?.partially}
            onChange={handleChangePartially}
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          ></label>
        </div>

        {/* *****************tOTAL cLAIMS wORKED************* */}
        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Total Worked Claims"} 
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={AmountDos}
              onChange={onChangeAmount}
              disabled
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}

{/* //---------------Total Worked Amount----- */}
{showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Total Worked Amount"} 
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={totalWorkedAmount}
            //  onChange={onChangeAmount}
              disabled
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}

        {/* *******************pENDING cLAIMS***************** */}
        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Claims"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={pendingClaimss}
              // onChange={onChangeInput}
              disabled
            />
          </div>
        ) : null}

        
       
        {/* *****************Pending Amount Amount****************** */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="pendAmuontGen"
              placeholder=""
              value={stateAmount?.pendAmuontGen}
              onChange={handleChangeAmount}
              disabled
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}
        </div>
        </Panel>
        <div className="mt-4"></div>

        {/* <div className="col-lg-12 mb-2 mt-4 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Payments
          </h4>
        </div> */}
        {/* *********************Amount****************** */}

        <Panel defaultExpanded={true} header="Payments Breakup" collapsible={true} >
          <div className="row mt-4">
        <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Insurance Payments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="insurance"
              onKeyPress={handleKeyPress}
              placeholder=""
            
              value={paymentstate?.insurance}
             //value={stateAmount?.CollectAmountGen}
              onChange={payments}
             // disabled
            />
             <i className="lightGreen">$</i>
            </div>
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                 Partially Insurance Payments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
                  className="form-control inputField"
                  type="number"
                  name="insurancePar"
                  placeholder=""
                  disabled={partially?.isShowPartiallyBreakup?false:true}
                  value={paymentstate?.insurancePar}
                  onChange={payments}
                  onKeyPress={handleKeyPress}
                />
                <i className="lightGreen">$</i>
                </div>
                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Patient Responsibility{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="Patients"
              placeholder=""
              value={paymentstate?.Patients}
              onKeyPress={handleKeyPress}
            
              onChange={payments}
            />
             <i className="lightGreen">$</i>

            </div>
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Partially Patient Responsibility{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
                  className="form-control inputField"
                  disabled={partially?.isShowPartiallyBreakup?false:true}
                  type="number"
                  name="PatientsPar"
                  placeholder=""
                  value={paymentstate?.PatientsPar}
                  onChange={payments}
                  onKeyPress={handleKeyPress}
                />
                <i className="lightGreen">$</i>

                </div>
                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Adjustments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="Adjustments"
              placeholder=""
              value={paymentstate?.Adjustments}
              onKeyPress={handleKeyPress}
              onChange={payments}
            />
             <i className="lightGreen">$</i>
            </div>
                 
                 </div>
                 <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                 Partially  Adjustments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
                  className="form-control inputField"
                  type="number"
                  name="AdjustmentsPar"
                  placeholder=""
                  disabled={partially?.isShowPartiallyBreakup?false:true}
                  value={paymentstate?.AdjustmentsPar}
                  onChange={payments}
                  onKeyPress={handleKeyPress}
                />
                <i className="lightGreen">$</i>
                  </div>
                  </div>

                 </div>
                 </div>

                 <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Total Payments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="paymentsTotal"
              placeholder=""
              value={paymentstate?.paymentsTotal}
              
              onChange={payments}
              disabled
            />
             <i className="lightGreen">$</i>
            </div>
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Partially Total Payments{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}

                  className="form-control inputField"
                  type="number"
                  name="paymentsTotalPar"
                  placeholder=""
                  value={paymentstate?.paymentsTotalPar}
                  onChange={payments}
                  disabled
                />
                 <i className="lightGreen">$</i>
                </div>

                  </div>

                  </div></div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Denial Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="DenialAmount"
              placeholder=""
              onKeyPress={handleKeyPress}
              value={paymentstate?.DenialAmount}
              onChange={payments}
            />
             <i className="lightGreen">$</i>
            </div>

                  </div>

                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Partially Denial Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
                  className="form-control inputField"
                  type="number"
                  name="DenialAmountPar"
                  placeholder=""
                  value={paymentstate?.DenialAmountPar}
                  onChange={payments}
                  onKeyPress={handleKeyPress}
                  disabled={partially?.isShowPartiallyBreakup?false:true}
                />
                <i className="lightGreen">$</i>
                  </div>
                  
                  </div>
                

                  </div>
                  </div>
                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Patient Payment Collected/Posted{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="PPC"
              placeholder=""
              onKeyPress={handleKeyPress}
              value={paymentstate?.PPC}
              onChange={payments}
            />
             <i className="lightGreen">$</i>
            </div>

                  </div>

                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Patient Payment Adjusted{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                   onWheel={(e) => e.target.blur()}
                  className="form-control inputField"
                  type="number"
                  name="PPA"
                  placeholder=""
                  value={paymentstate?.PPA}
                  onChange={payments}
                  onKeyPress={handleKeyPress}
                 
                />
                <i className="lightGreen">$</i>
                  </div>
                  
                  </div>
                

                  </div>
                  </div>
                  </div>
                  </Panel>






        {/* {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Insurance Payments"}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="insurance"
              placeholder=""
              value={paymentstate?.insurance}
              onChange={payments}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null} */}
        {/* {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Patient Responsibility"}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="Patients"
              placeholder=""
              value={paymentstate?.Patients}
              onChange={payments}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null} */}


        {/* {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Adjustments"}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="Adjustments"
              placeholder=""
              value={paymentstate?.Adjustments}
              onChange={payments}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null} */}
        {/* {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Total Payments"}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="paymentsTotal"
              placeholder=""
              value={paymentstate?.paymentsTotal}
              onChange={payments}
              disabled
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null} */}
        {/* {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Denial Amount"}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="DenialAmount"
              placeholder=""
              value={paymentstate?.DenialAmount}
              onChange={payments}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null} */}

        {/* ********************* All partially Payment********************** */}
        {/* {partially?.isShowPartiallyBreakup ? (
          <>
            {" "}
            <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Partially Payments
              </h4>
            </div>
            {showAmount ? (
              <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {"Insurance Payments"}
                </label>
                <input
                  className="form-control inputField"
                  type="number"
                  name="insurancePar"
                  placeholder=""
                  value={paymentstate?.insurancePar}
                  onChange={payments}
                />
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                ></label>
              </div>
            ) : null}
            {showAmount ? (
              <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {"Patient Responsibility"}
                </label>
                <input
                  className="form-control inputField"
                  type="number"
                  name="PatientsPar"
                  placeholder=""
                  value={paymentstate?.PatientsPar}
                  onChange={payments}
                />
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                ></label>
              </div>
            ) : null}
            {showAmount ? (
              <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {"Adjustments"}
                </label>
                <input
                  className="form-control inputField"
                  type="number"
                  name="AdjustmentsPar"
                  placeholder=""
                  value={paymentstate?.AdjustmentsPar}
                  onChange={payments}
                />
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                ></label>
              </div>
            ) : null}
            {showAmount ? (
              <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {"Total Payments"}
                </label>
                <input
                  className="form-control inputField"
                  type="number"
                  name="paymentsTotalPar"
                  placeholder=""
                  value={paymentstate?.paymentsTotalPar}
                  onChange={payments}
                  disabled
                />
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                ></label>
              </div>
            ) : null}
            {showAmount ? (
              <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {"Denial Amount"}
                </label>
                <input
                  className="form-control inputField"
                  type="number"
                  name="DenialAmountPar"
                  placeholder=""
                  value={paymentstate?.DenialAmountPar}
                  onChange={payments}
                />
                <label
                  className="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                ></label>
              </div>
            ) : null}
          </>
        ) : null} */}


{isAllowOpeneinalbreakup?<>
  <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Denials Claims Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="coding"
            placeholder=""
            onKeyPress={handleKeyPress}
              value={AllBreakupVal?.coding}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Billing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="Billing"
            onKeyPress={handleKeyPress}
            placeholder=""
             value={AllBreakupVal?.Billing}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
         Credientialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="Credientialing"
            placeholder=""
            onKeyPress={handleKeyPress}
               value={AllBreakupVal?.Credientialing}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="Provider"
            onKeyPress={handleKeyPress}
            placeholder=""
              value={AllBreakupVal?.Provider}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
         Patient
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="Patient"
            onKeyPress={handleKeyPress}       
            placeholder=""
               value={AllBreakupVal?.Patient}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
        Total 
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="totalDenial"
            placeholder=""
               value={AllBreakupVal?.totalDenial}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      
     
</>:null}

        <div className={!isPendingSectionOpen ? "col-4" : "col-sm-6 col-lg-3"}>
          <div class="form-group ">
            <div
              className={
                !isPendingSectionOpen
                  ? "d-flex justify-content-end mt-4"
                  : "d-flex mt-4"
              }
            >
              <button
                type="button"
                className="btnClear mr-1 "
                onClick={onAdddos}
              >
                {gridEdit.isEdit ? "Update" : "Add"}
               
              </button>
              <div className="d-flex  mt-2 ml-2"> <InfoOutlineIcon onClick={()=>openInfoSwal('Eob')} style={{fill:'#0e7490'}} /> </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  let vobCase = (
    <>
      <div className="row">
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: '30rem' }}
            //  value={handledosDate}
            oneTap
            value={datePickerVal}
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        {/* **************Received claims*********88 */}
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {`Received`} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="Recieved"
            placeholder=""
            onKeyPress={handleKeyPress}
            value={AmountDosRec}
            onWheel={(e) => e.target.blur()}
            onChange={onChangeInputCC}
         
            
            min="0"
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmountCC == true
              ? "Claims Received is Required"
              : null}
          </label>
        </div>

        {/* ****************portal***************8 */}

        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {`Through Portal`} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="portals"
            placeholder=""
            value={handleInputdosCC}
            onWheel={(e) => e.target.blur()}
            onChange={onChangeInput}
            onKeyPress={handleKeyPress}
            min="0"
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.AmountDosValCC == true ? "Portals is Required" : null}
          </label>
        </div>

        {/* *****************Calls************* */}
        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount} <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="calls"
              onWheel={(e) => e.target.blur()}
              placeholder=""
              value={AmountDos}
              onKeyPress={handleKeyPress}
              min="0"
              onChange={onChangeAmountCC}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.AmountDosVal == true ? "Calls is Required" : null}
            </label>
          </div>
        ) : null}
        {/* ******************worked************ */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount1} <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="worked"
              onWheel={(e) => e.target.blur()}
              placeholder=""
              value={handleInputdos}
              min="0"
              disabled
            />
          </div>
        ) : null}
        {/* *******************pENDING cLAIMS***************** */}
        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending "}
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              onWheel={(e) => e.target.blur()}
              value={pendingClaimss}
              min="0"
              disabled
            />
          </div>
        ) : null}





       
      </div>

      <div className="mt-4"></div>
     

      <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >

      {pendingSectionvob}
      </Panel>
      <div className={!isPendingSectionOpen ? "col-4" : "col-sm-6 col-lg-3"}>
          <div class="form-group ">
            <div
              className={
                !isPendingSectionOpen
                  ? "d-flex justify-content-end mt-4"
                  : "d-flex mt-4"
              }
            >
              <button
                type="button"
                className="btnClear mr-1 "
                onClick={onAdddos}
              >
                {gridEdit.isEdit ? "Update" : "Add"}
              </button>
              <div className="d-flex  mt-2 ml-2">
              <InfoOutlineIcon onClick={()=>openInfoSwal('vob')} style={{color:'0e7490'}} />
              </div>
            </div>
          </div>
        </div>
    </>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  let payerCase = (
    <>
      <div className="row">
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            //  value={handledosDate}
            oneTap
            value={datePickerVal}
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>

        {changeState != "Sp" && (
          <>
            <div class="col-sm-6 col-lg-6 mb-5">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Payers List <span style={{ color: "red" }}>*</span>
                </label>

                <Select
                  options={products || []}
                  placeholder="Select an option"
                  onChange={handlePracticeVal}
                  // defaultValue={[{value:result?.generalpayerName,label:result?.generalpayerID}]}
                  value={[
                    {
                      label: resultPayer?.generalpayerName,
                      value: resultPayer?.generalpayerID,
                    },
                  ]}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="col-lg-3"></div>
          </>
        )}

        {/* **************Received claims*********88 */}
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading1} <span style={{ color: "red" }}>*</span>
          </label>
          <input
           onWheel={(e) => e.target.blur()}
            className="form-control inputField"
            type="number"
            name="Recieved"
            placeholder=""
            value={handleInputdosCC}
            onChange={onChangeInputCC}
            onKeyPress={handleKeyPress}
            required
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmountCC == true
              ? "Claims Received is Required"
              : null}
          </label>
        </div>

        {/* ******************worked************ */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount} <span style={{ color: "red" }}>*</span>
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="worked"
              placeholder=""
              onKeyPress={handleKeyPress}
              value={handleInputdos}
              onChange={onChangeInput}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.recievedAmount == true
                ? "Worked Claims is Required"
                : null}
            </label>
          </div>
        ) : null}


         {/* ******************Partially Paid Claims************ */}

         {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {'Partially Paid Claims'} <span style={{ color: "red" }}>*</span>
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="worked"
              placeholder=""
              onKeyPress={handleKeyPress}
              value={partiallyPaidClaims}
              onChange={onHandlePartiallyPaidClaims}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.recievedAmount == true
                ? "Worked Claims is Required"
                : null}
            </label>
          </div>
        ) : null}

        {/* *******************pENDING cLAIMS***************** */}
        {isPendingSectionOpen ? (
          <div className={"col-3"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Claims"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              value={pendingClaimss}
              disabled
            />
          </div>
        ) : null}

        {/* *****************Received Amount****************** */}
        {}
        

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Received Claims Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="recAmountGen"
              onKeyPress={handleKeyPress}
              placeholder=""
              value={stateAmount?.recAmountGen}
              onChange={handleChangeAmount}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}
        {/* *****************Collected Amount****************** */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Collected Claims Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="CollectAmountGen"
              placeholder=""
              value={stateAmount?.CollectAmountGen}
            //  value={
            //   changeState == "Sp" ? AllBreakupVal?.totala : AllBreakupVal?.totala
            // }
              disabled
              onChange={handleChangeAmount}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}
        {/* *****************Pending Amount Amount****************** */}

        {showAmount ? (
          <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Pending Claims Amount"}
            </label>
            <input
             onWheel={(e) => e.target.blur()}
              className="form-control inputField"
              type="number"
              name="pendAmuontGen"
              placeholder=""
              value={stateAmount?.pendAmuontGen}
              onChange={handleChangeAmount}
              disabled
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        ) : null}

        {changeState == "Sp" && (
          <>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Special Payers
                </label>
                <Select
                  options={products || []}
                  // placeholder="Select an option"
                  onChange={handlePracticeVal}
                  value={[{ label: spayerName, value: spayerid }]}
                  styles={customStyles}
                />
                <button
                  style={{
                    marginTop: "-32px",
                    marginRight: "-30px",
                    background: "none",
                  }}
                  onClick={() => {
                    setMinimize((prev) => !prev);
                  }}
                  className={
                    minimize === false
                      ? "practiceShow "
                      : "practiceShow minimizeColor"
                  }
                >
                  {minimize === false ? (
                    <ExpandOutlineIcon />
                  ) : (
                    <CollaspedOutlineIcon />
                  )}
                </button>
              </div>
            </div>
            {minimize === true ? (
              <>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Payer Name
                    </label>
                    <input
                     onWheel={(e) => e.target.blur()}
                      class="form-control inputField"
                      type="text"
                      value={spayer}
                      onChange={onChangeName}
                      name="name"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-2">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Payer Id
                    </label>
                    <input
                      class="form-control inputField"
                      type="text"
                      value={sid}
                      onChange={onChangeName}
                      name="id"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-2 pb-2">
                  <div class="form-group mb-2 ">
                    <label
                      class="form-control"
                      style={{
                        color: "black",
                        border: "white",
                        marginTop: "-8",
                      }}
                    ></label>
                    <button
                      type="button"
                      onClick={saveList}
                      className="btnSave "
                    >
                      Add
                    </button>
                   
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}

        {isPendingSectionOpen && flag == "payer"
          ? pendingSectionhtmlGenralPayer
          : null}

        <div className={!isPendingSectionOpen ? "col-4" : "col-sm-6 col-lg-3"}>
          <div class="form-group ">
            <div
              className={
                !isPendingSectionOpen
                  ? "d-flex justify-content-end mt-4"
                  : "d-flex mt-4"
              }
            >
              <button
                type="button"
                className="btnClear mr-1 "
                onClick={onAdddos}
              >
                {gridEdit.isEdit ? "Update" : "Add"}
              </button>
              <div className="d-flex  mt-2 ml-2"> <InfoOutlineIcon onClick={()=>openInfoSwal('payer')} style={{fill:'#0e7490'}} /> </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );

  let case3 = (
    <>
      <div className="row">
        <div className="col-4">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            DOS <span style={{ color: "red" }}>*</span>
          </label>
          <br></br>
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            oneTap
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        <div className="col-4">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            // value={receivedClaims}
            onChange={onChangeInput}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? ` ${claimHeading} is required`
              : null}
          </label>
        </div>
        {showAmount ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount}:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangeAmount}
            />
          </div>
        ) : null}
        {showPortal ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              Calls:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangeCalls}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.calls == true ? `Calls is required` : null}
            </label>
          </div>
        ) : null}
      </div>

      <div className="row">
        {showCalls ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              Portals:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangePortals}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.portals == true ? `Portals is required` : null}
            </label>
          </div>
        ) : null}
        <div className="">
          <label className="inputLabel pb-3"></label>
          <br></br>
          <Button
            onClick={onAdddos}
            style={{ backgroundColor: "#0e7490", color: "white" }}
          >
            {gridEdit.isEdit ? "Update" : "Add"}

          </Button>
          
        </div>
      </div>
    </>
  );
  const getRequriedPara=()=>{

  }

  console.log("hey", dosArrayPaid);
  let checkCase1 = !showAmount && showPortal;
  //true && true shoeportal
  let checkCase2 = showAmount && !showPortal;
  let checkEob = showAmount && !showPortal && flag == "Eob";
  let checkvob = showAmount && !showPortal && flag == "vobb";
  let checkpayer = showAmount && !showPortal && flag == "payer";

  console.log(bfVal, "bfVal");
  
  

  return (
    <>
    {placement?.isShow ?<Sweetalertdemo
         handleCloseSwal={handleCloseSwal}
          triggerNotify={true}
          titleSweet={placement?.caseType=='vob'?"Condition ":placement?.caseType=='Auth'?"Condition":placement?.caseType=='Eob'?'Condition':placement?.caseType=='PatStat'?'Condition':placement?.caseType=='payer'?'':null}
          text={placement?.caseType=='vob'?
      `<div >    
              <span >1: Select Date of Service </span><br /><br />
              <span>2: Pending  Should be equal to Pending Claims Breakup Total  </span><br /><br />
              <span>3: On Negative or wrong calculation it will show toaster message</span><br /><br />
             
              
            </div>`:placement?.caseType=='Auth'?`<div >    
            <span >1:Select Date of Service</span><br /><br />
            <span>2: Pending Claims  Should be equal to Pending Claims Breakup Total  </span><br /><br />
            <span>3: On Negative or wrong calculation it will show toaster message</span><br /><br />
           
            
          </div>`:placement?.caseType=='Eob'?`<div >    
          <span >1:Select Date of Service</span><br /><br />
         
          <span>4: Denied Claims  should be equal to Denial Claims Breakup </span><br /><br />
          <span>5: if you enter Partially Paid Claims, then Partially Breakup Activates </span>
         
          
        </div>`:placement?.caseType=='PatStat'?`<div >    
        <span >1:Select Date of Service</span><br /><br />
        <span>2: Rejected Should  be equal to Rejected Breakup Total  </span><br /><br />
        
        <span>3:On Negative or wrong calculation it will show toaster message </span>
       
        
      </div>`:placement?.caseType=='payer'?`<div >    
      <span >1:Select Date of Service</span><br /><br />
      <span >2: Worked Claims should be equal to  Total Claims  </span><br /><br />
      <span>3: Collected Claims Amount is sum of No of Partially Paid Amount and No of Claims Paid Amount
        </span><br /><br />
      
      <span>4:Pending Claims Amount should be equal to  Total Worked Amount</span>
     
      
    </div>`:null
          }
          swalType={'info'}
        />:null}
    
      {parseFloat(bfVal) > 0 ? message : null}
      <div className="row">
        {!onlyShowTable
          ? checkCase1
            ? case3
            : checkEob
            ? EobCase
            : checkvob
            ? vobCase
            : checkpayer
            ? payerCase
            : checkCase2
            ? case2
            : case1
          : null}
        <div className="col-lg-12 mb-2 text-center"></div>
        <div className="col-lg-12 mb-2 text-center"></div>

        <UserAuditTable
        getRequriedPara={getRequriedPara}
          Footer={Footer}
          rowHeight={48}
          handleChange={handleChange}
          handleEditState={handleEditState}

          dateForgrid={parsedArray}
          isPopupOpen={isPopupOpen}
          Array={
            flag == "vobb"
              ? arrVob
              : flag == "payer"
              ? Payer
              : flag == "Eob" && changeState == "Eob"
              ? Eob
              : flag == "patStat" && changeState == "patState"
              ? PendingClaimBreakupgridForPatStat
              : flag === "generalPayer" && changeState == "Sp"
              ? specialpayer
              : flag === "generalPayer"
              ? generalpayer
              : onlyShowTable && showTableCheck === "charge"
              ? PendingClaimBreakupgrid
              : onlyShowTable && showTableCheck == "eob"
              ? PendingClaimBreakupgridForEob
              : showTableCheck == "vob" && onlyShowTable
              ? PendingClaimBreakupgridForVob
              : checkCase1
              ? vobsArr
              : checkCase2
              ? arr
              : vobsArr1
          }
          rowShown={5}
          flagFooter={flagFooter}
          isPaginationShow={true}
        
        />

        {onlyShowTable && showTableCheck == "eob" && (
          <>
            <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Paid Claims
              </h4>
            </div>
            <UserAuditTable
           
            getRequriedPara={getRequriedPara}
              rowHeight={48}
              handleChange={handleChange}
              handleEditState={handleEditState}
              dateForgrid={dosArrayPaid}
              isPopupOpen={isPopupOpen}
              Array={PaidClaimBreakupgridForEob}
              rowShown={5}
              isPaginationShow={true}
            />
          </>
        )}
        <div class="col-sm-6 col-lg-3 "></div>

        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>

        <div class="col-sm-6 col-lg-3 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              {/* <button
                type="button"
                className="btnClear mr-1 "
                onClick={handleClose}
              >
                Back
              </button> */}
              <div className="mr-2 ">
              
           
      
  
              </div>
              
              {onlyShowTable ? null : (
                <button type="button" className="btnSave" onClick={onSaveDos}>
                  Back
                </button>
              )}
              
            </div>
          </div>
        </div>
      </div>
      

    </>
  );
}
