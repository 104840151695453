import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onSaveApi } from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Select from 'react-select';
import ReactDropdown from "react-dropdown";
const TeamLeadForm = ({ record, practiceId, date, formTypeNoForApi }) => {
    const { onLoginSignOff } = useSelector((state) => state);


    const [result, setResult] = useState({
        GeneralOpen: "",
        bfval: null,
        calls: "",
        comments: null,
        created_at: ""
        , formtype: ""
        , id: ''
        , image: ""
        , insurance: "",
        pendingval: ""
        , pid: "",
        portals: "",
        recievedval: ""
        , sdate: "",
        stoken: "",
        submittype: "",
        updated_at: "",
        userid: "",
        verify: null,
        workedval: "",
        bfvalprv: "",
        rcount: "",
    })
    const [copyResult] = useState(result)

    const onSave = async () => {

        if (practiceId == null) {
            toast.info('Please Select Practice Name')
            return
        }
        else {

            const formDataForSaveApi = new FormData();
            formDataForSaveApi.append("userid", onLoginSignOff.userid);
            formDataForSaveApi.append("sdate", date);
            formDataForSaveApi.append("pid", practiceId);
            formDataForSaveApi.append("formtype", formTypeNoForApi);
            formDataForSaveApi.append("bfval", result.bfval);
            formDataForSaveApi.append("recievedval", result.recievedval);
            formDataForSaveApi.append("portals", result.portals);
            formDataForSaveApi.append("insurance", result.insurance);
            formDataForSaveApi.append("workedval", result.workedval);
            formDataForSaveApi.append("GeneralOpen", result.GeneralOpen);
            formDataForSaveApi.append("pendingval", result.pendingval);
            formDataForSaveApi.append(" stoken", result.stoken);
            formDataForSaveApi.append(" calls", result.calls);
            formDataForSaveApi.append("submittype", "No");

            let resultt = await onSaveApi(formDataForSaveApi)
            if (resultt?.data && resultt?.data.message == "Record Updated Successfully!") {
                toast.info('Record Saved Successfully')

            }
        }
    }



    useEffect(() => {
        if (record) {

            if (record.rcount != 0) {
                setResult({
                    GeneralOpen: record[0].GeneralOpen,
                    bfval: record[0].bfval,
                    calls: record[0].calls,
                    comments: record[0].comments,
                    created_at: record[0].created_at
                    , formtype: record[0].formtype
                    , id: record[0].id
                    , image: record[0].image
                    , insurance: record[0].insurance,
                    pendingval: record[0].pendingval
                    , pid: record[0].pid,
                    portals: record[0].portals,
                    recievedval: record[0].recievedval
                    , sdate: record[0].sdate,
                    stoken: record[0].stoken,
                    submittype: record[0].submittype,
                    updated_at: record[0].updated_at,
                    userid: record[0].userid,
                    verify: record[0].verify,
                    workedval: record[0].workedval,
                    bfvalprv: record[0].bfvalprv,
                    rcount: record[0].rcount,
                })
            }
            else {
                setResult(copyResult)
            }
        }

    }, [record, practiceId, date]);

    const handelChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        setResult({ ...result, [name]: value })

    }
    const onclear = () => {
        setResult(copyResult)
    }

    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: "black" }}>
                                Meeting
                            </label>
                            <ReactDropdown
                                value="please select"
                                placeholder="Select an option"
                                options={["Meeting"]}
                            />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: " black" }} >
                                Frequency
                            </label>
                            <ReactDropdown
                                value="please select"
                                placeholder="Select an option"
                                options={["Daily", "Weekly", "B-Weekly", "Monthly", "Other"]}
                            />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: " black" }} >
                                Status
                            </label>
                            <ReactDropdown
                                value="please select"
                                placeholder="Select an option"
                                options={["Done", "Postponed", "Cancelled", "No Show"]}
                            />
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="form-group mb-3 mt-3">
                            <textarea
                                class="form-control inputField"
                                placeholder="Comments if any!"
                            ></textarea>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: " black" }} >
                               Provider Reports
                            </label>
                            <ReactDropdown
                                className="inputField"
                                value="please select"
                                placeholder="Select an option"
                                options={["Provider Reports", "Patient Statement", "Co-Pay Collection", "Practice Health Report"]}
                            />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: " black" }} >
                                Frequency
                            </label>
                            <ReactDropdown
                                value="please select"
                                placeholder="Select an option"
                                options={["Daily", "Weekly", "B-Weekly", "Monthly", "Other"]}
                            />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-2">
                            <label class="inputLabel" style={{ color: " black" }} >
                                Status
                            </label>
                            <ReactDropdown
                                value="please select"
                                placeholder="Select an option"
                                options={["Sent", "Pending"]}
                            />
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="form-group mb-3 mt-3">
                            <label class="inputLabel" style={{ color: " black" }} >
                                Any Other Task
                            </label>
                            <textarea
                                class="form-control inputField"
                                placeholder="Comments if any!"
                            ></textarea>


                        </div>
                    </div>


                    <div class="col-sm-6 col-lg-12 mt-2">
                        <div class="form-group mb-2 ">
                            <div className="d-flex justify-content-end mt-4">
                                <button
                                    type="button"
                                    onClick={onclear}
                                    className="btnClear mr-1 "
                                >
                                    Clear
                                </button>
                                <button
                                    type="button"
                                    onClick={onSave}
                                    className="btnSave"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default TeamLeadForm
