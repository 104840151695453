import React from "react";
import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { IconButton, ButtonToolbar, ButtonGroup } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import VisibleIcon from '@rsuite/icons/Visible';
import { toast } from "react-toastify";

export default function DateAndPractice({
  showChargeType,
  PatTypeValue,
  onChangePatStatType,
  showPatStatType,
  showPayment,
  nowShowExtraDiv,
  onChangePayer,
  payerSelection,
  showPayer,
 
  date,
  products,
  handleFormTypeAndPract,
  selectedOption,
  setSelectedOption,
  callGlobalDos,
  openGlobalTable
}) {
  useEffect(() => {
    setSelectedOption(selectedOption);
  }, []);

  console.log("selectedOption", selectedOption);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");

  const handlePracticeVal = (val) => {
    const { value, label } = val;
    setSelectedOption(val);
    let obj = { name: "practiveVal", val: value };
    handleFormTypeAndPract(obj);
  };
  const handleVal = (event) => {
    const { value } = event.target;
    let obj = { name: "date", val: value };
    handleFormTypeAndPract(obj);
  };
  const showAlert=()=>{
    toast.info('Please Select Practice')
  }

  console.log('selectedOption',selectedOption)

  return (
    <>
   
   
      <div className="col-3">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Practice
        </label>
        <br />
        <Select
          options={products || []}
          placeholder="Select an option"
          onChange={handlePracticeVal}
          value={{ label: selectedOption.label, value: selectedOption.value }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>
      <div className="col-3">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Entry Date
        </label>
        <br />
        <input
          class="form-control inputField"
          type="date"
          name="phone"
          placeholder=""
          max={today}
          min={minDate}
          defaultValue={date}
          onChange={handleVal}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>

      


      {showPayment && <div className="col-3">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Payment Type
        </label>
        <br />
        <Select
          options={[{value:'EOBS',label:'EOB`s'},{value:'ERAS',label:'ERA`s'}]}
          placeholder="Select an option"
          onChange={onChangePayer}
          value={{ label: payerSelection?.label, value: payerSelection?.value }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
         
        </label>
      </div>}
      {showPatStatType && <div className="col-3">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
           Type
        </label>
        <br />
        <Select
          options={[{value:'PLD',label:'PLD'},{value:'Electronically',label:'Electronically'}]}
          placeholder="Select an option"
          onChange={onChangePatStatType}
          value={{ label: PatTypeValue?.label, value: payerSelection?.label }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
         
        </label>
      </div>}


      {showChargeType && <div className="col-3">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
           Type
        </label>
        <br />
        <Select
          options={[{value:'Paper',label:'Paper'},{value:'Electronically',label:'Electronically'}]}
          placeholder="Select an option"
          onChange={onChangePatStatType}
          value={{ label: PatTypeValue?.label, value: payerSelection?.label }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
         
        </label>
      </div>}

      <div className="col-3 ">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        ></label>
        <br></br>
        <span
          className="ml-4"
           style={{  top: "20px", right: "0" }}
          onClick={selectedOption?.value?() => callGlobalDos(true):showAlert}
        >
          <IconButton style={{color:'#0e7490'}} icon={<PlusIcon />}>Add</IconButton>
        </span>
        <span
          className="ml-4"
           style={{  top: "20px", right: "0" }}
          onClick={selectedOption?.value?() => openGlobalTable(true):showAlert}
        >
          <IconButton style={{color:'#0e7490'}} icon={<VisibleIcon />}>View</IconButton>
        </span>
        
      </div>
      {showPayer &&
      //  <div className="col-3">
      //   <label
      //     className="inputLabel"
      //     style={{ color: "black", fontSize: "13px" }}
      //   >
      //     Payer Type
      //   </label>
      //   <br />
      //   <Select
      //     options={[{value:'AccountReceivablegeneral',label:'A/R General'},{value:'AccountReceivablespecial',label:'A/R Special'}]}
      //     placeholder="Select an option"
      //     onChange={onChangePayer}
      //     value={{ label: payerSelection?.label, value: payerSelection?.value }}
      //     styles={customStyles}
      //   />
      //   <label
      //     className="inputLabel"
      //     style={{ color: "black", fontSize: "13px", color: "red" }}
      //   >
         
      //   </label>
      // </div>
      <div className="col-3"></div>
      
      }
      {nowShowExtraDiv?null:<div className="col-3"></div>}

      
    </>
  );
}
