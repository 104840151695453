import React from 'react'
import UserAuditTable from '../../Audit/userTaskViewTable'

export default function NewDetailTableScreen({data,backToMainScreen}) {
    console.log(data,'dataFor Screen')
    

    const dataForGrid = [
      {
        colName: "Date",
        flexGrow: 1.9,
        dataKey: "Dos",
        rowSpan: 1,
      },
       
        {
          colName: "Received Claims",
          flexGrow: 1.9,
          dataKey: "receivedClaims",
          rowSpan: 1,
        },
        {
          colName: "Worked Claims",
          flexGrow: 1.9,
          dataKey: "workedClaims",
          rowSpan: 1,
        },
        {
            colName: "Pending Claims",
            flexGrow: 1.9,
            dataKey: "pendingClaims",
            rowSpan: 1,
          },

      
       
    
      
        {
          colName: "Provider",
          flexGrow: 1.9,
          dataKey: "provider",
          rowSpan: 1,
        },
        {
          colName: "Operation",
          flexGrow: 1.9,
          dataKey: "operation",
          rowSpan: 1,
        },
        {
          colName: "Total Pending Claim Breakup",
          flexGrow: 1.9,
          dataKey: "total",
          rowSpan: 1,
        },
      
      ];


      const getRequriedPara=()=>{

      }

    
  return (
    <div>
         <UserAuditTable
          Array={dataForGrid}
          getRequriedPara={getRequriedPara}
          dateForgrid={data}
         // loader={loader}
        //  onChangePop={onChangePop}
        />
     <div class="form-group mb-2 ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    onClick={backToMainScreen}
                    className="btnClear mr-1 "
                  >
                    Back
                  </button>
                </div>
              </div>
      
    </div>
  )
}
