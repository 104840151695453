import React from 'react'
import UserAuditTable from './userTaskViewTable';


export default function SubRecordAgging({prevhelpingState,onBack}) {

    const vob = [
        
        {
          colName: `User`,
          flexGrow: 1.9,
          dataKey: "name",
          rowSpan: 1,
        },
        {
            colName: `Date of Service`,
            flexGrow: 1.9,
            dataKey: "date",
            rowSpan: 1,
          },
        {
            colName: `Date of Entry`,
            flexGrow: 1.9,
            dataKey: "sdate",
            rowSpan: 1,
          },
          {
            colName: `Total Claims Left`,
            flexGrow: 1.9,
            dataKey: "total",
            rowSpan: 1,
          },
    
        
      ];
      const Acc = [
        
        {
          colName: `User`,
          flexGrow: 1.9,
          dataKey: "name",
          rowSpan: 1,
        },
        {
            colName: `Date of Service`,
            flexGrow: 1.9,
            dataKey: "date",
            rowSpan: 1,
          },
        {
            colName: `Date of Entry`,
            flexGrow: 1.9,
            dataKey: "sdate",
            rowSpan: 1,
          },
          {
            colName: `Total Claims Left`,
            flexGrow: 1.9,
            dataKey: "total",
            rowSpan: 1,
          },
    
        
      ];
      const PatStat = [
        
        {
          colName: `User`,
          flexGrow: 1.9,
          dataKey: "name",
          rowSpan: 1,
        },
        {
            colName: `Date of Service`,
            flexGrow: 1.9,
            dataKey: "date",
            rowSpan: 1,
          },
        {
            colName: `Date of Entry`,
            flexGrow: 1.9,
            dataKey: "sdate",
            rowSpan: 1,
          },
          {
            colName: `Total Claims Left`,
            flexGrow: 1.9,
            dataKey: "total",
            rowSpan: 1,
          },
    
        
      ];
    const getRequriedPara=()=>{

    }

    console.log('prevhelpingState?.vobData',prevhelpingState?.vobData,prevhelpingState?.subScreenOpen)
  return (
    <div>
        <UserAuditTable
         getRequriedPara={getRequriedPara}

        tableHeight={400}
        
          rowHeight={48}
          dateForgrid={prevhelpingState?.subScreenOpen=='vobs'?prevhelpingState?.vobData:prevhelpingState?.subScreenOpen=='Acc'?prevhelpingState?.AccData:prevhelpingState?.subScreenOpen=='PatStat'?prevhelpingState?.patData:[]}
          Array={prevhelpingState?.subScreenOpen=='vobs'?vob:prevhelpingState?.subScreenOpen=='Acc'?Acc:prevhelpingState?.subScreenOpen=='PatStat'?PatStat:[]}
          rowShown={5}
          loader={false}
          isPaginationShow={true}
        />
         <div class="form-group mb-2 ">
          <div class="d-flex justify-content-end ">
            
            <button type="button" className="btnSave" onClick={onBack}>
              Back
            </button>
           
          </div>
        </div>
        
        
      
    </div>
  )
}
