let userRolesObject={
    admin:1,
    manager:2,
    teamlead:3,
    biller:5,
    practice:6,
    smo:7,
    mo:8,
    amo:9,
    hr:10,
    backupTeam:11,
    tl:12,
    director:19,
    vp:20,
    gm:21,


}
export default userRolesObject