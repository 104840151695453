import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ButtonToolbar,
  Loader,
  Placeholder,
  Button,
  Message,
} from "rsuite";
import { Drawer, RadioGroup, Radio, IconButton } from "rsuite";

import { useSelector } from "react-redux";

// import { getAuditRecord, insertAuditRemarks } from "../../Utils/ApiManager";
import { toast } from "react-toastify";
import { data } from "browserslist";
import {
  SearchIfAuditTriggerFire,
  getAuditRecord,
  getTlAuditRecord,
  insertAuditRemarks,
  insertTlAudit,
  overAllLog,
} from "../../../../Utils/ApiManager";
import { Input } from "rsuite";
import PopupRemarksAuditCharge from "./popupAuditCharge";
import { SocketContext } from "../../../../context/socket";
import AuditTabMenus from "./AuditTabs";
import ToasterRsuite from "./ToasterRsuite";

export default function ChargeTypeRemarksPop({
  flagIndi,

  prevstate,
  formNamee,
  practiceId,
  date,

  rowDataAudit,
  setShowPopup,
  CloseAuditRemark,
  practiceWiseID,
  practiceIDs,
}) {
  const stateRedux = useSelector((state) => state);
  const socket = useContext(SocketContext);
  let objArr = { tabName: "ClaimLevel" };
  const [helpingObject, sethelpingObj] = useState(objArr);

  console.log(rowDataAudit, flagIndi, "hloooooo");
  let obj = {
    id: 0,
    claim: "",
    ErrorFound: "",
    ErrorType: "",
    sdate: rowDataAudit?.date,
    pid: rowDataAudit?.pid,
    userid: rowDataAudit?.userid,
    username: rowDataAudit?.name,
    Remarks: "",
    holdAllGridArrayValue: [],
    gridEdit: false,
    rowid: null,
    formName: null,
    loading: false,
    isDisbledButton: false,
    holdOverAllExistingLogs: null,
    isShowMessageToast: false,
    isNotifyAudit: null,
  };
  console.log("formNamee", formNamee);
  const [state, setState] = useState(obj);
  let AuditPopObj = {
    //For chargen Entry
    ReceivedClaims: "",
    WorkedClaims: "",
    PendingClaims: "",
    ReceivedAmount: "",
    WorkedAmount: "",
    PendingAmount: "",

    ReceivedVobClaim: "",
    callsvob: "",
    portals: "",
    Remarks: "",

    // For Payments:
    Patients: "",
    PatientsPar: "",
    insurance: "",
    insurancePar: "",
    Adjustments: "",
    AdjustmentsPar: "",
    DenialAmount: "",
    DenialAmountPar: "",

    // For patStat
    recievedAmountCC: "",
    recievedAmount: "",
    internalNonVerify: "",
    AmountDosValCC: "",
    AmountDosVal: "",
    pendingAmount: "",

    //For Ar
    PayerType: "",
    payerlabel: "",
    recievedAmountCC: "",
    recievedAmount: "",
    pending: "",
  };
  const [popAudit, setAuditPop] = useState(AuditPopObj);

  useEffect(() => {
    if (state.formName != null) {
      getAudtRecord();
    }
  }, [state.formName]);
  useEffect(() => {
    setState((prev) => ({ ...prev, formName: formNamee }));
  }, []);
  useEffect(() => {
    searchifoverAllAuditTrigger();
  }, []);

  const message = (
    <Message showIcon type={"info"} closable>
      {"info"}: The message appears on the {"topCenter"}.
    </Message>
  );

  const onCloseMessage = () => {
    setState((prev) => ({ ...prev, isShowMessageToast: false }));
  };

  const getAudtRecord = async () => {
    let obj = {
      Dos: prevstate.Dos,
      userid: rowDataAudit[0].userid,
      sdate: date,
      pid: practiceId,
      formName: formNamee,
    };

    let res = await getTlAuditRecord(obj);

    if (res.data.length > 0) {
      setState((prevState) => ({
        ...prevState,
        id: res?.data[0]?.id,

        sdate: res.data[0].sdate,
        pid: res?.data[0]?.pid,
        userid: res?.data[0]?.userid,
        holdAllGridArrayValue: JSON.parse(res?.data[0]?.auditlogArray),
        isNotifyAudit: res?.data[0]?.isNotifyAudit,
        notifyName: res.data[0]?.notifyName,
      }));
    }
  };
  console.log(state, "state");

  // Audit Remakr s simple
  const insertAuditRemarkss = async (arg) => {
    
    console.log(rowDataAudit[0]);
    if (arg) {
      setState((prev) => ({ ...prev, loading: true }));
      // For Real Time Notification Pop up
      let obj = {
        notifyName: "insertAudit",
        Dos: prevstate.Dos,
        id: rowDataAudit[0].id,
        userid: stateRedux.onLoginSignOff.userid,
        pid: practiceId,

        sdate: date,
        formName: formNamee,
        isNotifyAudit: "Yes",
        victimid: rowDataAudit[0].userid,
        victimName: rowDataAudit[0].name,
        userName: stateRedux.onLoginSignOff.name,
        acknowledged: "No",
        recurringReamkrs: "",
        statusStage: 1,
        taskStatus: false,

        auditlogArray: JSON.stringify(state.holdAllGridArrayValue),
      };

      insertTlAudit(obj)
        .then((res) => {
          setState((prev) => ({ ...prev, loading: false }));
          setState((prev) => ({ ...prev, isShowMessageToast: true }));
          toast.info("Notification Sent", {
            className: "toast-message",
          });

       //   socket.emit("AuditClaimNotification", obj);
           CloseAuditRemark(false);
          setState((prev) => ({ ...prev, loading: false }));
        })
        .catch((err) => {
          setState((prev) => ({ ...prev, loading: false }));
          setState((prev) => ({ ...prev, loading: false }));
        });
    } else {
      setState((prev) => ({ ...prev, loading: true }));

      let obj = {
        notifyName: "insertAudit",

        Dos: prevstate.Dos,
        id: state.id,
        userid: stateRedux.onLoginSignOff.userid,
        pid: practiceId,
        username: rowDataAudit[0].name,
        victimid: rowDataAudit[0].userid,
        victimName: rowDataAudit[0].name,
        userName: stateRedux.onLoginSignOff.name,

        sdate: date,
        formName: formNamee,
        isNotifyAudit: "No",
        acknowledged: "No",
        recurringReamkrs: "",
        statusStage: 1,
        taskStatus: false,

        auditlogArray: JSON.stringify(state.holdAllGridArrayValue),
      };
      console.log("popAudit", popAudit);
      //handle socket
      //socket.emit("notificationFromTl", obj);

      insertTlAudit(obj)
        .then((res) => {
          toast.info(res.data.message);

          setState((prev) => ({ ...prev, isShowMessageToast: true }));
          // CloseAuditRemark(false);
          setState((prev) => ({ ...prev, loading: false }));
        })
        .catch((err) => {
          setState((prev) => ({ ...prev, loading: false }));
        });
    }
  };

  const comparsion = () => {
    if (flagIndi === "vob") {
      return (
        popAudit.ReceivedVobClaim === prevstate.ReceivedVobClaim &&
        popAudit.callsvob === prevstate.callsvob &&
        popAudit.portals === prevstate.portals
      );
    } else if (flagIndi == "Payments") {
      return (
        popAudit.Patients === prevstate.Patients &&
        popAudit.PatientsPar === prevstate.PatientsPar &&
        popAudit.insurance === prevstate.insurance &&
        popAudit.insurancePar === prevstate.insurancePar &&
        popAudit.Adjustments === prevstate.Adjustments &&
        popAudit.AdjustmentsPar === prevstate.AdjustmentsPar &&
        popAudit.DenialAmount === prevstate.DenialAmount &&
        popAudit.DenialAmountPar === prevstate.DenialAmountPar
      );
    } else if (flagIndi == "AccForm") {
      return (
        popAudit.recievedAmountCC === prevstate.recievedAmountCC &&
        popAudit.recievedAmount === prevstate.recievedAmount &&
        popAudit.pending === prevstate.pending
      );
    } else if (flagIndi == "PatState") {
      return (
        popAudit.recievedAmountCC === prevstate.recievedAmountCC &&
        popAudit.recievedAmount === prevstate.recievedAmount &&
        popAudit.internalNonVerify === prevstate.internalNonVerify &&
        popAudit.AmountDosValCC === prevstate.AmountDosValCC &&
        popAudit.AmountDosVal === prevstate.AmountDosVal &&
        popAudit.pendingAmount === prevstate.pendingAmount
      );
    } else {
      return (
        popAudit.ReceivedClaims === prevstate.ReceivedClaims &&
        popAudit.WorkedClaims === prevstate.WorkedClaims &&
        popAudit.PendingClaims === prevstate.PendingClaims &&
        popAudit.ReceivedAmount === prevstate.ReceivedAmount &&
        popAudit.WorkedAmount === prevstate.WorkedAmount &&
        popAudit.PendingAmount === prevstate.PendingAmount
      );
    }
  };

  const searchifoverAllAuditTrigger = () => {
    let obj = {
      Dos: prevstate.Dos,

      userid: stateRedux.onLoginSignOff.userid,
      victimid: rowDataAudit[0].userid,
      pid: practiceId,

      sdate: date,
      formName: formNamee,
    };
    SearchIfAuditTriggerFire(obj)
      .then((res) => {
        if (res && res.data) {
          setState((prevState) => ({
            ...prevState,
            holdOverAllExistingLogs: res.data,
          }));
        }
      })
      .catch((err) => {});
  };

  const generateChangeStatement = (property, prevValue, newValue) => {
    if (prevValue === newValue) {
      return `No Change made in ${property}`;
    } else {
      return `${property} value is changed from ${prevValue} to ${newValue}`;
    }
  };
  // on Clic save button
  const insertOverAllAuditLog = async (arg) => {
  
    if (arg) {
      if (flagIndi == "vob") {
        let statementR = generateChangeStatement(
          "Received",
          prevstate.ReceivedVobClaim,
          popAudit.ReceivedVobClaim
        );
        let statementp = generateChangeStatement(
          "Portal",
          prevstate.portals,
          popAudit.portals
        );
        let statementa = generateChangeStatement(
          "Call",
          prevstate.callsvob,
          popAudit.callsvob
        );
        let res = await comparsion();
        if (res == false) {
          setState((prev) => ({ ...prev, loading: true }));
          let obj = {
            notifyName: "insertAuditOverAll",

            Dos: prevstate.Dos,
            id: state.id,
            pid: practiceId,
            sdate: date,

            formName: formNamee,
            isNotifyAudit: "Yes",

            victimid: rowDataAudit[0].userid,

            userid: stateRedux.onLoginSignOff.userid,

            victimName: rowDataAudit[0].name,
            userName: stateRedux.onLoginSignOff.name,
            acknowledged: "No",
            recurringReamkrs: "",
            statusStage: 1,
            taskStatus: false,

            auditlogArray: JSON.stringify({
              ReceivedVobClaim: statementR,
              callsvob: statementp,
              portals: statementa,
              Remarks: popAudit?.Remarks,
            }),
          };
          //handle socket
          //socket.emit("notificationFromTl", obj);

          overAllLog(obj)
            .then((res) => {
              toast.info("Record Saved And Send Successfully", {
                zIndex: 9999,
                hideProgressBar: true,
                autoClose: 2000,
                position: toast.POSITION.TOP_CENTER,
              });
          //    socket.emit("overAllnotificationFromTl", obj);
               CloseAuditRemark(false);

              setState((prev) => ({ ...prev, loading: false }));
            })
            .catch((err) => {
              setState((prev) => ({ ...prev, loading: false }));
            });
        } else {
          toast.info("Values are not change", { position: "top-left" });
        }
      } else if (flagIndi == "Payments") {
        let res = await comparsion();
        if (res == false) {
          let statementPP = generateChangeStatement(
            "Patient Payment",
            prevstate.Patients,
            popAudit.Patients
          );
          let statementPPP = generateChangeStatement(
            "Partially Patient Payment",
            prevstate.PatientsPar,
            popAudit.PatientsPar
          );
          let statementIP = generateChangeStatement(
            "Ins. Payment",
            prevstate.insurance,
            popAudit.insurance
          );

          let statementPI = generateChangeStatement(
            "Partially Ins.Payment",
            prevstate.insurancePar,
            popAudit.insurancePar
          );
          let statementA = generateChangeStatement(
            "Adjustment",
            prevstate.Adjustments,
            popAudit.Adjustments
          );
          let statementPIP = generateChangeStatement(
            "Partially Adjustment",
            prevstate.AdjustmentsPar,
            popAudit.AdjustmentsPar
          );
          let statementPA = generateChangeStatement(
            "Denied Amount",
            prevstate.DenialAmount,
            popAudit.DenialAmount
          );
          let statementDA = generateChangeStatement(
            "Partially Denied Amount",
            prevstate.DenialAmountPar,
            popAudit.DenialAmountPar
          );
          //  let statementPDA = generateChangeStatement("Partially Denied Amount", prevstate.WorkedAmount, popAudit.WorkedAmount);

          let NotifyObj = {
            notifyName: "insertAuditOverAll",

            Dos: prevstate.Dos,
            id: state.id,
            pid: practiceId,
            sdate: date,

            formName: formNamee,
            isNotifyAudit: "Yes",

            victimid: rowDataAudit[0].userid,

            userid: stateRedux.onLoginSignOff.userid,

            victimName: rowDataAudit[0].name,
            userName: stateRedux.onLoginSignOff.name,
            acknowledged: "No",
            recurringReamkrs: "",
            statusStage: 1,
            taskStatus: false,

            auditlogArray: JSON.stringify({
              Patients: statementPP,
              PatientsPar: statementPPP,
              insurance: statementIP,
              insurancePar: statementPI,
              Adjustments: statementA,
              AdjustmentsPar: statementPIP,
              DenialAmount: statementPA,
              DenialAmountPar: statementDA,

              Remarks: popAudit?.Remarks,
            }),
          };

          overAllLog(NotifyObj)
            .then((res) => {
            //  socket.emit("overAllnotificationFromTl", NotifyObj);
              toast.info("Record Saved Successfully");
            })
            .catch((err) => {});
        }
      } else if (flagIndi == "AccForm") {
     
        let res = await comparsion();
        if (res == false) {
          let statementPP = generateChangeStatement(
            "Recieved Claims",
            prevstate.recievedAmountCC,
            popAudit.recievedAmountCC
          );
          let statementPPP = generateChangeStatement(
            "Worked Claims",
            prevstate.recievedAmount,
            popAudit.recievedAmount
          );
          let statementIP = generateChangeStatement(
            "Pending Claims",
            prevstate.pending,
            popAudit.pending
          );

          let NotifyObj = {
            notifyName: "insertAuditOverAll",

            Dos: prevstate.Dos,
            id: state.id,
            pid: practiceId,
            sdate: date,

            formName: formNamee,
            isNotifyAudit: "Yes",

            victimid: rowDataAudit[0].userid,

            userid: stateRedux.onLoginSignOff.userid,

            victimName: rowDataAudit[0].name,
            userName: stateRedux.onLoginSignOff.name,
            acknowledged: "No",
            recurringReamkrs: "",
            statusStage: 1,
            taskStatus: false,

            auditlogArray: JSON.stringify({
              recievedAmountCC: statementPP,
              recievedAmount: statementPPP,
              pending: statementIP,
              // AmountDosValCC: statementPI,
              // AmountDosVal: statementPIP,
              // pendingAmount: statementPA,

              Remarks: popAudit?.Remarks,
            }),
          };
          overAllLog(NotifyObj)
            .then((res) => {
           //   socket.emit("overAllnotificationFromTl", NotifyObj);
              toast.info("Record Saved And Send Successfully");

              // CloseAuditRemark(false);
              setState((prev) => ({ ...prev, loading: false }));
            })
            .catch((err) => {
              setState((prev) => ({ ...prev, loading: false }));
            });
        }
      } else if (flagIndi == "PatState") {
        let res = await comparsion();
        if (res == false) {
          let statementPP = generateChangeStatement(
            "Received",
            prevstate.recievedAmountCC,
            popAudit.recievedAmountCC
          );
          let statementPPP = generateChangeStatement(
            "Verified",
            prevstate.recievedAmount,
            popAudit.recievedAmount
          );
          let statementIP = generateChangeStatement(
            "Rejected",
            prevstate.internalNonVerify,
            popAudit.internalNonVerify
          );

          let statementPI = generateChangeStatement(
            "Received Amount",
            prevstate.AmountDosValCC,
            popAudit.AmountDosValCC
          );

          let statementPIP = generateChangeStatement(
            "Worked Amount",
            prevstate.AmountDosVal,
            popAudit.AmountDosVal
          );
          let statementPA = generateChangeStatement(
            "Pending Amount",
            prevstate.pendingAmount,
            popAudit.pendingAmount
          );
          let NotifyObj = {
            notifyName: "insertAuditOverAll",

            Dos: prevstate.Dos,
            id: state.id,
            pid: practiceId,
            sdate: date,

            formName: formNamee,
            isNotifyAudit: "Yes",

            victimid: rowDataAudit[0].userid,

            userid: stateRedux.onLoginSignOff.userid,

            victimName: rowDataAudit[0].name,
            userName: stateRedux.onLoginSignOff.name,
            acknowledged: "No",
            recurringReamkrs: "",
            statusStage: 1,
            taskStatus: false,

            auditlogArray: JSON.stringify({
              recievedAmountCC: statementPP,
              recievedAmount: statementPPP,
              internalNonVerify: statementIP,
              AmountDosValCC: statementPI,
              AmountDosVal: statementPIP,
              pendingAmount: statementPA,

              Remarks: popAudit?.Remarks,
            }),
          };
          overAllLog(NotifyObj)
            .then((res) => {
          //    socket.emit("overAllnotificationFromTl", NotifyObj);
              toast.info("Record Saved And Send Successfully");

              // CloseAuditRemark(false);
              setState((prev) => ({ ...prev, loading: false }));
            })
            .catch((err) => {
              setState((prev) => ({ ...prev, loading: false }));
            });
        }
      } else {
        let res = await comparsion();
        if (res == false) {
          setState((prev) => ({ ...prev, loading: true }));

          let statementR = generateChangeStatement(
            "ReceivedClaims",
            prevstate.ReceivedClaims,
            popAudit.ReceivedClaims
          );
          let statementWC = generateChangeStatement(
            "WorkedClaims",
            prevstate.WorkedClaims,
            popAudit.WorkedClaims
          );
          let statementPC = generateChangeStatement(
            "PendingClaims",
            prevstate.PendingClaims,
            popAudit.PendingClaims
          );

          let statementRA = generateChangeStatement(
            "ReceivedAmount",
            prevstate.ReceivedAmount,
            popAudit.ReceivedAmount
          );
          let statementWA = generateChangeStatement(
            "WorkedAmount",
            prevstate.WorkedAmount,
            popAudit.WorkedAmount
          );
          //  let statementPA = generateChangeStatement("PendingAmount", prevstate.PendingAmount, popAudit.PendingAmount);

          let NotifyObj = {
            notifyName: "insertAuditOverAll",

            Dos: prevstate.Dos,
            id: state.id,
            pid: practiceId,
            sdate: date,

            formName: formNamee,
            isNotifyAudit: "Yes",

            victimid: rowDataAudit[0].userid,

            userid: stateRedux.onLoginSignOff.userid,

            victimName: rowDataAudit[0].name,
            userName: stateRedux.onLoginSignOff.name,
            acknowledged: "No",
            recurringReamkrs: "",
            statusStage: 1,
            taskStatus: false,

            auditlogArray: JSON.stringify({
              ReceivedClaims: statementR,
              WorkedClaims: statementWC,
              PendingClaims: statementPC,
              ReceivedAmount: statementRA,
              WorkedAmount: statementWA,

              Remarks: popAudit?.Remarks,
            }),
          };
          //handle socket

          overAllLog(NotifyObj)
            .then((res) => {
           //   socket.emit("overAllnotificationFromTl", NotifyObj);
              toast.info("Record Saved And Send Successfully");

              // CloseAuditRemark(false);
              setState((prev) => ({ ...prev, loading: false }));
            })
            .catch((err) => {
              setState((prev) => ({ ...prev, loading: false }));
            });
        } else {
          toast.info("Values are not change", { position: "top-left" });
        }
      }
    }
  };

  console.log("state.isShowMessageToast", state.isShowMessageToast);
  const messagee = (
    <>
      {
        <Message showIcon type={"info"} closable>
          {`Warning`}: This Form is already saved and notify
        </Message>
      }
    </>
  );

  console.log("statestate", state?.holdOverAllExistingLogs);

  return (
    <>
      {state.isShowMessageToast == true ? (
        <ToasterRsuite message="This is a toast notification" />
      ) : null}
      {state.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      <Drawer
        size={"full"}
        open={true}
        style={{ zIndex: 9999 }}
        onClose={() => CloseAuditRemark(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Audit</Drawer.Title>

          <Drawer.Actions>
            {
            state?.isNotifyAudit === "Yes" &&
            helpingObject?.tabName === "ClaimLevel" ? (
              ""
            ) : helpingObject?.tabName !== "ClaimLevel" &&
              
              state?.holdOverAllExistingLogs && state?.holdOverAllExistingLogs!=null&&  Array.isArray(state.holdOverAllExistingLogs) && 
              state.holdOverAllExistingLogs[0]?.isNotifyAudit === "Yes" ? (
              ""
            ) : (
              <Button
                onClick={
                  helpingObject?.tabName == "ClaimLevel"
                    ? () => insertAuditRemarkss(false)
                    : () => insertOverAllAuditLog(false)
                }
                style={{
                  height: "33px",
                  backgroundColor: "#0E7490",
                  color: "white",
                }}
              >
                Save
              </Button>
            )}

            {state?.isNotifyAudit === "Yes" &&
            helpingObject?.tabName === "ClaimLevel" ? (
              ""
            ) : helpingObject?.tabName !== "ClaimLevel" &&state?.holdOverAllExistingLogs!=null && 
              state?.holdOverAllExistingLogs[0]?.isNotifyAudit === "Yes" ? (
              ""
            ) : (
              <Button
                onClick={
                  helpingObject?.tabName == "ClaimLevel"
                    ? () => insertAuditRemarkss(true)
                    : () => insertOverAllAuditLog(true)
                }
                style={{
                  height: "33px",
                  backgroundColor: "#0E7490",
                  color: "white",
                }}
              >
                Save And notify
              </Button>
            )}

            {/* <Button onClick={() => CloseAuditRemark(false)}>Cancel</Button> */}
          </Drawer.Actions>
          <br></br>
        </Drawer.Header>

        <br></br>

        <Drawer.Body>
          {state.isNotifyAudit == "Yes" && helpingObject.tabName == "ClaimLevel"
            ? messagee
            : null}
          {helpingObject.tabName !== "ClaimLevel" && state.holdOverAllExistingLogs!=null &&
          state.holdOverAllExistingLogs[0]?.isNotifyAudit === "Yes"
            ? messagee
            : null}
          <PopupRemarksAuditCharge
            helpingObject={helpingObject}
            sethelpingObj={sethelpingObj}
            popAudit={popAudit}
            setAuditPop={setAuditPop}
            setState={setState}
            state={state}
            DataDos={prevstate}
            flagIndi={flagIndi}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
}
