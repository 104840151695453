import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
 
  
 
  
 

export default function DoughnutChart({data,options}) {
 
  console.log(data,'data For party')
    
  return (
    <div className='tw-h-[370px] tw-ml-32'>
    <Doughnut  data={data}  options={{
        responsive: true,
        maintainAspectRatio: true,
      }} />
      </div>
  )
}

