import { Schema } from 'rsuite';


//Required Validation Model
export const createValidationSchema = (fields) => {
  const schema = Schema.Model(
    fields.reduce((result, { fieldName, rules,type }) => {
      console.log('type',type)
      return {
        ...result,
        [fieldName]: type.typeError.isRequired((value, data) => {
          if (!value) {
            return rules.requiredMessage;
          }
          return true;
        }),
      };
    }, {})
  );

  return schema;
};
