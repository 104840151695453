import React, { useState, useEffect } from "react";
import UserAuditTable from "../../../Audit/userTaskViewTable";
import ChargeTypeRemarksPop from "../ChargeEntry/DrawerChargeEntry";
import Dateoofservice from "../../../../Common/Dateoofservice";

export default function PaymentAudit({
  practiceId,
  date,
  setHelpingState,
  helpingStatem,
  loading,
  tableState,
  setDosArray,

  dosArray,
}) {
  let obj = {
    recordEob: [],
    recordEra: [],
    combineRecord: [],
    isToggleRemarks: false,
    Dos: "",
    isEobDosOpen: false,
    isEraDosOpen: false,

    Patients:"",
    PatientsPar:"",
    insurance:"",
    insurancePar:"",
    Adjustments:"",
    AdjustmentsPar:"",
    DenialAmount:"",
    DenialAmountPar:""





  };
  const [state, setState] = useState(obj);
  const [renderEffect, setRenderEffect] = useState(false);
  useEffect(() => {
    let paresedArray2;
    let parsedEra;
    let simp =
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEob == "" ||
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEob == null;
    let simp2 =
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra == "" ||
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra == null;
    if (simp) {
      setState((prev) => ({ ...prev, recordEob: [] }));
    } else {
      try {
        paresedArray2 = JSON.parse(
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEob || []
        );

        console.log("The string contains a valid JSON object.");
      } catch (error) {
        paresedArray2 =
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEob;
      }

      setState((prev) => ({ ...prev, recordEob: paresedArray2 }));
    }
    if (simp2) {
      setState((prev) => ({ ...prev, recordEra: [] }));
    } else {
      try {
        parsedEra = JSON.parse(
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra || []
        );
      } catch (error) {
        parsedEra = helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra;
      }

      setState((prev) => ({ ...prev, recordEra: parsedEra }));
    }
    console.log("paresedArray2", paresedArray2, "parsedEra", parsedEra);
  }, [helpingStatem.holdSubDosTableData]);
  useEffect(() => {
    let res = FuncToJoinTwoArrays(state.recordEob, state.recordEra);
    setState((prev) => ({ ...prev, combineRecord: res }));
  }, [state.recordEob, state.recordEra]);

  const CloseAuditRemark = (val) => {
    setState((prev) => ({ ...prev, isToggleRemarks: val }));
  };

  const FuncToJoinTwoArrays = (date, date2) => {
   
    const result = [];

    // Combine dates from both arrays into a single array for comparison
    const allDates = [...date, ...date2];

    // Create a set of unique dates
    const uniqueDates = new Set(allDates.map((item) => item.date));

    // Iterate over the unique dates and calculate sums for each date
    for (const d of uniqueDates) {
      const matchedDates1 = date.filter((item) => item.date === d);
      const matchedDates2 = date2.filter((item) => item.date === d);
      const partiallyinsuranceSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.insurancePar),
        0
      );

      const insuranceSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.insurance),
        0
      );
      const patientsSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.Patients),
        0
      );
      const partiallypatientsSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.PatientsPar),
        0
      );
      const adjustmentsSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.Adjustments),
        0
      );
      const partiallyadjustmentsSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.AdjustmentsPar),
        0
      );
      const amountdenialSumPar = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.DenialAmountPar),
        0
      );
      const amountdenialSumPar2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.DenialAmountPar),
        0
      );

      const amountdenialSum = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.DenialAmount),
        0
      );
      const partiallyinsuranceSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.insurancePar),
        0
      );

      const insuranceSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.insurance),
        0
      );
      const patientsSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.Patients),
        0
      );
      const partiallypatientsSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.PatientsPar),
        0
      );
      const adjustmentsSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.Adjustments),
        0
      );
      const partiallyadjustmentsSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.AdjustmentsPar),
        0
      );

      const amountdenialSum2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.DenialAmount),
        0
      );

      const newObj = {
        date: d,
        insurance: insuranceSum + insuranceSum2,
        Patients: patientsSum + patientsSum2,
        Adjustments: adjustmentsSum + adjustmentsSum2,
        insurancePar:partiallyinsuranceSum+partiallyinsuranceSum2,
        partiallyPatient:partiallypatientsSum+partiallypatientsSum2,
        partiallyAdjustments:partiallyadjustmentsSum+partiallyadjustmentsSum2,
        deniedAmount:amountdenialSum+amountdenialSum2,
        partiallydeniedAmount:amountdenialSumPar+amountdenialSumPar2
        
      };

      result.push(newObj);
    }

    return result;
  };

  const onShowTable = (rowData) => {
    let matchedArray = tableState.filter(
      (item) => item.userid === String(rowData.userid)
    );
    setHelpingState((prev) => ({
      ...prev,
      isSwitchTable: 2,
      holdSubDosTableData: matchedArray,
    }));
  };
  const onShowRemakrs = (rowData) => {
   

    setState((prev) => ({ ...prev, isToggleRemarks: true, Dos: rowData.date ,
      Patients:rowData.Patients,
      PatientsPar:rowData.PatientsPar,
      insurance:rowData.insurance,
      insurancePar:rowData.insurancePar,
      Adjustments:rowData.Adjustments,
      AdjustmentsPar:rowData.AdjustmentsPar,
      DenialAmount:rowData.DenialAmount,
      DenialAmountPar:rowData.DenialAmountPar








    }));
  };

  const onShowDos = (val) => {
    setState((prev) => ({ ...prev, isEobDosOpen: true }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: true }));
  };
  const onShowDosEra = () => {
    setState((prev) => ({ ...prev, isEraDosOpen: true }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: true }));
  };

  const MainTable = [
    { colName: "User", flexGrow: 1.9, dataKey: "name", rowSpan: 1 },
    {
      colName: `Patient Payment`,
      flexGrow: 1.9,
      dataKey: "patientsp",
      rowSpan: 1,
    },
    {
      colName: `Ins. Payment`,
      flexGrow: 1.9,
      dataKey: "insurancep",
      rowSpan: 1,
    },
    {
      colName: `Adjustment`,
      flexGrow: 1.9,
      dataKey: "adjustmentp",
      rowSpan: 1,
    },
    {
      colName: `Payments Total`,
      flexGrow: 1.9,
      dataKey: "total",
      rowSpan: 1,
    },
    {
      colName: `Amount Denied`,
      flexGrow: 1.9,
      dataKey: "denialsamount",
      rowSpan: 1,
    },
    {
      colName: "View By Dos",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowTable, btnType: "View" }],

      editRow: true,
    },
  ];

  const DosWiseTable = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Patient Payment`,
      flexGrow: 1.9,
      dataKey: "Patients",
      rowSpan: 1,
    },
    {
      colName: `Patient Partially Payment`,
      flexGrow: 1.9,
      dataKey: "partiallyPatient",
      rowSpan: 1,
    },
    
    {
      colName: `Ins. Payment`,
      flexGrow: 1.9,
      dataKey: "insurance",
      rowSpan: 1,
    },
    {
      colName: `Ins.Partially Payment`,
      flexGrow: 1.9,
      dataKey: "insurancePar",
      rowSpan: 1,
    },
    {
      colName: `Adjustment`,
      flexGrow: 1.9,
      dataKey: "Adjustments",
      rowSpan: 1,
    },
    {
      colName: `Adjustment Partially`,
      flexGrow: 1.9,
      dataKey: "partiallyAdjustments",
      rowSpan: 1,
    },
    {
      colName: `Amount Denied`,
      flexGrow: 1.9,
      dataKey: "deniedAmount",
      rowSpan: 1,
    },
    {
      colName: `Amount Partially Denied`,
      flexGrow: 1.9,
      dataKey: "partiallydeniedAmount",
      rowSpan: 1,
    },
    // {
    //   colName: "Audit",
    //   flexGrow: 1.6,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   actions: [{ label: "D", onClick: onShowRemakrs, btnType: "View" }],

    //   editRow: true,
    // },
  ];

  const EobDosWiseTable = [
    { colName: "EOB`s DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: "EOB`s Patient Payment",
      flexGrow: 1.9,
      dataKey: "Patients",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Partially Patient Payment",
      flexGrow: 1.9,
      dataKey: "PatientsPar",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Ins. Payment",
      flexGrow: 1.9,
      dataKey: "insurance",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Partially Ins.Payment",
      flexGrow: 1.9,
      dataKey: "insurancePar",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Adjustment",
      flexGrow: 1.9,
      dataKey: "Adjustments",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Partially Adjustment",
      flexGrow: 1.9,
      dataKey: "AdjustmentsPar",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Denied Amount",
      flexGrow: 1.9,
      dataKey: "DenialAmount",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Partially Denied Amount",
      flexGrow: 1.9,
      dataKey: "DenialAmountPar",
      rowSpan: 1,
    },
    {
      colName: "Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "D", onClick: onShowRemakrs, btnType: "View" },
        { label: "D", onClick: onShowDos, btnType: "info" },
      ],

      editRow: true,
    },
  ];
  const renderr = (val) => {
    setRenderEffect(val);
  };

  const EraDosWiseTable = [
    { colName: "ERA`s DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: "ERA`s Patient Payment",
      flexGrow: 1.9,
      dataKey: "Patients",
      rowSpan: 1,
    },
    {
      colName: "ERA`s Partially Patient Payment",
      flexGrow: 1.9,
      dataKey: "PatientsPar",
      rowSpan: 1,
    },
    {
      colName: "ERA`s Ins. Payment",
      flexGrow: 1.9,
      dataKey: "insurance",
      rowSpan: 1,
    },
    {
      colName: "ERA`s Partially Ins.Payment",
      flexGrow: 1.9,
      dataKey: "insurancePar",
      rowSpan: 1,
    },
    {
      colName: "ERA`s Adjustment",
      flexGrow: 1.9,
      dataKey: "Adjustments",
      rowSpan: 1,
    },
    {
      colName: "ERA`s Partially Adjustment",
      flexGrow: 1.9,
      dataKey: "AdjustmentsPar",
      rowSpan: 1,
    },
    {
      colName: "EOB`s Denied Amount",
      flexGrow: 1.9,
      dataKey: "DenialAmount",
      rowSpan: 1,
    },
    
    {
      colName: "EOB`s Partially Denied Amount",
      flexGrow: 1.9,
      dataKey: "DenialAmountPar",
      rowSpan: 1,
    },
    {
      colName: "Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "D", onClick: onShowRemakrs, btnType: "View" },
        { label: "D", onClick: onShowDosEra, btnType: "info" },
      ],

      editRow: true,
    },
  ];

  const handleClose = () => {
    setState((prev) => ({ ...prev, isEobDosOpen: false }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: false }));
  };

  const handleCloseEra = () => {
    setState((prev) => ({ ...prev, isEraDosOpen: false }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: false }));
  };
  const getRequriedPara=()=>{

  }

  console.log('state.combineRecord',state.recordEob)

  return (
    <>
      {state.isEraDosOpen ? (
        <Dateoofservice
          DenialClaimBreakup={true}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={state.recordEra}
          handleClose={handleCloseEra}
          claimHeading={"Claims Received"}
          claimAmount={"Paid"}
          columnHeader1={"Denied"}
          columnHeader2={"Total Worked Claims"}
          columnHeader3={"Pending Claims"}
          onlyShowTable={true}
          SecondTableName={"Eob"}
          flag={"Eob"}
        />
      ) : state.isEobDosOpen ? (
        <Dateoofservice
          DenialClaimBreakup={true}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={state.recordEob}
          handleClose={handleClose}
          claimHeading={"Claims Received"}
          claimAmount={"Paid"}
          columnHeader1={"Denied"}
          columnHeader2={"Total Worked Claims"}
          columnHeader3={"Pending Claims"}
          onlyShowTable={true}
          SecondTableName={"Eob"}
          flag={"Eob"}
        />
      ) : (
        <>
          {state.isToggleRemarks == true ? (
            <>
              <ChargeTypeRemarksPop
              flagIndi={"Payments"}
                prevstate={state}
                formNamee={"Payments"}
                practiceId={practiceId}
                date={date}
                CloseAuditRemark={CloseAuditRemark}
                rowDataAudit={helpingStatem.holdSubDosTableData}
              />
            </>
          ) : null}
          <div className="row">
            <div class="col-sm-6 col-lg-12 ">
              {helpingStatem.isSwitchTable == 2 ? (
                <>
                  <UserAuditTable
                  getRequriedPara={getRequriedPara}
                    rowHeight={48}
                    // handleChange={handleChange}
                    // handleEditState={handleEditState}
                    dateForgrid={state.combineRecord ? state.combineRecord : []}
                    // isPopupOpen={isPopupOpen}
                    Array={DosWiseTable}
                    rowShown={5}
                    loader={helpingStatem.loading}
                  />
                </>
              ) : helpingStatem.isSwitchTable == 3 ? (
                <>
                  <UserAuditTable
                   getRequriedPara={getRequriedPara}
                    rowHeight={48}
                    // handleChange={handleChange}
                    // handleEditState={handleEditState}
                    dateForgrid={state.recordEob ? state.recordEob : []}
                    // isPopupOpen={isPopupOpen}
                    Array={EobDosWiseTable}
                    rowShown={5}
                    loader={helpingStatem.loading}
                  />

                  <UserAuditTable
                   getRequriedPara={getRequriedPara}
                    rowHeight={48}
                    // handleChange={handleChange}
                    // handleEditState={handleEditState}
                    dateForgrid={state.recordEra ? state.recordEra : []}
                    // isPopupOpen={isPopupOpen}
                    Array={EraDosWiseTable}
                    rowShown={5}
                    loader={helpingStatem.loading}
                  />
                </>
              ) : helpingStatem.isSwitchTable == 1 ? (
                <UserAuditTable
                getRequriedPara={getRequriedPara}
                  rowHeight={48}
                  // handleChange={handleChange}
                  // handleEditState={handleEditState}
                  dateForgrid={tableState}
                  // isPopupOpen={isPopupOpen}
                  Array={MainTable}
                  rowShown={5}
                  loader={helpingStatem.loading}
                />
              ) : null}
            </div>
          </div>

          <div className="row">
            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 "></div>
            {/* <div class="col-sm-1 col-lg-3 mt-2">
    <div class="form-group ">
      <div className="d-flex justify-content-end mt-4">
        <button
          type="button"
          className="btnClear mr-1 "
          onClick={handleClose}
        >
          Back
        </button>
        
       
      </div>
    </div>
  </div> */}
          </div>
        </>
      )}
    </>
  );
}
