import React from "react";
const moment = require('moment');
export default function NotifcationComp({setStateHelping,searchOverAllbyonlyvictimid, notification,stateHelping,DrwaerOnNotification }) {
  //   let dispatch = useDispatch();
  //   const { NotifyForCardExpires } = useSelector((state) => state);

  const clearOnClickNotification = (selectedIndex) => {
    // NotifyForCardExpires &&
    //   NotifyForCardExpires.map((el, index) => {
    //     if (index == selectedIndex) {
    //       dispatch(removeItemFromNotification(selectedIndex));
    //     }
    //   });
  };

  const outputFormat = 'MM/DD/YYYY h:mm A';

  const onCancelNotify=()=>{
    setStateHelping((prev)=>({...prev,isShowNotification:false}))
  }


 
console.log("mpotify thing",stateHelping)
  return (
    <div
      className={
        notification
          ? "dropdown-menu dropdown-menu-right navbar-dropdown preview-list "
          : " dropdown-menu dropdown-menu-right navbar-dropdown preview-list  show"
      }
      aria-labelledby="notificationDropdown"
    >
        <div  style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
     
      backgroundColor: "#0e7490",

      
      marginTop:"-26px" // Add some padding for spacing
    }}>
        <p className="mb-0 font-weight-normal float-left dropdown-header" style={{color:"white"}}>
        Notifications
        <span className="" style={{marginLeft:"29vh"}} onClick={onCancelNotify}>X</span>
      </p>

        </div>
      
      
      <div
        className="list-notifiactions"
        style={{
          maxHeight: "300px",
          width: "100%",
          overflow: "auto",
        }}
      >
        {stateHelping &&
          stateHelping.map((el, index) => {
            return (
              <>
                <a className="dropdown-item preview-item" key={index} onClick={()=>DrwaerOnNotification(true,el)}>
                <div className="preview-thumbnail ">
  <div className="preview-icon bg-success backgroundlightGreen" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <i className="fa fa-eye ml-2 " s aria-hidden="true"></i>
  </div>
</div>

                 
                  
                 

                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                     
                      {el.statusStage==1?`You have notification from ${el.userName}`:el.statusStage==2?`You have notification from ${el.victimName} `:null}
                    </h6>

                    <p className="font-weight-light small-text mb-0 text-muted">
                    {moment.utc(el.created_at).local().format(outputFormat)}
                    </p>
                  </div>

                  <div className="d-flex">
                    <div className="ml-auto"></div>
                  </div>
                </a>
              </>
            );
          })}
      </div>

      <a className="dropdown-item preview-item"></a>
    </div>
  );
}
