
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { tlAdd } from "../../../../Utils/ApiManager";



const AccVerify = ({ record,practiceId, date, formTypeNoForApi }) => {
  const { onLoginSignOff } = useSelector((state) => state);
  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    submittype: "No",
    formtype: "",
    receivedClaims:"",
    workedClaims:"",
    pendingClaims:""
   
  };
  const [state, setState] = useState(payload);

  useEffect(() => {
    if (record) {
      setState((prevResult) => ({
        ...prevResult,
        field1: record?.bamount,
        field2: record?.cworked,
        field3: record?.ramount,
      }));
    }
  }, [record, practiceId]);

  const onclear = () => {
    setState(payload);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      const formDataForSaveApi = new FormData();
      formDataForSaveApi.append("userid", onLoginSignOff.userid);
      formDataForSaveApi.append("sdate", date);
      formDataForSaveApi.append("pid", practiceId);
      formDataForSaveApi.append("formtype", formTypeNoForApi);
      formDataForSaveApi.append("submittype", "No");
      formDataForSaveApi.append("field1", state?.field1);
      formDataForSaveApi.append("field2", state?.field2);
      formDataForSaveApi.append("field3", state?.field3);
      formDataForSaveApi.append("field4", state?.field4);
      formDataForSaveApi.append("field5", state?.field5);

      let resultt = await tlAdd(formDataForSaveApi);
      if (
        resultt?.data &&
        resultt?.data.message === "Record Updated Successfully!"
      ) {
        onclear();
        toast.info("Record Updated Successfully");
      }
      if (
        resultt?.data &&
        resultt?.data.message == "Record Created Successfully!"
      ) {
        onclear();
        toast.info("Record Created Successfully!");
      }
    }
  };

  return (
    <div className="row">
      {" "}
      <div class="col-sm-6 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Received Claims
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field1"
            value={state?.receivedClaims}
            onChange={handelChange}
            disabled
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Worked Claims
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field2"
            value={state.workedClaims}
            onChange={handelChange}
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          pendingClaims
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field3"
            value={state?.pendingClaims}
            onChange={handelChange}
          />
        </div>
      </div>{" "}
      {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            92-120
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field4"
            value={state.field4}
            onChange={handelChange}
          />
        </div>
      </div>{" "} */}
     


      <div class="col-sm-6 col-lg-12 ">
        <div class="form-group ">
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btnClear mr-1 "
              onClick={onclear}
            >
              Clear
            </button>
            <button type="button" className="btnSave"
              onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AccVerify