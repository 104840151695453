import React from "react"
import ReactDropdown from "react-dropdown"
function DenialVerification(){
    return(<>
   
    <div className="row">
    <div class="col-sm-6 col-lg-6">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Amount Denied
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field2"
            placeholder=""
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-6">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Verified
          </label>
          <ReactDropdown
            options={["Yes", "No"]}
            value={"Yes"}
            placeholder="Select an option"
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-12 mt-2 ">
        <div class="form-group mb-2">
          <textarea
            class="form-control inputField"
            placeholder="Comments if any!"
          />
        </div>
      </div>
    </div>
    </>)
}
export default  DenialVerification