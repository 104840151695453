import React, { useEffect, useState } from "react";
import { Panel, Toggle } from "rsuite";

import UserAuditTable from "../../Audit/userTaskViewTable";
import { generateAuditCodingForTl, getUsersName } from "../../../Utils/ApiManager";
import { toast } from "react-toastify";

const AmountVerification = ({ data,setHelpingState }) => {
  let obj = {
    getAllBellUsers:[],
    toggleSubTabName: "chargeEntry",
    isSwitchDosView: false,
    pendingClaimToggleMode: null,
    chargeTotal: null,
    RejectTotal: null,
    AuthTotal: null,
    vobTotal: null,
    receivedClaims: data?.receivedClaims || "",
    workedClaims: data?.workedClaims || "",
    pendingClaims: data?.pendingClaims || "",
    totalOperation: data?.totalOperation || "",
    totalPcTotal: data?.totalPcTotal || "",
    totalProvider: data?.totalProvider || "",
    pid: data?.praticeSelection?.value || undefined,
    dateOfEntry: data?.dateOfEntry || undefined,
  };
  const [helpingState, sethelpingState] = useState(obj);
  const [dataFor, setDataForGrid] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUsersName().then((res) => {
      sethelpingState((prev) => ({ ...prev, getAllBellUsers: res }));
    });
  }, []);

  const convertUseridIntoUserName = async (data) => {
   
    let res = helpingState.getAllBellUsers;

    const mergedData = data.map((user) => {
      const matchingData = res && res.data.length>0 && res?.data.find(
        (item) => item.userid === user.userid
      );
     

      return {
        ...user,
        ...matchingData,
       
      };
    });

    return mergedData;
  };


  useEffect(() => {
    sethelpingState((prev) => ({
      ...prev,
      receivedClaims: data?.receivedClaims || "",
      workedClaims: data?.workedClaims || "",
      pendingClaims: data?.pendingClaims || "",
      totalOperation: data?.totalOperation || "",
      totalPcTotal: data?.totalPcTotal || "",
      totalProvider: data?.totalProvider || "",
      pid: data?.praticeSelection?.value || undefined,
      dateOfEntry: data?.dateOfEntry || undefined,
    }));
  }, [data]);
  const getDataTogenerateAudit = async () => {
    setLoader(true);
    try {
      let obj = {
        pid: helpingState?.pid,
        sdate: helpingState?.dateOfEntry,
      };
      if (obj.pid == undefined || obj.sdate == undefined) {
        toast.error("Please chhose Practice and date");
        setLoader(false);

        return;
      } else {
        setLoader(true);
       

        const response = await generateAuditCodingForTl(obj);
     
       debugger
        let convertedData = await convertUseridIntoUserName(response.data.response);
        const parsedArr = convertedData.map(item => {
          // Parse dosArray string to array of objects
          const dosArray = JSON.parse(item.dosArray);
          
          // Return a new object with the parsed dosArray
          return {
            ...item,
            dosArray
          };
        });
        

        if (response.data.response) {
          setHelpingState((prev)=>({...prev,thisContiansData:parsedArr}))
          setDataForGrid(parsedArr);
        } else {
          setDataForGrid([]);
        }
        setLoader(false);
      }
    } catch (error) {
      console.error("Error generating audit data:", error);
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const dataForGrid = [
    {
      colName: "User Name",
      flexGrow: 1.9,
      dataKey: "name",
      rowSpan: 1,
    },
    {
      colName: "Received Claims",
      flexGrow: 1.9,
      dataKey: "totalReceivedClaims",
      rowSpan: 1,
    },
    {
      colName: "Worked Claims",
      flexGrow: 1.9,
      dataKey: "totalWorkedClaims",
      rowSpan: 1,
    },
    {
      colName: "Pending Claims",
      flexGrow: 1.9,
      dataKey: "totalPendingClaims",
      rowSpan: 1,
    },
    {
      colName: "Operation",
      flexGrow: 1.9,
      dataKey: "totalOperation",
      rowSpan: 1,
    },

   
    {
      colName: "Provider",
      flexGrow: 1.9,
      dataKey: "totalProvider",
      rowSpan: 1,
    },
    {
      colName: "Total",
      flexGrow: 1.9,
      dataKey: "totalPcTotal",
      rowSpan: 1,
    },
    {
      colName: "Action",
      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
      isAllow: false,
   //   isShowToolTip:true
    },
  ];
  const chargeEntryHtml = (
    <>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={helpingState?.receivedClaims}
              //   onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={helpingState?.workedClaims}
              //   onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Claims
            </label>
            <input
              class="form-control inputField"
              name="PendingClaims"
              placeholder=""
              value={helpingState?.pendingClaims}
              //   onChange={handelChange}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
  let pendingSectionhtmlCharge = (
    <>
      <div className="row">
        <div className="mt-4"></div>
        <Panel
          defaultExpanded={true}
          header="Pending Claims Breakup"
          collapsible={true}
        >
          <div className="row mt-4">
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Provider
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="providerVal"
                  placeholder=""
                  disabled
                  value={helpingState.totalProvider}
                  //   onChange={onChangeBreakupClaim}
                />
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Operation
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="operationVal"
                  placeholder=""
                  disabled
                  value={helpingState.totalOperation || 0}
                  //  onChange={onChangeBreakupClaim}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="total"
                  placeholder=""
                  disabled
                  value={helpingState.totalPcTotal}
                  // onChange={onChangeBreakupClaim}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </>
  );
  const onChangePop=(arg)=>{
    debugger
   let result=arg?.dosArray && arg?.dosArray.map(obj => {
    let newObj = {};
    for (let key in obj) {
        newObj[key] = obj[key] === "" ? 0 : obj[key];
    }
    return newObj;
})
    setHelpingState((prev)=>({...prev,
      isShowDetailedTable:true,
      selectedRow:result
    }))
   

  }
  const toggleBreakup = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      sethelpingState((prev) => ({ ...prev, pendingClaimToggleMode: value }));
    } else {
      sethelpingState((prev) => ({ ...prev, pendingClaimToggleMode: null }));
    }
  };
  const getRequriedPara = () => {};

  return (
    <div>
      <div className="mt-4"></div>
      <Panel
        defaultExpanded={true}
        header="Commulative Fields"
        collapsible={true}
      >
        <div className="row mt-4">{chargeEntryHtml} </div>
        {helpingState?.pendingClaimToggleMode == "on" ? (
          <>{pendingSectionhtmlCharge}</>
        ) : null}{" "}
      </Panel>

      <div class="col-sm-6 col-lg-3 mt-2"></div>
      <div class="col-sm-6 col-lg-3 mt-2"></div>
      <div class="col-sm-6 col-lg-3 mt-2"></div>
      <div class="form-group mb-2 ">
        <div className="d-flex justify-content-end ">
          <span className="mr-3 mt-1">
            {" "}
            <Toggle
              checked={helpingState?.pendingClaimToggleMode !== null}
              size="lg"
              checkedChildren="Hide PC Breakup"
              unCheckedChildren="Show PC Breakup"
              onClick={toggleBreakup}
            />
          </span>

          <button
            type="button"
            onClick={getDataTogenerateAudit}
            className="btnClear mr-1 "
          >
            Generate Audit
          </button>
        </div>
        <UserAuditTable
          Array={dataForGrid}
          getRequriedPara={getRequriedPara}
          dateForgrid={data?.thisContiansData}
          loader={loader}
          onChangePop={onChangePop}
        />
      </div>
    </div>
  );
};

export default AmountVerification;
