import Swal from 'sweetalert2'
import { useEffect } from 'react';

const Sweetalertdemo = ({triggerNotify,titleSweet,text,handleCloseSwal,swalType,showDenyButton}) => {
    console.log(titleSweet,'titleSweet')


    useEffect(() => {


      if(triggerNotify && swalType=='info'){
        HandleClick()


      }else if(triggerNotify){
        HandleClick1()

      }
      
     
    }, [triggerNotify])
    
    const HandleClick = () => {
      Swal.fire({
        customClass: {
         
          htmlContainer: 'custom-html-container',
          
        },
       
        title: `${titleSweet}`,
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: true,

        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        width:'50rem',
        allowOutsideClick:false,
        allowEscapeKey:false,
      }).then((result)=>{
        if (result.isConfirmed) {
          handleCloseSwal(true)
       
      } else if (result.isDenied || result.isDismissed) {
          handleCloseSwal(false)
      }
      })
    };
  
    const HandleClick1 = () => {
      Swal.fire({
        title: `${titleSweet}`,
        text: `${text}`,
        icon: 'warning',
        showDenyButton: showDenyButton,
       
        showCancelButton: true,
        confirmButtonColor: '#0e7490',
        
        cancelButtonColor: 'smoke',
        cancelButtonText:'Cancel',
        confirmButtonText: 'Yes',


       
        background:'#f0f9ff',
        
      }).then((result) => {
                 
        if (result.isConfirmed) {
            handleCloseSwal(true)
         
        } else if (result.isDenied ) {
          handleCloseSwal('backToMainScreen')
          
           
        }
        else if(result.isDismissed){
          handleCloseSwal(false)
        

        }
      })
    };
  
    const HandleClick12 = () => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href>Why do I have this issue?</a>'
      });
    };
  
    const HandleClicktop = () => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      });
    };
  
    const HandleClickAutoclose = () => {
      let timerInterval;
      Swal.fire({
        title: 'Auto close alert!',
        html: 'I will close in <b></b> milliseconds.',
        timer: 1000,
        timerProgressBar: true,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
    };
  
    return (
    
       
       <></>
      
    );
  };
  
  export default Sweetalertdemo;