import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { createValidationSchema, createValidationSchemaReq } from "../../../Common/Validation";

import { Schema } from 'rsuite';
import { toast } from "react-toastify";
import moment from "moment/moment";






export const CustomInputGroupWidthButtonC = ({
  value,
  disabled,
  placeholder,
  ...props
}) => (
  <InputGroup {...props} inside style={styles}>
    <Input value={value} disabled />
    <InputGroup.Button>
      <AddOutlineIcon style={{ color: "#0e7490" }} />
    </InputGroup.Button>
  </InputGroup>
);
const styles = {
  marginBottom: 10,
};

const { StringType, NumberType, DateType } = Schema.Types



export const onSaveDosC = (flag, dosArray, callBack, callBack1, callBack2, flagName,clearDate,clearField1,callBack3,callBack4,callBack5,callBack6,callBack7) => {


  let parsedArray = [];
  try {
    parsedArray = JSON.parse(dosArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = dosArray;
  }

  let countAmountdos = 0;
  let  countAmountdosCC=0;
  let recievedAmountCC=0
  let bf=0
  let bfAmount=0
 

  let count = 0;
  parsedArray &&
    parsedArray.map((el) => {
      count += parseFloat(el.recievedAmount);
      countAmountdos += parseFloat(el.AmountDosVal)
      countAmountdosCC+=parseFloat(el.AmountDosValCC)
      recievedAmountCC+=parseFloat(el.recievedAmountCC)
      if (el.bfRow === true) {
        bf = parseFloat(el.recievedAmount) - parseFloat(el.recievedAmountCC);
        bfAmount = parseFloat(el.AmountDosVal) - parseFloat(el.AmountDosValCC);
      }
      
    });
     
  if (flagName == 'specialpayer') {

    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }
  
  
    let  pending=0;
    let recievedAmountCC=0

    
 let ReceivedAmountTotal=0
 let CollectedAmountTotal=0
 let pendingAmountTotal=0
  
    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmountCC);//REC PAT STAT
       
        recievedAmountCC+=parseFloat(el.recievedAmount) //WORKED
        pending+=parseFloat(el.pending)//dENEID


        
  ReceivedAmountTotal+=parseFloat(el.recievedAmountgGen)
  CollectedAmountTotal+=parseFloat(el.collectedAmountgGen)
  pendingAmountTotal+=parseFloat(el.pendinGen)
  

      });
    callBack2((prevResult) => ({
      ...prevResult,
      srecieved: count,
      sworked:recievedAmountCC,
     // spendingval:pending

     ReceivedAmountTotal:ReceivedAmountTotal,
     CollectedAmountTotal:CollectedAmountTotal,
     pendingAmountTotal:pendingAmountTotal



    }))
    callBack1(false)
    callBack((prevResult) => ({
      ...prevResult,
      // srecieved: count,
      // sworked:recievedAmountCC,
     // spendingval:pending


    }))

    return
  }
  if (flagName == 'generalpayer') {

    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }
  
   
    let  pending=0;
    let recievedAmountCC=0

    let ReceivedAmountTotal=0
    let CollectedAmountTotal=0
    let pendingAmountTotal=0
  
    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmountCC);//REC PAT STAT
       
        recievedAmountCC+=parseFloat(el.recievedAmount) //WORKED
        pending+=parseFloat(el.pending)//dENEID
        ReceivedAmountTotal+=parseFloat(el.recievedAmountgGen)
        CollectedAmountTotal+=parseFloat(el.collectedAmountgGen)
        pendingAmountTotal+=parseFloat(el.pendinGen)
      });
      callBack2((prevResult) => ({
        ...prevResult,
        recievedval: count,
        workedval:recievedAmountCC,
        pendingval:pending,
        ReceivedAmountTotal:ReceivedAmountTotal,
        CollectedAmountTotal:CollectedAmountTotal,
        pendingAmountTotal:pendingAmountTotal
  
  
  
      }))
    callBack1(false)
    callBack((prevResult) => ({
      ...prevResult,
      recievedval: count,


    }))

    return
  }
  if (flagName == 'eobs') {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }
  
   
    let  countAmountdosCC=0;
    let recievedAmountCC=0
    let ReceivedAmountTotal=0
    let CollectedAmountTotal=0
    let pendingAmountTotal=0
  
    let count = 0;
    let partiallyAmount=0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmountCC);//REC PAT STAT
       
        countAmountdosCC+=parseFloat(el.AmountDosValCC) //PAID
        recievedAmountCC+=parseFloat(el.recievedAmount)//dENEID
        partiallyAmount+=parseFloat(el.partially)


        ReceivedAmountTotal+=parseFloat(el.recievedAmountgGen)
        CollectedAmountTotal+=parseFloat(el.collectedAmountgGen)
        pendingAmountTotal+=parseFloat(el.pendinGen)
      });
    callBack2((prevResult) => ({
      ...prevResult,
      claimrecieved: count,
      paidclaims:countAmountdosCC,
      denials:recievedAmountCC,
      partiallyPaid:partiallyAmount,
      
      ReceivedAmountTotal:ReceivedAmountTotal,
      CollectedAmountTotal:CollectedAmountTotal,
      pendingAmountTotal:pendingAmountTotal




    }))
    callBack1(false)
    callBack((prevResult) => ({
      ...prevResult,
      claimrecieved: count,


    }))
    clearDate('')
    clearField1('')

    return
  }
  if (flagName == 'vobs') {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }
  
   
    let  countAmountdosCC=0;
    let recievedAmountCC=0
    let worked=0
    let pending=0
  
    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmountCC);//REC PAT STAT
       
        countAmountdosCC+=parseFloat(el.AmountDosValCC) //Portal
        recievedAmountCC+=parseFloat(el.AmountDosVal)//calls
        worked+=parseFloat(el.totalClaimWorked)//worked
        pending+=parseFloat(el.pendingClaim)//pending claims
      });
    callBack2((prevResult) => ({
      ...prevResult,
      recievedval: count,
      portals:countAmountdosCC,
      workedval:worked,
      calls:recievedAmountCC,
      pendingval:pending




    }))
    callBack1(false)
   
    clearDate('')
    clearField1('')

    return
  }
 
  if (flagName == 'paidClaim') {
    callBack2((prevResult) => ({
      ...prevResult,
      paidclaims: count,


    }))
    callBack1(false)
    callBack((prevResult) => ({
      ...prevResult,
      paidclaims: count,


    }))
    clearDate('')
    clearField1('')

    return
  }
  if(flagName=='patStat'){
    
    let countAmountdos = 0;
    let  countAmountdosCC=0;
    let recievedAmountCC=0
    let nonVerfied=0
  
    let count = 0;
    let Adjusted=0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount);
        countAmountdos += parseFloat(el.AmountDosVal)
        countAmountdosCC+=parseFloat(el.AmountDosValCC)
        recievedAmountCC+=parseFloat(el.recievedAmountCC)
        nonVerfied+=parseFloat(el.internalNonVerify)
        Adjusted +=parseFloat(el.Adjusted)
      });
      clearField1(Adjusted)
    callBack(recievedAmountCC) 
    
    callBack1(count) 
    callBack2(countAmountdos)  
    callBack3(nonVerfied) 
    callBack4(countAmountdosCC) //Received Pat stata
    return

  }
  if (flag) {
    callBack2((prevResult) => ({
      ...prevResult,
      recievedval: count,


    }))
    callBack1(false)
    callBack((prevResult) => ({
      ...prevResult,
      recievedval: count,


    }))


  } else {
   
    callBack(count)
    callBack1(false)
    callBack2(countAmountdos)
    callBack3(countAmountdosCC)
    callBack4(recievedAmountCC)
    if (bf === 0 && bfAmount==0) {
     
    }else{
     // callBack6(bf)
     // callBack7(bfAmount)
    }
    
    

  }


};


export const handleEditStateC = async (rowData, dosArray, callBack) => {
  const nextData = Object.assign([], dosArray);
  const activeItem = nextData.find((item) => item.id === rowData.id);

  activeItem.status = activeItem.status ? null : "EDIT";
  callBack(nextData)


}

export const handleChangeC = (id, key, value, event, dosArray, callBack) => {
  let nextData;


  nextData = Object.assign([], dosArray);
  nextData.find((item) => item.id === id)[key] = value;
  callBack(nextData)
  //setDosArray(nextData);
};

// export const onAdddosC = (handledosDate, handleInputdos, dosArray, AmountDosRec, callBack) => {


//   let obj = {
//     date: handledosDate,
//     recievedAmount: handleInputdos,
//     status: null,
//     AmountDosVal: AmountDosRec,
//     id: dosArray.length + 1,
//   };
//   callBack((prevDosArray) => [...prevDosArray, obj])
//   // setDosArray((prevDosArray) => [...prevDosArray, obj]);
// };


export const onAdddosC = async (handledosDate, handleInputdosCC, dosArray, AmountDosRec, callBack, ErrorCallBack, flag,typeFlag,AllBreakupVal,AmountDosCC,handleInputdos,AmountDos,setDosArrayy,internalNonVerify,gridEdit,rowID,bfVal,bfRow,partially,stateAmount,DenialBreakup,eobRef) => {

  let result;
  if(flag=='gp')
  {
    result = dosArray.length > 0 && dosArray.some((el) => el.date === handledosDate && el.payerId === AmountDos?.generalpayerID);

  }
else if(flag=='Eob'){
  result = dosArray.length > 0 && dosArray.some((el) => el.date === handledosDate && el.eobRef === eobRef);

}
else{
  result=dosArray.length>0 && dosArray.some((el)=>el.date===handledosDate)

}

  if(result){
    toast.info("Duplicate record not allowed")

    return
  }
  

  let obj={}
  
  
  if(typeFlag=='payer'){
    let name=flag=='sp'?'scp':'gcp'
  let name1=flag=='sp'?'sca':'gca'
  let name2=flag=='sp'?'scr':'gcr'
  let name3=flag=='sp'?'sct':'gct'
  let name4=flag=='sp'?'scs':'gcs'



  let name5=flag=='sp'?'scpa':'gcpa'
  let name6=flag=='sp'?'scaa':'gcaa'
  let name7=flag=='sp'?'scra':'gcra'
  let name8=flag=='sp'?'scta':'gcta'
  let name9=flag=='sp'?'scsa':'gcsa'
  let totalF=parseFloat(handleInputdosCC)-parseFloat(handleInputdos)
  

    obj={
      payerlabel:flag=='sp'?AmountDos?.specialpayerName:AmountDos?.generalpayerName,
      payerId:flag=='sp'?AmountDos?.specialpayerID:AmountDos?.generalpayerID,
      PayerType:AmountDos?.PayerType,

      date: handledosDate,
      recievedAmount: handleInputdos ||0, //worked
      partiallyPaid:AmountDosCC || 0,
      
     
      status: null,
      recievedAmountCC:handleInputdosCC || 0,//Received amount
      pending:totalF || 0, //pending
      //AMount
      recievedAmountgGen:partially.recAmountGen || 0,
      collectedAmountgGen:partially.CollectAmountGen || 0,
      pendinGen:partially.pendAmuontGen|| 0,

      bfVal:totalF || 0,
     
      id: gridEdit.isEdit?rowID:dosArray.length + 1,
      
      
      [name]: flag=='sp'?AllBreakupVal?.scp:AllBreakupVal?.gcp || 0,
      [name1]: flag=='sp'?AllBreakupVal?.sca:AllBreakupVal?.gca || 0,
      [name2]: flag=='sp'?AllBreakupVal?.scr:AllBreakupVal?.gcr || 0,
      [name3]: flag=='sp'?AllBreakupVal?.sct:AllBreakupVal?.gct || 0,
      [name4]: flag=='sp'?AllBreakupVal?.scs:AllBreakupVal?.gcs ||0,

      [name5]: flag=='sp'?AllBreakupVal?.scpa:AllBreakupVal?.gcpa || 0,
      [name6]: flag=='sp'?AllBreakupVal?.scaa:AllBreakupVal?.gcaa || 0,
      [name7]: flag=='sp'?AllBreakupVal?.scra:AllBreakupVal?.gcra || 0,
      [name8]: flag=='sp'?AllBreakupVal?.scta:AllBreakupVal?.gcta || 0,
      [name9]: flag=='sp'?AllBreakupVal?.scsa:AllBreakupVal?.gcsa || 0,
      gppc:AllBreakupVal?.gppc || 0,
      gpdA:AllBreakupVal?.gpdA || 0,
      total: flag=='sp'?totalF:totalF || 0,
     
    };
    if(bfRow==true){
      obj.bfRow=true
    }

  }

  else if(typeFlag=='vobs'){
   
    obj = {
      date: handledosDate,//date
     // recievedAmount: handleInputdos,//worked
      status: null,
     
      id: gridEdit.isEdit?rowID:dosArray.length + 1,
      recievedAmountCC:handleInputdosCC || 0,//RECIEVED
      AmountDosValCC:AmountDosRec || 0,//PORTAL 
      AmountDosVal:AmountDos || 0,//calls
      totalClaimWorked:(parseFloat(AmountDosRec)+parseFloat(AmountDos)) || 0,
      pendingClaim:(parseFloat(handleInputdosCC)-(parseFloat(AmountDosRec)+parseFloat(AmountDos)))||0,
      bfVal:(parseFloat(handleInputdosCC)-(parseFloat(AmountDosRec)+parseFloat(AmountDos)) || 0),
      providerVal: AllBreakupVal?.providerVal || 0,
      codingVal: AllBreakupVal?.codingVal || 0,
      credientalingVal: AllBreakupVal?.credientalingVal || 0,
      operationVal: AllBreakupVal?.operationVal || 0,
      EdiVal: AllBreakupVal?.EdiVal || 0,
      total: AllBreakupVal?.total || 0,
      bfVal:AllBreakupVal?.total || 0,
      
     
     }
     if(bfRow==true){
      obj.bfRow=true
    }


  }
  else if(typeFlag=="Eob"){

    obj = {
      eobRef:eobRef,
      date: handledosDate,//date
      recievedAmount: handleInputdos,//deneid
      status: null,
     
      id: gridEdit.isEdit?rowID:dosArray.length + 1,
      recievedAmountCC:handleInputdosCC ||0,//Received claims
      AmountDosValCC:AmountDosRec || 0,//paid
      totalClaimWorked:(parseFloat(handleInputdos)+parseFloat(AmountDosRec)+parseFloat(partially))||0,
      pendingClaim:(parseFloat(handleInputdosCC)-((parseFloat(handleInputdos)+parseFloat(AmountDosRec)+parseFloat(partially))))||0,
      bfVal:(parseFloat(handleInputdosCC)-(parseFloat(handleInputdos)+parseFloat(AmountDosRec)))||0, 
      insurance:AllBreakupVal.insurance || 0,
      Patients:AllBreakupVal.Patients || 0,  
    Adjustments:AllBreakupVal.Adjustments || 0,
    DenialAmount:AllBreakupVal.DenialAmount ||0,
    paymentsTotal:AllBreakupVal.paymentsTotal||0,
    insurancePar:AllBreakupVal.insurancePar||0,
    PatientsPar:AllBreakupVal.PatientsPar||0,
    AdjustmentsPar:AllBreakupVal.AdjustmentsPar||0,
    paymentsTotalPar:AllBreakupVal.paymentsTotalPar||0,
    DenialAmountPar:AllBreakupVal.DenialAmountPar||0,
    //amOUNT
    recievedAmountgGen:stateAmount?.recAmountGen||0,
      collectedAmountgGen:stateAmount?.CollectAmountGen||0,
      pendinGen:stateAmount?.pendAmuontGen||0,
      Provider:DenialBreakup.Provider||0,
      coding:DenialBreakup.coding||0,
      Billing:DenialBreakup?.Billing||0,
      Credientialing:DenialBreakup?.Credientialing||0,
      Patient:DenialBreakup?.Patient||0,
      checkDate:AmountDosCC,
      totalWorkedAmount:AmountDos||0,


    partially:partially||0,
    //NEW tWO fIELD

    PPC:AllBreakupVal?.PPC ||0,
      PPA:AllBreakupVal?.PPA ||0
    
   

     }
     if(bfRow==true){
      obj.bfRow=true
    }
    
  }

 else if(typeFlag=='patStat'){
  

  let total=parseFloat(handleInputdos)+parseFloat(internalNonVerify)
  let value=parseFloat(handleInputdosCC)
  let pendingClaims=value-total

    obj={
      date: handledosDate,
      recievedAmount: handleInputdos ||0, //verified
      Adjusted:AmountDosCC||0,//adjusted
      AmountDosVal: AmountDos||0, //Collected
      status: null,
      recievedAmountCC:handleInputdosCC||0,//Rec statements
      AmountDosValCC:AmountDosRec||0, //Received
      internalNonVerify:internalNonVerify||0,//REJECTED
      id: gridEdit.isEdit?rowID:dosArray.length + 1,
      pendingStat:(parseFloat(handleInputdosCC)-(parseFloat(handleInputdos)+parseFloat(internalNonVerify)))||0,
      pendingAmount:(parseFloat(AmountDosRec)-parseFloat(AmountDos))||0,
      
      providerVal: AllBreakupVal?.providerVal||0,
      payerVal: AllBreakupVal?.payerVal||0,
      otherVal: AllBreakupVal?.otherVal||0,
      operationVal: AllBreakupVal?.operationVal||0,
      patVal: AllBreakupVal?.patVal||0,
      total: AllBreakupVal?.total||0,
      bfVal:pendingClaims,
      bfAmount:(parseFloat(AmountDosRec)-parseFloat(AmountDos))||0
      
     
    };
    if(bfRow==true){
      obj.bfRow=true
    }


  }
 else if(typeFlag=='denied'){

    obj={
      date: handledosDate,
      recievedAmount: handleInputdos||0,
      AmountDosVal: AmountDos||0,
      status: null,
      id: dosArray.length + 1,
      providerVal: AllBreakupVal?.providerVal||0,
      codingVal: AllBreakupVal?.codingVal||0,
      credientialVal: AllBreakupVal?.credientialVal||0,
      billingVal: AllBreakupVal?.billingVal||0,
      patVal: AllBreakupVal?.patVal||0,
      total: AllBreakupVal?.total||0,
      
     
    };
    if(bfRow==true){
      obj.bfRow=true
    }


  }else{
  

    // For charges Rejection and autohrization
   
     let pending;

    // let totalBf=parseFloat(handleInputdosCC)+parseFloat(bfVal)

    // pending = totalBf -= parseFloat(handleInputdos);

    obj = {
      date: handledosDate,
      recievedAmount: handleInputdosCC ||0,
      status: null,
      AmountDosVal: AmountDosRec||0,
      id: gridEdit.isEdit?rowID:dosArray.length + 1,
      recievedAmountCC:handleInputdos||0,
      AmountDosValCC:AmountDos||0,
      providerVal: AllBreakupVal?.providerVal||0,
      codingVal: AllBreakupVal?.codingVal||0,
      credientalingVal: AllBreakupVal?.credientalingVal||0,
      operationVal: AllBreakupVal?.operationVal||0,
      EdiVal: AllBreakupVal?.EdiVal||0,
      total: AllBreakupVal?.total||0,
      bfVal:AllBreakupVal?.total||0,
      bfAmount:(parseFloat(AmountDosRec)-parseFloat(AmountDos))||0,
      pendingAmount:(parseFloat(AmountDosRec)-parseFloat(AmountDos))||0
      

      


    }
    if(flag=='charge'){
      obj.isDemoGraphic=typeFlag
    }
    
    if(bfRow==true){
      obj.bfRow=true
    }

    //bf val

  
   
  //  internalNonVerify(pending)
    
  }


  const validationFieldsForEob= [
    {
      fieldName: 'recievedAmount',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    },
    
    {
      fieldName: 'date',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'checkDate',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'recievedAmountCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'AmountDosValCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'recievedAmountgGen',
      rules: { requiredMessage: 'recievedAmountgGen is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'recievedAmountgGen',
      rules: { requiredMessage: 'recievedAmountgGen is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'collectedAmountgGen',
      rules: { requiredMessage: 'recievedAmountgGen is required.' },
      type: { typeError: StringType() }


    },
    
     
     
    
  ];
 

  const validationFieldsForPatStat= [
    {
      fieldName: 'recievedAmount',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    },
    {
      fieldName: 'AmountDosVal',
      rules: { requiredMessage: 'AmountDosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'date',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'recievedAmountCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'AmountDosValCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
     {
      fieldName: 'internalNonVerify',
      rules: { requiredMessage: 'internal Non verify is required.' },
      type: { typeError: StringType() }
    }
     
    // Add more fields and their rules as needed
  ];

  const validationFields = [
    {
      fieldName: 'recievedAmount',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    },
    {
      fieldName: 'AmountDosVal',
      rules: { requiredMessage: 'AmountDosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'date',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: DateType() }


    },
    {
      fieldName: 'recievedAmountCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
    {
      fieldName: 'AmountDosValCC',
      rules: { requiredMessage: 'Amount DosVal is required.' },
      type: { typeError: StringType() }


    },
  
    // Add more fields and their rules as needed
  ];

  const validationFieldsVobs = [
    {
      fieldName: 'recievedAmountCC',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    },
    {
      fieldName: 'AmountDosValCC',
      rules: { requiredMessage: 'Portal amount is required.' },
      type: { typeError: StringType() }

    },
    {
      fieldName: 'AmountDosVal',
      rules: { requiredMessage: 'Calls amount is required.' },
      type: { typeError: StringType() }

    },
   
    {
      fieldName: 'date',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: DateType() }


    },
    // Add more fields and their rules as needed
  ];
  const validationFieldsPayer = [
    {
      fieldName: 'recievedAmountCC',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    },
    {
      fieldName: 'recievedAmount',
      rules: { requiredMessage: 'Worked amount is required.' },
      type: { typeError: StringType() }

    },
   
   
    {
      fieldName: 'date',
      rules: { requiredMessage: 'Date is required.' },
      type: { typeError: DateType() }


    },
    // Add more fields and their rules as needed
  ];

 


  const validationSchema = await createValidationSchema(typeFlag=='payer'?validationFieldsPayer:typeFlag=='Eob'?validationFieldsForEob:typeFlag=='patStat'?validationFieldsForPatStat:flag=='vobs'?validationFieldsVobs:validationFields);


  const errors = validationSchema.check(obj);
  console.log('errors',errors)
  if(typeFlag=='Eob'){
   
    if (errors?.recievedAmount?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
  
      }));
  
    }
    if (errors?.date?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }
    if (errors?.checkDate?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        checkDate: true,
  
      }))
  
    }
    if (errors?.recievedAmountCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmountCC: true,
  
      }))
  
    }
    if (errors?.AmountDosValCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosValCC: true,
  
      }))
  
    }

  }
  else if(typeFlag=='payer'){
    if (errors?.recievedAmountCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmountCC: true,
  
      }));
  
    }
    if (errors?.recievedAmount?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
  
      }));
  
    }
   
    if (errors?.date?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }
  }
  else if(flag=='vobs'){
    if (errors?.recievedAmountCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmountCC: true,
  
      }));
  
    }
    if (errors?.AmountDosValCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosValCC: true,
  
      }));
  
    }
    if (errors?.AmountDosValCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosVal: true,
  
      }));
  
    }
    if (errors?.date?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }

  }else{
    if (errors?.AmountDosVal?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosVal: true,
  
      }));
  
  
    }
    if (errors?.recievedAmount?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
  
      }));
  
    }
    if (errors?.date?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }
    if (errors?.recievedAmountCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmountCC: true,
  
      }))
  
    }
    if (errors?.AmountDosValCC?.hasError) {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosValCC: true,
  
      }))
  
    }
 //fOR pAT sTAT

    if (errors?.internalNonVerify?.hasError && typeFlag=='patStat') {
      ErrorCallBack((prevErrorModel) => ({
        ...prevErrorModel,
        internalNonVerify: true,
  
      }))
  
    }
    

  }
  if(typeFlag=='patStat'){
    if (!errors.recievedAmount.hasError && !errors.AmountDosVal.hasError && !errors.date.hasError && !errors?.recievedAmountCC?.hasError && !errors?.AmountDosValCC?.hasError && !errors?.internalNonVerify?.hasError) {
      const matchingIndex = dosArray.length>0? dosArray && dosArray.findIndex(item => item.date === obj.date && item.bfRow):-1;
    
      if(matchingIndex !== -1 && matchingIndex!==false){
        let res=await updateMatchingDateRow(dosArray,obj,matchingIndex)
        callBack((prevDosArray) => res);

      }else{
       // callBack((prevDosArray) => [...(prevDosArray ?? []), obj]);
        
        //  callBack((prevDosArray) => [...prevDosArray, obj]);
    
        callBack((prevDosArray) => {
         
          if (Array.isArray(prevDosArray)) {
            return [...prevDosArray, obj];
          } else {
            return [obj];
          }
        });

        
       

      }
      
     // callBack((prevDosArray) => [...prevDosArray, obj]);
      setDosArrayy((prevDosArray) => [...prevDosArray, obj]);

    }

  }
  else if(typeFlag=='payer'){

    if(!errors.recievedAmount.hasError && !errors.date.hasError && !errors?.recievedAmountCC?.hasError){
      const matchingIndex = (dosArray ==false || dosArray.length==0)?-1 : dosArray && dosArray.findIndex(item => item.date === obj.date && item.bfRow);
      if(matchingIndex !== -1 && matchingIndex!==false){
        let a=[]
        if(dosArray==false){
          a=[]

        }else{
          a=dosArray
        }
        let res=await updateMatchingDateRow(a,obj,matchingIndex)
        callBack((prevDosArray) => res);

      }else{
        callBack((prevDosArray) => {
          if (!Array.isArray(prevDosArray)) {
              prevDosArray = []; // Initialize it as an empty array if not already
          }
          return [...prevDosArray, obj];
      });

      }
    //  callBack((prevDosArray) => [...prevDosArray, obj]);

    }
  }
  else if(typeFlag=='Eob'){
    if (!errors.recievedAmount.hasError && !errors.date.hasError && !errors?.recievedAmountCC?.hasError && !errors?.AmountDosValCC?.hasError &&!errors?.checkDate?.hasError ) {
      
      const matchingIndex = dosArray&& dosArray.findIndex(item => item.date === obj.date && item.bfRow);
      if(matchingIndex !== -1 && matchingIndex!==false){
        let res=await updateMatchingDateRow(dosArray,obj,matchingIndex)
        callBack((prevDosArray) => res);

      }else{
        callBack((prevDosArray) => {
          if (Array.isArray(prevDosArray)) {
            return [...prevDosArray, obj];
          } else {
            return [obj]; // If prevDosArray is not an array, create a new array with obj
          }
        });

      }
     
      // setDosArrayy((prevDosArray) => [...prevDosArray, obj]);

    }

  }

  else if (flag == 'vobs') {
    if (!errors?.recievedAmountCC?.hasError && !errors?.AmountDosValCC?.hasError && !errors?.AmountDosVal?.hasError && !errors?.date?.hasError) {
      const matchingIndex = dosArray&& dosArray.findIndex(item => item.date === obj.date && item.bfRow);
      if(matchingIndex !== -1 && matchingIndex!==false){
        let res=await updateMatchingDateRow(dosArray,obj,matchingIndex)
        callBack((prevDosArray) => res);

      }else{
        callBack((prevDosArray) => {
          if (Array.isArray(prevDosArray)) {
            return [...prevDosArray, obj];
          } else {
            return [obj]; // If prevDosArray is not an array, create a new array with obj
          }
        });

      }
     

    }

  } else {
    if (!errors.recievedAmount.hasError && !errors.AmountDosVal.hasError && !errors.date.hasError && !errors?.recievedAmountCC?.hasError && !errors?.AmountDosValCC?.hasError) {
     
      const matchingIndex = dosArray && dosArray.findIndex(item => item.date === obj.date && item.bfRow);
      if(matchingIndex !== -1 && matchingIndex!==false){
        let res=await updateMatchingDateRow(dosArray,obj,matchingIndex)
        callBack((prevDosArray) => res);

      }else{
       // callBack((prevDosArray) => [...prevDosArray, obj]);
       callBack((prevDosArray) => {
        if (Array.isArray(prevDosArray)) {
          return [...prevDosArray, obj];
        } else {
          return [obj]; // If prevDosArray is not an array, create a new array with obj
        }
      });

      }
      
     
      setDosArrayy((prevDosArray) => [...prevDosArray, obj]);

    }

  }




};
const  updateMatchingDateRow=(arr, obj,matchingIndex)=> {


 
  

 
    arr[matchingIndex] = {
      ...arr[matchingIndex],
      ...obj,
    };
  

  return arr;
}


export const onChangeInputC = (val, callBack, clearError,stopEecutingErrModel) => {
  const { value, name } = val.target;
  if(stopEecutingErrModel=="stopEecutingErrModel"){
    callBack(value)
   

  }else{
    callBack(value)
    clearError((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }))

  }
 


  // sethandleInput(value);
};
export const onChangeDateC = (val, callBack, clearError,dosArrayRecieved) => {


 let date= moment(val).format('YYYY-MM-DD');
  
 
  let result= dosArrayRecieved.lengt>0 && dosArrayRecieved.some((el)=>el.date===date)
  if(result){
    toast.info("Duplicate dates not allowed")
   
    return
  }
  callBack(date)
  clearError((prevErrorModel) => ({
    ...prevErrorModel,
    date: false,
  }))
  //  setdosDate(formattedDate);
};
export const handleCloseC = (callBack, clearError, clearError1, clearError2, clearError3,clearError4,clearError5,clearError6) => {
  callBack(false)
  clearError((prevErrorModel) => ({
    ...prevErrorModel,
    recievedAmount: false,
    AmountDosVal: false,
    date: false
  }))
  clearError1('')
  clearError2('')
  clearError3('')
  clearError4("")
  clearError5("")
  clearError6("")

  //  setDosModel(false);
};
export const onChangeAmountC = (val, callBack, clearError,arg) => {
  if(arg=='stopEecutingErrModel'){
    const { value, name } = val.target;
  callBack(value)
  return

  }
  const { value, name } = val.target;
  callBack(value)
  clearError((prevErrorModel) => ({
    ...prevErrorModel,
    AmountDosVal: false,

  }))
  //setAmountDos(value)

}