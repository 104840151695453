import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "rsuite";
import { DatePicker, Stack } from "rsuite";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import UserAuditTable from "../Components/Audit/userTaskViewTable";
import EditIcon from "@rsuite/icons/Edit";
import { toast } from "react-toastify";

export default function Dateoofservice({
  DenialClaimBreakup,
  isAllowThirdTable,
  bfVal,
  claimHeading1,
  claimAmount1,
  claimHeading3,
  gridEdit,
  columnHeader1,
  columnHeader2,
  columnHeader3,
  columnHeader4,
  isBlockAllhtml,
  changeState,
  flag,
  dosArrayPaid,
  deductArray,

  showTableCheck,
  onlyShowTable,
  AllBreakupVal,
  pendingClaimss,
  setPendingClaimm,
  setdosDate,
  onChangeBreakupClaim,

  handledosDate,
  recievedClaimArraydos,
  dosPendingBreakup,
  handleInputdos,
  AmountDos,
  allowPending,
  practiceId,
  setDosArray,
  errorModel,
  renderr,
  showPortal,
  showCalls,
  showAmount,
  onChangeAmount,
  handleChange,
  handleEditState,
  onSaveDos,
  dosArray,
  onAdddos,
  onChangeInput,
  onChangeDate,
  onCleanDate,
  onChangeCalendarDate,
  handleClose,
  onChangePortals,
  onChangeCalls,
  claimHeading,
  claimAmount,
  renderEffect,
  isPendingSectionOpen,
  setisPendingSectionOpen,
  handleError,
  SecondTableName
}) {
  console.log("hey there", dosArray);
  let onChangeValues;
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  useEffect(() => {
  

    if (allowPending) {
      if (dosPendingBreakup) {
        let { isAllow, onChangeValues } = dosPendingBreakup;
        onChangeValues = onChangeValues;

        let check = isAllow && handleInputdos != null && AmountDos != null;

        if (check) {
          setisPendingSectionOpen(true);
        }
      }
      return;
    }
  }, [handleInputdos, AmountDos, handleInputdos]);

  useEffect(() => {

    
    if (allowPending) {
      if (AmountDos == "ok") {
        if (
          handledosDate &&
          handleInputdos &&
          recievedClaimArraydos.length > 0
        ) {
          let pending;
          let matchedObj1 = deductArray.find(
            (item) => item.date === handledosDate
          );
          let matchedObj = recievedClaimArraydos.find(
            (item) => item.date === handledosDate
          );

          if (matchedObj && matchedObj1) {
            let value = matchedObj.recievedAmount;
            let value1 = matchedObj1.recievedAmount;

            let wholeVal = parseInt(value);
            let additionVal = parseInt(handleInputdos) + parseInt(value1);

            pending = wholeVal -= additionVal;
            setPendingClaimm(pending);
          } else {
            toast.info("Please select correct date of service");
            setdosDate(null);
            return;
          }
        }
        return;
      }
      
     
      if (handledosDate && handleInputdos && recievedClaimArraydos.length > 0) {
        let pending;
        let matchedObj = recievedClaimArraydos.find(
          (item) => item.date === handledosDate
        );

        if (matchedObj) {
          let value = matchedObj.recievedAmount;

          pending = value -= parseInt(handleInputdos);
          setPendingClaimm(pending);
        } else {
          toast.info("Please select correct date of service");
          setdosDate(null);
          return;
        }
      }
    }
  }, [handledosDate, handleInputdos]);

  const handleDelete = (rowData) => {
    let arr = dosArray && dosArray.filter((item) => item.id !== rowData?.id);
    const { flag, state } = setDosArray;
    if (flag == "1") {
      state(arr);
      return;
    }
    if (flag == "2") {
      state((prev) => ({
        ...prev,
        dosArray: arr,
      }));
      return;
    }
    if (flag == "3") {
      return;
    }
  };
  useEffect(() => {
    // if (practiceId == null) {
    //   toast.info("Please Select Practice ");
    // }

    const timeoutId = setTimeout(() => {
      renderr(!renderEffect);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const arr = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `${claimAmount}`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
   
    // {
    //   colName: "Action",
    //   flexGrow: 1.6,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   actions: [
    //     { label: "E", onClick: handleEditState, btnType: "Edit" },
    //     { label: "D", onClick: handleDelete, btnType: "Delete" },
    //   ],
    //   editRow: true,
    // },
    // {
    //   colName: "Action",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
    // {
    //   colName: "Delete",
    //   flexGrow: 1.7,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   editRow: true,
    // },
  ];


  const EobView = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    { colName: "RefNo/CheckNo", flexGrow: 1.9, dataKey: "eobRef", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Worked Amount`,
      flexGrow: 1.7,
      dataKey: "totalWorkedAmount",
      rowSpan: 1,
    },
    {
      colName: `${claimAmount}`,
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `${columnHeader1}`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Partially Paid`,
      flexGrow: 1.9,
      dataKey: "partially",
      rowSpan: 1,
    },
    {
      colName: `${columnHeader2}`,
      flexGrow: 1.9,
      dataKey: "totalClaimWorked",
      rowSpan: 1,
    },
    
    {
      colName: `${columnHeader3}`,
      flexGrow: 1.9,
      dataKey: "pendingClaim",
      rowSpan: 1,
    },
    {
      colName: `Payment ($)`,
      flexGrow: 1.9,
      dataKey: "paymentsTotal",
      rowSpan: 1,
    },
    {
      colName: `Insurance ($)`,
      flexGrow: 1.9,
      dataKey: "insurance",
      rowSpan: 1,
    },
    {
      colName: `Patients ($)`,
      flexGrow: 1.9,
      dataKey: "Patients",
      rowSpan: 1,
    },
    {
      colName: `Adjustments ($)`,
      flexGrow: 1.9,
      dataKey: "Adjustments",
      rowSpan: 1,
    },
    {
      colName: `Denials Amount ($)`,
      flexGrow: 1.9,
      dataKey: "DenialAmount",
      rowSpan: 1,
    },
    
   
    
  ];
  const PayerView = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `${columnHeader1}`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },

    {
      colName: `Payer Type`,
      flexGrow: 1.9,
      dataKey: "PayerType",
      rowSpan: 1,
    },
    {
      colName: `Worked Claims`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Pending Claims`,
      flexGrow: 1.9,
      dataKey: "pending",
      rowSpan: 1,
    },
    {
      colName: `Partially Paid Claims`,
      flexGrow: 1.6,
      dataKey: "partiallyPaid",
      rowSpan: 1,
    },
   
    
   
    
  ];
  const vobView = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Received`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Through Portals`,
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `Through Calls`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `${columnHeader3}`,
      flexGrow: 1.9,
      dataKey: "pendingClaim",
      rowSpan: 1,
    },
    {
      colName: `Total`,
      flexGrow: 1.9,
      dataKey: "totalClaimWorked",
      rowSpan: 1,
    },
    
    
   
    
  ];

  
  const DenailBreakupEob = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Coding`,
      flexGrow: 1.9,
      dataKey: "coding",
      rowSpan: 1,
    },
    {
      colName: `Billing`,
      flexGrow: 1.9,
      dataKey: "Billing",
      rowSpan: 1,
    },
    {
      colName: `Credientialing`,
      flexGrow: 1.9,
      dataKey: "Credientialing",
      rowSpan: 1,
    },
    {
      colName: `Provider`,
      flexGrow: 1.9,
      dataKey: "Provider",
      rowSpan: 1,
    },
    // {
    //   colName: `totalDenial`,
    //   flexGrow: 1.9,
    //   dataKey: "totalDenial",
    //   rowSpan: 1,
    // },
    
    
   
    
  ];
  const vobsArr = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.5,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: "Portals",
      flexGrow: 1.7,
      dataKey: "portals",
      rowSpan: 1,
    },
    {
      colName: "Calls",
      flexGrow: 1.6,
      dataKey: "calls",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];

  const PendingClaimBreakupgrid = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Credientialing",
      flexGrow: 1.9,
      dataKey: "credientalingVal",
      rowSpan: 1,
    },
    { colName: "EDI ", flexGrow: 1.9, dataKey: "EdiVal", rowSpan: 1 },
    {
      colName: "Operation",
      flexGrow: 1.9,
      dataKey: "operationVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];
  let name=flag=='sp'?'scp':'gcp'
  let name1=flag=='sp'?'sca':'gca'
  let name2=flag=='sp'?'scr':'gcr'
  let name3=flag=='sp'?'sct':'gct'
  let name4=flag=='sp'?'scs':'gcs'

  let name5=flag=='sp'?'scpa':'gcpa'
  let name6=flag=='sp'?'scaa':'gcaa'
  let name7=flag=='sp'?'scra':'gcra'
  let name8=flag=='sp'?'scta':'gcta'
  let name9=flag=='sp'?'scsa':'gcsa'


  const payerViewPending = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "No. of Claims Paid", flexGrow: 1.9, dataKey: `${name}`, rowSpan: 1 },
    { colName: "No. of Claims Adjusted", flexGrow: 1.9, dataKey: `${name1}`, rowSpan: 1 },
    {
      colName: "Sent for Resubmission",
      flexGrow: 1.9,
      dataKey: `${name2}`,
      rowSpan: 1,
    },
    { colName: "Sent to Provider", flexGrow: 1.9, dataKey: `${name3}`, rowSpan: 1 },
    {
      colName: "No. of Apeals Sent",
      flexGrow: 1.9,
      dataKey: `${name4}`,
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const payerViewPendingAmount = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "No. of Claims Paid Amount ($)", flexGrow: 1.9, dataKey: `${name5}`, rowSpan: 1 },
    { colName: "No. of Claims Adjusted Amount ($)", flexGrow: 1.9, dataKey: `${name6}`, rowSpan: 1 },
    {
      colName: "Sent for Resubmission Amount ($)",
      flexGrow: 1.9,
      dataKey: `${name7}`,
      rowSpan: 1,
    },
    { colName: "Sent to Provider Amount ($)", flexGrow: 1.9, dataKey: `${name8}`, rowSpan: 1 },
    {
      colName: "No. of Apeals Sent Amount ($)",
      flexGrow: 1.9,
      dataKey: `${name9}`,
      rowSpan: 1,
    },
    { colName: "Total Amount ($)", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];



  const PendingClaimBreakupgridForVob = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Pending", flexGrow: 1.9, dataKey: "Pending", rowSpan: 1 },
  ];
  const PaidClaimBreakupgridForEob = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    {
      colName: "Claims Paid",
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
  ];
  const PendingClaimBreakupgridForEob = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Crediential",
      flexGrow: 1.9,
      dataKey: "credientialVal",
      rowSpan: 1,
    },
    { colName: "Patient End ", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    {
      colName: "Billing",
      flexGrow: 1.9,
      dataKey: "billingVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const PendingClaimBreakupgridForPatStat = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    { colName: "Non Verified", flexGrow: 1.9, dataKey: "recievedAmount", rowSpan: 1 },
    

    { colName: "Patient End", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    { colName: "Operation End", flexGrow: 1.9, dataKey: "operationVal", rowSpan: 1 },
    {
      colName: "Payer End",
      flexGrow: 1.9,
      dataKey: "payerVal",
      rowSpan: 1,
    },
    { colName: "Other ", flexGrow: 1.9, dataKey: "otherVal", rowSpan: 1 },
   
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const PendingClaimBreakupgridForGeneralPayer = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },

    { colName: "Provider", flexGrow: 1.9, dataKey: "providerVal", rowSpan: 1 },
    { colName: "Coding", flexGrow: 1.9, dataKey: "codingVal", rowSpan: 1 },
    {
      colName: "Crediential",
      flexGrow: 1.9,
      dataKey: "credientialVal",
      rowSpan: 1,
    },
    { colName: "Patient End ", flexGrow: 1.9, dataKey: "patVal", rowSpan: 1 },
    {
      colName: "Billing",
      flexGrow: 1.9,
      dataKey: "billingVal",
      rowSpan: 1,
    },
    { colName: "Total", flexGrow: 1.9, dataKey: "total", rowSpan: 1 },
  ];

  const vobsArr1 = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
   
    {
      colName: `${columnHeader1}`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `${claimHeading} `,
      flexGrow: 2.5,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `${columnHeader3}`,
      flexGrow: 1.9,
      dataKey: "total",
      rowSpan: 1,
    },
    
    {
      colName: `${columnHeader2} ($)`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `${claimAmount} ($)`,
      flexGrow: 2.5,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    
   
    
    {
      colName: `${columnHeader4} ($)`,
      flexGrow: 1.9,
      dataKey: "bfAmount",
      rowSpan: 1,
    },

    // {
    //   colName: "Action",
    //   flexGrow: 1.6,
    //   dataKey: "id",
    //   rowSpan: 1,
    //   actions: [
    //     { label: "E", onClick: handleEditState, btnType: "Edit" },
    //     { label: "D", onClick: handleDelete, btnType: "Delete" },
    //   ],
    //   editRow: true,
    // },
  ];
  // {parseInt(bfVal)!=0 &&vobsArr1.push({
  
  //     colName: `bfvalL`,
  //     flexGrow: 1.9,
  //     dataKey: "bfVal",
  //     rowSpan: 1,
    

  // })}
  

  const patStat = [
    { colName: "Date", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.5,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },

   
    {
      colName: `${claimHeading1}`,
      flexGrow: 2.5,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `${claimAmount1}`,
      flexGrow: 2.5,
      dataKey: "internalNonVerify",
      rowSpan: 1,
    },
    {
      colName: `Pending`,
      flexGrow: 2.5,
      dataKey: "pendingStat",
      rowSpan: 1,
    },
    
    // {
    //   colName: `Adjusted`,
    //   flexGrow: 2.5,
    //   dataKey: "Adjusted",
    //   rowSpan: 1,
    // },
    {
      colName: `${claimAmount} ($)`,
      flexGrow: 2.5,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `${claimHeading3} ($)`,
      flexGrow: 2.5,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `Pending Amount ($)`,
      flexGrow: 2.5,
      dataKey: "pendingAmount",
      rowSpan: 1,
    },
   
    
  ];
  const generalpayer = [
    { colName: "Date", flexGrow: 2.5, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.8,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Claims Paid`,
      flexGrow: 2.5,
      dataKey: "gcp",
      rowSpan: 1,
    },
    {
      colName: `ClaimsAdjusted`,
      flexGrow: 2.9,
      dataKey: "gca",
      rowSpan: 1,
    },
    {
      colName: `Resubmission`,
      flexGrow: 2.5,
      dataKey: "gcr",
      rowSpan: 1,
    },
    {
      colName: `Provider Sent`,
      flexGrow: 2.5,
      dataKey: "gct",
      rowSpan: 1,
    },
    {
      colName: `Appeals Sent`,
      flexGrow: 2.5,
      dataKey: "gcs",
      rowSpan: 1,
    },
    // {
    //   colName: `Total`,
    //   flexGrow: 1.9,
    //   dataKey: "total",
    //   rowSpan: 1,
    // },
    {
      colName: `Pending`,
      flexGrow: 1.9,
      dataKey: "Pending",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 2.5,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];

  const specialpayer = [
    { colName: "Date", flexGrow: 2.5, dataKey: "date", rowSpan: 1 },
    {
      colName: `${claimHeading}`,
      flexGrow: 2.8,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Claims Paid`,
      flexGrow: 2.5,
      dataKey: "scp",
      rowSpan: 1,
    },
    {
      colName: `ClaimsAdjusted`,
      flexGrow: 2.9,
      dataKey: "sca",
      rowSpan: 1,
    },
    {
      colName: `Resubmission`,
      flexGrow: 2.5,
      dataKey: "scr",
      rowSpan: 1,
    },
    {
      colName: `Provider Sent`,
      flexGrow: 2.5,
      dataKey: "sct",
      rowSpan: 1,
    },
    {
      colName: `Appeals Sent`,
      flexGrow: 2.5,
      dataKey: "scs",
      rowSpan: 1,
    },
    // {
    //   colName: `Total`,
    //   flexGrow: 1.9,
    //   dataKey: "total",
    //   rowSpan: 1,
    // },
    {
      colName: `Pending`,
      flexGrow: 1.9,
      dataKey: "Pending",
      rowSpan: 1,
    },

    {
      colName: "Action",
      flexGrow: 2.5,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "E", onClick: handleEditState, btnType: "Edit" },
        { label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      editRow: true,
    },
  ];
 
  let parsedArray = [];
  try {
    parsedArray = JSON.parse(dosArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = dosArray;
  }

  console.log(dosArrayPaid, "dosArrayPaid");
  let pendingSectionhtml = (
    <>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Pending Claims Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            //  value={providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            // value={codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientalingVal"
            placeholder=""
            //   value={credientialVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            EDI
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="EdiVal"
            placeholder=""
            //   value={ediVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Operation
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="operationVal"
            placeholder=""
            //   value={operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );


  let pendingSectionPatStat = (
    <>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
           Rejected Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Provider End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            //  value={providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Patient End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="patVal"
            placeholder=""
            // value={codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Operation End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="operationVal"
            placeholder=""
            //   value={credientialVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Payer`s End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="payerVal"
            placeholder=""
            //   value={ediVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Other
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="otherVal"
            placeholder=""
            //   value={operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );
  let pendingSectionhtmlEob = (
    <>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Denied Claims Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            //  value={providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            // value={codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientialVal"
            placeholder=""
            //   value={credientialVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient End
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="patVal"
            placeholder=""
            //   value={ediVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Billing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="billingVal"
            placeholder=""
            //   value={operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );

  let pendingSectionhtmlGenralPayer = (
    <>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Worked Claims Breakup
        </h4>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          No. of Claims Paid
          </label>

          <input
            class="form-control inputField"
            type="text"
                  name={(changeState=='Sp' && flag=='generalPayer')?"scp":"gcp"}
            placeholder=""
            //  value={providerVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          No. of Claims Adjusted
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={(changeState=='Sp' && flag=='generalPayer')?"sca":"gca"}
            placeholder=""
            // value={codingVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Sent for Resubmission
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={(changeState=='Sp' && flag=='generalPayer')?"scr":"gcr"}
            placeholder=""
            //   value={credientialVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          Sent to Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={(changeState=='Sp' && flag=='generalPayer')?"sct":"gct"}
            placeholder=""
            //   value={ediVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
          No. of Apeals Sent
          </label>

          <input
            class="form-control inputField"
            type="number"
            name={(changeState=='Sp' && flag=='generalPayer')?"scs":"gcs"}
            placeholder=""
            //   value={operationVal}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );


  console.log('isPendingSectionOpen ',isPendingSectionOpen ,'AmountDos',AmountDos,'flag',flag)

 

  let case1 = (
    isBlockAllhtml?null:<>
    <div className="row">
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Dos <span style={{ color: "red" }}>*</span>
        </label>
        <br />
        <DatePicker
          onChange={onChangeDate}
          onChangeCalendarDate={onChangeCalendarDate}
          onClean={onCleanDate}
          style={{ width: 400 }}
          oneTap
          // defaultValue={new Date()}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {errorModel.date == true ? "Date is Required" : null}
        </label>
      </div>
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          {claimHeading} <span style={{ color: "red" }}>*</span>:
        </label>
        <input
          className="form-control inputField"
          type="number"
          name="receivedClaims"
          placeholder=""
          // value={receivedClaims}
          onChange={onChangeInput}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {errorModel.recievedAmount == true
            ? `${claimHeading} is Required`
            : null}
        </label>
      </div>
      {isPendingSectionOpen ? (
        <div className={"col-3"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {"Pending"}
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={pendingClaimss}
            // onChange={onChangeInput}
            disabled
          />
        </div>
      ) : null}
      {isPendingSectionOpen && AmountDos == "ok" && flag!='patStat'
        ? pendingSectionhtmlEob:( isPendingSectionOpen && AmountDos=='ok' && flag=='patStat')?pendingSectionPatStat:
        ( isPendingSectionOpen && AmountDos!='ok' && flag=='generalPayer')?pendingSectionhtmlGenralPayer: (isPendingSectionOpen && AmountDos != "ok")
        ? pendingSectionhtml:null
        }

      <div className="col-4">
        <label className="inputLabel pb-3"></label>
        <br />
        <Button
          onClick={onAdddos}
          style={{ backgroundColor: "#0e7490", color: "white" }}
        >
          Add
        </Button>
      </div>
    </div>
  </>
    
  );

  // let case1 = (
  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             //  defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>:
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? `${claimHeading} is Required`
  //               : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );

  // let case2 = (
  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             // defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //             required
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? "Received Claims is Required"
  //               : null}
  //           </label>
  //         </Col>
  //         {showAmount ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 {claimAmount} <span style={{ color: "red" }}>*</span>
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeAmount}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.AmountDosVal == true
  //                   ? "Amount is Required"
  //                   : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );
  // let case3 = (
  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             // defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? ` ${claimHeading} is required`
  //               : null}
  //           </label>
  //         </Col>
  //         {showAmount ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 {claimAmount}:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeAmount}
  //               />
  //             </Col>
  //           </>
  //         ) : null}
  //         {showPortal ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 Calls:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeCalls}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.calls == true ? `Calls is required` : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //       </Row>

  //       <Row>
  //         {showCalls ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 Portals:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangePortals}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.portals == true ? `Portals is required` : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );

  let case2 = (
    isBlockAllhtml?null:<>
    <div className="row">
      <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Dos <span style={{ color: "red" }}>*</span>
        </label>
        <br />
        <DatePicker
          onChange={onChangeDate}
          onChangeCalendarDate={onChangeCalendarDate}
          onClean={onCleanDate}
          style={{ width: 400 }}
          oneTap
          // defaultValue={new Date()}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {errorModel.date == true ? "Date is Required" : null}
        </label>
      </div>
      <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          {claimHeading} <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className="form-control inputField"
          type="number"
          name="receivedClaims"
          placeholder=""
          // value={receivedClaims}
          onChange={onChangeInput}
          required
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {errorModel.recievedAmount == true
            ? "Received Claims is Required"
            : null}
        </label>
      </div>
      {showAmount ? (
        <div className={isPendingSectionOpen ? "col-3" : "col-4"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimAmount} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            // value={receivedClaims}
            onChange={onChangeAmount}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.AmountDosVal == true ? "Amount is Required" : null}
          </label>
        </div>
      ) : null}
      {isPendingSectionOpen ? (
        <div className={"col-3"}>
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {"Pending"}
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            value={pendingClaimss}
            // onChange={onChangeInput}
            disabled
          />
        </div>
      ) : null}

      {isPendingSectionOpen ? pendingSectionhtml : null}
      {!isPendingSectionOpen && (
        <>
          <div className="col-4"></div>
          <div className="col-4"></div>
        </>
      )}
      <div className={!isPendingSectionOpen ? "col-4" : "col-sm-6 col-lg-3"}>
        <div class="form-group ">
          <div
            className={
              !isPendingSectionOpen
                ? "d-flex justify-content-end mt-4"
                : "d-flex mt-4"
            }
          >
            <button
              type="button"
              className="btnClear mr-1 "
              onClick={onAdddos}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
    
  );

  let case3 = (
    isBlockAllhtml?null:
    <>
      <div className="row">
        <div className="col-4">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Dos <span style={{ color: "red" }}>*</span>
          </label>
          <br></br>
          <DatePicker
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 400 }}
            oneTap
            // defaultValue={new Date()}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.date == true ? "Date is Required" : null}
          </label>
        </div>
        <div className="col-4">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            {claimHeading} <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="form-control inputField"
            type="number"
            name="receivedClaims"
            placeholder=""
            // value={receivedClaims}
            onChange={onChangeInput}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {errorModel.recievedAmount == true
              ? ` ${claimHeading} is required`
              : null}
          </label>
        </div>
        {showAmount ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {claimAmount}:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangeAmount}
            />
          </div>
        ) : null}
        {showPortal ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              Calls:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangeCalls}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.calls == true ? `Calls is required` : null}
            </label>
          </div>
        ) : null}
      </div>

      <div className="row">
        {showCalls ? (
          <div className="col-4">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              Portals:
            </label>
            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              // value={receivedClaims}
              onChange={onChangePortals}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            >
              {errorModel.portals == true ? `Portals is required` : null}
            </label>
          </div>
        ) : null}
        <div className="">
          <label className="inputLabel pb-3"></label>
          <br></br>
          <Button
            onClick={onAdddos}
            style={{ backgroundColor: "#0e7490", color: "white" }}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );

  console.log("hey", dosArrayPaid);
  let checkCase1 = !showAmount && showPortal;
  //true && true shoeportal
  let checkCase2 = showAmount && !showPortal;

  console.log("console table", parsedArray);
  const getRequriedPara=()=>{

  }

  return (
    <div className="row">
      {!onlyShowTable
        ? checkCase1
          ? case3
          : checkCase2
          ? case2
          : case1
        : null}
      <div className="col-lg-12 mb-2 text-center"></div>
      <div className="col-lg-12 mb-2 text-center"></div>
      <UserAuditTable
       isPaginationShow={true}
      getRequriedPara={getRequriedPara}
        rowHeight={48}
        tableHeight={flag=='vobb' && SecondTableName=='vobb'?'30rem':'200'}
        handleChange={handleChange}
        handleEditState={handleEditState}
        dateForgrid={parsedArray}
        isPopupOpen={isPopupOpen}
        Array={
         (flag=='sp' ||flag=='gp') && SecondTableName=='payer'?PayerView:
          flag=='vobb' && SecondTableName=='vobb'?vobView:
          flag=='Eob' && SecondTableName=='Eob'?EobView:
          (flag=="patStat" && changeState=="patState")?PendingClaimBreakupgridForPatStat:
          flag === "generalPayer" && changeState=="Sp"?
          specialpayer:
        
          
          flag === "generalPayer"?
          generalpayer:
          onlyShowTable && showTableCheck === "patStat"?
          patStat

          :onlyShowTable && showTableCheck === "charge"
            ? PendingClaimBreakupgrid
            : onlyShowTable && showTableCheck == "eob"
            ? PendingClaimBreakupgridForEob
            : showTableCheck == "vob" && onlyShowTable
            ? PendingClaimBreakupgridForVob
            : checkCase1
            ? vobsArr
            : checkCase2
            ? arr
            : vobsArr1
        }
        rowShown={5}
      />
      {SecondTableName=='Eob' ||SecondTableName=='vobb' ?null:<><div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                {SecondTableName === "patStat"?'Non-Verified Breakup':'Pending Claims Breakup'}
              </h4>
            </div>
            {isBlockAllhtml && <>
        <UserAuditTable
         isPaginationShow={true}
        getRequriedPara={getRequriedPara}

        rowHeight={48}
        handleChange={handleChange}
        handleEditState={handleEditState}
        dateForgrid={parsedArray}
        isPopupOpen={isPopupOpen}
        Array={
          onlyShowTable && SecondTableName === "payer"?payerViewPending:
          
          onlyShowTable && SecondTableName === "charge"
            ? PendingClaimBreakupgrid
            : onlyShowTable && SecondTableName === "patStat"?PendingClaimBreakupgridForPatStat:null
        }
        rowShown={5}
      />

      </>}


      {isAllowThirdTable?.allow && isAllowThirdTable.flagThirdTable=='generalPayer' && <>
        <UserAuditTable
         isPaginationShow={true}
        getRequriedPara={getRequriedPara}
        rowHeight={48}
        handleChange={handleChange}
        handleEditState={handleEditState}
        dateForgrid={parsedArray}
        isPopupOpen={isPopupOpen}
        Array={
          payerViewPendingAmount
          
          
        }
        rowShown={5}
      />

      </>}
            </>}

            {DenialClaimBreakup && <>
              <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Denials Claims Breakup
        </h4>
      </div>
        <UserAuditTable
         isPaginationShow={true}
        getRequriedPara={getRequriedPara}
        rowHeight={48}
        handleChange={handleChange}
        handleEditState={handleEditState}
        dateForgrid={parsedArray}
        isPopupOpen={isPopupOpen}
        Array={
          DenailBreakupEob
          
          
        }
        rowShown={5}
      />

      </>}


            


      

      {onlyShowTable && showTableCheck == "eob" && (
        <>
          <div className="col-lg-12 mb-2 mt-4 text-center">
            <h4
              class="inputLabel "
              style={{
                color: "black",
                borderBottom: "2px solid rgb(14, 116, 144)",
                paddingBottom: "10px",
              }}
            >
              Paid Claims
            </h4>
          </div>
          <UserAuditTable
           isPaginationShow={true}
          getRequriedPara={getRequriedPara}
            rowHeight={48}
            handleChange={handleChange}
            handleEditState={handleEditState}
            dateForgrid={dosArrayPaid}
            isPopupOpen={isPopupOpen}
            Array={PaidClaimBreakupgridForEob}
            rowShown={5}
          />
        </>
      )}
      <div class="col-sm-6 col-lg-3 "></div>

      <div class="col-sm-6 col-lg-3 "></div>
      <div class="col-sm-6 col-lg-3 "></div>

      <div class="col-sm-6 col-lg-3 mt-2">
        <div class="form-group ">
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="btnClear mr-1 "
              onClick={handleClose}
            >
              Back
            </button>
            {/* {onlyShowTable?null:
             <button type="button" className="btnSave" onClick={onSaveDos}>
             Save
           </button>
            } */}
           
          </div>
        </div>
      </div>

      {/* <div className="col-4">
<Button
          onClick={onSaveDos}
           style={{ backgroundColor: "#0e7490", color: "white" }}
    >
           Save
    </Button>

</div> */}

      {/*             
       <div className="col-4"> <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button></div> */}
    </div>
    // <Modal open={true} size={"lg"} onClose={handleClose}>
    //   <Modal.Header>
    //     <div className="col-lg-12 mb-2 text-center"></div>
    //     <div className="col-lg-12 mb-2 text-center"></div>

    //     <div className="col-lg-12 mb-2 text-center">
    //       <Modal.Title>
    //         <h4
    //           class="inputLabel "
    //           style={{
    //             color: "black",
    //             borderBottom: "2px solid rgb(14, 116, 144)",
    //             paddingBottom: "10px",
    //           }}
    //         >
    //           Add Date of Service
    //         </h4>
    //       </Modal.Title>
    //     </div>
    //   </Modal.Header>
    //   <Modal.Body style={{ overflow: "visible !important" }}>
    //     {checkCase1 ? case3 : checkCase2 ? case2 : case1}
    //     <div className="col-lg-12 mb-2 text-center"></div>
    //     <div className="col-lg-12 mb-2 text-center"></div>
    //     <div className="col-lg-12 mb-2 text-center"></div>
    //     <div className="col-lg-12 mb-2 text-center"></div>
    //     <br></br>
    //     <UserAuditTable
    //       rowHeight={48}
    //       handleChange={handleChange}
    //       handleEditState={handleEditState}
    //       dateForgrid={parsedArray}
    //       isPopupOpen={isPopupOpen}
    //       Array={checkCase1 ? vobsArr : checkCase2 ? arr : vobsArr1}
    //       rowShown={5}
    //     />
    //   </Modal.Body>

    //   <Modal.Footer>
    //     <Button
    //       onClick={onSaveDos}
    //       style={{ backgroundColor: "#0e7490", color: "white" }}
    //     >
    //       Save
    //     </Button>
    //     <Button onClick={handleClose} appearance="subtle">
    //       Cancel
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
}
