import React, { useEffect, useState } from "react";
import EligibilityVobsForm from "./AllForms/eligiblityVobsForm";
import Rejection from "./AllForms/RejectionForm";
import { fetchCompositions, fetchProduct } from "../../Utils/ApiManager";

import Select from "react-select";

import ChargeEntry from "./AllForms/ChargeEntry";
import AuthorizationForm from "./AllForms/AuthorizationForm";
import EobsForm from "./AllForms/EobsForm";
import AccountReciecableForm from "./AccountReciecableForm";
import image from "../../Assets/images/NoFormFound.svg";
import ErasForm from "./AllForms/ErasForm";
import PatStatement from "./AllForms/PatStatement";
import { useSelector,useDispatch } from "react-redux";

import TabMenus from "./AllForms/FormTab.js";
import Sweetalertdemo from "../../Common/NotifySwal";
import NotifySwal from "../../Common/NotifySwal"
import { AllConfirmationOnTab } from "../../Actions/Actions";
import PaymentPosting from "./PaymentPosting";


function OperationMainForm({
  state,
  setState,
  setPracticeId,
  setRecord,
  setFormTypeNoForApi,
  isMoveTab,
  setisMoveTab,
  setFormType,
  onChangePayer,
  payerSelection,
  products,
  handleFormTypeAndPract,
  handelAccountReceiveable,
  formTypeNoForApi,
  date,
  formType,
  record,
  practiceId,
  Elibility,
  payerType,
  onRecordUpdateOrCreated,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nameGeneric, setNameGeneric] = useState(formType);
  const [selectedOption, setSelectedOption] = useState("");
  const { AllConfirmationonTab } = useSelector((state) => state);
 
  
  let dispatch=useDispatch()

  console.log(formType, "formType");

  useEffect(() => {
    if (formType == "Auth") {
      setNameGeneric("Authorization");
    } else if (formType == "Rejection") {
      setNameGeneric("Rejection");

    } else if (formType == "chargeEntry") {
      setNameGeneric("Charge Entry");
    } else if (formType == "EOBS") {
      setNameGeneric("EOB`s");
    } else if (formType == "ELIBILITY/VOBS") {
      setNameGeneric("VOB`s");
    } else if (formType == "AccountReceivable") {
      setNameGeneric("Account Receivable");
    } else if (formType == "ERAS") {
      setNameGeneric("ERA`s");
    }
  }, [formType]);

  const handleClick = (index) => {
    console.log(index);
    setActiveIndex(index);
  };

  const isActive = activeIndex === 1;
  const ariaExpanded = isActive ? "true" : "false";
  const panelClass = isActive
    ? "panel-collapse collapse show"
    : "panel-collapse collapse";
  console.log("products",date);

  const handleCloseSwal=(val)=>{
    if(val){
      dispatch(AllConfirmationOnTab(false))

    }
    else{

    }

  }
  const handleSwalConfirm=(val)=>{
    if(val){
      
      setisMoveTab((prev)=>({...prev,nextTabName:formType}))
    }else{
      setFormType(isMoveTab.flag)
     // setisMoveTab((prev)=>({...prev,nextTabName:isMoveTab.flag}))

    }

  }

  console.log('there benn',formType,formTypeNoForApi)
  
  return (
    <>
      <div class="">
        <div className="">
          <div class=" row mt-1">
            <div
              class=""
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="sticky-container">
              <TabMenus
              setPracticeId={setPracticeId}
              setRecord={setRecord}
              
                payerType={payerType}
                date={date}
                products={products}
                handelAccountReceiveable={handelAccountReceiveable}
                handleFormTypeAndPract={handleFormTypeAndPract}
              />
              </div>
              
              {formType == "chargeEntry"  && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(1)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={formType == "chargeEntry" ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType == "chargeEntry" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          {formType == "chargeEntry"
                            ? nameGeneric
                            : "Patient Statement"}
                        </a>
                      </h4>
                    </div> */}
                    {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                 {formType == "chargeEntry"
                            ? nameGeneric
                            : "Patient Statement"}
               
              </h4>
            </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "chargeEntry"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={formType == "chargeEntry" ? true : false}
                    >
                      <div class="">
                        <ChargeEntry
                        
                        setSelectedOption={setSelectedOption}
                          handleFormTypeAndPract={handleFormTypeAndPract}
                          products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          record={record}
                          practiceId={practiceId}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) }
              {formType == "chargeEntryPat" ? (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(1)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={
                            formType == "chargeEntryPat" ? "after" : ""
                          }
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType == "chargeEntryPat" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          {formType == "chargeEntry"
                            ? "Charge Entry"
                            : "Patient Statement / Verification"}
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "chargeEntryPat"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType == "chargeEntryPat" ? true : false
                      }
                    >
                      <div class="">
                        <PatStatement
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          record={record}
                          practiceId={practiceId}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {formType == "Rejection"  && (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(2)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={formType == "Rejection" ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={formType == "Rejection" ? true : false}
                          aria-controls="collapseOne"
                        >
                          {nameGeneric}
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "Rejection"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={formType == "Rejection" ? true : false}
                    >
                      <div class=" "></div>
                      <Rejection
                        handleFormTypeAndPract={handleFormTypeAndPract}
                        products={products}
                        setNameGeneric={setNameGeneric}
                        onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                        formTypeNoForApi={formTypeNoForApi}
                        date={date}
                        record={record}
                        practiceId={practiceId}
                      />
                    </div>
                  </div>
                </>
              )}
              {/* {
                (formType == "Rejection" && isMoveTab.isTabMove==true && isMoveTab.flag==isMoveTab.nextFlag) && <>
                <NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Save This Form'}/>


                </>} */}

                

              {/* {
                (formType == "Auth" && isMoveTab.isTabMove==true && isMoveTab.flag==isMoveTab.nextFlag) && <>
                <NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Save This Form'}/>


                </>

                

              } */}

              {formType == "Auth" && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(3)}
                  >
                    

                    <div
                      id="collapseOne"
                      className={
                        formType == "Auth"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={formType == "Auth" ? true : false}
                    >
                      <div class=" ">
                        <AuthorizationForm
                        setisMoveTab={setisMoveTab }
                          handleFormTypeAndPract={handleFormTypeAndPract}
                          products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          record={record}
                          practiceId={practiceId}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formType == "ELIBILITY/VOBS" && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(4)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={
                            formType == "ELIBILITY/VOBS" ? "after" : ""
                          }
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType == "ELIBILITY/VOBS" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          {nameGeneric}
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "ELIBILITY/VOBS"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType == "ELIBILITY/VOBS" ? true : false
                      }
                    >
                      <div class=" ">
                        <EligibilityVobsForm
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          record={record}
                          practiceId={practiceId}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
             
              
              {(formType=="AccountReceivable"|| (payerType=="AccountReceivablegeneral" || payerType=="AccountReceivablespecial" || payerType=="AccountReceivable" ))&& (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(6)}
                  >
                    

                    <div
                      id="collapseOne"
                      className={
                        formType=="AccountReceivable"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType=="AccountReceivable" ? true : false
                      }
                    >
                      <div class=" ">
                        <AccountReciecableForm
                        onChangePayer={onChangePayer}
                        payerSelection={payerSelection}
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          payerType={payerType}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}




              {/* **********************************************For payment Posting ***************************** */}


{formType === "Payment" && (
        <>
          {" "}
          <div class="panel panel-default" onClick={() => handleClick(5)}>
            {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={formType === "EOBS" ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={formType === "EOBS" ? true : false}
                          aria-controls="collapseOne"
                        >
                          {nameGeneric}
                        </a>
                      </h4>
                    </div> */}

            <div
              id="collapseOne"
              className={
                formType === "Payment"
                  ? "panel-collapse collapse show"
                  : "panel-collapse collapse"
              }
              aria-expanded={formType === "Payment" ? true : false}
            >
              <div class=" ">
                <PaymentPosting
                 state={state}
                 setState={setState}
                setFormTypeNoForApi={setFormTypeNoForApi}
                formType={formType}
                handleClick={handleClick}
                  handleFormTypeAndPract={handleFormTypeAndPract}
                  products={products}
                  setNameGeneric={setNameGeneric}
                  onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                  record={record}
                  practiceId={practiceId}
                  formTypeNoForApi={formTypeNoForApi}
                  date={date}
                />
              </div>
            </div>
          </div>
        </>
      )}
              
              {formType == "GENERAL FIELDS" && (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(8)}
                  >
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div className={activeIndex == 8 ? "after" : ""}></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={activeIndex == 8 ? true : false}
                          aria-controls="collapseOne"
                        >
                          General Fields
                        </a>
                      </h4>
                    </div>

                    <div
                      id="collapseOne"
                      className={
                        activeIndex == 8
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={activeIndex == 8 ? true : false}
                    >
                      <div class=" ">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Praesent nisl lorem, dictum id pellentesque at,
                          vestibulum ut arcu. Curabitur erat libero, egestas eu
                          tincidunt ac, rutrum ac justo. Vivamus condimentum
                          laoreet lectus, blandit posuere tortor aliquam vitae.
                          Curabitur molestie eros.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formType == null && (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(8)}
                  >
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div className={formType == null ? "after" : ""}></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={formType == null ? true : false}
                          aria-controls="collapseOne"
                        >
                          No Form Selected
                        </a>
                      </h4>
                    </div>

                    <div
                      id="collapseOne"
                      className={
                        formType == null
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={activeIndex == 8 ? true : false}
                    >
                      <div class="">
                        <img src={image} style={{ height: "20rem" }}></img>
                        {/* <Error404 /> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OperationMainForm;
