import React, { useState } from "react";

import AccountRecievable from "./AllTlForms/AccountRecievable";

import Payments from "./AllTlForms/Payments/Payments";
import ChargeEntry from "./AllTlForms/ChargeEntry/ChargeEntry";
import TeamLeadForm from "./AllTlForms/TeamLeadForm";
import Denials from "./AllTlForms/Denial/Denials";
import TabMenus from "./FormTabTl";
function TLMainForm({
  setPracticeId,
  setRecord,
  handelClear,
  handleSubCheckBoxChargeEntry,
  products,
  handleFormTypeAndPract,
  onRecordUpdateOrCreated,
  formType,
  subCheckBox,
  record,
  practiceId,
  formTypeNoForApi,
  date,
 
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    console.log(index);
    setActiveIndex(index);
  };
  console.log("subCheckBox", subCheckBox, "formType", formType);

  return (
    <>
      <div class="">
        <div className="">
          <div class=" row mt-1">
            <div
              class=""
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="sticky-container">
              <TabMenus
              setPracticeId={setPracticeId}
              setRecord={setRecord}
               // payerType={payerType}
               handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                date={date}
                products={products}
              
                handleFormTypeAndPract={handleFormTypeAndPract}
              />
              </div>
              
              {formType == "AmountVerification" && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(1)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={formType == "chargeEntry" ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType == "chargeEntry" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          Charge Entry
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "chargeEntry"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse show"
                      }
                      aria-expanded={formType == "chargeEntry" ? true : false}
                    >
                      <div class="">
                        {/* <ChargeEntry  record={record} practiceId={practiceId}/> */}
                        <ChargeEntry
                        handleFormTypeAndPract={handleFormTypeAndPract}
                        products={products}
                       
                        
                        handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          subCheckBox={subCheckBox}
                          formType={formType}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formType == "DeniedVerification" && (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(2)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={
                            formType == "DeniedVerification" ? "after" : ""
                          }
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType == "DeniedVerification" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          Denails
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "DeniedVerification"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType == "DeniedVerification" ? true : false
                      }
                    >
                      <div class=" "></div>
                      {/* <Rejection /> */}
                      <Denials
                        handleFormTypeAndPract={handleFormTypeAndPract}
                        products={products}
                       
                        
                        handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                        onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                        subCheckBox={subCheckBox}
                        formType={formType}
                        record={record}
                        practiceId={practiceId}
                        formTypeNoForApi={formTypeNoForApi}
                        date={date}
                      />{" "}
                    </div>
                  </div>
                </>
              )}

              {formType == "AccountRecVerify" && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(6)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={
                            formType === "AccountRecVerify" ? "after" : ""
                          }
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType === "AccountRecVerify" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          Account Receivable
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "AccountRecVerify"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType == "AccountRecVerify" ? true : false
                      }
                    >
                      <div class=" ">
                        {/* <AccountReciecableForm /> */}
                        <AccountRecievable
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                        
                         
                         handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                          subCheckBox={subCheckBox}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          formType={formType}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formType == "PaymentsVerification" && (
                <>
                  {" "}
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(6)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={
                            formType === "PaymentsVerification" ? "after" : ""
                          }
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                            formType === "PaymentsVerification" ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                          Payments
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == "PaymentsVerification"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={
                        formType == "PaymentsVerification" ? true : false
                      }
                    >
                      <div class=" ">
                        {/* <AccountReciecableForm /> */}
                        <Payments
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                        
                         
                         handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          subCheckBox={subCheckBox}
                          formType={formType}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {formType == 'TLInterface' && (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(8)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div className={formType == null ? "after" : ""}></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={formType == null ? true : false}
                          aria-controls="collapseOne"
                        >
                          Team Lead Interface
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        formType == 'TLInterface'
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={activeIndex == 8 ? true : false}
                    >
                      <div class="">
                        <TeamLeadForm
                         practiceId={practiceId}
                         handleFormTypeAndPract={handleFormTypeAndPract}
                         products={products}
                         date={date}
                         formTypeNoForApi={formTypeNoForApi}
                         handelClear={handelClear}
                         
                         handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}/>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TLMainForm;
