import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getBucketImages, insertFromType5EobsRecord } from "../../../Utils/ApiManager";

import { useSelector } from "react-redux";

import Dateoofservice from "../../../Common/Dateoofservice";
import NotifySwal from "../../../Common/NotifySwal";
import { useDispatch } from "react-redux";
import {Panel} from "rsuite"
import {
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "./CommonDos";
import { handleCloseC } from "./CommonDos";
import { Schema } from "rsuite";
import { createValidationSchema } from "../../../Common/Validation";
import DateAndPractice from "./DateAndPractice";
import DateoofserviceGlobal from "../../../Common/DateofServiceGlobal";
import moment from "moment/moment";
import DateoofserviceBF from "./BFClaims";
import { AllConfirmationOnTab } from "../../../Actions/Actions";
import FileUpload2 from "../../../Common/FileUpload2";
import ViewDocument from "../../../Common/IframeModel";
const EobsForm = ({
  payerSelection,
  onChangePayer,
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  practiceId,
  record,
  formTypeNoForApi,
  date,
  onRecordUpdateOrCreated,
}) => {
  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");
  const [bfScreenArray, setBfScreenArray] = useState("");

  const { onLoginSignOff } = useSelector((state) => state);
  let objPar = {
    partially: "",
    isShowPartiallyBreakup: false,
  };

  let objR={
    filelist:[]

  }
  const [objectcluster,setobjCLuster]=useState(objR)

  const [FileList, setFileList] = useState([]);
  const [partially, setPartially] = useState(objPar);
  console.log(record, formTypeNoForApi, "eobs");
  const [docViewer, setDocViewer] = useState(false);
  const [dosModel, setDosModel] = useState(false);

  const [handledosDate, setdosDate] = useState("");
  let footerObj = {
    isAllow: true,
    RecievedClaims: 0,
    PaidClaims: 0,
    DeniedClaims: 0,
    TotalWorkedClaims: 0,
    pendingClaims: 0,
    partially: 0,
  };
  let objAmount = {
    recAmountGen: "",
    CollectAmountGen: "",
    pendAmuontGen: "",

    receivedAmount: 0,
    CollectedAmount: 0,
    PendingAmount: 0,
  };

  let helpingObj = {
    isSpeakToaster: false,
    loadingn: false,
    isSpeakerToaster: false,
    isBackSpeak: false,
    AllFiles: null,
    renderEffectForUpload: false,
    getSelectedRowDocument: null,
    updatedblodState: [],
    updatedblob: [],
    isFileViewerOpen: null,
    isAllStatesAreSet:false
  };
  const [helpingState, sethelpingState] = useState(helpingObj);
  const [footerState, setFooterState] = useState(footerObj);
  const [stateAmount, setStateAmount] = useState(objAmount);
  const [handleInputdos, sethandleInput] = useState("");
  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [dosArrayPaid, setDosArrayPaid] = useState([]);
  const [dosArrayDenied, setDosArrayDenied] = useState([]);
  const [dosArrayEobRec, setDosArrayEobRec] = useState([]);
  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [openDosmodelRecStatePaid, setDosModelRecPaid] = useState(false);
  const [openDosmodelRecStateDenied, setDosModelRecDenied] = useState(false);
  const [openDosmodelRecStateEob, setDosModelRecEob] = useState(false);
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [handleCheckDate, setCheckDate] = useState(``);
  const [AmountDosRec, setAmountDosRec] = useState("");
  const [renderEffect, setRenderEffect] = useState(false);
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const [bfRow, setisThisbfRow] = useState(false);
  const [eobRef, setEobRef] = useState("");


  const [totalWorkedAmount,setTotalAmount]=useState("")


  //new things

  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [totalClaims, setTotalClaims] = useState(false);

  const [globalDos, openGlobalDos] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [rowID, setRowID] = useState(null);

  //*************88 */

  const [handleInputdosRec, sethandleInputRec] = useState("");

  useEffect(() => {
    clearAllFileds();
  }, [practiceId]);
  useEffect(() => {
    if (date) {
      clearAllFileds();
    }
  }, [date]);

  let obj = {
    GeneralOpen: "", //
    adjustmentp: "", //
    claimbf: "", //
    claimrecieved: "", //
    claimspending: "", //
    denials: "", //
    partiallyPaid: "",
    denialsamount: "", //
    eobbf: "", //
    eobrecieved: "", //
    eobspending: "", //
    eobworked: "", //

    image: "", //
    insurance: "",
    insurancep: "", //
    paidclaims: "", //
    patientsp: "", //
    payments: "",
    eraAmount: "",

    stoken: "", //
    totalclaimsworked: "", //
    providerVal: "",
    codingVal: "",
    credientialVal: "",
    billingVal: "",
    patVal: "",
    comments: "",
    dosArray: [],
    dosArrayDenied: [],
    dosArrayPaid: [],
    dosArrayEobRec: [],
    dosArrayRecieved: [],

    ReceivedAmountTotal: 0,
    CollectedAmountTotal: 0,
    pendingAmountTotal: 0,
    codingOverAll: 0,
    PatientOverAll: 0,
    ProviderOverAll: 0,
    BillingOverAll: 0,
    CredientialingOverAll: 0,
    totalWorkedAmountAllSum:0
  };
  let breakupObj = {
    providerVal: 0,
    codingVal: 0,
    credientialVal: 0,
    billingVal: 0,
    patVal: 0,
    total: 0,
    insurancePartially: "",
    PatientResponsibilityPartially: "",
    Adjustmentspartially: "",
    paymentPartially: "",
    DenialPartially: "",
    coding: "",
    Billing: "",
    Credientialing: "",
    Provider: "",
    Patient: "",
    totalDenial: 0,
  };
  const [isPendingBreakOpen, setisPendingBreakOpen] = useState(false);
  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);
  console.log("AllBreakupVal", AllBreakupVal);

  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);

  let objPayment = {
    insurance: "",
    Patients: "",
    Adjustments: "",
    DenialAmount: "",
    paymentsTotal: "",
    //partially payments
    insurancePar: "",
    PatientsPar: "",
    AdjustmentsPar: "",
    paymentsTotalPar: "",
    DenialAmountPar: "",
    PPC:"",
    PPA:""
  };


  useEffect(() => {
   
    if(FileList && FileList.length>0){
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data
    
        const blob = new Blob([res.data], { type: el.mimetype });
        var file = new File([blob],  el.originalname);
      // const uint8Array = new Uint8Array(res.data);
      //  const blob = new Blob([uint8Array], { type: el.mimetype });
      //  blob.name = el.originalname;
      //  blob.lastModified = el.lastModified;
         
   
          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original:el.originalname
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
       
       
       
       setRecords((prev)=>({...prev,image:updatedArr}))
      //  setobjCLuster((prev)=>({
      //   ...prev,
      //   filelist:updatedArr

      // }))
     
      })
  
  
      
    }
   
  }, [FileList])

  useEffect(() => {
    if (parseFloat(handleInputdos) > 0) {
      setisPendingBreakOpen(true);
    } else {
      setisPendingBreakOpen(false);
    }
  }, [handleInputdos]);

  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }

    let countAmountdosCC = 0;
    let recievedAmountCC = 0;
    let pendingClaim = 0;
    let totalWorked = 0;
    let partiallyAmount = 0;

    let RecievdAmount = 0;
    let collectedAmount = 0;
    let pendingAmount = 0;

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmountCC); //REC PAT STAT

        countAmountdosCC += parseFloat(el.AmountDosValCC); //PAID
        recievedAmountCC += parseFloat(el.recievedAmount); //dENEID
        totalWorked +=
          parseFloat(el.AmountDosValCC) +
          parseFloat(el.recievedAmount) +
          parseFloat(el.partially);
        partiallyAmount += parseFloat(el.partially);
        pendingClaim =
          parseFloat(el.recievedAmountCC) -
          (parseFloat(el.AmountDosValCC) +
            parseFloat(el.recievedAmount) +
            parseFloat(el.partially));

        RecievdAmount += parseFloat(el?.recievedAmountgGen);
        collectedAmount += parseFloat(el?.collectedAmountgGen);
        pendingAmount += parseFloat(el?.pendinGen);
      });

    setFooterState((prev) => ({
      ...prev,
      RecievedClaims: count,
      PaidClaims: countAmountdosCC,
      DeniedClaims: recievedAmountCC,
      TotalWorkedClaims: totalWorked,
      pendingClaims: pendingClaim,
      partially: partiallyAmount,

      receivedAmount: RecievdAmount,

      CollectedAmount: collectedAmount,
      PendingAmount: pendingAmount,
    }));
  }, [dosArrayRecieved]);

  useEffect(() => {
    if (parseFloat(partially.partially) > 0) {
      setPartially((prev) => ({ ...prev, isShowPartiallyBreakup: true }));
    } else {
      setPartially((prev) => ({ ...prev, isShowPartiallyBreakup: false }));
    }
  }, [partially.partially]);

  const [paymentstate, setPaymentState] = useState(objPayment);

  const onChangePaymwents = (e) => {
    const { value, name } = e.target;
    setPaymentState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeAmount = (e) => {
    const { name, value } = e.target;

    setStateAmount((prev) => ({ ...prev, [name]: value }));
  };


  useEffect(() => {
   
       if(paymentstate?.paymentsTotal || paymentstate?.paymentsTotalPar || paymentstate?.DenialAmount ||paymentstate?.DenialAmountPar ){
  let total=parseFloat(paymentstate?.paymentsTotal ||0)+parseFloat(paymentstate?.paymentsTotalPar||0)+parseFloat(paymentstate?.DenialAmount || 0)+parseFloat(paymentstate?.DenialAmountPar ||0)
        setTotalAmount(total)
       }
     
   }, [paymentstate?.paymentsTotal,paymentstate?.paymentsTotalPar,paymentstate?.DenialAmount,paymentstate?.DenialAmountPar])

  useEffect(() => {
    if (
      paymentstate.insurance ||
      paymentstate.Patients ||
      paymentstate.Adjustments
    ) {
      
      let total =
        parseFloat(paymentstate.insurance||0) +
        parseFloat(paymentstate.Patients||0) +
        parseFloat(paymentstate.Adjustments||0);
      setPaymentState((prev) => ({ ...prev, paymentsTotal: total }));
    }else{
      setPaymentState((prev) => ({ ...prev, paymentsTotal: 0 }));
    }
    console.log('kokok',paymentstate.insurancePar,paymentstate.PatientsPar,paymentstate.AdjustmentsPar)
    if (
      (paymentstate.insurancePar) ||
      (paymentstate.PatientsPar) ||
      (paymentstate.AdjustmentsPar)
    ) {
      let total =
        parseFloat(paymentstate.insurancePar||0) +
        parseFloat(paymentstate.PatientsPar||0) +
        parseFloat(paymentstate.AdjustmentsPar||0);
        console.log('total',total)
      setPaymentState((prev) => ({ ...prev, paymentsTotalPar: total }));
    }else{
      setPaymentState((prev) => ({ ...prev, paymentsTotalPar: 0 }));

    }
  }, [
    paymentstate.insurance,
    paymentstate.Patients,
    paymentstate.Adjustments,
    paymentstate.AdjustmentsPar,
    paymentstate.insurancePar,
    paymentstate.PatientsPar,
  ]);

  useEffect(() => {
    const {
      providerVal,
      codingVal,
      credientialVal,
      billingVal,
      patVal,
      total,
    } = AllBreakupVal;

    if (providerVal || codingVal || credientialVal || billingVal || patVal) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(patVal) || 0) +
        (parseFloat(credientialVal) || 0) +
        (parseFloat(billingVal) || 0) +
        (parseFloat(codingVal) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
  }, [
    AllBreakupVal.providerVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.patVal,
    AllBreakupVal.credientialVal,
    AllBreakupVal.billingVal,
  ]);

  useEffect(() => {
    const { coding, Credientialing, Provider, Patient, Billing } =
      AllBreakupVal;

    if (coding || Credientialing || Provider || Patient || Billing) {
      let totalValue =
        (parseFloat(coding) || 0) +
        (parseFloat(Credientialing) || 0) +
        (parseFloat(Provider) || 0) +
        (parseFloat(Patient) || 0) +
        (parseFloat(Billing) || 0);
      setBreakupVal((prev) => ({ ...prev, totalDenial: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, totalDenial: 0 }));
    }
  }, [
    AllBreakupVal.coding,
    AllBreakupVal.Credientialing,
    AllBreakupVal.Provider,
    AllBreakupVal.Patient,
    AllBreakupVal.Billing,
  ]);

  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;
    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(AllBreakupVal, "onChangeBreakupClaim");
  const [records, setRecords] = useState(obj);

  let errorobj = {
    recievedAmount: false,
    recievedAmountCC: false,
    AmountDosValCC: false,
    billedAmount: false,
    insuranceAmount: false,
    refNo: false,
    checkDate:false,

    date: false,
  };
  const { StringType, NumberType, DateType } = Schema.Types;
  const [errorModel, setErrorModel] = useState(errorobj);

  const openDosmodel = () => {
    // setNameGeneric('Worked Claims')
    setDosModel(true);
  };

  const onChangeInput = (val) => {
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmountCC: false,
    }));
  };

  let nextData;

  const handleTaosterSpeak = () => {
    if(dosArrayRecieved && dosArrayRecieved.length==0){
      toast.info('Please Enter Date of Service Wise Record')
      return
     }
    sethelpingState((prev) => ({ ...prev, isSpeakerToaster: true }));
  };

  const handleClose = () => {
    openGlobalTable(false);
    setDosModel(false);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
      AmountDosVal: false,
      AmountDosValCC: false,
      date: false,
    }));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (globalDos) {
      dispatch(AllConfirmationOnTab(true));
    } else {
      dispatch(AllConfirmationOnTab(false));
    }
  }, [globalDos]);

  useEffect(() => {
   
    let totalclaimsworked =
      parseFloat(records?.paidclaims ? records?.paidclaims : 0) +
      parseFloat(records?.denials ? records?.denials : 0) +
      parseFloat(records?.partiallyPaid ? records?.partiallyPaid : 0);
    setRecords((prevResult) => ({
      ...prevResult,
      totalclaimsworked: totalclaimsworked,
    }));
    let eobPending =
      parseFloat(records?.eobrecieved ? records?.eobrecieved : 0) -
      parseFloat(records?.eobworked ? records?.eobworked : 0);
    let claimspending =
      parseFloat(records?.claimrecieved ? records?.claimrecieved : 0) -
      parseFloat(records?.totalclaimsworked ? records?.totalclaimsworked : 0);

    let paymentss =
      parseFloat(records?.patientsp ? records?.patientsp : 0) +
      parseFloat(records?.insurance ? records?.insurance : 0) +
      parseFloat(records?.adjustmentp ? records?.adjustmentp : 0);
    setRecords((prevResult) => ({
      ...prevResult,

      payments: paymentss,
      eobspending: eobPending,
      claimspending: claimspending,
    }));
  }, [
    records?.totalclaimsworked,
    records?.claimrecieved,
    records?.claimbf,
    records?.eobbf,
    records?.eobworked,
    records?.eobrecieved,
    records?.paidclaims,
    records?.denials,
    records?.partiallyPaid,
    records?.patientsp,
    records?.insurance,
    records?.adjustmentp,
  ]);
  useEffect(() => {
    if (record) {
      getRecords(record);
    }
  }, [record]);

  console.log("dosArrayt", dosArrayDenied);

  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem = arr1 && arr1.find((item1) =>
      arr2.some(
        (item2) =>
          item1.date === item2.date &&
          item1.recievedAmount === item2.recievedAmount
      )
    );

    return !matchingItem;
  };

  useEffect(() => {
    // new Changes 12/6/2023 commented bleow line
    // setPaymentState((prev)=>({...prev,insurance:stateAmount.CollectAmountGen}))
    if (stateAmount.recAmountGen || totalWorkedAmount) {
      // let pending =
      //   parseFloat(stateAmount.recAmountGen) -
      //   parseFloat(stateAmount.CollectAmountGen);

      // new Changes 12/6/23

       let pending =
         parseFloat(stateAmount.recAmountGen) -
         parseFloat(totalWorkedAmount);

      setStateAmount((prev) => ({ ...prev, pendAmuontGen: pending }));
    }
  }, [stateAmount.recAmountGen,totalWorkedAmount]);
  useEffect(() => {
    if(parseFloat(stateAmount.pendAmuontGen)<0){
      toast.info('Pending Amount value must be a positive number. Please verify your calculations.')
    }
    
  }, [stateAmount.pendAmuontGen])
  

  const getRecords = (record) => {
   
    if (record?.rcount === "0") {
      setRecords((prevResult) => ({
        ...prevResult,
        eobbf: record?.bfvalprv ? record?.bfvalprv : 0,
        claimbf: record?.bfprv ? record?.bfprv : 0,
      }));
      setFileList([])
      const newArray = transformArray(record?.dosArrayRecieved);
      setDosArrayRec(newArray);
    } else {
      if (record.result.length == 0) {
        return;
      } else {
        let obj;
        try {
          obj = JSON.parse(record && record?.result[0]?.breakupval);
        } catch {
          obj = record && record?.result[0]?.breakupval;
        }
        let parsedArray;
        let parsedArray1;
        let parsedArray2;
        let parsedArray3;
        let parsedArray4;

        try {
          parsedArray = JSON.parse(record.result[0]?.dosArrayDenied);
          parsedArray1 = JSON.parse(record.result[0]?.dosArrayEobRec);
          parsedArray2 = JSON.parse(record.result[0]?.dosArrayPaid);
          parsedArray3 = JSON.parse(record.result[0]?.dosArrayRecieved);
          parsedArray4 = JSON.parse(record.result[0]?.dosArray);
          console.log("The string contains a valid JSON object.");
        } catch (error) {
          parsedArray = record.result[0]?.dosArrayDenied;
          parsedArray1 = record.result[0]?.dosArrayEobRec;
          parsedArray2 = record.result[0]?.dosArrayPaid;
          parsedArray3 = record.result[0]?.dosArrayRecieved;
          parsedArray4 = record.result[0]?.dosArray;
        }
        let parArr=[]
        try{
          parArr=JSON.parse(record?.result[0]?.image)

        }catch{
          parArr=record?.result[0]?.image

        }
        
 sethelpingState((prev) => ({ ...prev, bucketImages:record?.result[0]?.image,updatedblodState:parArr }));
 setRecords((prev)=>({...prev,image:parArr}))//image
 setFileList(parArr)//file

        setRecords(record.result[0]);
        // setRecords((prevResult) => ({
        //   ...prevResult,
        //   eobbf: record.result[0]?.eobspending,
        //   claimbf: record.result[0]?.claimspending

        // }));
        setBfScreenArray(record?.dosArrayRecieved);

        const newArray = transformArray(record?.dosArrayRecieved);
        let combineArray;
        const result = checkArraysHaveSameDateAndAmount(
          newArray,
          JSON.parse(record.result[0]?.dosArrayRecieved)
        );
        if (result == false) {
          combineArray = JSON.parse(record.result[0]?.dosArrayRecieved);
        } else {
          let arr=[]
          try{
            arr=JSON.parse(record.result[0]?.dosArrayRecieved)

          }catch{
            arr=record.result[0]?.dosArrayRecieved

          }
          if(newArray==false){
            combineArray= [].concat(arr)
        
          }else{
            combineArray= newArray.concat(arr)
        
        
          }
          
        }

        setDosArrayRec(combineArray);
        // setDosArrayRec(parsedArray3)

        setRecords((prevResult) => ({
          ...prevResult,
          eobbf: record.result[0]?.eobbf,
          claimbf: record.result[0]?.claimbf,
          providerVal: obj?.providerval,

          codingVal: obj?.codingval,
          credientialVal: obj?.credientialVal,
          billingVal: obj?.billingval,
          patVal: obj?.patVal,

          comments: record?.result[0]?.comments,
          image: record?.result[0]?.image,
          dosArray: parsedArray4,
          dosArrayDenied: parsedArray,
          dosArrayEobRec: parsedArray1,
          dosArrayPaid: parsedArray2,
          dosArrayRecieved: parsedArray3,
          eraAmount: record?.result[0].eraAmount,
        }));
        // setEobRef(record?.result[0]?.eobRef)
      }
    }
  };

  const onPreview = (val, row) => {
    let obj = { bucketImages: val };
    sethelpingState((prev)=>({...prev,getSelectedRowDocument:obj}))
    sethelpingState((prev) => ({
      ...prev,
      //  base64: objRes.base64String,
      isFileViewerOpen: true,
    }));
   
   
  };

  const onRemove = (val) => {
    let remainingObjects = FileList.filter(
      (obj) => obj.fileKey !== val.fileKey
    );
    setFileList(remainingObjects);
    setRecords((prevResult) => ({ ...prevResult, image: remainingObjects }));
    //setImage(remainingObjects)
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setRecords({ ...records, [name]: value });
  };

  const onSave = async () => {
    sethelpingState((prev) => ({ ...prev, loadingn: true }));
    const breakupval = `{
      "billingval": ${records.billingVal},
      "codingval": ${records.codingVal},
      "providerval": ${records.providerVal},
      "patVal": ${records.patVal},
      "credientialVal": ${records.credientialVal}
    }`;

    if(dosArrayRecieved.length==0){
      toast.info('For Save! Please Add Some Dos Wise Record')
      sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
      return

    }

    const formDataForSaveApi = new FormData();
    formDataForSaveApi.append("userid", onLoginSignOff.userid);
    formDataForSaveApi.append("eobRef", eobRef);

    formDataForSaveApi.append("sdate", date);
    formDataForSaveApi.append("pid", practiceId);
    formDataForSaveApi.append("formtype", formTypeNoForApi);
    formDataForSaveApi.append("submittype", "No");
    formDataForSaveApi.append("eobbf", records.eobbf);
    formDataForSaveApi.append("eobrecieved", records.eobrecieved);
    formDataForSaveApi.append("eobworked", records.eobworked);

    formDataForSaveApi.append("eobspending", records.eobspending);
    formDataForSaveApi.append("claimbf", records.claimbf);
    formDataForSaveApi.append("claimrecieved", records.claimrecieved);
    formDataForSaveApi.append("paidclaims", records.paidclaims);
    formDataForSaveApi.append("denials", records.denials);
    formDataForSaveApi.append("partiallyPaid", records.partiallyPaid);
    formDataForSaveApi.append("totalclaimsworked", records.totalclaimsworked);
    formDataForSaveApi.append("claimspending", records.claimspending);

    formDataForSaveApi.append("eraAmount", records.eraAmount);
    formDataForSaveApi.append("breakupval", breakupval);

    formDataForSaveApi.append("payments", records.payments);
    formDataForSaveApi.append("insurancep", records.insurancep);
    formDataForSaveApi.append("patientsp", records.patientsp);
    formDataForSaveApi.append("adjustmentp", records.adjustmentp);
    formDataForSaveApi.append("denialsamount", records.denialsamount);
    formDataForSaveApi.append("GeneralOpen", records.GeneralOpen);
    formDataForSaveApi.append("insurance", records.insurance);
    formDataForSaveApi.append("totalWorkedAmountAllSum", records.totalWorkedAmountAllSum);
    formDataForSaveApi.append("stoken", records.stoken);
    if (records?.image && records?.image.length > 0 && records?.image!="[]") {
      records?.image.forEach((el, index) => {
        formDataForSaveApi.append(`file`,el?.blobFile );
      });
    } else {
      formDataForSaveApi.append(`file`, null);
    }

    formDataForSaveApi.append("file", records.image);
    formDataForSaveApi.append("comments", records.comments);
    //Amoyunt
    formDataForSaveApi.append(
      "ReceivedAmountTotal",
      records?.ReceivedAmountTotal
    );
    formDataForSaveApi.append(
      "CollectedAmountTotal",
      records?.CollectedAmountTotal
    );
    formDataForSaveApi.append(
      "pendingAmountTotal",
      records?.pendingAmountTotal
    );

    formDataForSaveApi.append(
      "dosArrayDenied",
      JSON.stringify(records.dosArrayDenied)
    );
    formDataForSaveApi.append("dosArray", JSON.stringify(records.dosArray));
    formDataForSaveApi.append(
      "dosArrayPaid",
      JSON.stringify(records.dosArrayPaid)
    );
    formDataForSaveApi.append(
      "dosArrayEobRec",
      JSON.stringify(records.dosArrayEobRec)
    );
    formDataForSaveApi.append(
      "dosArrayRecieved",
      JSON.stringify(dosArrayRecieved)
    );

    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    }
    insertFromType5EobsRecord(formDataForSaveApi)
      .then((result) => {
        if (
          result?.data &&
          result?.data.message == "Record Updated SuccessFully"
        ) {
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
          toast.info(result?.data.message);
          clearAllFileds();
          onRecordUpdateOrCreated("Eobs");
        }
        if (
          result?.data &&
          result?.data.message == "Record Created SuccessFully"
        ) {
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
          toast.info(result?.data.message);
          clearAllFileds();
          onRecordUpdateOrCreated("Eobs");
        }
      })
      .catch((err) => {
        if (
          err?.response?.data &&
          err?.response?.data?.message ==
            "Breakup should be equal to pending value!"
        ) {
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
          toast.info(err?.response?.data?.message);
        }
      });
  };
  const handleInputChangef = (event) => {
    const selectedImage = event;
    setRecords((prev)=>({...prev,image:selectedImage}))

   // setImage(selectedImage);

    setFileList(selectedImage);
  };
  const clearAllFileds = () => {
    setRecords(obj);
  };
  const handleInputChange = (event) => {
    setRecords({ ...records, image: event[0] });
  };
  const openImageViwerModel = (val) => {
    setDocViewer(val);
    sethelpingState((prev) => ({ ...prev, isFileViewerOpen: val }));
  };

  const handleChangePartially = (e) => {
    const { value, name } = e.target;
    setPartially((prev) => ({ ...prev, partially: value }));
  };

  //---------------
  const handleCommonEdit = async (rowData, arg) => {
   
    if (rowData.AmountDosValCC) {
      if (rowData.bfRow == true) {
        setisThisbfRow(true);
      } else {
        setisThisbfRow(false);
      }

      let obj = {
        isEdit: true,
        rowDataa: rowData,
      };
      setGridEdit(obj);

      sethandleInputRec(rowData.recievedAmountCC);
      setEobRef(rowData.eobRef); // rECEIVED AMOUNT
      setAmountDosRec(rowData.AmountDosValCC); //PAID
      setRowID(rowData.id);
      sethandleInput(rowData.recievedAmount); //DENEID

      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      setPaymentState((prev) => ({
        ...prev,
        insurancePar: rowData.insurancePar,
        PatientsPar: rowData.PatientsPar,
        AdjustmentsPar: rowData.AdjustmentsPar,
        DenialAmountPar: rowData.DenialAmountPar,
        paymentsTotalPar: rowData.paymentsTotalPar,
       // DenialAmountPar: rowData.DenialAmountPar,
        insurance: rowData.insurance,
        Patients: rowData.Patients,
        Adjustments: rowData.Adjustments,
        DenialAmount: rowData.DenialAmount,
        paymentsTotal: rowData.paymentsTotal,
        PPC:rowData?.PPC,
        PPA:rowData?.PPA
      }));
      setStateAmount((prev) => ({
        ...prev,
        recAmountGen: rowData.recievedAmountgGen,
        CollectAmountGen: rowData.collectedAmountgGen,
        pendinGen: rowData.pendinGen,
      }));
      setBreakupVal((prev)=>({...prev,
        coding: rowData.coding,
        Billing: rowData.Billing,
        Credientialing: rowData.Credientialing,
        Provider: rowData.Provider,
        Patient: rowData.Patient,
        }))
        setCheckDate(rowData?.checkDate)
        setPartially((prev)=>({...prev,partially:rowData.partially}))
    } else {
      return;
    }
  };
  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }
    if (arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray = parseArr && parseArr.filter((item) => item?.bfVal !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        recievedAmountCC: item?.bfVal?.toString(),
        //AmountDosVal: item?.bfAmount?.toString(),
        recievedAmount: "0",
        AmountDosValCC: "0",
        totalClaimWorked: "0",
        pendingClaim: "0",
        paymentsTotal: "0",
        partially: "0",
        insurance: "0",
        Patients: "0",
        Adjustments: "0",
        DenialAmount: "0",
        providerVal: "0",
        codingVal: 0,
        credientalingVal: 0,
        operationVal: 0,
        EdiVal: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };

  const handleChangeCommondos = (id, key, value, event, arg) => {
    if (arg == "paidClaim") {
      handleChangeC(id, key, value, event, dosArrayPaid, setDosArrayPaid);
    } else if (arg == "EobRec") {
      handleChangeC(id, key, value, event, dosArrayEobRec, setDosArrayEobRec);
    } else if (arg == "DeniedClaim") {
      handleChangeC(id, key, value, event, dosArrayDenied, setDosArrayDenied);
    } else {
      handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
    }
  };
  const onSaveDosCo = (arg) => {
  
   
    if(eobRef=="" && stateAmount?.CollectAmountGen=="" && stateAmount?.recAmountGen=="" &&  handleInputdosRec=="" &&  AmountDosRec=="" && handleInputdos=="" && partially?.partially=="" && paymentstate?.insurancePar=="" && paymentstate?.Patients=="" && paymentstate?.PatientsPar=="" && paymentstate?.Adjustments=="" && paymentstate?.AdjustmentsPar=="" && paymentstate?.DenialAmount=="" && paymentstate?.DenialAmountPar=="" && (dosArrayRecieved.length==0||dosArrayRecieved.length<0)  )
   {
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    callGlobalDos(false);

    onSaveDosC(
      true,
      dosArrayRecieved,
      setRecords,
      setDosModelRec,
      setRecords,
      "eobs",
      sethandleInputRec,
      setdosDateRec,
      null,
      stateAmount
    );

    setisThisbfRow(false);
    sethandleInputRec("");
    setAmountDosRec("");
    sethandleInput("");
    setPendingClaimss(0);
    setTotalClaims(0);
    setPartially((prev) => ({
      ...prev,
      partially: 0,
      isShowPartiallyBreakup: false,
    }));
    setPaymentState(objPayment);
    sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
  }else{
    sethelpingState((prev) => ({ ...prev, isSpeakToaster: true }));
  }
    
  };
  const onChangeInputCC = (val) => {
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }));

    onChangeInputC(
      val,
      sethandleInputRec,
      setErrorModel,
      "stopEecutingErrModel"
    );
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayDenied);
  };

  const onChangeDateCheck=(val)=>{
    let date= moment(val).format('YYYY-MM-DD');
    setCheckDate(date)
    if(val){
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        checkDate: false,
      }))

    }
    

   
  }
  const onChangeDAmountCC = (val) => {
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosValCC: false,
    }));
    onChangeAmountC(
      val,
      setAmountDosRec,
      setErrorModel,
      "stopEecutingErrModel"
    );
  };

  const onHandleCloseCall = () => {
    if (gridEdit.isEdit) {
      onAdddosCC();
    }

    callGlobalDos(false);

    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);

    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
      recievedAmountCC: false,
      AmountDosValCC: false,
      date: false,
    }));
  };
  const handleCloseCC = (arg) => {
    sethelpingState((prev) => ({ isBackSpeak: true }));
  };
  const onAdddosCC = (arg) => {
 
    if (
      isPendingBreakOpen &&
      parseFloat(handleInputdos) != parseFloat(AllBreakupVal.totalDenial)
    ) {
      toast.info("Denail Claims Should be Equal to Denail Breakup Total");
      return;
    }
    let total = totalWorkedAmount
    // if (parseFloat(stateAmount?.recAmountGen) != total) {
    //   toast.info('Total Worked Amount should be equal to Billed Amount')
       
    //   return;
    // }

    onAdddosC(
      handledosDateRec, //date
      handleInputdosRec, //claims Received
      dosArrayRecieved, //Array
      AmountDosRec, //paid
      setDosArrayRec, // setdosArrayReceived
      setErrorModel, //errormodel
      "Eob",
      "Eob",
      paymentstate,
      handleCheckDate,
      handleInputdos,
      totalWorkedAmount,
      null,
      null,
      gridEdit,
      rowID,
      null,
      bfRow,
      partially.partially,
      stateAmount,
      AllBreakupVal,
      eobRef
    );
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    setisThisbfRow(false);
    sethandleInputRec("");
    setAmountDosRec("");
    sethandleInput("");
    setPendingClaimss(0);
    setTotalClaims(0);
    setTotalAmount("")
    setStateAmount((prev)=>({
      ...prev,
      CollectAmountGen:"",
      recAmountGen:""

    }))
    setEobRef("")
    setPartially((prev) => ({
      ...prev,
      partially: "",
      isShowPartiallyBreakup: false,
    }));
    setPaymentState(objPayment);
  };

  const onHandlePendingClaimss = (val) => {
    if(parseFloat(val)<0){
      toast.info('Pending Claims value must be a positive number. Please verify your calculations.')
      
     }

    setPendingClaimss(val);
  };
  const openDosmodelWorked = () => {
    setNameGeneric("Claims Received");
    setDosModelRec(true);
  };
  const openDosmodelWorkedPaid = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Paid Claims");
    setDosModelRecPaid(true);
  };
  const openDosmodelWorkedDenied = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Denied Claims");
    setDosModelRecDenied(true);
  };
  const openDosmodelWorkedEobRec = () => {
    setDosModelRecEob(true);
  };
  const renderr = (val) => {
    setRenderEffect(val);
  };
  const onCleanDateSimple = () => {
    setdosDate("");
  };
  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };

  const onCleanDateCheckDate= () => {
   setCheckDate("");
  };

  const [openModelPending, setModelPending] = useState(false);
  const openDosmodelPending = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }

    setModelPending(true);
  };

  const handleClosePopPending = () => {
    setNameGeneric("EOB`s");
    setModelPending(false);
  };
  // new Things

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  useEffect(() => {
    let paresedArray2;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArrayRecieved;
    }
    if (paresedArray2.length > 0) {
      const sumProperty = (property) =>
        paresedArray2.reduce(
          (sum, obj) => sum + (parseFloat(obj[property]) || 0),
          0
        );

      setRecords((prev) => ({
        ...prev,
        insurance:
          parseFloat(sumProperty("insurance")) +
          parseFloat(sumProperty("insurancePar")),
        patientsp:
          parseFloat(sumProperty("Patients")) +
          parseFloat(sumProperty("PatientsPar")),
        adjustmentp:
          parseFloat(sumProperty("Adjustments")) +
          parseFloat(sumProperty("AdjustmentsPar")),
        denialsamount:
          parseFloat(sumProperty("DenialAmount")) +
          parseFloat(sumProperty("DenialAmountPar")),
        ProviderOverAll: sumProperty("coding"),
        codingOverAll: sumProperty("Provider"),
        PatientOverAll: sumProperty("Patient"),
        BillingOverAll: sumProperty("Billing"),
        CredientialingOverAll: sumProperty("Credientialing"),
        totalWorkedAmountAllSum:sumProperty("totalWorkedAmount")
      }));
    }
  }, [dosArrayRecieved]);
  console.log("Records", records, dosArrayRecieved);

  const [openBf, setBf] = useState(false);
  const openBfScreen = () => {
    setBf(true);
  };
  const handleCloseBfScreen = () => {
    setBf(false);
  };

  const handleOnChangeeobRef = (e) => {
    const { value, name } = e.target;
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      eobRef: false,
    }));

    setEobRef(value);
  };

  useEffect(() => {
    if(helpingState?.isAllStatesAreSet){
      onSave()
      
    }
   
  }, [helpingState?.isAllStatesAreSet])
  

  const handleSwalConfirm = (val) => {
  
    if (val==true) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);

      onSaveDosC(
        true,
        dosArrayRecieved,
        setRecords,
        setDosModelRec,
        setRecords,
        "eobs",
        sethandleInputRec,
        setdosDateRec,
        null,
        stateAmount
      );

      setisThisbfRow(false);
      sethandleInputRec("");
      setAmountDosRec("");
      sethandleInput("");
      setPendingClaimss(0);
      setTotalClaims(0);
      setPartially((prev) => ({
        ...prev,
        partially: 0,
        isShowPartiallyBreakup: false,
      }));
      setPaymentState(objPayment);
      sethelpingState((prev)=>({...prev,isAllStatesAreSet:true}))
    } else if(!val) {
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
      
    }
  else if(val=='backToMainScreen'){
    sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
    sethandleInputRec("");
      setAmountDosRec("");
      sethandleInput("");
      setPendingClaimss(0);
      setTotalClaims(0);
      setTotalAmount("")
      setStateAmount((prev)=>({
        ...prev,
        CollectAmountGen:"",
        recAmountGen:""
  
      }))
      callGlobalDos(false);
      setEobRef("")
      setPartially((prev) => ({
        ...prev,
        partially: "",
        isShowPartiallyBreakup: false,
      }));
      setPaymentState(objPayment);

  }

  };

  const handleSwalConfirm2 = (val) => {
    if (val) {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
      onSave();
    } else {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
    }
  };
  const handleSwalConfirmBackButton = (val) => {
    if (val) {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
      onHandleCloseCall();
    } else {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
    }
  };
  console.log('statwAmount',stateAmount)
  let pendingSectionhtml = (
    <>
    
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            value={records?.ProviderOverAll}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            value={records?.codingOverAll}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientalingVal"
            placeholder=""
            value={records?.CredientialingOverAll}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="EdiVal"
            placeholder=""
            value={records?.PatientOverAll}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Billing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="operationVal"
            placeholder=""
            value={records?.BillingOverAll}
            onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={AllBreakupVal?.total}
            onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
    </>
  );


  const handleKeyPress = (e) => {
    
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (charCode === 8 || (charCode >= 48 && charCode <= 57) || ( charCode != 190 && charCode != 110)) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };

  console.log('helpingState.isSpeakToaster',helpingState.isSpeakToaster);
  return (
    <>
     {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      {helpingState.isSpeakToaster==true ? (
        <NotifySwal
        showDenyButton={true}
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
        />
      ) : null}
      {helpingState.isSpeakerToaster==true ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm2}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {helpingState.isBackSpeak==true ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmBackButton}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {openBf == true ? (
        <>
          <DateoofserviceBF
            handleClose={handleCloseBfScreen}
            dosArray={bfScreenArray}
          />
        </>
      ) : openScreenGlobalTable == true ? (
        <Dateoofservice
        DenialClaimBreakup={true}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={dosArrayRecieved}
          handleClose={handleClose}
          claimHeading={"Claims Received"}
          claimAmount={"Paid"}
          columnHeader1={"Denied"}
          columnHeader2={"Total Worked Claims"}
          columnHeader3={"Pending Claims"}
          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"Eob"}
          flag={"Eob"}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal
        handleKeyPress={handleKeyPress}
        totalWorkedAmount={totalWorkedAmount}
        
        onCleanDateCheckDate={onCleanDateCheckDate}
        onChangeDateCheck={onChangeDateCheck}
          isAllowOpeneinalbreakup={isPendingBreakOpen}
          eobRef={eobRef}
          handleOnChangeeobRef={handleOnChangeeobRef}
          stateAmount={stateAmount}
          handleChangeAmount={handleChangeAmount}
          partially={partially}
          handleChangePartially={handleChangePartially}
          Footer={footerState}
          flagFooter={"Eob"}
          paymentstate={paymentstate}
          payments={onChangePaymwents}
          changeState={"Eob"}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
          AmountDos={totalClaims}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={setTotalClaims}
          // pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}
          onChangeInputCC={onChangeInputCC}
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          // onChangeAmount={onChangeAmount}
          onChangeAmountCC={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          claimHeading={"Claims Denied"}
          claimAmount={"Total Claims Worked"}
          claimHeading1={"Claims Received"}
          claimAmount1={"Claims Paid"}
          practiceId={practiceId}
          onlyShowTable={false}
          flag={"Eob"}
          realFlag={"Era"}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          recievedClaimArraydos={dosArrayRecieved}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={() => handleChangeCommondos("claimRec")}
          handleEditState={() => handleCommonEdit("claimRec")}
          onSaveDos={() => onSaveDosCo("claimRec")}
          dosArray={dosArrayRecieved}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          onAdddos={() => onAdddosCC("claimRec")}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={() => handleCloseCC("claimRec")}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Recieved Amount"}
        />
      ) : openDosmodelRecStatePaid == true ? (
        <Dateoofservice
          //For Paid
          recievedClaimArraydos={dosArrayRecieved}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          pendingClaimss={pendingClaimss}
          handleChange={() => handleChangeCommondos("paidClaim")}
          handleEditState={() => handleCommonEdit("paidClaim")}
          onSaveDos={() => onSaveDosCo("paidClaim")}
          setDosArray={{ state: setDosArrayPaid, flag: "1" }}
          dosArray={dosArrayPaid}
          onAdddos={() => onAdddosCC("paidClaim")}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={() => handleCloseCC("paidClaim")}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Paid Claims"}
          claimAmount={"Paid Amuont"}
        />
      ) : openDosmodelRecStateDenied == true ? (
        <Dateoofservice
          AllBreakupVal={AllBreakupVal}
          deductArray={dosArrayPaid}
          handledosDate={handledosDateRec}
          recievedClaimArraydos={dosArrayRecieved}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          AmountDos={"ok"}
          handleInputdos={handleInputdosRec}
          dosPendingBreakup={{ isAllow: true, onChangeValues: handleChange }}
          allowPending={true}
          isPendingSectionOpen={isPendingSectionOpen}
          setisPendingSectionOpen={setisPendingSectionOpen}
          onlyShowTable={false}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          onChangeBreakupClaim={onChangeBreakupClaim}
          setdosDate={setdosDateRec}
          handleChange={() => handleChangeCommondos("DeniedClaim")}
          handleEditState={() => handleCommonEdit("DeniedClaim")}
          onSaveDos={() => onSaveDosCo("DeniedClaim")}
          setDosArray={{ state: setDosArrayDenied, flag: "1" }}
          dosArray={dosArrayDenied}
          onAdddos={() => onAdddosCC("DeniedClaim")}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={() => handleCloseCC("DeniedClaim")}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Denied Claims"}
          claimAmount={"Denied Amuont"}
        />
      ) : openDosmodelRecStateEob == true ? (
        <Dateoofservice
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={() => handleChangeCommondos("EobRec")}
          handleEditState={() => handleCommonEdit("EobRec")}
          onSaveDos={() => onSaveDosCo("EobRec")}
          setDosArray={{ state: setDosArrayEobRec, flag: "1" }}
          dosArray={dosArrayEobRec}
          onAdddos={() => onAdddosCC("EobRec")}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={() => handleCloseCC("EobRec")}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"EOB`s Received"}
          claimAmount={"EOB`s Amuont"}
        />
      ) : openModelPending ? (
        <Dateoofservice
          dosArrayPaid={dosArrayPaid}
          showTableCheck={"eob"}
          onlyShowTable={true}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={dosArrayDenied}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
        />
      ) : (
        <>
          {/* <DocViewer
            docViewer={docViewer}
            image={records?.image}
            openImageViwerModel={openImageViwerModel}
          /> */}
              {helpingState?.isFileViewerOpen == true && (
            // <DocViewer
            //   docViewer={true}
            //   image={helpingState?.base64}
            //   openImageViwerModel={openImageViwerModel}
            // />
            <ViewDocument
              seletedRow={helpingState?.getSelectedRowDocument}
              openImageViwerModel={openImageViwerModel}
            />
          )}

          <div class="row">
            <DateAndPractice
            nowShowExtraDiv={true}
            payerSelection={payerSelection}
            onChangePayer={onChangePayer}
            showPayment={true}
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={products}
              date={date}
            />
             <Panel defaultExpanded={true} header="Payment Posting" collapsible >
              <div className="row mt-4">
            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EOB`s B/F{" "}
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="eobbf"
                  placeholder=""
                  disabled
                  value={records?.eobbf}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EOB`s Received{" "}
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="eobrecieved"
                  placeholder=""
                  value={records?.eobrecieved}
                  onChange={handleChange}
                />
                {/* <CustomInputGroupWidthButtonC
                  value={records?.eobrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorkedEobRec}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EOB`sWorked{" "}
                </label>
                <input
                  class="form-control inputField"
                  type=""
                  name="eobworked"
                  placeholder=""
                  value={records?.eobworked}
                  onChange={handleChange}
                />
                {/* <CustomInputGroupWidthButton
                  value={records.eobworked}
                  disabled={true}
                  size="md"
                  onClick={openDosmodel}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EOB`sAmount{" "}
                </label>
                <input
                  class="form-control inputField"
                  type=""
                  name="eraAmount"
                  placeholder=""
                  value={records?.eraAmount}
                  onChange={handleChange}
                />
                {/* <CustomInputGroupWidthButton
                  value={records.eobworked}
                  disabled={true}
                  size="md"
                  onClick={openDosmodel}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EOB`sPending{" "}
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="eobspending"
                  placeholder=""
                  disabled
                  value={records?.eobspending}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label
                  class="inputLabel"
                  style={{ color: "black" }}
                  onClick={openBfScreen}
                >
                  Claims B/F{" "}
                  {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="claimbf"
                  placeholder=""
                  disabled
                  value={records?.claimbf}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Claims Received{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={records?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={records?.claimrecieved}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Claims Paid{" "}
                  </label>
                  <input
                    class="form-control inputField"
                    type="number"
                    name="paidclaims"
                    placeholder=""
                    value={records?.paidclaims}
                    onChange={handleChange}
                    disabled
                  />
                  {/* <CustomInputGroupWidthButtonC
                    value={records?.paidclaims}
                    disabled={true}
                    size="md"
                    onClick={openDosmodelWorkedPaid}
                    placeholder="Medium"
                  /> */}
                </div>
                <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Claims Denied{" "}
                  </label>
                  <input
                    class="form-control inputField"
                    type="text"
                    name="denials"
                    placeholder=""
                    value={records?.denials}
                    onChange={handleChange}
                    disabled
                  />
                  {/* <CustomInputGroupWidthButtonC
                    value={records?.denials}
                    disabled={true}
                    size="md"
                    onClick={openDosmodelWorkedDenied}
                    placeholder="Medium"
                  /> */}
                </div>
                <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Claims Partially Paid{" "}
                  </label>
                  <input
                    class="form-control inputField"
                    type="text"
                    name="partiallyPaid"
                    placeholder=""
                    value={records?.partiallyPaid}
                    onChange={handleChange}
                    disabled
                  />
                  {/* <CustomInputGroupWidthButtonC
                    value={records?.denials}
                    disabled={true}
                    size="md"
                    onClick={openDosmodelWorkedDenied}
                    placeholder="Medium"
                  /> */}
                </div>
              </div>
            </div>

            <div class="col-sm-2">
            <div className="ebos">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  TC Worked{" "}
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="totalclaimsworked"
                  placeholder=""
                  disabled
                  value={records?.totalclaimsworked}
                  onChange={handleChange}
                />
                {/* <CustomInputGroupWidthButton
                  value={records?.totalclaimsworked}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
              </div>
              <div class="form-group">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total Worked Amount{" "}
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="totalWorkedAmountAllSum"
                 
                  disabled
                  value={records?.totalWorkedAmountAllSum}
                //  onChange={handleChange}
                />
                {/* <CustomInputGroupWidthButton
                  value={records?.totalclaimsworked}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
              </div>
              </div>
            </div>

            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Claims Pending{" "}
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimspending"
                  placeholder=""
                  disabled
                  value={records?.claimspending}
                  // onChange={handleChange}
                />
              </div>
            </div>
           

            {/* <div class="col-sm-2 col-lg-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Claims Received Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
               
                  value={records?.ReceivedAmountTotal}
                  disabled
                />
                
              </div>
            </div> */}

            {/* <div class="col-sm-2 col-lg-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Claims Collected Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                
                 
                  value={records?.CollectedAmountTotal}
                  disabled
                />
                
              </div>
            </div> */}
            {/* <div class="col-sm-2 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Claims  Pending Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                
                  value={records?.pendingAmountTotal}
                  disabled
                />
                
              </div>
            </div> */}

            {/* <div class="col-sm-2">
              
            </div>
            <div class="col-sm-2">
              
            </div> */}
            <div class="col-sm-2"></div>

            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total Payments{" "}
                </label>
                <div class="form-group input-icon">
                  <input
                    class="form-control inputField"
                    type="text"
                    name="payments"
                    placeholder=""
                    disabled
                    value={records?.payments}
                    onChange={handleChange}
                  />
                  <i className="lightGreen">$</i>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Insurance Payments{" "}
                </label>

                <div class="form-group input-icon">
                  <input
                    class="form-control inputField"
                    type="text"
                    name="insurance"
                    placeholder=""
                    onChange={handleChange}
                    value={records?.insurance}
                    disabled
                  />
                  <i className="lightGreen">$</i>
                </div>
              </div>
            </div>

            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Patient Responsibility{" "}
                </label>

                <div class="form-group input-icon">
                  <input
                    class="form-control inputField"
                    type=""
                    name="patientsp"
                    placeholder=""
                    onChange={handleChange}
                    value={records?.patientsp}
                    disabled
                  />
                  <i className="lightGreen">$</i>
                </div>
              </div>
            </div>

            <div class="col-sm-2">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Adjustments{" "}
                </label>
                <div class="form-group input-icon">
                  <input
                    class="form-control inputField"
                    type="text"
                    name="adjustmentp"
                    placeholder=""
                    onChange={handleChange}
                    value={records?.adjustmentp}
                    disabled
                  />
                  <i className="lightGreen">$</i>
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <div class="form-group ">
                <label class="inputLabel" style={{ color: "black" }}>
                  Denials Amount{" "}
                </label>
                <div class="form-group input-icon">
                  <input
                    class="form-control inputField"
                    type="text"
                    name="denialsamount"
                    placeholder=""
                    onChange={handleChange}
                    value={records?.denialsamount}
                    disabled
                  />
                  <i className="lightGreen">$</i>
                </div>
              </div>
            </div>
            </div>
            </Panel>
            <div className="mt-4"></div>
            {<Panel defaultExpanded={true} header="Denials Claims Breakup" collapsible >
            {<div className="row mt-4">{pendingSectionhtml}</div>}
              </Panel>}

            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Denied Claim's Breakup
              </h4>
            </div> */}

            {/* <div class="form-group col-lg-2 mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Coding{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="codingVal"
                placeholder=""
                value={records?.codingVal}
                onChange={handleChange}
              />
            </div>
            <div class="form-group col-lg-2 mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Billing{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="billingVal"
                placeholder=""
                value={records?.billingVal}
                onChange={handleChange}
              />
            </div>
            <div class="form-group col-lg-3 mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Provider{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="providerVal"
                placeholder=""
                value={records?.providerVal}
                onChange={handleChange}
              />
            </div>
            <div class="form-group col-lg-2 mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Credentialing{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="credientialVal"
                placeholder=""
                value={records?.credientialVal}
                onChange={handleChange}
              />
            </div>
            <div class="form-group col-lg-2 mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Patient End{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="patVal"
                placeholder=""
                value={records?.patVal}
                onChange={handleChange}
              />
            </div> */}


            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div> */}
             <div class="mt-4"></div>
             <Panel defaultExpanded={true} header="Remarks" collapsible={true} >
           

            <div class="col-sm-12 col-lg-12 mt-4">
              <div class="form-group mb-2">
                {/* <label class="inputLabel" style={{ color: "black" }}>
                  Other Task
                </label> */}
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  name="GeneralOpen"
                  placeholder="Enter Remarks Here"
                  class="form-control inputField"
                  onChange={handleChange}
                  value={records?.GeneralOpen}
                ></textarea>

                {/* <input
                  class="form-control inputField"
                  type="text"
                  name="GeneralOpen"
                  placeholder=""
                 
                /> */}
              </div>
            </div>
            </Panel>

            {/* <div class="col-sm-6 col-lg-5">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Insurance Correspondence Received
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="insurancep"
                  placeholder=""
                  onChange={handleChange}
                  value={records?.insurancep}
                />
              </div>
            </div>

            <div class="col-sm-6 col-lg-2 d-flex ">
              <div class="form-group mb-2 ">
                <label class="inputLabel" style={{ color: "black" }}>
                  Action Taken{" "}
                </label>
                <div class="row ">
                  <div
                    class="form-check form-check-inline mr-4 "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      alignContent: "center",
                    }}
                  >
                    <input
                      class="form-check-input mr-2  mb-2"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="Yes"
                      name="stoken"
                      onChange={handleChange}
                      checked={records.stoken == "Yes" ? true : false}
                    />
                    <label class="mr-4" for="inlineCheckbox1">
                      Yes{" "}
                    </label>
                    <input
                      class="form-check-input   mb-2"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="No"
                      name="stoken"
                      onChange={handleChange}
                      checked={records.stoken == "No" ? true : false}
                    />
                    <label class="mr-4" for="inlineCheckbox2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {records.stoken == "Yes" ? (
              <div class="col-sm-6 col-lg-2">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Remarks
                  </label>

                  <input
                    class="form-control inputField"
                    type="text"
                    name="comments"
                    placeholder=""
                    onChange={handleChange}
                    value={records?.comments}
                  />
                </div>
              </div>
            ) : null} */}

            {/* <div class="col-sm-6 col-lg-6">
          <div class="form-group ">
            <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label>
            <form>
              <div>
                <input
                  name="image"
                  style={{ width: "81%" }}
                  onChange={handleChange}
                  type="file"
                />
               
              </div>
            </form>
          </div>
        </div> */}

            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Attachments
              </h4>
            </div> */}
            <div className="mt-4"></div>
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4 class="inputLabel " style={{ color: "black" }}>
                Attachments
              </h4>
            </div> */}
            <div class="col-sm-12 col-lg-12">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label> */}
                {/* <ImageUploaderr
                  openImageViwerModel={openImageViwerModel}
                  handleInputChange={handleInputChange}
                  image={records.image}
                /> */}
                 <FileUpload2


onRemove={onRemove}
setobjCLuster={setobjCLuster}
objectcluster={objectcluster}
helpingState={helpingState}
  onPreview={onPreview}
  fileList={FileList}
  openImageViwerModel={openImageViwerModel}
  handleInputChange={handleInputChangef}
  image={records?.image}
/>
              </div>
            </div>

            <div class="col-sm-6 col-lg-12">
              <div class="form-group ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    onClick={clearAllFileds}
                    className="btnClear mr-1 "
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    onClick={handleTaosterSpeak}
                    className="btnSave"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EobsForm;
