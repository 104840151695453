import React, { useState, useEffect } from "react";
import AmountVerification from "./AmountVerification";
import Meeting from "./Meeting";
import Reports from "./Reports";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Panel } from 'rsuite';
import {
  getUsersName,
  insertTlChargeEntry,
  searchChargeEntry,
  searchChargeEntryUser,
} from "../../../../Utils/ApiManager";
import DateAndPractice from "../../DateAndPracticeTl";
import Dateoofservice from "../../../../Common/Dateoofservice";
const ChargeEntry = ({
  handleFormTypeAndPract,
  products,
  dateAndP,
  subCheckBox,
  formTypeNoForApi,
  date,
  formType,
  practiceId,
  record,
  onRecordUpdateOrCreated,
}) => {
  const { onLoginSignOff } = useSelector((state) => state);
  let payload = {
    userid: "",
    sdate: null,
    pid: "",
    formType: "",
    submittype: "",
    receivedClaims: "",

    workedClaims: "",
    pendingClaims: "",
    receivedAmount: "",
    workedAmount: "",
    pendingAmount: "",

    totalRecievedAmountAuth: "",
    totalWorkedAmountAuth: "",
    totalpendingValAuth: "",
    totalrecievedvalClaimsAuth: "",
    totalworkedvalClaimsAuth: "",
    totalpendingvalAuth: "",

    totalRecievedAmountReject: "",
    totalWorkedAmountReject: "",
    totalrecievedvalClaimsReject: "",
    totalworkedvalClaimsReject: "",
    totalpendingValReject: "",
    totalpendingvalReject: "",

    totalRecievedVob: "",
    portals: "",
    calls: "",
  };
  const [state, setState] = useState(payload);

  const [tableState, setTableState] = useState([]);
  let helpingObj = {
    loading: false,
    isSwitchTable: false,
    holdSubDosTableData: [],
    getAllBellUsers: "",
    RejectionTableState: "",
    AuthorizationTableState: "",
    VobsTableState: "",
    dosArrayRecievedCharge: [],
    dosArrayRecievedAuth: [],
    dosArrayRecievedVob: [],
    dosArrayRecievedRejection: [],
    isToggleEffect: false,
  };
  const [helpingStatem, setHelpingState] = useState(helpingObj);
  useEffect(() => {
    getAllUser();
  }, []);
  const getAllUser = async () => {
    let res = await getUsersName();
    setHelpingState((prev) => ({ ...prev, getAllBellUsers: res }));
  };

  useEffect(() => {
    if (record) {
      setState((prev) => ({
        ...prev,
        receivedClaims: record?.ReceivedClaims,
        workedClaims: record.WorkedClaims,
        pendingClaims: record?.PendingClaims,
        receivedAmount: record.ReceivedAmount,
        workedAmount: record.CollectedAmount,
        pendingAmount: record?.PendingAmount,

        totalRecievedAmountAuth: record?.totalRecievedAmountAuth,
        totalWorkedAmountAuth: record?.totalWorkedAmountAuth,
        totalpendingValAuth: record?.totalpendingValAuth,
        totalrecievedvalClaimsAuth: record?.totalrecievedvalClaimsAuth,
        totalworkedvalClaimsAuth: record?.totalworkedvalClaimsAuth,
        totalpendingvalAuth: record?.totalpendingvalAuth,

        totalRecievedAmountReject: record?.totalRecievedAmountReject,
        totalWorkedAmountReject: record?.totalWorkedAmountReject,
        totalrecievedvalClaimsReject: record?.totalrecievedvalClaimsReject,
        totalworkedvalClaimsReject: record?.totalworkedvalClaimsReject,
        totalpendingValReject: record?.totalpendingValReject,
        totalpendingvalReject: record?.totalpendingvalReject,

        totalRecievedVob: record?.totalRecievedVob,
        portals: record?.portals,
        calls: record?.calls,
        parsedBreakupValsCharge:record?.parsedBreakupValsCharge,
        parsedBreakupValsReject:record?.parsedBreakupValsReject,
        parsedVobbreakupval:record?.parsedVobbreakupval,
        parsedBreakupValsAuth:record?.parsedBreakupValsAuth
      }));
      // setState(record?.result[0])
    }
  }, [record, practiceId]);

  const onclear = () => {
    setState(payload);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };
  // this will convert userif into Name
  const convertUseridIntoUserName = async (data) => {
    let res = helpingStatem.getAllBellUsers;

    const mergedData =
      data.length > 0 &&
      data.map((user) => ({
        ...user,
        ...(res && res?.data.find((item) => item.userid === user.userid)),
      }));
    return mergedData;
  };

  const onSearch = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      setHelpingState((prev) => ({ ...prev, loading: true }));
      let obj = {
        userid: onLoginSignOff.userid,
        sdate: date,
        pid: practiceId,
        formtype: 1,
      };

      let resultt = await searchChargeEntryUser(obj);
      if (resultt?.data) {
        let convertedData = await convertUseridIntoUserName(
          resultt?.data.chargeEntry
        );
        let convertedDataReject = await convertUseridIntoUserName(
          resultt?.data.Rejection
        );
        let convertedDataAuth = await convertUseridIntoUserName(
          resultt?.data.Auth
        );
        let convertedDatavobs = await convertUseridIntoUserName(
          resultt?.data.vobs
        );
        console.log('convertedData',convertedDatavobs)
        const formattedArrayCharge = convertedData && convertedData.map((item) => {
          return {
            ...item,
            recievedamount: isNaN(Number(item.recievedamount)) ? 0 : Number(item.recievedamount).toLocaleString(),
            workedamount: isNaN(Number(item.workedamount)) ? 0 : Number(item.workedamount).toLocaleString(),
            pendingamountval: isNaN(Number(item.pendingamountval)) ? 0 : Number(item.pendingamountval).toLocaleString(),
            pendingval: isNaN(Number(item.pendingval)) ? 0 : Number(item.pendingval),
            workedval: isNaN(Number(item.workedval)) ? 0 : Number(item.workedval),
            recievedval:isNaN(Number(item.recievedval))?0:Number(item.recievedval)
          };
        });
        setTableState(formattedArrayCharge);


        const formattedArrayReject = convertedDataReject && convertedDataReject.map((item) => {
          return {
            ...item,
            recievedamount: isNaN(Number(item.recievedamount)) ? 0 : Number(item.recievedamount).toLocaleString(),
            workedamount: isNaN(Number(item.workedamount)) ? 0 : Number(item.workedamount).toLocaleString(),
            pendingamountval: isNaN(Number(item.pendingamountval)) ? 0 : Number(item.pendingamountval).toLocaleString(),
            pendingval: isNaN(Number(item.pendingval)) ? 0 : Number(item.pendingval),
            workedval: isNaN(Number(item.workedval)) ? 0 : Number(item.workedval),
            recievedval:isNaN(Number(item.recievedval))?0:Number(item.recievedval)
          };
        });
        const formattedArrayAuth = convertedDataAuth && convertedDataAuth.map((item) => {
          return {
            ...item,
            recievedamount: isNaN(Number(item.recievedamount)) ? 0 : Number(item.recievedamount).toLocaleString(),
            workedamount: isNaN(Number(item.workedamount)) ? 0 : Number(item.workedamount).toLocaleString(),
            pendingamountval: isNaN(Number(item.pendingamountval)) ? 0 : Number(item.pendingamountval).toLocaleString(),
            pendingval: isNaN(Number(item.pendingval)) ? 0 : Number(item.pendingval),
            workedval: isNaN(Number(item.workedval)) ? 0 : Number(item.workedval),
            recievedval:isNaN(Number(item.recievedval))?0:Number(item.recievedval)
          };
        });


        const formattedArrayVob = convertedDatavobs && convertedDatavobs.map((item) => {
          return {
            ...item,
           
            portals: isNaN(Number(item.portals)) ? 0 : Number(item.portals),
            calls: isNaN(Number(item.calls)) ? 0 : Number(item.calls),
            recievedval:isNaN(Number(item.recievedval))?0:Number(item.recievedval)
          };
        });
      

        setHelpingState((prev) => ({
          ...prev,
          loading: false,
          RejectionTableState: formattedArrayReject,
          AuthorizationTableState: formattedArrayAuth,
          VobsTableState: formattedArrayVob,
        }));
      } else {
        setHelpingState((prev) => ({ ...prev, loading: false }));
        toast.info("Something Went Wrong");
      }
    }
  };

  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [globalDos, openGlobalDos] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const handleClose = () => {};
  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };
  console.log("helpingStatem", helpingStatem);
  return (
    <>
      {subCheckBox == "AmountVerification" && (
        <>
          <div className="row">
            {helpingStatem.isSwitchTable == false &&
            helpingStatem.isToggleEffect != true ? (
              <DateAndPractice
                onSearch={onSearch}
                openGlobalTable={openGlobalTable}
                callGlobalDos={callGlobalDos}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                handleFormTypeAndPract={handleFormTypeAndPract}
                products={products}
                date={date}
                isShowSearchButton={true}
              />
            ) : null}
          </div>
         
          <AmountVerification
          setHelpingState={setHelpingState}
           
            helpingStatem={helpingStatem}
            setTableState={setTableState}
            tableState={tableState}
            onSearch={onSearch}
            handleFormTypeAndPract={handleFormTypeAndPract}
            products={products}
            onLoginSignOff={onLoginSignOff}
            state={state}
            handelChange={handelChange}
            onclear={onclear}
            record={record}
            formTypeNoForApi={formTypeNoForApi}
            date={date}
            formType={formType}
            practiceId={practiceId}
          />

         

          
          <div class="col-sm-6 col-lg-12 mt-2">
            {helpingStatem.isSwitchTable == false &&
            helpingStatem.isToggleEffect != true ? (
              <div class="form-group mb-2 ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    onClick={onclear}
                    className="btnClear mr-1 "
                  >
                    Clear
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ChargeEntry;
