import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "rsuite";
import { DatePicker, Stack } from "rsuite";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";

import EditIcon from "@rsuite/icons/Edit";
import { toast } from "react-toastify";
import UserAuditTable from "../../Audit/userTaskViewTable";

export default function DateoofserviceBF({
  setDosArray,
  setBfScreenArray,
  dosArray,
  handleClose,
  gridEdit,
  handleEditState,
  handleDelete

 
}) {
  useEffect(() => {
    if(gridEdit?.isBfEdit){
      handleDeleteBf(gridEdit?.rowDataa)

    }
    
  
  }, [gridEdit?.isBfEdit])
  
  console.log('dosArray',dosArray)
  let parsedArray = [];
  try {
    parsedArray = JSON.parse(dosArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = dosArray;
  }

  console.log(parsedArray,'parsedArray')

  const handleDeleteBf = (rowData) => {
   
    let parsedArray = [];
  try {
    parsedArray = JSON.parse(dosArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = dosArray;
  }
    
  
 
    let arr = parsedArray.length>0 && parsedArray.filter((item) => item.id !== rowData?.id);
    const { flag, state,statebf } = setDosArray;
    if (flag == "1") {
      statebf(arr);
      return;
    }
    // if (flag == "2") {
    //   state((prev) => ({
    //     ...prev,
    //     dosArray: arr,
    //   }));
    //   return;
    // }
    // if (flag == "3") {
    //   return;
    // }
  };



  const arr = [
    { colName: "Date of Service", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `B/F claims`,
      flexGrow: 1.9,
      dataKey: "bfVal",
      rowSpan: 1,
    },
    {
      colName: `B/F Amount`,
      flexGrow: 1.9,
      dataKey: "bfAmount",
      rowSpan: 1,
    },
    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
       gridEdit.isBfEdit?{} :{ label: "E", onClick: handleEditState, btnType: "Edit" },
        //{ label: "D", onClick: handleDelete, btnType: "Delete" },
      ],
      
      editRow: true,
    }
   
   
   
  ];




 
 



 

 


  



 


  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             //  defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>:
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? `${claimHeading} is Required`
  //               : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );

  // let case2 = (
  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             // defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //             required
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? "Received Claims is Required"
  //               : null}
  //           </label>
  //         </Col>
  //         {showAmount ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 {claimAmount} <span style={{ color: "red" }}>*</span>
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeAmount}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.AmountDosVal == true
  //                   ? "Amount is Required"
  //                   : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );
  // let case3 = (
  //   <>
  //     <Grid>
  //       <Row className="show-grid">
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel "
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             Dos <span style={{ color: "red" }}>*</span>
  //           </label>
  //           <br></br>
  //           <DatePicker
  //             onChange={onChangeDate}
  //             onChangeCalendarDate={onChangeCalendarDate}
  //             onClean={onCleanDate}
  //             oneTap
  //             // defaultValue={new Date()}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.date == true ? "Date is Required" : null}
  //           </label>
  //         </Col>
  //         <Col xs={4}>
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px" }}
  //           >
  //             {claimHeading} <span style={{ color: "red" }}>*</span>
  //           </label>

  //           <input
  //             class="form-control inputField"
  //             type="number"
  //             name="receivedClaims"
  //             placeholder=""
  //             // value={receivedClaims}
  //             onChange={onChangeInput}
  //           />
  //           <label
  //             class="inputLabel"
  //             style={{ color: "black", fontSize: "13px", color: "red" }}
  //           >
  //             {errorModel.recievedAmount == true
  //               ? ` ${claimHeading} is required`
  //               : null}
  //           </label>
  //         </Col>
  //         {showAmount ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 {claimAmount}:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeAmount}
  //               />
  //             </Col>
  //           </>
  //         ) : null}
  //         {showPortal ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 Calls:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangeCalls}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.calls == true ? `Calls is required` : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //       </Row>

  //       <Row>
  //         {showCalls ? (
  //           <>
  //             <Col xs={4}>
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px" }}
  //               >
  //                 Portals:
  //               </label>

  //               <input
  //                 class="form-control inputField"
  //                 type="number"
  //                 name="receivedClaims"
  //                 placeholder=""
  //                 // value={receivedClaims}
  //                 onChange={onChangePortals}
  //               />
  //               <label
  //                 class="inputLabel"
  //                 style={{ color: "black", fontSize: "13px", color: "red" }}
  //               >
  //                 {errorModel.portals == true ? `Portals is required` : null}
  //               </label>
  //             </Col>
  //           </>
  //         ) : null}
  //         <Col xs={4}>
  //           <label className="inputLabel pb-3"></label>
  //           <br></br>
  //           <Button
  //             onClick={onAdddos}
  //             style={{ backgroundColor: "#0e7490", color: "white" }}
  //           >
  //             Add
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </>
  // );
  const getRequriedPara=()=>{

  }


  return (
    <>

      <div className="row">
      <div class="col-sm-6 col-lg-12 ">
      <UserAuditTable
      getRequriedPara={getRequriedPara}
       rowHeight={48}
      // handleChange={handleChange}
      // handleEditState={handleEditState}
       dateForgrid={parsedArray}
      // isPopupOpen={isPopupOpen}
       Array={
           arr
       
       }
       rowShown={5}
     />
      </div>
     
     
    
    


     

     
     


     
   </div>


<div className="row">
<div class="col-sm-6 col-lg-3 "></div>
<div class="col-sm-6 col-lg-3 "></div>
<div class="col-sm-6 col-lg-3 "></div>
{/* <div class="col-sm-1 col-lg-3 mt-2">
  <div class="form-group ">
    <div className="d-flex justify-content-end mt-4">
      <button
        type="button"
        className="btnClear mr-1 "
        onClick={handleClose}
      >
        Back
      </button>
      
     
    </div>
  </div>
</div> */}

</div>




    </>
  

    

  );
}
