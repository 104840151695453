import React, { useState, useEffect } from "react";
import { Table, Tag, Button } from "rsuite";

import "rsuite/dist/rsuite.css";
import "rsuite-table/dist/css/rsuite-table.css";
import {  Whisper } from 'rsuite';
import EditIcon from "@rsuite/icons/Edit";
import AdvancedAnalyticsIcon from "@rsuite/icons/AdvancedAnalytics";
import { CSVLink } from "react-csv";
import { Tooltip } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Cell {...props} style={{ padding: "6px", backgroundColor: "#F0F9FF" }}>
      <Button
        appearance="link"
        onClick={() => {
          onClick(rowData);
        }}
      >
        {rowData.status === "EDIT" ? "Save" : "Edit"}
      </Button>
    </Cell>
  );
};

const PopupTable = ({
  handleUserWiseRowData,
  isShowExportButton,
  dateForgrid,
  state,
  loader,
  ColumnName,
  Array,
  onChangePop,
  loading,
  setRecord,
  flag,
}) => {
  const [DataForGrid, setDataForGrid] = useState([]);
  const [csvData, setCsvData] = useState("");

  const PopUpCell = ({
    rowData,

    onChangePop,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 5, backgroundColor: "#F0F9FF" }}>
      {/* <CheckIcon
        onClick={() => onChangeVerify(rowData)}
        style={{ color: "#0e7490" }}
      /> */}

      <AdvancedAnalyticsIcon
        onClick={() => onChangePop(rowData)}
        style={{ color: "#0e7490" }}
      />
    </Cell>
  );

  const generateCsvData = (data, columns) => {
    // Extract column headers from the 'columns' array

    const headers = columns.map((column) => column.colName);

    // Create an array to hold the CSV data
    const csvDataBucket = [headers];

    // Iterate over the 'data' array and extract values based on 'dataKey'
    data != false &&
      data.forEach((item) => {
        const rowData = columns.map((column) => {
          // If 'dataKey' is provided, use it to extract the value from 'item'
          if (column.dataKey) {
            return item[column.dataKey];
          } else {
            // If 'dataKey' is not provided, use an empty string
            return "";
          }
        });
        csvDataBucket.push(rowData);
      });

    return csvDataBucket;
  };
  useEffect(() => {
    if (Array) {
      const csvDataArr = generateCsvData(DataForGrid, Array);

      //setCsvData(csvDataArr)
      if (JSON.stringify(csvDataArr) !== JSON.stringify(csvData)) {
        setCsvData(csvDataArr);
      }
    }
  }, [DataForGrid]);

  useEffect(() => {
    if (dateForgrid) {
      const data = Object.keys(dateForgrid).map((key) => {
        return {
          CatValue: key,
          ...dateForgrid[key],
        };
      });
      console.log(data, "first check");
      setDataForGrid(dateForgrid);
    }
  }, [dateForgrid]);
  const handleEditState = async (rowData) => {};
  const CellWrapper = ({ rowData, dataKey, item,toolTip, onClick, ...props }) => {
    console.log(rowData,'cellWrap')
    return (
      <Cell
        {...props}
        style={{ backgroundColor: "#F0F9FF" }}
        dataKey={item?.dataKey} // Pass the dataKey from the item prop
        onClick={() => {
          onClick(rowData, item?.dataKey);
        }}
      >
        {flag == "DateWiseRec" &&
        item?.dataKey != "date" &&
        item?.dataKey != "label" ? (
          <>
          {item?.isShowToolTip?<Whisper
      trigger="hover"
      placement={"top"}
      controlId={`control-id-${"top"}`}
      speaker={
        <Tooltip>{rowData[toolTip]}</Tooltip>
      }
    >
      {/* <span className="table-content-edit-span">{rowData[dataKey]}</span> */}
      <span
            style={
              parseInt(rowData[item?.dataKey])
                ? {
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
                : {
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
            }
          >
            {rowData[item?.dataKey]}
          </span>
      
    </Whisper>:<span
            style={
              parseInt(rowData[item?.dataKey])
                ? {
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
                : {
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
            }
          >
            {rowData[item?.dataKey]}
          </span>}
           
          </>
        
         
        ) : (
          <span>{rowData[item?.dataKey]}</span>
        )}
      </Cell>
    );
  };

  return (
    <>
      {isShowExportButton ? (
        <div class="form-group mb-2 ">
          <div class="d-flex justify-content-end ">
            <button
              type="button"
              className="btnClear mr-1 "
              // onClick={onClear}
            >
              <CSVLink
                className="downloadbtn"
                filename="my-file.csv"
                data={csvData}
              >
                Export to CSV
              </CSVLink>
            </button>
          </div>
        </div>
      ) : null}
      <div style={{ height: "300px" }}>
        <Table
          virtualized
          shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
          height={300}
          data={DataForGrid}
          onRowClick={(data) => {
            console.log(data);
          }}
          autoHeight={true}
          cellBordered={true}
          headerHeight={37}
          rowHeight={30}
          loading={loading}
        >
          {Array &&
            Array.map((item, index) => (
              <Column
                fullText={true}
                key={index}
                flexGrow={item.flexGrow}
                align="center"
                rowSpan={
                  item.colName == "Practice Name"
                    ? (rowData) => {
                        return rowData.rowSpan;
                      }
                    : () => {
                        return 1;
                      }
                }
              >
                <HeaderCell
                  style={{
                    backgroundColor: "#0e7490",
                    color: "white",
                    fontSize: "0.805rem",
                  }}
                >
                  {item.colName}
                </HeaderCell>

                {item?.popupCell ? (
                  <PopUpCell onChangePop={onChangePop} />
                ) : item?.EditCell ? (
                  <ActionCell dataKey="image" onClick={handleEditState} />
                ) : item?.popEachCell ? (
                  <CellWrapper item={item} onClick={handleUserWiseRowData}  toolTip={item?.toolTipKey} />
                ) : (
                  <Cell
                    style={{ padding: 5, backgroundColor: "#F0F9FF" }}
                    onClick={(rowData) => {
                      handleUserWiseRowData(rowData);
                    }}
                    dataKey={item?.dataKey}
                  />
                )}
              </Column>
            ))}
        </Table>
      </div>
    </>
  );
};
export default PopupTable;
