import React, { useEffect, useState, useRef } from "react";
import DateAndPractice from "../../Operation/AllForms/DateAndPractice";
import Select from "react-select";
import { CheckPicker, Checkbox, DatePicker, IconButton, Panel } from "rsuite";
import {
  FetchCodingForTl,
  createTlwWrkSheetOfCoding,
  fetchProduct,
  tlwWrkSheetOfCoding,
} from "../../../Utils/ApiManager";
import moment from "moment";
import { useSelector } from "react-redux";
import PlusIcon from "@rsuite/icons/Plus";
import { toast } from "react-toastify";

export default function TlInterfaceWorkSheet() {
  const { onLoginSignOff } = useSelector((state) => state);
  const [addButton, setAddButtton] = useState(false);
  const [newOption, setNewOption] = useState("");
  const mountRef1 = useRef();
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
  let obj = {
    id: 0,
    openPopup: false,
    praticesNames: [],
    praticeSelection: null,
    // funhandlePracticeVal: handlePracticeVal,
    dateOfEntry: moment().format("YYYY-MM-DD"),
    bfVal: "",
    internalTableArray: [],
    receivedClaims: "",
    workedClaims: "",
    pendingClaims: "",
    provider: "",
    operation: "",
    total: "",
    remarks: "",
    loading: false,
    isOpenSwal: false,
    isOpenView: false,
    isAllStatesAreSet: false,
    ImageSection: {
      image: [],
      bucketImages: null,
      isFileViewerOpen: null,
      FileList: [],

      getSelectedRowDocument: null,
    },
    Type: { label: "Paper", value: "Paper" },
    file: null,
    totalOperation: "",
    totalPcTotal: "",
    totalProvider: "",
    tlType: "Coding",
    loading: false,
    checkedMeeting:false
  };
  const [helpingState, setHelpingState] = useState(obj);
  const [options, setOptions] = useState([
    { label: "E&M", value: "E&M" },
    // { label: "Patient Statement", value: "Patient Statement" },
    // { label: "Co-Pay Collection", value: "Co-Pay Collection" },
    // { label: "Practice Health Report", value: "Practice Health Report" },
  ]);
  const [formData, setFormData] = useState({
    id: 0,
    meeting: [],
    remarks: "",
    frequency: "",
    d_MailTotlCounts: "",
    otherComments: "",
    providerReports: "E&M",
    providerReportsFrequency: "",
    anyOtherTask: "",
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleShowButton = () => {
    setAddButtton(!addButton);
    setNewOption("");
  };
  const addOption = () => {
    if (newOption.trim() !== "") {
      const trimmedOption = newOption.trim();
      const optionExists = options.some((opt) => opt.value === trimmedOption);
      if (!optionExists) {
        setOptions((prevOptions) => [
          ...prevOptions,
          { label: trimmedOption, value: trimmedOption },
        ]);
        toast.info("Option Added Successfully");
        setNewOption("");
      } else {
        toast.error("Option Already Exists");
      }
    }
  };
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setHelpingState((prev) => ({ ...prev, praticesNames: optionList }));
    }
  };
  const handlePracticeVal = (val) => {
    console.log(val, "MMMMMMMMM");
    setHelpingState((prev) => ({ ...prev, praticeSelection: val }));
    fetchRecord(helpingState.dateOfEntry, val);
  };
  const handleDosDate = (event) => {
    if (event == null) {
      setHelpingState((prev) => ({
        ...prev,
        dateOfEntry: moment().format("YYYY-MM-DD"),
      }));
    }
    let date;
    if (event) {
      if (event instanceof Date) {
        date = moment(event).format("YYYY-MM-DD");
        setHelpingState((prev) => ({ ...prev, dateOfEntry: date }));
      }
      fetchRecord(date, helpingState.praticeSelection);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
    }
  };
  const fetchRecord = async (date, val) => {
    const dateObject = new Date(date);

    onClear();
    let obj = {
      userid: onLoginSignOff.userid,
      pid: val.value,
      sdate: dateObject,
      // formType: "10",
    };
    setHelpingState((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const res = await tlwWrkSheetOfCoding(obj);
      console.log(res?.data.message, "fetchRecord");
      // const firstResponse = res?.data?.response[0];
      if (res?.data?.message == "No records found ") {
        // toast.info("No Records Found !");
        onClear();
      } else {
        let arr=[]
        try{
          arr=JSON.parse(res?.data[0]?.meeting)

        }catch{
          arr=res?.data[0]?.meeting

        }
        setFormData((prev) => ({
          ...prev,
          d_MailTotlCounts: res?.data[0]?.dmailCounts,
          id: res?.data[0]?.id,
          meeting: arr,
          frequency: res?.data[0]?.meetingFrequency,
          // submittype: "yes",
          providerReports: res?.data[0]?.providerReports,
          providerReportsFrequency: res?.data[0]?.providerFrequency,
          otherComments: res?.data[0]?.meetingComments,
          anyOtherTask: res?.data[0]?.proReportsComments,
          id: res?.data[0]?.id,
          remarks: res?.data[0]?.internalMtingRemarks,
        }));
      }

      setHelpingState((prev) => ({
        ...prev,
        tlType:res?.data[0]?.tlType,
        loading: false,
      }));
    } catch (error) {
      console.log(error, "error");
      setHelpingState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  const handletlType = (val) => {
    debugger
    setHelpingState((prev) => ({ ...prev, tlType: val.value }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "namefdfsdf");

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(formData, "/////");
  };

  const handleMeetingSenarion = (selectedOption) => {
   
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      meeting: selectedOption,
    }));
  };
  const handleInputChangee = (e) => {
    setNewOption(e.target.value);
  };
  const onSave = async () => {
    if (helpingState?.tlType == "") {
      toast.info("Please select Tl Type");
      return;
    }
    if (helpingState.praticeSelection == null) {
      toast.info("Please select Practice Name");
      return;
    } else {
      setHelpingState((prev) => ({ ...prev, loading: true }));
      const formDataForSaveApi = {
        userid: onLoginSignOff.userid,
        sdate: helpingState.dateOfEntry,
        pid: helpingState.praticeSelection.value,
        dmailCounts: formData.d_MailTotlCounts || 0,
        // formtype: 15,
        meeting: JSON.stringify(formData.meeting),
        meetingFrequency: formData.frequency,
        // submittype: "yes",
        providerReports: formData.providerReports,
        providerFrequency: formData.providerReportsFrequency,
        meetingComments: formData.otherComments,
        proReportsComments: formData.anyOtherTask,
        // AnyOtherTaskReports: formData.anyOtherTask,
        // id: formData.id,
        tlType: helpingState.tlType,
        id: formData.id,
        internalMtingRemarks: formData.remarks,
      };

      createTlwWrkSheetOfCoding(formDataForSaveApi)
        .then((result) => {
          setHelpingState((prev) => ({ ...prev, loading: false }));
          toast.info("Record Saved Successfully");
        })
        .catch((error) => {
          setHelpingState((prev) => ({ ...prev, loading: false }));
          toast.info("Error occure");
        });
    }
  };
  const onClear = () => {
    setFormData({
      meeting: [],
      remarks: "",
      frequency: "",
      otherComments: "",
      providerReports: "E&M",
      providerReportsFrequency: "",
      anyOtherTask: "",
      d_MailTotlCounts: 0,
    });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  console.log(helpingState.dateOfEntry, "helpingState");
  console.log(formData, "{{{{{lll");

  const footerStyles = {
    border: "1px solid #e5e5e5",
    position: "absolute",
    marginTop: "45px",
    borderBottom: "1px solid rgb(229, 229, 229)", // Matching the border color
    // padding: "9px 67px", // Adjusted padding values
    // paddingLeft: "9px",
    paddingRight: "30%",
    width: "100%",
    backgroundColor: "aliceblue",
    borderRadius: "5px",
    marginLeft: "10px",
  };


  const checkAllItems=(e,checked)=>{
    setHelpingState((prev)=>({
      ...prev,
      checkedMeeting:checked
    }))
    if(checked){
      let arr=["Emails","Training","Team Queries","Rejection and Denials","Practice SOPS","Docuhub tickets"]
      setFormData((prev)=>({
        ...prev,
        meeting:arr
      }))
    }else{
      setFormData((prev)=>({
        ...prev,
        meeting:[]
      }))

    }

  }

  return (
    <div className="row m-2">
      {helpingState.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : null}
      <div className="col-4 ">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          TL Type
        </label>
        <br />
        <Select
          options={[
            { label: "Coding", value: "Coding" },
            // { label: "Payments", value: "Payments" },
            // { label: "Patient Statement", value: "PatientStatement" },
            // { label: "Account Receivable", value: "AccountReceivable" },
          ]}
          placeholder="Select an option"
          onChange={handletlType}
           value={{ label: helpingState.tlType ,value: helpingState.tlType}}
          styles={customStyles}
        />
      </div>
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Practice
        </label>
        <br />
        <Select
          options={helpingState?.praticesNames || []}
          placeholder="Select an option"
          onChange={handlePracticeVal}
          value={{
            label: helpingState?.praticeSelection?.label,
            value: helpingState?.praticeSelection?.value,
          }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Entry Date
        </label>
        <br />
        <DatePicker
          onChange={handleDosDate}
          style={{ width: 400 }}
          oneTap
          value={
            helpingState?.dateOfEntry
              ? moment(helpingState?.dateOfEntry).toDate()
              : null
          }
          onKeyDown={handleKeyDown}
        />
        {/* <DatePicker
            class="form-control inputField"
            type="date"
            name="phone"
            placeholder=""
            max={today}
            min={minDate}
            //  defaultValue={date}
              onChange={handleDosDate}
          value={helpingState?.dateOfEntry ? moment(helpingState?.dateOfEntry).toDate() : null}
          /> */}
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>

        {/* {false ? null : <div className="col-3"></div>} */}
      </div>
      <Panel defaultExpanded={true} header="Meeting" collapsible>
        <div className="row mt-4">
          <div className="col-sm-4 mt-2" ref={mountRef1}>
            <div className="form-group mb-2">
              <label className="inputLabel" style={{ color: "black" }}>
                Meeting Type
              </label>
              {/* <ReactDropdown
                      value={formData.meeting}
                      placeholder="Select an option"
                      onChange={handleMeetingSenarion}
                      options={["Internal", "External"]}
                      styles={customStyles}
                    /> */}
              {/* <Select
                options={[
                  { label: "Internal", value: "Internal" },
                  { label: "External", value: "External" },
                ]}
                placeholder="Select an option"
                onChange={handleMeetingSenarion}
                value={{
                  label: formData.meeting,
                  value: formData.meeting,
                }}
                styles={customStyles}
              /> */}
              <div>
                <CheckPicker
                  container={() => mountRef1.current}
                  virtualized
                  label="Meeting"
                  data={[
                    { label: "Emails", value: "Emails" },
                    { label: "Training", value: "Training" },
                    {
                      label: "Rejection and Denials",
                      value: "Rejection and Denials",
                    },

                    { label: "Team Queries", value: "Team Queries" },
                    { label: "Practice SOPS", value: "Practice SOPS" },
                    { label: "Docuhub tickets", value: "Docuhub tickets" },

                  ]}
                  style={{ width: 1000 }}
                  onChange={handleMeetingSenarion}
                  block={false}
                  value={formData?.meeting?formData?.meeting:[]}
                  countable
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        container={() => mountRef1.current}
                        indeterminate={
                          formData?.meeting &&
                          formData?.meeting.length > 0 &&
                          formData?.meeting.length < formData?.meeting.length
                        }
                        checked={helpingState?.checkedMeeting}
                        onChange={checkAllItems}
                      >
                        Select All Meeting Type
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          {true ? (
            <div className="col-sm-4 pt-2">
              <div className="form-group mb-2">
                <label className="inputLabel" style={{ color: "black" }}>
                  Remarks
                </label>
                <input
                  className="form-control inputField"
                  type="text"
                  name="remarks"
                  placeholder=""
                  value={formData.remarks}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          ) : (
            <div className="col-sm-4">
              <div className="form-group mb-2 mt-2">
                <label className="inputLabel" style={{ color: "black" }}>
                  Frequency
                </label>
                <Select
                  options={[
                    { label: "Daily", value: "Daily" },
                    { label: "Weekly", value: "Weekly" },
                    { label: "B-Weekly", value: "B-Weekly" },
                    { label: "Monthly", value: "Monthly" },
                    { label: "Other", value: "Other" },
                  ]}
                  placeholder="Select an option"
                  onChange={(selectedOption) =>
                    handleInputChange({
                      target: {
                        name: "frequency",
                        value: selectedOption.value,
                      },
                    })
                  }
                  value={{
                    label: formData.frequency,
                    value: formData.frequency,
                  }}
                  styles={customStyles}
                />
                {/* <ReactDropdown
                        value={formData.frequency}
                        placeholder="Select an option"
                        options={[
                          "Daily",
                          "Weekly",
                          "B-Weekly",
                          "Monthly",
                          "Other",
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange({
                            target: {
                              name: "frequency",
                              value: selectedOption.value,
                            },
                          })
                        }
                      /> */}
              </div>
            </div>
          )}

          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2 mt-2">
              <label class="inputLabel" style={{ color: "black" }}>
                D - Mail Total Counts
              </label>

              <input
                className="form-control inputField"
                type="number"
                name="d_MailTotlCounts"
                placeholder=""
                value={formData.d_MailTotlCounts}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group mb-3 mt-3">
              <label className="inputLabel" style={{ color: "black" }}>
                Other
              </label>
              <textarea
                className="form-control inputField"
                placeholder="Comments if any!"
                name="otherComments"
                value={formData.otherComments}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4"></div>
      </Panel>

      <div className="mt-4"></div>

      <Panel defaultExpanded={true} header="Reports" collapsible={true}>
        <div className="row mt-4">
          <div className="col-sm-4">
            <div className="form-group mb-2">
              <label className="inputLabel" style={{ color: "black" }}>
                Provider Reports
              </label>
              {/* <ReactDropdown
          className="inputField"
          value={formData.providerReports}
          placeholder="Select an option"
          options={[
            "DWC",
            "Patient Statement",
            "Co-Pay Collection",
            "Practice Health Report",
          ]}
          onChange={(selectedOption) =>
            handleInputChange({
              target: {
                name: "providerReports",
                value: selectedOption.value,
              },
            })
          }
        /> */}
              <Select
                options={options}
                placeholder="Select an option"
                onChange={(selectedOption) =>
                  handleInputChange({
                    target: {
                      name: "providerReports",
                      value: selectedOption.value,
                    },
                  })
                }
                value={{
                  label: formData.providerReports,
                  value: formData.providerReports,
                }}
                styles={customStyles}
              />
            </div>
          </div>

          {addButton && (
            <div class="col-sm-3 col-lg-3">
              <div class="form-group mb-2 ">
                <label class="inputLabel" style={{ color: "black" }}>
                  Enter New Option Title
                </label>

                <input
                  className="form-control inputField"
                  type="text"
                  placeholder="Enter new Option to be added"
                  value={newOption}
                  onChange={handleInputChangee}
                />
              </div>
            </div>
          )}

          <div className=" col-lg-2 mt-4 ">
            <div className="form-group mb-2 mt-1">
              {addButton ? (
                <>
                  <button
                    type="button"
                    onClick={addOption}
                    className="btnSave p-2"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={handleShowButton}
                    className="btnSave mt-1 ml-1 p-2"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <span
                    className=""
                    style={{
                      right: "0",
                      backgroundColor: "green",
                      marginTop: "1px",
                    }}
                    onClick={() => handleShowButton()}
                  >
                    <IconButton
                      style={{
                        color: "#0e7490",
                        backgroundColor: "#0e7490",
                      }}
                      icon={<PlusIcon style={{ backgroundColor: "#0e7490" }} />}
                    ></IconButton>
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group mb-2">
              <label className="inputLabel" style={{ color: "black" }}>
               Remarks
              </label>
              
              {/* <Select
                options={[
                  { label: "Daily", value: "Daily" },
                  { label: "Weekly", value: "Weekly" },
                  { label: "B-Weekly", value: "B-Weekly" },
                  { label: "Monthly", value: "Monthly" },
                  { label: "Other", value: "Other" },
                ]}
                placeholder="Select an option"
                onChange={(selectedOption) =>
                  handleInputChange({
                    target: {
                      name: "providerReportsFrequency",
                      value: selectedOption.value,
                    },
                  })
                }
                value={{
                  label: formData.providerReportsFrequency,
                  value: formData.providerReportsFrequency,
                }}
                styles={customStyles}
              /> */}
              <input
                  className="form-control inputField"
                  type="text"
                  name="providerReportsFrequency"
                  placeholder=""
                  value={formData.providerReportsFrequency}
                  onChange={handleInputChange}
                />
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group mb-3 mt-3">
              <label className="inputLabel" style={{ color: "black" }}>
                Any Other Task
              </label>
              <textarea
                className="form-control inputField"
                name="anyOtherTask"
                placeholder="Comments if any!"
                value={formData.anyOtherTask}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        {/* <div className="mt-4"></div> */}
      </Panel>

      <div className="col-sm-12 col-lg-12 mt-2 ">
        <div className="form-group mb-2 ">
          <div className="d-flex justify-content-end ">
            <button type="button" onClick={onClear} className="btnClear mr-1">
              Clear
            </button>
            <button type="button" onClick={onSave} className="btnSave">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
