import moment from "moment/moment";
import React, { useState } from "react";
import Select from 'react-select';
import "react-dropdown/style.css";
function FormTypeTL({
  products,
  handleFormTypeAndPract,
  date,
  handleSubCheckBoxChargeEntry,
}) {
  const [checkedType, setCheckedType] = useState("null");
  const [selectedOption, setSelectedOption] = useState('');
  const [minimize, setMinimize] = useState(false)
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white': 'black',
      backgroundColor: state.isSelected ? 'rgb(14, 116, 144)':'' ,
      marginTop:'2px',
      ':hover': {
        backgroundColor: 'rgb(14, 116, 144)', // Background color on hover
        cursor: 'pointer', // Change cursor on hover
        color: 'white'
      }
      
    }),
  };

  const handleFormType = (event) => {



    const { value } = event.target;
    if (
      value == "Reports" ||
      value == "AmountVerification" ||
      value == "MeetingTl" ||
      value == "PaymentsVerification" ||
      value == "ReportsPayments" ||
      value == "MeetingPayment" ||
      value == 'AccountPayments' ||
      value == 'DeniedVerification' ||
      value == 'MeetingDenied' ||
      value == 'ReportsDenied' ||
      value == 'AccountRecVerify' ||
      value == 'MeetingDeniedAC' ||
      value == 'ReportsDeniedAC'

    ) {

      handleSubCheckBoxChargeEntry(value);
      setCheckedType(value);
    }
    setCheckedType(value);
    let obj = { name: "formType", val: value };

    handleFormTypeAndPract(obj);
  };
  const handlePracticeVal = (val, e) => {
    console.log(e, "practiveVal")
    const { value, label } = val;

    setSelectedOption(label);
    let obj = { name: "practiveVal", val: value };

    handleFormTypeAndPract(obj);
  };
  const handleVal = (event) => {
    const { value } = event.target;
    let obj = { name: "date", val: value };
    handleFormTypeAndPract(obj);
  };
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");


  return (
    <>
      <div className="card mb-3">
      <div
          className="card-header border-bottom"
          style={{ backgroundColor: "#0e7490", color: "white" }}
        >
          <h6 className="m-0">Practice Name / Date <button
            onClick={() => { setMinimize((prev) => !prev) }}
            className={minimize === true ? "practiceShow " : "practiceShow minimizeColor"}>{minimize === true ? "+" : "-"}</button></h6>
        </div>
        {minimize === false ?
          <div className="row">
            <div class="col-sm-12">
              <div class="form-group mb-2">
                <label class="inputLabel">Date</label>

                <input
                  class="form-control inputField"
                  type="date"
                  name="phone"
                  placeholder=""
                  max={today}
                  min={minDate}
                  defaultValue={date}
                  onChange={handleVal}
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group mb-2">
                <label class="inputLabel">Practice</label>

                <Select
              options={products || []}
              placeholder="Select an option"
              onChange={handlePracticeVal}
              value={selectedOption ? selectedOption.value : null}
            styles={customStyles}
            />
              </div>
            </div>
          </div> : null}
        <br></br>
        <div
          className="card-header border-bottom"
          style={{ backgroundColor: "#0e7490", color: "white" }}
        >
          <h6 className="m-0">Form Type</h6>
        </div>
        <div className="card-body p-0">
          <ul className="list-group list-group-flush">
            <li className="list-group-item px-3 pb-2">
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="AmountVerification"
                  checked={
                    checkedType == "chargeEntryTl" ||
                    checkedType == "AmountVerification" ||
                    checkedType == "MeetingTl" ||
                    checkedType == "Reports"
                  }
                  onClick={handleFormType}
                />
                <label className="form-check-label">Charge Entry</label>
              </div>
              
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="PaymentsVerification"

                  checked={checkedType == 'ReportsPayments' || checkedType == 'PaymentsVerification' || checkedType == 'MeetingPayment' || checkedType == 'AccountPayments'}
                  onClick={handleFormType}
                />
                <label className="form-check-label">Payments</label>
              </div>
              
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="DeniedVerification"

                  checked={

                    checkedType == "DeniedVerification" ||
                    checkedType == "MeetingDenied" ||
                    checkedType == "ReportsDenied"
                  }
                  onClick={handleFormType}
                />
                <label className="form-check-label">Denials</label>
              </div>


             

              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="AccountRecVerify"

                  checked={checkedType == 'AccountRecVerify' || checkedType == 'MeetingDeniedAC' || checkedType == 'ReportsDeniedAC'}
                  onClick={handleFormType}
                />
                <label className="form-check-label">Account Receivable</label>
              </div>
             
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default FormTypeTL;
