import { combineReducers } from "redux";
import { Packages } from "../Common/Packages";



//Sign OFF lOGIN USERNAME

function onLoginSignOff(state = false, action) {
  switch (action.type) {
    case "signOFF":
      return action.payload;
    default:
      return state;
  }
}

function userList(state = false, action) {
  switch (action.type) {
    case "userlist":
      return action.payload;
    default:
      return state;
  }
}

function userNames(state = false, action) {
  switch (action.type) {
    case "userNames":
      return action.payload;
    default:
      return state;
  }
}

function practiceName(state = false, action) {
  switch (action.type) {
    case "practiceName":
      return action.payload;
    default:
      return state;
  }
}
function practiceIdies(state = false, action) {
  switch (action.type) {
    case "practiceIdies":
      return action.payload;
    default:
      return state;
  }
}
function AllConfirmationonTabSwitch(state = false, action) {
  switch (action.type) {
    case "AllConfirmationOnTab":
      return action.payload;
    default:
      return state;
  }
}


function isConfirmationSpeakTab(state = false, action) {
  switch (action.type) {
    case "isConfirmationSpeakonTab":
      return action.payload;
    default:
      return state;
  }
}


function permissionReducers(state = [], action) {
  switch (action.type) {
    case "permissions":
      return action.payload;
    default:
      return state;
  }
}


function getPracticesAgianstTeams(state = [], action) {
  switch (action.type) {
    case "getPractices":
      return action.payload;
    default:
      return state;
  }
}













const rootReducer = combineReducers({

 


  //sIGN OFF REDUX
  
  AllConfirmationonTab:AllConfirmationonTabSwitch,
  getPracticesAgianstTeams:getPracticesAgianstTeams,
  PermissionReducers:permissionReducers,
  isConfirmationSpeakTab:isConfirmationSpeakTab,
  
  onLoginSignOff:onLoginSignOff,
  userList:userList,
  userNames:userNames,
  practiceIdies:practiceIdies,
  practiceName:practiceName,
 
});


export default rootReducer;
