import React,{useEffect,useState} from 'react'
import UserAuditTable from '../../../Audit/userTaskViewTable'

export default function View({parentHelpingState,openViewSection}) {
    let obj={
        tableState:[]
    }
    const [viewState,setViewState]=useState(obj)

    
    useEffect(() => {
    
        if(parentHelpingState?.internalTableArray){
            let parssedArr=[]
            try{
                parssedArr=JSON.parse(parentHelpingState?.internalTableArray)

            }catch{
                parssedArr=parentHelpingState?.internalTableArray


            }

            const formattedArrayReject = parssedArr && parssedArr.map((item) => {
                return {
                  ...item,
                 
                  operation: isNaN(Number(item.operation)) ? 0 : Number(item.operation),
                  provider: isNaN(Number(item.provider)) ? 0 : Number(item.provider),
                  total:isNaN(Number(item.total))?0:Number(item.total)
                };
              });
            setViewState((prev)=>({...prev,tableState:formattedArrayReject}))

        }
    
    }, [parentHelpingState?.internalTableArray])
    
    const getRequriedPara = () => {};

    const TableColumns = [
        { colName: "DOS", flexGrow: 1.9, dataKey: "Dos", rowSpan: 1 },
    
        {
          colName: `Received Claims`, //Pat Statement
          flexGrow: 2.5,
          dataKey: "receivedClaims",
          rowSpan: 1,
        },
    
       
       
        {
          colName: `Worked Claims`, //Rejected
          flexGrow: 2.5,
          dataKey: "workedClaims",
          rowSpan: 1,
        },
        
        {
          colName: `Pending Claims`, //Received Amount
          flexGrow: 2.5,
          dataKey: "pendingClaims",
          rowSpan: 1,
        },
        
        {
            colName: `Provider`, //Received Amount
            flexGrow: 2.5,
            dataKey: "provider",
            rowSpan: 1,
          },
        {
            colName: `Operation`, //Received Amount
            flexGrow: 2.5,
            dataKey: "operation",
            rowSpan: 1,
          },
        
        
    
        
      ];
  return (
    <>
    <div className='mr-2'>
    <div >
        <UserAuditTable
        getRequriedPara={getRequriedPara}
         // Footer={addNewState?.footerState}
        rowHeight={48}
        //  handleChange={handleChange}
        //  handleEditState={handleEditState}
        dateForgrid={viewState?.tableState}
        //  isPopupOpen={isPopupOpen}
        Array={TableColumns}
        rowShown={5}
      //  flagFooter={'coding'}
      isPaginationShow={true}
      />
      
    </div>

<div className="row">
        <div class="col-sm-6 col-lg-6"></div>
        <div class="col-sm-3 col-lg-3"></div>

        <div class="col-sm-3 col-lg-3 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <div className="mr-2 "></div>

              
                <button type="button" className="btnSave" onClick={()=>openViewSection(false)}>
                  Back
                </button>
              
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
    
  )
}
