import React, { useState } from "react";
import CodingUserInterfaceTabs from "./codingUserInterfaceTabs";
import UserInterfaceCoding from "./UserInterface/userInterfaceCodig";
function Coding() {
  let obj = {
    tabName: "ChargeEntryCoding",
  };
  const [helpingState, setHelpingState] = useState(obj);
  return (
    <>
      <div className="ml-3"><CodingUserInterfaceTabs setHelpingState={setHelpingState} /></div>
      {helpingState?.tabName == "ChargeEntryCoding" && <UserInterfaceCoding />}
    </>
  );
}
export default Coding;
