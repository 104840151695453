import React, { useState, useEffect } from "react";
import {
  getBucketImages,
  insertFormType9PatStat,
  insertFormType9PatStats,
  onSaveApi,
} from "../../../Utils/ApiManager";
import { Schema,Panel } from 'rsuite';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Dateoofservice from "../../../Common/Dateoofservice";
import NotifySwal from "../../../Common/NotifySwal"
import { CustomInputGroupWidthButtonC, handleChangeC,onChangeDateC,onSaveDosC,onAdddosC,handleEditStateC,onChangeInputC,onChangeAmountC} from "./CommonDos";
import {handleCloseC} from './CommonDos'
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import { createValidationSchema } from "../../../Common/Validation";
import DateoofserviceGlobal from "../../../Common/DateofServiceGlobal";
import DateAndPractice from "./DateAndPractice";
import moment from "moment/moment";
import DateoofserviceBF from "./BFClaims";
import { useDispatch } from "react-redux";
import { AllConfirmationOnTab } from "../../../Actions/Actions";
import FileUpload2 from "../../../Common/FileUpload2";
import ViewDocument from "../../../Common/IframeModel";
function PatStatement({handleFormTypeAndPract,products, record, practiceId, formTypeNoForApi, date,onRecordUpdateOrCreated }) {


  let yourDate = new Date()
  let datee=moment(yourDate).format('YYYY-MM-DD');
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const [globalDos, openGlobalDos] = useState("");
  const [rowID,setRowID]=useState(null)
  const [bfVal, setBfVal] = useState(0); //bfval
  const [pendingAmountDos, setpendingAmountDos] = useState("");
  const [bfamount, setBfamount] = useState(0); //bfamount
  const [receivedClaims, setReceivedClaims] = useState(null); //receive val
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [openDosmodelRecState, setDosModelRec] = useState(false); 
  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [openScreenGlobalTable,setGlobalTable]=useState(false)
  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [AmountDos, setAmountDos] = useState(null);
  const [bfScreenArray, setBfScreenArray] = useState("");
  const [bfRow, setisThisbfRow] = useState(false);

  
  
  //receivedamount
  let errorobj={
    recievedAmount:false,
    AmountDosVal:false,
    date:false,
    recievedAmountCC: false,
    AmountDosValCC: false,
    internalNonVerify:false
    }
    const { StringType, NumberType, DateType } = Schema.Types
    const [errorModel,setErrorModel]=useState(errorobj)

  const [verfied, setVerified] = useState(null); //

  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);
  const [nonverfied, setNonVerified] = useState(null); //ok
  const [workedAmount, setWorkedAmount] = useState(null);
  const [pendingClaims, setPendingClaims] = useState(null); //pendingval
  const [pendingAmount, setPendingAmount] = useState(null); //pendingamountval

  let breakupObj = {
    providerVal: "",
    payerVal: "",
    otherVal: "",
    operationVal: "",
    patVal: "",
    total: 0,
  };
const [AllBreakupVal, setBreakupVal] = useState(breakupObj);

  // All breakups
  const [totalVal, setTotalVal] = useState(null); ///totalval
  const [providerVal, setProviderVal] = useState(0); //providerEnd
  const [patVal, setPatVal] = useState(0);
  const [openModelPending, setModelPending] = useState(false);
  let footerObj={
    isAllow:true,
    
    recievedPatStat:0,
    Verified:0,
    Rejected:0,
    ReceivedAmount:0,
    WorkedAmount:0,
    Adjusted:0,
    pendingAmount:0,
    PendingClaims:0
  }
  const [footerState,setFooterState]=useState(footerObj)

  const [operationVal, setOperationVal] = useState(0);
  const [payerVal, setpayerVal] = useState(0);
  const [otherVal, setotherVal] = useState(0);

  const [generalTextField, setGeneralTextField] = useState(null); // general open
  const [insuranceRecieved, setInsuranceRecieved] = useState(null); //insurance
  const [actionToken, setActionToken] = useState(null);
  const [image, setImage] = useState("");
  const [comments, setComments] = useState(null);
  const { onLoginSignOff } = useSelector((state) => state);
  const [docViewer,setDocViewer]=useState(false)
  const [handledosDate, setdosDate] = useState("");
  const [dosModel, setDosModel] = useState(false);
  const [handleInputdos, sethandleInput] = useState("");
  const [data, setData] = React.useState(null);
  const [dosArray, setDosArray] = useState([]);
  const [dosArrayNonV, setDosArrayNonV] = useState([]);
  const [dosModelN, setDosModelN] = useState(false);
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [AmountDosRec, setAmountDosRec] = useState("");
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [renderEffect,setRenderEffect]=useState(false)
  const [datePickerVal,setDatePickerVal]=useState(new Date())
  const [selectedOption, setSelectedOption] = useState("");
  const [internalNonVerify,setInternalNonVerify]=useState("")
  const [AdjustedState,setAdjustedState]=useState("")
  const [paymentstateAdjusted,setPaymentState]=useState("")

  let helpingObj={
    isSpeakToaster:false,
    loadingn:false,
    isSpeakerToaster:false,
    isBackSpeak:false,
    AllFiles: null,
    renderEffectForUpload: false,
    getSelectedRowDocument: null,
    updatedblodState: [],
    updatedblob: [],
    isFileViewerOpen: null,
    PatType:{label:'PLD',value:'PLD'},
    isAllStatesAreSet:false
  }
  const [helpingState,sethelpingState]=useState(helpingObj)
  let objR={
    filelist:[]

  }
  const [objectcluster,setobjCLuster]=useState(objR)

  const [FileList, setFileList] = useState([]);


  useEffect(() => {
   
    if(FileList && FileList.length>0){
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data
    
        const blob = new Blob([res.data], { type: el.mimetype });
        var file = new File([blob],  el.originalname);
      // const uint8Array = new Uint8Array(res.data);
      //  const blob = new Blob([uint8Array], { type: el.mimetype });
      //  blob.name = el.originalname;
      //  blob.lastModified = el.lastModified;
         
   
          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original:el.originalname
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
       
       
       
      setImage(updatedArr)
      //  setobjCLuster((prev)=>({
      //   ...prev,
      //   filelist:updatedArr

      // }))
     
      })
  
  
      
    }
   
  }, [FileList])




  useEffect(() => {

    
    let paresedArray2;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);
      
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2=dosArrayRecieved
    
    }
    if (paresedArray2.length > 0) {
      const sumProperty = (property) =>
      paresedArray2.reduce((sum, obj) => sum + (parseFloat(obj[property]) || 0), 0);
      setProviderVal(sumProperty("providerVal"));
      setOperationVal(sumProperty("operationVal"));





      setotherVal(sumProperty("otherVal"));
      setPatVal(sumProperty("patVal"));
      setOperationVal(sumProperty("operationVal"));
    }
  }, [dosArrayRecieved]);
  useEffect(() => {
   
    if(parseFloat(pendingAmountDos)<0){
      toast.info('Pending Amount value must be a positive number. Please verify your calculations.')
    }
   
  }, [pendingAmountDos])





  useEffect(() => {
    
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }
  
    let countAmountdos = 0;
    let  countAmountdosCC=0;
    let recievedAmountCC=0
    let nonVerfied=0
    let pending=0
    let pendingAmount=0
  
    let count = 0;
    let Adjusted=0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount); //Recieved
        countAmountdos += parseFloat(el.AmountDosVal)
        countAmountdosCC+=parseFloat(el.AmountDosValCC)
        recievedAmountCC+=parseFloat(el.recievedAmountCC)
        nonVerfied+=parseFloat(el.internalNonVerify)
        Adjusted +=parseFloat(el.Adjusted)
        pending+=parseFloat(el.pendingStat)
        pendingAmount+=parseFloat(el.pendingAmount)

      });
      setFooterState((prev)=>({
        ...prev,
        recievedPatStat:recievedAmountCC,
        Adjusted:Adjusted,
        Rejected:nonVerfied,
        WorkedAmount:countAmountdos,
        ReceivedAmount:countAmountdosCC,
        Verified:count,
        PendingClaims:pending,
        pendingAmount:pendingAmount






      }))

   
  }, [dosArrayRecieved])
  const onPreview = (val, row) => {
    let obj = { bucketImages: val };
    sethelpingState((prev)=>({...prev,getSelectedRowDocument:obj}))
    sethelpingState((prev) => ({
      ...prev,
      //  base64: objRes.base64String,
      isFileViewerOpen: true,
    }));
   
   
  };


  const dispatch = useDispatch();

  useEffect(() => {
    if(globalDos){
      dispatch(AllConfirmationOnTab(true))

    }else{
      dispatch(AllConfirmationOnTab(false))

    }

    
  }, [globalDos])

  


  useEffect(() => {
    const {
      providerVal,
      payerVal,
      otherVal,
      operationVal,
      patVal,
      total,
    } = AllBreakupVal;

    if (
      providerVal ||
      payerVal ||
      otherVal ||
      patVal ||
      operationVal
     
    ) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(payerVal) || 0) +
        (parseFloat(otherVal) || 0) +
        
        (parseFloat(operationVal) || 0) +
        (parseFloat(patVal) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
  }, [
    AllBreakupVal.providerVal,
    AllBreakupVal.payerVal,
    AllBreakupVal.otherVal,
   
    AllBreakupVal.operationVal,
    AllBreakupVal.patVal,
  ]);

  const onChangeAmount = (val) => {
    
    const { value, name } = val.target;
    setAmountDos(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosValCC: false,
    }));
  };

  const onChangeBreakupClaim = (e) => {
    
    const { name, value } = e.target;
    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log('AllBreakUpVal',AllBreakupVal)


  // For Pending Claims 


  useEffect(() => {
   
    if(handleInputdosRec || internalNonVerify ||  handleInputdosRec  ){
      onHandlePendingClaimss('PendingClaim')



    }
    if(AmountDosRec && AmountDos){
      onHandlePendingClaimss('PendingAmount')

    }

   
  }, [handleInputdos ,internalNonVerify,handleInputdosRec,AmountDos,AmountDosRec])
  

  useEffect(() => {
    if (record) {
      handleChargeFieldsOnGetRecord(record);
    }
    if (practiceId == "0") {
      setActionToken("");
    }
  }, [record, practiceId]);
  useEffect(() => {
    if (practiceId) {
      onClear();
    }
  }, [practiceId]);

  useEffect(() => {
    if (date) {
      onClear();
    }
  }, [date]);
  const openDosmodel = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setDosModel(true);
  };
  const openGlobalTable=(arg)=>{
    setGlobalTable(arg)
    

  }
  const handleClose = () => {
    openGlobalTable(false)
    setBreakupVal(breakupObj);
    setPendingClaimss(0)



    sethandleInput(null);
   
    setdosDate("");

  
   
    setisPendingSectionOpen(false);
    setBreakupVal(breakupObj);
    setDosModel(false);
   
   
    setdosDate('')
   setErrorModel((prevErrorModel) => ({
    ...prevErrorModel,
    recievedAmount: false,
    AmountDosVal: false,
    date: false
  }))
  };
  const onChangeInput = (val) => {
  
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,

    }));
  };



  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };



  const handleKeyPress = (e) => {
    
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (charCode === 8 || (charCode >= 48 && charCode <= 57)) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };

  

  const onHandlePendingClaimss = (val) => {
  
   
    if(val=='PendingClaim'){
      if ( handleInputdos || handleInputdosRec || internalNonVerify ) {
        let pending;
  
        let value = parseFloat(handleInputdosRec);
  
        pending = value -= parseFloat(handleInputdos)+parseFloat(internalNonVerify)
        if(parseFloat(pending)<0){
          toast.info('Pending  value must be a positive number. Please verify your calculations.')
          
         }

        setPendingClaimss(pending);
      }
    }
    else{
     
      if ( AmountDos || AmountDosRec) {
        let pending;
  
        let value = parseFloat(AmountDosRec);
  
        pending = value -= parseFloat(AmountDos);
        setpendingAmountDos(pending);
      }

    }
   

   
    // setPendingClaimss(val);
  };
  const onChangeDate = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;
  let result = dosArray.some((el) => el.date === formattedDate);
    if (result) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,

    }))
  };
  const onAdddos =async () => {


    let result = dosArray.some((el) => el.date === handledosDate);
    if (result) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }


   
    let obj = {
      date: handledosDate,
      recievedAmount: handleInputdos,
      status: null,
      id: dosArray.length + 1,


      
    };





    const validationFields = [
      {
        fieldName: 'recievedAmount',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: StringType() }
  
      },
      // {
      //   fieldName: 'AmountDosVal',
      //   rules: { requiredMessage: 'AmountDosVal is required.' },
      //   type: { typeError: StringType() }
  
  
      // },
      {
        fieldName: 'date',
        rules: { requiredMessage: 'Date is required.' },
        type: { typeError: DateType() }
  
  
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);
    // if (errors.AmountDosVal.hasError) {
    //   setErrorModel((prevErrorModel) => ({
    //     ...prevErrorModel,
    //     AmountDosVal: true,
  
    //   }));
  
  
    // }
    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
  
      }));
  
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }
    if (!errors.recievedAmount.hasError && !errors.date.hasError) {
      setDosArray((prevDosArray) => [...prevDosArray, obj]);
  
    }
   
  };
  const styles = {
    marginBottom: 10,
  };

  const CustomInputGroupWidthButton = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={styles}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );
  const onSaveDos = () => {
    
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount);
      });
     
      setVerified(count)
  //  setWorkedClaims(count);
    setDosModel(false);
  };
  const handleEditState = async (rowData) => {
    if(!rowData.hasOwnProperty("status"))
  {
    return

  }
    const nextData = Object.assign([], dosArray);
    const activeItem = nextData.find((item) => item.id === rowData.id);
 console.log(activeItem.status,'bug here',nextData)
    activeItem.status = activeItem.status ? null : "EDIT";
    setDosArray(nextData);

    // if(rowData.status=='Save'){
    //   let obj={
    //     date:rowData.date,
    //     recievedAmount:rowData.recievedAmount,
    //     status:'EDIT'
    //   }
    //   setDosArray(prevDosArray => [...prevDosArray, obj]);

    // }

    // const nextData = Object.assign([], data);
    // const activeItem = nextData.find((item) => item.id === rowData.id);
    // activeItem.status = rowData.status === 'Save' ? 'EDIT' : null;
    // setData(nextData);
  };

  let nextData;
  const handleChange = (id, key, value,event) => {
    event.persist();
    
     nextData = Object.assign([], dosArray);
    nextData.find((item) => item.id === id)[key] = value;
   setDosArray(nextData);
  };


  //---------------non Verified



  const openDosmodelN = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setDosModelN(true);
  };
  const handleCloseN = () => {
    setPendingClaimss(0)
    sethandleInput(null)
    setDosModelN(false);
 
   // setAmountDos('')
    setdosDate('')
   setErrorModel((prevErrorModel) => ({
    ...prevErrorModel,
    recievedAmount: false,
    // AmountDosVal: false,
    date: false
  }))
  };
  const onChangeInputN = (val) => {
   
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,

    }));
  };
  const onChangeDateN = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;
    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,

    }))
  };
  const onAdddosN=async () => {

    let result = dosArrayNonV.some((el) => el.date === handledosDate);
    if (result) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    if (parseFloat(handleInputdos) != parseFloat(AllBreakupVal.total)) {
      toast.info(
        "Totalll Pending breakup value should be equal to pending Value "
      );
      return;
    }

   
    let obj = {
      date: handledosDate,
      recievedAmount: handleInputdos,
      status: null,
      id: dosArrayNonV.length + 1,

      providerVal: AllBreakupVal.providerVal,
      payerVal: AllBreakupVal.payerVal,
      patVal: AllBreakupVal.patVal,
      operationVal: AllBreakupVal.operationVal,
      otherVal: AllBreakupVal.otherVal,
      total: AllBreakupVal.total,
      Pending:pendingClaimss
    };

    const validationFields = [
      {
        fieldName: 'recievedAmount',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: StringType() }
  
      },
      // {
      //   fieldName: 'AmountDosVal',
      //   rules: { requiredMessage: 'AmountDosVal is required.' },
      //   type: { typeError: StringType() }
  
  
      // },
      {
        fieldName: 'date',
        rules: { requiredMessage: 'Date is required.' },
        type: { typeError: DateType() }
  
  
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);
    // if (errors.AmountDosVal.hasError) {
    //   setErrorModel((prevErrorModel) => ({
    //     ...prevErrorModel,
    //     AmountDosVal: true,
  
    //   }));
  
  
    // }
    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
  
      }));
  
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
  
      }))
  
    }
    if (!errors.recievedAmount.hasError && !errors.date.hasError) {
      setDosArrayNonV((prevDosArray) => [...prevDosArray, obj]);
  
    }
    
   
  };
  const stylesN = {
    marginBottom: 10,
  };

  const CustomInputGroupWidthButtonN = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={stylesN}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );
  const onSaveDosN = () => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayNonV);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayNonV;
    }

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount);
      });
      setPendingClaimss(0)
     
      sethandleInput(null)
      setBreakupVal(breakupObj);
      setNonVerified(count)
  //  setWorkedClaims(count);
    setDosModelN(false);

  };
  const handleEditStateN = async (rowData) => {
    if(!rowData.hasOwnProperty("status"))
  {
    return

  }
    const nextData = Object.assign([], dosArrayNonV);
    const activeItem = nextData.find((item) => item.id === rowData.id);

    activeItem.status = activeItem.status ? null : "EDIT";
    setDosArrayNonV(nextData);

    // if(rowData.status=='Save'){
    //   let obj={
    //     date:rowData.date,
    //     recievedAmount:rowData.recievedAmount,
    //     status:'EDIT'
    //   }
    //   setDosArray(prevDosArray => [...prevDosArray, obj]);

    // }

    // const nextData = Object.assign([], data);
    // const activeItem = nextData.find((item) => item.id === rowData.id);
    // activeItem.status = rowData.status === 'Save' ? 'EDIT' : null;
    // setData(nextData);
  };

  let nextDatan;
  const handleChangeN = (id, key, value,event) => {
    event.persist();
    
    nextDatan = Object.assign([], dosArrayNonV);
    nextDatan.find((item) => item.id === id)[key] = value;
   setDosArrayNonV(nextDatan);
  };

  useEffect(() => {
  
    
   
    let Pending =
      parseFloat(receivedClaims ? receivedClaims : 0) -
       
      (parseFloat(verfied ? verfied : 0) + parseFloat(nonverfied ? nonverfied : 0));

    setPendingClaims(Pending);
  }, [receivedClaims, verfied, nonverfied]);

  useEffect(() => {
    let pendingAmount =
      parseFloat(receivedAmount ? receivedAmount : 0) +
      parseFloat(bfamount ? bfamount : 0) -
      parseFloat(workedAmount ? workedAmount : 0);
    console.log();

    setPendingAmount(pendingAmount);
  }, [receivedAmount, workedAmount, pendingAmount]);

  useEffect(() => {
    
    if (providerVal || patVal || payerVal || otherVal || operationVal) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(otherVal) || 0) +
        (parseFloat(operationVal) || 0) +
        (parseFloat(patVal) || 0) +
        (parseFloat(payerVal) || 0);
  
      setTotalVal(totalValue);
    } else {
      setTotalVal(0);
    }
  }, [providerVal, patVal, operationVal, otherVal, payerVal]);

  useEffect(() => {
    if (receivedAmount && workedAmount) {
      setPendingAmount(receivedAmount - workedAmount);
    } else {
      setPendingAmount(0);
    }
  }, [receivedAmount, workedAmount]);

  const onChangeValues = (event) => {
    const { name, value } = event.target;

    if (name == "workedAmount") {
      setWorkedAmount(value);
      return;
    }
    if (name == "verfied") {
      setVerified(value);
      return;
    }
    if (name == "nonverfied") {
      setNonVerified(value);
      return;
    }

    if (name == "receivedClaims") {
      setReceivedClaims(value);
      return;
    }
    if (name == "receivedAmount") {
      setReceivedAmount(value);
      return;
    }

    if (name == "pendingAmount") {
      setPendingAmount(value);
      return;
    }
    if (name == "totalVal") {
      setTotalVal(parseFloat(value, 10));
      return;
    }
    if (name == "providerVal") {
      setProviderVal(parseFloat(value, 10));
      return;
    }
    if (name == "patVal") {
      setPatVal(parseFloat(value, 10));
      return;
    }

    if (name == "otherVal") {
      setotherVal(value);
      return;
    }
    if (name == "operationVal") {
      setOperationVal(parseFloat(value, 10));
      return;
    }
    if (name == "payerVal") {
      setpayerVal(parseFloat(value, 10));
      return;
    }
    if (name == "generalTextField") {
      setGeneralTextField(value);
      return;
    }
    if (name == "insuranceRecieved") {
      setInsuranceRecieved(value);
      return;
    }
    if (name == "takenYes") {
      setActionToken(value);
      return;
    }
    if (name == "takenNo") {
      setActionToken(value);
      return;
    }

    if (name == "comments") {
      setComments(value);
      return;
    }
  };
  const transformArray = (arr) => {
   
    if(arr==undefined){
      return []
    }

    if (! arr && arr.length === 0) {
      return [];
    }
  
    let parseArr;
  
    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }
  
    const filteredArray = parseArr && parseArr.filter(item => item?.bfVal !== 0);
  
    const newArray = filteredArray.length > 0 && filteredArray.map(item => ({
      ...item,
      recievedAmountCC: item?.bfVal?.toString(),
      AmountDosValCC: item?.bfAmount?.toString(),
      Adjusted:"0",
      recievedAmount: "0",
      internalNonVerify:"0",
      AmountDosVal: "0",
      providerVal: "0",
      codingVal: 0,
      credientalingVal: 0,
      operationVal: 0,
      EdiVal: 0,
      total: 0,
      pendingAmount:0,
      pendingStat:0,
      
      bfRow: true
    }));
  
    return newArray;
  };
  

  // const transformArray=(arr)=>{

  
  //   if(arr && arr.length==0|| arr==undefined){
  //     return []
  //   }
 
  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);
      
  //   } catch (error) {
      
  //     parseArr = arr;
      

  //   }
    
  //   const newArray = parseArr && parseArr.map(item => ({
  //     ...item,
      
  //     recievedAmountCC:item?.bfVal?.toString(),
  //     AmountDosValCC: item?.bfAmount?.toString(),
  //     recievedAmount: "0",
  //     Adjusted:"0",
  //     AmountDosVal: "0",
  //     internalNonVerify:"0",
  //     providerVal: "0",
  //     codingVal: 0,
  //     credientalingVal: 0,
  //     operationVal: 0,
  //     EdiVal: 0,
  //     total: 0,
  //     bfRow:true
  //   }));
  
  //   return newArray;
  // }


  const checkArraysHaveSameDateAndAmount=(arr1, arr2)=> {
    const matchingItem = arr1!==undefined && arr1.length>0 &&arr1.find(item1 =>
      arr2.some(item2 => item1.date === item2.date && item1.recievedAmount === item2.recievedAmount)
    );
  
    return !matchingItem;
  }
  const handleChargeFieldsOnGetRecord = (record) => {
    
  

    
    if (record?.result.length==0 ) {
      setFileList([])
      // setBfVal(record.bfvalprv);
      setBfVal(record?.bfvalprv)
     // setPaymentState(record?.dosArrayRecieved?.Adjusted)
       setBfamount(record.bfprv!=null?record.bfprv:0);
     // setBfScreenArray(record?.dosArrayRecieved)

      const newArray = transformArray(record?.dosArrayRecieved?record?.dosArrayRecieved:[]);
      setDosArrayRec(newArray)
       
       
    } else {
      let parseArr=[]
      try{
        parseArr=JSON.parse(record.result[0]?.dosArrayRecieved)

      }catch{
        parseArr=record.result[0]?.dosArrayRecieved

      }

      let parImage=[]
      try{
        parImage=JSON.parse(record.result[0]?.image)
      }catch{
        parImage=record.result[0]?.image

      }
    

      
 sethelpingState((prev) => ({ ...prev, bucketImages: record.result[0]?.image,updatedblodState:parImage }));
setImage(parImage)
 setFileList(parImage)//file
     
      const newArray = transformArray(record?.dosArrayRecieved);
      const result = checkArraysHaveSameDateAndAmount(newArray, parseArr);
      
      let combineArray;
      if(result==false){
        combineArray=parseArr
 
 }else{
  combineArray= newArray && newArray.concat(parseArr)

 }


     // let combineArray=newArray.concat(JSON.parse(record.result[0]?.dosArrayRecieved))
    setDosArrayRec(combineArray);
      let jsonString = record?.result[0]?.breakupval;
if (jsonString) {
  jsonString = jsonString.replace(/"otherval":\s*0+(\d+)/g, '"otherval": $1');
}


  let parsedArray = [];
    try {
      parsedArray = JSON.parse(record.result[0]?.dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = record.result[0]?.dosArray;
    }
    let isParseObj1 = [];
    try {
      isParseObj1 = JSON.parse(record.result[0]?.dosArrayNonV);
    } catch (error) {
      // Handle the case where JSON parsing failed
      isParseObj1=record.result[0]?.dosArrayNonV
    }

      let obj = {};
      let resobj={}
      try {
        obj = JSON.parse(jsonString);
        resobj=JSON.parse(record?.result[0]?.paymentstateAdjusted)
      } catch (error) {
        // Handle the case where JSON parsing failed
        console.error("Error parsing JSON:", error);
        resobj=record?.result[0]?.paymentstateAdjusted
      }
      console.log(obj,'obj')
    

      setReceivedClaims(record?.result[0]?.recievedval);

      setWorkedAmount(record?.result[0]?.collectedamount
        );
      setPendingClaims(record?.result[0]?.pendingval);
      setPendingAmount(record?.result[0]?.pendingamountval);
      setTotalVal(record?.result[0]?.totalval);
      setReceivedAmount(record?.result[0]?.receivedamount);
      setProviderVal(obj?.providerval);
      setPatVal(obj?.patval);

      setotherVal(obj?.otherval);
      setOperationVal(obj?.operval);
      setpayerVal(obj?.payerval);

      setActionToken(record.result[0]?.stoken);
      setGeneralTextField(record.result[0]?.GeneralOpen);
      setInsuranceRecieved(record.result[0]?.insurance);
     // setImage(record.result[0]?.image);
      setVerified(record.result[0]?.verifiedval);
      setNonVerified(record.result[0]?.nonverifiedval);
      setComments(record.result[0]?.comments);
      setDosArray(parsedArray)
      setDosArrayNonV(isParseObj1)
      setDosArrayRec(JSON.parse(record.result[0]?.dosArrayRecieved))
      setBfScreenArray(record?.dosArrayRecieved?.bfVal)
      setBfVal(record?.bfvalprv)
      setAdjustedState(record?.result[0]?.paymentstateAdjusted )
      sethelpingState((prev)=>({...prev,PatType:{label:record.result[0]?.PatType,value:record.result[0]?.PatType}}))
    }
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    }

    // if (nonverfied != totalVal) {
    //   toast.info("Total breakup should be equal to non-verified claims ");
    //   return;
    // }


    if(dosArrayRecieved.length==0){
      toast.info('For Save! Please Add Some Dos Wise Record')
      sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
      return

    }
    if (pendingClaims < 0) {
      toast.info("Pending Statments should not be in negative number  ");
      return;
    }
   
    if (pendingAmount < 0) {
      toast.info("Pending Amount should not be in negative number  ");
      return;
    }
    sethelpingState((prev)=>({...prev,loadingn:true}))

    const breakupval = `{
      "providerval": ${providerVal>0?providerVal:0},
      "patval": ${patVal>0?patVal:0},
      "payerval": ${payerVal>0?payerVal:0},
      "otherval": ${otherVal>0?otherVal:0},
      "operval": ${operationVal>0?operationVal:0}
    }`;
   

    let workedVal=parseFloat(verfied)+parseFloat(nonverfied)

    const formDataForSaveApi = new FormData();
    formDataForSaveApi.append("userid", onLoginSignOff.userid);
    formDataForSaveApi.append("sdate", date);
    formDataForSaveApi.append("pid", practiceId);
    

    formDataForSaveApi.append("formtype", formTypeNoForApi);
    formDataForSaveApi.append("submittype", "No");
    formDataForSaveApi.append("bfval", bfVal);
    formDataForSaveApi.append("bfamount", bfamount);
    formDataForSaveApi.append("recievedval", receivedClaims);
    formDataForSaveApi.append("recievedamount", receivedAmount);
    formDataForSaveApi.append("verifiedval", verfied);
    formDataForSaveApi.append("nonverifiedval", nonverfied);
    formDataForSaveApi.append("breakupval", breakupval);
    formDataForSaveApi.append("pendingval", pendingClaims);
    formDataForSaveApi.append("pendingamountval", pendingAmount);
    formDataForSaveApi.append("totalval", totalVal);
    formDataForSaveApi.append("GeneralOpen", generalTextField);
    formDataForSaveApi.append("insurance", insuranceRecieved);
    formDataForSaveApi.append("stoken", actionToken);
    formDataForSaveApi.append("PatType", helpingState?.PatType?.label);
   // formDataForSaveApi.append("file", image);
   if (image && image.length > 0 && image!="[]") {
    image.forEach((el, index) => {
      formDataForSaveApi.append(`file`,el?.blobFile );
    });
  } else {
    formDataForSaveApi.append(`file`, null);
  }
    formDataForSaveApi.append("comments", comments);
    formDataForSaveApi.append("collectedamount", workedAmount);
    formDataForSaveApi.append("dosArray", JSON.stringify(dosArray));
    formDataForSaveApi.append("dosArrayNonV", JSON.stringify(dosArrayNonV));
    formDataForSaveApi.append("dosArrayRecieved", JSON.stringify(dosArrayRecieved));
    formDataForSaveApi.append("workedVal", JSON.stringify(workedVal));
    formDataForSaveApi.append("paymentstateAdjusted", JSON.stringify(AdjustedState));

    


    let result = await insertFormType9PatStats(formDataForSaveApi);

    if (result?.data && result?.data.message == "Record Updated SuccessFully") {
      sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
      toast.info("Record updated Successfully");
      onClear();
      onRecordUpdateOrCreated('PatStat')
    }
    if (
      result?.data &&
      result?.data.message == "Record Created SuccessFully"
    ) {
      sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
      toast.info(result?.data.message);
      onClear();
      onRecordUpdateOrCreated('PatStat')
    }

    if (
      result?.data &&
      result?.data?.message == "Breakup should be equal to pending value!"
    ) {
      sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
      toast.info("Can`t Save Form ! Breakup should be equal to pending value!");
    }
    sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
  };

  const handleInputChange = (event) => {
    const selectedImage = event;

    setImage(selectedImage);

    setFileList(selectedImage);
  };
  const openImageViwerModel=(val)=>{

  
    setDocViewer(val)
    sethelpingState((prev) => ({ ...prev, isFileViewerOpen: val }));
  
  }

  const onClear = () => {
    setAdjustedState("")
    setReceivedClaims("");
    setReceivedAmount("");
    setVerified("");
    setNonVerified("");

    setWorkedAmount("");
    setPendingClaims(null);
    setPendingAmount(null);
    setTotalVal(null);
    setProviderVal(0);
    setPatVal(0);
    setpayerVal(0);

    setotherVal(0);
    setOperationVal(0);
    setGeneralTextField("");
    setInsuranceRecieved("");
    setActionToken(null);
    setImage("");
    setDosArrayNonV([])
    setDosArray([])
    setDosArrayRec([])
  };

  let totalValue =
    parseFloat(providerVal) +
    parseFloat(otherVal) +
    parseFloat(payerVal) +
    parseFloat(patVal) +
    parseFloat(operationVal);

  console.log("DOSaRRAYrECEIVED", dosArrayRecieved);

   //--------------------
 const handleCommonEdit=async(rowData)=>{
  if (rowData.AmountDosVal) {
    if(rowData.bfRow==true){
      setisThisbfRow(true)

    }else{
      setisThisbfRow(false)
    }

    let obj = {
      isEdit: true,
      rowDataa: rowData,
    };
    setGridEdit(obj);

    sethandleInputRec(rowData.recievedAmountCC)
    setAmountDosRec(rowData.AmountDosValCC)
    sethandleInput(rowData.recievedAmount)
    setRowID(rowData.id)
    setAmountDos(rowData.AmountDosVal)
    setInternalNonVerify(rowData.internalNonVerify)
    setDatePickerVal(moment(rowData.date, 'YYYY-MM-DD').toDate())
    setdosDateRec(rowData.date)
    setBreakupVal((prev)=>({
      ...prev,
      providerVal: rowData.providerVal,
      otherVal: rowData.otherVal,
      operationVal: rowData.operationVal,
      payerVal: rowData.payerVal,
    patVal: rowData.patVal,
    total: rowData.total,
    }))
    
  } else {
    return;
  }
 
  

 }
 const handleChangeCommondos=(id, key, value,event)=>{
  handleChangeC(id, key, value,event,dosArrayRecieved,setDosArrayRec)

 }

 const onSaveDosCo=()=>{
  if(handleInputdos=="" && handleInputdosRec=="" && internalNonVerify=="" && AmountDosRec=="" && pendingAmountDos=="" && AllBreakupVal?.providerVal=="" && AllBreakupVal?.patVal=="" && AllBreakupVal?.operationVal=="" && AllBreakupVal?.payerVal=="" && AllBreakupVal?.otherVal=="" && (dosArrayRecieved.length==0||dosArrayRecieved.length<0)){
    
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj)
    callGlobalDos(false);
    setBreakupVal(breakupObj);
    setpendingAmountDos(0);
    onSaveDosC(false,dosArrayRecieved,setReceivedClaims,setVerified,setWorkedAmount,'patStat',setdosDateRec,setAdjustedState,setNonVerified,setReceivedAmount
      )
    
    sethelpingState((prev)=>({...prev,isSpeakToaster:false}))

  }else{
    sethelpingState((prev)=>({...prev,isSpeakToaster:true}))

  }

 

  

 }
 const onChangeInputCC=(val,)=>{
  setErrorModel((prevErrorModel) => ({
    ...prevErrorModel,
    recievedAmountCC: false,
  }))
  
  
  onChangeInputC(val,sethandleInputRec,setErrorModel,'stopEecutingErrModel')
 


 }
 const onRemove=(val)=>{

  let remainingObjects = FileList.filter((obj) => obj.fileKey !== val.fileKey);
  setFileList(remainingObjects)
 // setResult((prevResult) => ({ ...prevResult, image: remainingObjects }));
 setImage(remainingObjects)
  

}
 const onChangeDateCC=(val)=>{
  setDatePickerVal(val)
  
  onChangeDateC(val,setdosDateRec,setErrorModel,dosArrayRecieved)
 
   
 }
 const onChangeDAmountCC=(val)=>{
  

  onChangeAmountC(val,setAmountDosRec,setErrorModel)
   
 }
 const handleCloseCC=()=>{

  sethelpingState((prev)=>({isBackSpeak:true}))
  

 }



const onChangePaymwents=(e)=>{
 
  
  const {value}=e.target

  setPaymentState(value)


}
 const onAdddosCC=()=>{
  
  let obj = {
    isEdit: false,
    rowDataa: null,
  };
  setGridEdit(obj)
  if (parseFloat(internalNonVerify) != parseFloat(AllBreakupVal.total)) {
    toast.info(
      "Total Rejected breakup value should be equal to Rejected value "
    );
    return;
  }



 
  
  onAdddosC(handledosDateRec,handleInputdosRec,dosArrayRecieved,AmountDosRec,setDosArrayRec,setErrorModel, null,
    'patStat',
    AllBreakupVal,
    paymentstateAdjusted,
    handleInputdos,
    AmountDos,
    setDosArray,internalNonVerify,gridEdit,
    rowID, bfVal,
    bfRow,)


    setPaymentState("")
    setisThisbfRow(false)
  sethandleInputRec("")
  setInternalNonVerify("")
  setAmountDosRec("")
  sethandleInput("")
  setAmountDos("")
  setPendingClaimss(0)
  setpendingAmountDos(0)
 // setdosDateRec("")
  
  setBreakupVal(breakupObj)
  
  

 }

 console.log('dosArray',dosArray)
 const openDosmodelWorked = () => {
  setDosModelRec(true);
};
const onCleanDateSimple=()=>{
  setdosDate('')
 }
 const onCleanDateSimpleC=()=>{
  setdosDateRec('')
  
 }
 const cleanDos=()=>{
  setdosDate('')

 }
 const renderr=(val)=>{
  setRenderEffect(val)

 }


 
 const openDosmodelPending = () => {
  if (dosArrayRecieved.length == 0) {
    toast.info("Please Enter Received Claims");
    return;
  }
 // setNameGeneric('Pending Claims')

  setModelPending(true);
};
const handleClosePopPending = () => {
 // setNameGeneric("Charge Entry")
  setModelPending(false);
};

const handleTaosterSpeak=()=>{
  if(dosArrayRecieved && dosArrayRecieved.length==0){
    toast.info('Please Enter Date of Service Wise Record')
    return
   }
    
  sethelpingState((prev)=>({...prev,isSpeakerToaster:true}))
}





const handleInternalNonVerify=(val)=>{


 
    const { value, name } = val.target;
    setInternalNonVerify(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      internalNonVerify: false,
    }));


}
const dummy=()=>{

}




const [openBf,setBf]=useState(false)
const openBfScreen=()=>{
  setBf(true)

}
const handleCloseBfScreen=()=>{
  setBf(false)

}

console.log('pendingClaims',pendingClaimss)


useEffect(() => {
  if(helpingState?.isAllStatesAreSet){
    onSave()
    
  }
 
}, [helpingState?.isAllStatesAreSet])



const handleSwalConfirm=(val)=>{

  if(val==true){
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj)
    callGlobalDos(false);
    setBreakupVal(breakupObj);
    setpendingAmountDos(0);
    onSaveDosC(false,dosArrayRecieved,setReceivedClaims,setVerified,setWorkedAmount,'patStat',setdosDateRec,setAdjustedState,setNonVerified,setReceivedAmount
      )
      sethelpingState((prev)=>({...prev,isSpeakToaster:false,isAllStatesAreSet:true}))
  }
  else if(!val){
    sethelpingState((prev)=>({...prev,isSpeakToaster:false}))
    
    
  }
  else if(val=='backToMainScreen'){
    
    sethelpingState((prev)=>({...prev,isSpeakToaster:false}))

    setPaymentState("")
    
  sethandleInputRec("")
  setInternalNonVerify("")
  setAmountDosRec("")
  sethandleInput("")
  setAmountDos("")
  setPendingClaimss(0)
  setpendingAmountDos(0)
 // setdosDateRec("")
  
  setBreakupVal(breakupObj)
  callGlobalDos(false);

  }

}

const handleSwalConfirm2=(val)=>{
  if(val){
    

    sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))
    onSave()

  }else{
    sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))

  }

}
const onHandleCloseCall=()=>{
  if(gridEdit.isEdit){
    onAdddosCC()



  }
  callGlobalDos(false);
  setBreakupVal(breakupObj);
  let obj = {
    isEdit: false,
    rowDataa: null,
  };
  setGridEdit(obj)
  setpendingAmountDos(0);
 
  handleCloseC(setDosModelRec,setErrorModel,sethandleInputRec,setAmountDosRec,setdosDateRec, sethandleInput, setAmountDos,
    setPendingClaimss)
}
const handleSwalConfirmBackButton=(val)=>{
  if(val){
    

    sethelpingState((prev)=>({...prev,isBackSpeak:false}))
    onHandleCloseCall()

  }else{
    sethelpingState((prev)=>({...prev,isBackSpeak:false}))

  }

}
const onChangePatStatType=(event)=>{


 sethelpingState((prev)=>({...prev,PatType:event}))

}







 

  return (
    <>
    {/* //verified */}
    {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}

    {helpingState.isSpeakToaster?<NotifySwal showDenyButton={true} triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Save Values'}/>:null}
   {helpingState.isSpeakerToaster?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm2}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
   {helpingState.isBackSpeak?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirmBackButton}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
     {
      openBf==true?<>
       
        <DateoofserviceBF
        handleClose={handleCloseBfScreen}
        dosArray={bfScreenArray}
           />
      </>
       

      :
     openScreenGlobalTable == true ? (
      <Dateoofservice
       
      isBlockAllhtml={true}
       
      renderr={renderr}
      renderEffect={renderEffect}
     
      dosArray={dosArrayRecieved}
      
      handleClose={handleClose}
     
      claimHeading={'Received'}
          claimAmount={'Received Amount'}
          claimHeading1={"Verified"}
          claimHeading2={"Received Amount"}
          claimHeading3={"Collected Amount"}
          claimHeading4={"Pending  Amount"}
          claimAmount1={"Rejected"}
      breakupName={'Pending Claim Breakup'}


      setdosDate={setdosDate}
      onlyShowTable={true}
      SecondTableName={'patStat'}
      showTableCheck={'patStat'}
      // flag={"patStat"}
      // changeState={'patState'}
    />
      ) : dosModelN == true ? ( //NON VERFIEID
        <Dateoofservice
        deductArray={dosArray}
        flag={"patStat"}
        changeState={"patState"}

        handledosDate={handledosDate}
 isPendingSectionOpen={isPendingSectionOpen}
        pendingClaimss={pendingClaimss}
        AmountDos={'ok'}
        handleInputdos={handleInputdos}
          setdosDate={setdosDate}
      
        onlyShowTable={false}
 allowPending={true}
        dosPendingBreakup={{ isAllow: true, onChangeValues: onChangeValues }}
        AllBreakupVal={AllBreakupVal}
        practiceId={practiceId}
      
        onCleanDate={cleanDos}
        errorModel={errorModel}
        renderr={renderr}
        renderEffect={renderEffect}
          handleChange={handleChangeN}
          handleEditState={handleEditStateN}
          onSaveDos={onSaveDosN}
          setDosArray={{state:setDosArrayNonV,flag:'1'}}
          dosArray={dosArrayNonV}
          onAdddos={onAdddosN}
          onChangeDate={onChangeDateN}
          onChangeInput={onChangeInputN}
          handleClose={handleCloseN}
          setPendingClaimm={dummy}
          claimHeading={'Rejected'}
          claimAmount={'Non-Verified Amount'}
          onChangeBreakupClaim={onChangeBreakupClaim}
          setisPendingSectionOpen={setisPendingSectionOpen}
          recievedClaimArraydos={dosArrayRecieved}
          
        />
      ):openDosmodelRecState == true ? (  //RECEEIVED PAT STAT
        <Dateoofservice
        bfVal={bfVal}
       // recievedClaimArraydos={dosArrayRecieved}
        practiceId={practiceId}
        setDosArray={{state:setDosArrayRec,flag:'1'}}
        onCleanDate={onCleanDateSimpleC}
        errorModel={errorModel}
        renderr={renderr}
        renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
         // onAdddos={onAdddosCC}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          claimHeading={'Received Patient Statments'}
          claimAmount={'Received Amount'}
          claimHeading1={"Verified"}
          claimHeading2={"Received Amount"}
          claimHeading3={"Collected Amount"}
          claimHeading4={"Pending  Amount"}
          claimAmount1={"Rejected"}
        />
      ): globalDos == true ? (
        <DateoofserviceGlobal
        handleKeyPress={handleKeyPress}
        Footer={footerState}
        flagFooter={'PatStat'}

        bfVal={bfVal}
       
        flag = {"patStat"}
        payments={onChangePaymwents}
        paymentstate={paymentstateAdjusted}
       
        datePickerVal={datePickerVal}
        openGlobalTable={openGlobalTable}
        
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
           AmountDos={AmountDos}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={dummy}
          pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={dummy}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}//verified
          onChangeInputCC={onChangeInputCC}//Received pat stat
          onChangeDate={onChangeDateCC} //dos date onchange
          handledosDate={handledosDateRec}//dos date state
          handleClose={handleCloseCC}//close
          onChangeAmount={onChangeAmount}//Received amount
          onChangeAmountCC={onChangeDAmountCC} //Collected mount
          onChangeInternalNonVerify={handleInternalNonVerify}
          internalNonVerify={internalNonVerify}

          showAmount={false}
          showPortal={false}
          claimHeading={'Received'}
          claimAmount={'Received Amount'}
          claimHeading1={"Verified"}
          claimHeading2={"Received Amount"}
          claimHeading3={"Worked Amount"}
          claimHeading4={"Pending  Amount"}
          claimAmount1={"Rejected"}
          practiceId={practiceId}
          onlyShowTable={false}
        />
      ):openModelPending ? (
        <Dateoofservice
          onlyShowTable={true}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={dosArrayNonV}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
          showTableCheck={"vob"}
        />
      ):<>
      {/* <DocViewer   docViewer={docViewer}  image={image} openImageViwerModel={openImageViwerModel}/> */}
      {helpingState?.isFileViewerOpen == true && (
        <ViewDocument
        seletedRow={helpingState?.getSelectedRowDocument}
        openImageViwerModel={openImageViwerModel}
      />

      )}
      
        <div class="row">
        <DateAndPractice
        PatTypeValue={helpingState?.PatType}
        onChangePatStatType={onChangePatStatType}
        showPatStatType={true}
            openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={products}
              date={date}
            />
              {/* <div className="col-lg-12 mb-2 mt-4 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Patient Statement
            
          </h4>
        </div> */}
        <Panel defaultExpanded={true} header="Patient Statement/Verification" collapsible >
        <div className="row mt-4">
          <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }} onClick={openBfScreen}>
              B/F Claims 
                {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
            </label>
            <input
              class="form-control inputField"
              type="text"
              name="bfVal"
              placeholder={""}
              value={bfVal}
              disabled
            />
          </div>
        </div>


        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received 
            </label>
            {/* <CustomInputGroupWidthButtonC
              value={receivedClaims}
              disabled={true}
              size="md"
              onClick={openDosmodelWorked}
              placeholder="Medium"
            /> */}

            <input
              class="form-control inputField"
              type=""
              name="receivedClaims"
              placeholder=""
              value={receivedClaims}
              onChange={onChangeValues}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div className="ebos">
            <div class="form-group mb-2 ">
              <label class="inputLabel" style={{ color: "black" }}>
                Verified{" "}
              </label>
              <input
                class="form-control inputField"
                type="number"
                name="verfied"
                placeholder=""
                onChange={onChangeValues}
                value={verfied}
                disabled
              />
               {/* <CustomInputGroupWidthButton
              value={verfied}
              disabled={true}
              size="md"
              onClick={openDosmodel}
              placeholder="Medium"
            /> */}
            </div>
            <div class="form-group ">
              <label class="inputLabel" style={{ color: "black" }}>
                Rejected{" "}
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="nonverfied"
                placeholder=""
                onChange={onChangeValues}
                value={nonverfied}
                disabled
              />
              {/* <CustomInputGroupWidthButtonN
              value={nonverfied}
              disabled={true}
              size="md"
              onClick={openDosmodelN}
              placeholder="Medium"
            /> */}
            </div>
            {/* <div class="form-group mb-2 ">
              <label class="inputLabel" style={{ color: "black" }}>
                Adjusted{" "}
              </label>
              <input
                class="form-control inputField"
                type="number"
                name="verfied"
                placeholder=""
                onChange={onChangeValues}
                value={AdjustedState}
                disabled
              />
               
            </div> */}
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending 
            </label>

            <input
              class="form-control inputField"
              type="text"
              name="pendingClaims"
              placeholder=""
              value={pendingClaims}
              disabled
            />
             {/* <CustomInputGroupWidthButton
                  value={
                    pendingClaims
                  }
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
          </div>
        </div>
      
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              B/F Amount
            </label>
            <div  class="form-group input-icon">
            <input
              class="form-control inputField"
              type="text"
              name="bfamount"
              placeholder=""
              value={bfamount}
              disabled
            />
            <i className="lightGreen">$</i>

            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Amount
            </label>
            <div  class="form-group input-icon">

 
            <input
            disabled
              class="form-control inputField"
              type="text"
              name="receivedAmount"
              placeholder=""
              value={receivedAmount?.toLocaleString()}
              onChange={onChangeValues}
            />
             <i className="lightGreen">$</i>
          </div>
         
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
             Worked Amount
            </label>
            <div  class="form-group input-icon">
            <input
              class="form-control inputField"
              type="text"
              name="workedAmount"
              placeholder=""
              value={workedAmount?.toLocaleString()}
              disabled
              onChange={onChangeValues}
            />
            <i className="lightGreen">$</i>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Amount
            </label>
            <div  class="form-group input-icon">
            <input
              class="form-control inputField"
              type="text"
              name="pendingAmount"
              placeholder=""
              value={pendingAmount?.toLocaleString()}
              onChange={onChangeValues}
              disabled
            />
            <i className="lightGreen">$</i>
            </div>
          </div>
        </div>
          </div>
      
        </Panel>
        
       

        <div className="col-lg-12 mb-2 text-center"></div>

        <div class="col-sm-6 col-lg-3"></div>
        <div class="col-sm-6 col-lg-3"></div>
        <div class="col-sm-6 col-lg-3"></div>

       

        <Panel defaultExpanded={true} header="Total Rejected Breakup" collapsible >
       <div className="row mt-3">
       <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Provider End
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="providerVal"
              placeholder=""
              disabled
              value={providerVal}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Patient End
            </label>

            <input
            disabled
              class="form-control inputField"
              type="number"
              name="patVal"
              placeholder=""
              value={patVal}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Operation End
            </label>

            <input
            disabled
              class="form-control inputField"
              type="number"
              name="operationVal"
              placeholder=""
              value={operationVal}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Payer`s End
            </label>

            <input
            disabled
              class="form-control inputField"
              type="number"
              name="payerVal"
              placeholder=""
              value={payerVal}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Other
            </label>

            <input
            disabled
              class="form-control inputField"
              type="number"
              name="otherVal"
              placeholder=""
              value={otherVal}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Total
            </label>

            <input
            disabled
              class="form-control inputField"
              type="number"
              name="totalVal"
              placeholder=""
              value={totalValue}
              onChange={onChangeValues}
            />
          </div>
        </div>
       </div>

        </Panel>
        
       
         <Panel defaultExpanded={true} header="Remarks" collapsible={false} >
        

        <div class="col-sm-12 col-lg-12 mt-4">
          <div class="form-group mb-2">
            {/* <label class="inputLabel" style={{ color: "black" }}>
              Other Task
            </label> */}
            <textarea
                 rows="4" cols="50"
                 type="text"
                 name="generalTextField"
                 placeholder="Enter Remarks Here"
                 class="form-control inputField"
                 value={generalTextField}
                 onChange={onChangeValues}
                ></textarea>

            {/* <input
              class="form-control inputField"
              type="text"
              name="generalTextField"
              placeholder=""
              value={generalTextField}
              onChange={onChangeValues}
            /> */}
          </div>
        </div>
        </Panel>
       
        <div class="col-sm-12 col-lg-12">
          <div class="form-group ">
           
            <FileUpload2


onRemove={onRemove}
setobjCLuster={setobjCLuster}
objectcluster={objectcluster}
helpingState={helpingState}
  onPreview={onPreview}
  fileList={FileList}
  openImageViwerModel={openImageViwerModel}
  handleInputChange={handleInputChange}
  image={image}
/>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 "></div>

        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                
                className="btnClear mr-1 "
                onClick={onClear}
              >
                Clear
              </button>
              <button type="button" className="btnSave" onClick={handleTaosterSpeak}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      </>}
      
     
     
    
    </>
  );
}
export default PatStatement;
