import React, { useState } from "react";
import AuditField from "./AuditFields";
import { Panel } from "rsuite";
import AuditTab from "./AuditFormTab";
import Agging from "./Agging";
import Threshold from "./Threshold/Threshold";



function AuditMainForm({

  setProducts,
  date,
  handleFormVal,
  products,
  startDatee,
  endDate,
  formType,
}) {
  let obj = {
    tabName: "userWise",
  };
  const [helpingObj, sethelpingObj] = useState(obj);
  return (
    <>
      <div class=" row mt-1">
        <div class="" id="accordion" role="tablist" aria-multiselectable="true">
          {true && (
            <>
              {" "}
              <div
                class="panel panel-default mt-4"
                //  onClick={() => handleClick(1)}
              >
                {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={true ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={
                           true ? true : false
                          }
                          aria-controls="collapseOne"
                        >
                         Audit 
                        </a>
                      </h4>
                    </div> */}

                <div
                  id="collapseOne"
                  className={
                    true
                      ? "panel-collapse collapse show"
                      : "panel-collapse collapse show"
                  }
                  aria-expanded={true ? true : false}
                >
                  <div class="">
                    <AuditTab sethelpingObj={sethelpingObj} />
                    {helpingObj.tabName == "userWise" ? (
                      
                      
                        <div className="row">
                      <AuditField
                     
                      setProducts={setProducts}
                       formTypeString={"UserWise Productivity"}
                        date={date}
                        handleFormVal={handleFormVal}
                        products={products}
                        startDatee={startDatee}
                        endDate={endDate}
                        formType={formType}
                       
                      />
                      
                      
                      </div>
                     
                    ) :helpingObj.tabName=="Agging"? (
                      <Agging />
                    ):<Threshold   products={products}/>}

                    {/* <ChargeEntry  record={record} practiceId={practiceId}/> */}
                    {/* <ChargeEntry  subCheckBox={subCheckBox} formType={formType} record={record} practiceId={practiceId} formTypeNoForApi={formTypeNoForApi} date={date} /> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default AuditMainForm;
