import React, { useEffect, useState } from "react";
import DateAndPractice from "../../Operation/AllForms/DateAndPractice";
import Select from "react-select";
import { DatePicker } from "rsuite";
import AmountVerification from "./AmountVerification";
import { FetchCodingForTl, fetchProduct, getUsersName } from "../../../Utils/ApiManager";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NewDetailTableScreen from "./newDetailTableScreen";

export default function TLInterfaceOfCoding() {
  const { onLoginSignOff } = useSelector((state) => state);

  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
  let obj = {
    thisContiansData:[],
    isShowDetailedTable:false,
    id: 0,
    openPopup: false,
    praticesNames: [],
    praticeSelection: null,
    // funhandlePracticeVal: handlePracticeVal,
    dateOfEntry: moment().format("YYYY-MM-DD"),
    bfVal: "",
    internalTableArray: [],
    receivedClaims: "",
    workedClaims: "",
    pendingClaims: "",
    provider: "",
    operation: "",
    total: "",
    remarks: "",
    loading: false,
    isOpenSwal: false,
    isOpenView: false,
    isAllStatesAreSet: false,
    getAllBellUsers:[],
    ImageSection: {
      image: [],
      bucketImages: null,
      isFileViewerOpen: null,
      FileList: [],

      getSelectedRowDocument: null,
    },
    selectedRow:[],
    Type: { label: "Paper", value: "Paper" },
    file: null,
    totalOperation: "",
    totalPcTotal: "",
    totalProvider: "",
  };

  const [helpingState, setHelpingState] = useState(obj);
  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setHelpingState((prev) => ({ ...prev, praticesNames: optionList }));
    }
  };
  const handlePracticeVal = (val) => {
    console.log(val, "MMMMMMMMM");
    setHelpingState((prev) => ({ ...prev, praticeSelection: val }));
  };
  const handleDosDate = (event) => {
    if (event == null) {
      setHelpingState((prev) => ({
        ...prev,
        dateOfEntry: moment().format("YYYY-MM-DD"),
      }));
    }
    let date;
    if (event) {
      // Check if it's a date picker change (assuming Dos is a property in your state)
      if (event instanceof Date) {
        date = moment(event).format("YYYY-MM-DD");
        setHelpingState((prev) => ({ ...prev, dateOfEntry: date }));
      }
    }
   
  };
  useEffect(() => {
    
    if(helpingState?.dateOfEntry  && (helpingState?.praticeSelection!=null &&helpingState?.praticeSelection?.value))
    {
      fetchRecord(helpingState?.dateOfEntry);

    }
   
  }, [helpingState?.dateOfEntry,helpingState?.praticeSelection?.value])
  
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
    }
  };




  const fetchRecord = async (date) => {
    let obj = {
      userId: onLoginSignOff.userid,
      pid: helpingState?.praticeSelection?.value,
      sdate: date,
      formType: "10",
    };
    setHelpingState((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const res = await FetchCodingForTl(obj);
     
      const firstResponse = res?.data?.response[0];
      debugger
      

      if (
        firstResponse?.totalWorkedClaims == 0 &&
        firstResponse?.totalPendingClaims == 0 &&
        firstResponse?.totalReceivedClaims == 0 &&
        firstResponse?.totalOperation == 0 &&
        firstResponse?.totalPcTotal == 0 &&
        firstResponse?.totalProvider == 0
      ) {
        toast.info("No data Found");
        setHelpingState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
      setHelpingState((prev) => ({
        ...prev,
        workedClaims: firstResponse?.totalWorkedClaims,
        pendingClaims: firstResponse?.totalPendingClaims,
        receivedClaims: firstResponse?.totalReceivedClaims,
        totalOperation: firstResponse?.totalOperation,
        totalPcTotal: firstResponse?.totalPcTotal,
        totalProvider: firstResponse?.totalProvider,
      }));
      setHelpingState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      console.log(error, "error");
      setHelpingState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  const backToMainScreen=()=>{
  
    setHelpingState((prev)=>({...prev,
      isShowDetailedTable:false
    }))

  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  console.log('helpingState?.thisContiansData',helpingState?.thisContiansData)
  return (<>
   {helpingState?.isShowDetailedTable?<NewDetailTableScreen data={helpingState?.selectedRow} backToMainScreen={backToMainScreen}/>:
   <div className="row m-2">
      {helpingState.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : null}
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Practice
        </label>
        <br />
        <Select
          options={helpingState?.praticesNames || []}
          placeholder="Select an option"
          onChange={handlePracticeVal}
          value={{
            label: helpingState?.praticeSelection?.label,
            value: helpingState?.praticeSelection?.value,
          }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>
      <div className="col-4">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Entry Date
        </label>
        <br />
        <DatePicker
          onChange={handleDosDate}
          style={{ width: 400 }}
          oneTap
          value={
            helpingState?.dateOfEntry
              ? moment(helpingState?.dateOfEntry).toDate()
              : null
          }
          onKeyDown={handleKeyDown}
        />
        {/* <DatePicker
            class="form-control inputField"
            type="date"
            name="phone"
            placeholder=""
            max={today}
            min={minDate}
            //  defaultValue={date}
              onChange={handleDosDate}
          value={helpingState?.dateOfEntry ? moment(helpingState?.dateOfEntry).toDate() : null}
          /> */}
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>

        {/* {false ? null : <div className="col-3"></div>} */}
      </div>
      <div className="col-112">
        {" "}
        <AmountVerification data={helpingState} setHelpingState={setHelpingState} />
      </div>
    </div>}


  </>
   
    
   
  );
}
