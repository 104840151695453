





/// Sign off redux action
function onLoginSignOFF(payload) {
  return {
    type: "signOFF",
    payload: payload,
  };
}

function userLists(payload) {
  return {
    type: "userlist",
    payload: payload,
  };
}


function userNames(payload) {
  return {
    type: "userNames",
    payload: payload,
  };
}
function practName(payload) {
  return {
    type: "practiceName",
    payload: payload,
  };
}
function practIdies(payload) {
  return {
    type: "practiceIdies",
    payload: payload,
  };
}
function AllConfirmationOnTab(payload) {
  return {
    type: "AllConfirmationOnTab",
    payload: payload,
  };
}


function isConfirmationSpeak(payload){
  return {
    type: "isConfirmationSpeakonTab",
    payload: payload,
  };

}


function PermissionsAction(payload){
  return {
    type: "permissions",
    payload: payload,
  };

}


function getPracticeAgainstTeam(payload){
  return {
    type: "getPractices",
    payload: payload,
  };

}






export {
  


  // sIGN Off
  
  AllConfirmationOnTab,
  getPracticeAgainstTeam,
  PermissionsAction,
  onLoginSignOFF,
  userNames,
  userLists,
  practIdies,
  practName,
  isConfirmationSpeak
  
};
