import React, { useState, useEffect } from "react";
import DateAndPractice from "../../DateAndPracticeTl";
import AuditTableTl from "./AuditTable";
import { Button, ButtonToolbar } from "rsuite";
import ChargeTypeRemarksPop from "./DrawerChargeEntry";
import FacebookOfficialIcon from "@rsuite/icons/legacy/FacebookOfficial";
import { PanelGroup, Panel, Placeholder, Stack, ButtonGroup } from "rsuite";
import DetailIcon from "@rsuite/icons/Detail";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import CharacterAuthorizeIcon from "@rsuite/icons/CharacterAuthorize";
import Dateoofservice from "../../../../Common/Dateoofservice";

import { Toggle } from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";

const AmountVerification = ({
  setTableState,

  setHelpingState,
  helpingStatem,
  tableState,
  state,
  handelChange,
  handleFormTypeAndPract,
  products,
  onSearch,
  practiceId,
  date,
}) => {
  const backToMainScreen = () => {
    setHelpingState((prev) => ({ ...prev, isSwitchTable: false }));
  };
  const CloseAuditRemark = () => {
    setHelpingState((prev) => ({ ...prev, isToggleRemarks: false }));
  };

  let obj = {
    toggleSubTabName: "chargeEntry",
    isSwitchDosView: false,
    pendingClaimToggleMode: null,
    chargeTotal: null,
    RejectTotal: null,
    AuthTotal: null,
    vobTotal: null,
  };
  const [helpingState, sethelpingState] = useState(obj);

  useEffect(() => {
    if (helpingState?.toggleSubTabName) {
      setHelpingState((prev) => ({
        ...prev,
        RejectionTableState: [],
        AuthorizationTableState: [],
        VobsTableState: [],
      }));
      setTableState([]);
    }
  }, [helpingState.toggleSubTabName]);

  useEffect(() => {
    if (state.parsedBreakupValsCharge) {
      let chargetotal = Object.values(state.parsedBreakupValsCharge).reduce(
        (acc, value) => acc + parseInt(value),
        0
      );
      sethelpingState((prev) => ({ ...prev, chargeTotal: chargetotal }));
    }
    if (state.parsedBreakupValsReject) {
      let chargeRejection = Object.values(state.parsedBreakupValsReject).reduce(
        (acc, value) => acc + parseInt(value),
        0
      );
      sethelpingState((prev) => ({ ...prev, RejectTotal: chargeRejection }));
    }
    if (state.parsedBreakupValsAuth) {
      let chargeAuth = Object.values(state.parsedBreakupValsAuth).reduce(
        (acc, value) => acc + parseInt(value),
        0
      );
      sethelpingState((prev) => ({ ...prev, AuthTotal: chargeAuth }));
    }
    if (state.parsedVobbreakupval) {
      let vobTotal = Object.values(state.parsedVobbreakupval).reduce(
        (acc, value) => acc + parseInt(value),
        0
      );
      sethelpingState((prev) => ({ ...prev, vobTotal: vobTotal }));
    }
  }, [state.parsedBreakupValsCharge]);

  useEffect(() => {
    sethelpingState((prev) => ({ ...prev, pendingClaimToggleMode: null }));
  }, [helpingState.toggleSubTabName]);

  let name = "Charge Entry Table";
  name =
    helpingState.toggleSubTabName == "chargeEntry"
      ? "Charge Entry Table"
      : helpingState.toggleSubTabName == "Rejection"
      ? "Rejection Audit"
      : helpingState.toggleSubTabName == "Authorization"
      ? "Authorization Audit"
      : helpingState.toggleSubTabName == "Eligibility/vobs"
      ? "Eligibility/vobs Audit"
      : null;

  const handleToggle = (e) => {
    const { name } = e.target;
    console.log(name, "newName");
    sethelpingState((prev) => ({ ...prev, toggleSubTabName: name }));
  };

  let pendingSectionhtmlCharge = (
    <>
      <div className="row">
        {/* <div className="col-lg-12 mb-2 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Pending Claims Breakup
          </h4>
        </div> */}
        <div className="mt-4"></div>
         <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >
          <div className="row mt-4">
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Provider
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="providerVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsCharge?.providerVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Coding
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="codingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsCharge?.operationVal}
              //onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Credentialing
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="credientalingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsCharge?.credientalingVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              EDI
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="EdiVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsCharge?.EdiVal}
              //    onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Operation
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="operationVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsCharge?.operationVal}
              //  onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Total
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="total"
              placeholder=""
              disabled
              value={helpingState.chargeTotal}
              // onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        </div>
        </Panel>
      </div>
    </>
  );
  let pendingSectionhtmlRejection = (
    <>
      <div className="row">
        {/* <div className="col-lg-12 mb-2 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Pending Claims Breakup
          </h4>
        </div> */}
        <div className="mt-4"></div>
         <Panel defaultExpanded={true} header=" Pending Claims Breakup" collapsible={true} >
          <div className="row mt-4">
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Provider
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="providerVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsReject?.providerVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Coding
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="codingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsReject?.codingval}
              //onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Credentialing
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="credientalingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsReject?.credientalingVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              EDI
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="EdiVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsReject?.EdiVal}
              //    onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Operation
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="operationVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsReject?.operationVal}
              //  onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Total
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="total"
              placeholder=""
              disabled
              value={helpingState.RejectTotal}
              // onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        </div>
        </Panel>
      </div>
    </>
  );
  let pendingSectionhtmlAuth = (
    <>
      <div className="row">
        {/* <div className="col-lg-12 mb-2 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Pending Claims Breakup
          </h4>
        </div> */}
         <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >
          <div className="row mt-4">
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Provider
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="providerVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsAuth?.providerVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Coding
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="codingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsAuth?.codingVal}
              //onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Credentialing
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="credientalingVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsAuth?.credientalingVal}
              //   onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              EDI
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="EdiVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsAuth?.EdiVal}
              //    onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Operation
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="operationVal"
              placeholder=""
              disabled
              value={state.parsedBreakupValsAuth?.operationVal}
              //  onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Total
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="total"
              placeholder=""
              disabled
              value={helpingState.AuthTotal}
              // onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        </div>
        </Panel>
      </div>
    </>
  );

  let pendingSectionvob = (
    <>
    <div className="mt-4"></div>
     <Panel defaultExpanded={true} header=" Pending Claims Breakup" collapsible={true} >
      <div className="row mt-4">

        {/* <div className="col-lg-12 mb-2 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
              paddingBottom: "10px",
            }}
          >
            Pending Claims Breakup
          </h4>
        </div> */}
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Provider
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="providerVal"
              placeholder=""
              value={state?.parsedVobbreakupval?.providerVal}
              disabled
              //  onChange={onChangeBreakupClaim}
            />
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Operation
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="operationVal"
              placeholder=""
              value={state?.parsedVobbreakupval?.operationVal}
              disabled
              //  onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Total
            </label>

            <input
              class="form-control inputField"
              type="number"
              name="total"
              placeholder=""
              disabled
              value={helpingState?.vobTotal}
              // onChange={onChangeBreakupClaim}
            />
          </div>
        </div>
      </div>
      </Panel>
    </>
  );

  const chargeEntryHtml = (
    <>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.receivedClaims}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.workedClaims}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Claims
            </label>
            <input
              class="form-control inputField"
              name="PendingClaims"
              placeholder=""
              value={state?.pendingClaims}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Amount
            </label>
            <input
              class="form-control inputField"
              name="ReceivedAmount"
              placeholder=""
              value={state?.receivedAmount?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Amount
            </label>

            <input
              class="form-control inputField"
              name="billedAmount"
              placeholder=""
              value={state?.workedAmount?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Amount
            </label>
            <input
              class="form-control inputField"
              name="PendingAmount"
              placeholder=""
              value={state?.pendingAmount?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
  const RejectionEntryHtml = (
    <>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.totalrecievedvalClaimsReject}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.totalworkedvalClaimsReject}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Claims
            </label>
            <input
              class="form-control inputField"
              name="PendingClaims"
              placeholder=""
              value={state?.totalpendingvalReject}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Amount
            </label>
            <input
              class="form-control inputField"
              name="ReceivedAmount"
              placeholder=""
              value={state?.totalRecievedAmountReject?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Amount
            </label>

            <input
              class="form-control inputField"
              name="billedAmount"
              placeholder=""
              value={state?.totalWorkedAmountReject?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Amount
            </label>
            <input
              class="form-control inputField"
              name="PendingAmount"
              placeholder=""
              value={state?.totalpendingValReject?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
  const AuthorizationHtml = (
    <>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.totalrecievedvalClaimsAuth}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Claims
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.totalworkedvalClaimsAuth}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Claims
            </label>
            <input
              class="form-control inputField"
              name="PendingClaims"
              placeholder=""
              value={state?.totalpendingvalAuth}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received Amount
            </label>
            <input
              class="form-control inputField"
              name="ReceivedAmount"
              placeholder=""
              value={state?.totalRecievedAmountAuth?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Worked Amount
            </label>

            <input
              class="form-control inputField"
              name="billedAmount"
              placeholder=""
              value={state?.totalWorkedAmountAuth?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Pending Amount
            </label>
            <input
              class="form-control inputField"
              name="PendingAmount"
              placeholder=""
              value={state?.totalpendingValAuth?.toLocaleString()}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
  const Vobs = (
    <>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Received
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.totalRecievedVob}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Through Portal
            </label>
            <input
              class="form-control inputField"
              name="workedClaims"
              placeholder=""
              value={state?.portals}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Through Calls
            </label>
            <input
              class="form-control inputField"
              name="PendingClaims"
              placeholder=""
              value={state?.calls}
              onChange={handelChange}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
  let chargeCheck = helpingState.toggleSubTabName == "chargeEntry";
  let RejectionCheck = helpingState.toggleSubTabName == "Rejection";
  let AuthCheck = helpingState.toggleSubTabName == "Authorization";
  let vob = helpingState.toggleSubTabName == "Eligibility/vobs";

  const [renderEffect, setRenderEffect] = useState(false);

  const renderr = (val) => {
    setRenderEffect(val);
  };
  const handleClose = () => {
    sethelpingState((prev) => ({ ...prev, isSwitchDosView: false }));
    setHelpingState((prev) => ({ ...prev, isToggleEffect: false }));
  };

  const switchDosHelpignFunc = (val) => {
    if (chargeCheck) {
      let chargeParsed = [];
      try {
        chargeParsed = JSON.parse(val.dosArrayRecieved);
      } catch {
        chargeParsed = val.dosArrayRecieved;
      }

      setHelpingState((prev) => ({
        ...prev,
        dosArrayRecievedCharge: chargeParsed,
      }));
    } else if (RejectionCheck) {
      let RejectParsed = [];
      try {
        RejectParsed = JSON.parse(val.dosArrayRecieved);
      } catch {
        RejectParsed = val.dosArrayRecieved;
      }
      setHelpingState((prev) => ({
        ...prev,

        dosArrayRecievedRejection: RejectParsed,
      }));
    } else if (AuthCheck) {
      let AuthParsed = [];
      try {
        AuthParsed = JSON.parse(val.dosArrayRecieved);
      } catch {
        AuthParsed = val.dosArrayRecieved;
      }

      setHelpingState((prev) => ({
        ...prev,

        dosArrayRecievedAuth: AuthParsed,
      }));
    } else if (vob) {
      let vobParsed = [];
      try {
        vobParsed = JSON.parse(val.dosArrayRecieved);
      } catch {
        vobParsed = val.dosArrayRecieved;
      }
      setHelpingState((prev) => ({
        ...prev,

        dosArrayRecievedVob: vobParsed,
      }));
    }

    sethelpingState((prev) => ({ ...prev, isSwitchDosView: true }));
    setHelpingState((prev) => ({ ...prev, isToggleEffect: true }));
  };
  console.log("helpingStatem", helpingStatem);
  const toggleBreakup = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      sethelpingState((prev) => ({ ...prev, pendingClaimToggleMode: value }));
    } else {
      sethelpingState((prev) => ({ ...prev, pendingClaimToggleMode: null }));
    }
  };
 

  console.log(tableState, "moiz");

  return (
    <>
      {helpingState.isSwitchDosView === true &&
      (chargeCheck || RejectionCheck || AuthCheck) ? (
        <Dateoofservice
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={
            chargeCheck
              ? helpingStatem.dosArrayRecievedCharge
              : RejectionCheck
              ? helpingStatem.dosArrayRecievedRejection
              : AuthCheck
              ? helpingStatem.dosArrayRecievedAuth
              : vob
              ? helpingStatem.dosArrayRecievedVob
              : null
          }
          handleClose={handleClose}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          onlyShowTable={true}
          SecondTableName={"charge"}
        />
      ) : helpingState.isSwitchDosView === true && vob ? (
        <Dateoofservice
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={
            chargeCheck
              ? helpingStatem.dosArrayRecievedCharge
              : RejectionCheck
              ? helpingStatem.dosArrayRecievedRejection
              : AuthCheck
              ? helpingStatem.dosArrayRecievedAuth
              : vob
              ? helpingStatem.dosArrayRecievedVob
              : null
          }
          handleClose={handleClose}
          claimHeading={"Claims Received"}
          claimAmount={"Portal"}
          columnHeader1={"Calls"}
          columnHeader2={"Worked"}
          columnHeader3={"Pending "}
          //setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"vobb"}
          flag={"vobb"}
        />
      ) : (
        <>
          {helpingStatem.isSwitchTable ? (
            <>
              {chargeCheck ? (
                <AuditTableTl
               
                  openDosView={switchDosHelpignFunc}
                  setHelpingState={setHelpingState}
                  tableState={tableState}
                  helpingStatem={helpingStatem}
                  practiceId={practiceId}
                  date={date}
                  flagIndi={"charge"}
                  verifyFlag={"charge"}
                />
              ) : RejectionCheck ? (
                <AuditTableTl
                
                  openDosView={switchDosHelpignFunc}
                  flagIndi={"charge"}
                  verifyFlag={"Reject"}
                  setHelpingState={setHelpingState}
                  tableState={helpingStatem.RejectionTableState}
                  helpingStatem={helpingStatem}
                  practiceId={practiceId}
                  date={date}
                />
              ) : AuthCheck ? (
                <AuditTableTl
               
                  openDosView={switchDosHelpignFunc}
                  verifyFlag={"Auth"}
                  flagIndi={"charge"}
                  setHelpingState={setHelpingState}
                  tableState={helpingStatem.AuthorizationTableState}
                  helpingStatem={helpingStatem}
                  practiceId={practiceId}
                  date={date}
                />
              ) : vob ? (
                <AuditTableTl
               
                  openDosView={switchDosHelpignFunc}
                  verifyFlag={"vob"}
                  flagIndi={"vob"}
                  setHelpingState={setHelpingState}
                  tableState={helpingStatem.VobsTableState}
                  helpingStatem={helpingStatem}
                  practiceId={practiceId}
                  date={date}
                />
              ) : null}

              <div class="form-group mb-2 ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    onClick={backToMainScreen}
                    className="btnClear mr-1 "
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="col-lg-12 mb-2 text-center">
                <h4
                  class="inputLabel "
                  style={{
                    color: "black",
                    borderBottom: "2px solid rgb(14, 116, 144)",
                    paddingBottom: "10px",
                  }}
                >
                  Audit
                </h4>
              </div> */}

              <div class="row">
                {
                  <Stack justifyContent="space-between">
                    <span></span>
                    <ButtonGroup>
                      <Button
                        name={"chargeEntry"}
                        onClick={(e) => handleToggle(e)}
                        style={
                          helpingState.toggleSubTabName == "chargeEntry"
                            ? { backgroundColor: "#0e7490", color: "white" }
                            : { color: "#0e7490" }
                        }
                        startIcon={<DetailIcon />}
                      >
                        Charge Entry
                      </Button>
                      <Button
                        name={"Rejection"}
                        onClick={(e) => handleToggle(e)}
                        style={
                          helpingState.toggleSubTabName == "Rejection"
                            ? { backgroundColor: "#0e7490", color: "white" }
                            : { color: "#0e7490" }
                        }
                        startIcon={<ChangeListIcon />}
                      >
                        Rejection
                      </Button>
                      <Button
                        onClick={(e) => handleToggle(e)}
                        style={
                          helpingState.toggleSubTabName == "Eligibility/vobs"
                            ? { backgroundColor: "#0e7490", color: "white" }
                            : { color: "#0e7490" }
                        }
                        name={"Eligibility/vobs"}
                        startIcon={<DetailIcon />}
                      >
                        Eligibility/vobs
                      </Button>

                      <Button
                        onClick={(e) => handleToggle(e)}
                        style={
                          helpingState.toggleSubTabName == "Authorization"
                            ? { backgroundColor: "#0e7490", color: "white" }
                            : { color: "#0e7490" }
                        }
                        startIcon={<CharacterAuthorizeIcon />}
                        name={"Authorization"}
                      >
                        Authorization
                      </Button>
                    </ButtonGroup>
                  </Stack>
                }
                <div className="mt-4"></div>

                <Panel
                  defaultExpanded={true}
                  header="Commulative Fields"
                  collapsible={true}
                >
                  <div className="row mt-4">
                    {helpingState.toggleSubTabName == "chargeEntry" ? (
                      <>
                        {chargeEntryHtml}{" "}
                        {helpingState?.pendingClaimToggleMode == "charge"
                          ? pendingSectionhtmlCharge
                          : null}
                      </>
                    ) : helpingState.toggleSubTabName == "Rejection" ? (
                      <>
                        {RejectionEntryHtml}
                        {helpingState?.pendingClaimToggleMode == "Rejection"
                          ? pendingSectionhtmlRejection
                          : null}
                      </>
                    ) : helpingState.toggleSubTabName == "Authorization" ? (
                      <>
                        {AuthorizationHtml}
                        {helpingState?.pendingClaimToggleMode == "Auth"
                          ? pendingSectionhtmlAuth
                          : null}
                      </>
                    ) : helpingState.toggleSubTabName == "Eligibility/vobs" ? (
                      <>
                        {Vobs}
                        {helpingState?.pendingClaimToggleMode == "vob"
                          ? pendingSectionvob
                          : null}
                      </>
                    ) : null}
                  </div>
                </Panel>

                {/* //***********Rec Claims */}

                {/* ****************WorkedClaims******** */}

                <div class="col-sm-6 col-lg-3 mt-2"></div>
                <div class="col-sm-6 col-lg-3 mt-2"></div>
                <div class="col-sm-6 col-lg-3 mt-2"></div>

                <div class="form-group mb-2 ">
                  <div className="d-flex justify-content-end ">
                    <span className="mr-3 mt-1">
                      {" "}
                      <Toggle
                        checked={
                          helpingState?.pendingClaimToggleMode == null
                            ? false
                            : true
                        }
                        size="lg"
                        value={
                          chargeCheck
                            ? "charge"
                            : RejectionCheck
                            ? "Rejection"
                            : AuthCheck
                            ? "Auth"
                            : vob
                            ? "vob"
                            : null
                        }
                        checkedChildren="Hide PC Breakup"
                        unCheckedChildren="Show PC Breakup"
                        onClick={toggleBreakup}
                      />
                    </span>

                    <button
                      type="button"
                      onClick={onSearch}
                      className="btnClear mr-1 "
                    >
                      Generate Audit
                    </button>
                  </div>
                </div>

                {/* <div className="col-lg-12 mb-2 text-center">
                  <h4
                    class="inputLabel "
                    style={{
                      color: "black",
                      borderBottom: "2px solid rgb(14, 116, 144)",
                      paddingBottom: "10px",
                    }}
                  >
                    {name}
                  </h4>
                </div> */}
                {chargeCheck ? (
                  <AuditTableTl
                    openDosView={switchDosHelpignFunc}
                    verifyFlag={"charge"}
                    setHelpingState={setHelpingState}
                    tableState={tableState}
                    helpingStatem={helpingStatem}
                    practiceId={practiceId}
                    date={date}
                    flagIndi={"charge"}
                  />
                ) : RejectionCheck ? (
                  <AuditTableTl
                    openDosView={switchDosHelpignFunc}
                    flagIndi={"charge"}
                    verifyFlag={"Reject"}
                    setHelpingState={setHelpingState}
                    tableState={helpingStatem.RejectionTableState}
                    helpingStatem={helpingStatem}
                    practiceId={practiceId}
                    date={date}
                  />
                ) : AuthCheck ? (
                  <AuditTableTl
                    openDosView={switchDosHelpignFunc}
                    flagIndi={"charge"}
                    verifyFlag={"Auth"}
                    setHelpingState={setHelpingState}
                    tableState={helpingStatem.AuthorizationTableState}
                    helpingStatem={helpingStatem}
                    practiceId={practiceId}
                    date={date}
                  />
                ) : vob ? (
                  <AuditTableTl
                    openDosView={switchDosHelpignFunc}
                    flagIndi={"vob"}
                    verifyFlag={"vob"}
                    setHelpingState={setHelpingState}
                    tableState={helpingStatem.VobsTableState}
                    helpingStatem={helpingStatem}
                    practiceId={practiceId}
                    date={date}
                  />
                ) : null}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AmountVerification;
