import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "rsuite";
import { Drawer, RadioGroup, Radio, IconButton,Toggle,Divider } from "rsuite";
import {Steps} from "rsuite"


import ListComp from "./ListReportNotify";
import { Timeline } from "rsuite";
import { Panel, Placeholder } from "rsuite";
import { useSelector } from "react-redux";
import {
  fetchProduct,
  insertTlAudit,
  overAllLog,
  overAllVerifyWithRating,
  verifyAuditOverAllDOS,
} from "../../Utils/ApiManager";
import { toast } from "react-toastify";
import { Input } from "rsuite";
import { Checkbox } from "rsuite";
import { socket } from "../../context/socket";
import { Rate } from "rsuite";
import UserAuditTable from "../Audit/userTaskViewTable";
import AuditTabMenus from "../TeamLeadInterface/AllTlForms/ChargeEntry/AuditTabs";
import userRolesObject from "../../Common/AllRolesInfo"

export default function DrawerNotification({
  isDrwaerOnNotification,
  searchOverAllbyonlyvictimid,

  stateHelping,
}) {

  console.log('stateHelping',stateHelping)
  let objTab={
    tabName:''
  }
  const [helpingObj,sethelpingObj]=useState(objTab)
  const { onLoginSignOff } = useSelector((state) => state);
  const [practiceName, setPracticName] = useState();
  const [loadingVerify, setloadingverify] = useState(false);
  let obj = {
    isShowRemarks: false,
    victimremakrs: null,
    rating: null,
    auditLogArray: [],
    parRecurringComment: [],
    handleTaskCompletion:false
  };
  const [stateNotify, setNotifyState] = useState(obj);



  useEffect(() => {
    if(stateHelping.selectedArray?.acknowledged=='No'){

      let obj = {
        notifyName: "insertAudit",
        formName: stateHelping.selectedArray?.formName,
        sdate: stateHelping.selectedArray?.sdate,
        Dos: stateHelping.selectedArray?.Dos,
        isNotifyAudit: "Yes",
        id: stateHelping.selectedArray?.id,
        pid: stateHelping.selectedArray.pid,
        victimid: stateHelping.selectedArray.userid,
        userid: stateHelping.selectedArray.victimid,
        victimName: stateHelping.selectedArray.userName,
        userName: stateHelping.selectedArray?.victimName,
        recurringReamkrs: "",
        acknowledged: "Yes",
        statusStage: 1,
        auditlogArray: stateHelping?.selectedArray?.auditLogArray,
        taskStatus:false
        // userRejectedCheck:true,
        // userRejectedRemarks:stateNotify.victimremakrs
      };
    
    insertTlAudit(obj)
      .then((res) => {
       // toast.info("Notification Sent");
      //  setloadingverify(false);
   //     socket.emit("AuditClaimNotification", obj);
      })
      .catch((err) => {
       // toast.info("Some Thing Went Wrong");
      //  setloadingverify(false);
      });

    }
  }, [stateHelping.selectedArray])
  

  useEffect(() => {
    fetchProductsFun();
  }, []);
  let filteredItem;

  const fetchProductsFun = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));

      let pid = stateHelping.selectedArray.pid;
      filteredItem = optionList.find((item) => item.value === String(pid));
      console.log("optionList", optionList);
      setPracticName(filteredItem);
      // setProducts(optionList);
    }
  };
  useEffect(() => {
    if (stateHelping.selectedArray) {
      let parRecurringComment = [];
      let parsedArra = [];
      try {
        parsedArra = JSON.parse(stateHelping.selectedArray.auditlogArray);
        parRecurringComment = JSON.parse(
          stateHelping.selectedArray.recurringReamkrs
        );
      } catch {
        parsedArra = stateHelping.selectedArray.auditlogArray;
        parRecurringComment = stateHelping.selectedArray.recurringReamkrs;
      }
      let newParrobject = [];
      try {
        newParrobject = JSON.parse(parsedArra);
      } catch {
        newParrobject = parsedArra;
      }
      setNotifyState((prev) => ({ ...prev, isShowRemarks: true }));
      setNotifyState((prev) => ({ ...prev, auditLogArray: newParrobject }));
      setNotifyState((prev) => ({
        ...prev,
        parRecurringComment: parRecurringComment,
      }));
    }
  }, [stateHelping.selectedArray]);


  let parsedArray = [];
  try {
    parsedArray = JSON.parse(stateHelping.selectedArray.auditlogArray);
    console.log("The string contains a valid JSON object.");
  } catch (error) {
    parsedArray = stateHelping.selectedArray.auditlogArray;
  }
  const DosWiseTable = [
    {
      colName: `Claim #`,
      flexGrow: 1.9,
      dataKey: "claim",
      rowSpan: 1,
    },
    {
      colName: `Error Found`,
      flexGrow: 1.9,
      dataKey: "ErrorFound",
      rowSpan: 1,
    },
    {
      colName: `Error Type`,
      flexGrow: 1.9,
      dataKey: "ErrorType",
      rowSpan: 1,
    },
    {
      colName: "Remarks",
      flexGrow: 1.6,
      dataKey: "Remarks",
      rowSpan: 1,
    },
  ];

  

  console.log("stateNotify", stateNotify);


  console.log(stateHelping.selectedArray?.formName,'hrello parsed')



  const handleTaskCompletion=(checked)=>{
   
    setNotifyState((prev)=>({...prev,handleTaskCompletion:checked}))

  }
  const getRequriedPara=()=>{

  }
  console.log(parsedArray, "soneeeee");

  let notifyBodyCharge = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Practice Name: <b>{practiceName?.label}</b>
          </span>
        </div>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Date of Entry: <b>{stateHelping.selectedArray?.sdate}</b>
          </span>
        </div>
        <br></br>
        <br></br>

        {""}
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Team Lead: <b>{stateHelping.selectedArray?.userName}</b>
          </span>
        </div>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Form Name: <b>{stateHelping.selectedArray?.formName}</b>
          </span>
        </div>
        <br></br>
        <br></br>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Date of Service (Dos): <b>{stateHelping.selectedArray?.Dos}</b>
          </span>
        </div>
        <UserAuditTable
        getRequriedPara={getRequriedPara}
          rowHeight={48}
          dateForgrid={
            stateNotify?.auditLogArray ? stateNotify.auditLogArray : []
          }
          Array={DosWiseTable}
          rowShown={5}
          loader={false}
        />
      </div>
    </>
  );


  console.log(stateHelping.selectedArray?.formName,'parsedArray')


  let notifyBodyChargeoverAll = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Practice Name: <b>{practiceName?.label}</b>
          </span>
        </div>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Date of Entry: <b>{stateHelping.selectedArray?.sdate}</b>
          </span>
        </div>
        <br></br>
        <br></br>

        {""}
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Team Lead: <b>{stateHelping.selectedArray?.userName}</b>
          </span>
        </div>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Form Name: <b>{stateHelping.selectedArray?.formName}</b>
          </span>
        </div>
        <br></br>
        <br></br>
        <div className="col-lg-6">
          <span className="mb-0 font-weight-normal float-left dropdown-header">
            Date of Service (Dos): <b>{stateHelping.selectedArray?.Dos}</b>
          </span>
        </div>

        {stateHelping.selectedArray?.formName=="Account Receivable"?(<>
          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
          <Timeline endless>

            <Timeline.Item>{parsedArray.recievedAmountCC}</Timeline.Item>
            <Timeline.Item>{parsedArray.recievedAmount}</Timeline.Item>
            <Timeline.Item>{parsedArray.pending}</Timeline.Item>
           
          </Timeline>
          </div>
          <div class="col-sm-6 col-lg-12">
          <br></br>
          <br></br>

          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
            <span className="">Remarks</span>
            <br></br>
            <br></br>

            <Input
              class="form-control inputField"
              as="textarea"
              name="Remarks"
              placeholder=""
              value={parsedArray.Remarks}
              disabled
            />
          </div>
        </div>
        </>):stateHelping.selectedArray?.formName == "vobs" ? (
          <>
          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
        <Timeline endless>
          <Timeline.Item>{parsedArray.ReceivedVobClaim}</Timeline.Item>
          <Timeline.Item>{parsedArray.callsvob}</Timeline.Item>
          <Timeline.Item>{parsedArray.portals}</Timeline.Item>
        </Timeline>
        </div>
        <div class="col-sm-6 col-lg-12">
        <br></br>
        <br></br>

        <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
          <span className="">Remarks</span>
          <br></br>
          <br></br>

          <Input
            class="form-control inputField"
            as="textarea"
            name="Remarks"
            placeholder=""
            value={parsedArray.Remarks}
            disabled
          />
        </div>
      </div>
</>

       
      ):stateHelping.selectedArray?.formName=='Payments'?( <>
        <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
          <Timeline endless>
            <Timeline.Item>{parsedArray.Adjustments}</Timeline.Item>
            <Timeline.Item>{parsedArray.AdjustmentsPar}</Timeline.Item>
            <Timeline.Item>{parsedArray.DenialAmount}</Timeline.Item>
            <Timeline.Item>{parsedArray.DenialAmountPar}</Timeline.Item>
            <Timeline.Item>{parsedArray.Patients}</Timeline.Item>
            <Timeline.Item>{parsedArray.PatientsPar}</Timeline.Item>
            <Timeline.Item>{parsedArray.DenialAmount}</Timeline.Item>
            <Timeline.Item>{parsedArray.DenialAmountPar}</Timeline.Item>
          </Timeline>
        </div>
        <div class="col-sm-6 col-lg-12">
          <br></br>
          <br></br>

          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
            <span className="">Remarks</span>
            <br></br>
            <br></br>

            <Input
              class="form-control inputField"
              as="textarea"
              name="Remarks"
              placeholder=""
              value={parsedArray.Remarks}
              disabled
            />
          </div>
        </div>
      </>):stateHelping.selectedArray?.formName=='Patient Statement'?(<>
        <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
        <Timeline endless>
            <Timeline.Item>{parsedArray.AmountDosValCC}</Timeline.Item>
            <Timeline.Item>{parsedArray.AmountDosVal}</Timeline.Item>
            <Timeline.Item>{parsedArray.pendingAmount}</Timeline.Item>
            <Timeline.Item>{parsedArray.DenialAmountPar}</Timeline.Item>
            <Timeline.Item>{parsedArray.recievedAmountCC}</Timeline.Item>
            <Timeline.Item>{parsedArray.recievedAmount}</Timeline.Item>
            <Timeline.Item>{parsedArray.internalNonVerify}</Timeline.Item>

          
          </Timeline>
          <div class="col-sm-6 col-lg-12">
          <br></br>
          <br></br>

          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
            <span className="">Remarks</span>
            <br></br>
            <br></br>

            <Input
              class="form-control inputField"
              as="textarea"
              name="Remarks"
              placeholder=""
              value={parsedArray.Remarks}
              disabled
            />
          </div>
        </div>
          </div>
      
      </>) : (
        <>
          <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
            <Timeline endless>
              <Timeline.Item>{parsedArray.ReceivedClaims}</Timeline.Item>
              <Timeline.Item>{parsedArray.WorkedClaims}</Timeline.Item>
              <Timeline.Item>{parsedArray.PendingClaims}</Timeline.Item>
              <Timeline.Item>{parsedArray.ReceivedAmount}</Timeline.Item>
              <Timeline.Item>{parsedArray.WorkedAmount}</Timeline.Item>
            </Timeline>
          </div>
          <div class="col-sm-6 col-lg-12">
            <br></br>
            <br></br>

            <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
              <span className="">Remarks</span>
              <br></br>
              <br></br>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={parsedArray.Remarks}
                disabled
              />
            </div>
          </div>
        </>
      
        
       
      )}
        
      </div>
    </>
  );


  const onVerifyOverAll = () => {
    
    setloadingverify(true);

    let arr = [];
    if (
      stateNotify?.parRecurringComment &&
      stateNotify?.parRecurringComment.length == 0
    ) {
      arr.push({ Remarks: stateNotify.parRecurringComment, name:onLoginSignOff.name });
    }

    if (
      stateNotify?.parRecurringComment &&
      stateNotify?.parRecurringComment.length > 0
    ) {
      arr = stateNotify?.parRecurringComment;
    }

    if (stateNotify?.victimremakrs) {
      arr.push({ Remarks: stateNotify.victimremakrs,name:onLoginSignOff.name });
    }

    //when  user Rejected check
    let obj;
    if (stateNotify.isShowRemarks == true) {
      obj = {
        notifyName: "insertAuditOverAll",
        formName: stateHelping.selectedArray?.formName,
        sdate: stateHelping.selectedArray?.sdate,
        Dos: stateHelping.selectedArray?.Dos,
        isNotifyAudit: "Yes",
        id: stateHelping.selectedArray?.id,
        pid: stateHelping.selectedArray.pid,
        victimid: stateHelping.selectedArray.userid,
        userid: stateHelping.selectedArray.victimid,
        victimName: stateHelping.selectedArray.userName,
        userName: stateHelping.selectedArray?.victimName,
        recurringReamkrs: JSON.stringify(arr),
        acknowledged: "Yes",
        statusStage: 1,
        auditlogArray: stateHelping?.selectedArray?.auditLogArray,
        taskStatus:stateNotify?.handleTaskCompletion
        // userRejectedCheck:true,
        // userRejectedRemarks:stateNotify.victimremakrs
      };
    } else {
      //when user go without rejected check
      obj = {
        notifyName: "insertAuditOverAll",
        formName: stateHelping.selectedArray?.formName,
        sdate: stateHelping.selectedArray?.sdate,
        Dos: stateHelping.selectedArray?.Dos,
        isNotifyAudit: "Yes",
        id: stateHelping.selectedArray?.id,
        pid: stateHelping.selectedArray.pid,
        victimid: stateHelping.selectedArray.userid,
        userid: stateHelping.selectedArray.victimid,
        victimName: stateHelping.selectedArray.userName,
        userName: stateHelping.selectedArray?.victimName,
        recurringReamkrs: "",
        acknowledged: "Yes",
        statusStage: 1,
        auditlogArray: stateHelping?.selectedArray?.auditLogArray,
        taskStatus:stateNotify?.handleTaskCompletion
        // userRejectedCheck:true,
        // userRejectedRemarks:stateNotify.victimremakrs
      };
    }
    overAllLog(obj)
      .then((res) => {
        toast.info("Notification Sent");
        setloadingverify(false);
        isDrwaerOnNotification(false);
        // socket.emit("AuditClaimNotification", obj);
      })
      .catch((err) => {
        toast.info("Some Thing Went Wrong");
        setloadingverify(false);
      });

    // verifyAuditOverAllDOS(obj)
    //   .then((res) => {
    //     if (res) {
    //       setloadingverify(false);
    //       toast.info("OverAll Claim Noitfy Verified");
    //       socket.emit('AuditClaimNotification',obj)
    //       searchOverAllbyonlyvictimid(null,false);
    //       isDrwaerOnNotification(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setloadingverify(false);
    //   });
  };
  

  const onVerify = () => {
    setloadingverify(true);

    let arr = [];
    if (
      stateNotify?.parRecurringComment &&
      stateNotify?.parRecurringComment.length == 0
    ) {
      arr.push({ Remarks: stateNotify.parRecurringComment,name:onLoginSignOff.name });
    }

    if (
      stateNotify?.parRecurringComment &&
      stateNotify?.parRecurringComment.length > 0
    ) {
      arr = stateNotify?.parRecurringComment;
    }

    if (stateNotify?.victimremakrs) {
      arr.push({ Remarks: stateNotify.victimremakrs,name:onLoginSignOff.name });
    }

    //when  user Rejected check
    let obj;
    if (stateNotify.isShowRemarks == true) {
      obj = {
        notifyName: "insertAudit",
        formName: stateHelping.selectedArray?.formName,
        sdate: stateHelping.selectedArray?.sdate,
        Dos: stateHelping.selectedArray?.Dos,
        isNotifyAudit: "Yes",
        id: stateHelping.selectedArray?.id,
        pid: stateHelping.selectedArray.pid,
        victimid: stateHelping.selectedArray.userid,
        userid: stateHelping.selectedArray.victimid,
        victimName: stateHelping.selectedArray.userName,
        userName: stateHelping.selectedArray?.victimName,
        recurringReamkrs: JSON.stringify(arr),
        acknowledged: "Yes",
        statusStage: 1,
        auditlogArray: stateHelping?.selectedArray?.auditLogArray,
        taskStatus:stateNotify?.handleTaskCompletion
        // userRejectedCheck:true,
        // userRejectedRemarks:stateNotify.victimremakrs
      };
    } else {
      //when user go without rejected check
      obj = {
        notifyName: "insertAudit",
        formName: stateHelping.selectedArray?.formName,
        sdate: stateHelping.selectedArray?.sdate,
        Dos: stateHelping.selectedArray?.Dos,
        isNotifyAudit: "Yes",
        id: stateHelping.selectedArray?.id,
        pid: stateHelping.selectedArray.pid,
        victimid: stateHelping.selectedArray.userid,
        userid: stateHelping.selectedArray.victimid,
        victimName: stateHelping.selectedArray.userName,
        userName: stateHelping.selectedArray?.victimName,
        recurringReamkrs: "",
        acknowledged: "Yes",
        statusStage: 1,
        auditlogArray: stateHelping?.selectedArray?.auditLogArray,
        taskStatus:stateNotify?.handleTaskCompletion
        // userRejectedCheck:true,
        // userRejectedRemarks:stateNotify.victimremakrs
      };
    }
    insertTlAudit(obj)
      .then((res) => {
        toast.info("Notification Sent");
        setloadingverify(false);
        isDrwaerOnNotification(false);
       // socket.emit("AuditClaimNotification", obj);
      })
      .catch((err) => {
        toast.info("Some Thing Went Wrong");
        setloadingverify(false);
      });

    // verifyAuditOverAllDOS(obj)
    //   .then((res) => {
    //     if (res) {
    //       setloadingverify(false);
    //       toast.info("OverAll Claim Noitfy Verified");
    //       socket.emit('AuditClaimNotification',obj)
    //       searchOverAllbyonlyvictimid(null,false);
    //       isDrwaerOnNotification(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setloadingverify(false);
    //   });
  };

  console.log("stateNotify", stateNotify);

  const handlerejectRemark = (e, arg) => {
    setNotifyState((prev) => ({ ...prev, isShowRemarks: arg }));
  };

  const handleChangeRemakrs = (e) => {
    setNotifyState((prev) => ({ ...prev, victimremakrs: e }));
  };
  const ratingHandle = (val) => {
    setNotifyState((prev) => ({ ...prev, rating: val }));
  };
  const callApiForRating = () => {
    if (stateNotify.rating == null) {
      toast.info("Please Rate this Audit");
      return;
    }
    obj = {
      formName: stateHelping.selectedArray?.formName,
      sdate: stateHelping.selectedArray?.sdate,
      Dos: stateHelping.selectedArray?.Dos,
      pid: stateHelping.selectedArray.pid,
      victimid: stateHelping.selectedArray.victimid,
      userid: stateHelping.selectedArray.userid,
    };
    overAllVerifyWithRating(obj)
      .then((res) => {
        isDrwaerOnNotification(false);
        toast.info("Rating has been Added");
        searchOverAllbyonlyvictimid(null, null, true);
      })
      .catch((err) => {});
  };

  console.log(
    "stateNotify.parRecurringComment",
    stateNotify.parRecurringComment
  );

  let  htmlForOverAll= (
    <>
      <Panel
        style={{ backgroundColor: "#f0f9ff" }}
        collapsible
        bordered
        header="OverAll Report"
      >
        {notifyBodyChargeoverAll}
      </Panel>
      <Panel
        header="Remarks History"
        style={{ backgroundColor: "#f0f9ff" }}
        collapsible
        bordered
      >
        <div style={{ overflow: 'auto',height: '250px',}}>
        {stateNotify.parRecurringComment &&
        stateNotify.parRecurringComment.length == 0
          ? null
          : stateNotify?.parRecurringComment &&
            stateNotify?.parRecurringComment.length > 0 &&
            stateNotify?.parRecurringComment?.map((el) => {
              return (
                <div > <Divider>{el.name}</Divider>
                  <br></br>
                  <span>{el.Remarks}</span>
                </div>
              );
            })}
        </div>
       
      </Panel>
    </>
  );

  let tableForClaimLevel = (
    <>
      <Panel
      defaultExpanded={true}
        style={{ backgroundColor: "#f0f9ff" }}
        collapsible
        bordered
        header="Claim Level Report"
      >
        {notifyBodyCharge}
      </Panel>
      <Panel
      defaultExpanded={true}
        header="Remarks History"
        style={{ backgroundColor: "#f0f9ff" }}
        collapsible
        bordered
      >
        <div style={{ overflow: 'auto',height: '250px',}}>
        {stateNotify.parRecurringComment &&
        stateNotify.parRecurringComment.length == 0
          ? null
          : stateNotify?.parRecurringComment &&
            stateNotify?.parRecurringComment.length > 0 &&
            stateNotify?.parRecurringComment?.map((el) => {
              return (
                <div >
                 <Divider>{el.name}</Divider>
                  <br></br>
                  
                  <span>{el.Remarks}</span>
                </div>
              );
            })}
            </div>
      </Panel>
    </>
  );


  console.log('helpingObjhelpingObj',stateHelping)
  let check = stateHelping.selectedArray?.statusStage == 2;


  console.log('stateHelping.selectedArray',stateHelping.selectedArray)

  return (
    <>
      {loadingVerify == true ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}

      <Drawer
        size={"full"}
        open={true}
        style={{ zIndex: 9999 }}
        onClose={() => isDrwaerOnNotification(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Audit</Drawer.Title>


          <Drawer.Actions>
            <Button onClick={() => isDrwaerOnNotification(false)}>
              Cancel
            </Button>
            {stateHelping?.selectedArray?.taskStatus=='Incomplete' ||stateHelping?.selectedArray?.taskStatus==false?<Button
              onClick={stateHelping.selectedArray?.notifyName=='insertAudit'?onVerify:onVerifyOverAll}
              style={{
                height: "33px",
                backgroundColor: "#0E7490",
                color: "white",
              }}
            >
              Done
            </Button>:null}
           
           

            {/* <Button
              style={{
                height: "33px",
                backgroundColor: "#0E7490",
                color: "white",
              }}
            >
              Acknowledge
            </Button> */}
          </Drawer.Actions>
          <br></br>
        </Drawer.Header>

        <br></br>

        <Drawer.Body>
        <div className="col-lg-12  ">
        {/* <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
           
          }}
        >
          <AuditTabMenus helpingObject={'General'} sethelpingObj={sethelpingObj} />
        </h4> */}
      </div>
          <div style={{ maxHeight: "800px", overflow: "auto" }}>
            {stateHelping?.selectedArray?.notifyName=="insertAudit"?tableForClaimLevel:htmlForOverAll}

            {stateHelping?.selectedArray?.taskStatus=='Incomplete' || stateHelping?.selectedArray?.taskStatus==false?
            <Panel
            style={{ backgroundColor: "#f0f9ff" }}
            header="Enter Remarks"
            bordered
            collapsible
          >
            {" "}
            <div className="row">
              <div className="">
                {" "}
                <span className="mb-0 font-weight-normal float-left dropdown-header">
                  <Checkbox
                    disabled={
                      stateHelping.selectedArray?.statusStage == 2
                        ? true
                        : false
                    }
                    onChange={handlerejectRemark}
                    checked={
                      stateHelping.selectedArray?.userRejectedCheck == "1"
                        ? true
                        : stateNotify.isShowRemarks == true
                        ? true
                        : false
                    }
                  >
                    <b> Do you want to Enter Remarks</b>
                  </Checkbox>
                </span>
              </div>
              {stateNotify.isShowRemarks == true ? (
                <div class="form-group mb-2 mb-0 font-weight-normal  dropdown-header">
                  <br></br>

                  <Input
                    class="form-control inputField"
                    as="textarea"
                    name="Remarks"
                    placeholder=""
                    value={stateNotify?.victimremakrs}
                    onChange={handleChangeRemakrs}
                    disabled={check ? true : false}
                  />
                </div>
              ) : null}
            </div>
          </Panel>
            :null
            }
            

           
            <br></br>
            <br></br>
            <br></br>

            {/* <Steps current={2}>
            <Steps.Item title="Intiated" description="" />
            <Steps.Item title="Acknowledged" description="" />
            <Steps.Item title="In Progress" description="" />
    <Steps.Item title="Finished" description="" /> */}
   
   
   
  {/* </Steps> */}

            <div class="col-sm-6 col-lg-12 mt-2">
              <div class="form-group mb-2 ">
                <div class="d-flex justify-content-end mt-4">
                  {onLoginSignOff.type==userRolesObject.biller?null:<Toggle onChange={handleTaskCompletion} size="lg" checkedChildren="Not Close Task" unCheckedChildren="Close Task" />}
                  
                
                </div>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}
