import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import { SocketContext } from "../../context/socket";
import { Badge } from "rsuite";

import Layout from "./../../Components/layout/Layout";

import Dashboard from "../Dashboard/Dashboard";

import PageNotFound from "../PageNotFound/PageNotFound";

import Login from "../Login/Login";

import PublicLayout from "../Publiclayout";

import { useSelector, useDispatch } from "react-redux";
import userRolesObject from "../../Common/AllRolesInfo"

import store from "../../Store/Store";

import Coding from "../coding/coding";
import Operation from "../Operation/Operation";
import TLInterface from "../TeamLeadInterface/TlInterface";
import Audit from "../Audit/Audit";
import TLInterfaceReports from "../TeamLeadInterfaceReports/TlInterface";
import {
  LoginSignOFF,
  SearchIfAuditTriggerFire,
  getTeamsInfo,
  searchbyVictimeid,
  searchbyVictimeidClaimLevel,
  searchbyVictimeidOverAll,
} from "../../Utils/ApiManager";
import TaskListComp from "../TaskLists/TaskList";
import TLInterfaceOfCoding from "../coding/TLInterface/TLInterfaceOfCoding";
import CodingofTlInterFace from "../coding/TLInterface/MainTlInterface";
import { getPracticeAgainstTeam } from "../../Actions/Actions";
import CodingAudit from "../coding/Report/CodingAudit";

const MainRoutes = () => {
  // const socket = useContext(SocketContext);
  const location = useLocation();
  let dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    LoginObject,
    RoomInfo,
    AllPatientsAccepted,
    globalRoomEnds,
    PatientDataDetails,
    onLoginSignOff,
  } = useSelector((state) => state);

  const [patientList, setPatientList] = useState(false);
  const [acceptedPatient, setAcceptedPatientlist] = useState(false);
  const [stripeLatestObject, setStripeLatestObject] = useState();
  const [SuccessUrlState, setSuccessUrlState] = useState(null);
  const [openDirectPatientJoin, setOpenDirectPatientJoin] = useState(null);
  const [callEndWhenProviderSwitchesTab, setCallEndWhenProviderSwitchesTab] =
    useState(false);
  const [isGlobalRoomCall, setIsGloablaRoomCall] = useState(false);
  const [isShowCallActionsButton, setIsShowCallActionsButton] = useState(false);
  const [instantEffectOnRoom, setInstantEffectOnRoom] = useState(false);

  let obj = {
    isShowNotification: false,
    notifcationArray: [],
    isShowNotificationDrawer: false,
    seleteedArray: {},
    // holdAllOverlogs:[]
  };

  const [stateHelping, setStateHelping] = useState(obj);
  const showNotification = (row) => {
    setStateHelping((prev) => ({ ...prev, isShowNotification: true }));
  };
  const callTeams=()=>{

    // call Team and differentiate 
    let formData = new FormData();
    formData.append("get_user", onLoginSignOff.userid);
    getTeamsInfo(formData).then((res)=>{
      console.log(res.data)
     
      if(res.data){
        let output = res?.data.map(team => team.id.replace(/\s/g, '')).join(',');
        let formData = new FormData();
    formData.append("team_practices", output);

        LoginSignOFF(formData).then((res)=>{
         
          dispatch(getPracticeAgainstTeam(res.data))

        })
      }
      


    })

  }

  useEffect(() => {
   callTeams()
  }, [])
  

  const searchOverAllbyonlyvictimid = (objNotify, arg) => {

    
    // fOR SOCKET REAL TIME  For user
    if (arg) {
      let obj = {
        victimid: objNotify.victimid,
        isNotifyAudit:'Yes',
        type:'user'   
       
      };
      searchbyVictimeidClaimLevel(obj)
        .then((res) => {
          if (res && res.data) {
            res.data.map((el) => {
              
                setStateHelping((prevState) => ({
                  ...prevState,
                  notifcationArray: res.data,
                }));
              
            });
          }
        })
        .catch((err) => {});
    } else {
      //fOR lEAD 
      if(onLoginSignOff.type==19|| onLoginSignOff.type==userRolesObject.tl|| onLoginSignOff.type==userRolesObject.backupTeam ){
        let obj = {
          userid: onLoginSignOff.userid,
          isNotifyAudit:'Yes',
          type:'Tl'
         
        };
        searchbyVictimeidClaimLevel(obj)
          .then((res) => {
            if (res && res.data ) {
              res.data.map((el) => {
                if(el.userid!=onLoginSignOff.userid){
                  setStateHelping((prevState) => ({
                    ...prevState,
                    notifcationArray: res.data,
                  }));

                }
                 
                 
                
              });
            }
          })
          .catch((err) => {});

      }else{
        let obj = {
          victimid: onLoginSignOff.userid,
          isNotifyAudit:'Yes',
          type:'user'
        };
        searchbyVictimeidClaimLevel(obj)
          .then((res) => {
            if (res && res.data) {
              res.data.map((el) => {
                if (el.victimid==onLoginSignOff.userid) {
                  setStateHelping((prevState) => ({
                    ...prevState,
                    notifcationArray: res.data,
                  }));
                }
              });
            }
          })
          .catch((err) => {});

      }
     
    }
  };


  const searchOverAllbyonlyvictimidOverAll = (objNotify, arg) => {

    
    // fOR SOCKET REAL TIME  For user
    if (arg) {
      let obj = {
        victimid: objNotify.victimid,
        isNotifyAudit:'Yes',
        type:'user'   
       
      };
      searchbyVictimeidOverAll(obj)
        .then((res) => {
          if (res && res.data) {
            res.data.map((el) => {
              
                setStateHelping((prevState) => ({
                  ...prevState,
                  notifcationArray: res.data,
                }));
              
            });
          }
        })
        .catch((err) => {});
    } else {
      //fOR lEAD 
      if(onLoginSignOff.type==19 ){
        let obj = {
          userid: onLoginSignOff.userid,
          isNotifyAudit:'Yes',
          type:'Tl'
         
        };
        searchbyVictimeidOverAll(obj)
          .then((res) => {
            if (res && res.data ) {
              res.data.map((el) => {
                if(el.userid!=onLoginSignOff.userid){
                  setStateHelping((prevState) => ({
                    ...prevState,
                    notifcationArray: res.data,
                  }));

                }
                 
                 
                
              });
            }
          })
          .catch((err) => {});

      }else{
        let obj = {
          victimid: onLoginSignOff.userid,
          isNotifyAudit:'Yes',
          type:'user'
        };
        searchbyVictimeidOverAll(obj)
          .then((res) => {
            if (res && res.data) {
              res.data.map((el) => {
                if (el.victimid==onLoginSignOff.userid) {
                  setStateHelping((prevState) => ({
                    ...prevState,
                    notifcationArray: res.data,
                  }));
                }
              });
            }
          })
          .catch((err) => {});

      }
     
    }
  };


  // For Sleected Arrat and and open drawer
  const isDrwaerOnNotification = (val, arg) => {
    if (arg) {
      setStateHelping((prev) => ({
        ...prev,

        selectedArray: arg,
      }));
    }
    setStateHelping((prev) => ({
      ...prev,
      isShowNotificationDrawer: val,
    }));
  };

  // const isDrwaerOnNotification = (val, arg) => {

  //   setStateHelping((prev) => ({
  //     ...prev,
  //     isShowNotificationDrawer: val,
  //     selectedArray: [...(prev.selectedArray || []), arg],
  //   }));
  // };

  const searchifoverAllAuditTriggerFunc = (objNotify) => {
    let obj = {
      Dos: objNotify.Dos,

      userid: objNotify.userid,
      victimid: objNotify.victimid,
      pid: objNotify.pid,

      sdate: objNotify.sdate,
      formName: objNotify.formName,
    };
    SearchIfAuditTriggerFire(obj)
      .then((res) => {
        
        if (res && res.data) {
          setStateHelping((prevState) => ({
            ...prevState,
            notifcationArray: res.data,
          }));
        }
      })
      .catch((err) => {});
  };

  const notifyFromUser = (value) => {
 
    let objNotify = value.notifyobj;
    if (onLoginSignOff.userid == objNotify.victimid) {
     
      searchOverAllbyonlyvictimidOverAll(objNotify, true,false);

      
      //call APi
     
      // searchifoverAllAuditTriggerFunc(objNotify);
    }

    // let isObjectPresent =
    //   stateHelping.notifcationArray.length > 0 &&
    //   stateHelping.notifcationArray.some(
    //     (item) =>
    //       item?.sdate === obj.sdate &&
    //       item?.pid === obj.pid &&
    //       item?.Dos === obj.Dos
    //   );

    // if (!isObjectPresent) {
    //   setStateHelping((prevState) => ({
    //     ...prevState,
    //     notifcationArray: [...prevState.notifcationArray, objNotify],
    //   }));
    // }
    // console.log(value, "valueSocket");
  };


  const notifyFromUserAuditLevel=(obj)=>{


    let objNotify = obj.notifyobj;
    if (onLoginSignOff.userid == objNotify.victimid) {
      
      searchOverAllbyonlyvictimid(objNotify, true,false);
      
    }


  }

  // useEffect(() => {
  //   console.log("This will on  Socket pool");
  //   socket.on("overAllnotificationFromTl", notifyFromUser);
  //   socket.on("AuditClaimNotification", notifyFromUserAuditLevel);

  //   return () => {
  //     // before the component is destroyed
  //     // unbind all event handlers used in this component
  //     socket.off("overAllnotificationFromTl", notifyFromUser);
  //     socket.off("AuditClaimNotification", notifyFromUserAuditLevel);
  //   };
  // });

  const notifyFromVictim=(obj)=>{
   
    if (onLoginSignOff.userid == obj.notifyobj.userid) {
     
      searchOverAllbyonlyvictimid(obj, true,true);
     
    }


  }
 


  // useEffect(() => {
  //   console.log("This will on  Socket pool");
  //   socket.on("overAllnotificationFromVictim", notifyFromVictim);
  //   return () => {
  //     // before the component is destroyed
  //     // unbind all event handlers used in this component
  //     socket.off("overAllnotificationFromVictim", notifyFromVictim);
  //   };
  // });

  const [ehrWidth, setEhrWidth] = useState(0);
  const [ehrHeight, setHeight] = useState(0);

  // useEffect(() => {
  //   if (LoginObject.username && ehrWidth === 500 && ehrHeight === 500) {
  //     let obj = {};
  //     obj.ehrWidth = ehrWidth;
  //     obj.ehrHeight = ehrHeight;
  //     navigate("/room");
  //     dispatch(getEhrDimensions(obj));
  //   }
  // }, [ehrWidth, ehrHeight]);
  useEffect(() => {
    const updateDimensions = () => {
      setEhrWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateDimensions);
    updateDimensions();
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let visitingPath = window.location.href;
  let lastPart = null;

  useEffect(() => {
    let url = window.location.href;
    setSuccessUrlState(url);
    if (LoginObject && LoginObject.jwt) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  // useEffect(() => {
  //   if () {
  //     navigate("/join-doctor");
  //   }
  // }, [RoomInfo]);

  const getClickOnTab = (tabValue) => {
    if (tabValue == "Room") {
      setIsGloablaRoomCall(true);
    }
  };

  const detectSideNavChange = (val) => {
    setCallEndWhenProviderSwitchesTab(true);
  };

  if (
    onLoginSignOff.name &&
    onLoginSignOff.message == "User Logged In Successfully"
  ) {
    return (
      <>
        {
          <Routes>
            <Route
              exact="true"
              path="/"
              element={
                <Layout
                setStateHelping={setStateHelping}
                
                  searchOverAllbyonlyvictimid={searchOverAllbyonlyvictimid}
                  searchOverAllbyonlyvictimidOverAll={searchOverAllbyonlyvictimidOverAll}
                  isDrwaerOnNotification={isDrwaerOnNotification}
                  stateHelping={stateHelping}
                  showNotification={showNotification}
                  instantEffectOnRoom={instantEffectOnRoom}
                  isShowCallActionsButton={isShowCallActionsButton}
                  setCallEndWhenProviderSwitchesTab={
                    setCallEndWhenProviderSwitchesTab
                  }
                  isGlobalRoomCall={isGlobalRoomCall}
                  getClickOnTab={getClickOnTab}
                  detectSideNavChange={detectSideNavChange}
                  callEndWhenProviderSwitchesTab={
                    callEndWhenProviderSwitchesTab
                  }
                />
              }
            >
              <Route
                path="/"
                exact="true"
                element={
                  <Dashboard
                    searchOverAllbyonlyvictimid={searchOverAllbyonlyvictimid}
                    searchOverAllbyonlyvictimidOverAll={searchOverAllbyonlyvictimidOverAll}
                  />
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    searchOverAllbyonlyvictimid={searchOverAllbyonlyvictimid}
                    searchOverAllbyonlyvictimidOverAll={searchOverAllbyonlyvictimidOverAll}
                  />
                }
              />
              {/* <Route
                path="/userInterFace"
                element={
                 <UserInterFace />
                }
              /> */}
              {/* <Route
                path="/Audit"
                element={
                 <Audit />
                }
              /> */}
              {/* <Route
                path="/Reports"
                element={
                 <Reports />
                }
              /> */}
              <Route path="/Coding/userInterface" element={<Coding />} />
              <Route path="/Coding/TLInterface" element={<CodingofTlInterFace/>}/>
              <Route path="/Coding/Report" element={<CodingAudit/>}/>
              <Route path="/Operation/userInterface" element={<Operation />} />
              <Route path="/Operation/TLInterface" element={<TLInterface />} />
              <Route path="/Operation/Audit" element={<Audit />} />
              <Route path="/Operation/TaskList" element={<TaskListComp />} />
              <Route
                path="/Reports/operations"
                element={<TLInterfaceReports />}
              />

              {/* <Route path="/TLInterface" element={<TLInterface />} /> */}
            </Route>
            <Route>
              {" "}
              <Route path="/*" element={<PageNotFound />} />
            </Route>
          </Routes>
        }
      </>
    );
  } else {
    return (
      <>
        {/* {openPopup?<PatientJoin openPopup={openPopup}/>:undefined} */}
        <Routes>
          <Route exact="true" path="/" element={<PublicLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </>
    );
  }
};
export default MainRoutes;