import React from "react";
import AmountVerification from "./AmountVerification";
import Meeting from "./Meeting";
import Reports from "./Reports";
const ChargeEntry = ({ subCheckBox,formTypeNoForApi,date,formType,practiceId,record }) => {
  return (
    <>
      {subCheckBox == "AmountVerification" ? (
        <AmountVerification record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
      ) : subCheckBox == "MeetingTl" ? (
        <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
      ) : subCheckBox == "Reports" ? (
        <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
      ) : null}
    </>
  );
};

export default ChargeEntry;
