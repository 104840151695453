import React, { useEffect, useState } from "react";
import { Panel, IconButton, DatePicker } from "rsuite";
import Select from "react-select";

import PlusIcon from "@rsuite/icons/Plus";
import VisibleIcon from "@rsuite/icons/Visible";
import moment from "moment/moment";
import FileUpload2 from "../../../Common/FileUpload2";
import ViewDocument from "../../../Common/IframeModel";
import { getBucketImages } from "../../../Utils/ApiManager";
import { debug } from "webpack";

export default function HtmlSection({
  openViewSection,
  handleDosDate,
  handleType,
  helpingState,
  setHelpingState,
  handlePopup,
  handlePracticeVal,
  handleRemarks,
  onSave,
}) {
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };

  let objR = {
    filelist: [],
  };
  const [objectcluster, setobjCLuster] = useState(objR);
  useEffect(() => {
    if (helpingState?.ImageSection?.FileList && helpingState?.ImageSection?.FileList.length > 0) {
      let arr = helpingState?.ImageSection?.FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data

          const blob = new Blob([res.data], { type: el.mimetype });
          var file = new File([blob], el.originalname);

          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original: el.originalname,
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
        setHelpingState((prev) => ({
          ...prev,
          ImageSection: { ...prev.ImageSection, image: updatedArr },
        }));
      });
    }
  }, [helpingState?.ImageSection?.FileList]);

  const onRemove = (val) => {
 
    let remainingObjects = setHelpingState?.ImageSection?.FileList.filter(
      (obj) => obj.fileKey !== val.fileKey
    );
    setHelpingState((prev) => ({
      ...prev,
      ImageSection: {
        ...prev.ImageSection,
        FileList: remainingObjects,
        image: remainingObjects,
      },
    }));
  };

  const onPreview = (val, row) => {
   
    
    let obj = { bucketImages: val };
    setHelpingState((prev) => ({
      ...prev,
      ImageSection: {
        ...prev.ImageSection,
        getSelectedRowDocument: obj,
        isFileViewerOpen: true,
      },
    }));
  };
  const openImageViwerModel = (val) => {
    // setDocViewer(val);
    setHelpingState((prev) => ({
      ...prev,
      ImageSection: { ...prev.ImageSection, isFileViewerOpen: val },
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
    }
  };

  const handleInputChangeF = (event) => {
    const selectedImage = event;
    setHelpingState((prev) => ({
      ...prev,
      ImageSection: {
        ...prev.ImageSection,
        image: selectedImage,
        FileList: selectedImage,
      },
    }));
  };

  return (
    <>
     {helpingState?.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      {helpingState?.ImageSection?.isFileViewerOpen == true && (
        <ViewDocument
          seletedRow={helpingState?.ImageSection?.getSelectedRowDocument}
          openImageViwerModel={openImageViwerModel}
        />
      )}
      <div className="row ">
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Practice
          </label>
          <br />
          <Select
            options={helpingState?.praticesNames || []}
            placeholder="Select an option"
            onChange={handlePracticeVal}
            value={{
              label: helpingState?.praticeSelection?.label,
              value: helpingState?.praticeSelection?.value,
            }}
            styles={customStyles}
          />
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {/* {errorModel.date == true ? "Date is Required" : null} */}
          </label>
        </div>
        <div className="col-3">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          >
            Entry Date
          </label>
          <br />
          <DatePicker
            onChange={handleDosDate}
            style={{ width: 400 }}
            oneTap
            value={
              helpingState?.dateOfEntry
                ? moment(helpingState?.dateOfEntry).toDate()
                : null
            }
            onKeyDown={handleKeyDown}
          />
          {/* <DatePicker
            class="form-control inputField"
            type="date"
            name="phone"
            placeholder=""
            max={today}
            min={minDate}
            //  defaultValue={date}
              onChange={handleDosDate}
          value={helpingState?.dateOfEntry ? moment(helpingState?.dateOfEntry).toDate() : null}
          /> */}
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px", color: "red" }}
          >
            {/* {errorModel.date == true ? "Date is Required" : null} */}
          </label>
        </div>

        {true && (
          <div className="col-3">
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              Type
            </label>
            <br />
            <Select
              options={[
                { value: "Review Coding", label: "Review Coding" },
                { value: "Complete Coding", label: "Complete Coding" },
                { value: "Reconciliation ", label: "Reconciliation " },
              ]}
              placeholder="Select an option"
              onChange={handleType}
              value={{
                label: helpingState?.Type?.label,
                value: helpingState?.Type?.value,
              }}
              // styles={customStyles}
            />
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        )}

        <div className="col-3 ">
          <label
            className="inputLabel"
            style={{ color: "black", fontSize: "13px" }}
          ></label>
          <br></br>
          <span
            className="ml-4"
            style={{ top: "20px", right: "0" }}
            onClick={() => handlePopup(true)}
          >
            <IconButton style={{ color: "#0e7490" }} icon={<PlusIcon />}>
              Add
            </IconButton>
          </span>
          <span
            className="ml-4"
            style={{ top: "20px", right: "0" }}
             onClick={()=>openViewSection('true')}
          >
            <IconButton style={{ color: "#0e7490" }} icon={<VisibleIcon />}>
              View
            </IconButton>
          </span>
        </div>

        {false ? null : <div className="col-3"></div>}
      </div>
      <div>
        <div class="row mr-2">
          <Panel
            defaultExpanded={true}
            header="Coding Fields"
            collapsible={true}
          >
            <div className="row mt-4">
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label
                    class="inputLabel"
                    style={{ color: "black" }}
                    //   onClick={openBfScreen}
                  >
                    B/F Claims
                    {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
                  </label>
                  {
                    <input
                      class="form-control inputField"
                      type="text"
                      name="bfVal"
                      placeholder={""}
                      value={helpingState?.bfVal}
                      disabled
                    />
                  }
                  {/* <input
                  class="form-control inputField"
                  type="text"
                  name="bfVal"
                  placeholder={""}
                  value={bfVal}
                  disabled
                /> */}
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Received Claims
                  </label>

                  <input
                    class="form-control inputField"
                    type=""
                    name="receivedClaims"
                    placeholder=""
                    value={helpingState?.receivedClaims}
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Worked Claims
                  </label>

                  <input
                    class="form-control inputField"
                    type="text"
                    name="workedClaims"
                    placeholder=""
                    value={helpingState?.workedClaims}
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Pending Claims
                  </label>

                  <input
                    class="form-control inputField"
                    type="text"
                    name="pendingClaims"
                    placeholder=""
                    value={helpingState?.pendingClaims}
                    disabled
                  />
                </div>
              </div>
            </div>
          </Panel>

          <div className="col-lg-12 mb-2 text-center"></div>

          <div class="col-sm-6 col-lg-3"></div>
          <div class="col-sm-6 col-lg-3"></div>
          <div class="col-sm-6 col-lg-3"></div>
          <div className="col-lg-12 mb-2 text-center"></div>
          <Panel
            defaultExpanded={true}
            header="Pending Claims Breakup"
            collapsible
          >
            <div className="row mt-3">
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Provider
                  </label>

                  <input
                    class="form-control inputField"
                    type="number"
                    name="providerVal"
                    placeholder=""
                    value={helpingState?.provider}
                    disabled
                  />
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Coding
                  </label>

                  <input
                    class="form-control inputField"
                    type="number"
                    name="operationVal"
                    placeholder=""
                    value={helpingState?.operation}
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Total
                  </label>

                  <input
                    class="form-control inputField"
                    type="number"
                    name="total"
                    placeholder=""
                    value={helpingState?.total}
                    disabled
                  />
                </div>
              </div>
            </div>
          </Panel>

          <div class="mt-4"></div>
          <Panel defaultExpanded={true} header="Remarks" collapsible={true}>
            {
              <div class="col-sm-12 col-lg-12 mt-4">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}></label>
                  <textarea
                    rows="4"
                    cols="50"
                    type="text"
                    name="generalTextField"
                    placeholder="Enter Remarks Here"
                    class="form-control inputField"
                    value={helpingState?.remarks}
                    onChange={handleRemarks}
                  ></textarea>
                </div>
              </div>
            }
          </Panel>
          <div className="mt-4"></div>

          <FileUpload2
            onRemove={onRemove}
            setobjCLuster={setobjCLuster}
            objectcluster={objectcluster}
            onPreview={onPreview}
            fileList={helpingState?.ImageSection?.FileList}
            openImageViwerModel={openImageViwerModel}
            handleInputChange={handleInputChangeF}
            image={helpingState?.ImageSection?.image}
          />

          <div class="col-sm-12 col-lg-12">
            <div class="form-group "></div>
          </div>
          <div class="col-sm-6 col-lg-3 "></div>

          <div class="col-sm-6 col-lg-3 "></div>
          <div class="col-sm-6 col-lg-3 "></div>
          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4">
                {/* <button
                  type="button"
                  className="btnClear mr-1 "
                  // onClick={onClear}
                >
                  Clear
                </button> */}
                <button type="button" className="btnSave" onClick={onSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
