import React, { useState, useEffect } from "react";
import { Checkbox, Panel } from "rsuite";
import {
  getAggingAcc,
  getAggingRec,
  getAggingRecPat,
  getUsersName,
} from "../../Utils/ApiManager";
import moment from "moment";
import IdInfoIcon from "@rsuite/icons/IdInfo";
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import { Loader } from "rsuite";
import SubRecordAgging from "./subRecordAgging";

export default function Aging() {
  let helpingObj = {
    oneMonthBucket: true,
    twoMonthBucket: false,
    threeMonthBucket: false,
    fourMonthBucket: false,
    startDate: null,
    endDate: null,
    DateArray: [],
    AccAgging: 0,
    PatStatAgging: 0,
    vobAgging: 0,
    loading: false,
    loadingPatStat: false,
    vobData: [],
    patData: [],
    AccData: [],
    subScreenOpen: null,
    isSubScreenOpen: false,
  };
  const [helpingState, sethelpingState] = useState(helpingObj);

  useEffect(() => {
    if (helpingState.oneMonthBucket) {
      dateCalculation(1);
    } else if (helpingState.twoMonthBucket) {
      dateCalculation(2);
    } else if (helpingState.threeMonthBucket) {
      dateCalculation(3);
    } else if (helpingState.fourMonthBucket) {
      dateCalculation(4);
    }
  }, [
    helpingState.oneMonthBucket,
    helpingState.twoMonthBucket,
    helpingState.threeMonthBucket,
    helpingState.fourMonthBucket,
  ]);
 
 

  

  const dateCalculation = (arg) => {
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    const endDateReal = moment(endDate).format("YYYY-MM-DD");

    endDate.setMonth(endDate.getMonth() - arg);

    const startDate = moment(endDate).format("YYYY-MM-DD");

    const startMoment = moment(startDate);
    const endMoment = moment(endDateReal);

    const dateArray = [];

    while (startMoment.isSameOrBefore(endMoment)) {
      dateArray.push(startMoment.format("YYYY-MM-DD"));
      startMoment.add(1, "days").clone();
    }

    sethelpingState((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDateReal,
      DateArray: dateArray,
    }));
  };
  const onSearchAggingPatStat = () => {
    sethelpingState((prev) => ({ ...prev, loadingPatStat: true }));
    let obj = {
      startDate: helpingState.startDate,
      endDate: helpingState.endDate,
      DateArray: helpingState.DateArray,
    };
    getAggingRecPat(obj)
      .then(async(res) => {
        let totalClaimsPatStat = 0;
        if (res.data.message == "No data found") {
          sethelpingState((prev) => ({
            ...prev,
            // AccAgging: AccModelData,
            // PatStatAgging: totalClaimsPatStat,

            loadingPatStat: false,
          }));

          return;
        }
        let resUser = await getUsersName();

        const mergedData = res.data.PatStat.map((user) => ({
          ...user,
          ...(resUser && resUser?.data.find((item) => item.userid === user.userid)),
        }));

        mergedData.map((el) => {
          totalClaimsPatStat = totalClaimsPatStat + el.total;
        });
        let patStat = [];

        patStat = mergedData.map((item) => {
          const { bfRow, ...rest } = item;
          return rest;
        });

        sethelpingState((prev) => ({
          ...prev,

          PatStatAgging: totalClaimsPatStat,
          patData: patStat,

          loadingPatStat: false,
        }));
      })
      .catch((err) => {
        sethelpingState((prev) => ({
          ...prev,

          loadingPatStat: false,
        }));
      });
  };

  const onSearchAggingAcc = () => {
    sethelpingState((prev) => ({ ...prev, loadingAcc: true }));
    let obj = {
      startDate: helpingState.startDate,
      endDate: helpingState.endDate,
      DateArray: helpingState.DateArray,
    };
    getAggingAcc(obj)
      .then(async(res) => {
        if (res.data.message == "No data found") {
          sethelpingState((prev) => ({
            ...prev,
            // AccAgging: AccModelData,
            // PatStatAgging: totalClaimsPatStat,

            loadingAcc: false,
          }));

          return;
        }
        let AccModelData = 0;
        res.data.AccModelData.map((el) => {
          AccModelData = AccModelData + el.total;
        });
        let accArr;

        let resUser = await getUsersName();

    const mergedData = res.data.AccModelData.map((user) => ({
      ...user,
      ...(resUser && resUser?.data.find((item) => item.userid === user.userid)),
    }));

        accArr = mergedData.map((item) => {
          const { bfRow, ...rest } = item;
          return rest;
        });

        sethelpingState((prev) => ({
          ...prev,

          AccAgging: AccModelData,
          AccData: accArr,

          loadingAcc: false,
        }));
      })
      .catch((err) => {
        sethelpingState((prev) => ({
          ...prev,

          loadingAcct: false,
        }));
      });
  };

  const onsearchAgging = async () => {
    sethelpingState((prev) => ({ ...prev, loading: true }));
    onSearchAggingPatStat();
    onSearchAggingAcc();
    let obj = {
      startDate: helpingState.startDate,
      endDate: helpingState.endDate,
      DateArray: helpingState.DateArray,
    };

    getAggingRec(obj)
      .then(async(res) => {
        if (res.data.message == "No data found") {
          sethelpingState((prev) => ({
            ...prev,
            // AccAgging: AccModelData,
            // PatStatAgging: totalClaimsPatStat,

            loading: false,
          }));

          return;
        }
        let totalClaimsVob = 0;
        // let totalClaimsPatStat = 0;
        // let AccModelData = 0;

        res.data.vob.map((el) => {
          totalClaimsVob = totalClaimsVob + el.total;
        });

        let resUser = await getUsersName();

    const mergedData = res.data.vob.map((user) => ({
      ...user,
      ...(resUser && resUser?.data.find((item) => item.userid === user.userid)),
    }));

        let arrVob = [];
        arrVob = mergedData.map((item) => {
          const { bfRow, ...rest } = item;
          return rest;
        });

        // res.data.PatStat.map((el) => {
        //   totalClaimsPatStat = totalClaimsPatStat + el.total;
        // });
        // res.data.AccModelData.map((el) => {
        //   AccModelData = AccModelData + el.total;
        // });

        sethelpingState((prev) => ({
          ...prev,
          // AccAgging: AccModelData,
          // PatStatAgging: totalClaimsPatStat,
          vobAgging: totalClaimsVob,
          vobData: arrVob,
          loading: false,
        }));
      })
      .catch((err) => {
        sethelpingState((prev) => ({
          ...prev,

          loading: false,
        }));
      });
  };

  const onChangeCheckBox = (arg, val, event) => {
    const { name } = event.target;

    const updatedState = {
      oneMonthBucket: name === "oneBucket",
      twoMonthBucket: name === "twoBucket",
      threeMonthBucket: name == "threeBucket", // Set other properties as needed
      fourMonthBucket: name == "fourBucket",
    };

    sethelpingState((prev) => ({ ...prev, ...updatedState }));
  };

  const handleCardClick = (e, arg) => {
    sethelpingState((prev) => ({
      ...prev,
      subScreenOpen: arg,
      isSubScreenOpen: true,
    }));
  };
  const onBack = () => {
    sethelpingState((prev) => ({
      ...prev,

      isSubScreenOpen: false,
    }));
  };

  console.log(helpingState, "helpingState");
  return (
    <>
      {helpingState?.isSubScreenOpen ? (
        <SubRecordAgging onBack={onBack} prevhelpingState={helpingState} />
      ) : (
        <>
          <Panel
            defaultExpanded={true}
            header="Aging Bucket"
            collapsible={true}
          >
            <div className="mt-2"></div>
            <div className="row">
              <div className="col-sm-2 col-lg-2">
                {" "}
                <Checkbox
                  name={"oneBucket"}
                  defaultChecked={false}
                  checked={helpingState?.oneMonthBucket}
                  value={helpingState?.oneMonthBucket}
                  onChange={onChangeCheckBox}
                >
                  0-30
                </Checkbox>
              </div>
              <div className="col-sm-2 col-lg-2">
                {" "}
                <Checkbox
                  name={"twoBucket"}
                  defaultChecked={false}
                  checked={helpingState?.twoMonthBucket}
                  value={helpingState?.twoMonthBucket}
                  onChange={onChangeCheckBox}
                >
                  31-60
                </Checkbox>
              </div>
              <div className="col-sm-2 col-lg-2">
                {" "}
                <Checkbox
                  name={"threeBucket"}
                  defaultChecked={false}
                  checked={helpingState?.threeMonthBucket}
                  value={helpingState?.threeMonthBucket}
                  onChange={onChangeCheckBox}
                >
                  61-90
                </Checkbox>
              </div>
              <div className="col-sm-2 col-lg-2">
                {" "}
                <Checkbox
                  name={"fourBucket"}
                  defaultChecked={false}
                  checked={helpingState?.fourMonthBucket}
                  value={helpingState?.fourMonthBucket}
                  onChange={onChangeCheckBox}
                >
                  91-120
                </Checkbox>
              </div>
            </div>
            <div className="col-sm-2 col-lg-2"></div>
            <div class="d-flex justify-content-end ">
              {" "}
              <button
                type="button"
                className="btnClear  "
                onClick={onsearchAgging}
              >
                Search
              </button>
            </div>
          </Panel>
          <div className="mt-4"></div>

          <div className="row">
            <div
              className="col-sm-3 col-md-3"
              onClick={(e) => handleCardClick(e, "vobs")}
            >
              <div class={`cardDashboard ${"bg-c-green"}  order-card`}>
                <div class=" card-body card-block">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-mutedCard mb-0">
                        {helpingState?.loading ? null : helpingState?.oneMonthBucket?"(0-30) Aging":helpingState?.twoMonthBucket?"(31-60) Aging":helpingState?.threeMonthBucket?"(61-90) Aging":helpingState?.fourMonthBucket?"(91-120) Aging":'Aging'}
                      </h5>
                      <h5 class="card-title text-uppercase text-mutedCard mb-0 mt-2">
                        {helpingState?.loading ? null : "Eligibility/VOB`s"}
                      </h5>
                      <div className="mb-2"></div>
                      <span class="h2 font-weight-bold mb-0">
                        {helpingState?.loading ? (
                          <Loader size="lg" content="Loading Vobs Aging..." />
                        ) : (
                          helpingState?.vobAgging
                        )}
                      </span>
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape  text-white shadow"
                        style={{ backgroundColor: "rgb(0 187 154)",height:'50px',width:'40px' }}
                      >
                        <IdInfoIcon style={{height:'50px',width:'40px'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-sm-3 col-md-3"
              onClick={(e) => handleCardClick(e, "PatStat")}
            >
              <div class={`cardDashboard ${"bg-c-green"}  order-card`}>
                <div class=" card-body card-block">
                  <div class="row">
                    {helpingState?.loadingPatStat ? (
                      <>
                        <div class="col">
                          <h5 class="card-title text-uppercase text-mutedCard mb-0"></h5>
                          <h5 class="card-title text-uppercase text-mutedCard mb-0 mt-2"></h5>
                          <div className="mb-2"></div>
                          <span class="h2 font-weight-bold mb-0">
                            <Loader size="lg" content="Loading Aging..." />
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="col">
                          <h5 class="card-title text-uppercase text-mutedCard mb-0">
                            {helpingState?.oneMonthBucket?"(0-30) Aging":helpingState?.twoMonthBucket?"(31-60) Aging":helpingState?.threeMonthBucket?"(61-90) Aging":helpingState?.fourMonthBucket?"(91-120) Aging":'Aging'}
                          </h5>
                          <h5 class="card-title text-uppercase text-mutedCard mb-0 mt-2">
                            {"Patient Statement"}
                          </h5>
                          <div className="mb-2"></div>
                          <span class="h2 font-weight-bold mb-0">
                            {helpingState?.PatStatAgging}
                          </span>
                        </div>
                      </>
                    )}
                     <div class="col-auto">
                      <div
                        class="icon icon-shape  text-white shadow"
                        style={{ backgroundColor: "rgb(0 187 154)",height:'50px',width:'40px' }}
                      >
                        <CharacterAuthorizeIcon style={{height:'50px',width:'40px'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-sm-3 col-md-3"
              onClick={(e) => handleCardClick(e, "Acc")}
            >
              <div class={`cardDashboard ${"bg-c-green"}  order-card`}>
                <div class=" card-body card-block">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-mutedCard mb-0">
                        {helpingState?.loadingAcc ? null : helpingState?.oneMonthBucket?"(0-30) Aging":helpingState?.twoMonthBucket?"(31-60) Aging":helpingState?.threeMonthBucket?"(61-90) Aging":helpingState?.fourMonthBucket?"(91-120) Aging":'Aging'}
                      </h5>
                      <h5 class="card-title text-uppercase text-mutedCard mb-0 mt-2">
                        {helpingState?.loadingAcc ? null : "Account Receivable"}
                      </h5>
                      <div className="mb-2"></div>
                      <span class="h2 font-weight-bold mb-0">
                        {helpingState?.loadingAcc ? (
                          <Loader size="lg" content="Loading A/R Aging..." />
                        ) : (
                          helpingState?.AccAgging
                        )}
                      </span>
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape  text-white shadow"
                        style={{ backgroundColor: "rgb(0 187 154)",height:'50px',width:'40px' }}
                      >
                        <CharacterAuthorizeIcon style={{height:'50px',width:'40px'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
