import React, { useEffect } from "react";
import "../../Operation/operation.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchProduct } from "../../../Utils/ApiManager";
import moment from "moment";
import CodingMainForm from "./CodingMainForm";

function CodingAudit() {
  
  const [formType, setFormType] = useState(null);
  const [startDatee, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const { onLoginSignOff } = useSelector((state) => state);

  const handleFormVal = (val) => {
    if (val.name == "startDate") {
      setStartDate(val.val);
      return;
    }
    if (val.name == "endDate") {
      setEndDate(val.val);
      return;
    }
    if (val.name == "formType") {
      setFormType(val.value);

      return;
    }
  };

  console.log("products",products)

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    console.log("res",res)
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setProducts([...optionList]);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div class="p-4 navcolor bg-white rounded  mb-5">
            <div>
              <CodingMainForm
                date={date}
                handleFormVal={handleFormVal}
                setProducts={setProducts}
                products={products}
                startDatee={startDatee}
                endDate={endDate}
                formType={formType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CodingAudit;
