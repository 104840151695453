import React, { useState, useContext, useEffect } from "react";
import Logo from "../../../Assets/images/logo2.svg";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userAvatar from "../../../Assets/images/userAvatar.svg";
import { updateProviderStatus } from "../../../Utils/ApiManager";

import "./toggle.css";
import Notifcation from "./Notifcation";
import capitalizeFirst from "../../../Common/CaptilizeFirstLetter";

import store from "../../../Store/Store";
import SVGComponent from "../../SvgComp/Svh";
import NotifcationComp from "./Notification/Notify";
import { Badge } from 'rsuite';



let RommURL = process.env.REACT_APP_PATIENT_URL;
export const Header = ({
  setStateHelping,
  onCancelNotify,
  searchOverAllbyonlyvictimid,
  isDrwaerOnNotification,
  stateHelping,
 
  toggleSidenavBar,
  toggleDarkMode,
  darkMode,
  ProviderInCall,
  showNotification
}) => {
  

  let dispatch = useDispatch();
  
  const [notification, setnotifications] = useState(true);
  const [profilepic, setprofilepic] = useState(false);
  const state = useSelector((state) => state);
  const {
    LoginObject,
    StripePackagePurchaseDetailObject,
    updateProfileImagee,
    AllPatientsAccepted,
    onLoginSignOff
  } = useSelector((state) => state);
  const [prevProviderName, setPrevProviderName] = useState(null);
  const [notificationCounter, setnotificationsCounter] = useState(0);
  const [counterEffect, setCounterEffect] = useState(false);
  const [checked, setChecked] = useState();
  const toggleChecked = () => {
    setChecked((value) => !value);
  };

  let isProviderMessagesOpens =
    store && store.getState()?.isProviderMessagesOpens;


  const [toggleModal, setToggleModal] = useState(false);
  const [isMessageSectionOpen, setIsMessageSectionOpen] = useState(false);
  const [dispatchForNotifys, setDispatchForNotifys] = useState();
  let navigate = useNavigate();

  
  useEffect(() => {
    setIsMessageSectionOpen(isProviderMessagesOpens);
  }, [isProviderMessagesOpens]);
  useEffect(() => {
    if (dispatchForNotifys) {
      dispatchNotfiyForText(dispatchForNotifys, isMessageSectionOpen);
    }
  }, [dispatchForNotifys]);

  const dispatchNotfiyForText = (patientdata, Arg) => {
    if (LoginObject.id === patientdata.providerId && Arg != true) {
      let obj = {};
      obj.msg = `${patientdata.PatName} just texted you`;

  
    }
  };

 

  const openModal = () => {
    setToggleModal(true);
  };
  // const handleLogout = () => {
  //   updateProviderStatuss();

  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.href = "/";
  // };

  useEffect(() => {
    if (profilepic) {
      setnotifications(true);
    }
  }, [profilepic]);
  useEffect(() => {
    if (!notification) {
      setprofilepic(false);
    }
  }, [notification]);
  

 

  

  return (
    <>
   
      
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo mr-5">
            {/* <img src={Logo} className="mr-2" alt="logo" /> */}
            <div> <SVGComponent height={60} top={50} left={0}/></div>
          </a>
          <a className="navbar-brand brand-logo-mini">
            {/* <img src={Logo} alt="logo" /> */}
          <div  > <SVGComponent height={60} top={50} left={0} /></div>
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="icon-menu" onClick={() => toggleSidenavBar()}>
              <i className="fas fa-bars"></i>
            </span>
          </button>
        
          <ul className="navbar-nav mr-lg-2">
            <li className="nav-item nav-search d-none d-lg-block">
             
            </li>
          </ul>

          <ul className="navbar-nav navbar-nav-right">
            {/* <li
              className="nav-item  mt-2 darkModeToogle"
              data-toggle="tooltip"
              data-placement="top"
              title={darkMode ? "Switch to Day Mode" : "Switch to Dark Mode"}
            >
            
              <label className=" d-flex togglei mt-0 ml-2 ">
                <input
                  className="toggle__inputi  ml-5 "
                  name="check"
                  type="checkbox"
                  checked={checked}
                  onClick={() => toggleDarkMode()}
                  id="myggle"
                />
                <div className="toggle__filli">
                  {darkMode ? (
                    <>
                      <span>🌞</span>
                    </>
                  ) : (
                    <>
                      <span className="ml-1  pl-4">🌜</span>
                    </>
                  )}
                </div>
              </label>
            </li> */}
            <li
              className={
                notification ? "nav-item dropdown show" : "nav-item dropdown "
              }
              onClick={() => setnotifications(!notification)}
            >
               <Badge content={stateHelping.notifcationArray.length}>
               <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                data-toggle="dropdown"
              >
                <i className="far fa-bell" onClick={showNotification}></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-danger">
                  
                </span>
                <span className=""></span>
              </a>

               </Badge>
              
              {stateHelping.isShowNotification?<NotifcationComp
              setStateHelping={setStateHelping}
              searchOverAllbyonlyvictimid={searchOverAllbyonlyvictimid}
                DrwaerOnNotification={isDrwaerOnNotification} stateHelping={stateHelping.notifcationArray}notification={false}/>:null}

             
            </li>
            <li
              onClick={() => setprofilepic(!profilepic)}
              className={
                profilepic
                  ? "nav-item nav-profile dropdown"
                  : "nav-item nav-profile dropdown show"
              }
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                {updateProfileImagee ? (
                  <>
                    {/* <img
                      src={"https://dashboard.telxy.com/" + updateProfileImagee}
                      alt="profile"
                    /> */}
                  </>
                ) : (
                  <>
                    {/* <img src={userAvatar} alt="profile" /> */}
                  </>
                )}
                {/* <img src={userAvatar} alt="profile" /> */}
                <b
                  style={{
                    fontSize: "18px",
                    marginLeft: "0.5rem",
                    fontWeight: "600",
                  }}
                >
                  {'Welcome! '+capitalizeFirst(onLoginSignOff.name)}
                </b>
              </a>
              {/* <div
                className={
                  profilepic
                    ? "dropdown-menu dropdown-menu-right navbar-dropdown show"
                    : "dropdown-menu dropdown-menu-right navbar-dropdown "
                }
                aria-labelledby="profileDropdown"
              >
                <a
                  className="dropdown-item"
                  onClick={() => navigate("/settings")}
                >
                  <i className="fas fa-ellipsis-v text-primary"></i>
                  Settings
                </a>
               
              </div> */}
            </li>
            <li className="nav-item nav-settings d-none d-lg-flex">
              <a className="nav-link">
                <i className="fas fa-sliders-v"></i>
              </a>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right rounded d-lg-none align-self-center p-2 text-white"
            type="button"
            data-toggle="offcanvas"
            onClick={() => toggleSidenavBar()}
          >
            <span className="icon-menu fas fa-bars ml-1">
              <i className="fas fa-sliders-v"></i>
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};
