import { Nav } from 'rsuite';
import React,{useEffect} from 'react';
import HomeIcon from '@rsuite/icons/legacy/Home';
import DetailIcon from '@rsuite/icons/Detail'
import CouponIcon from '@rsuite/icons/Coupon';
import ChangeListIcon from '@rsuite/icons/ChangeList';
import IdInfoIcon from '@rsuite/icons/IdInfo';
import { useState } from 'react';
import FirefoxIcon from '@rsuite/icons/legacy/Firefox';
import DropboxIcon from '@rsuite/icons/legacy/Dropbox';
import DocPassIcon from '@rsuite/icons/DocPass';
import EllipsisHIcon from '@rsuite/icons/legacy/EllipsisH';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import TextImageIcon from '@rsuite/icons/TextImage';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';





const Navbar = ({helpingObject,  active, onSelect, handleFormType, ...props }) => {


  const[navTab,setNavTab]=useState(null)
  let navItems=[
    { eventKey: 'ClaimLevel', label: helpingObject=='vob'?'Account Level Audit':'Claim Level Audit', icon: <IdInfoIcon /> },
  { eventKey:'OverAll' , label: 'OverAll Audit', icon: <DocPassIcon /> },
   
 
   
    
  
  ];



  


   

  const isActive = (eventKey) => active === eventKey;
  const activeStyle = { backgroundColor: "#0e7490", color: "white" };
  const subactiveStyle = { backgroundColor: "#0e7490", color: "white" };
  console.log('Active,',active)

  return (
    <>
    <div className="nav-container rs-nav-item rs-nav-item-active">
    <Nav {...props} activeKey={active} onSelect={onSelect}  appearance="subtle">
        {navItems && navItems.map(({ eventKey, label, icon }) => (
          <Nav.Item
            key={eventKey}
            eventKey={eventKey}
            icon={icon}
            style={isActive(eventKey) ? activeStyle : {}}
            onSelect={handleFormType}
          >
            <span style={{fontWeight:'normal'}}>{label}</span>
          </Nav.Item>
        ))}
        
       
              <span
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                 
                }}
              >
                
               
              </span>
            
      </Nav>

    </div>
      
    
      
    </>
  );
};

const AuditTabMenus = ({helpingObject, sethelpingObj }) => {
  const [active, setActive] = useState('ClaimLevel');
  const [checkedType, setCheckedType] = useState('ClaimLevel');
  console.log(helpingObject,'helpingObject')

  const handleFormType = (event,arg) => {
    sethelpingObj((prev)=>({...prev,tabName:event}))
   
  
  
   
   
   
  };

  return (
    <>
      <Navbar
       // payerType={payerType}
       helpingObject={helpingObject}
        appearance="tabs"
        active={active}
        onSelect={setActive}
        handleFormType={handleFormType}
      />
    
    </>
  );
};

export default AuditTabMenus;




