import React, { useState, useEffect } from "react";
import HtmlSection from "./htmlSection";
import AddNewCoding from "./AddNew/addNew";
import {
  FetchCoding,
  fetchProduct,
  insertCoding,
} from "../../../Utils/ApiManager";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { toast } from "react-toastify";
import NotifySwal from "../../../Common/NotifySwal";
import View from "./View/View";

export default function  UserInterfaceCoding() {
  const { onLoginSignOff } = useSelector((state) => state);

  let obj = {
    id: 0,
    openPopup: false,
    praticesNames: [],
    praticeSelection: null,
    // funhandlePracticeVal: handlePracticeVal,
    dateOfEntry: moment().format("YYYY-MM-DD"),
    bfVal: "",
    internalTableArray: [],
    receivedClaims: "",
    workedClaims: "",
    pendingClaims: "",
    provider: "",
    operation: "",
    total: "",
    remarks: "",
    loading: false,
    isOpenSwal: false,
    isOpenView:false,
    isAllStatesAreSet: false,
    ImageSection: {
      image: [],
      bucketImages: null,
      isFileViewerOpen: null,
      FileList: [],

      getSelectedRowDocument: null,
    },
    Type: { label: "Review Coding", value: "Review Coding" },
    file: null,
  };

  const [helpingState, setHelpingState] = useState(obj);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (helpingState?.praticeSelection && helpingState?.dateOfEntry) {
      fetchRecord();
    }
  }, [helpingState?.praticeSelection, helpingState?.dateOfEntry]);


  useEffect(() => {
    if (helpingState?.ImageSection?.bucketImages) {
      let arr = [];
      try {
        arr = JSON.parse(helpingState?.ImageSection?.bucketImages);
      } catch {
        arr = helpingState?.ImageSection?.bucketImages;
      }
      setHelpingState((prev)=>({...prev,ImageSection:{...prev.ImageSection,
        FileList:arr
      }}))

      // setFileList(arr);
    }
  }, [helpingState?.ImageSection?.bucketImages]);

  useEffect(() => {
    if (
      helpingState?.internalTableArray &&
      helpingState?.internalTableArray.length > 0
    ) {
      let parsedArray = [];
      try {
        parsedArray = JSON.parse(helpingState?.internalTableArray);
       
      } catch (error) {
        parsedArray = helpingState?.internalTableArray;
      }

      let receivedClaims = 0;
      let workedClaims = 0;
      let total = 0;
      let pendingClaims = 0;
      let provider = 0;
      let operation = 0;

      helpingState?.internalTableArray &&
        helpingState?.internalTableArray.map((el) => {
          receivedClaims += parseInt(el.receivedClaims);
          workedClaims += parseInt(el.workedClaims);
          total += parseInt(el.total);
          pendingClaims += parseInt(el.pendingClaims);
          provider += parseInt(el.provider);
          operation += parseInt(el.operation);
        });
      setHelpingState((prev) => ({
        ...prev,
        receivedClaims: receivedClaims,
        workedClaims: workedClaims,
        total: total,
        pendingClaims: pendingClaims,
        provider: provider,
        operation: operation,
      }));
    }
  }, [helpingState?.internalTableArray]);

  //----------------------------- For Practices Name------------------------
  const handlePracticeVal = (val) => {
    setHelpingState((prev) => ({ ...prev, praticeSelection: val }));
  };
  const handlePopup = (val) => {
    if (helpingState?.praticeSelection == null) {
      toast.info("Please Select Practice");
      return;
    }

    if (val == true) {
      setHelpingState((prev) => ({ ...prev, openPopup: val }));
      return;
    }
    if (val == false && helpingState?.internalTableArray.length > 0) {
      // For Grid Edit
      setHelpingState((prev) => ({ ...prev, isOpenSwal: true }));
      return;
    }
    if (val == false && helpingState?.internalTableArray == 0) {
      setHelpingState((prev) => ({ ...prev, openPopup: val }));
      return;
    }
  };
  const handleRemarks = (e) => {
    const { value } = e.target;
    setHelpingState((prev) => ({ ...prev, remarks: value }));
  };
  const handleType = ({ value, label }) => {
    setHelpingState((prev) => ({
      ...prev,
      Type: {
        ...prev.Type,
        label: label,
        value: value,
      },
    }));
  };
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setHelpingState((prev) => ({ ...prev, praticesNames: optionList }));
    }
  };

  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem =
      arr2 &&
      arr2.length > 0 &&
      arr1 &&
      arr1.length > 0 &&
      arr1.find((item1) =>
        arr2.some(
          (item2) =>
            item1.date === item2.date &&
            item1.recievedAmount === item2.recievedAmount
        )
      );

    return !matchingItem;
  };

  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }
    if (arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray =
      parseArr && parseArr.filter((item) => item?.bfval !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        receivedClaims: item?.pendingClaims,
        workedClaims: 0,
        pendingClaims: 0,
        provider: 0,
        operation: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };
  const fetchRecord = () => {
    let obj = {
      userid: onLoginSignOff.userid,
      pid: helpingState?.praticeSelection?.value,
      sdate: helpingState?.dateOfEntry,
      formType: "10",
    };
    FetchCoding(obj)
      .then(async (res) => {
        if (res.data) {
          let reso = res.data[0];

          if (reso?.rcount == 0) {
            let newArray = await transformArray(reso?.bfArray);
            setHelpingState((prev) => ({
              ...prev,
              bfVal: reso?.bfVal,
              internalTableArray: newArray,
            }));

            setHelpingState((prev)=>({...prev,ImageSection:{
              ...prev.ImageSection,
              image:[],
              FileList:[]
            }}))
            return;
          }
          if (reso?.rcount == 1) {
            const newArray = transformArray(reso?.bfArray);
            let parsedArr = [];
            try {
              parsedArr = JSON.parse(reso?.dosArray);
            } catch {
              parsedArr = reso?.dosArray;
            }


            let arr = [];
      try {
        arr = JSON.parse(reso?.file);
      } catch {
        arr = reso?.file;
      }
     

      setHelpingState((prev)=>({...prev,ImageSection:{
        ...prev.ImageSection,
        image:arr,
        bucketImages:reso?.file,
       

      }}))



    


            const result = checkArraysHaveSameDateAndAmount(
              newArray,
              parsedArr
            );

            let combineArray;
            if (result == false) {
              combineArray = JSON.parse(reso?.bfArray);
            } else {
              let arr = [];
              try {
                arr = JSON.parse(reso?.dosArray);
              } catch {
                arr = reso?.dosArray;
              }

              if (newArray == false) {
                combineArray = [].concat(arr);
              } else {
                combineArray = newArray.concat(arr);
              }
            }

            setHelpingState((prev) => ({
              ...prev,
              receivedClaims: reso?.receivedClaims,
              id: reso?.id,
              workedClaims: reso?.workedClaims,
              pendingClaims: reso?.pendingClaims,
              bfVal: reso?.bfVal || 0,
              internalTableArray: combineArray,
              file: reso?.file,
              operation: reso?.operation,
              remarks: reso?.remarks,
              total: reso?.total,
              type: reso?.type,
              provider: reso?.provider,
            }));
          }
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    if (helpingState?.praticeSelection == null) {
      toast.info("Please Select Practice");
      return;
    }
    if (helpingState?.internalTableArray.length == 0) {
      toast.info("For Save! Please Add Some Dos Wise Record");
      setHelpingState((prev) => ({
        ...prev,
        loading: false,
        isAllStatesAreSet: false,
      }));
      return;
    }

    setHelpingState((prev) => ({ ...prev, loading: true }));
    const formDataForSaveApi = new FormData();
    let image = helpingState?.ImageSection?.image;
    if (image && image.length > 0 && image != "[]") {
      image.forEach((el, index) => {
        formDataForSaveApi.append(`file`, el?.blobFile);
      });
    } else {
      formDataForSaveApi.append(`file`, null);
    }
    formDataForSaveApi.append("id", helpingState?.id);
    formDataForSaveApi.append("userid", onLoginSignOff.userid);
    formDataForSaveApi.append("sdate", helpingState?.dateOfEntry);
    formDataForSaveApi.append("type", helpingState?.Type?.value);
    formDataForSaveApi.append("pid", helpingState?.praticeSelection?.value);
    formDataForSaveApi.append("bfval", helpingState?.bfVal || 0);

    formDataForSaveApi.append(
      "receivedClaims",
      helpingState?.receivedClaims || 0
    );

    formDataForSaveApi.append("workedClaims", helpingState?.workedClaims || 0);

    formDataForSaveApi.append(
      "pendingClaims",
      helpingState?.pendingClaims || 0
    );
    formDataForSaveApi.append("provider", helpingState?.provider || 0);
    formDataForSaveApi.append("operation", helpingState?.operation || 0);
    formDataForSaveApi.append("total", helpingState?.total || 0);

    formDataForSaveApi.append("remarks", helpingState?.remarks);
    formDataForSaveApi.append("formType", "10");
    formDataForSaveApi.append(
      "dosArray",
      JSON.stringify(helpingState?.internalTableArray)
    );
    insertCoding(formDataForSaveApi)
      .then((res) => {
        if (res.data && res.data.message == "Created successfully") {
          toast.info("Record Created Successfully");
          setHelpingState((prev) => ({ ...prev, loading: false }));
          fetchRecord();
        }
        if(res.data && res.data.message == "Update successful"){
          setHelpingState((prev) => ({ ...prev, loading: false }));
          toast.info("Record Updated Successfully");
          fetchRecord();
         
        }
        setHelpingState((prev) => ({ ...prev, loading: false }));
        
      })
      .catch((err) => {
        toast.info("Record Not Saved");
        setHelpingState((prev) => ({ ...prev, loading: false }));
      });

    setHelpingState((prev) => ({
      ...prev,
      isOpenSwal: false,
      openPopup: false,
    }));
  };
  const handleDosDate = (event) => {
    if (event == null) {
      setHelpingState((prev) => ({
        ...prev,
        dateOfEntry: moment().format("YYYY-MM-DD"),
      }));
    }
    if (event) {
      // Check if it's a date picker change (assuming Dos is a property in your state)
      if (event instanceof Date) {
        let date = moment(event).format("YYYY-MM-DD");
        setHelpingState((prev) => ({ ...prev, dateOfEntry: date }));
      }
    }
  };

  const handleSwalConfirmOnInternalSave = (val) => {
    if (val == true) {
      onSave();
    } else if (!val) {
      setHelpingState((prev) => ({ ...prev, isOpenSwal: false }));
    } else if (val == "backToMainScreen") {
      setHelpingState((prev) => ({
        ...prev,
        isOpenSwal: false,
        openPopup: false,
      }));
    }
  };

  const  openViewSection=(val)=>{
    setHelpingState((prev)=>({...prev,isOpenView:val}))

  }
  return (
    <>
      {helpingState.isOpenSwal ? (
        <NotifySwal
          showDenyButton={true}
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmOnInternalSave}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
        />
      ) : null}
      {helpingState?.openPopup == true ?
        <AddNewCoding
          parentHelpingState={helpingState}
          parentsetHelpingState={setHelpingState}
          handlePopup={handlePopup}
        />:
      helpingState?.isOpenView?<><View openViewSection={openViewSection} parentHelpingState={helpingState}/></> : (
       <div className="ml-3"> <HtmlSection
        openViewSection={openViewSection}
          handleDosDate={handleDosDate}
          onSave={onSave}
          handleRemarks={handleRemarks}
          handleType={handleType}
          handlePopup={handlePopup}
          handlePracticeVal={handlePracticeVal}
          helpingState={helpingState}
          setHelpingState={setHelpingState}
        />
        </div>
      )}
    </>
  );
}
