import React,{useEffect,useState} from 'react';
import { Uploader, Panel } from 'rsuite';

const FileUpload2 = ({onRemove,objectcluster,setobjCLuster, onPreview,fileList, handleInputChange }) => {
 const [previewFile,setPreviewFile]=useState("")
  useEffect(() => {
    

    if(fileList && fileList.length>0){
         
      
      setPreviewFile(fileList)
      setobjCLuster((prev)=>({
        ...prev,
        filelist:fileList

      }))
    }else{
      setobjCLuster((prev)=>({
        ...prev,
        filelist:[]

      }))

    }
   
  }, [fileList,])
 
  
  console.log('fileListfileList',fileList)
  
  return (
    <Panel defaultExpanded={true} header="Attachment" collapsible>
      <div className='mt-3'>
        <Uploader
         accept={".png, .jpeg, .jpg, .pdf, .docx, .xlsx"}
        onRemove={onRemove}
        draggable={true}
        
          listType="picture-text"
          defaultFileList={Array.isArray(objectcluster?.filelist) && objectcluster?.filelist?objectcluster?.filelist:[]}
          onChange={handleInputChange}
          fileList={Array.isArray(objectcluster?.filelist) && objectcluster?.filelist?objectcluster?.filelist:[]}
          method={'GET'}
          
         action={'https://laravel.medifusion.com/text'}
          renderFileInfo={(file, fileElement) => {
            let name=file.name?file?.name:file?.originalname.slice(0, 10)
            return (
              <>
              {console.log('legalFile',file)}
                <span>File Name: {name}</span>
               {<button className='fas fa-eye pl-2 lightGreen view_button'
                onClick={()=>onPreview(file)} > View</button>} 
                {file.response &&
          file.response.status === 503 && (
            <span className="text-danger pl-2">
           
            </span>
          )}
                
              </>
            );
          }}
        />
      </div>
    </Panel>
  );
};

export default FileUpload2;
