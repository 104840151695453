import React, { useEffect } from "react";
import { useState } from "react";
import {Panel} from "rsuite"

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import NotifySwal from "../../../../Common/NotifySwal"
import arr from "../../../../Common/PayerList";
import {
  getBucketImages,
  insertFormType7AccountReceivable,
  onSaveApi,
} from "../../../../Utils/ApiManager";
import Select from "react-select";
import array from "../output";

import Dateoofservice from "../../../../Common/Dateoofservice";
import { useDispatch } from "react-redux";
import {
  onChangePayer,
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "../../AllForms/CommonDos";
import { handleCloseC } from "../../AllForms/CommonDos";
import { createValidationSchema } from "../../../../Common/Validation";
import { Schema } from "rsuite";
import DateAndPractice from "../../AllForms/DateAndPractice";
import DateoofserviceGlobal from "../../../../Common/DateofServiceGlobal";
import moment from "moment/moment";
import DateoofserviceBF from "../../AllForms/BFClaims";
import { Result } from "postcss";
import { AllConfirmationOnTab } from "../../../../Actions/Actions";
import FileUpload2 from "../../../../Common/FileUpload2";
import ViewDocument from "../../../../Common/IframeModel";
import { debug } from "webpack";

const GeneralPayers = ({
  onChangePayer,
  payerSelection,
  handleFormTypeAndPract,
  productss,
  setNameGeneric,
  record,
  practiceId,
  date,
  formTypeNoForApi,
  onRecordUpdateOrCreated,
}) => {
  let errorobj = {
    recievedAmount: false,
    AmountDosVal: false,
    date: false,
  };
  let footerObj = {
    isAllow: true,
    receivedClaims: "",

    workedClaims: "",

    pendingClaims: "",
    receivedAmount:"",
    CollectedAmount:"",
    PendingAmount:"",
    workedBreakupTotal: "",


  };
  let breakupObj = {
    gcp: "",
    gca: "",
    gcr: "",
    gct: "",
    gcs: "",
    total: "",
    gcpa:"",
    gcaa:"",
    gcra:"",
    gcta:"",
    gcsa:"",
    

    totala:"",

    //For Partially Paid Breakup 
    gppc:"", //partailly paid claims
    gpdA:"" //partially denied amount 

  };

  let objAmount = {
    recAmountGen: "",
    CollectAmountGen: "",
    pendAmuontGen: "",
  };
  let helpingObj={
    isSpeakToaster:false,
    loadingn:false,
    isSpeakerToaster:false,
    isBackSpeak:false,
    AllFiles: null,
    renderEffectForUpload: false,
    getSelectedRowDocument: null,
    updatedblodState: [],
    updatedblob: [],
    isFileViewerOpen: null,
    isAllStatesAreSet:false
  }
  let objR={
    filelist:[]

  }
  const [objectcluster,setobjCLuster]=useState(objR)

  const [FileList, setFileList] = useState([]);
  const [helpingState,sethelpingState]=useState(helpingObj)
  const [stateAmount, setStateAmount] = useState(objAmount);
  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");
  const [footerState, setFooterState] = useState(footerObj);

  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);
  const [pendingClaimss, setPendingClaimss] = useState("");
  const [partiallyPaidClaims, setPartiallyPaidClaims] = useState("");
  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);
  const [openModelPending, setModelPending] = useState(false);
  const [bfScreenArray, setBfScreenArray] = useState("");
  const [TotalAll, setTotalAll] = useState(0);
  const [TotalAll2, setTotalAll2] = useState(0);

  // new thingd

  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [totalClaims, setTotalClaims] = useState(false);
  const [bfRow, setisThisbfRow] = useState(false);

  const [globalDos, openGlobalDos] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");
  const [rowID, setRowID] = useState(null);

  useEffect(() => {
    if(parseInt(stateAmount?.pendAmuontGen)<0){
      toast.info('Pending Amount value must be a positive number. Please verify your calculations.')
    }
   
  }, [stateAmount?.pendAmuontGen])

  useEffect(() => {
    const { gcp, gca, gcr, gct, gcs, total,gcpa,gcaa,gcra,gcta,gcsa,gppc} = AllBreakupVal;
    if(parseInt(AllBreakupVal?.totala)){
      // setStateAmount((prev)=>({...prev,CollectAmountGen:AllBreakupVal?.totala}))
    }
    

    if (gcp || gca || gcr || gct || gcs) {
      let totalValue =
        (parseInt(gcp) || 0) +
        (parseInt(gca) || 0) +
        (parseInt(gcr) || 0) +
        (parseInt(gct) || 0) +
        (parseInt(gcs) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
    if (gcpa || gcaa || gcra || gcta || gcsa || gppc) {
      let totalValue =
     // (parseInt(gcpa) || 0) +
      (parseInt(gcaa) || 0) +
      (parseInt(gcra) || 0) +
      (parseInt(gcta) || 0) +
      (parseInt(gcsa) || 0);
      
        // (parseInt(gcpa) || 0) +parseInt(gppc)
        
      setBreakupVal((prev) => ({ ...prev, totala: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, totala: 0 }));
    }
  }, [
    AllBreakupVal.gcp,
    AllBreakupVal.gca,
    AllBreakupVal.gcr,
    AllBreakupVal.gct,
    AllBreakupVal.gcs,
    AllBreakupVal.total,
    AllBreakupVal.gcpa,
    AllBreakupVal.gcaa,
    AllBreakupVal.gcra,
    AllBreakupVal.gcta,
    AllBreakupVal.gcsa,
    AllBreakupVal.totala,
  ]);

  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem = arr1!=undefined && arr1.length>0 && arr1.find((item1) =>
      arr2.some(
        (item2) =>
          item1.date === item2.date &&
          item1.recievedAmount === item2.recievedAmount
      )
    );

    return !matchingItem;
  };

  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }

    if (!arr && arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray = parseArr && parseArr.filter((item) => item?.bfVal !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        recievedAmountCC: item?.bfVal?.toString(),
        AmountDosVal: item?.bfAmount?.toString(),
        recievedAmount: "0",
        AmountDosValCC: "0",
        providerVal: "0",
        recievedAmountgGen:"0",
      collectedAmountgGen:"0",
      pendinGen:"0",

        codingVal: 0,
        credientalingVal: 0,
        operationVal: 0,
        EdiVal: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };

  // const transformArray=(arr)=>{

  //   if(arr.length==0){
  //     return []
  //   }

  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);

  //   } catch (error) {

  //     parseArr = arr;

  //   }

  //   const newArray = parseArr.length>0 && parseArr.map(item => ({
  //     ...item,

  //     recievedAmountCC:item?.bfVal?.toString(),

  //     payerlabel: "0",
  //     payerId:"0",
  //     recievedAmount: "0",
  //     total: "0",

  //     bfRow:true
  //   }));

  //   return newArray;
  // }

  const handleChangeAmount = (e) => {
  
    const { name, value } = e.target;

    setStateAmount((prev) => ({ ...prev, [name]: value }));
  };
  const onPreview = (val, row) => {
  
    let obj = { bucketImages: val };
    sethelpingState((prev)=>({...prev,getSelectedRowDocument:obj}))
    sethelpingState((prev) => ({
      ...prev,
      //  base64: objRes.base64String,
      isFileViewerOpen: true,
    }));
   
   
  };
  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;
   
    if (value == "") {
      setBreakupVal((prevState) => ({
        ...prevState,
        [name]: 0,
      }));
      return;
    }
    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onHandlePendingClaimss = (val) => {
    if(parseInt(val)<0){
      toast.info('Pending Claims value must be a positive number. Please verify your calculations.')
      
     }

    setPendingClaimss(val);
  };
  
  const onHandlePartiallyPaidClaims=(e)=>{
   
    const {value}=e.target
    setPartiallyPaidClaims(value)
  }

  const { StringType, NumberType, DateType } = Schema.Types;
  const [errorModel, setErrorModel] = useState(errorobj);
  const [renderEffect, setRenderEffect] = useState(false);
  const { onLoginSignOff } = useSelector((state) => state);
  const [selectedOption, setSelectedOption] = useState("");
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [AmountDosRec, setAmountDosRec] = useState([]);
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [products, setProducts] = useState([]);

  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [dosModel, setDosModel] = useState(false);
  const [handledosDate, setdosDate] = useState("");
  const [handleInputdos, sethandleInput] = useState("");
  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [docViewer, setDocViewer] = useState(false);
  const [totalworkedAmount, settotalWorkedAmount] = useState("");



  console.log('dosArrayRecieved',dosArrayRecieved)



  useEffect(() => {
   
    if(FileList && FileList.length>0){
     
      let arr = FileList && Array.isArray(FileList) && FileList.length>0 && FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data
    
        const blob = new Blob([res.data], { type: el.mimetype });
        var file = new File([blob],  el.originalname);
      // const uint8Array = new Uint8Array(res.data);
      //  const blob = new Blob([uint8Array], { type: el.mimetype });
      //  blob.name = el.originalname;
      //  blob.lastModified = el.lastModified;
         
   
          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original:el.originalname
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      
      const validPromises = arr && Array.isArray(arr) && arr.filter(item => item instanceof Promise);
      if(validPromises!=false){
        Promise.all(arr).then((updatedArr) => {
       
       
       
          setResult((prev)=>({...prev,image:updatedArr}))
         //  setobjCLuster((prev)=>({
         //   ...prev,
         //   filelist:updatedArr
   
         // }))
        
         })

      }
     
  
  
      
    }
   
  }, [FileList])

  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }

    let pending = 0;
    let recievedAmountCC = 0;
    let totalBreakup = 0;
    let RecievdAmount=0;
    let collectedAmount=0;
    let pendingAmount=0

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el?.recievedAmountCC); //REC PAT STAT

        // recievedAmountCC += parseInt(el.recievedAmount); //WORKED
        pending += parseInt(el?.pending); //dENEID
        totalBreakup += parseInt(el?.pending);


        RecievdAmount += parseInt(el?.recievedAmountgGen);
        collectedAmount += parseInt(el?.collectedAmountgGen);
        pendingAmount += parseInt(el?.pendinGen);
      });

    setFooterState((prev) => ({
      ...prev,
      receivedClaims: count,
      //  workedBreakupTotal:totalBreakup,
      workedClaims: recievedAmountCC,
      receivedAmount:RecievdAmount,

      CollectedAmount: collectedAmount,
      PendingAmount:pendingAmount

    }));
  }, [dosArrayRecieved]);

  let obj = {
    userid: "",
    sdate: "",
    pid: "",
    formtype: "", //
    submittype: "",
    generalpayers: 0,
    generalpayerName: "",
    generalpayerID: "",
    PayerType:"",

    specialpayerName: "",
    specialpayerID: "",
    bfval: "", //
    recievedval: 0, //
    workedval: 0,
    pendingval: 0, //
    specialpayers: "",
    sbf: "",
    srecieved: "",
    sworked: "",
    spendingval: "",
    comments: "",
    breakupval: {
      gcp: "0",
      gca: "0",
      gcr: "0",
      gct: "0",
      gcs: "0",

      gcpa: "0",
      gcaa: "0",
      gcra: "0",
      gcta: "0",
      gcsa: "0",

    },
    sbreakval: {
      scp: 0,
      sca: 0,
      scr: 0,
      sct: 0,
      scs: 0,
    },

    GeneralOpen: "",
    insurance: "",
    stoken: "",
    image: "",
    totalval: "",
    dosArray: [],
    ReceivedAmountTotal:"",
CollectedAmountTotal:"",
pendingAmountTotal:""

  };
  const [result, setResult] = useState(obj);
  const openDosmodel = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Worked Claims");
    setDosModel(true);
  };
  const onSaveDos = () => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(result.dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = result.dosArray;
    }

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el.recievedAmount);
      });
    setResult((prevResult) => ({
      ...prevResult,
      workedval: count,
    }));
    setNameGeneric("Account Receivable");
    setBreakupVal(breakupObj);
    setPendingClaimss(0);
    setisPendingSectionOpen(false);
    setPendingClaimss(0);
    // setBreakupVal(breakupObj);

    setDosModel(false);
  };

  // useEffect(() => {
  //   if(paymentstate?.paymentsTotal || paymentstate?.paymentsTotalPar || paymentstate?.DenialAmount ||paymentstate?.DenialAmountPar ){


  //   }
   
  // }, [paymentstate?.paymentsTotal,paymentstate?.paymentsTotalPar,paymentstate?.DenialAmount,paymentstate?.DenialAmountPar])
  

  const openDosmodelPending = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Pending Claims");

    setModelPending(true);
  };

  const handleEditState = async (rowData) => {
    if (!rowData.hasOwnProperty("status")) {
      return;
    }
    const nextData = Object.assign([], result.dosArray);
    const activeItem = nextData.find((item) => item.id === rowData.id);
    console.log(activeItem.status, "bug here", nextData);
    activeItem.status = activeItem.status ? null : "EDIT";
    // setDosArray(nextData);
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };

  const CustomInputGroupWidthButton = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={styles}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );

  const onChangeInput = (val) => {
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }));
  };
  const onChangeDate = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;

    let results = result.dosArray.some((el) => el.date === formattedDate);
    if (results) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,
    }));
  };

  let nextData;
  const handleChangeeob = (id, key, value, event) => {
    event.persist();

    nextData = Object.assign([], result.dosArray);
    nextData.find((item) => item.id === id)[key] = value;
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };
  const styles = {
    marginBottom: 10,
  };
  const onAdddos = async () => {
    let results = result.dosArray.some((el) => el.date === handledosDate);
    if (results) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    if (parseInt(handleInputdos) != parseInt(AllBreakupVal.total)) {
      toast.info("Worked Claims should be equal to total Worked Breakup ");
      return;
    }

    let obj = {
      date: handledosDate,
      recievedAmount: handleInputdos,
      status: null,
      id: result.dosArray.length + 1,

      gcp: AllBreakupVal.gcp,
      gca: AllBreakupVal.gca,
      gcr: AllBreakupVal.gcr,
      gct: AllBreakupVal.gct,
      gcs: AllBreakupVal.gcs,
      total: AllBreakupVal.total,
      Pending: pendingClaimss,
    };

    const validationFields = [
      {
        fieldName: "recievedAmount",
        rules: { requiredMessage: "Received amount is required." },
        type: { typeError: StringType() },
      },
      // {
      //   fieldName: 'AmountDosVal',
      //   rules: { requiredMessage: 'AmountDosVal is required.' },
      //   type: { typeError: StringType() }

      // },
      {
        fieldName: "date",
        rules: { requiredMessage: "Date is required." },
        type: { typeError: DateType() },
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);
    // if (errors.AmountDosVal.hasError) {
    //   setErrorModel((prevErrorModel) => ({
    //     ...prevErrorModel,
    //     AmountDosVal: true,

    //   }));

    // }
    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
      }));
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
      }));
    }
    if (!errors.recievedAmount.hasError && !errors.date.hasError) {
      setResult((prevResult) => ({
        ...prevResult,
        dosArray: [...prevResult.dosArray, obj],
      }));
    }
  };
  const handleClose = () => {
    openGlobalTable(false);

    setdosDate("");
    sethandleInput(null);

    setisPendingSectionOpen(false);
    setPendingClaimss(0);
    setPartiallyPaidClaims("")

    setBreakupVal(breakupObj);
    setDosModel(false);
  };
 
  const handlePracticeVal = (val) => {
   
   
    const { value, label,PayerType
    } = val;
   // setSelectedOption(val);
    setResult((prevResult) => ({
      ...prevResult,
      PayerType:PayerType,

      generalpayerName: label,
      generalpayerID: value,
    }));
  };

  useEffect(() => {
    let check = result.breakupval;
    let totalVal;
    let totalVal2;
    if (check.gcp || check.gca || check.gcs || check.gcr || check.gct || check?.gcpa || check?.gcaa|| check?.gcsa || check?.gcra ||check?.gcta) {
      totalVal =
        (parseInt(check.gcp) || 0) +
        (parseInt(check.gca) || 0) +
        (parseInt(check.gcs) || 0) +
        (parseInt(check.gcr) || 0) +
        (parseInt(check.gct) || 0);

      setResult((prevResult) => ({
        ...prevResult,

        totalval: totalVal,
      }));
    } else {
      setResult((prevResult) => ({
        ...prevResult,

        totalval: totalVal,
      }));
    }
  }, [
    result.breakupval.gcp,
    result.breakupval.gca,
    result.breakupval.gcr,
    result.breakupval.gct,
    result.breakupval.gcs,

    result.breakupval.gcpa,
    result.breakupval.gcaa,
    result.breakupval.gcra,
    result.breakupval.gcta,
    result.breakupval.gcsa,
  ]);

  useEffect(() => {
    if (date) {
      onclear();
    }
  }, [date]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  useEffect(() => {

  
    if(AllBreakupVal?.gppc||AllBreakupVal?.gcpa){
      let total=parseInt(AllBreakupVal?.gppc||0)+parseInt(AllBreakupVal?.gcpa||0)
      setStateAmount((prev)=>({...prev,CollectAmountGen:total}))
    }
   
  }, [AllBreakupVal?.gppc,AllBreakupVal?.gcpa])
  

  useEffect(() => {
    let optionList = array.map((item) => ({
      value: item.ID,
      label: `${item.NAME}-${item.ID}`,
    }));
    setProducts(optionList);
    if (record) {
      if (record?.rcount === "0" && !record[0]?.id) {
        setResult((prevResult) => ({
          ...prevResult,
          bfval: record?.bfvalprv,
          sbf: record?.bfprv,
        }));
        setFileList([])

        const newArray = transformArray(record?.dosArrayRecieved);
        let a=[]
        if(newArray==false){
          a=[]

        }
        setDosArrayRec(a);
        //  setBfScreenArray(record?.dosArrayRecieved)
      } else {
        setResult(record.result[0]);
        let obj = {};
        try {
          obj = JSON.parse(record.result[0]?.breakupval);
        } catch (error) {
          // Handle the case where JSON parsing failed
          obj = record.result[0]?.breakupval;
        }
        let obj1 = {};
        let obj2 = {};
        let obj3=[]

        try {
          obj1 = JSON.parse(record.result[0]?.dosArray);
          obj2 = JSON.parse(record.result[0]?.dosArrayRecieved);
          obj3=JSON.parse(record.result[0]?.image)
        } catch (error) {
          // Handle the case where JSON parsing failed
          obj1 = record.result[0]?.dosArray;
          obj2 = record.result[0]?.dosArrayRecieved;
          obj3=record.result[0]?.image
          
        }


        sethelpingState((prev) => ({ ...prev, bucketImages: record.result[0]?.image,updatedblodState:obj3 }));
        setResult((prev)=>({...prev,image:obj3}))//image
        setFileList(obj3)//file
        setResult((prevResult) => ({
          ...prevResult,
          breakupval: {
            ...prevResult?.breakupval,
            gcp: obj?.gcp,
            gca: obj?.gca,
            gcr: obj?.gcr,
            gct: obj?.gct,
            gcs: obj?.gcs,
          },
          generalpayerName: obj2?.payerlabel,
          generalpayerID: obj2?.payerId,
          dosArray: obj1,
          dosArrayRecieved: obj2,
        }));
        const newArray = transformArray(obj2);
        const result = checkArraysHaveSameDateAndAmount(newArray, obj2);
        let combineArray;
        if (result == false) {
          combineArray = obj2;
        } else {
          if(newArray==false){
            combineArray= [].concat(obj2)
        
          }else{
            combineArray= newArray &&  newArray.concat(obj2);
          }
        
        
         
        }

        // let combineArray=newArray.concat(JSON.parse(record.result[0]?.dosArrayRecieved))
        setDosArrayRec(combineArray);
        // setBfScreenArray(obj2)
      }
    }
  }, [record, practiceId]);
  console.log(dosArrayRecieved, "dosArrayRecieved");

  useEffect(() => {
    if (practiceId) {
      setResult((prev) => ({
        ...prev,
        pid: practiceId,
      }));
    }
  }, [practiceId]);

  useEffect(() => {
    if (result?.recievedval && result?.workedval) {
      let val = Number(result?.recievedval) + Number(result?.bfval);
      const pendingval = val - Number(result?.workedval);
      setResult((prevResult) => ({ ...prevResult, pendingval: pendingval }));
    }
  }, [result?.recievedval, result?.workedval]);

  const onclear = () => {
    setResult(obj);
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    }


    if(dosArrayRecieved.length==0){
      toast.info('For Save! Please Add Some Dos Wise Record')
      sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
      return

    }

    // if (result?.totalval != result?.workedval) {
    //   toast.info("Total breakup should be equal to Worked Claims");
    //   return;
    // }
    sethelpingState((prev)=>({...prev,loadingn:true}))

    const breakupval = `{
        "gcp": ${parseInt(
          result.breakupval?.gcp > 0 ? result.breakupval?.gcp : 0,
          10
        )},
        "gca": ${parseInt(
          result.breakupval?.gca > 0 ? result.breakupval?.gca : 0,
          10
        )},
        "gcr": ${parseInt(
          result.breakupval?.gcr > 0 ? result.breakupval?.gcr : 0,
          10
        )},
        "gct": ${parseInt(
          result.breakupval?.gct > 0 ? result.breakupval?.gct : 0,
          10
        )},
        "gcs": ${parseInt(
          result.breakupval?.gcs > 0 ? result.breakupval?.gcs : 0,
          10
        )}
      }`;
    let a = 0;
    const sbreakval = `{
        "scp":${parseInt(a)},
        "sca":${parseInt(a)},
        "scr":  ${parseInt(a)},
        "sct":  ${parseInt(a)},
        "scs":  ${parseInt(a)}
      }`;

    const formDataForSaveApi = new FormData();
    formDataForSaveApi.append("userid", onLoginSignOff.userid); //
    formDataForSaveApi.append("sdate", date); //
    formDataForSaveApi.append("pid", practiceId); //
    formDataForSaveApi.append("formtype", formTypeNoForApi); //
    formDataForSaveApi.append("generalpayers", "yes");
    formDataForSaveApi.append("bfval", result?.bfval); //
    formDataForSaveApi.append("recievedval", result?.recievedval); //
    formDataForSaveApi.append("insurance", result?.insurance);
    formDataForSaveApi.append("workedval", result?.workedval);
    formDataForSaveApi.append("GeneralOpen", result?.GeneralOpen);
    formDataForSaveApi.append("pendingval", result?.pendingval);
    formDataForSaveApi.append("stoken", result?.stoken);
    formDataForSaveApi.append("submittype", "No");
    // formDataForSaveApi.append("file", result?.image);
    if (result?.image && result?.image.length > 0 && result?.image!="[]") {
      result?.image.forEach((el, index) => {
        formDataForSaveApi.append(`file`,el?.blobFile );
      });
    } else {
      formDataForSaveApi.append(`file`, null);
    }

    //All States
    formDataForSaveApi.append("ReceivedAmountTotal", result?.ReceivedAmountTotal);
    formDataForSaveApi.append("CollectedAmountTotal", result?.CollectedAmountTotal);
    formDataForSaveApi.append("pendingAmountTotal", result?.pendingAmountTotal);
    //*************** */

    formDataForSaveApi.append("specialpayers", "no");
    formDataForSaveApi.append("sbf", result?.sbf);
    formDataForSaveApi.append("srecieved", result?.srecieved);
    formDataForSaveApi.append("sworked", 0);
    formDataForSaveApi.append("spendingval", result?.spendingval);
    formDataForSaveApi.append("breakupval", breakupval);
    formDataForSaveApi.append("sbreakval", sbreakval);

    formDataForSaveApi.append("generalpayerName", result?.generalpayerName);
    formDataForSaveApi.append("generalpayerID", result?.generalpayerID);
    

    formDataForSaveApi.append("specialpayerName", result?.specialpayerName);
    formDataForSaveApi.append("specialpayerID", result?.specialpayerID);
    formDataForSaveApi.append("comments", result?.comments);
    formDataForSaveApi.append("total", result?.totalval);
    formDataForSaveApi.append("dosArray", JSON.stringify(result?.dosArray));
    formDataForSaveApi.append(
      "dosArrayRecieved",
      JSON.stringify(dosArrayRecieved)
    );

    insertFormType7AccountReceivable(formDataForSaveApi)
      .then((result) => {
        console.log(result, "'''''''''''''");
        if (result?.data.message == "Record Updated SuccessFully") {
          toast.info(result?.data.message);
          onclear();
          sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
          onRecordUpdateOrCreated("AccountReceivable");
        }
        if (result?.data.message == "Record Created SuccessFully") {
          toast.info(result?.data.message);
          onclear();
          sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
          onRecordUpdateOrCreated("AccountReceivable");
        }
      })
      .catch((err) => {
        console.log("then", err);
        if (
          err?.response?.data &&
          err?.response?.data?.message ==
            "Portals and Calls values should be equal to worked value!"
        ) {
          sethelpingState((prev)=>({...prev,loadingn:false,isAllStatesAreSet:false}))
          toast.info(err?.response?.data?.message);
        }
      });
  };
  // const handleInputChange = (event) => {
  //   const selectedImage = event[0];
  //   setResult((prevResult) => ({ ...prevResult, image: selectedImage }));
  // };

  const handleInputChange = (event) => {
    const selectedImage = event;
    setResult((prev)=>({...prev,image:selectedImage}))

  //  setImage(selectedImage);

    setFileList(selectedImage);
  };
  const handelChange = (e) => {
    const { name, value } = e.target;

    let check =
      name == "gcp" ||
      name == "gca" ||
      name == "gcr" ||
      name == "gct" ||
      name == "gcs" ||
      name == "scp" ||
      name == "sca" ||
      name == "scr" ||
      name == "sct" ||
      name == "scs";
    if (check) {
      setResult((prevResult) => ({
        ...prevResult,
        breakupval: {
          ...prevResult.breakupval,
          [name]: value,
        },
      }));

      return;
    }
    setResult((prevResult) => ({ ...prevResult, [name]: value }));
  };
  console.log(result, "result");
  const openImageViwerModel = (val) => {
    setDocViewer(val);
    sethelpingState((prev) => ({ ...prev,isFileViewerOpen: val }));
  };



  
  const dispatch = useDispatch();

  useEffect(() => {
    if(globalDos){
      dispatch(AllConfirmationOnTab(true))

    }else{
      dispatch(AllConfirmationOnTab(false))

    }

    
  }, [globalDos])
  console.log("last bug", result);

  //--------------------
  const handleCommonEdit = async (rowData) => {
  
    if (rowData.recievedAmount) {
      if (rowData.bfRow == true) {
        setisThisbfRow(true);
      } else {
        setisThisbfRow(false);
      }

      let obj = {
        isEdit: true,
        rowDataa: rowData,
      };
      setGridEdit(obj);
      

      sethandleInputRec(rowData.recievedAmountCC); // rECEIVED AMOUNT
      setPendingClaimss(rowData.pending);
      setPartiallyPaidClaims(rowData.partiallyPaid) //PAID
      setRowID(rowData.id);
      sethandleInput(rowData.recievedAmount); //DENEID

      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      setResult((prevResult) => ({
        ...prevResult,

        generalpayerName: rowData?.payerlabel,
        generalpayerID: rowData?.payerId,
      }));
      setBreakupVal((prev) => ({
        ...prev,
        gcp: rowData.gcp,
        gca: rowData.gca,
        gcr: rowData.gcr,
        gct: rowData.gct,
        gcs: rowData.gcs,
        gcpa:rowData.gcpa,
        gcaa:rowData.gcaa,
        gcra:rowData.gcra,
        gcta:rowData.gcta,
        gcsa:rowData.gcsa,
        gppc:rowData.partiallyPaid,
        gpdA:rowData?.gpdA
      }));
    } else {
      return;
    }
  };
  const handleChangeCommondos = (id, key, value, event) => {
    handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
  };
  const onSaveDosCo = () => {
    if(handleInputdosRec=="" && handleInputdos=="" && partiallyPaidClaims=="" && stateAmount?.recAmountGen=="" && AllBreakupVal?.gcp=="" && AllBreakupVal?.gcpa=="" && AllBreakupVal?.gca=="" && AllBreakupVal?.gcaa=="" && AllBreakupVal?.gcr=="" && AllBreakupVal?.gcra=="" && AllBreakupVal?.gct=="" && AllBreakupVal?.gcta=="" && AllBreakupVal?.gcs=="" && AllBreakupVal?.gcsa=="" &&  AllBreakupVal?.gppc=="" && AllBreakupVal?.gpdA=="" && (dosArrayRecieved.length==0||dosArrayRecieved.length<0))
    {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      onSaveDosC(
        true,
        dosArrayRecieved,
        setResult,
        setDosModelRec,
        setResult,
        "generalpayer",null,null,null,stateAmount
      );
      setStateAmount((prev)=>({...prev,recAmountGen:""}))
      sethandleInputRec("")
      sethandleInput("")


      sethelpingState((prev)=>({...prev,isSpeakToaster:false}))

    }else{
      sethelpingState((prev)=>({...prev,isSpeakToaster:true}))

    }
    
    
  };
  const onChangeInputCC = (val) => {
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmountCC: false,
    }));
    onChangeInputC(
      val,
      sethandleInputRec,
      setErrorModel,
      "stopEecutingErrModel"
    );
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayRecieved);
  };
  const onChangeDAmountCC = (val) => {
    onChangeAmountC(val, setAmountDosRec);
  };
  const handleCloseCC = () => {
    sethelpingState((prev)=>({isBackSpeak:true}))
   
  };
  const handleTaosterSpeak=()=>{
    if(dosArrayRecieved && dosArrayRecieved.length==0){
      toast.info('Please Enter Date of Service Wise Record')
      return
     }
    
    sethelpingState((prev)=>({...prev,isSpeakerToaster:true}))
  }
  const onAdddosCC = () => {
  

    const gppc = parseInt(AllBreakupVal?.gppc);
    const paidClaims = parseInt(partiallyPaidClaims);
    
    // if (!isNaN(gppc) && !isNaN(paidClaims)) {
    //   if (gppc !== paidClaims) {
    //     toast.info('Partially Paid Claims should be equal to Partially breakup');
    //     return;
    //   }
    // }
    
    

   let total=parseInt(AllBreakupVal?.gcaa||0)+parseInt(AllBreakupVal?.gcra||0)+parseInt(AllBreakupVal?.gcta||0)+parseInt(AllBreakupVal?.gcsa||0)


    if(stateAmount?.pendAmuontGen!=(total))
    {
      toast.info('Total Worked Amount should be equal to  Pending Claims Amount')
      return
    }

    
    if((result?.generalpayerName).length==0){
     toast.info('Please Select Payer List')
      return

    }
    if (handleInputdos != "") {
      if (parseInt(handleInputdos) != parseInt(AllBreakupVal.total)) {
        toast.info(
          "Worked Claims should be equal to Total Claims"
        );
        return;
      }
    }
    onAdddosC(
      handledosDateRec,
      handleInputdosRec,
      dosArrayRecieved,
      AmountDosRec,
      setDosArrayRec,
      setErrorModel,
      "gp",
      "payer",
      AllBreakupVal,
      partiallyPaidClaims,
      handleInputdos,
      result,
      null,
      null,
      gridEdit,
      rowID,
      null,
      bfRow,
      stateAmount,
    );

    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    setisThisbfRow(false);
    sethandleInputRec("");

    sethandleInput("");
    setPendingClaimss("");
    setPartiallyPaidClaims("")
    //  setTotalClaims(0)
    setBreakupVal(breakupObj);
  };
  const openDosmodelWorked = () => {
    setNameGeneric("Recieved Claims");
    setDosModelRec(true);
  };
  const onCleanDateSimple = () => {
    setdosDate("");
  };
  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };
  const renderr = (val) => {
    setRenderEffect(val);
  };

  useEffect(() => {
    
    if (stateAmount.recAmountGen || stateAmount.CollectAmountGen) {
      let pending =
        parseInt(stateAmount.recAmountGen) -
        parseInt(stateAmount.CollectAmountGen);
      setStateAmount((prev) => ({ ...prev, pendAmuontGen: pending }));
    }
  }, [stateAmount.recAmountGen, stateAmount.CollectAmountGen]);

  useEffect(() => {
    let paresedArray2;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArrayRecieved;
    }

    if (Array.isArray(paresedArray2) && paresedArray2.length > 0) {
      const filteredArray2 = paresedArray2.filter((obj) => obj !== undefined);
      const sumProperty = (property) =>
        filteredArray2.reduce(
          (sum, obj) => sum + (parseInt(obj[property]) || 0),
          0
        );
      let gcp = sumProperty("gcp");
      let gca = sumProperty("gca");
      let gcr = sumProperty("gcr");
      let gct = sumProperty("gct");
      let gcs = sumProperty("gcs");



      let gcpa = sumProperty("gcpa");
      let gcaa = sumProperty("gcaa");
      let gcra = sumProperty("gcra");
      let gcta = sumProperty("gcta");
      let gcsa = sumProperty("gcsa");
      setTotalAll(gcp + gca + gcr + gct + gcs);
      setTotalAll2(gcpa+gcaa+gcra+gcta+gcsa)

      setResult((prev) => ({
        ...prev,
        breakupval: {
          ...prev.breakupval,
          gcp: gcp,
          gca: gca,
          gcr: gcr,
          gct: gct,
          gcs: gcs,

          gcpa:gcpa,
          gcaa:gcaa,
          gcra:gcra,
          gcta:gcta,
          gcsa:gcsa

        },
      }));
      //  setBreakupVal((prev)=>({...prev,
      //   gcp:sumProperty("gcp"),
      //     gca:sumProperty("gca"),
      //     gcr:sumProperty("gcr"),
      //     gct:sumProperty("gct"),
      //     gcs:sumProperty("gcs"),
      // }))
    }
  }, [dosArrayRecieved]);
  console.log("All", AllBreakupVal);

  const handleClosePopPending = () => {
    // setNameGeneric("Charge Entry")
    setNameGeneric("Account Receivable");
    setModelPending(false);
  };

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const [openBf, setBf] = useState(false);
  const openBfScreen = () => {
    setBf(true);
  };
  const handleCloseBfScreen = () => {
    setBf(false);
  };

  const onHandleCloseCall=()=>{
    if (gridEdit.isEdit) {
      onAdddosCC();
    }
    callGlobalDos(false);
    setErrorModel(errorobj);

    // setNameGeneric("Account Receivable");
    // handleCloseC(
    //   setDosModelRec,
    //   setErrorModel,
    //   sethandleInputRec,
    //   setAmountDosRec,
    //   setdosDateRec
    // );

  }


  useEffect(() => {
    if(helpingState?.isAllStatesAreSet){
      onSave()
      
    }
   
  }, [helpingState?.isAllStatesAreSet])

  const handleSwalConfirm=(val)=>{
 
    
    if (gridEdit.isEdit) {
      onAdddosCC();
    }
    if(val==true){

      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      onSaveDosC(
        true,
        dosArrayRecieved,
        setResult,
        setDosModelRec,
        setResult,
        "generalpayer",null,null,null,stateAmount
      );
      setStateAmount((prev)=>({...prev,recAmountGen:""}))
      sethandleInputRec("")
      sethandleInput("")
      sethelpingState((prev)=>({...prev,isSpeakToaster:false,isAllStatesAreSet:true}))

    }
    
   
    else if(!val){

      sethelpingState((prev)=>({...prev,isSpeakToaster:false}))
    }
    else if(val=='backToMainScreen'){
      sethelpingState((prev)=>({...prev,isSpeakToaster:false}))
      setisThisbfRow(false);
      sethandleInputRec("");
  
      sethandleInput("");
      setPendingClaimss("");
      setPartiallyPaidClaims("")
      //  setTotalClaims(0)
      setBreakupVal(breakupObj);
      callGlobalDos(false);
      
      


    }

  }

  const handleSwalConfirm2=(val)=>{
    if(val){
      

      sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))
     onSave()

    }else{
      sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))

    }

  }
  const handleSwalConfirmBackButton=(val)=>{
    if(val){
      

      sethelpingState((prev)=>({...prev,isBackSpeak:false}))
      onHandleCloseCall()

    }else{
      sethelpingState((prev)=>({...prev,isBackSpeak:false}))

    }

  }
  const onRemove=(val)=>{

    let remainingObjects = FileList.filter((obj) => obj.fileKey !== val.fileKey);
    setFileList(remainingObjects)
   // setResult((prevResult) => ({ ...prevResult, image: remainingObjects }));
   //setImage(remainingObjects)
   setResult((prev)=>({...prev,image:remainingObjects}))
    

  }


  const handleKeyPress = (e) => {
    
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (charCode === 8 || (charCode >= 48 && charCode <= 57)||( charCode != 190 && charCode != 110)) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };


  console.log('result breakup',result?.breakupval)

  return (
    <>
    {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
     {helpingState.isSpeakToaster?<NotifySwal  showDenyButton={true} triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Save Values'}/>:null}
   {helpingState.isSpeakerToaster?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm2}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
   {helpingState.isBackSpeak?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirmBackButton}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
      {openBf == true ? (
        <>
          <DateoofserviceBF
            handleClose={handleCloseBfScreen}
            dosArray={bfScreenArray}
          />
        </>
      ) : openScreenGlobalTable == true ? (
        <Dateoofservice
        isAllowThirdTable={{allow:true,flagThirdTable:'generalPayer'}}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          // handleChange={handleChange}
          // handleEditState={handleEditState}
          // onSaveDos={onSaveDos}
          dosArray={dosArrayRecieved}
          // setDosArray={{ state: setDosArray, flag: "1" }}
          // onAdddos={onAdddos}
          //  onChangeDate={onChangeDate}
          //  onChangeInput={onChangeInput}
          //  onChangeInputCC={onChangeInputCC}
          handleClose={handleClose}
          //  showAmount={true}
          //  showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          //  practiceId={practiceId}
          //  dosPendingBreakup={{ isAllow: true, onChangeValues: onChangeValues }}

          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"payer"}
          flag={"gp"}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal
        handleKeyPress={handleKeyPress}
          stateAmount={stateAmount}
          handleChangeAmount={handleChangeAmount}
          Footer={footerState}
          flagFooter={"generalPayer"}
          resultPayer={result}
          handlePracticeVal={handlePracticeVal}
          changeState={"gp"}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
          AmountDos={totalClaims}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={setTotalClaims}
          // pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          partiallyPaidClaims={partiallyPaidClaims}
          setPendingClaimm={onHandlePendingClaimss}
          onHandlePartiallyPaidClaims={onHandlePartiallyPaidClaims}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={arr}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}
          onChangeInputCC={onChangeInputCC}
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          // onChangeAmount={onChangeAmount}
          onChangeAmountCC={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          // claimHeading={"Denied"}
          claimAmount={"Worked Claims"}
          claimHeading1={"Received Claims"}
          //  claimAmount1={"Paid"}
          practiceId={practiceId}
          onlyShowTable={false}
          flag={"payer"}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          recievedClaimArraydos={[]}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Received Amount"}
        />
      ) : openModelPending ? (
        <Dateoofservice
          onlyShowTable={true}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={result.dosArray}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
          showTableCheck={"vob"}
        />
      ) : (
        <>
          {/* <DocViewer
            docViewer={docViewer}
            image={result?.image}
            openImageViwerModel={openImageViwerModel}
          /> */}
          {helpingState?.isFileViewerOpen == true && ( <ViewDocument
              seletedRow={helpingState?.getSelectedRowDocument}
              openImageViwerModel={openImageViwerModel}
            />
          )}
          <div class="row">
            {/* <div className="col-lg-12 mb-2 text-center">
        <h4 class="inputLabel " style={{ color: "black" }}>
          General Payers
        </h4>
      </div> */}
            <DateAndPractice
              nowShowExtraDiv={true}
              onChangePayer={onChangePayer}
              payerSelection={payerSelection}
              showPayer={true}
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={productss}
              date={date}
            />

            <Panel defaultExpanded={true} header="A/R" collapsible > 
            <div className="row mt-4">
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label
                  class="inputLabel"
                  style={{ color: "black" }}
                  onClick={openBfScreen}
                >
                  B/F
                  {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="bfval"
                  placeholder=""
                  value={result?.bfval}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Received Claims
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.recievedval}
                  disabled
                />
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Worked Claims
                </label>
                <input
                  class="form-control inputField"
                  type=""
                  name="workedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.workedval}
                  disabled
                />
                {/* <CustomInputGroupWidthButton
                  value={result.workedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodel}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending Claims
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="pendingval"
                  placeholder=""
                  value={result?.pendingval}
                  disabled
                />
                {/* <CustomInputGroupWidthButton
                value={result?.pendingval}u
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Received Claims Amount
                </label>
                <div  class="form-group input-icon">

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.ReceivedAmountTotal}
                  disabled
                />
                <i className="lightGreen">$</i>
                </div>
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Collected Claims Amount
                </label>
                <div  class="form-group input-icon">

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                 
                  value={result?.CollectedAmountTotal}
                  disabled
                />
                <i className="lightGreen">$</i>
                </div>
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Pending Claims Amount
                </label>
                <div  class="form-group input-icon">

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.pendingAmountTotal}
                  disabled
                />
                 <i className="lightGreen">$</i>

                </div>
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            </div>
            </Panel>
            <div className="row mt-4"></div>

            

            {/* <div class="col-sm-6 col-lg-6 mb-5">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Payers List
                </label>

                <Select
                  options={products || []}
                  placeholder="Select an option"
                  onChange={handlePracticeVal}
                  // defaultValue={[{value:result?.generalpayerName,label:result?.generalpayerID}]}
                  value={[
                    {
                      label: result?.generalpayerName,
                      value: result?.generalpayerID,
                    },
                  ]}
                  styles={customStyles}
                />
              </div>
            </div> */}
            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Total Breakup of worked claims:
              </h4>
            </div> */}

            <Panel  defaultExpanded={true} header="Worked claims Breakup" collapsible >
              <div className="row mt-4">
              <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcp"
                  placeholder=""
                  disabled
                  onChange={handelChange}
                  value={result?.breakupval?.gcp}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid Amount{" "}
                     {" "}

                  </label>
                  <div  class="form-group input-icon">

                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcpa"
                  placeholder=""
                  disabled
                  onChange={handelChange}
                  value={result?.breakupval?.gcpa}
                />
                 <i className="lightGreen">$</i>

                </div>
                  </div>

                  </div>
                  </div>



                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gca"
                  placeholder=""
                  disabled
                  value={result?.breakupval?.gca}
                  onChange={handelChange}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcaa"
                  placeholder=""
                  disabled
                  value={result?.breakupval?.gcaa}
                  onChange={handelChange}
                />
                 <i className="lightGreen">$</i>
                </div>
                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcr"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcr}
                  onChange={handelChange}
                />

                 </div>
                 <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcra"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcra}
                  onChange={handelChange}
                />
                <i className="lightGreen">$</i>
                </div>
                  </div>

                 </div>
                 </div>


                 <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gct"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gct}
                  onChange={handelChange}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcta"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcta}
                  onChange={handelChange}
                />
                 <i className="lightGreen">$</i>
                </div>

                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcs"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcs}
                  onChange={handelChange}
                />

                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcsa"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcsa}
                  onChange={handelChange}
                />
                <i className="lightGreen">$</i>


</div>
                  </div>
                  </div>
                  </div>



                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Total Claims{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="total"
                  placeholder=""
                  value={TotalAll}
                  disabled
                />

                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Total Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="totala"
                  placeholder=""
                  value={TotalAll2}
                  disabled
                />
                <i className="lightGreen">$</i>

                </div>
                  </div>

                  </div>
                  </div>

              </div>
            </Panel>
            <div className="row mt-4"></div>


           








            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="gcp"
                  placeholder=""
                  disabled
                  onChange={handelChange}
                  value={result?.breakupval?.gcp}
                />
              </div>
              

            </div> */}
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="gca"
                  placeholder=""
                  disabled
                  value={result?.breakupval?.gca}
                  onChange={handelChange}
                />
              </div>
            </div> */}

            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="gcr"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcr}
                  onChange={handelChange}
                />
              </div>
            </div> */}

            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="gct"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gct}
                  onChange={handelChange}
                />
              </div>
            </div> */}


            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="gcs"
                  disabled
                  placeholder=""
                  value={result?.breakupval?.gcs}
                  onChange={handelChange}
                />
              </div>
            </div> */}
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="total"
                  placeholder=""
                  value={TotalAll}
                  disabled
                />
              </div>
            </div> */}
            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div> */}


            <div class="col-sm-3 col-lg-3"></div>
            <Panel defaultExpanded={true} header="Remarks" collapsible={true} >

            <div class="col-sm-12 col-lg-12 mt-4">
              <div class="form-group mb-2">
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  name="GeneralOpen"
                  placeholder="Enter Remarks Here"
                  class="form-control inputField"
                  value={result?.GeneralOpen}
                  onChange={handelChange}
                ></textarea>

                {/* <input
                  class="form-control inputField"
                  type="text"
                  name="GeneralOpen"
                  placeholder=""
                  value={result?.GeneralOpen}
                  onChange={handelChange}
                /> */}
              </div>
            </div>
            </Panel>
            <div className="mt-4"></div>

            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Insurance Correspondence Recieved
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="insurance"
                  placeholder=""
                  value={result?.insurance}
                  onChange={handelChange}
                />
              </div>
            </div> */}

            {/* <div class="col-sm-6 col-lg-3 d-flex ">
              <div class="form-group mb-2 ">
                <label class="inputLabel" style={{ color: "black" }}>
                  Action Taken{" "}
                </label>
                <div class="row ml-1">
                  <div
                    class="form-check form-check-inline "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      alignContent: "center",
                    }}
                  >
                    <input
                      class="form-check-input mr-2  mb-2"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="Yes"
                      name="stoken"
                      checked={result?.stoken == "Yes" ? true : false}
                      onChange={handelChange}
                    />
                    <label class="mr-4" for="inlineCheckbox1">
                      Yes{" "}
                    </label>
                    <input
                      class="form-check-input mr-2  mb-2"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="No"
                      name="stoken"
                      checked={result?.stoken == "No" ? true : false}
                      onChange={handelChange}
                    />
                    <label class="mr-4" for="inlineCheckbox2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {result?.stoken == "Yes" ? (
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Remarks
                  </label>

                  <input
                    class="form-control inputField"
                    type="text"
                    name="comments"
                    placeholder=""
                    onChange={handelChange}
                    value={result?.comments}
                  />
                </div>
              </div>
            ) : null} */}

            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Attachments
              </h4>
            </div> */}
            <div class="col-sm-12 col-lg-12">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label> */}
                {/* <ImageUploaderr
                  openImageViwerModel={openImageViwerModel}
                  handleInputChange={handleInputChange}
                  image={result?.image}
                /> */}
                
 <FileUpload2


onRemove={onRemove}
setobjCLuster={setobjCLuster}
objectcluster={objectcluster}
helpingState={helpingState}
  onPreview={onPreview}
  fileList={FileList}
  openImageViwerModel={openImageViwerModel}
  handleInputChange={handleInputChange}
  image={result?.image}
/>
              </div>
            </div>

            <div class="col-sm-12 col-lg-12 mt-2">
              <div class="form-group ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    // style={{
                    //   backgroundColor: "white",
                    //   color: "#40E0D0",
                    //   borderRadius: "10px",
                    //   fontSize:18,
                    //   height: "3rem",
                    //   width: "6rem",
                    //   border: "2px solid ",
                    // }}
                    className="btnClear mr-1 "
                    onClick={onclear}
                  >
                    Clear
                  </button>
                  <button type="button" onClick={handleTaosterSpeak} className="btnSave">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralPayers;
