import React,{useState}from "react";
import { Input } from "rsuite";
import { Button } from "rsuite";
import UserAuditTable from "../../../Audit/userTaskViewTable";
import {DatePicker} from 'rsuite'
import Select from "react-select";
import AuditTabMenus from "./AuditTabs";
import OverAllAuditField from "./OverAllAuditField";



export default function PopupRemarksAuditCharge({sethelpingObj,helpingObject,setAuditPop,popAudit, setState,state,DataDos,flagIndi}) {
 
   
  const handleChangeC = (e,event) => {
   
    let value = e;
    setState((prevState) => ({
      ...prevState,
      claim: value,
    }));
  };
  const handleChangeE = (e,event) => {
    let value=e
   
    
    setState((prevState) => ({
      ...prevState,
      ErrorFound: value,
    }));
  };
 
  const handleChangeT = (value) => {
   
    
   
    
    setState((prevState) => ({
      ...prevState,
      ErrorType: value.value,
    }));
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  const handleChangeAudit = (e,event) => {
    let value=e
   
    
    setState((prevState) => ({
      ...prevState,
      Remarks: value,
    }));
  };
  const handleEditState=(rowData)=>{
    setState((prev)=>({...prev,claim:rowData.claim,ErrorFound:rowData.ErrorFound,ErrorType:rowData.ErrorType,Remarks:rowData.Remarks,gridEdit:true,rowid:rowData.id}))
    handleDelete(rowData)
  }
  const handleDelete=(rowData)=>{
   
    const updatedArray = state.holdAllGridArrayValue.filter((item) => item.id !== rowData.id);
    setState((prev)=>({...prev,holdAllGridArrayValue:updatedArray}))
   

  }

  const DosWiseTable = [
   
    {
      colName: `Claim #`,
      flexGrow: 1.9,
      dataKey: "claim",
      rowSpan: 1,
    },
    {
      colName: `Error Found`,
      flexGrow: 1.9,
      dataKey: "ErrorFound",
      rowSpan: 1,
    },
    {
      colName: `Error Type`,
      flexGrow: 1.9,
      dataKey: "ErrorType",
      rowSpan: 1,
    },
    {
      colName: "Remarks",
      flexGrow: 1.6,
      dataKey: "Remarks",
      rowSpan: 1,
   
    },
    {
        colName: "Action",
        flexGrow: 1.6,
        dataKey: "id",
        rowSpan: 1,
        actions: [
         { label: "E",
          onClick: handleEditState,
           btnType: "Edit" },
          { label: "D", 
          onClick: handleDelete,
           btnType: "Delete" },
          //  { label: "D", 
          // // onClick: handleDelete,
          //   btnType: "Bell" },
        ],
        
        editRow: true,
      }
    
  ];


console.log(state,'state',flagIndi,'helpingObject')

  const AddRemarksToArray=()=>{
    let obj={
        id:state.gridEdit?state.rowid:state.holdAllGridArrayValue.length + 1,
        claim:state?.claim,
        ErrorFound:state.ErrorFound,
        ErrorType:state.ErrorType,
        Remarks:state.Remarks

    }
    setState((prevDosArray) => {
        if (Array.isArray(prevDosArray.holdAllGridArrayValue)) {
          return {
            ...prevDosArray,
            holdAllGridArrayValue: [...prevDosArray.holdAllGridArrayValue, obj],
            gridEdit:false
          };
        } else {
          return {
            ...prevDosArray,
            holdAllGridArrayValue: [obj],
            gridEdit:false

          };
        }
      });
    setState((prev)=>({...prev,claim:"",ErrorFound:"",ErrorType:"",Remarks:""}))


  }
  const getRequriedPara=()=>{

  }



  return (
    
    <div class="row">
      

      <div className="col-lg-12 mb-2 text-center"></div>

      <div class="col-sm-6 col-lg-3"></div>
      <div class="col-sm-6 col-lg-3"></div>
      <div class="col-sm-6 col-lg-3"></div>
      <div className="col-lg-12  ">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
           
          }}
        >
          <AuditTabMenus helpingObject={flagIndi} sethelpingObj={sethelpingObj} />
        </h4>
      </div>
      
      

    {helpingObject.tabName=='ClaimLevel'?<>
      <div class="col-sm-3 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
           {flagIndi=='vob'|| flagIndi=='Auth' ?'Patient Account #': 'Claim #'}
          </label>

          <Input
            class="form-control inputField"
            type="text"
            name="claim"
            placeholder=""
            value={state.claim}
            onChange={(event)=>handleChangeC(event)}
          />
        </div>
      </div>
      <div class="col-sm-3 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Error Found
          </label>

          <Input
            class="form-control inputField"
            type="text"
            name="ErrorFound"
            placeholder=""
            value={state.ErrorFound}
            onChange={handleChangeE}
          />
        </div>
      </div>
      <div class="col-sm-4 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Error Type
          </label>
          <Select
          options={[{value:'Critical',label:'Critical'},{value:'Non-Critical',label:'Non-Critical'},{value:'Nill',label:'Nill'}]}
          placeholder="Select an option"
          onChange={handleChangeT}
          value={{ label: state.ErrorType, value: state.ErrorType }}
          styles={customStyles}
        />

          {/* <Input
            class="form-control inputField"
            type="text"
            name="ErrorType"
            placeholder=""
            value={state.ErrorType}
            onChange={handleChangeT}
          /> */}
        </div>
      </div>
      {/* <div class="col-sm-3 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Error Type
          </label>

          <Input
            class="form-control inputField"
            type="text"
            name="ErrorType"
            placeholder=""
            value={state.ErrorType}
            onChange={handleChangeT}
          />
        </div>
      </div> */}
      <div class="col-sm-6 col-lg-12">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Remarks
          </label>

          <Input
            class="form-control inputField"
            as="textarea"
            name="Remarks"
            placeholder=""
            value={state.Remarks}
            onChange={handleChangeAudit}
          />
        </div>
      </div>
      <div class="form-group mb-2 "><div class="d-flex justify-content-end mt-4"><button type="button" class="btnClear mr-1 " onClick={AddRemarksToArray}>{state.gridEdit?'Update Remarks':'Add Remarks'}</button></div></div>
      <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Audit Remarks Table
        </h4>
      </div>
      <UserAuditTable
       getRequriedPara={getRequriedPara}
              rowHeight={48}
             
              
              dateForgrid={state.holdAllGridArrayValue?state.holdAllGridArrayValue:[]}
              // isPopupOpen={isPopupOpen}
              Array={DosWiseTable}
              rowShown={5}
              loader={false}
            />
    </>:<OverAllAuditField setAuditPop={setAuditPop} popAudit={popAudit} DataDos={DataDos} flagIndi={flagIndi} state={state} />}
     
    </div>
  );
}
