import React from "react";
import GraphTab from "./graphTab";
import Skeletion from "./Skeletion";

export default function StaticsCard2({
  isVob,
  stateChild,
  handleGraphSwitch,
  data,
  label,
  icon,
  disableAmount,
  graphComponent,
  graphComponent2,
}) {
  return (
    <div className=" tw-flex-auto tw-w-full tw-lg:pr-4 tw-rtl:pr-0 tw-rtl:pl-4 tw-md:pr-4">
      <div class="tw-flex tw-relative tw-flex-col tw-mb-8  tw-overflow-hidden tw-bg-white  tw-shadow tw-dark-bg-dark-card">
        <div className="tw-grid tw-grid-cols-12 tw-bg-[#0e7490] tw-items-center tw-p-1">
          <div className="tw-col-span-6 tw-ml-2 ">
            <div className="tw-flex ">
              <h4 className="tw-text-gray-700  tw-font-bold tw-flex ">
                {icon}
                <span className="tw-ml-2  tw-text-white">{label}</span>
              </h4>

            </div>
          </div>
          <div className="tw-col-span-6  ">
            <GraphTab
            isVob={isVob}
              stateChild={stateChild}
              handleGraphSwitch={handleGraphSwitch}
            />
          </div>
        </div>
        {stateChild?.loader? <Skeletion /> : <div className="tw-grid tw-grid-cols-12  tw-mt-2 ">
          <div class="tw-mb-4 tw-col-span-12 tw-ml-16">
            {" "}
            {stateChild?.graphSwitchState == "1"
              ? graphComponent
              : graphComponent2}
          </div>
          <div className="tw-col-span-6  tw-bg-[#C3DCE3] ">
            <h4 className="mt-2 tw-text-[#0e7490] tw-ml-16">
              Worked Claims : {data?.workedClaim}
            </h4>
          </div>
          <div className="tw-col-span-6 tw-bg-[#C3DCE3] ">
            {!disableAmount && (
              <>
                {" "}
                <h4 className="mt-2 tw-text-[#0e7490]">
                  Worked Amount $ {data?.workedAmount && data?.workedAmount?.toFixed(2)}
                </h4>
              </>
            )}
          </div>
        </div>}

       
      </div>
    </div>
  );
}
