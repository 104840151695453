import React from 'react'
import { Modal, ButtonToolbar, Button, Placeholder } from 'rsuite';
import UserAuditTable from './userTaskViewTable';

export default function SmallModel({row,handleClose,data}) {
  console.log(data,'there is data')

    const getRequriedPara=()=>{

    }
    const thresholdCol = [
      
      {
        colName: "Practice Name",
        flexGrow: 1.1,
        dataKey: "pname",
      },
        
        { colName: "Portals", flexGrow: 1.1, dataKey: "thresh_portal" },
        {
          colName: "Call",
          flexGrow: 1.1,
          dataKey: "thresh_eligibility",
        },
    
        {
          colName: "Authorization",
          flexGrow: 1.1,
          dataKey: "thresh_authorization",
        },
        {
          colName: "Rejections",
          flexGrow: 1.1,
          dataKey: "thresh_rejections",
        },
        {
          colName: "Manual",
          flexGrow: 1.1,
          dataKey: "thresh_posting",
        },
        {
          colName: "Electronically",
          flexGrow: 1.1,
          dataKey: "thresh_posting_electronically",
        },
    
        {
          colName: "Denials",
          flexGrow: 1.1,
          dataKey: "thresh_denials",
        },
    
        {
          colName: "General Payer",
          flexGrow: 1.1,
          dataKey: "claims_per_hours",
        },
        {
          colName: "Special Payer",
          flexGrow: 1.1,
          dataKey: "claims_review_per_hours",
        }
       
       
      ];
      
  return (
    <Modal size={'xl'} open={true} onClose={()=>handleClose(false)}>
        <Modal.Header>
          <Modal.Title>Average Per Hour Record On Docuhub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <UserAuditTable
           
           
           rowShown={5}
           getRequriedPara={getRequriedPara}
           isShowExportButton={true}
           dateForgrid={data?data:[]}
           Array={
            thresholdCol
            
           }
           isPaginationShow={true}
          
           loader={false}
           reportName={"PracticeWise"}
         />


        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>handleClose(false)} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={()=>handleClose(false)} className='btn btn-primary'>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
