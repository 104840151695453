import React from "react";
import ReactDropdown from "react-dropdown";
import AccVerify from "./AccRec/AccRecVerify";
import Reports from "./ChargeEntry/Reports";
import Meeting from "./ChargeEntry/Meeting";

const AccountRecievable = ({ handleFormTypeAndPract,products,handleSubCheckBoxChargeEntry,subCheckBox,formTypeNoForApi,date,formType,practiceId,record,onRecordUpdateOrCreated}) => {
  return (<>
    {subCheckBox == "AccountRecVerify"  ? (
      <AccVerify products={products} handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry} handleFormTypeAndPract={handleFormTypeAndPract}  onRecordUpdateOrCreated={onRecordUpdateOrCreated} record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) :subCheckBox == "ReportsDeniedAC" ? (
      <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ): subCheckBox == "MeetingDeniedAC" ? (
      <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    ) :   null}
  </>
    
  );
};

export default AccountRecievable;
