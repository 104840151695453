
import React,{useState,useEffect} from 'react'
import StaticsCard2 from '../../graphCard/staticsCard2'
import LineGraph from '../../graphCard/graphType/LineGraph'
import DocPassIcon from "@rsuite/icons/DocPass";
import { useSelector } from "react-redux";
import moment from "moment";
import { chargeEntryGraph } from '../../../../Utils/ApiManager';
import MountainGraph from '../../graphCard/graphType/MountainGraph';
import DoughnutChart from '../../graphCard/graphType/Doughnut';
import IdInfoIcon from '@rsuite/icons/IdInfo';


export default function VobsDashboard({state,setState}) {


  const { onLoginSignOff } = useSelector((state) => state);
  let obj = {
    loader: false,
    graphSwitchState: "1",
  };

  const [stateChild, setStateChild] = useState(obj);
  useEffect(() => {
    getChargeEntryData();
  }, [state.ActiveTab]);

  const comparsionDate = (startDate, endDate) => {
    const dates = [];

    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "days");
    }

    return dates;
  };
  const getChargeEntryData = () => {
    setStateChild((prev) => ({
      ...prev,
      loader: true,
    }));
    let dateArr = [];
    const currentDate = moment();
    const oneDayBehind = currentDate.subtract(1, "days");
    const formattedDate = oneDayBehind.format("YYYY-MM-DD");
    if (state.ActiveTab == "1") {
      dateArr = [];
      dateArr.push(formattedDate);
    }
    if (state.ActiveTab == "3") {
      let startDate = oneDayBehind.subtract(2, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    if (state.ActiveTab == "7") {
      let startDate = oneDayBehind.subtract(6, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    if (state.ActiveTab == "30") {
      let startDate = oneDayBehind.subtract(29, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    let obj = {
      formtypee: "4",
      userids: onLoginSignOff?.users?.map((item) => item.userid).join(","),
      dates: dateArr,
    };
    chargeEntryGraph(obj)
      .then((res) => {
        
       
        if (res && res.data) {
          let inputData = res.data?.totalUsers;

          const outputData = {
            totalWorkedAmount:
              inputData &&
              inputData.map((item) => item.totalWorkedAmount?item.totalWorkedAmount.toString():0),
            WorkedClaim: inputData.map((item) => item.WorkedClaim?item.WorkedClaim.toString():0),
            totalUserId: inputData.map((item) =>item.totalUserId?item.totalUserId.toString():0),
          };
          setState((prev) => ({
            ...prev,
            VobData: outputData,
            ChargeLabels: dateArr,
          }));
          setStateChild((prev) => ({
            ...prev,
            loader: false,
          }));
        }
      })
      .catch((err) => {
        setStateChild((prev) => ({
          ...prev,
          loader: false,
        }));
      });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  let labels = state?.ChargeLabels;

  let data = {
    labels,
    datasets: [
      {
        label: "Users",
        data: state?.VobData.totalUserId,
        borderColor: "rgb(216, 166, 166)",
        backgroundColor: "rgb(216, 166, 166)",
      },
      {
        label: "Worked Claims",
        data: state?.VobData.WorkedClaim,
        borderColor: "rgb(193, 39, 45)",
        backgroundColor: "rgb(193, 39, 45)",
      },
     
    ],
    
  };

  let data2 = {
    labels,
    datasets: [
     
      {
        fill:true,
        label: "Worked Amount",
        data: state?.VobData.totalWorkedAmount,
        borderColor: "rgb(14, 116, 144)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
    
  };

  const data3 = {
    labels: [`Worked Claim(${labels})`, `Users(${labels})`,],
    datasets: [
      {
        label: "",
        data: [
          state?.VobData.WorkedClaim,
          state?.VobData.totalUserId,
        ],
        backgroundColor: [
          "rgb(193, 39, 45)",

          "rgb(216, 166, 166)",
        ],
        borderColor: ["rgb(193, 39, 45)", "rgb(216, 166, 166)"],
        borderWidth: 1,
      },
    ],
  };
  const data4 = {
    labels: [`Worked Amount(${labels})`, `Worked Claims(${labels})`,],
    datasets: [
      {
        label: "",
        data: [
          state?.VobData.totalWorkedAmount,
          state?.VobData.WorkedClaim,
          
        ],
        backgroundColor: [
          "rgb(193, 39, 45)",

          "rgb(216, 166, 166)",
        ],
        borderColor: ["rgb(193, 39, 45)", "rgb(216, 166, 166)"],
        borderWidth: 1,
      },
    ],
  };
  const handleGraphSwitch = (arg) => {
    setStateChild((prev) => ({
      ...prev,
      graphSwitchState: arg,
    }));
  };
  return (
    <div class="flex flex-wrap mt-6 mt-2  ml-4 tw-px-2 tw-py-2 tw-mx-auto ">
      {false ? (
       <></>
      ):<StaticsCard2
      isVob={true}
      handleGraphSwitch={handleGraphSwitch}
     
        disableAmount={true}
        data={state?.AllRecord?.["eligibilty/vob"]}
        label={"Eligibility/VOB`s"}
        icon={<IdInfoIcon style={{ color: "white" }} />}
        graphComponent={state?.ActiveTab == "1" ? (
          <DoughnutChart options={options}  data={data3} />
        ) :<LineGraph options={options} data={data} />}
        graphComponent2={state?.ActiveTab=="1"?<DoughnutChart options={options}  data={data4} />:<MountainGraph options={options} data={data2} />}
       stateChild={stateChild}
      />}
    
   </div>
  )
}
