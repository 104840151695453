import React from 'react'

export default function Skeletion() {
  return (
    <div class="tw-flex tw-flex-col tw-bg-neutral-300 tw-w-90 tw-h-100 tw-animate-pulse tw-rounded-xl tw-p-4 tw-gap-4">
    <div class="tw-bg-neutral-400/50 tw-w-full tw-h-32 tw-animate-pulse tw-rounded-md"></div>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <div class="tw-bg-neutral-400/50 tw-w-full tw-h-4 tw-animate-pulse tw-rounded-md"></div>
      <div class="tw-bg-neutral-400/50 tw-w-4/5 tw-h-4 tw-animate-pulse tw-rounded-md"></div>
      <div class="tw-bg-neutral-400/50 tw-w-full tw-h-4 tw-animate-pulse tw-rounded-md"></div>
      <div class="tw-bg-neutral-400/50 tw-w-2/4 tw-h-4 tw-animate-pulse tw-rounded-md"></div>
    </div>
  </div>
  )
}
