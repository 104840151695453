import React, { useState, useEffect, useRef } from "react";
import Multiselect from "multiselect-react-dropdown";

import Select from "react-select";
import moment from "moment/moment";
import { CheckPicker, Panel } from "rsuite";
import { DateRangePicker } from "rsuite";
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import {
  ButtonGroup,
  Button,
  Whisper,
  Popover,
  Dropdown,
  IconButton,
} from "rsuite";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";

import { Schema } from "rsuite";
import {
  practIdies,
  practName,
  userLists,
  userNames,
} from "../../Actions/Actions";
import { Checkbox } from "rsuite";
import {
  fetchProduct,
  fetchTlRecord,
  getAccRecordByUserid,
  getPracticeByTeams,
  getTeamsInfo,
  getThresholdFordocuhub,
  getTlAudit,
  getTluserwiseAudit,
  getUsersByTeams,
  getUsersByTeamsRoleBased,
  getUsersName,
  userIdess,
  userPracticeWiseFilter,
} from "../../Utils/ApiManager";
import AuditTable from "./AuditTable";
import PracticeView from "./PractiveViewComponent";
import { useSelector, useDispatch } from "react-redux";

import UserAuditTable from "./userTaskViewTable";
import { createValidationSchema } from "../../Common/Validation";
import { toast } from "react-toastify";
import { pipeline } from "stream";
import HtmlToPdfConverter from "./HtmltopdfConvertor";
import SmallModel from "./smallModel";

function AuditField({
  setProducts,
  date,
  handleFormVal,
  products,
  startDatee,
  endDate,
  formType,
  formTypeString,
}) {
  let errorobj = {
    pidies: false,
    uidies: false,
    tuidies: false,
  };
  let dispatch = useDispatch();

  const { onLoginSignOff, getPracticesAgianstTeams } = useSelector(
    (state) => state
  );
  const [errorModel, setErrorModel] = useState(errorobj);
  const { StringType, NumberType, DateType } = Schema.Types;

  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
  const [userList, setUserList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pracPopup, setOpenPracticeViewPop] = useState(false);
  const [dateForgrid, setDataForGrid] = useState(null);
  const [practiceWiseID, setuseiridPracticeView] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [tabelSizee, settabelSizee] = useState(false);

  let payload = {
    pidies: "",
    uidies: "",
    tuidies: "",
    formtype: "audit",
    sdate: null,
    edate: null,
    auditType: "OverAll Verifications",
  };

  let helpObj = {
    getAllDefaultTeams: [],
    practicesSelection: [],
    userSelection: [],
    auditParamType: ["Worked Claim"],
    auditParamTypeCopy: ["Worked Claim"],
    AllUserChecked: false,
    AllAuditTypeCheck: false,
    headerName: "Worked",
    AllPracticeCheck: false,
    TeamsInfo: [],
    TeamsInfoId: [],
    TeamsAllCheck: false,
    isThresholdShow: false,
    activePage: 1,
    rowsToExport: [],
    thresholdRecord: [],
    AllUserList: [],
    userListForExport: [],
    action: 0,
    loading: false,
    rowsToExportThreshold: [],
    practiceWisePopType: null,
    ThresActivePage: 1,
    userListWithTeam: [],
    sdateReport: null,
    edateReport: null,
    pidiesForReport: "",
    LoggedUserPractices: "",
    Limit: 5,
    ThresLimit: 5,
    rowThresPop: null,
    isThressmallModelOpen: false,
    expandButtonType: [],
    tableSizeThres: false,
    comparingTeamList: [],
    thresDocuhubRec: [],
    daysDifference: null,
    ShiftType:
      onLoginSignOff?.shift == "3"
        ? {
            label: "Both",
            value: "3",
          }
        : onLoginSignOff?.shift == "2"
        ? {
            label: "Night Shift",
            value: "2",
          }
        : {
            label: "Morning Shift",
            value: "1",
          },
  };
  const [helpingState, sethelpingState] = useState(helpObj);
  let footerObj = {
    isAllow: true,
    total_charges: 0,
    total_Rejections: 0,
    total_Auth: 0,
    total_Vobs: 0,
    total_Eobs: 0,
    total_Era: 0,
    total_Arr: 0,
    totalPatStat: 0,
    Total: 0,
  };
  const [Footer, setFooterState] = useState(footerObj);

  let footerObjCombine = {
    isAllow: true,
    total_charges: 0,
    total_charges_amount: 0,
    total_Rejections: 0,
    total_Rejections_amount: 0,
    total_Auth: 0,
    total_Auth_amount: 0,
    total_Vobs: 0,
    total_Eobs: 0,
    total_Eobs_amount: 0,
    total_Era: 0,
    total_Era_amount: 0,
    total_Arr: 0,
    total_Arr_amount: 0,
    totalPatStat: 0,
    totalPatStat_amount: 0,
  };
  const [FooterCombine, setFooterStateCombine] = useState(footerObjCombine);

  let footerObjAmount = {
    isAllow: true,

    total_charges_amount: 0,

    total_Rejections_amount: 0,

    total_Auth_amount: 0,

    total_Eobs_amount: 0,

    total_Era_amount: 0,

    total_Arr_amount: 0,

    totalPatStat_amount: 0,
  };
  const [FooterAmount, setFooterStateAmount] = useState(footerObjAmount);

  useEffect(() => {
    if (products.length > 0) {
      sethelpingState((prev) => ({ ...prev, LoggedUserPractices: products }));
    }
  }, [products]);

  const [state, setState] = useState(payload);
  useEffect(() => {
    if (startDatee) {
      setState((prevState) => ({
        ...prevState,
        sdate: startDatee,
      }));
    }
    if (endDate) {
      setState((prevState) => ({
        ...prevState,
        edate: endDate,
      }));
    }
    if (formType) {
      setState((prevState) => ({
        ...prevState,
        tuidies: formType,
      }));
    }
  }, [startDatee, endDate, formType]);
  const UserWisePracticeFilterFunc = (arg) => {
    const formData = new FormData();
    formData.append("user_practices", arg);
    userPracticeWiseFilter(formData).then((res) => {
      let pratices =
        res?.data &&
        res?.data.map((el) => ({
          label: el.pname,
          value: el.id,
        }));
      setProducts(pratices);

      // setState((prev)=>({...prev,pidies:pulist}))
    });
  };
  const sizee = (arg) => {
    if (arg == "Thres") {
      sethelpingState((prev) => ({
        ...prev,
        tableSizeThres: !helpingState.tableSizeThres,
      }));
    } else {
      settabelSizee((prev) => !prev);
    }
  };
  console.log("expandButtonType".helpingState?.expandButtonType);
  const TeamWisePractice = (arg) => {
    let data;
    if (arg[0]?.label) {
      // Data is in the format of dataInObjects, so extract the 'value' property
      data = arg.map((item) => item.value).join(",");
    } else {
      data = arg.join(",");
    }

    const formData = new FormData();
    formData.append("team_practices", data);

    getPracticeByTeams(formData)
      .then((res) => {
        let specidfiedProducts =
          res?.data &&
          res?.data.map((el) => ({
            label: el.pname,
            value: el.id,
          }));

        const product = helpingState.LoggedUserPractices.map((bigoItem) => {
          const matchedUser = specidfiedProducts.find(
            (user) => user.value == bigoItem.value
          );

          if (matchedUser) {
            return bigoItem;
          }
        });

        let arr = product.filter((item) => item !== null && item !== undefined);

        setProducts(arr);
      })
      .catch((err) => {});
  };
  const loggedPractices = async () => {
    let Products = await fetchProducts();

    setProducts(Products);
  };

  useEffect(() => {
    if (
      helpingState?.TeamsInfoId.length == 0 ||
      (helpingState?.TeamsInfoId.length == helpingState?.TeamsInfo.length &&
        helpingState?.userListWithTeam.length > 0)
    ) {
      if (
        helpingState?.TeamsInfoId.length == 0 ||
        helpingState?.TeamsInfoId.length == helpingState?.TeamsInfo.length
      ) {
        if (
          helpingState?.TeamsInfoId.length == helpingState?.TeamsInfo.length
        ) {
          TeamWisePractice(helpingState?.TeamsInfo);
        }
        if (helpingState?.TeamsInfoId.length == 0) {
          loggedPractices();
        }

        // userList set to normal
        getUserNames();
      } else {
        //call Api of Team Practuices
        // TeamWisePractice(helpingState?.TeamsInfoId);

        getUsersByTeams(helpingState?.TeamsInfoId.join(","))
          .then((res) => {
            let userListss =
              res &&
              res.data &&
              res.data.map((item) => ({
                value: item.id,
                label: `${item.name}`,
              }));
              //major change
              const userids = onLoginSignOff.users.map((el) => el.userid);
              const joinedUserIdsN = userids.join(",");

            let indicesArray = joinedUserIdsN.split(",").map(Number);

            let filteredArr =
              userListss &&
              userListss.filter((obj) =>
                indicesArray.includes(Number(obj.value))
              );

            let AllUserInfo = mapValues(
              helpingState.userListWithTeam,
              filteredArr,
              helpingState?.ShiftType?.value
            );

            setUserList(AllUserInfo);
          })
          .catch((err) => {});
        // get users id and compare with logged user id
        //update userList
      }
    }
  }, [
    helpingState?.TeamsInfoId,
    helpingState?.userListWithTeam,
    helpingState?.ShiftType?.value,
  ]);

  const mapValues = (arr, arr1, shiftType) => {
    if (shiftType == "3") {
      return (
        arr1 &&
        arr1.map((item) => {
          const matchingObj = arr && arr.find((obj) => obj.id === item.value);
          return matchingObj
            ? { ...item, ...matchingObj }
            : {
                ...item,
                team: null,
                team_label: null,
                m_team: null,
                shift: null,
                employee_id: null,
                teamName: null,
              };
        })
      );
    } else {
      return (
        arr1 &&
        arr1
          .map((item) => {
            const matchingObj = arr.find(
              (obj) => obj.id === item.value && obj.shift === shiftType
            );
            return matchingObj ? { ...item, ...matchingObj } : null; // Skip objects where conditions are not met
          })
          .filter(Boolean)
      ); // Filter out null values
    }
  };

  // useEffect(() => {
  //   setDataForGrid(null);
  // }, [dateForgrid]);
  useEffect(() => {
    if (formTypeString == "UserWise Productivity") {
      setState((prevState) => ({
        ...prevState,
        tuidies: "",
      }));
    }
  }, []);

  useEffect(() => {
    getTeamsInfoFunc();
  }, []);

  const getTeamsInfoFunc = async () => {
    let formData = new FormData();
    formData.append("get_user", onLoginSignOff.userid);

    try {
      const res = await getUsersByTeamsRoleBased(formData);

      let resAllTeam = await getTeamsInfo(formData);

      if (resAllTeam.data) {
        sethelpingState((prev) => ({
          ...prev,
          getAllDefaultTeams: resAllTeam.data,
        }));
        let allTeamsData = await Promise.all(
          resAllTeam.data.map(async (team) => {
            const userData = await getUsersByTeams(team.id);

            return userData.data.map((user) => ({
              ...user,
              teamName: team.name,
            }));
          })
        );
        console.log("allTeamsData ok bro", allTeamsData);

        const combinedUserData = allTeamsData.flat();

        sethelpingState((prev) => ({
          ...prev,
          userListWithTeam: combinedUserData,
        }));

        let vpTeams = [];

        vpTeams = res.data.vpteams;
        vpTeams.push(res.data.team);

        let DataTeam = vpTeams.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        const uniqueArray = Array.from(
          new Set(DataTeam && DataTeam.map(JSON.stringify))
        ).map(JSON.parse);
        const removeTeam = [
          "Medifusion",
          "CRM",
          "Credentialing Team",
          "Coding Team",
        ];
        const filteredArray =
          uniqueArray &&
          uniqueArray.filter((team) => !removeTeam.includes(team.value));

        sethelpingState((prev) => ({ ...prev, TeamsInfo: filteredArray }));
      }
    } catch (err) {
      // Handle the error here
    }
  };

  useEffect(() => {
    if (helpingState?.userListWithTeam.length > 0) {
      getUserNames();
    }
  }, [helpingState?.userListWithTeam.helpingState?.ShiftType?.value]);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);

    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));

      const allOption = { id: "selectAll", label: "Select All Practices" };

      return [...optionList];
    }
  };

  const getUserNames = async () => {
    let Products = await fetchProducts();
    const formDatag = new FormData();
    let pulist = Products.filter((item) => !item?.id) // Filter out items without an 'id'
      .map((item) => item?.value) // Extract 'value' property
      .join(",");
    formDatag.append("pidies", pulist);
    let res = await userIdess(formDatag);

    let userListss =
      res &&
      res.data &&
      res.data.map((item) => ({
        value: item.id,
        label: `${item.fname} ${item.lname}`,
      }));
    const userids = onLoginSignOff.users.map((el) => el.userid);
    const joinedUserIds = userids.join(",");

    let indicesArray = joinedUserIds.split(",").map(Number);

    // Those Filter Things

    let filteredArr = userListss.filter((obj) =>
      indicesArray.includes(Number(obj.value))
    );
    const allOptionUser = { id: "selectAll", label: "Select All Users" };
    let AllUserInfo = mapValues(
      helpingState.userListWithTeam,
      filteredArr,
      helpingState?.ShiftType?.value
    );

    if (
      helpingState?.ShiftType?.value == "1" ||
      helpingState?.ShiftType?.value == "2"
    ) {
      let listUsers =
        AllUserInfo && AllUserInfo.map((item) => item.value).join(",");

      let a = listUsers.split(",");

      setState((prevState) => ({
        ...prevState,
        uidies: a,
      }));
    }

    setUserList(AllUserInfo);

    sethelpingState((prev) => ({ ...prev, AllUserList: filteredArr }));
  };

  let objCss = {
    chips: {
      // To change css chips(Selected options)
      background: "rgb(14, 116, 144)",
      padding: "4px 24px",
    },
    option: {
      // To change css for dropdown options
      backgroundColor: "rgb(14, 116, 144) !important",
    },

    highlightOption: {
      background: "rgb(14, 116, 144) !important",
      color: "#fff",
    },
    searchWrapper: {
      // To change css for multiselect (Width,height,etc..)
      maxHeight: "100px", // Set a maximum height for the container
      overflowY: "auto", // Add a vertical scrollbar when needed
    },
    //   optionContainer: { // To change css for option container
    //     border: '2px solid',
    //     color: 'rgb(14, 116, 144)'
    //   }
  };

  const handleAllUser = (event, checked) => {
    let pulist;

    if (checked) {
      sethelpingState((prev) => ({
        ...prev,
        userSelection: userList,
        AllUserChecked: true,
      }));

      pulist = userList && userList.map((item) => item.value).join(",");

      let a = pulist.split(",");

      setState((prevState) => ({
        ...prevState,
        uidies: a,
      }));
      sethelpingState((prev) => ({ ...prev, userListForExport: a }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        userSelection: [],
        AllUserChecked: false,
        userListForExport: [],
      }));
      setState((prevState) => ({
        ...prevState,
        uidies: [],
      }));
    }
  };
  useEffect(() => {
    if (state?.uidies.length > 0) {
      let pulist = state?.uidies.map((item) => item).join(",");

      UserWisePracticeFilterFunc(pulist);
    } else {
      loggedPractices();
    }
  }, [state?.uidies]);

  const onSelectUser = (selectedOption, a) => {
    let ass;
    let pulist;
    if (selectedOption.length == 0) {
      pulist = [];
      sethelpingState((prev) => ({
        ...prev,
        AllUserChecked: false,
        userListForExport: pulist,
      }));
      setState((prevState) => ({
        ...prevState,
        uidies: pulist,
      }));
    } else {
      pulist = selectedOption.map((item) => item).join(",");

      ass = pulist.split(",");
      setState((prevState) => ({
        ...prevState,
        uidies: ass,
      }));
      sethelpingState((prev) => ({ ...prev, userListForExport: ass }));
    }

    dispatch(userLists(pulist));
    dispatch(userNames(JSON.stringify(selectedOption)));
  };

  const comparewithRedux = (pulist) => {
    let pulistArray = pulist && pulist.split(",");

    let outputSet = new Set();

    getPracticesAgianstTeams.forEach((item) => {
      if (pulistArray.includes(item.id)) {
        outputSet.add(item.team);
      }
    });

    let output = Array.from(outputSet);

    let newArr = helpingState?.getAllDefaultTeams.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    const removeTeam = [
      "Medifusion",
      "CRM",
      "Credentialing Team",
      "Coding Team",
    ];
    const filteredArray =
      newArr && newArr.filter((team) => !removeTeam.includes(team.value));

    let matchingArray = filteredArray.filter((item) =>
      output.includes(item.value)
    );
    sethelpingState((prev) => ({
      ...prev,
      TeamsInfo: matchingArray,
    }));
  };

  const onSelectPractice = async (selectedOption) => {
    let pulist;

    if (selectedOption.length == 0) {
      getUserNames();
      setState((prevState) => ({
        ...prevState,
        pidies: "",
      }));
      let filteredArray = helpingState?.auditParamType.filter(
        (type) => type !== "Hourly Report"
      );

      sethelpingState((prev) => ({
        ...prev,
        AllPracticeCheck: false,
        auditParamType: filteredArray,
      }));
      getTeamsInfoFunc();
      setState((prev)=>({...prev,
        uidies:[] 
      }))

      return;
    }

    pulist = selectedOption.map((item) => item).join(",");

    // COMPARE WITH REDUX PRACTICES
    //****If we have Team Type is blank then selecting practice */
    if (helpingState?.TeamsInfoId.length == 0) {
      await comparewithRedux(pulist);
    }

    dispatch(practIdies(pulist));
    dispatch(practName(JSON.stringify(selectedOption)));
    const formDatag = new FormData();
    formDatag.append("pidies", pulist);
    let res = await userIdess(formDatag);
    let userListss =
      res &&
      res.data &&
      res.data.map((item) => ({
        value: item.id,
        label: `${item.fname} ${item.lname}`,
      }));
      const userids = onLoginSignOff.users.map((el) => el.userid);
              const joinedUserIdsN = userids.join(",");
      
    let indicesArray = joinedUserIdsN.split(",").map(Number);

    let filteredArr = userListss.filter((obj) =>
      indicesArray.includes(Number(obj.value))
    );

    const allOptionUser = { id: "selectAll", label: "Select All Users" };
    let AllUserInfo = mapValues(
      helpingState.userListWithTeam,
      filteredArr,
      helpingState?.ShiftType?.value
    );
    
    const joinedUserIds = AllUserInfo && AllUserInfo.map(user => user.id).join(',');
    setState((prev)=>({...prev,
      uidies:joinedUserIds.split(',') 
    }))
    setUserList(AllUserInfo);
    setState((prevState) => ({
      ...prevState,
      pidies: pulist,
    }));
  };

  const getUsers = () => {};

  const setValidationFunc = async (obj) => {
    const validationFields = [
      {
        fieldName: "pidies",
        rules: { requiredMessage: "Received amount is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "uidies",
        rules: { requiredMessage: "AmountDosVal is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "tuidies",
        rules: { requiredMessage: "Date is required." },
        type: { typeError: StringType() },
      },

      // Add more fields and their rules as needed
    ];

    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);

    if (errors.pidies.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        pidies: true,
      }));
    }
    if (errors.uidies.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        uidies: true,
      }));
    }
    if (errors.tuidies.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        tuidies: true,
      }));
    }

    return errors;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
    }
  };
  useEffect(() => {
    if (dateForgrid && helpingState?.headerName) {
      const startIdx = (helpingState.activePage - 1) * helpingState.Limit;
      const endIdx = startIdx + helpingState.Limit;

      const pageItems = dateForgrid && dateForgrid.slice(startIdx, endIdx);

      calculateFooter(pageItems);
    }
  }, [
    dateForgrid,
    helpingState?.headerName,
    helpingState?.Limit,
    helpingState?.ThresActivePage,
    helpingState?.activePage,
  ]);

  console.log("helpingState?.Limit", helpingState?.Limit);
  const calculateFooter = (pageItems) => {
    if (helpingState?.headerName == "Worked") {
      pageItems &&
        pageItems.forEach((item) => {
          footerObj.total_charges += item.charges;
          footerObj.total_Rejections += item.rejections;
          footerObj.total_Auth += item.Auth;
          footerObj.total_Vobs += item.vobs;
          footerObj.total_Eobs += item.eobs;
          footerObj.total_Era += item.eras;
          footerObj.total_Arr += item.generalpayers;
          footerObj.totalPatStat += item.patStat;
          // Add other sum calculations for total_Eobs, total_Era, total_Arr, and Total if needed
        });

      setFooterState(footerObj);
    } else if (helpingState?.headerName == "both") {
      //------------------------------
      pageItems &&
        pageItems.forEach((item) => {
          footerObj.total_charges += item.charges;
          footerObj.total_Rejections += item.rejections;
          footerObj.total_Auth += item.Auth;
          footerObj.total_Vobs += item.vobs;
          footerObj.total_Eobs += item.eobs;
          footerObj.total_Era += item.eras;
          footerObj.total_Arr += item.generalpayers;
          footerObj.totalPatStat += item.patStat;

          // Add other sum calculations for total_Eobs, total_Era, total_Arr, and Total if needed
        });
      setFooterState(footerObj);
      //--------------------------
      pageItems &&
        pageItems.forEach((item) => {
          footerObjAmount.total_charges_amount += item.chargesworkedAmount;
          footerObjAmount.total_Rejections_amount += item.rejectionworkedAmount;
          footerObjAmount.total_Auth_amount += item.authworkedAmount;
          footerObjAmount.total_Eobs_amount += item.eobWorkedAmount;
          footerObjAmount.total_Era_amount += item.eraWorkedAmount;
          footerObjAmount.total_Arr_amount += item.payerCollectedAmountTotal;
          footerObjAmount.totalPatStat_amount += item.patStatcollectedamount;

          // Add other sum calculations for total_Eobs, total_Era, total_Arr, and Total if needed
        });
      setFooterStateAmount(footerObjAmount);

      //-----------------------------

      pageItems &&
        pageItems.forEach((item) => {
          footerObjCombine.total_charges += item.charges;
          footerObjCombine.total_Rejections += item.rejections;
          footerObjCombine.total_Auth += item.Auth;
          footerObjCombine.total_Vobs += item.vobs;
          footerObjCombine.total_Eobs += item.eobs;
          footerObjCombine.total_Era += item.eras;
          footerObjCombine.total_Arr += item.generalpayers;
          footerObjCombine.totalPatStat += item.patStat;
          footerObjCombine.total_charges_amount += item.chargesworkedAmount;
          footerObjCombine.total_Rejections_amount +=
            item.rejectionworkedAmount;
          footerObjCombine.total_Auth_amount += item.authworkedAmount;
          footerObjCombine.total_Eobs_amount += item.eobWorkedAmount;
          footerObjCombine.total_Era_amount += item.eraWorkedAmount;
          footerObjCombine.total_Arr_amount += item.payerCollectedAmountTotal;
          footerObjCombine.totalPatStat_amount += item.patStatcollectedamount;

          // Add other sum calculations for total_Eobs, total_Era, total_Arr, and Total if needed
        });
      setFooterStateCombine(footerObjCombine);
    } else {
      pageItems &&
        pageItems.forEach((item) => {
          footerObjAmount.total_charges_amount += item.chargesworkedAmount;
          footerObjAmount.total_Rejections_amount += item.rejectionworkedAmount;
          footerObjAmount.total_Auth_amount += item.authworkedAmount;
          footerObjAmount.total_Eobs_amount += item.eobWorkedAmount;
          footerObjAmount.total_Era_amount += item.eraWorkedAmount;
          footerObjAmount.total_Arr_amount += item.payerCollectedAmountTotal;
          footerObjAmount.totalPatStat_amount += item.patStatcollectedamount;

          // Add other sum calculations for total_Eobs, total_Era, total_Arr, and Total if needed
        });
      setFooterStateAmount(footerObjAmount);
    }
  };

  useEffect(() => {
    if (state.pidies) {
      sethelpingState((prev) => ({
        ...prev,
        pidiesForReport: state.pidies,
      }));
    }
    if (state.sdate) {
      sethelpingState((prev) => ({
        ...prev,
        sdateReport: state.sdate,
      }));
    }
    if (state.edate) {
      sethelpingState((prev) => ({
        ...prev,
        edateReport: state.edate,
      }));
    }
  }, [state.pidies, state.sdate, state.edate]);

  const onSearch = async () => {
    if (startDatee == undefined || endDate == undefined) {
      toast.info("Please Select Date Range");
      return;
    }
    sethelpingState((prev) => ({
      ...prev,
      sdateReport: state.sdate,
      edateReport: state.edate,
      pidiesForReport: state.pidies,
      auditParamTypeCopy: helpingState.auditParamType,
    }));

    if (helpingState?.auditParamType.includes("Hourly Report")) {
      sethelpingState((prev) => ({ ...prev, isThresholdShow: true }));
    } else {
      sethelpingState((prev) => ({ ...prev, isThresholdShow: false }));
    }
    if (
      helpingState?.auditParamType.includes("Worked Claim") &&
      helpingState?.auditParamType.includes("Worked Amount")
    ) {
      sethelpingState((prev) => ({ ...prev, headerName: "both" }));
    } else if (helpingState?.auditParamType.includes("Worked Claim")) {
      sethelpingState((prev) => ({ ...prev, headerName: "Worked" }));
    } else if (helpingState?.auditParamType.includes("Worked Amount")) {
      sethelpingState((prev) => ({ ...prev, headerName: "Amount" }));
    }

    setLoader(true);

    let obj = {
      pidies: state.pidies,
      uidies: state.uidies,
      tuidies: state.tuidies,
      formtype: state.formtype,
      sdate: state.sdate,
      edate: state.edate,
      products: null,
      // AuditType:state.auditType
    };

    if (formTypeString == "UserWise Productivity") {
      if (obj.uidies == "" && obj.tuidies == "" && obj.formtype == "audit") {
        let pulist = userList
          ? userList.map((item) => item.value).join(",")
          : "";

        let obj = {
          pidies: state.pidies,
          uidies: pulist,
          tuidies: state.tuidies,
          formtype: state.formtype,
          sdate: state.sdate,
          edate: state.edate,
          products: null,
          // AuditType:state.auditType
        };

        let response = await getTluserwiseAudit(obj);

        let res = await getUsersName();

        const mergedData = response.data.result.map((user) => ({
          ...user,
          ...(res && res?.data.find((item) => item.userid === user.userid)),
        }));

        if (response.data) {
        } else {
          setLoader(false);
        }

        const matchedUsers = mergedData.map((user) => {
          const match =
            helpingState.AllUserList &&
            helpingState.AllUserList.find(
              (item) => item.value === user.userid.toString()
            );

          if (match?.label) {
            return { ...user, label: match.label };
          }
          return user;
        });

        const matchUserThres = matchedUsers.map((bigoItem) => {
          const matchedUser = helpingState.userListWithTeam.find(
            (user) => user.id == bigoItem.userid
          );

          if (matchedUser) {
            return {
              ...bigoItem,
              teamName: matchedUser.teamName,
              label: matchedUser.name,
              employee_id: matchedUser.employee_id,
            };
          }

          return bigoItem;
        });

        let obj1 = {
          sdate: state.sdate,
          edate: state.edate,
          tuidies: state.uidies,
          pidies: state.pidies,
        };
        let Data = await getAccRecordByUserid(obj1);

        const result = Object.values(
          Data &&
            Data?.data.reduce((acc, obj) => {
              if (!acc[obj.userid]) {
                acc[obj.userid] = { ...obj };
              } else {
                acc[obj.userid].generalPayer += obj.generalPayer;
                acc[obj.userid].specialPayer += obj.specialPayer;
              }
              return acc;
            }, {})
        );
       

        const combinedArray = matchUserThres.map((item) => {
          const matchingItem = result.find(
            (el) => el.userid.toString() === item.userid
          );

          if (matchingItem) {
            item.generalpayers = matchingItem.generalpayers;
            item.specialpayers = matchingItem.specialpayers;
            item.payerCollectedAmountTotal =
              matchingItem.payerCollectedAmountTotal;
          }

          return item;
        });

        const startDate = moment(helpingState.sdateReport, "YYYY-MM-DD");
        const endDate = moment(helpingState.edateReport, "YYYY-MM-DD");

        let daysDifff = 1;

        const current = startDate.clone();
        while (current.isBefore(endDate)) {
          if (current.day() !== 0 && current.day() !== 6) {
            daysDifff++;
          }
          current.add(1, "days");
        }
        let daysDiff = daysDifff * 8;
        sethelpingState((prev) => ({ ...prev, daysDifference: daysDiff }));
debugger
        let updatedArr = combinedArray.map((item) => {
          // Divide attributes by hours and create new attributes
          return {
            ...item,
            rejection_hours: parseInt(item.rejections) / daysDiff,
            Auth_hours: parseInt(item.Auth) / daysDiff,

            calls_hours: parseInt(item.calls) / daysDiff,
            portals_hours: parseInt(item.portals) / daysDiff,

            manual_hours: parseInt(item.eobs) / daysDiff,
            electronically: parseInt(item.eras) / daysDiff,
            denials_hours:
              parseInt(item.eobdenials + item.eradenials) / daysDiff,
            generalPayer_hours: parseInt(item.generalpayers) / daysDiff,
            specialPayer_hours: parseInt(item.specialpayers) / daysDiff,
          };
        });

        const updatedArrWithTotalHours =
          updatedArr &&
          updatedArr.map((el) => ({
            ...el, // Spread the original object properties
            totalHours: (
              el?.rejection_hours +
              el?.Auth_hours +
              el?.calls_hours +
              el?.portals_hours +
              el?.manual_hours +
              el?.electronically +
              el?.denials_hours +
              el?.generalPayer_hours +
              el?.specialPayer_hours
            ).toFixed(1),
          }));
        // const daysDiff = endDate.diff(startDate, "days");

        let reso = await getThresholdFordocuhub(state.pidies);

        let objReal = [];
        if (objReal) {
          for (let prop in objReal) {
            if (objReal.hasOwnProperty(prop)) {
              objReal[prop] = (parseFloat(objReal[prop]) * daysDiff).toString();
            }
          }
        }

        console.log("combinedArray", combinedArray);

        const resultForThreshold = await updatedArrWithTotalHours.map(
          (item) => {
            const percentage = (value, criteria) => {
              let val = parseFloat(value);
              let thresh = parseFloat(criteria);
              const result = (val / thresh) * 100;

              if (thresh !== 0) {
                return result.toFixed(1);
              } else {
                return 0; // or any other appropriate value
              }
            };

            return {
              ...item,
              rejections: percentage(
                item.rejections,
                objReal.thresh_rejections
              ),
              portals: percentage(item.portals, objReal.thresh_portal),
              calls: percentage(item.calls, objReal.thresh_eligibility),
              eobdenials: percentage(
                item.eobdenials + item.eradenials,
                objReal.thresh_denials
              ),
              Auth: percentage(item.Auth, objReal.thresh_authorization),
              eobs: percentage(item.eobs, objReal.thresh_posting),
              eras: percentage(
                item.eras,
                objReal.thresh_posting_electronically
              ),
              generalPayer: percentage(
                item.generalpayers,
                objReal.claims_per_hours
              ),
              specialPayer: percentage(
                item.specialpayers,
                objReal.claims_review_per_hours
              ),
              rejectionHour: (item.rejections / daysDiff).toFixed(1),
              portalHour: (item.portals / daysDiff).toFixed(1),
              callsHours: (item.calls / daysDiff).toFixed(1),
              eobdenialsHours: (
                item.eobdenials +
                item.eradenials / daysDiff
              ).toFixed(1),
              AuthHours: (item.Auth / daysDiff).toFixed(1),
              eobsHours: (item.eobs / daysDiff).toFixed(1),
              erasHours: (item.eras / daysDiff).toFixed(1),
              generalPayerHours: (item?.generalpayers / daysDiff).toFixed(1),
              specialPayerHours: (item?.specialpayers / daysDiff).toFixed(1),
            };
          }
        );

        const newArr = matchedUsers.map((bigoItem) => {
          const matchedUser = helpingState.userListWithTeam.find(
            (user) => user.id == bigoItem.userid
          );

          if (matchedUser) {
            return {
              ...bigoItem,
              teamName: matchedUser.teamName,
              label: matchedUser?.name,
              employee_id: matchedUser?.employee_id,
            };
          }

          return bigoItem;
        });

        // -------------------------For Teams Bug------------------
        if (helpingState?.TeamsInfoId.length > 0) {
          let modifiedArr2 = helpingState?.TeamsInfoId.map((value) => {
            let matchingObject = helpingState.TeamsInfo.find(
              (obj) => obj.value === value
            );
            return matchingObject
              ? { label: matchingObject.label, value: value }
              : { label: "", value };
          });
          let filteredArr = newArr.filter((obj) => {
            let matchingObject = modifiedArr2.find(
              (item) => item.label === obj.teamName
            );
            return matchingObject;
          });
          setDataForGrid(filteredArr);
        } else {
          setDataForGrid(newArr);
        }

        //For Threshold
        sethelpingState((prev) => ({
          ...prev,
          thresholdRecord: resultForThreshold,
        }));

        setLoader(false);
        return;
      }

      let response = await getTluserwiseAudit(obj);
      let obj1 = {
        sdate: state.sdate,
        edate: state.edate,
        tuidies: state.uidies,
        pidies: state.pidies,
      };
      let Data = await getAccRecordByUserid(obj1);

      const result = await Object.values(
        Data &&
          Data?.data.reduce((acc, obj) => {
            if (!acc[obj.userid]) {
              acc[obj.userid] = { ...obj };
            } else {
              acc[obj.userid].generalPayer += obj.generalPayer;
              acc[obj.userid].specialPayer += obj.specialPayer;
            }
            return acc;
          }, {})
      );

      const matchedUsers = await response?.data?.result.map((user) => {
        const match =
          helpingState.AllUserList &&
          helpingState.AllUserList?.find(
            (item) => item.value === user.userid.toString()
          );
        if (match?.label) {
          return { ...user, label: match.label };
        }
        return user;
      });

      const matchUserThres = matchedUsers.map((bigoItem) => {
        const matchedUser = helpingState.userListWithTeam.find(
          (user) => user.id == bigoItem.userid
        );

        if (matchedUser) {
          return {
            ...bigoItem,
            teamName:
              matchedUser.teamName +
              (matchedUser?.m_team == null ? "" : matchedUser?.m_team),
            label: matchedUser?.name,
            employee_id: matchedUser?.employee_id,
          };
        }

        return bigoItem;
      });

      const combinedArray = await matchUserThres.map((item) => {
        const matchingItem = result.find(
          (el) => el.userid.toString() === item.userid
        );

        if (matchingItem) {
          item.generalpayers = matchingItem.generalpayers;
          item.specialpayers = matchingItem.specialpayers;
          item.payerCollectedAmountTotal =
            matchingItem.payerCollectedAmountTotal;
        }

        return item;
      });

      const startDate = moment(helpingState.sdateReport, "YYYY-MM-DD");
      const endDate = moment(helpingState.edateReport, "YYYY-MM-DD");

      // let daysDifff = endDate.diff(startDate, "days");

      let daysDifff = 1;

      const current = startDate.clone();
      while (current.isBefore(endDate)) {
        if (current.day() !== 0 && current.day() !== 6) {
          daysDifff++;
        }
        current.add(1, "days");
      }

      // 16 hours

      //16 hours * 10==160
      //160

      let daysDiff = daysDifff * 8;
      sethelpingState((prev) => ({ ...prev, daysDifference: daysDiff }));

      let res = await getThresholdFordocuhub(state.pidies);

      let objReal = [];
      if (objReal) {
        for (let prop in objReal) {
          if (objReal.hasOwnProperty(prop)) {
            objReal[prop] = (parseFloat(objReal[prop]) * daysDiff).toString();
          }
        }
      }

      let updatedArr = combinedArray.map((item) => {
        // Divide attributes by hours and create new attributes
        return {
          ...item,
          rejection_hours: item.rejections / daysDiff,
          Auth_hours: item.Auth / daysDiff,

          calls_hours: item.calls / daysDiff,
          portals_hours: item.portals / daysDiff,

          manual_hours: item.eobs / daysDiff,
          electronically: item.eras / daysDiff,
          denials_hours: item.eobdenials + item.eradenials / daysDiff,
          generalPayer_hours: item.generalpayers / daysDiff,
          specialPayer_hours: item.specialpayers / daysDiff,
        };
      });
      const updatedArrWithTotalHours =
        updatedArr &&
        updatedArr.map((el) => ({
          ...el, // Spread the original object properties
          totalHours: (
            el?.rejection_hours +
            el?.Auth_hours +
            el?.calls_hours +
            el?.portals_hours +
            el?.manual_hours +
            el?.electronically +
            el?.denials_hours +
            el?.denials_hours +
            el?.generalPayer_hours +
            el?.specialPayer_hours
          ).toFixed(1),
        }));

      const resultForThreshold = await updatedArrWithTotalHours.map((item) => {
        const percentage = (value, criteria) => {
          let val = parseFloat(value);
          let thresh = parseFloat(criteria);
          const result = (val / thresh) * 100;

          if (thresh !== 0) {
            return result.toFixed(1);
          } else {
            return 0; // or any other appropriate value
          }
        };

        return {
          ...item,
          rejections: percentage(item.rejections, objReal.thresh_rejections),
          portals: percentage(item.portals, objReal.thresh_portal),
          calls: percentage(item.calls, objReal.thresh_eligibility),
          eobdenials: percentage(
            item.eobdenials + item.eradenials,
            objReal.thresh_denials
          ),
          Auth: percentage(item.Auth, objReal.thresh_authorization),
          eobs: percentage(item.eobs, objReal.thresh_posting),
          eras: percentage(item.eras, objReal.thresh_posting_electronically),
          generalPayer: percentage(
            item.generalpayers,
            objReal.claims_per_hours
          ),
          specialPayer: percentage(
            item.specialpayers,
            objReal.claims_review_per_hours
          ),
          rejectionHour: (item.rejections / daysDiff).toFixed(1),
          portalHour: (item.portals / daysDiff).toFixed(1),
          callsHours: (item.calls / daysDiff).toFixed(1),
          eobdenialsHours: (
            item.eobdenials +
            item.eradenials / daysDiff
          ).toFixed(1),
          AuthHours: (item.Auth / daysDiff).toFixed(1),
          eobsHours: (item.eobs / daysDiff).toFixed(1),
          erasHours: (item.eras / daysDiff).toFixed(1),
          generalPayerHours: (item?.generalpayers / daysDiff).toFixed(1),
          specialPayerHours: (item?.specialpayers / daysDiff).toFixed(1),
        };
      });

      console.log("userListWithTeam", resultForThreshold);
      console.log("matchedUsers", matchedUsers);

      const newArr = matchedUsers.map((bigoItem) => {
        const matchedUser = helpingState.userListWithTeam.find(
          (user) => user.id == bigoItem.userid
        );
        console.log("bigoItem", matchedUser);

        if (matchedUser) {
          return {
            ...bigoItem,
            teamName:
              matchedUser.teamName +
              (matchedUser?.m_team == null ? "" : matchedUser?.m_team),
            label: matchedUser?.name,
            employee_id: matchedUser?.employee_id,
          };
        }

        return bigoItem;
      });

      // const startIdx = (helpingState.activePage - 1) * helpingState.Limit;
      // const endIdx = startIdx + helpingState.Limit;

      // const pageItems =await newArr && newArr.slice(startIdx, endIdx);

      // calculateFooter(pageItems)
      // -------------------------For Teams Bug------------------
      if (helpingState?.TeamsInfoId.length > 0) {
        let modifiedArr2 = helpingState?.TeamsInfoId.map((value) => {
          let matchingObject = helpingState.TeamsInfo.find(
            (obj) => obj.value === value
          );
          return matchingObject
            ? { label: matchingObject.label, value: value }
            : { label: "", value };
        });
        let filteredArr = newArr.filter((obj) => {
          let matchingObject = modifiedArr2.find(
            (item) => item.label === obj.teamName
          );
          return matchingObject;
        });
        setDataForGrid(filteredArr);
      } else {
        setDataForGrid(newArr);
      }

      sethelpingState((prev) => ({
        ...prev,
        thresholdRecord: resultForThreshold,
      }));
      setLoader(false);
    } else {
      let errors = await setValidationFunc(obj);
      if (
        !errors.pidies.hasError &&
        !errors.uidies.hasError &&
        !errors.tuidies.hasError
      ) {
        let res = await getTlAudit(obj);

        let formTypeArr = state.tuidies && state.tuidies.split(",");

        let result = Object.keys(res.data)
          .filter((key) =>
            formTypeArr.includes(res.data[key].success.formType.toString())
          )
          .reduce((acc, key) => {
            acc[key] = res.data[key];
            return acc;
          }, {});
        setLoader(false);

        // setDataForGrid(result);
        setDataForGrid(result);
      } else {
        setLoader(false);
      }
    }
  };

  const onClear = () => {
    setState(payload);
    sethelpingState((prev) => ({
      ...prev,
      auditParamType: null,
      TeamsInfo: [],
      TeamsAllCheck: false,
      AllPracticeCheck: false,
      AllUserChecked: false,
      AllAuditTypeCheck: false,
    }));
  };
  const handlePracticeVal = (val) => {
    const { value, label } = val;

    setState((prevResult) => ({
      ...prevResult,

      auditType: label,
    }));
  };

  const onChangePop = (rowData) => {
    if (rowData?.generalPayer) {
      sethelpingState((prev) => ({ ...prev, practiceWisePopType: "thres" }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        practiceWisePopType: "recordPratice",
      }));
    }

    setuseiridPracticeView(rowData);
    setOpenPracticeViewPop(true);
  };
  const handleClosePracticeWisePopup = (val) => {
    setOpenPracticeViewPop(val);
  };

  let check =
    state.uidies == "" && state.pidies == "" && state.sdate && state.edate;

  const arr = [
    { colName: "Emp Id", flexGrow: 1.3, dataKey: "employee_id" },
    { colName: "Users", flexGrow: 2, dataKey: !check ? "label" : "label" },

    { colName: "Team Name", flexGrow: 2.5, dataKey: "teamName" },
    { colName: "Charges", flexGrow: 1.3, dataKey: "charges" },
    { colName: "Rejections", flexGrow: 1.3, dataKey: "rejections" },
    { colName: "Auth", flexGrow: 0.7, dataKey: "Auth" },
    { colName: "VOB`s", flexGrow: 0.7, dataKey: "vobs" },
    { colName: "EOB`s", flexGrow: 0.8, dataKey: "eobs" },
    { colName: "ERA`s", flexGrow: 0.8, dataKey: "eras" },
    { colName: "Account Receivable", flexGrow: 1.5, dataKey: "generalpayers" },
    // { colName: "Acc Special Payers", flexGrow: 1.5, dataKey: "specialpayers" },
    { colName: "Patient Statement", flexGrow: 1.5, dataKey: "patStat" },

    {
      colName: "Date of Entry Wise",

      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
    },
  ];
  const combineHeader = [
    { colName: "Users", flexGrow: 2, dataKey: !check ? "label" : "label" },
    // { colName: "Charges", flexGrow: 1.1, dataKey: "charges" },
    { colName: "Charges $", flexGrow: 1.1, dataKey: "chargesworkedAmount" },
    // { colName: "Rejections", flexGrow: 1.1, dataKey: "rejections" },
    {
      colName: "Rejections $",
      flexGrow: 1.1,
      dataKey: "rejectionworkedAmount",
    },
    // { colName: "Auth", flexGrow: 0.7, dataKey: "Auth" },
    { colName: "Auth $", flexGrow: 0.7, dataKey: "authworkedAmount" },
    { colName: "VOB`s", flexGrow: 0.7, dataKey: "vobs" },
    // { colName: "EOB`s", flexGrow: 0.8, dataKey: "eobs" },
    { colName: "EOB`s $", flexGrow: 0.8, dataKey: "eobWorkedAmount" },
    // { colName: "ERA`s", flexGrow: 0.8, dataKey: "eras" },
    { colName: "ERA`s $", flexGrow: 0.8, dataKey: "eraWorkedAmount" },
    // { colName: "Account Receivable", flexGrow: 1.5, dataKey: "generalpayers" },
    {
      colName: "Account Receivable $",
      flexGrow: 1.5,
      dataKey: "payerCollectedAmountTotal",
    },
    // { colName: "Patient Statement", flexGrow: 1.5, dataKey: "patStat" },
    {
      colName: "Patient Statement $",
      flexGrow: 1.5,
      dataKey: "patStatcollectedamount",
    },
    {
      colName: "Date of Entry Wise ",
      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
    },
  ];

  const arrworkedAmount = [
    { colName: "Emp Id", flexGrow: 1.3, dataKey: "employee_id" },
    { colName: "Users", flexGrow: 1.5, dataKey: "label" },
    { colName: "Team Name", flexGrow: 2.5, dataKey: "teamName" },
    { colName: "Charges $", flexGrow: 1.1, dataKey: "chargesworkedAmount" },
    {
      colName: "Rejections $",
      flexGrow: 1.1,
      dataKey: "rejectionworkedAmount",
    },
    { colName: "Auth $", flexGrow: 0.7, dataKey: "authworkedAmount" },
    // { colName: "VOB`s", flexGrow: 0.7, dataKey: "eobWorkedAmount" },
    { colName: "EOB`s $", flexGrow: 0.8, dataKey: "eobWorkedAmount" },
    { colName: "ERA`s $", flexGrow: 0.8, dataKey: "eraWorkedAmount" },
    {
      colName: "Account Receivable $",
      flexGrow: 1.5,
      dataKey: "payerCollectedAmountTotal",
    },
    // { colName: "Acc Special Payers", flexGrow: 1.5, dataKey: "specialpayers" },
    {
      colName: "Patient Statement $",
      flexGrow: 1.5,
      dataKey: "patStatcollectedamount",
    },

    {
      colName: "Date of Entry Wise ",
      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
    },
  ];
  const thresholdCol = [
    { colName: "Emp Id", flexGrow: 1.3, dataKey: "employee_id" },
    { colName: "Users", flexGrow: 2, dataKey: "label" },

    {
      colName: "Team Name",
      flexGrow: 4,
      dataKey: "teamName",
      //  isShowToolTip:true,
    },

    {
      colName: "Portals",
      flexGrow: 1.1,
      dataKey: "portalHour",
      //  isShowToolTip:true ,
      //  toolTipKey:"portals"
    },
    {
      colName: "Call",
      flexGrow: 1.1,
      dataKey: "callsHours",
      //   isShowToolTip:true,
      //    toolTipKey:'calls'
    },

    {
      colName: "No.of Pre Authorization",
      flexGrow: 1.1,
      dataKey: "AuthHours",
      //   isShowToolTip:true,
      //   toolTipKey:'Auth'
    },

    {
      colName: "No of Rejections",
      flexGrow: 1.1,
      dataKey: "rejectionHour",
      //   isShowToolTip:true,
      //   toolTipKey:'rejections'
    },
    {
      colName: "Manual",
      flexGrow: 1.1,
      dataKey: "eobsHours",
      //  isShowToolTip:true,
      //    toolTipKey:'eobs'
    },
    {
      colName: "Electronically",
      flexGrow: 1.1,
      dataKey: "erasHours",
      //   isShowToolTip:true,
      //   toolTipKey:'eras',
    },

    {
      colName: "No of Denials",
      flexGrow: 1.1,
      dataKey: "eobdenialsHours",
      //   isShowToolTip:true,
      //   toolTipKey:'eobdenials'
    },

    {
      colName: "General Payer",
      flexGrow: 1.1,
      dataKey: "generalPayerHours",
      //  isShowToolTip:true,
      //  toolTipKey:'generalPayer'
    },
    {
      colName: "Special Payer",
      flexGrow: 1.1,
      dataKey: "specialPayerHours",
      // isShowToolTip:true,
      // toolTipKey:'specialpayers'
    },
    // change of 12/15/2023
    { colName: "Per Hour Working", flexGrow: 1.5, dataKey: "totalHours" },

    {
      colName: "Action",
      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
      isAllow: false,
      //   isShowToolTip:true
    },
  ];
  console.log("hello world", helpingState?.TeamsInfoId);

  const handleFormType = (val) => {
    let pulist = val.map((item) => item.id).join(",");
    let obj = { name: "formType", value: pulist };
    handleFormVal(obj);
  };
  const onRemoveFormType = (val) => {
    let pulist = val.map((item) => item.id).join(",");
    let obj = { name: "formType", value: pulist };
    handleFormVal(obj);
  };
  const handleVal = (event) => {
    if (event == null) {
      let obj = { name: "startDate", val: null };
      handleFormVal(obj);
      let objSec = { name: "endDate", val: null };
      handleFormVal(objSec);

      return;
    }
    const formattedDates =
      event && event.map((date) => moment(date).format("YYYY-MM-DD"));
    //  const { name, value } = event.target;
    // if (name == "startDate") {
    let obj = { name: "startDate", val: formattedDates[0] };
    handleFormVal(obj);
    //  return;
    // }
    //   if (name == "endDate") {
    let objSec = { name: "endDate", val: formattedDates[1] };
    handleFormVal(objSec);
    // return;
    //  }
  };

  const handleShiftType = (arg) => {
    const { label, value } = arg;
    sethelpingState((prev) => ({
      ...prev,
      ShiftType: { ...prev.ShiftType, label, value },
    }));
  };
  const checkAllPractices = async (e, checked) => {
    if (checked) {
      let pulist = products
        .filter((item) => !item?.id) // Filter out items without an 'id'
        .map((item) => item?.value) // Extract 'value' property
        .join(",");

      if (helpingState?.TeamsInfoId.length == 0) {
        await comparewithRedux(pulist);
      }
      const formDatag = new FormData();
      formDatag.append("pidies", pulist);

      let res = await userIdess(formDatag);
      let userListss =
        res &&
        res.data &&
        res.data.map((item) => ({
          value: item.id,
          label: `${item.fname} ${item.lname}`,
        }));
        const userids = onLoginSignOff.users.map((el) => el.userid);
    const joinedUserIdsN = userids.join(",");
      let indicesArray = joinedUserIdsN.split(",").map(Number);

      let filteredArr = userListss.filter((obj) =>
        indicesArray.includes(Number(obj.value))
      );
     

      let AllUserInfo = mapValues(
        helpingState.userListWithTeam,
        filteredArr,
        helpingState?.ShiftType?.value
      );
      
      const joinedUserIds = AllUserInfo && AllUserInfo.map(user => user.id).join(',');

      setState((prev)=>({...prev,
        uidies:joinedUserIds.split(',') 
      }))

      setUserList(AllUserInfo);


      sethelpingState((prev) => ({ ...prev, AllPracticeCheck: true }));
      setState((prevState) => ({
        ...prevState,
        pidies: pulist,
      }));
    } else {
      getTeamsInfoFunc();
      sethelpingState((prev) => ({ ...prev, AllPracticeCheck: false }));
      setState((prevState) => ({
        ...prevState,
        pidies: "",
        uidies:[]
      }));

      getUserNames();
    }
  };

  const getRequriedPara = (activePage, reportName, limit) => {
    if (reportName == "Thres") {
      sethelpingState((prev) => ({
        ...prev,
        ThresActivePage: activePage,
        ThresLimit: limit,
      }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        activePage: activePage,
        Limit: limit,
      }));
    }
  };

  const handleUserWiseRowData = async () => {};

  const handleInputChange = async (event, e) => {
    const containsThreshold = event.includes("Hourly Report");
    // if(state.sdate==state.edate){
    //   toast.info("For Threshold ! Please choose a one-day difference of Date Range.");
    //   return

    // }

    if (containsThreshold && state.pidies == "") {
      let result = products.map((item) => item.value).join(",");
      setState((prev) => ({ ...prev, pidies: result }));
      sethelpingState((prev) => ({ ...prev, AllPracticeCheck: true }));
    }
    if (event.length == 3) {
      sethelpingState((prev) => ({ ...prev, AllAuditTypeCheck: true }));
    } else {
      sethelpingState((prev) => ({ ...prev, AllAuditTypeCheck: false }));
    }

    sethelpingState((prev) => ({ ...prev, auditParamType: event }));
  };
  const handleAllAuditType = (er, event) => {
    if (state.pidies == "") {
      toast.info("For Threshold ! Please Select Practice Name");
      return;
    }
    if (event) {
      sethelpingState((prev) => ({
        ...prev,
        auditParamType: ["Worked Claim", "Worked Amount", "Hourly Report"],
        AllAuditTypeCheck: true,
      }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        auditParamType: [],
        AllAuditTypeCheck: false,
      }));
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };

  const disabledDate = (date) => {
    let res = moment(date).format("YYYY-MM-DD");

    let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    // Disable dates that are outside the min and max boundaries
    return (minDate && res < minDate) || (today && res > today);
  };

  const footerStyles = {
    border: "1px solid #e5e5e5",
    position: "absolute",
    marginTop: "45px",
    borderBottom: "1px solid rgb(229, 229, 229)", // Matching the border color
    // padding: "9px 67px", // Adjusted padding values
    // paddingLeft: "9px",
    paddingRight: "30%",
    width: "100%",
    backgroundColor: "aliceblue",
    borderRadius: "5px",
    marginLeft: "10px",
  };

  const onSelecTeam = async (selectedOption, e) => {
    if (selectedOption.length == 0) {
      sethelpingState((prev) => ({
        ...prev,
        TeamsInfoId: selectedOption,
        TeamsAllCheck: false,
      }));
      setState((prev) => ({ ...prev, uidies: [] }));
    } else {
      let allTeamsData = await Promise.all(
        selectedOption &&
          selectedOption.map(async (team) => {
            const userData = await getUsersByTeams(team);

            return userData.data.map((user) => ({
              ...user,
              teamName: team.name,
            }));
          })
      );
      let res = allTeamsData.flat();
      const matchedUsers =
        userList &&
        userList.filter((user) => res && res.some((u) => u.id === user.id));
      // setUserList(matchedUsers)
      const joinedUserIds =
        matchedUsers && matchedUsers.map((user) => user.id).join(",");

      sethelpingState((prev) => ({ ...prev, TeamsInfoId: selectedOption }));
      setState((prev) => ({ ...prev, uidies: joinedUserIds.split(",") }));
    }
  };

  const onSelectAllTeam = async (e, check) => {
    if (check) {
      const allTeamLabels = helpingState.TeamsInfo.map((el) => el.value);

      let allTeamsData = await Promise.all(
        allTeamLabels &&
          allTeamLabels.map(async (team) => {
            const userData = await getUsersByTeams(team);

            return userData.data.map((user) => ({
              ...user,
              teamName: team.name,
            }));
          })
      );
      let res = allTeamsData.flat();
      const matchedUsers =
        userList &&
        userList.filter((user) => res && res.some((u) => u.id === user.id));
      // setUserList(matchedUsers)
      const joinedUserIds =
        matchedUsers && matchedUsers.map((user) => user.id).join(",");

      setState((prev) => ({ ...prev, uidies: joinedUserIds.split(",") }));
      sethelpingState((prev) => ({
        ...prev,
        TeamsAllCheck: check,
        TeamsInfoId: allTeamLabels,
      }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        TeamsAllCheck: check,
        TeamsInfoId: [],
      }));

      setState((prev) => ({ ...prev, uidies: [] }));
    }
  };

  const onSearchExport = async (userid) => {
    sethelpingState((prev) => ({ ...prev, loading: true }));
    let obj = {
      pidies: helpingState.pidiesForReport,
      uidies: state.uidies,
      tuidies: state.tuidies,
      formtype: state.formtype,
      sdate: helpingState.sdateReport,
      edate: helpingState.edateReport,
      products: products,
      // AuditType:state.auditType
    };

    if (formTypeString == "UserWise Productivity") {
      if (obj.uidies == "" && obj.tuidies == "" && obj.formtype == "audit") {
        let pulist = userList
          ? userList.map((item) => item.value).join(",")
          : "";

        let obj = {
          pidies: helpingState.pidiesForReport,
          uidies: pulist,
          tuidies: userid,
          formtype: state.formtype,
          sdate: state.sdateReport,
          edate: state.edateReport,
          products: products,
          // AuditType:state.auditType
        };

        let response = await getTluserwiseAudit(obj);
        let res = await getUsersName();

        const mergedData = response.data.result.map((user) => ({
          ...user,
          ...(res && res?.data.find((item) => item.userid === user.userid)),
        }));

        const matchedUsers = mergedData.map((user) => {
          const match =
            userList &&
            userList.find((item) => item.value === user.userid.toString());
          if (match?.label) {
            return { ...user, label: match.label };
          }
          return user;
        });

        const matchUserThres = matchedUsers.map((bigoItem) => {
          const matchedUser = helpingState.userListWithTeam.find(
            (user) => user.id == bigoItem.userid
          );

          if (matchedUser) {
            return {
              ...bigoItem,
              teamName:
                matchedUser.teamName +
                (matchedUser?.m_team == null ? "" : matchedUser?.m_team),
              label: matchedUser?.name,
              employee_id: matchedUser?.employee_id,
            };
          }

          return bigoItem;
        });
        let resoo = matchUserThres.map((el) => {
          let sum = parseInt(el.eobdenials) + parseInt(el.eradenials);
          el.sumDenials = sum;

          // Loop through the children array and add the corresponding values
          // from the parent object to each child object.
          el.children.map((child) => {
            child.sumDenialChildren =
              parseInt(child?.eobdenials) + parseInt(child?.eradenials);
            return child;
          });

          return el;
        });
        sethelpingState((prev) => ({
          ...prev,
          loading: false,
          rowsToExport: resoo,
        }));

        // setDataForGrid(matchedUsers);
        return;
      }

      //have children Array
      let response = await getTluserwiseAudit(obj);
      setLoader(false);

      const matchedUsers = response?.data?.result.map((user) => {
        const match =
          helpingState.AllUserList &&
          helpingState.AllUserList?.find(
            (item) => item.value === user.userid.toString()
          );
        if (match?.label) {
          return { ...user, label: match.label };
        }
        return user;
      });

      const matchUserThres = matchedUsers.map((bigoItem) => {
        const matchedUser = helpingState.userListWithTeam.find(
          (user) => user.id == bigoItem.userid
        );

        if (matchedUser) {
          return {
            ...bigoItem,
            teamName:
              matchedUser.teamName +
              (matchedUser?.m_team == null ? "" : matchedUser?.m_team),
            label: matchedUser?.name,
            employee_id: matchedUser?.employee_id,
          };
        }

        return bigoItem;
      });

      let res = matchUserThres.map((el) => {
        let sum = parseInt(el.eobdenials) + parseInt(el.eradenials);
        el.sumDenials = sum;

        // Loop through the children array and add the corresponding values
        // from the parent object to each child object.
        el.children.map((child) => {
          child.sumDenialChildren =
            parseInt(child?.eobdenials) + parseInt(child?.eradenials);
          return child;
        });

        return el;
      });

      //label  Add

      sethelpingState((prev) => ({
        ...prev,
        rowsToExport: res,
        loading: false,
      }));
    }
  };

  console.log("userList", userList, "Uidies", state.uidies);

  const callPopThresHold = (rowData) => {
    sethelpingState((prev) => ({
      ...prev,
      rowThresPop: rowData,
      isThressmallModelOpen: true,
    }));
  };
  const printDocument = (arg) => {
    //const input = document.getElementById('divToPrint');
    if (arg == 0) {
      sethelpingState((prev) => ({
        ...prev,
        practiceWisePopType: "recordPratice",
      }));
      const itemsPerPage = 5; // Number of items to display per page
      const activePage = 1; // The active page (1 or 2)

      const startIdx = (helpingState.activePage - 1) * helpingState.Limit;
      const endIdx = startIdx + helpingState.Limit;

      const pageItems = dateForgrid && dateForgrid.slice(startIdx, endIdx);
      const userIdsArray = pageItems.map((item) => item.userid.toString());
      onSearchExport(userIdsArray);
    } else {
      sethelpingState((prev) => ({ ...prev, practiceWisePopType: "thres" }));
      //For Threshold Record
      const itemsPerPage = 5; // Number of items to display per page
      const activePage = 1; // The active page (1 or 2)

      const startIdx =
        (helpingState.ThresActivePage - 1) * helpingState.ThresLimit;
      const endIdx = startIdx + helpingState.ThresLimit;

      const pageItems = dateForgrid && dateForgrid.slice(startIdx, endIdx);
      const userIdsArray = pageItems.map((item) => item.userid.toString());
      onSearchExport(userIdsArray);

      //This one Line is commented,it will perform all user record fetch

      //   onSearchExport(helpingState.userListForExport);
    }
  };

  console.log('state.uidies',state.uidies.length)

  const tableHeaderStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#0e7490",

    color: "white",
  };

  const nestedTableHeaderStyle = {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#0e7490",
    color: "white",
  };

  const blueTd = {
    backgroundColor: "rgb(240, 249, 255)",
  };
  const yellowTd = {
    backgroundColor: "rgb(243, 239, 164)",
  };

  let pdfTable = (
    <div className="App container" style={{ fontFamily: "Arial, sans-serif" }}>
      <div id="divToPrint" style={{ display: "none" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          User Productivity Report
        </h2>
        <table
          style={{
            border: "1px solid black",
            width: "80%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Users</th>
              <th style={tableHeaderStyle}>Team Name</th>
              <th style={tableHeaderStyle}>Charges</th>
              <th style={tableHeaderStyle}>Rejections</th>
              <th style={tableHeaderStyle}>Auth</th>
              <th style={tableHeaderStyle}>VOB`s</th>
              <th style={tableHeaderStyle}>EOB`s</th>
              <th style={tableHeaderStyle}>ERA`s</th>
              <th style={tableHeaderStyle}>Account Receivable</th>
              <th style={tableHeaderStyle}>Patient Statement</th>
            </tr>
          </thead>
          <tbody>
            {helpingState.rowsToExport &&
              helpingState.rowsToExport.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={`parent-${index}`}>
                    <td style={blueTd}>{item.label}</td>
                    <td style={blueTd}>{item.teamName}</td>
                    <td style={blueTd}>{item.charges}</td>
                    <td style={blueTd}>{item.rejections}</td>
                    <td style={blueTd}>{item.Auth}</td>
                    <td style={blueTd}>{item.vobs}</td>
                    <td style={blueTd}>{item.eobs}</td>
                    <td style={blueTd}>{item.eras}</td>
                    <td style={blueTd}>{item.generalpayers}</td>
                    <td style={blueTd}>{item.patStat}</td>
                  </tr>
                  {item.children && item.children.length > 0 && (
                    <tr>
                      <td colSpan="10" style={{ padding: 0 }}>
                        <table
                          style={{
                            border: "1px solid black",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={nestedTableHeaderStyle}>
                                Practice Name
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Date of Entry
                              </th>
                              <th style={nestedTableHeaderStyle}>Charges</th>
                              <th style={nestedTableHeaderStyle}>Rejections</th>
                              <th style={nestedTableHeaderStyle}>Auth</th>
                              <th style={nestedTableHeaderStyle}>VOBs</th>
                              <th style={nestedTableHeaderStyle}>EOBs</th>
                              <th style={nestedTableHeaderStyle}>ERAs</th>
                              <th style={nestedTableHeaderStyle}>
                                Account Receivable
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Patient Statements
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.children.map((child, childIndex) => (
                              <tr key={`child-${childIndex}`}>
                                <td style={yellowTd}>{child.label}</td>
                                <td style={yellowTd}>{child.date}</td>
                                <td style={yellowTd}>
                                  {child.chargesworkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.rejectionsworkedval}
                                </td>
                                <td style={yellowTd}>{child.Authworkedval}</td>
                                <td style={yellowTd}>{child.vobworkedval}</td>
                                <td style={yellowTd}>{child.eobworkedval}</td>
                                <td style={yellowTd}>{child.eraworkedval}</td>
                                <td style={yellowTd}>
                                  {child.generalPayerworkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.patStatWorkedval}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  let pdfTableCombine = (
    <div className="App container" style={{ fontFamily: "Arial, sans-serif" }}>
      <div id="divToPrint" style={{ display: "none" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          User Productivity Report
        </h2>
        <table
          style={{
            border: "1px solid black",
            width: "80%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Users</th>
              <th style={tableHeaderStyle}>Team Name</th>
              <th style={tableHeaderStyle}>Charges</th>
              <th style={tableHeaderStyle}>Charges $</th>
              <th style={tableHeaderStyle}>Rejections</th>
              <th style={tableHeaderStyle}>Rejections $</th>
              <th style={tableHeaderStyle}>Auth</th>
              <th style={tableHeaderStyle}>Auth $</th>
              <th style={tableHeaderStyle}>VOB`s</th>

              <th style={tableHeaderStyle}>EOB`s</th>
              <th style={tableHeaderStyle}>EOB`s $</th>
              <th style={tableHeaderStyle}>ERA`s</th>
              <th style={tableHeaderStyle}>ERA`s $</th>
              <th style={tableHeaderStyle}>Account Receivable</th>
              <th style={tableHeaderStyle}>Account Receivable $</th>
              <th style={tableHeaderStyle}>Patient Statement</th>
              <th style={tableHeaderStyle}>Patient Statement $</th>
            </tr>
          </thead>
          <tbody>
            {helpingState.rowsToExport &&
              helpingState.rowsToExport.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={`parent-${index}`}>
                    <td style={blueTd}>{item.label}</td>
                    <td style={blueTd}>{item.teamName}</td>
                    <td style={blueTd}>{item.charges}</td>
                    <td style={blueTd}>{item.chargesworkedAmount}</td>
                    <td style={blueTd}>{item.rejections}</td>
                    <td style={blueTd}>{item.rejectionworkedAmount}</td>
                    <td style={blueTd}>{item.Auth}</td>
                    <td style={blueTd}>{item.authworkedAmount}</td>
                    <td style={blueTd}>{item.vobs}</td>
                    <td style={blueTd}>{item.eobs}</td>
                    <td style={blueTd}>{item.eobWorkedAmount}</td>
                    <td style={blueTd}>{item.eras}</td>
                    <td style={blueTd}>{item.eraWorkedAmount}</td>
                    <td style={blueTd}>{item.generalpayers}</td>
                    <td style={blueTd}>{item.payerCollectedAmountTotal}</td>
                    <td style={blueTd}>{item.patStat}</td>
                    <td style={blueTd}>{item.patStatcollectedamount}</td>
                  </tr>
                  {item.children && item.children.length > 0 && (
                    <tr>
                      <td colSpan="17" style={{ padding: 0 }}>
                        <table
                          style={{
                            border: "1px solid black",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={nestedTableHeaderStyle}>
                                Practice Name
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Date of Entry
                              </th>
                              <th style={nestedTableHeaderStyle}>Charges</th>
                              <th style={nestedTableHeaderStyle}>Charges $</th>
                              <th style={nestedTableHeaderStyle}>Rejections</th>
                              <th style={nestedTableHeaderStyle}>
                                Rejections $
                              </th>
                              <th style={nestedTableHeaderStyle}>Auth</th>
                              <th style={nestedTableHeaderStyle}>Auth $</th>
                              <th style={nestedTableHeaderStyle}>VOBs</th>
                              <th style={nestedTableHeaderStyle}>EOBs</th>
                              <th style={nestedTableHeaderStyle}>EOBs $</th>
                              <th style={nestedTableHeaderStyle}>ERAs</th>
                              <th style={nestedTableHeaderStyle}>ERAs $</th>
                              <th style={nestedTableHeaderStyle}>
                                Account Receivable
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Account Receivable $
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Patient Statements
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Patient Statements $
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.children.map((child, childIndex) => (
                              <tr key={`child-${childIndex}`}>
                                <td style={yellowTd}>{child.label}</td>
                                <td style={yellowTd}>{child.date}</td>
                                <td style={yellowTd}>
                                  {child.chargesworkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.chargeamountval}
                                </td>
                                <td style={yellowTd}>
                                  {child.rejectionsworkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.rejectionsamountval}
                                </td>
                                <td style={yellowTd}>{child.Authworkedval}</td>
                                <td style={yellowTd}>{child.Authamountval}</td>
                                <td style={yellowTd}>{child.vobworkedval}</td>
                                <td style={yellowTd}>{child.eobworkedval}</td>
                                <td style={yellowTd}>{child.eobamountval}</td>
                                <td style={yellowTd}>{child.eraworkedval}</td>
                                <td style={yellowTd}>{child.eraamountval}</td>
                                <td style={yellowTd}>
                                  {child.generalPayerworkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.generalamountval}
                                </td>
                                <td style={yellowTd}>
                                  {child.patStatWorkedval}
                                </td>
                                <td style={yellowTd}>
                                  {child.patStatamountval}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  let pdfTableAmount = (
    <div className="App container" style={{ fontFamily: "Arial, sans-serif" }}>
      <div id="divToPrint" style={{ display: "none" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          User Productivity Report
        </h2>
        <table
          style={{
            border: "1px solid black",
            width: "80%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Users</th>
              <th style={tableHeaderStyle}>Team Name</th>
              <th style={tableHeaderStyle}>Charges $</th>
              <th style={tableHeaderStyle}>Rejections $</th>
              <th style={tableHeaderStyle}>Auth $</th>

              <th style={tableHeaderStyle}>EOB`s $</th>
              <th style={tableHeaderStyle}>ERA`s $</th>
              <th style={tableHeaderStyle}>Account Receivable $</th>
              <th style={tableHeaderStyle}>Patient Statement $</th>
            </tr>
          </thead>
          <tbody>
            {helpingState.rowsToExport &&
              helpingState.rowsToExport.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={`parent-${index}`}>
                    <td style={blueTd}>{item.label}</td>
                    <td style={blueTd}>{item.teamName}</td>
                    <td style={blueTd}>{item.chargesworkedAmount}</td>
                    <td style={blueTd}>{item.rejectionworkedAmount}</td>
                    <td style={blueTd}>{item.authworkedAmount}</td>
                    <td style={blueTd}>{item.eobWorkedAmount}</td>
                    <td style={blueTd}>{item.eraWorkedAmount}</td>
                    <td style={blueTd}>{item.payerCollectedAmountTotal}</td>
                    <td style={blueTd}>{item.patStatcollectedamount}</td>
                  </tr>
                  {item.children && item.children.length > 0 && (
                    <tr>
                      <td colSpan="9" style={{ padding: 0 }}>
                        <table
                          style={{
                            border: "1px solid black",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={nestedTableHeaderStyle}>
                                Practice Name
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Date of Entry
                              </th>
                              <th style={nestedTableHeaderStyle}>Charges $</th>
                              <th style={nestedTableHeaderStyle}>
                                Rejections $
                              </th>
                              <th style={nestedTableHeaderStyle}>Auth $</th>

                              <th style={nestedTableHeaderStyle}>EOBs $</th>
                              <th style={nestedTableHeaderStyle}>ERAs $</th>
                              <th style={nestedTableHeaderStyle}>
                                Account Receivable $
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Patient Statements $
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.children.map((child, childIndex) => (
                              <tr key={`child-${childIndex}`}>
                                <td style={yellowTd}>{child.label}</td>
                                <td style={yellowTd}>{child.date}</td>
                                <td style={yellowTd}>
                                  {child.chargeamountval}
                                </td>
                                <td style={yellowTd}>
                                  {child.rejectionsamountval}
                                </td>
                                <td style={yellowTd}>{child.Authamountval}</td>

                                <td style={yellowTd}>{child.eobamountval}</td>
                                <td style={yellowTd}>{child.eraamountval}</td>
                                <td style={yellowTd}>
                                  {child.generalamountval}
                                </td>
                                <td style={yellowTd}>
                                  {child.patStatamountval}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  let pdfTableThresHold = (
    <div
      className="App container mt-5"
      style={{ fontFamily: "Arial, sans-serif" }}
    >
      <div id="divToPrint" style={{ display: "none" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Threshold Report
        </h2>
        <table
          style={{
            border: "1px solid black",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Users</th>
              <th style={tableHeaderStyle}>Portals %</th>
              <th style={tableHeaderStyle}>Call %</th>
              <th style={tableHeaderStyle}>No.of Pre Authorization %</th>
              <th style={tableHeaderStyle}>No of Rejections %</th>
              <th style={tableHeaderStyle}>Manual %</th>
              <th style={tableHeaderStyle}>Electronically %</th>
              <th style={tableHeaderStyle}>No of Denials %</th>
              <th style={tableHeaderStyle}>General Payer %</th>
              <th style={tableHeaderStyle}>Special Payer %</th>
            </tr>
          </thead>
          <tbody>
            {helpingState.rowsToExport &&
              helpingState.rowsToExport.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={`parent-${index}`}>
                    <td style={blueTd}>{item.label || 0}</td>
                    <td style={blueTd}>{item.portals || 0}</td>
                    <td style={blueTd}>{item.calls || 0}</td>
                    <td style={blueTd}>{item.Auth || 0}</td>
                    <td style={blueTd}>{item.rejections || 0}</td>
                    <td style={blueTd}>{item.eobs || 0}</td>
                    <td style={blueTd}>{item.eras || 0}</td>
                    <td style={blueTd}>{item.sumDenials || 0}</td>
                    <td style={blueTd}>{item.generalpayers || 0}</td>
                    <td style={blueTd}>{item.specialpayers || 0}</td>
                  </tr>
                  {item.children && item.children.length > 0 && (
                    <tr>
                      <td colSpan="10" style={{ padding: 0 }}>
                        <table
                          style={{
                            border: "1px solid black",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={nestedTableHeaderStyle}>
                                Practice Name
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                Date of Entry
                              </th>
                              <th style={nestedTableHeaderStyle}>Portals</th>
                              <th style={nestedTableHeaderStyle}>Calls</th>
                              <th style={nestedTableHeaderStyle}>Rejections</th>
                              <th style={nestedTableHeaderStyle}>
                                No of Pre Authorization
                              </th>
                              <th style={nestedTableHeaderStyle}>Manual</th>
                              <th style={nestedTableHeaderStyle}>
                                Electronically
                              </th>
                              <th style={nestedTableHeaderStyle}>
                                No of Denials
                              </th>
                              <th style={nestedTableHeaderStyle}>A/R</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.children.map((child, childIndex) => (
                              <tr key={`child-${childIndex}`}>
                                <td style={yellowTd}>{child.label || 0}</td>
                                <td style={yellowTd}>{child.date || 0}</td>
                                <td style={yellowTd}>{child.vobPortal || 0}</td>
                                <td style={yellowTd}>{child.vobCalls || 0}</td>
                                <td style={yellowTd}>
                                  {child.rejectionsworkedval || 0}
                                </td>
                                <td style={yellowTd}>
                                  {child.Authworkedval || 0}
                                </td>
                                <td style={yellowTd}>
                                  {child.eobworkedval || 0}
                                </td>
                                <td style={yellowTd}>
                                  {child.eraworkedval || 0}
                                </td>
                                <td style={yellowTd}>
                                  {child.sumDenialChildren || 0}
                                </td>
                                <td style={yellowTd}>
                                  {child.generalPayerworkedval || 0}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const handleGeneratePdf = async () => {
    const doc = new jsPDF();

    //get table html
    const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const customPageWidth = 1000; // e.g., for a larger width
    const customPageHeight = 1000; // e.g., for a larger height
    const pageOptions = {
      size: [customPageWidth, customPageHeight],
    };

    const documentDefinition = {
      content: html,
      pageOrientation: "landscape", // Set the orientation to 'landscape' for a wider page
      pageSize: { width: customPageWidth, height: customPageHeight },
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  useEffect(() => {
    if (helpingState.rowsToExport.length > 0) {
      handleGeneratePdf();
    } else {
    }
  }, [helpingState.rowsToExport]);

  const disabledDateRange = (date) => {
    const today = new Date();
    const nextMonth = moment(today).add(1, "month").toDate();
    // Disable dates in the future
    if (date > today) {
      return true;
    }

    // Disable dates in the next month
    if (date >= nextMonth) {
      return true;
    }

    return false;
  };

  const handleExport = (arg) => {
    printDocument(arg);
  };
  const handleClose = () => {
    sethelpingState((prev) => ({ ...prev, isThressmallModelOpen: false }));
  };
  const renderMenu = (menu) => {
    if (userList == null || userList.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          No Users Found
        </p>
      );
    }
    return menu;
  };

  // Now, you have your JSX code inside the template string without errors.
  const options = [
    "Export To Pdf (According to Worked Claim View)",
    "Export To Pdf (Threshold Report)",
  ];

  console.log("dateForgrid", dateForgrid);
  const mountRef = useRef();
  const mountRef1 = useRef();
  const mountRef2 = useRef();
  const mountRef3 = useRef();
  const mountRef4 = useRef();
  console.log("DataForTableBro", dateForgrid);

  return (
    <>
      {helpingState?.isThressmallModelOpen ? (
        <SmallModel
          row={helpingState?.rowThresPop}
          data={helpingState?.thresDocuhubRec}
          handleClose={handleClose}
        />
      ) : null}
      {helpingState?.practiceWisePopType == "thres"
        ? pdfTableThresHold
        : helpingState?.headerName == "both"
        ? pdfTableCombine
        : helpingState?.headerName == "Worked"
        ? pdfTable
        : pdfTableAmount}

      {helpingState.loading ? (
        <>
          <div className="loaderbgpopup">
            <div className="loaderdivpopup"></div>
          </div>
        </>
      ) : null}
      {pracPopup ? (
        <PracticeView
          handleUserWiseRowData={handleUserWiseRowData}
          state={state}
          handleClosePracticeWisePopup={handleClosePracticeWisePopup}
          practiceWiseID={practiceWiseID}
          pracPopup={pracPopup}
          formType={formType}
          startDatee={startDatee}
          endDate={endDate}
          products={products}
          helpingStatePrev={helpingState}
        />
      ) : null}

      <>
        <div class="row mt-4">
          <div class="col-sm-6 col-lg-6" ref={mountRef3}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Date Range
              </label>

              <DateRangePicker
                container={() => mountRef3.current}
                disabledDate={disabledDateRange}
                appearance="default"
                placeholder="Please Select Date Range"
                defaultValue={[new Date(), new Date()]}
                value={[
                  moment(
                    state.sdate ? state.sdate : new Date(),
                    "YYYY-MM-DD"
                  ).toDate(),
                  moment(
                    state.edate ? state.edate : new Date(),
                    "YYYY-MM-DD"
                  ).toDate(),
                ]}
                //  moment(endDate, 'YYYY-MM-DD').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (z) {}')]}
                onChange={handleVal}
                onKeyDown={handleKeyDown}
                style={{ width: "50rem" }}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-3" ref={mountRef1}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Shift Type{" "}
                {formTypeString == "OverAll Verifications" && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </label>
              <br></br>
              <div>
                <Select
                  options={
                    onLoginSignOff?.shift == "3"
                      ? [
                          { label: "Morning Shift", value: "1" },
                          { label: "Night Shift", value: "2" },
                          { label: "Both", value: "3" },
                        ]
                      : onLoginSignOff?.shift == "2"
                      ? [{ label: "Night Shift", value: "2" }]
                      : onLoginSignOff?.shift == "1"
                      ? [{ label: "Morning Shift", value: "1" }]
                      : []
                  }
                  placeholder="Select an option"
                  onChange={handleShiftType}
                  value={{
                    label: helpingState?.ShiftType?.label,
                    value: helpingState?.ShiftType?.value,
                  }}
                  styles={customStyles}
                />
              </div>

              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "13px", color: "red" }}
              >
                {formTypeString == "OverAll Verifications" && errorModel.pidies
                  ? "Practice Name is Required"
                  : null}
              </label>
            </div>
          </div>

          <div class="col-sm-3 col-lg-3" ref={mountRef2}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Audit Type
              </label>
              <CheckPicker
                container={() => mountRef2.current}
                virtualized
                label="Audit Type"
                data={["Worked Claim", "Worked Amount", "Hourly Report"].map(
                  (item) => ({
                    label: item,
                    value: item,
                  })
                )}
                style={{ width: 500 }}
                onChange={handleInputChange}
                block={false}
                value={
                  helpingState?.auditParamType
                    ? helpingState?.auditParamType
                    : []
                }
                countable
                renderExtraFooter={() => (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        state?.uidies &&
                        state?.uidies.length > 0 &&
                        state?.uidies.length < state?.uidies.length
                      }
                      checked={helpingState?.AllAuditTypeCheck}
                      onChange={handleAllAuditType}
                    >
                      Select All
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div>

          <div class="col-sm-6 col-lg-6" ref={mountRef1}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Practice Name{" "}
                {formTypeString == "OverAll Verifications" && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </label>
              <br></br>
              <div ref={mountRef1}>
                <CheckPicker
                  container={() => mountRef1.current}
                  virtualized
                  label="Practice"
                  data={products ? products : []}
                  style={{ width: 1000 }}
                  onChange={onSelectPractice}
                  block={false}
                  value={state?.pidies ? state?.pidies.split(",") : []}
                  countable
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        container={() => mountRef1.current}
                        indeterminate={
                          state?.pidies &&
                          state?.pidies.length > 0 &&
                          state?.pidies.length < state?.pidies.length
                        }
                        checked={helpingState?.AllPracticeCheck}
                        onChange={checkAllPractices}
                      >
                        Select All Practices
                      </Checkbox>
                    </div>
                  )}
                />
              </div>

              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "13px", color: "red" }}
              >
                {formTypeString == "OverAll Verifications" && errorModel.pidies
                  ? "Practice Name is Required"
                  : null}
              </label>
            </div>
          </div>

          {/* <div class="col-sm-3 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Audit Type
            </label>
            <Select
              options={[
                { label: "OverAll Verifications", value: "01" },
                { label: "User Productivity", value: "02" },
              ]}
              placeholder="Select an option"
              onChange={handlePracticeVal}
              // defaultValue={[{value:result?.generalpayerName,label:result?.generalpayerID}]}
              value={[{ label: state.auditType, value: state.auditType }]}
              //  styles={customStyles}
            />
          </div>
        </div> */}
          {formTypeString == "OverAll Verifications" && (
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  FormType{" "}
                  {formTypeString == "OverAll Verifications" && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
                <Multiselect
                  options={[
                    { name: "Charge Entry ", id: 1 },
                    { name: "Rejections", id: 2 },
                    { name: "Authorization", id: 3 },
                    { name: "ELIGIBILITY/VOBS", id: 4 },

                    { name: "EOB`s", id: 5 },

                    { name: "ERA`s", id: 6 },

                    { name: "Account Receivable", id: 7 },
                    { name: "Patient Statement", id: 9 },
                  ]} // Options to display in the dropdown
                  // selectedValues={'Please Select Form Type'} // Preselected value to persist in dropdown
                  onSelect={handleFormType} // Function will trigger on select event
                  onRemove={onRemoveFormType} // Function will trigger on remove event
                  style={objCss}
                  showArrow={true}
                  showCheckbox={true}
                  placeholder="Please Select Form Type"
                  displayValue="name" // Property name to display in the dropdown options
                />
                <label
                  class="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                >
                  {formTypeString == "OverAll Verifications" &&
                  errorModel.tuidies
                    ? "Form Type is Required"
                    : null}
                </label>
              </div>
            </div>
          )}

          {/* <div class="col-sm-3 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              End Date
            </label>
            <input
              class="form-control inputField"
              type="date"
              name="endDate"
              placeholder=""
              max={today}
              min={minDate}
              defaultValue={date}
              onChange={handleVal}
            />
          </div>
        </div> */}

          {/* <div class="col-sm-6 col-lg-6" ref={mountRef3}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Audit Type
              </label>
              <CheckPicker
                container={() => mountRef2.current}
                virtualized
                label="Audit Type"
                data={["Worked Claim", "Worked Amount", "Per Hour Threshold"].map(
                  (item) => ({
                    label: item,
                    value: item,
                  })
                )}
                style={{ width: 500 }}
                onChange={handleInputChange}
                block={false}
                value={
                  helpingState?.auditParamType
                    ? helpingState?.auditParamType
                    : []
                }
                countable
                renderExtraFooter={() => (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        state?.uidies &&
                        state?.uidies.length > 0 &&
                        state?.uidies.length < state?.uidies.length
                      }
                      checked={helpingState?.AllAuditTypeCheck}
                      onChange={handleAllAuditType}
                    >
                      Select All
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div> */}

          <div class="col-sm-3 col-lg-3" ref={mountRef}>
            <div class="form-group mb-2">
              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "12px" }}
              >
                Users{" "}
                {formTypeString == "OverAll Verifications" && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </label>

              <div>
                <CheckPicker
                  container={() => mountRef.current}
                  renderMenu={renderMenu}
                  virtualized
                  label="Users"
                  data={userList ? userList : []}
                  style={{ width: 1000 }}
                  onChange={onSelectUser}
                  // onSelect={helpingState?.uidies}
                  block={false}
                  value={state?.uidies ? state?.uidies : []}
                  countable
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          state?.uidies &&
                          state?.uidies.length > 0 &&
                          state?.uidies.length < state?.uidies.length
                        }
                        checked={helpingState?.AllUserChecked}
                        onChange={handleAllUser}
                      >
                        Select All Users
                      </Checkbox>

                      {/* <Button
                      style={footerButtonStyle}
                      appearance="primary"
                      className=""
                      size="sm"
                      onClick={() => {
                        picker.current.close();
                      }}
                    >
                      Ok
                    </Button> */}
                    </div>
                  )}
                />
              </div>

              <label
                class="inputLabel"
                style={{ color: "black", fontSize: "13px", color: "red" }}
              >
                {formTypeString == "OverAll Verifications" && errorModel.uidies
                  ? "Users is Required"
                  : null}
              </label>
            </div>
          </div>
          {(helpingState?.ShiftType?.value == "2" ||
            helpingState?.ShiftType?.value == "3") && (
            <div class="col-sm-3 col-lg-3" ref={mountRef}>
              <div class="form-group mb-2">
                <label
                  class="inputLabel"
                  style={{ color: "black", fontSize: "12px" }}
                >
                  Team Type{" "}
                  {formTypeString == "OverAll Verifications" && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
                <br></br>
                {/* <Multiselect
                  options={products ? products : []} // Options to display in the dropdown
                  selectedValues={helpingState.practicesSelection} // Preselected value to persist in dropdown
                  onSelect={onSelectPractice} // Function will trigger on select event
                  onRemove={onSelectPracticeRemove} // Function will trigger on remove event
                  style={objCss}
                  showArrow={true}
                  showCheckbox={true}
                  placeholder="Please Select Practice"
                  displayValue="label" // Property name to display in the dropdown options
                /> */}
                <div>
                  <CheckPicker
                    virtualized
                    container={() => mountRef.current}
                    label="Team"
                    data={helpingState?.TeamsInfo}
                    style={{ width: 1000 }}
                    onChange={onSelecTeam}
                    sticky
                    value={
                      helpingState?.TeamsInfoId ? helpingState?.TeamsInfoId : []
                    }
                    countable
                    renderExtraFooter={() => (
                      <div style={footerStyles}>
                        <Checkbox
                          indeterminate={
                            helpingState?.TeamsInfoId &&
                            helpingState?.TeamsInfoId.length > 0 &&
                            helpingState?.TeamsInfoId <
                              helpingState?.TeamsInfoId.length
                          }
                          checked={helpingState?.TeamsAllCheck}
                          onChange={onSelectAllTeam}
                        >
                          Select All Teams
                        </Checkbox>
                      </div>
                    )}
                  />
                </div>
                <label
                  class="inputLabel"
                  style={{ color: "black", fontSize: "13px", color: "red" }}
                >
                  {formTypeString == "OverAll Verifications" &&
                  errorModel.pidies
                    ? "Practice Name is Required"
                    : null}
                </label>
              </div>
            </div>
          )}

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4"></div>
            </div>
          </div>
        </div>

        <div className="mt-4"></div>
        <div class="form-group mb-2 ">
          <div class="d-flex justify-content-end ">
            <button type="button" className="btnClear mr-1 " onClick={onClear}>
              Clear
            </button>
            <button type="button" className="btnSave" onClick={onSearch}>
              Search
            </button>
            <div className="ml-2">
              <ButtonGroup>
                <Button
                  className="btn btn-primary"
                  onClick={() => handleExport(helpingState.action)}
                >
                  {options[helpingState.action]}
                </Button>
                <Whisper
                  placement="bottomEnd"
                  trigger="click"
                  speaker={({ onClose, left, top, className }, ref) => {
                    const handleSelect = (eventKey) => {
                      onClose();
                      sethelpingState((prev) => ({
                        ...prev,
                        action: eventKey,
                      }));
                      printDocument(eventKey);
                    };
                    return (
                      <Popover
                        ref={ref}
                        className={className}
                        style={{ left, top }}
                        full
                      >
                        <Dropdown.Menu onSelect={handleSelect}>
                          {options.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={index}>
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Popover>
                    );
                  }}
                >
                  <IconButton
                    style={{ backgroundColor: "#0e7490", color: "white" }}
                    icon={<ArrowDownIcon />}
                  />
                </Whisper>
              </ButtonGroup>
            </div>
          </div>
        </div>

        {formTypeString == "OverAll Verifications" && (
          <AuditTable dateForgrid={dateForgrid} state={state} loader={loader} />
        )}

        {formTypeString == "UserWise Productivity" &&
          (helpingState?.auditParamTypeCopy.includes("Worked Claim") ||
            helpingState?.auditParamTypeCopy.includes("Worked Amount")) && (
            <>
              <div>
                {helpingState?.headerName != "both" && (
                  <UserAuditTable
                    Footer={
                      helpingState?.headerName == "Worked"
                        ? Footer
                        : FooterAmount
                    }
                    flagFooter={
                      helpingState?.headerName == "both"
                        ? "ReportCombine"
                        : helpingState?.headerName == "Worked"
                        ? "Report"
                        : "ReportAmount"
                    }
                    isPaginationShow={true}
                    rowShown={5}
                    tabelSizee={tabelSizee}
                    sizee={sizee}
                    previewButton
                    getRequriedPara={getRequriedPara}
                    isShowExportButton={true}
                    dateForgrid={dateForgrid}
                    Array={
                      helpingState?.headerName == "Worked"
                        ? arr
                        : arrworkedAmount
                    }
                    onChangePop={onChangePop}
                    loader={loader}
                    reportName={"PracticeWise"}
                  />
                )}
              </div>
              {helpingState.headerName == "both" && (
                <>
                  <div className="col-lg-12 mb-2 text-center">
                    <h4
                      class="inputLabel "
                      style={{
                        color: "black",
                        borderBottom: "2px solid rgb(14, 116, 144)",
                        paddingBottom: "10px",
                      }}
                    >
                      Worked Claim Table
                    </h4>
                  </div>
                  <UserAuditTable
                    Footer={Footer}
                    flagFooter={"Report"}
                    isPaginationShow={true}
                    rowShown={5}
                    tabelSizee={tabelSizee}
                    sizee={sizee}
                    previewButton
                    getRequriedPara={getRequriedPara}
                    isShowExportButton={true}
                    dateForgrid={dateForgrid}
                    Array={arr}
                    onChangePop={onChangePop}
                    loader={loader}
                    reportName={"PracticeWise"}
                  />

                  <div className="col-lg-12 mb-2 text-center">
                    <h4
                      class="inputLabel "
                      style={{
                        color: "black",
                        borderBottom: "2px solid rgb(14, 116, 144)",
                        paddingBottom: "10px",
                      }}
                    >
                      Worked Amount Table
                    </h4>
                  </div>

                  <UserAuditTable
                    Footer={FooterAmount}
                    flagFooter={"ReportAmount"}
                    isPaginationShow={true}
                    rowShown={5}
                    tabelSizee={tabelSizee}
                    sizee={sizee}
                    previewButton
                    getRequriedPara={getRequriedPara}
                    isShowExportButton={true}
                    dateForgrid={dateForgrid}
                    Array={arrworkedAmount}
                    onChangePop={onChangePop}
                    loader={loader}
                    reportName={"PracticeWise"}
                  />
                </>
              )}
            </>
          )}

        {helpingState?.isThresholdShow ? (
          <>
            <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Hourly Report
              </h4>
            </div>
            <div className="mt-4"></div>
            <div
              className={helpingState?.tableSizeThres ? "UserAuditTable" : ""}
            >
              <UserAuditTable
                isPaginationShow={true}
                callPopThresHold={callPopThresHold}
                isUserAllow={true}
                getRequriedPara={getRequriedPara}
                rowShown={5}
                previewButton
                isShowExportButton={true}
                dateForgrid={helpingState.thresholdRecord}
                Array={thresholdCol}
                onChangePop={onChangePop}
                loader={loader}
                reportName={"Thres"}
                tabelSizee={helpingState?.tableSizeThres}
                sizee={sizee}
              />
            </div>
          </>
        ) : null}
      </>
    </>
  );
}
export default AuditField;
