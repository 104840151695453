import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";


import { useNavigate } from "react-router-dom";

import logo from "../../Assets/images/telxyLogo.svg";


// import "../../Assets/css/login.css";

import { LoginSignOFF, demoApi } from "../../Utils/ApiManager";
import { onLoginSignOFF } from "../../Actions/Actions";
import { toast } from "react-toastify";
import SVGComponent from "../SvgComp/Svh";
import LoginSvg from '../../Assets/images/LoginPage.svg'
import greenBlock from '../../Assets/images/greenBlock.png'
import Loader from "../../Common/Loader/Loader";


function Login() {
  //-------------- All Global variable Section-----------
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const { IsUrlsCameFromEhrr, RoomInfo } = useSelector((state) => state);
  //----------Alll States -------------------
  const [username, setUsername] = useState("");
  const [NavbarToogle, setNavbarToogle] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingg, setLoadingg] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const openModal = () => {
    setToggleModal(true);
  };



  useEffect(() => {
    if (username) {
    } else {
      setError(false);
    }
  }, [username]);

  const loginUserr = async () => {

    setLoading(true);
    if (username === "") {
      setUserNameError(true);
      setLoading(false);
      return;
    } else {
      setUserNameError(false);
    }
    if (password === "") {
      setPasswordError(true);
      setLoading(false);
      return;
    } else {
      setPasswordError(false);
    }
    const formData = new FormData()
    formData.append('login', username)
    formData.append('username', username)
    formData.append('password', password)
    LoginSignOFF(formData).then((el) => {

      dispatch(onLoginSignOFF(el.data))

      if (el.data.message == 'User Logged In Successfully') {
        navigate("/Dashboard");

      }


    }).catch((response) => {

      console.log('response', response.response
      )

      if (response?.response?.status === 422) {

        toast.info(response.data.message)

      }
      if (response?.response?.status == 404) {
        setLoading(false)

        toast.info(response.response.data.message)

      }


    })


    // 

  };

  const onClear = () => {

    setUsername('')
    setPassword('')
  }
  const demoApiA=()=>{
    demoApi()
  }


  const isValidName = () => {


    if (username) {

    } else {
      setError(false);
    }
    if (username === '' || username === null) {
      setError(false);
    }
  };


  const handleUserName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    value = value;
    if (name === "username") {

      setError(false);
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const onEnterLogin = (e) => {
    if (e.keyCode === 13) {
      loginUserr();
    }
  };

  return (
    <>

      {loadingg ? (
        <Loader small={false} large={true} forButton={true} />
      ) : (
        <>
          {/* <div className="LoginPagePublic">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="navbar-brand">
              
                <div style={{left:'80px'}}>
                <SVGComponent  height={30} top={62} left={52}/>

                </div>
              
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setNavbarToogle(!NavbarToogle)}
              >
                <span className="icon-menu fas fa-bars text-white"></span>
              </button>

              <div
                className={
                  NavbarToogle
                    ? "collapse navbar-collapse show"
                    : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >

              </div>
            </nav>
            <div className=" main ">
              <div className="logininLeft">
                <div
                  style={{ height: "92vh" }}
                  className="d-flex  justify-content-center align-items-start mt-5"
                >
                  <div className="w-75">
                    <div className="containter">
                      <div
                        id="carouselExampleFade"
                        className="carousel slide carousel-fade"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner p-3">
                          <div className="carousel-item active">
                            <h5>Reporting</h5>
                            <p>Detail Reports of your Calls </p>
                          </div>
                          <div className="carousel-item">
                            <h5>Video Consulation</h5>
                            <p>Best Video Consulation Experience </p>
                          </div>
                          <div className="carousel-item">
                            <h5>Online Payment </h5>
                            <p>Change Package of your Choice anytime </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="logininRight">
                <div
                  style={{ height: "calc(100vh - 59px)" }}
                  className="d-flex justify-content-center 
             align-items-center"
                >
                  <div className="border p-5 rounded loginForm">
                    <h2 className="form-title  fw-bolder text-center   text-primary">
                      Log in{" "}
                    </h2>

                    <div className="mt-5">
                      <div>
                        <label>Username</label>
                        <input
                          className="form-group"
                          type="text"
                          name="username"
                          id="username"
                          placeholder="Email "
                          onChange={handleUserName}
                          onKeyDown={onEnterLogin}
                          aria-describedby={userNameError ? "invalid" : "valid"}
                          onBlur={() => {
                            if (username) isValidName();
                          }}
                        />
                        {userNameError ? (
                          <p className="text-danger">
                            UserName is Empty
                          </p>
                        ) : (
                          ""
                        )}

                      </div>

                      <div className=" pb-0 mb-0">
                        <label>Password</label>
                        <input
                          className="form-group "
                          type="password"
                          name="password"
                          id="your_pass"
                          placeholder="Password"
                          onChange={handleUserName}
                          onKeyDown={onEnterLogin}
                          aria-describedby={passwordError ? "invalid" : "valid"}
                        />
                        {passwordError ? (
                          <p className="text-danger">
                            Password is Empty
                          </p>
                        ) : (
                          ""
                        )}

                      </div>

                      <div className="bg-orange d-flex justify-content-center ">
                        <button
                          style={{
                            width: "140px",
                            marginTop: "10px",
                            borderRadius: "5px",
                            height: "42px",
                          }}
                          className="btn btn-SignIn pl-3 pr-3 "
                          onClick={loginUserr}
                          disabled={isLoading}
                        >
                          Log in
                          {isLoading ? (
                            <Loader
                              small={true}
                              large={false}
                              forButton={true}
                            />
                          ) : undefined}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}



          <div className="loginPg">
            <div className="loginPgVertLine">

            </div>
            <div className="loginPgForm">
            {/* <button onClick={demoApiA}>demo button</button> */}
              <form>
                <div className="mb-4">
                

                </div>
                <h3 className="mt-4">Login to your account!</h3>
                <div className="formGroup mt-4">
                  <label>User Name</label>

                  <input
                    className="form-control"
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    placeholder="UserName "
                    onChange={handleUserName}
                    onKeyDown={onEnterLogin}
                    aria-describedby={userNameError ? "invalid" : "valid"}
                    onBlur={() => {
                      if (username) isValidName();
                    }}



                  />

                  <span class="fielderror">
                    {userNameError ? (
                      <span>
                        UserName is Empty
                      </span>
                    ) : (
                      ""
                    )}

                  </span>
                  <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="formGroup mt-4">
                  <label>Enter password</label>
                  <div className="d-flex">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      id="your_pass"
                      placeholder="Password"
                      onChange={handleUserName}
                      onKeyDown={onEnterLogin}
                      value={password}
                      aria-describedby={passwordError ? "invalid" : "valid"}

                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i
                          class="fa fa-eye viewIconCol"
                          onClick={() => setShowPassword(prev => !prev)}

                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>

                  <span class="fielderror">
                    {passwordError ? (
                      <span>
                        Password is Empty
                      </span>
                    ) : (
                      ""
                    )}

                  </span>
                  <div className="mt-3">
                    <a
                      href="#/"

                    >
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    className="btnOutline01 mr-1"
                    onClick={onClear}
                  >
                    Clear

                  </button>
                  <button
                    type="button"
                    className="btnFilled02"
                    id="loginBtn"
                    onClick={loginUserr}
                    disabled={isLoading}

                  >
                    Login
                    {isLoading ? (
                      <Loader
                        small={true}
                        large={false}
                        forButton={true}
                      />
                    ) : undefined}
                  </button>
                </div>
              </form>
            </div>
            <div className="loginPgContent">
              {/* <div className="loginFeature01">
                <div className="logodiv"> <SVGComponent height={130} top={2} left={1} /></div>
                <div className="loginFeatureCircle01">

                </div>
                <div>
                  <div className="lFcircle01">Check Eligibility</div>
                  <p>
                    We are expert with payers as well as patients to verify
                    eligibility and obtain authorizations for services or
                    procedures to be provided ...
                  </p>
                </div>
              </div>
              <div className="loginFeature02">
                <div className="loginFeatureCircle02">

                </div>
                <div>
                  <div className="lFcircle02">Get Instant Reports</div>
                  <p>
                    Reports are a great tool for helping you spot insurance
                    payments and ERA ...
                  </p>
                </div>
              </div>
              <div className="loginFeature03">
                <div className="loginFeatureCircle03">

                </div>
                <div>
                  <div className="lFcircle03">Find Claim Status</div>
                  <p>
                    Keep track of all of your claims with our integrated claim
                    submission reports, which provide a clear picture of what's
                    going on with your claims. ...
                  </p>
                </div>
              </div>
              <div className="loginFeature04">
                <div className="loginFeatureCircle04">

                </div>
                <div>
                  <div className="lFcircle04">User Setup</div>
                  <p>
                    {" "}
                    Easy to use Interface - Fusion EDI is the finest and
                    straightforward alternative for all medical claims
                    processing ...
                  </p>
                </div>
              </div> */}
            <img src={LoginSvg}></img>
            </div>
          </div>
        </>
      )}
    </>


  );
}

export default Login;
