import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { CheckPicker } from "rsuite";
import { Checkbox } from "rsuite";

import { DateRangePicker } from "rsuite";
import {
  getThresholdReportFromDoc,
  getUsersByTeamsRoleBased,
} from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";

import UserAuditTable from "../userTaskViewTable";
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

export default function Threshold({ products }) {
  const footerStyles = {
    padding: "10px 2px",
    borderTop: "1px solid #e5e5e5",
  };
  const mountRef1 = useRef();
  const mountRef3 = useRef();
  const mountRef = useRef();
  const { onLoginSignOff } = useSelector((state) => state);

  useEffect(() => {
    getTeams();
    onSearch();
  }, []);

  let helpingobj = {
    pidies: onLoginSignOff.idies,
    AllPracticeCheck: false,
    sdate: moment(new Date()).format("YYYY-MM-DD"),
    edate: moment(new Date()).format("YYYY-MM-DD"),
    TeamsInfo: [],
    TeamsAllCheck: false,
    TeamsInfoId: null,
    DataDocu: [],
  };

  const [tabelSizee, settabelSizee] = useState(true);

  const [helpingState, sethelpingState] = useState(helpingobj);

  const onSelectAllTeam = (e, check) => {
    if (check) {
      const allTeamLabels = helpingState.TeamsInfo.map((el) => el.value);
      sethelpingState((prev) => ({
        ...prev,
        TeamsAllCheck: check,
        TeamsInfoId: allTeamLabels,
      }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        TeamsAllCheck: check,
        TeamsInfoId: [],
      }));
    }
  };

  const getTeams = async () => {
    let formData = new FormData();
    formData.append("get_user", onLoginSignOff.userid);

    const res = await getUsersByTeamsRoleBased(formData);

    let vpTeams = [];

    vpTeams = res.data.vpteams;
    vpTeams?.push(res.data.team);

    let DataTeam =vpTeams && vpTeams.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    sethelpingState((prev) => ({ ...prev, TeamsInfo: DataTeam }));
  };

  const onSelectPractice = async (selectedOption) => {
    let pulist;

    if (selectedOption.length == 0) {
      sethelpingState((prevState) => ({
        ...prevState,
        pidies: "",
        AllPracticeCheck: false,
      }));

      return;
    }

    pulist = selectedOption.map((item) => item).join(",");

    sethelpingState((prevState) => ({
      ...prevState,
      pidies: pulist,
    }));
  };

  const checkAllPractices = async (e, checked) => {
    if (checked) {
      let pulist = products
        .filter((item) => !item?.id) // Filter out items without an 'id'
        .map((item) => item?.value) // Extract 'value' property
        .join(",");

      sethelpingState((prev) => ({
        ...prev,
        AllPracticeCheck: true,
        pidies: pulist,
      }));
    } else {
      sethelpingState((prev) => ({
        ...prev,
        AllPracticeCheck: false,
        pidies: "",
      }));
    }
  };

  const handleVal = (event) => {
    if (event == null) {
      sethelpingState((prev) => ({ ...prev, sdate: null, edate: null }));

      return;
    }

    const formattedDates =
      event && event.map((date) => moment(date).format("YYYY-MM-DD"));

    sethelpingState((prev) => ({
      ...prev,
      sdate: formattedDates[0],
      edate: formattedDates[1],
    }));
  };

  const vob = [
    {
      colName: `Practice Name`,
      flexGrow: 2.7,
      dataKey: "pname",
      rowSpan: 1,
    },
    {
      colName: `Is  Charge Manual`,
      flexGrow: 2.1,
      dataKey: "is_thresh_charge_manual",
      rowSpan: 1,
    },
    {
      colName: `Is  Charge Demographic`,
      flexGrow: 2.2,
      dataKey: "is_thresh_charge_demo_graphics",
      rowSpan: 1,
    },
    {
      colName: `Charge`,
      flexGrow: 1.7,
      dataKey: "thresh_charge_entry",
      rowSpan: 1,
    },

    {
      colName: `Is Charge Electronically`,
      flexGrow: 2.6,
      dataKey: "is_thresh_charge_electronically",
      rowSpan: 1,
    },
    {
      colName: `Electronically`,
      flexGrow: 2.1,
      dataKey: "thresh_electronically",
      rowSpan: 1,
    },
    {
      colName: `Rejections`,
      flexGrow: 2.1,
      dataKey: "thresh_rejections",
      rowSpan: 1,
    },
    {
      colName: `Is Call`,
      flexGrow: 1.5,
      dataKey: "is_thresh_eligibility_call",
      rowSpan: 1,
    },
    {
      colName: `Eligibility`,
      flexGrow: 1.5,
      dataKey: "thresh_eligibility",
      rowSpan: 1,
    },
    {
      colName: `Portal`,
      flexGrow: 2,
      dataKey: "thresh_eligibility_portal",
      rowSpan: 1,
    },
    {
      colName: `Authorization`,
      flexGrow: 1.9,
      dataKey: "thresh_authorization",
      rowSpan: 1,
    },
    {
      colName: `Is Posting Manual`,
      flexGrow: 1.9,
      dataKey: "is_thresh_posting_manual",
      rowSpan: 1,
    },
    {
      colName: `Posting`,
      flexGrow: 1.9,
      dataKey: "thresh_posting",
      rowSpan: 1,
    },
    {
      colName: `is Posting Electronically`,
      flexGrow: 1.9,
      dataKey: "is_thresh_posting_electronically",
      rowSpan: 1,
    },
    {
      colName: `Denails`,
      flexGrow: 1.9,
      dataKey: "thresh_denials",
      rowSpan: 1,
    },
    {
      colName: `General Payer`,
      flexGrow: 1.9,
      dataKey: "general_payer",
      rowSpan: 1,
    },
    {
      colName: `Special Payer`,
      flexGrow: 1.9,
      dataKey: "special_payer",
      rowSpan: 1,
    },
    {
      colName: `Claims Per Hours`,
      flexGrow: 1.9,
      dataKey: "claims_per_hours",
      rowSpan: 1,
    },
    {
      colName: `Claims Review Per Hours`,
      flexGrow: 1.9,
      dataKey: "claims_review_per_hours",
      rowSpan: 1,
    },
  ];

  const disabledDateRange = (date) => {
    const today = new Date();
    const nextMonth = moment(today).add(1, "month").toDate();
    // Disable dates in the future
    if (date > today) {
      return true;
    }

    // Disable dates in the next month
    if (date >= nextMonth) {
      return true;
    }

    return false;
  };

  const getRequriedPara = () => {};
  const onSelecTeam = (selectedOption, e) => {
    if (selectedOption.length == 0) {
      sethelpingState((prev) => ({
        ...prev,
        TeamsInfoId: selectedOption,
        TeamsAllCheck: false,
      }));
    } else {
      sethelpingState((prev) => ({ ...prev, TeamsInfoId: selectedOption }));
    }
  };

  const handleGeneratePdf = async () => {
    if (helpingState?.DataDocu.length > 0) {
      const doc = new jsPDF();

      //get table html
      const pdfTable = document.getElementById("divToPrint");
      //html to pdf format
      var html = htmlToPdfmake(pdfTable.innerHTML);
      const customPageWidth = 1500; // e.g., for a larger width
      const customPageHeight = 1500; // e.g., for a larger height
      const pageOptions = {
        size: [customPageWidth, customPageHeight],
      };

      const documentDefinition = {
        content: html,
        pageOrientation: "landscape", // Set the orientation to 'landscape' for a wider page
        pageSize: { width: customPageWidth, height: customPageHeight },
      };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).open();
    } else {
    }
  };

  const onSearch = () => {
    const formData = new FormData();
    formData.append("action", "threshold");
    formData.append("id", onLoginSignOff.userid);
    formData.append("from", helpingState.sdate);
    formData.append("to", helpingState.edate);
    if (helpingState.pidies != "") {
      formData.append("pratice_ids", helpingState.pidies);
    }
    if (helpingState.TeamsInfoId != null) {
      formData.append("team_ids", helpingState.TeamsInfoId);
    }

    getThresholdReportFromDoc(formData)
      .then((res) => {
        if (res.data) {
          sethelpingState((prev) => ({ ...prev, DataDocu: res.data.response }));
        }
      })
      .catch((err) => {});
  };
  const tableHeaderStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#0e7490",

    color: "white",
  };
  const blueTd = {
    backgroundColor: "rgb(240, 249, 255)",
  };

  const sizee = (arg) => {
   // settabelSizee((prev) => !prev);
  };

  let pdfTableAmount = (
    <div className="App container" style={{ fontFamily: "Arial, sans-serif" }}>
      <div id="divToPrint" style={{ display: "none" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Threshold Report
        </h2>
        <table
          style={{
            border: "1px solid black",
            width: "80%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Practice Name</th>
              <th style={tableHeaderStyle}>Is Thresh Charge Manual</th>
              <th style={tableHeaderStyle}>Is Thresh Charge Demographic</th>
              <th style={tableHeaderStyle}>Thresh Charge</th>
              <th style={tableHeaderStyle}>Is Thresh Charge Electronically</th>

              <th style={tableHeaderStyle}>Thresh Electronically</th>
              <th style={tableHeaderStyle}>Thresh Rejections</th>
              <th style={tableHeaderStyle}>Is Thresh Call</th>
              <th style={tableHeaderStyle}>Thresh Eligibility</th>
              <th style={tableHeaderStyle}>Thresh Portal</th>
              <th style={tableHeaderStyle}>Thresh Authorization</th>
              <th style={tableHeaderStyle}>Is Thresh Posting Manual</th>
              <th style={tableHeaderStyle}>Thresh Posting</th>

              <th style={tableHeaderStyle}>Is Thresh Posting Electronically</th>
              <th style={tableHeaderStyle}>Thresh Denails</th>
              <th style={tableHeaderStyle}>General Payer</th>
              <th style={tableHeaderStyle}>Special Payer</th>
              <th style={tableHeaderStyle}>Claims Per Hours</th>
              <th style={tableHeaderStyle}>Claims Review Per Hours</th>
            </tr>
          </thead>
          <tbody>
            {helpingState?.DataDocu &&
              helpingState?.DataDocu.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={`parent-${index}`}>
                    <td style={blueTd}>{item.pname}</td>
                    <td style={blueTd}>{item.is_thresh_charge_manual}</td>
                    <td style={blueTd}>
                      {item.is_thresh_charge_demo_graphics}
                    </td>
                    <td style={blueTd}>{item.thresh_charge_entry}</td>
                    <td style={blueTd}>
                      {item.is_thresh_charge_electronically}
                    </td>
                    <td style={blueTd}>{item.thresh_electronically}</td>
                    <td style={blueTd}>{item.thresh_rejections}</td>
                    <td style={blueTd}>{item.is_thresh_eligibility_call}</td>
                    <td style={blueTd}>{item.thresh_eligibility}</td>

                    <td style={blueTd}>{item.thresh_eligibility_portal}</td>
                    <td style={blueTd}>{item.thresh_authorization}</td>
                    <td style={blueTd}>{item.is_thresh_posting_manual}</td>
                    <td style={blueTd}>{item.thresh_posting}</td>

                    <td style={blueTd}>
                      {item.is_thresh_posting_electronically}
                    </td>
                    <td style={blueTd}>{item.thresh_denials}</td>
                    <td style={blueTd}>{item.general_payer}</td>
                    <td style={blueTd}>{item.special_payer}</td>

                    <td style={blueTd}>{item.claims_per_hours}</td>
                    <td style={blueTd}>{item.claims_review_per_hours}</td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      {pdfTableAmount}
      <div className="mt-3"></div>
      <div className="row">
      <div class="col-sm-6 col-lg-6" ref={mountRef}>
          <div class="form-group mb-2">
            <label
              class="inputLabel"
              style={{ color: "black", fontSize: "12px" }}
            >
              Team Type{" "}
            </label>
            <br></br>

            <div>
              <CheckPicker
                virtualized
                container={() => mountRef.current}
                label="Team"
                data={helpingState?.TeamsInfo||[]}
                style={{ width: 1000 }}
                onChange={onSelecTeam}
                sticky
                value={
                  helpingState?.TeamsInfoId ? helpingState?.TeamsInfoId : []
                }
                countable
                renderExtraFooter={() => (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        helpingState?.TeamsInfoId &&
                        helpingState?.TeamsInfoId.length > 0 &&
                        helpingState?.TeamsInfoId <
                          helpingState?.TeamsInfoId.length
                      }
                      checked={helpingState?.TeamsAllCheck}
                      onChange={onSelectAllTeam}
                    >
                      Select All Teams
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
       

        {/* <div class="col-sm-6 col-lg-6" ref={mountRef3}>
          <div class="form-group mb-2">
            <label
              class="inputLabel"
              style={{ color: "black", fontSize: "12px" }}
            >
              Date Range
            </label>

            <DateRangePicker
              container={() => mountRef3.current}
              disabledDate={disabledDateRange}
              appearance="default"
              placeholder="Please Select Date Range"
              defaultValue={[new Date(), new Date()]}
              value={[
                moment(
                  helpingState.sdate ? helpingState.sdate : new Date(),
                  "YYYY-MM-DD"
                ).toDate(),
                moment(
                  helpingState.edate ? helpingState.edate : new Date(),
                  "YYYY-MM-DD"
                ).toDate(),
              ]}
              //  moment(endDate, 'YYYY-MM-DD').format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ (z) {}')]}
              onChange={handleVal}
              style={{ width: "50rem" }}
            />
          </div>
        </div> */}

        <div class="col-sm-6 col-lg-6" ref={mountRef1}>
          <div class="form-group mb-2">
            <label
              class="inputLabel"
              style={{ color: "black", fontSize: "12px" }}
            >
              Practice Name{" "}
            </label>
            <br></br>
            <div ref={mountRef1}>
              <CheckPicker
                container={() => mountRef1.current}
                virtualized
                label="Practice"
                data={products ? products : []}
                style={{ width: 1000 }}
                onChange={onSelectPractice}
                block={false}
                value={
                  helpingState?.pidies ? helpingState?.pidies.split(",") : []
                }
                countable
                renderExtraFooter={() => (
                  <div style={footerStyles}>
                    <Checkbox
                      container={() => mountRef1.current}
                      indeterminate={
                        helpingState?.pidies &&
                        helpingState?.pidies.length > 0 &&
                        helpingState?.pidies.length <
                          helpingState?.pidies.length
                      }
                      checked={helpingState?.AllPracticeCheck}
                      onChange={checkAllPractices}
                    >
                      Select All Practices
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

      
      </div>
      <div class="form-group mb-2 ">
        <div class="d-flex justify-content-end ">
          <button type="button" className="btnSave" onClick={onSearch}>
            Search
          </button>
          <button
            type="button"
            className="btnSave ml-2"
            onClick={handleGeneratePdf}
          >
            Export to pdf
          </button>
        </div>
      </div>
      <div className={tabelSizee ? "UserAuditTable" : ""}>
        <UserAuditTable
          previewButton
          getRequriedPara={getRequriedPara}
          tableHeight={400}
          rowHeight={48}
          dateForgrid={helpingState?.DataDocu}
          Array={vob}
          rowShown={10}
          loader={false}
          isPaginationShow={true}
          sizee={sizee}
          reportName={"PracticeWise"}
          tabelSizee={tabelSizee}
        />
      </div>
    </>
  );
}
