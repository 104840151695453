import React, { useEffect } from "react";
import "./App.css";
import MainRoutes from "./Components/Routes/MainRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "./Components/Login/Login.css";
// import { SocketContext, socket } from "./context/socket";
import packageJson from "../package.json";

const App = () => {
  const state = useSelector((state) => state);
  console.log("resdux", state);
  console.log("redux", state);
  let dispatch = useDispatch();
  const isProduction =
    process.env.REACT_APP_LOCAL_SERVER_URL ===
    "https://laravel.medifusion.com/";
  const version = packageJson.version;

  return (
    <>
      {/* <SocketContext.Provider value={socket}> */}
        <MainRoutes />
      {/* </SocketContext.Provider> */}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className="toast-message"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
