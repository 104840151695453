import React, { useState } from "react";

import TlInterfaceTabs from "./TlInterfaceTabs";
import TLInterfaceOfCoding from "./TLInterfaceOfCoding";
import TlInterfaceWorkSheet from "./TlInterfaceWorkSheet";
function CodingofTlInterFace() {
  let obj = {
    tabName: "ChargeEntryCoding",
    tabName: "tlWorkSheet",
  };
  const [helpingState, setHelpingState] = useState(obj);
  return (
    <>
      <TlInterfaceTabs setHelpingState={setHelpingState} />
      {helpingState?.tabName == "tlWorkSheet" && <TlInterfaceWorkSheet />}

      {helpingState?.tabName == "ChargeEntryCoding" && <TLInterfaceOfCoding />}
    </>
  );
}
export default CodingofTlInterFace;
