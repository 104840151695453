import React,{useState,useEffect} from "react";

import EobsForm from "./AllForms/EobsForm";
import ErasForm from "./AllForms/ErasForm";

export default function PaymentPosting({
  state,
  setState,
    setFormTypeNoForApi,
    handleClick,
  formType,
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  onRecordUpdateOrCreated,
  record,
  practiceId,
  formTypeNoForApi,
  date
}) {

    console.log(formTypeNoForApi,'formTypeNoForApi',formType,'formType')
    


    useEffect(() => {
       
        
        if(formTypeNoForApi==5 && formType=='Payment'){
            setState({value:'EOBS',label:'EOB`s'})
           

        }
        if(formTypeNoForApi==6 && formType=='Payment'){
            setState({value:'ERAS',label:'ERA`s'})

        }
     
    }, [formTypeNoForApi])

    const onChangeTab=(val)=>{
        if(val.value=='ERAS'){
            setFormTypeNoForApi(6)
        }
        if(val.value=='EOBS'){
            setFormTypeNoForApi(5)
        }
        
        
        
        

    }
    console.log(state?.value == "EOBS",'state')
    
  return (

   
    <>
    {state?.value == "ERAS" ? (
                <>
                  <div
                    class="panel panel-default"
                    onClick={() => handleClick(6)}
                  >
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <div
                          className={formType == "ERAS" ? "after" : ""}
                        ></div>
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          aria-expanded={formType == "ERAS" ? true : false}
                          aria-controls="collapseOne"
                        >
                          {nameGeneric}
                        </a>
                      </h4>
                    </div> */}

                    <div
                      id="collapseOne"
                      className={
                        state?.value == "ERAS"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={state?.value == "ERAS" ? true : false}
                    >
                      <div class=" ">
                        <ErasForm
                        payerSelection={state}
                        handleFormTypeAndPract={handleFormTypeAndPract}
                        products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          onChangePayer={onChangeTab}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )
              :state?.value == "EOBS" ? (
                <>
                  {" "}
                  <div class="panel panel-default" onClick={() => handleClick(5)}>
                    {/* <div class="panel-heading" role="tab" id="headingOne">
                              <h4 class="panel-title">
                                <div
                                  className={formType === "EOBS" ? "after" : ""}
                                ></div>
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  aria-expanded={formType === "EOBS" ? true : false}
                                  aria-controls="collapseOne"
                                >
                                  {nameGeneric}
                                </a>
                              </h4>
                            </div> */}
        
                    <div
                      id="collapseOne"
                      className={
                        state?.value === "EOBS"
                          ? "panel-collapse collapse show"
                          : "panel-collapse collapse"
                      }
                      aria-expanded={state?.value === "EOBS" ? true : false}
                    >
                      <div class=" ">
                        <EobsForm
                         payerSelection={state}
                          handleFormTypeAndPract={handleFormTypeAndPract}
                          products={products}
                          setNameGeneric={setNameGeneric}
                          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                          record={record}
                          practiceId={practiceId}
                          formTypeNoForApi={formTypeNoForApi}
                          date={date}
                          onChangePayer={onChangeTab}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ):null}
      
      
    </>
  );
}
