import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUsersName,
  insertAccVerfiy,
  tlAdd,
} from "../../../../Utils/ApiManager";
import Meeting from "../ChargeEntry/Meeting";
import Reports from "../ChargeEntry/Reports";
import DateAndPractice from "../../DateAndPracticeTl";
import PaymentAudit from "./AccAuditTable";
import Dateoofservice from "../../../../Common/Dateoofservice";
import {Panel} from 'rsuite'

const AccVerify = ({
  products,
  handleSubCheckBoxChargeEntry,
  handleFormTypeAndPract,
  dateAndP,
  practiceId,
  date,
  formTypeNoForApi,
  record,
  onRecordUpdateOrCreated,
}) => {
  const { onLoginSignOff } = useSelector((state) => state);

  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    formType: "",
    submittype: "",
    receivedClaims: "",
    workedClaims: "",
    pendingClaims: "",
    isDateofSeviceOpen:false,
    totalClaims:""
  };
  const [state, setState] = useState(payload);
  const [tableState, setTableState] = useState([]);
  let helpingObj = {
    loading: false,
    isSwitchTable: 1,
    holdSubDosTableData: [],
    getAllBellUsers: "",
  };
  const [helpingStatem, setHelpingState] = useState(helpingObj);
  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };

  const convertUseridIntoUserName = async (data) => {
    let res = helpingStatem.getAllBellUsers;

    const mergedData = data.map((user) => {
      const matchingData = res?.data.find(
        (item) => item.userid === user.userid
      );
      const total = matchingData
        ? Number(user.patientsp) +
          Number(user.adjustmentp) +
          Number(user.insurancep)
        : 0;

      return {
        ...user,
        ...matchingData,
        total,
      };
    });

    return mergedData;
  };


  

  useEffect(() => {
    if (date) {
      onclear();
    }
  }, [date]);
  useEffect(() => {
    getUsersName().then((res) => {
      setHelpingState((prev) => ({ ...prev, getAllBellUsers: res }));
    });
  }, []);

  useEffect(() => {
    if (record) {
      setState((prevResult) => ({
        ...prevResult,
        receivedClaims: record?.ReceivedClaims,
        workedClaims: record?.WorkedClaims,
        pendingClaims: record?.PendingClaims,
        ReceivedAmountTotal:record?.ReceivedAmountTotal,
        CollectedAmountTotal:record?.CollectedAmountTotal,
        pendingAmountTotal:record?.pendingAmountTotal,
        parsedBreakupValsAcc:record?.parsedBreakupValsAcc


      }));
    }
  }, [record, practiceId]);

  
  useEffect(() => {
    if(state.parsedBreakupValsAcc){
     
      let totalClaims=parseFloat(state.parsedBreakupValsAcc.gca ||0)+parseFloat(state.parsedBreakupValsAcc.gcp ||0)+parseFloat(state.parsedBreakupValsAcc.gcp || 0)+parseFloat(state.parsedBreakupValsAcc.gcr || 0)+parseFloat(state.parsedBreakupValsAcc.gct ||0)
      let totalAmount=parseFloat(state.parsedBreakupValsAcc.gcaa || 0)+parseFloat(state.parsedBreakupValsAcc.gcpa ||0)+parseFloat(state.parsedBreakupValsAcc.gcpa || 0)+parseFloat(state.parsedBreakupValsAcc.gcra || 0)+parseFloat(state.parsedBreakupValsAcc.gcta ||0)
      setState((prev)=>({...prev,totalClaims:totalClaims,totalAmount:totalAmount}))
     
    }
    
  }, [state.parsedBreakupValsAcc])

  const onclear = () => {
    setState(payload);
  };

  const onSearch = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      setHelpingState((prev) => ({ ...prev, loading: true }));
      let obj = {
        userid: onLoginSignOff.userid,
        sdate: date,
        pid: practiceId,
        formtype: formTypeNoForApi,
      };
      // const formDataForSaveApi = new FormData();
      // formDataForSaveApi.append("userid", onLoginSignOff.userid);
      // formDataForSaveApi.append("sdate", date);
      // formDataForSaveApi.append("pid", practiceId);
      // formDataForSaveApi.append("formtype", formTypeNoForApi);
      // formDataForSaveApi.append("submittype", "No");

      // formDataForSaveApi.append("file", "");

      let resultt = await insertAccVerfiy(obj);
      if (resultt?.data) {
        if (resultt?.data.length == 0) {
          toast.info("No Record Found");
          setHelpingState((prev) => ({ ...prev, loading: false }));

          return;
        } else {
          let convertedData = await convertUseridIntoUserName(resultt?.data);
          const formattedArrayCharge = convertedData && convertedData.map((item) => {
            return {
              ...item,
              
              pendingval: isNaN(Number(item.pendingval)) ? 0 : Number(item.pendingval),
              workedval: isNaN(Number(item.workedval)) ? 0 : Number(item.workedval),
              recievedval:isNaN(Number(item.recievedval))?0:Number(item.recievedval)
            };
          });
          setTableState(formattedArrayCharge);
          setHelpingState((prev) => ({ ...prev, loading: false }));
        }
      } else {
        setHelpingState((prev) => ({ ...prev, loading: false }));
        toast.info("Something Went Wrong");
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState("");

  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [globalDos, openGlobalDos] = useState("");
  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const switchTableDetail = () => {
    setHelpingState((prev) => ({ ...prev, isSwitchTable: 3 }));
  };

  const backToMainScreen = (arg) => {
    if (arg == "mainScreen") {
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 1 }));
    } else {
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 2 }));
    }
  };
  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const isDosOpen=()=>{
    setState((prev)=>({...prev,isDateofSeviceOpen:true}))

  }
  const [renderEffect, setRenderEffect] = useState(false);
  const renderr = (val) => {
    setRenderEffect(val);
  };
  const handleClose=()=>{
    setState((prev)=>({...prev,isDateofSeviceOpen:false}))

  }

  console.log('state?.parsedBreakupValsAcc',state?.parsedBreakupValsAcc)



  let pendingClaimTotal=(<>
    {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Total Breakup of worked claims:
              </h4>
            </div> */}
            <div className="mt-4"></div>
             <Panel defaultExpanded={true} header="Total Breakup of worked claims" collapsible={true} >

<div className="row mt-4">
            <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcp"
                  placeholder=""
                  disabled
                  onChange={handelChange}
                  value={state?.parsedBreakupValsAcc?.gcp}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid Amount{" "}
                     {" "}

                  </label>
                  <div  class="form-group input-icon">

                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcpa"
                  placeholder=""
                  disabled
                  onChange={handelChange}
                  value={state?.parsedBreakupValsAcc?.gcpa?.toLocaleString()}
                />
                 <i className="lightGreen">$</i>

                </div>
                  </div>

                  </div>
                  </div>



                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gca"
                  placeholder=""
                  disabled
                  value={state?.parsedBreakupValsAcc?.gca}
                  onChange={handelChange}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcaa"
                  placeholder=""
                  disabled
                  value={state?.parsedBreakupValsAcc?.gcaa?.toLocaleString()}
                  onChange={handelChange}
                />
                 <i className="lightGreen">$</i>
                </div>
                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcr"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gcr}
                  onChange={handelChange}
                />

                 </div>
                 <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcra"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gcra?.toLocaleString()}
                  onChange={handelChange}
                />
                <i className="lightGreen">$</i>
                </div>
                  </div>

                 </div>
                 </div>


                 <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gct"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gct}
                  onChange={handelChange}
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcta"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gcta?.toLocaleString()}
                  onChange={handelChange}
                />
                 <i className="lightGreen">$</i>
                </div>

                  </div>

                  </div>
                  </div>


                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcs"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gcs}
                  onChange={handelChange}
                />

                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="gcsa"
                  disabled
                  placeholder=""
                  value={state?.parsedBreakupValsAcc?.gcsa?.toLocaleString()}
                  onChange={handelChange}
                />
                <i className="lightGreen">$</i>


</div>
                  </div>
                  </div>
                  </div>



                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Total Claims{" "}
                  </label>
                  <input
                  class="form-control inputField"
                  type="text"
                  name="total"
                  placeholder=""
                 value={state.totalClaims}
                  disabled
                />

                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Total Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  class="form-control inputField"
                  type="text"
                  name="totala"
                  placeholder=""
                 value={state.totalAmount?.toLocaleString()}
                  disabled
                />
                <i className="lightGreen">$</i>

                </div>
                  </div>

                  </div>
                  </div>
                  </div>
                  </Panel>
  </>)

  console.log('state',tableState?.dosArrayRecievedgen)
  return (
    <>
    {state.isDateofSeviceOpen==true?<Dateoofservice
        isAllowThirdTable={{allow:true,flagThirdTable:'generalPayer'}}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          // handleChange={handleChange}
          // handleEditState={handleEditState}
          // onSaveDos={onSaveDos}
          dosArray={tableState[0]?.dosArrayRecievedgen}
          // setDosArray={{ state: setDosArray, flag: "1" }}
          // onAdddos={onAdddos}
          //  onChangeDate={onChangeDate}
          //  onChangeInput={onChangeInput}
          //  onChangeInputCC={onChangeInputCC}
          handleClose={handleClose}
          //  showAmount={true}
          //  showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          //  practiceId={practiceId}
          //  dosPendingBreakup={{ isAllow: true, onChangeValues: onChangeValues }}

         
          onlyShowTable={true}
          SecondTableName={"payer"}
          flag={"gp"}
        />:<div className="row">
        {helpingStatem.isSwitchTable != 1 ? null : (
          <DateAndPractice
            openGlobalTable={openGlobalTable}
            callGlobalDos={callGlobalDos}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleFormTypeAndPract={handleFormTypeAndPract}
            products={products}
            date={dateAndP}
          />
        )}
        
        {helpingStatem.isSwitchTable != 1 ? null : (
          <>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Received Claims
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="range1"
                  value={state.receivedClaims}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>{" "}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Worked Claims
                </label>
                <input
                  class="form-control inputField"
                  type="31-60"
                  name="range2"
                  value={state.workedClaims}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>{" "}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending Claims
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="range3"
                  value={state.pendingClaims}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>
  
  
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Received Claims Amount
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="range3"
                  value={state.ReceivedAmountTotal?.toLocaleString()}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>
  
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Collected Claims Amount
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="range3"
                  value={state.CollectedAmountTotal?.toLocaleString()}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Pending Claims Amount
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  name="range3"
                  value={state.pendingAmountTotal?.toLocaleString()}
                  onChange={handelChange}
                  disabled
                />
              </div>
            </div>
  
            
            <br></br>
            <br></br>{" "}
            {pendingClaimTotal}
            
            <div class="form-group mb-2 ">
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="button"
                  onClick={onSearch}
                  className="btnClear mr-1 "
                >
                  Generate Audit
                </button>
              </div>
            </div>
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Account Receivable Audit Table
              </h4>
            </div> */}
          </>
        )}
  
        
  
        <PaymentAudit
        flagIndi={"AccForm"}
        practiceId={practiceId}
        date={date}
        isDosOpen={isDosOpen}
          setHelpingState={setHelpingState}
          tableState={tableState}
          helpingStatem={helpingStatem}
        />
  
        {helpingStatem.isSwitchTable != 1 ? (
          <div class="col-sm-6 col-lg-12 ">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4">
                {helpingStatem.isSwitchTable == 2 ? (
                  <>
                    {" "}
                    <button
                      type="button"
                      className="btnClear mr-1 "
                      onClick={switchTableDetail}
                    >
                      View Detail
                    </button>
                  </>
                ) : null}
  
                {helpingStatem.isSwitchTable == 2 ? (
                  <button
                    type="button"
                    className="btnSave"
                    onClick={() => backToMainScreen("mainScreen")}
                  >
                    Back to Main Screen
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btnSave"
                    onClick={() => backToMainScreen("subScreen")}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div class="col-sm-6 col-lg-12 ">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="button"
                  className="btnClear mr-1 "
                  onClick={onclear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}
  
        
      </div>}

    
    </>
    
  );
};

export default AccVerify;
