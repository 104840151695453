import React from 'react';
import { useToaster, Message } from 'rsuite';

export default function ToasterRsuite({ message, placement = 'topCenter', duration = 5000 }) {
  const toaster = useToaster();

  const showToast = () => {
    toaster.push(
      <Message showIcon type={'info'} closable>
        {message}
      </Message>,
      { placement, duration, zIndex: 9999999 }
    );
  };

  return (
    <div>
      <button onClick={showToast}>Show Toast</button>
    </div>
  );
}