import React, { useState, useEffect } from "react";
import { Table, Tag, Pagination, Button } from "rsuite";
import { CSVLink } from "react-csv";
import PushMessageIcon from '@rsuite/icons/PushMessage';


import "rsuite/dist/rsuite.css";
import { Tooltip, Whisper } from 'rsuite';
import "rsuite-table/dist/css/rsuite-table.css";
import EditIcon from "@rsuite/icons/Edit";
import AdvancedAnalyticsIcon from "@rsuite/icons/AdvancedAnalytics";
import TrashIcon from "@rsuite/icons/Trash";


import EyeCloseIcon from "@rsuite/icons/EyeClose";
import ViewsAuthorizeIcon from '@rsuite/icons/ViewsAuthorize'
import FooterComp from "./Footer";
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import CheckIcon from '@rsuite/icons/Check';











const { Column, HeaderCell, Cell } = Table;

const UserAuditTable = ({
  isPaginationShow,
  callPopThresHold,
  
  reportName,
  getRequriedPara,
  renderRowExpanded,
  isExpandableTable,
  tableHeight,
  isShowExportButton,
  sizee,
  previewButton,
  flagFooter,
  Footer,
  isPopupOpen,
  rowShown,
  rowHeight,
  dateForgrid,
  state,
  loader,
  ColumnName,
  Array,
  onChangePop,
  handleEditState,
  handleChange,
  deleteRow,
  tabelSizee
}) => {
  const [DataForGrid, setDataForGrid] = useState([]);
  const [limit, setLimit] = useState(rowShown ? rowShown : 7);
  const [effect, setrenderingEffect] = useState(false);
  const [page, setPage] = useState(1);
  const [csvData, setCsvData] = useState("")


  useEffect(() => {
    if (page) {
      getRequriedPara(page, reportName, limit)

    }

  }, [page, limit])

  const CustomComponent = ({ placement }) => (
    <></>
  );

  const handleChangeLimit = (dataKey) => {

    setPage(1);
    setLimit(dataKey);
  };

  console.log("Array", Array);
  const data =
    DataForGrid?.length > 0 &&
    DataForGrid?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  const PopUpCell = ({
    isUserAllow,
    rowData,

    onChangePop,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 5, backgroundColor: "#F0F9FF" }} >
      {/* <CheckIcon
        onClick={() => onChangeVerify(rowData)}
        style={{ color: "#0e7490" }}
      /> */}
      
      <AdvancedAnalyticsIcon
        onClick={() => onChangePop(rowData)}
        style={{ color: "#0e7490" }}
      />
      {"  "}

      {isUserAllow && <PushMessageIcon
        onClick={() => callPopThresHold(rowData)}
        style={{ color: "#0e7490" }}
      /> }

    

     
      
     
     
    </Cell>
  );

  useEffect(() => {
    if (dateForgrid) {
      console.log('dateForgrid', dateForgrid)
      const data = Object.keys(dateForgrid).map((key) => {
        return {
          CatValue: key,
          ...dateForgrid[key],
        };
      });
      console.log(data, "first check");
      setDataForGrid(dateForgrid);
    }
  }, [dateForgrid, isPopupOpen]);

  console.log(DataForGrid, "DataForGrid");

  const EditableCell = ({item, rowData, dataKey, onChange,toolTip, ...props }) => {
    console.log(rowData, "kop");
    const editing = rowData?.status === "EDIT";

    let warningColorRow = rowData?.bfRow ? "warningRowColor" : null;
    return (
      <Cell
        {...props}
        style={
          rowData?.bfRow
            ? { backgroundColor: "#f3efa4" }
            : { backgroundColor: "#F0F9FF" }
        }
        className={editing ? `table-content-editing ` : ""}
      >
        {editing ? (
          <input
            key={"input1"}
            className="rs-input"
            style={{ height: "50x !important" }}
            defaultValue={rowData[dataKey]}
            onChange={(event) => {
              onChange &&
                onChange(rowData.id, dataKey, event.target.value, event);
            }}
          />
        ) : (
          <>
          {item?.isShowToolTip? <Whisper
      trigger="hover"
      placement={"top"}
      controlId={`control-id-${"top"}`}
      speaker={
        <Tooltip>{rowData[toolTip]}</Tooltip>
      }
    >
      <span className="table-content-edit-span">{rowData[dataKey]}</span>
      
    </Whisper>: <span className="table-content-edit-span">{rowData[dataKey]}</span>}
          
         
           
          
          </>
          
        )}
      </Cell>
    );
  };

  const ActionCell = ({ item, rowData, dataKey, btnType, ...props }) => {
    console.log(item, "btnType");
    return (
      <Cell
        {...props}
        style={
          rowData?.bfRow
            ? {
              backgroundColor: "#f3efa4",
              padding: "6px",
              height: "31px !important",
            }
            : { padding: "6px", backgroundColor: "#F0F9FF" }
        }
      >
        {item &&
          item?.actions?.map((el, index) => {
            return (
              <Button
                appearance="link"
                key={index}
                onClick={() => el?.onClick(rowData)}
              >
                {el.btnType == "Edit" ? (
                  rowData.status === "EDIT" ? (
                    <ViewsAuthorizeIcon style={{ color: "#0e7490" }} />
                  ) : (
                    <EditIcon style={{ color: "#0e7490" }} />
                  )
                ) : el.btnType == "Delete" ? (
                  <TrashIcon style={{ color: "red" }} />
                ) : el.btnType == 'Audit' ? <ViewsAuthorizeIcon /> : el.btnType == "View" ? (
                  <EyeCloseIcon style={{ color: "#0e7490" }} />
                ) : el.btnType == 'Bell' ? <i style={{ color: "#0e7490" }} className="far fa-bell"></i> : el.btnType == 'Verify' ? <CheckIcon style={{ color: "#0e7490" }} /> : el.btnType == 'info' ? <i class="fas fa-info"></i> : (
                  el.label
                )}
              </Button>
            );
          })}
      </Cell>
    );
  };

  console.log( "Footer", Footer);




  const generateCsvData = (data, columns) => {
    // Extract column headers from the 'columns' array

    const headers = columns.map(column => column.colName);

    // Create an array to hold the CSV data
    const csvDataBucket = [headers];

    // Iterate over the 'data' array and extract values based on 'dataKey'
    data != false && data.forEach(item => {
      const rowData = columns.map(column => {
        // If 'dataKey' is provided, use it to extract the value from 'item'
        if (column.dataKey) {
          return item[column.dataKey];
        } else {
          // If 'dataKey' is not provided, use an empty string
          return '';
        }
      });
      csvDataBucket.push(rowData);
    });

    return csvDataBucket;
  }
  useEffect(() => {

    if (Array) {

      const csvDataArr = generateCsvData(DataForGrid, Array);


      //setCsvData(csvDataArr)
      if (JSON.stringify(csvDataArr) !== JSON.stringify(csvData)) {
        setCsvData(csvDataArr);
      }

    }

  }, [data])


 






  return (
    <>
  
      
     
        
          <div class="form-group mb-2 ">
            <div class="d-flex justify-content-end ">
           {previewButton && reportName=='PracticeWise'?<button className=" btnClear mr-1 " onClick={()=>sizee(reportName)}>{tabelSizee?"Exit":"Expand"}</button>:'' }
           {previewButton && reportName=='Thres'?<button className=" btnClear mr-1 " onClick={()=>sizee(reportName)}>{tabelSizee?"Exit":"Expand"}</button>:'' }
            {isShowExportButton ?   <button
                type="button"
                className="btnClear mr-1 "
              // onClick={onClear}
              >
                <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
                  Export to CSV
                </CSVLink>
              </button>: null}     

            </div>
          </div> 




        <div id="table-to-xls">

          <Table
            affixHeader={true}
            virtualized
            shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
            autoHeight={tabelSizee}
            data={data}
            onRowClick={(data) => {
              console.log(data);
            }}
            //autoHeight={true}
            cellBordered={true}
            headerHeight={37}
            rowHeight={rowHeight ? rowHeight : 30}
            loading={loader}

          >
            {Array &&
              Array.map((item, index) => (
                <Column
                  key={index}
                  fullText={true}
                  flexGrow={item.flexGrow ? item.flexGrow : 2}
                  align="center"
                  

                >
                  <HeaderCell
                    style={{
                      backgroundColor: isPopupOpen ? "#0e7490" : "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                      position: "sticky",
                    }}
                  >
                    {item.colName}
                  </HeaderCell>

                {item?.popupCell ? (
                  
                  <PopUpCell onChangePop={onChangePop} isUserAllow={item?.isAllow}/>
                ) : item?.editRow ? (
                 
                    <ActionCell
                    item={item}
                    onClick={handleEditState}
                    onClickDel={deleteRow}
                    isUserAllow={item?.isAllow}
                  />
                 
                  
                ) : (
                  
                  <EditableCell
                    dataKey={item?.dataKey}
                    toolTip={item?.toolTipKey}
                    item={item}
                    onChange={handleChange}
                  />
                  
                )}
              </Column>
            ))}
        </Table>
        <FooterComp flagFooter={flagFooter} data={data}  Footer={Footer} Array={Array}/>
      
        
       
      </div>
      
     
      <br></br>
      {" "}

             

       

        {isPaginationShow &&<div className="mt-4 pb-5 " style={{ zIndex: '1000' }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={DataForGrid.length}
            limitOptions={[5, 10, 50, 100]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>}
     
    </>
  );
};
export default UserAuditTable;
