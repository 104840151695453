import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";

import DashboardIcon from "@rsuite/icons/Dashboard";
import TextImageIcon from "@rsuite/icons/TextImage";
import { useSelector } from "react-redux";
import userRolesObject from "../../../Common/AllRolesInfo";

import "./sidenav.css";

export const Sidenav = ({
  instantEffectOnRoom,
  providerInCallSettings,
  toggleSidenavBar,
  toogleSideNav,
  callEndWhenProviderSwitchesTab,
  detectSideNavChange,
  setProviderInCallSettings,
  getClickOnTab,
}) => {
  const { NavigateDasboardsIssue, AllPatientsAccepted } = useSelector(
    (state) => state
  );
  const { onLoginSignOff } = useSelector((state) => state);
  const [Active, setActive] = useState("Dashboard");
  const [ToogleOnMobile, setToogleOnMobile] = useState(false);
  const [dropDownopToogle, setDropdownToogle] = useState(false);

  const [dropDownopToogleReports, setDropdownToogleReports] = useState(false);
  const [dropDownopToogleCoding, setDropdownToogleCoding] = useState(false);
  const [isHover, setIsHover] = useState(null);

  useLayoutEffect(() => {
    const width = window.innerWidth;
    if (width < 992) {
      setToogleOnMobile(true);
    }
  }, []);
  useEffect(() => {
    if (NavigateDasboardsIssue) {
      setActive("Dashboard");
    }
  }, [NavigateDasboardsIssue]);
  useEffect(() => {
    if (instantEffectOnRoom === "Room") {
      setActive("Room");
    }
  }, [instantEffectOnRoom]);

  const handleClick = (value) => {
    getClickOnTab(value);
    if (ToogleOnMobile === true && toogleSideNav == true) {
      toggleSidenavBar();
    }
    setActive(value);
  };

  useEffect(() => {
    if (providerInCallSettings) {
      detectSideNavChange();
      setProviderInCallSettings(false);
    }
  }, [providerInCallSettings]);

  

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  const onMouseEnterFun = (val) => {
    setIsHover(val);
  };
  const onMouseLeave = () => {
    setIsHover(null);
  };
  return (
    <>
      <nav
        className={
          toogleSideNav
            ? "sidebar sidebar-offcanvas active"
            : "sidebar sidebar-offcanvas"
        }
        id="sidebar"
      >
        <ul className="nav" style={{ position: "fixed" }}>
          <li
            className={
              Active === "Dashboard" ? " nav-item  active" : " nav-item  "
            }
          >
            <NavLink
              onClick={() => handleClick("Dashboard")}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item active" : "nav-link nav-item"
              }
              to={"/dashboard"}
            >
              <i
                className="fas fa-th pe-3"
                style={
                  Active === "Dashboard"
                    ? { color: "white" }
                    : { color: "#0e7490" }
                }
              ></i>

              <span className="menu-title ">Dashboard</span>
            </NavLink>
          </li>

          <li
            className={
              Active === "Operationn" ? " nav-item active" : " nav-item "
            }
          >
            <NavLink
              onClick={() => {
                handleClick("Operationnn");
                setDropdownToogle((pre) => !pre);
              }}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item " : "nav-link nav-item "
              }
              to={"/"}
            >
              <i
                className="fas fa-chart-bar pe-3 "
                style={
                  Active === "Operationn"
                    ? { color: "white" }
                    : { color: "#0e7490" }
                }
              ></i>
              <span className="menu-title">
                Operation
                <i
                  className={
                    dropDownopToogle === false
                      ? "arrow menu-title down"
                      : "  arrow menu-title up"
                  }
                ></i>{" "}
              </span>
            </NavLink>
          </li>

          {/* Dropdown section  */}
          <div
            className={
              dropDownopToogle === true ? "dropDownop mt-1" : "displayDropdown"
            }
          >
            <ul
              style={{ marginTop: "-1px", marginBottom: "-1px" }}
              className=" nav"
            >
              <li
                className={
                  Active === "Operation" ? " nav-item active" : " nav-item "
                }
              >
                <NavLink
                  onClick={() => handleClick("Operation")}
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-item " : "nav-link nav-item "
                  }
                  to={"/Operation/userInterface"}
                >
                  <i
                    class="fas fa-regular fa-clipboard pe-3"
                    style={
                      Active === "Operation"
                        ? { color: "white" }
                        : { color: "#0e7490" }
                    }
                  ></i>
                  {/* <i className="fas fa-chart-bar pe-3"></i> */}
                  <span className="menu-title fontSize ">User Interface</span>
                </NavLink>
              </li>
              {onLoginSignOff.type == userRolesObject.biller ? null : (
                <>
                  <li
                    className={
                      Active === "OperationTLInterface"
                        ? " nav-item active"
                        : " nav-item "
                    }
                  >
                    <NavLink
                      onClick={() => handleClick("OperationTLInterface")}
                      className={({ isActive }) =>
                        isActive ? "nav-link nav-item " : "nav-link nav-item "
                      }
                      to={"/Operation/TLInterface"}
                    >
                      {/* <i className="fas fa-chart-bar pe-3"></i> */}
                      <i
                        class="fas fa-solid fa-user-plus pe-3"
                        style={
                          Active === "OperationTLInterface"
                            ? { color: "white" }
                            : { color: "#0e7490" }
                        }
                      ></i>
                      <span className="menu-title fontSize ">TL Interface</span>
                    </NavLink>
                  </li>
                </>
              )}

              {onLoginSignOff.type == userRolesObject.biller ? null : (
                <>
                  <li
                    className={
                      Active === "OperationAudit"
                        ? " nav-item active"
                        : " nav-item "
                    }
                  >
                    <NavLink
                      onClick={() => handleClick("OperationAudit")}
                      className={({ isActive }) =>
                        isActive ? "nav-link nav-item " : "nav-link nav-item "
                      }
                      to={"/Operation/Audit"}
                    >
                      {/* <i className="fas fa-chart-bar pe-3" ></i> */}
                      <i
                        class="fas fa-solid fa-book pe-3"
                        style={
                          Active === "OperationAudit"
                            ? { color: "white" }
                            : { color: "#0e7490" }
                        }
                      ></i>
                      <span className="menu-title fontSize">Reports</span>
                    </NavLink>
                  </li>
                </>
              )}

              <li
                className={
                  Active === "TaskList" ? " nav-item active" : " nav-item "
                }
              >
                <NavLink
                  onClick={() => handleClick("TaskList")}
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-item " : "nav-link nav-item "
                  }
                  to={"/Operation/TaskList"}
                >
                  {/* <i className="fas fa-chart-bar pe-3"></i> */}
                  <i
                    class="fas fa-solid fa-user-plus pe-3"
                    style={
                      Active === "TaskList"
                        ? { color: "white" }
                        : { color: "#0e7490" }
                    }
                  ></i>
                  <span className="menu-title fontSize ">Task List</span>
                </NavLink>
              </li>
            </ul>
          </div>
          {/* ***** End***** */}

          {/* *********************Settingd Commented************** */}
          <li
            className={Active === "Coding" ? " nav-item active" : " nav-item "}
          >
            <NavLink
              onClick={() => {
                handleClick("Coding");
                setDropdownToogleCoding((pre) => !pre);
              }}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item " : "nav-link nav-item "
              }
             
            >
              <i
                className="fas fa-cogs pe-3"
                style={
                  Active === "Coding"
                    ? { color: "white" }
                    : { color: "#0e7490" }
                }
              ></i>
              <span className="menu-title">
                Coding &nbsp;&nbsp;
                <i
                  className={
                    dropDownopToogleCoding === false
                      ? "arrow menu-title down "
                      : "  arrow menu-title up"
                  }
                ></i>{" "}
              </span>
            </NavLink>
          </li>

          <div
            className={
              dropDownopToogleCoding === true
                ? "dropDownop mt-1"
                : "displayDropdown"
            }
          >
            <ul
              style={{ marginTop: "-1px", marginBottom: "-1px" }}
              className=" nav"
            >
              <li
                className={
                  Active === "OperationC" ? " nav-item active" : " nav-item "
                }
              >
                <NavLink
                  onClick={() => handleClick("OperationC")}
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-item " : "nav-link nav-item "
                  }
                  to={"/Coding/userInterface"}
                >
                  <i
                    class="fas fa-regular fa-clipboard pe-3"
                    style={
                      Active === "OperationC"
                        ? { color: "white" }
                        : { color: "#0e7490" }
                    }
                  ></i>
                
                  <span className="menu-title fontSize ">User Interface</span>
                </NavLink>
              </li>
              {onLoginSignOff.type == userRolesObject.biller ? null : (
                <>
                  <li
                    className={
                      Active === "TLInterfaceOfCoding"
                        ? " nav-item active"
                        : " nav-item "
                    }
                  >
                    <NavLink
                      onClick={() => handleClick("TLInterfaceOfCoding")}
                      className={({ isActive }) =>
                        isActive ? "nav-link nav-item " : "nav-link nav-item "
                      }
                      to={"/Coding/TLInterface"}
                    >
                      
                      <i
                        class="fas fa-solid fa-user-plus pe-3"
                        style={
                          Active === "TLInterfaceOfCoding"
                            ? { color: "white" }
                            : { color: "#0e7490" }
                        }
                      ></i>
                      <span className="menu-title fontSize ">TL Interface</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      Active === "ReportAudit"
                        ? " nav-item active"
                        : " nav-item "
                    }
                  >
                    <NavLink
                      onClick={() => handleClick("ReportAudit")}
                      className={({ isActive }) =>
                        isActive ? "nav-link nav-item " : "nav-link nav-item "
                      }
                      to={"/Coding/Report"}
                    >
                      {/* <i className="fas fa-chart-bar pe-3" ></i> */}
                      <i
                        class="fas fa-solid fa-book pe-3"
                        style={
                          Active === "ReportAudit"
                            ? { color: "white" }
                            : { color: "#0e7490" }
                        }
                      ></i>
                      <span className="menu-title fontSize">Reports</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>

          <li className={Active === "settings" ? " nav-item " : " nav-item "}>
            <NavLink
              onClick={() => handleLogout("logout")}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item " : "nav-link nav-item"
              }
              to="/"
            >
              <i
                className="fas fa-sign-out-alt   "
                style={{ color: "#0e7490" }}
              ></i>
              <span className="menu-title " style={{ paddingLeft: "17px" }}>
                {"  "} Logout
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};
