import ReactDropdown from "react-dropdown";
import React, { useState } from "react";


function Meeting({ state,handelChange,handleFrequencyMeeting,handleStatusMeeting }) {

  
  
  
 

  

  return (
    <>
      <div className="row">
       
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Frequency
            </label>

            <ReactDropdown
              value={state?.meeting_Frequency?state?.meeting_Frequency:"please select"}
              placeholder="Select an option"
              onChange={handleFrequencyMeeting}
              options={["Daily", "Weekly", "Bi-Weekly", "Monthly", "Other"]}
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Status
            </label>
            <ReactDropdown
              value={state?.meeting_Status?state?.meeting_Status:"please select"}
              placeholder="Select an option"
              onChange={handleStatusMeeting}
              options={["Done", "Postponed", "Cancelled", "No Show"]}
            />
          </div>
        </div>
       

        <div class="col-sm-12 mt-1">
          <div class="form-group  mb-3 mt-3 ">
          <label class="inputLabel" style={{ color: "black" }}>
              Any other task
            </label>
            <textarea
              class="form-control inputField"
              placeholder="Comments if any!"
              name={"meeting_comments"}
              onChange={handelChange}
              value={state?.meeting_comments}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        
      </div>
    </>
  );
}
export default Meeting;
