import ReactDropdown from "react-dropdown";

import React,{useState} from "react";

function Reports({state,handelChange,handleReports,handleDrop,handleFrequency }){
  
 

 


  
  
    return(<>
    <div className="row">
                    <div class="col-sm-4">
                    <div class="form-group mb-2">
                        <label class="inputLabel" style={{ color: "black" }}>
                            Provider Reports
                        </label>
                        <ReactDropdown
                        className=" inputField"
                            value={state?.reports_type?state?.reports_type:'Please Select'}
                            placeholder="Select an option"
                             onChange={handleReports}
                            options={["DWC", "Patient Statement", "Co-Pay Collection", "Practice Health Report"]}

                        />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-2">
                        <label class="inputLabel" style={{ color: "black" }}>
                            Frequency
                        </label>

                        <ReactDropdown
                            value={state?.reports_Frequncy?state?.reports_Frequncy:'Please Select'}
                            placeholder="Select an option"
                            onChange={handleFrequency}
                            options={["Daily", "Weekly", "Bi-Weekly", "Monthly", "Other"]}

                        />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-2">
                        <label class="inputLabel" style={{ color: "black" }}>
                            Status
                        </label>
                        <ReactDropdown
                            value={state?.reports_status?state?.reports_status:'Please Select'}
                          
                            placeholder="Select an option"
                             onChange={handleDrop}
                            options={["Sent", "Pending"]}

                        />
                    </div>
                </div>

                <div class="col-sm-12">
                    <div class="form-group mb-3 mt-3">
                    <label class="inputLabel" style={{ color: "black" }}>
            Any Other Task
          </label>
                    <textarea
            class="form-control inputField"
           
            placeholder="Comments if any!"
            onChange={handelChange}
            name="reports_comments"
            value={state?.reports_comments}
          />
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        
                
                </div>
    </>)

}
export default Reports