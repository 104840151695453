import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Meeting from "../ChargeEntry/Meeting";
import Reports from "../ChargeEntry/Reports";
import {
  generatePaymentAuditMainTable,
  getUsersName,
  insertTlPaymentTeamLead,
} from "../../../../Utils/ApiManager";
import DateAndPractice from "../../DateAndPracticeTl";
import PaymentAudit from "./PaymentAuditTable";
import { Panel } from "rsuite";


const PaymentVerification = ({
  handleFormTypeAndPract,
  products,
  handleSubCheckBoxChargeEntry,
  dateAndP,
  record,
  practiceId,
  formTypeNoForApi,
  date,
  formType,
  onRecordUpdateOrCreated,
}) => {
  const { onLoginSignOff } = useSelector((state) => state);
  const [tableState, setTableState] = useState([]);
  let helpingObj = {
    loading: false,
    isSwitchTable: 1,
    holdSubDosTableData: [],
    getAllBellUsers: "",
    isTriggerEffectDos:false

  };
  const [helpingStatem, setHelpingState] = useState(helpingObj);
  useEffect(() => {
    if (date) {
      onclear();
    }
  }, [date]);

  useEffect(() => {
    getUsersName().then((res) => {
      setHelpingState((prev) => ({ ...prev, getAllBellUsers: res }));
    });
  }, []);
  


 
  const backToMainScreen = (arg) => {
    if(arg=='mainScreen'){
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 1 }));

    }else{
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 2 }));

    }
    
  };

  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    formType: "",
    submittype: "",
    PatPayment: "",
    Adjustment: "",
    paymentIns: "",
    totalBreakupTotal:""

  };
  const [state, setState] = useState(payload);

  useEffect(() => {
    if (record) {
      setState((prev) => ({
        ...prev,
        PatPayment: record.patientsp,
        Adjustment: record.adjustmentp,
        paymentIns: record.insurancep,
        eobrecieved:record.eobrecieved,
        eobspending:record.eobspending,
        eobworked:record.eobworked,
        eraAmount:record.eraAmount,
        insurancep:record.insurancep,
        paidclaims:record.paidclaims,
        patientsp:record.patientsp,
        totalclaimsworked:record.totalclaimsworked,
        denialsamount:record.denialsamount,
        claimrecieved:record.claimrecieved,
        denials:record.denials,
        partiallyPaid:record.partiallyPaid,
        claimspending:record.claimspending,
        overAllbreakup:record?.totalPendingClaim,
        









      }));
    }
  }, [record, practiceId]);

  useEffect(() => {

    if(state.overAllbreakup){
      let sum=parseInt(state.overAllbreakup.Provider)+parseInt(state.overAllbreakup.coding)+parseInt(state.overAllbreakup.Billing)+parseInt(state.overAllbreakup?.Credientialing)+parseInt(state.overAllbreakup?.Patient)
      setState((prev)=>({...prev,totalBreakupTotal:sum}))
    }
   
  }, [state.overAllbreakup])
  

  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };
  const convertUseridIntoUserName = async (data) => {
   
    let res = helpingStatem.getAllBellUsers;

    const mergedData = data.map((user) => {
      const matchingData = res && res.data.length>0 && res?.data.find(
        (item) => item.userid === user.userid
      );
      const total = matchingData
        ? Number(user.patientsp) +
          Number(user.adjustmentp) +
          Number(user.insurancep)
        : 0;

      return {
        ...user,
        ...matchingData,
        total,
      };
    });

    return mergedData;
  };

  const onclear = () => {
    setState(payload);
  };

  const onSearch = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      setHelpingState((prev) => ({ ...prev, loading: true }));
      let obj = {
        userid: onLoginSignOff.userid,
        sdate: date,
        pid: practiceId,
        formtype: 1,
      };

      let resultt = await generatePaymentAuditMainTable(obj);

      if (resultt?.data) {
        if (resultt?.data.length == 0) {
          toast.info("No Record Found");

          return;
        } else {
          let convertedData = await convertUseridIntoUserName(resultt?.data);
          const formattedArrayPayment = convertedData && convertedData.map((item) => {
            return {
              ...item,
              denialsamount: isNaN(Number(item.denialsamount)) ? 0 : Number(item.denialsamount).toLocaleString(),
              insurancep: isNaN(Number(item.insurancep)) ? 0 : Number(item.insurancep).toLocaleString(),
              patientsp: isNaN(Number(item.patientsp)) ? 0 : Number(item.patientsp).toLocaleString(),
              total: isNaN(Number(item.total)) ? 0 : Number(item.total).toLocaleString(),
              adjustmentp: isNaN(Number(item.adjustmentp)) ? 0 : Number(item.adjustmentp).toLocaleString(),
             
            };
          });
          setTableState(formattedArrayPayment);
          setHelpingState((prev) => ({ ...prev, loading: false }));
        }
      } else {
        setHelpingState((prev) => ({ ...prev, loading: false }));
        toast.info("Something Went Wrong");
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState("");

  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [globalDos, openGlobalDos] = useState("");
  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };
  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const switchTableDetail=()=>{
    setHelpingState((prev)=>({...prev,isSwitchTable:3}))


  }


  let pendingSectionhtml = (
    <>
      {/* <div className="col-lg-12 mb-2 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Denials Claims Breakup
        </h4>
      </div> */}
       <Panel defaultExpanded={true} header="Denials Claims Breakup" collapsible={true} >
        <div className="row mt-4">
        <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Provider
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="providerVal"
            placeholder=""
            value={state?.overAllbreakup?.coding}
           // onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Coding
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="codingVal"
            placeholder=""
            value={state?.overAllbreakup?.Provider}
          //  onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Credentialing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="credientalingVal"
            placeholder=""
            value={state?.overAllbreakup?.Credientialing}
          //  onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="EdiVal"
            placeholder=""
            value={state?.overAllbreakup?.Patient}
          //  onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Billing
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="operationVal"
            placeholder=""
            value={state?.overAllbreakup?.Billing}
         //   onChange={onChangeBreakupClaim}
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Total
          </label>

          <input
            class="form-control inputField"
            type="number"
            name="total"
            placeholder=""
            disabled
            value={state.totalBreakupTotal}
         //   onChange={onChangeBreakupClaim}
          />
        </div>
      </div>
        </div>
     
      </Panel>
    </>
  );
 
  console.log("practiceid", practiceId);
  return (
    <div class="row">
      {helpingStatem.isSwitchTable!=1 ? null : (
       
          <DateAndPractice
          onSearch={onSearch}
          openGlobalTable={openGlobalTable}
          callGlobalDos={callGlobalDos}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          isShowSearchButton={false}
          flag={'charge'}
         
        />
       
        
      )}
      {helpingStatem.isSwitchTable!=1? null : (
        <Panel>
           <>
          
           <div className="row">

           <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                EOB`s Received{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                 value={state?.eobrecieved}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                EOB`sWorked{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.eobworked}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                EOB`s Amount{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                 // value={state?.eobspending? state?.eobspending.toLocaleString():state?.eobspending}
                 value={state?.eobspending?.toLocaleString()}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                EOB`s Pending{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.eraAmount}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            {/* <div className="col-lg-12 mb-2 text-center">
            <h4
              class="inputLabel "
              style={{
                color: "black",
                borderBottom: "2px solid rgb(14, 116, 144)",
                paddingBottom: "10px",
              }}
            >
              Claims Section
            </h4>
          </div> */}
          <div className="mt-4"></div>
           <Panel defaultExpanded={true} header="Claims Section" collapsible={true} >
            <div className="row mt-4">
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Claims Received{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.claimrecieved}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Claims Paid{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.paidclaims}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Denied Claims{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.denials}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Partially Paid Claims{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.partiallyPaid}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total Claims Worked{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                 value={state?.totalclaimsworked}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-4 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending Claims{" "}
                </label>
                {/* <CustomInputGroupWidthButtonC
                  value={state?.claimrecieved}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}

                <input
                  class="form-control inputField"
                  type="text"
                  name="claimrecieved"
                  placeholder=""
                  value={state?.claimspending}
                 // onChange={handleChange}
                  disabled
                />
              </div>
            </div>

            </div>
         
            </Panel>
            <div class="col-sm-6 col-lg-6"></div>
            <br></br>
            <br></br>
            {/* <div className="col-lg-12 mb-2 text-center">
            <h4
              class="inputLabel "
              style={{
                color: "black",
                borderBottom: "2px solid rgb(14, 116, 144)",
                paddingBottom: "10px",
              }}
            >
              Payment Section
            </h4>
          </div> */}
          <div className="mt-4"></div>
          <Panel defaultExpanded={true} header="Payment Section" collapsible={true} >
            <div className="row mt-4">
           
           
            <div class="col-sm-4 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Patient Payment
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="PatPayment"
                value={state?.PatPayment}
                onChange={handelChange}
                disabled
              />
            </div>
          </div>{" "}
          <div class="col-sm-4 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Ins. Payment
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="paymentIns"
                value={state?.paymentIns}
                onChange={handelChange}
                disabled
              />
            </div>
          </div>{" "}
          <div class="col-sm-4 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Adjustment
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="Adjustment"
                value={state?.Adjustment}
                onChange={handelChange}
                disabled
              />
            </div>
          </div>{" "}
          </div>
          </Panel>

          {/* <div className="col-lg-12 mb-2 text-center">
            <h4
              class="inputLabel "
              style={{
                color: "black",
                borderBottom: "2px solid rgb(14, 116, 144)",
                paddingBottom: "10px",
              }}
            >
              Denied Payment Section
            </h4>
          </div> */}
          <div className="mt-4"></div>
          <Panel defaultExpanded={true} header="Denied Payment Section" collapsible={true} >
          <div class="col-sm-4 col-lg-3 mt-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Amount Denied
              </label>
              <input
                class="form-control inputField"
                type="text"
                name="PatPayment"
                value={state?.denialsamount?.toLocaleString()
                }
                onChange={handelChange}
                disabled
              />
            </div>
          </div>
          </Panel>
          <div className="mt-4"></div>
         

          {pendingSectionhtml}

          <div class="form-group mb-2 ">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                onClick={onSearch}
                className="btnClear mr-1 "
              >
                Generate Audit
              </button>
            </div>
          </div>

          
          {/* <div className="col-lg-12 mb-2 text-center">
            <h4
              class="inputLabel "
              style={{
                color: "black",
                borderBottom: "2px solid rgb(14, 116, 144)",
                paddingBottom: "10px",
              }}
            >
              Audit Table
            </h4>
          </div> */}
           </div>
          
        </>
        </Panel>

       
      )}
      
      <PaymentAudit
      practiceId={practiceId} date={date}
        setHelpingState={setHelpingState}
        tableState={tableState}
        helpingStatem={helpingStatem}
      />
      

      

      {helpingStatem.isSwitchTable!=1 || helpingStatem.isSwitchTable!=3? (
        <div class="col-sm-6 col-lg-12 ">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
            {helpingStatem.isSwitchTable==2?<button
                type="button"
                className="btnClear mr-1 "
                onClick={switchTableDetail}
              >
                View Detail
              </button>:null}
              
              {helpingStatem.isSwitchTable==2? <button
                type="button"
                className="btnSave"
                onClick={()=>backToMainScreen('mainScreen')}
              >
                Back to Main Screen
              </button>:<>{helpingStatem.isTriggerEffectDos==true?null: <button
                type="button"
                className="btnSave"
                onClick={()=>backToMainScreen('subScreen')}
              >
                Back
              </button>}</>}
           
             
            </div>
           
          </div>
        </div>
      ) : (
        <div class="col-sm-6 col-lg-12 ">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                className="btnClear mr-1 "
                onClick={onclear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentVerification;
