import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchTeamLeadData,
  insertTeamLeadData,
  onSaveApi,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";

import ReactDropdown from "react-dropdown";
import DateAndPractice from "../DateAndPracticeTl";
import {  Input, Panel,IconButton } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import Select from "react-select";
const TeamLeadForm = ({
  handleFormTypeAndPract,
  products,
  handleSubCheckBoxChargeEntry,
  dateAndP,
  record,
  practiceId,
  date,
  formTypeNoForApi,
}) => {
  const { onLoginSignOff } = useSelector((state) => state);
  console.log(practiceId, date);
  useEffect(() => {
    if (practiceId != null && date != "") {
      fetchData();
    }
  }, [practiceId, date]);

  let obj = {
    tlType: "",
    loading: false,
  };
  const [state, setState] = useState(obj);
  const [options, setOptions] = useState([
    { label: "DWC", value: "DWC" },
    { label: "Patient Statement", value: "Patient Statement" },
    { label: "Co-Pay Collection", value: "Co-Pay Collection" },
    { label: "Practice Health Report", value: "Practice Health Report" },
  ])

  const [formData, setFormData] = useState({
    id: 0,
    meeting: "",
    remarks: "",
    frequency: "",
    d_MailTotlCounts: "",
    otherComments: "",
    providerReports: "",
    providerReportsFrequency: "",
    anyOtherTask: "",
  });
  const [addButton, setAddButtton] = useState(false);
  const [newOption, setNewOption] = useState("");

  const handleShowButton = () => {
    setAddButtton(!addButton);
    setNewOption("");
  };

  const fetchData = () => {
    onClear();
    const formDataFetch = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: 15,  
    };



  

    fetchTeamLeadData(formDataFetch)
      .then((res) => {
        const result = res.data[0];

        let parsedArray = [];
        try {
          parsedArray = JSON.parse(result.tlType);
        } catch {
          parsedArray = result.tlType;
        }

        const formDataForSaveApi = {
          id: result.id,
          meeting: result.Meeting,
          frequency: result.Frequency,
          providerReports: result.ProviderReports,
          providerReportsFrequency: result.reportsFrequncy,
          otherComments: result.Other,
          anyOtherTask: result.AnyOtherTaskReports,
          d_MailTotlCounts:result.dmailtotalcounts
        };
        setState((prev) => ({ ...prev, tlType: parsedArray }));

        setFormData(formDataForSaveApi);
      })
      .catch((error) => {
        toast.info("No Data Found");
      });
  };
  console.log(state,'state')

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please select Practice Name");
      return;
    } else {
      setState((prev) => ({ ...prev, loading: true }));
      const formDataForSaveApi = {
        userid: onLoginSignOff.userid,
        sdate: date,
        pid: practiceId,
        dmailtotalcounts:formData.d_MailTotlCounts,
        formtype: 15,
        Meeting: formData.meeting,
        Frequency: formData.frequency,
        submittype: "yes",
        ProviderReports: formData.providerReports,
        reportsFrequncy: formData.providerReportsFrequency,
        Other: formData.otherComments,
        remarks: formData.remarks,
        AnyOtherTaskReports: formData.anyOtherTask,
        id: formData.id,
        tlType:JSON.stringify(state.tlType)
        

      };    

      insertTeamLeadData(formDataForSaveApi)
        .then((result) => {
          setState((prev) => ({ ...prev, loading: false }));
          toast.info("Record Saved Successfully");
        })
        .catch((error) => {
          setState((prev) => ({ ...prev, loading: false }));
          toast.info("Error occure");
        });
    }
  };

  const handleInputChangee = (e) => {
    setNewOption(e.target.value);
  };
  const addOption = () => {
    if (newOption.trim() !== "") {
      const trimmedOption = newOption.trim();
      const optionExists = options.some((opt) => opt.value === trimmedOption);
      if (!optionExists) {
        setOptions((prevOptions) => [
          ...prevOptions,
          { label: trimmedOption, value: trimmedOption },
        ]);
        toast.info("Option Added Successfully");
        setNewOption("");
      } else {
        toast.error("Option Already Exists");
      }
    }
  };
  const handletlType = (val) => {
    setState((prev) => ({ ...prev, tlType: val }));
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleMeetingSenarion = (selectedOption) => {
    const { value } = selectedOption;
    setFormData((prevFormData) => ({
      ...prevFormData,
      meeting: value,
    }));
  };

  const onClear = () => {
    setFormData({
      meeting: "Please select",
      remarks: "",
      frequency: "Please select",
      otherComments: "",
      providerReports: "Please select",
      providerReportsFrequency: "Please select",
      anyOtherTask: "",
      id: 0,
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  return (
    <>
      {state.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : null}
      <div class="row">
        <DateAndPractice
          flag={"tl"}
          handletlType={handletlType}
          state={state}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={dateAndP}
          isShowSearchButton={true}
        />

        <div className="row">
          <>
            <Panel defaultExpanded={true} header="Meeting" collapsible>
              <div className="row mt-4">
                <div className="col-sm-4 mt-2">
                  <div className="form-group mb-2">
                    <label className="inputLabel" style={{ color: "black" }}>
                      Meeting
                    </label>
                    {/* <ReactDropdown
                      value={formData.meeting}
                      placeholder="Select an option"
                      onChange={handleMeetingSenarion}
                      options={["Internal", "External"]}
                      styles={customStyles}
                    /> */}
                    <Select
                      options={[
                        { label: "Internal", value: "Internal" },
                        { label: "External", value: "External" },
                      ]}
                      placeholder="Select an option"
                      onChange={handleMeetingSenarion}
                      value={{
                        label: formData.meeting,
                        value: formData.meeting,
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>

                {formData.meeting == "Internal" ? (
                  <div className="col-sm-4">
                    <div className="form-group mb-2">
                      <label className="inputLabel" style={{ color: "black" }}>
                        Remarks
                      </label>
                      <input
                        className="form-control inputField"
                        type="text"
                        name="remarks"
                        placeholder=""
                        value={formData.remarks}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-4">
                    <div className="form-group mb-2 mt-2">
                      <label className="inputLabel" style={{ color: "black" }}>
                        Frequency
                      </label>
                      <Select
                        options={[
                          { label: "Daily", value: "Daily" },
                          { label: "Weekly", value: "Weekly" },
                          { label: "B-Weekly", value: "B-Weekly" },
                          { label: "Monthly", value: "Monthly" },
                          { label: "Other", value: "Other" },
                        ]}
                        placeholder="Select an option"
                        onChange={(selectedOption) =>
                          handleInputChange({
                            target: {
                              name: "frequency",
                              value: selectedOption.value,
                            },
                          })
                        }
                        value={{
                          label: formData.frequency,
                          value: formData.frequency,
                        }}
                        styles={customStyles}
                      />
                      {/* <ReactDropdown
                        value={formData.frequency}
                        placeholder="Select an option"
                        options={[
                          "Daily",
                          "Weekly",
                          "B-Weekly",
                          "Monthly",
                          "Other",
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange({
                            target: {
                              name: "frequency",
                              value: selectedOption.value,
                            },
                          })
                        }
                      /> */}
                    </div>
                  </div>
                )}

                <div class="col-sm-3 col-lg-4">
                  <div class="form-group mb-2 mt-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      D - Mail Total Counts
                    </label>

                    <input
                      className="form-control inputField"
                      type="number" 
                      name="d_MailTotlCounts"
                      placeholder=""
                      value={formData.d_MailTotlCounts}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group mb-3 mt-3">
                    <label className="inputLabel" style={{ color: "black" }}>
                      Other
                    </label>
                    <textarea
                      className="form-control inputField"
                      placeholder="Comments if any!"
                      name="otherComments"
                      value={formData.otherComments}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4"></div>
            </Panel>

            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
                <h4
                  className="inputLabel"
                  style={{
                    color: "black",
                    borderBottom: "2px solid rgb(14, 116, 144)",
                    paddingBottom: "10px",
                  }}
                >
                  Reports
                </h4>
              </div> */}
            <div className="mt-4"></div>

            <Panel defaultExpanded={true} header="Reports" collapsible={true}>
              <div className="row mt-4">
                <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label className="inputLabel" style={{ color: "black" }}>
                      Provider Reports
                    </label>
                    {/* <ReactDropdown
                      className="inputField"
                      value={formData.providerReports}
                      placeholder="Select an option"
                      options={[
                        "DWC",
                        "Patient Statement",
                        "Co-Pay Collection",
                        "Practice Health Report",
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "providerReports",
                            value: selectedOption.value,
                          },
                        })
                      }
                    /> */}
                    <Select
                      options={options}
                      placeholder="Select an option"
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "providerReports",
                            value: selectedOption.value,
                          },
                        })
                      }
                      value={{
                        label: formData.providerReports,
                        value: formData.providerReports,
                      }}
                      styles={customStyles}
                    />
                  </div>

                </div>


                {addButton && (
                  <div class="col-sm-3 col-lg-3">
                    <div class="form-group mb-2 ">
                      <label class="inputLabel" style={{ color: "black" }}>
                        Enter New Option Title
                      </label>

                      <input
                        className="form-control inputField"
                        type="text"
                        placeholder="Enter new Option to be added"
                        value={newOption}
                        onChange={handleInputChangee}
                      />
                    </div>
                  </div>
                )}

                <div className=" col-lg-2 mt-4 ">
                  <div className="form-group mb-2 mt-1">
                    {addButton ? (
                      <>
                        <button
                          type="button"
                          onClick={addOption}
                          className="btnSave p-2"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={handleShowButton}
                          className="btnSave mt-1 ml-1 p-2"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <span
                          className=""
                          style={{
                            right: "0",
                            backgroundColor: "green",
                            marginTop: "1px",
                          }}
                          onClick={() => handleShowButton()}
                        >
                          <IconButton
                            style={{
                              color: "#0e7490",
                              backgroundColor: "#0e7490",
                            }}
                            icon={
                              <PlusIcon
                                style={{ backgroundColor: "#0e7490" }}
                              />
                            }
                          ></IconButton>
                        </span>
                      </>
                    )}
                     </div>
                </div>

                <div className="col-sm-3">
                  <div className="form-group mb-2">
                    <label className="inputLabel" style={{ color: "black" }}>
                      Frequency
                    </label>
                    {/* <ReactDropdown
                      value={formData.providerReportsFrequency}
                      placeholder="Select an option"
                      options={[
                        "Daily",
                        "Weekly",
                        "B-Weekly",
                        "Monthly",
                        "Other",
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "providerReportsFrequency",
                            value: selectedOption.value,
                          },
                        })
                      }
                    /> */}
                    <Select
                      options={[
                        { label: "Daily", value: "Daily" },
                        { label: "Weekly", value: "Weekly" },
                        { label: "B-Weekly", value: "B-Weekly" },
                        { label: "Monthly", value: "Monthly" },
                        { label: "Other", value: "Other" },
                      ]}
                      placeholder="Select an option"
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: "providerReportsFrequency",
                            value: selectedOption.value,
                          },
                        })
                      }
                      value={{
                        label: formData.providerReportsFrequency,
                        value: formData.providerReportsFrequency,
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group mb-3 mt-3">
                    <label className="inputLabel" style={{ color: "black" }}>
                      Any Other Task
                    </label>
                    <textarea
                      className="form-control inputField"
                      name="anyOtherTask"
                      placeholder="Comments if any!"
                      value={formData.anyOtherTask}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4"></div>
            </Panel>
          </>
        </div>

        <div className="col-sm-12 col-lg-12 mt-2">
          <div className="form-group mb-2 ">
            <div className="d-flex justify-content-end mt-4">
              <button type="button" onClick={onClear} className="btnClear mr-1">
                Clear
              </button>
              <button type="button" onClick={onSave} className="btnSave">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamLeadForm;
