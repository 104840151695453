import React from "react";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { DatePicker } from "rsuite";
import UserAuditTable from "../../../Audit/userTaskViewTable";

import { Checkbox } from "rsuite";
import { Panel } from "rsuite";
import moment from "moment/moment";

export default function AddNewHtml({onAdd,parentHelpingState,handlePopup,handleChange,addNewState,handleEditState,handleDelete}) {

  const getRequriedPara = () => {};
  const handleKeyDown = (e) => {
    // if (e.key === '.') {
    //   e.preventDefault();
    // }
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
    }
  };
 
  let pendingSectionhtml = (
    <>
      <Panel
        defaultExpanded={true}
        header="Pending Claims Breakup"
        collapsible={true}
      >
        
        <div className="row mt-4 ">
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Provider
              </label>

              <input
                class="form-control inputField"
                type="number"
                name="provider"
                onWheel={(e) => e.target.blur()}
                placeholder=""
                  value={addNewState?.provider}
                //  onKeyPress={handleKeyPress}
                 onChange={handleChange}
                 onkeydown={handleKeyDown}
                 disabled={addNewState?.receivedClaims==addNewState?.workedClaims}
              />
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Coding
              </label>

              <input
                class="form-control inputField"
                type="number"
                onWheel={(e) => e.target.blur()}
                name="operation"
                placeholder=""
                   value={addNewState?.operation}
                  // onKeyPress={handleKeyPress}
                   onChange={handleChange}
                   disabled={addNewState?.receivedClaims==addNewState?.workedClaims}
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Total
              </label>

              <input
                class="form-control inputField"
                type="number"
                name="total"
                placeholder=""
                disabled
                   value={addNewState?.total}
                   onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Panel>
    </>
  );

  const TableColumns = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "Dos", rowSpan: 1 },

    {
      colName: `Received Claims`, //Pat Statement
      flexGrow: 2.5,
      dataKey: "receivedClaims",
      rowSpan: 1,
    },

   
   
    {
      colName: `Worked Claims`, //Rejected
      flexGrow: 2.5,
      dataKey: "workedClaims",
      rowSpan: 1,
    },
    
    {
      colName: `Pending Claims`, //Received Amount
      flexGrow: 2.5,
      dataKey: "pendingClaims",
      rowSpan: 1,
    },
    {
        colName: `Total Breakup`, //Received Amount
        flexGrow: 2.5,
        dataKey: "total",
        rowSpan: 1,
      },
    
    

      {
        colName: "Action",
        flexGrow: 1.6,
        dataKey: "id",
        rowSpan: 1,
        actions: [
          addNewState?.isgridEdit
            ? {}
            : { label: "E", onClick: handleEditState, btnType: "Edit" },
          { label: "D", onClick: handleDelete, btnType: "Delete" },
        ],
        editRow: true,
      },
  ];

  
  return (
    <>
    <div className="mr-2">
      <Panel defaultExpanded={true} header="Dos Wise Fields" collapsible={true}>
        <div className="row mt-4 ">
          <div className={true ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              DOS 
            </label>
            <br />
            <DatePicker
               onChange={handleChange}
              
              style={{ width: 400 }}
            
              oneTap
              value={addNewState?.Dos ? moment(addNewState?.Dos).toDate() : null}
              
              onKeyDown={handleKeyDown}
            />
            <label
        className="inputLabel"
        style={{ color: "black", fontSize: "13px", color: "red" }}
      >
        {addNewState.errorModel?.DosError == true ? "Date is Required" : null}
      </label>
          </div>

          {/* ******************Received Amount***************** */}
          <div className={true ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Received Claims"} <span style={{ color: "red" }}>*</span>
            </label>

            <input
              className="form-control inputField"
              type="number"
              name="receivedClaims"
              placeholder=""
              onWheel={(e) => e.target.blur()}
               value={addNewState?.receivedClaims}
               onChange={handleChange}
              // onKeyPress={handleKeyPress}
               required
              //  disabled={gridEdit.isBfEdit == true ? true : false}
            />

            <label
        className="inputLabel"
        style={{ color: "black", fontSize: "13px", color: "red" }}
      >
        {addNewState.errorModel?.receivedClaimsError == true
          ? "Received Claims is Required"
          : null}
      </label>
          </div>

          {/* worked claism */}

          <div className={true ? "col-3" : "col-4"}>
            <label
              className="inputLabel"
              style={{ color: "black", fontSize: "13px" }}
            >
              {"Worked Claims"} <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="form-control inputField"
              type="number"
              onWheel={(e) => e.target.blur()}
              name="workedClaims"
              placeholder=""
                value={addNewState?.workedClaims}
              //  onKeyPress={handleKeyPress}
               onChange={handleChange}
              required
            />
            <label
        className="inputLabel"
        style={{ color: "black", fontSize: "13px", color: "red" }}
      >
        {addNewState.errorModel?.workedClaimsError == true
          ? "Worked Claims is Required"
          : null}
      </label>
          </div>
          {true && (
            <div className={"col-3"}>
              <label
                className="inputLabel"
                style={{ color: "black", fontSize: "13px" }}
              >
                {"Is DemoGraphic"}
              </label>

              <div class="form-group input-icon">
                <Checkbox  name='isDemographic' onChange={handleChange} checked={addNewState?.isDemographics}/>
              </div>
            </div>
          )}

          {true ? (
            <div className={"col-3"}>
              <label
                className="inputLabel"
                style={{ color: "black", fontSize: "13px" }}
              >
                {"Pending Claims"}
              </label>
              <input
                onWheel={(e) => e.target.blur()}
                className="form-control inputField"
                type="number"
                name="pendingClaims"
                placeholder=""
                  value={addNewState?.pendingClaims}
               
                disabled
              />
            </div>
          ) : null}
        </div>
      </Panel>
      <div className="row mt-4"></div>

      {true ? pendingSectionhtml : null}
      {true && (
        <>
          <div className="col-4"></div>
          <div className="col-4"></div>
        </>
      )}
      <div className={true ? "col-4" : "col-sm-6 col-lg-3"}>
        <div class="form-group ">
          <div className={true ? "d-flex  mt-4" : "d-flex mt-4"}>
            <button
              type="button"
              className="btnClear mr-1"
                onClick={onAdd}
            >
               {addNewState.isgridEdit  ? "Update" : "Add"}
             
              {/* {addNewState.isgridEdit || gridEdit.isBfEdit ? "Update" : "Add"} */}
            </button>
            <div className="d-flex  mt-2 ml-2">
              {" "}
              <InfoOutlineIcon
              //   onClick={()=>openInfoSwal('Auth')}
              //  style={{fill:'#0e7490'}}
              />
            </div>
          </div>
        </div>
      </div>
      <UserAuditTable
        getRequriedPara={getRequriedPara}
          Footer={addNewState?.footerState}
        rowHeight={48}
        //  handleChange={handleChange}
        //  handleEditState={handleEditState}
        dateForgrid={parentHelpingState?.internalTableArray}
        //  isPopupOpen={isPopupOpen}
        Array={TableColumns}
        rowShown={5}
        flagFooter={'coding'}
        isPaginationShow={true}
      />
      <div className="row">
        <div class="col-sm-6 col-lg-6"></div>
        <div class="col-sm-3 col-lg-3"></div>

        <div class="col-sm-3 col-lg-3 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <div className="mr-2 "></div>

              
                <button type="button" className="btnSave" onClick={()=>handlePopup(false)}>
                  Back
                </button>
              
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
