import React, { useState } from "react";
import CodingAuditTab from "./CodingAuditTab";
import CodingAuditField from "./CodingAuditField";

function CodingMainForm({
  setProducts,
  date,
  handleFormVal,
  products,
  startDatee,
  endDate,
  formType,
}) {
  
  let obj = {
    tabName: "userWise",
  };
  const [helpingObj, sethelpingObj] = useState(obj);
  return (
    <>
      <div class=" row mt-1">
        <div class="" id="accordion" role="tablist" aria-multiselectable="true">
          {true && (
            <>
              {" "}
              <div class="panel panel-default mt-4">
                <div
                  id="collapseOne"
                  className={
                    true
                      ? "panel-collapse collapse show"
                      : "panel-collapse collapse show"
                  }
                  aria-expanded={true ? true : false}
                >
                  <div class="">
                    <CodingAuditTab sethelpingObj={sethelpingObj} />
                    {helpingObj.tabName == "userWise" ? (
                      <div className="row">
                        <CodingAuditField
                          setProducts={setProducts}
                          formTypeString={"UserWise Productivity"}
                          date={date}
                          handleFormVal={handleFormVal}
                          products={products}
                          startDatee={startDatee}
                          endDate={endDate}
                          formType={formType}
                        />
                      </div>
                    ) : ""
                    // helpingObj.tabName == "Agging" ? (
                    //   <Agging />
                    // ) : (
                    //   <Threshold products={products} 
                    //   />
                    // )
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default CodingMainForm;
