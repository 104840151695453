import React, { useState, useEffect } from "react";
import UserAuditTable from "../../../Audit/userTaskViewTable";
import ChargeTypeRemarksPop from "./DrawerChargeEntry";
import Sweetalertdemo from "../../../../Common/NotifySwal";
import { getBucketImages, verifyChargeEntryTl } from "../../../../Utils/ApiManager";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PopupAttachment from "./popupAttachment";

export default function AuditTableTl({
  openDosView,
  verifyFlag,
  flagIndi,
  practiceId,
  date,
  setHelpingState,
  helpingStatem,
  loading,
  tableState,
  setDosArray,

  dosArray,
}) {
  const {onLoginSignOff} = useSelector((state) => state);
  let obj = {
    record: [],
    isToggleRemarks: false,
    Dos: "",
    isVerifyToggle: false,
    userId: null,

    //For chargen Entry
    ReceivedClaims: "",
    WorkedClaims: "",
    PendingClaims: "",
    ReceivedAmount: "",
    WorkedAmount: "",
    PendingAmount: "",


    ReceivedVobClaim: "",
    callsvob: "",
    portals: "",
    image:'',
    openPopup:false
  };
  console.log("here", practiceId, date);
  const [state, setState] = useState(obj);
  useEffect(() => {
    let paresedArray2;
    if (
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved == "" ||
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved == null
    ) {
      setState((prev) => ({ ...prev, record: [] }));
    } else {
      try {
        paresedArray2 = JSON.parse(
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved
        );

        console.log("The string contains a valid JSON object.");
      } catch (error) {
        paresedArray2 = helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved;
      }
      setState((prev) => ({ ...prev, record: paresedArray2 }));
    }
  }, [helpingStatem.holdSubDosTableData]);

  const onShowTable = (rowData) => {
    let matchedArray = tableState.filter(
      (item) => item.userid === String(rowData.userid)
    );
    setHelpingState((prev) => ({
      ...prev,
      isSwitchTable: true,
      holdSubDosTableData: matchedArray,
    }));
  };
  const onShowRemakrs = (rowData) => {
    if (flagIndi == "Reject" || flagIndi == "charge" || flagIndi == "Auth") {
      setState((prev) => ({
        ...prev,
        isToggleRemarks: true,
        Dos: rowData.date,
        ReceivedAmount: rowData.AmountDosVal,
        WorkedAmount: rowData.AmountDosValCC,
        ReceivedClaims: rowData.recievedAmount,
        WorkedClaims: rowData.recievedAmountCC,
        PendingAmount:
          parseInt(rowData.AmountDosVal) - parseInt(rowData.AmountDosValCC),
        PendingClaims:
          parseInt(rowData.recievedAmount) - parseInt(rowData.recievedAmountCC),
      }));
    } else if (flagIndi == "vob") {
      setState((prev) => ({
        ...prev,
        isToggleRemarks: true,
        Dos: rowData.date,
        ReceivedVobClaim: rowData.recievedAmountCC,
        portals: rowData.AmountDosVal,
        callsvob: rowData.AmountDosValCC,
      }));
    }
  };
  const handleAttachments = (arg) => {
 
    let parrsedArray=[]
    try{
      parrsedArray=JSON.parse(arg?.image)

    }catch{
      parrsedArray=arg?.image

    }
    
    setState((prev)=>({...prev,image:parrsedArray,openPopup:true}))
    

  };


 
  const handleVerification = (rowData) => {
    setState((prev) => ({ ...prev, userId: rowData.userid }));

    setState((prev) => ({ ...prev, isVerifyToggle: true }));
  };
  const switchDos = (val) => {
    
   
    openDosView(val)
  };
  const MainTable = [
    { colName: "User", flexGrow: 1.9, dataKey: "name", rowSpan: 1 },

    {
      colName: `Received Claims`,
      flexGrow: 1.9,
      dataKey: "recievedval",
      rowSpan: 1,
    },
    {
      colName: `Worked Claims`,
      flexGrow: 1.9,
      dataKey: "workedval",
      rowSpan: 1,
    },
    {
      colName: `Pending Claims`,
      flexGrow: 1.9,
      dataKey: "pendingval",
      rowSpan: 1,
    },
    {
      colName: `Received Amount`,
      flexGrow: 1.9,
      dataKey: "recievedamount",
      rowSpan: 1,
    },
    {
      colName: `Worked Amount`,
      flexGrow: 1.9,
      dataKey: "workedamount",
      rowSpan: 1,
    },
    {
      colName: `Pending Amount`,
      flexGrow: 1.9,
      dataKey: "pendingamountval",
      rowSpan: 1,
    },
    // {
    //   colName: `Verification Status`,
    //   flexGrow: 1.9,
    //   dataKey: "verify",
    //   rowSpan: 1,
    // },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "D", onClick: onShowTable, btnType: "Audit" },
        { label: "D", onClick: handleAttachments, btnType: "View" },
        // { label: "D", onClick: handleVerification, btnType: "Verify" },
        { label: "D", onClick: switchDos, btnType: "info" },
      ],

      editRow: true,
    },
  ];

  const CloseAuditRemark = () => {
    setState((prev) => ({ ...prev, isToggleRemarks: false }));
  };

  const DosWiseTable = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Received Claims`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Worked Claims`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Pending Claims`,
      flexGrow: 1.9,
      dataKey: "total",
      rowSpan: 1,
    },
    {
      colName: `Received Amount`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `Worked Amount`,
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },

    {
      colName: "Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowRemakrs, btnType: "Edit" }],

      editRow: true,
    },
  ];
 

  const MainTableVob = [
    { colName: "User", flexGrow: 1.9, dataKey: "name", rowSpan: 1 },

    {
      colName: `Received`,
      flexGrow: 1.9,
      dataKey: "recievedval",
      rowSpan: 1,
    },
    {
      colName: `Portals`,
      flexGrow: 1.9,
      dataKey: "portals",
      rowSpan: 1,
    },
    {
      colName: `Calls`,
      flexGrow: 1.9,
      dataKey: "calls",
      rowSpan: 1,
    },
    // {
    //   colName: `Verification Status`,
    //   flexGrow: 1.9,
    //   dataKey: "verify",
    //   rowSpan: 1,
    // },

    {
      colName: "Action",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "D", onClick: onShowTable, btnType: "Audit" },
        { label: "D", onClick: handleAttachments, btnType: "View" },
        // { label: "D", onClick: handleVerification, btnType: "Verify" },
        { label: "D", onClick: switchDos, btnType: "info" },
      ],

      editRow: true,
    },
  ];

  const DosWiseTablevob = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Received `,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Calls`,
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },
    {
      colName: `Portals`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },

    {
      colName: " Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowRemakrs, btnType: "Edit" }],

      editRow: true,
    },
  ];

  const handleCloseSwal = async (val) => {
    if (val) {
      //call new Api
      let obj = {
        pid: practiceId,
        sdate: date,
        userId: state.userId,
        formtype:
          verifyFlag == "charge"
            ? 1
            : verifyFlag == "Reject"
            ? 2
            : verifyFlag == "Auth"
            ? 3
            : verifyFlag == "vob"
            ? 4
            : null,
      };
      await verifyChargeEntryTl(obj)
        .then((res) => {
          toast.info("Record Verified Successfully");
          setState((prev) => ({ ...prev, isVerifyToggle: false }));
        })
        .catch((err) => {
          toast.info("Record do not Verified Successfully");

          setState((prev) => ({ ...prev, isVerifyToggle: false }));
        });
    }
  };
  const handleClose=()=>{
    setState((prev)=>({...prev,openPopup:false}))
  }
  const getRequriedPara=()=>{

  }

  console.log("tableState", tableState);

  return (
    <>
    {state?.openPopup==true?<PopupAttachment handleClose={handleClose}  state={state}/>:null}
      {state.isVerifyToggle ? (
        <Sweetalertdemo
          handleCloseSwal={handleCloseSwal}
          triggerNotify={true}
          titleSweet={"Are you sure"}
          text={"Do you want to verify the record"}
          swalType={'Confirmation'}
        />
      ) : null}
      <div className="row">
        <div class="col-sm-6 col-lg-12 ">
          {state.isToggleRemarks ? (
            <>
              <ChargeTypeRemarksPop
                flagIndi={flagIndi}
                verifyFlag={verifyFlag}
                prevstate={state}
                formNamee={
                  verifyFlag == "charge"
                    ? "ChargeEntry"
                    : verifyFlag == "Reject"
                    ? "Rejection"
                    : verifyFlag == "Auth"
                    ? "Authorization"
                    : verifyFlag == "vob"
                    ? "vobs"
                    : null
                }
                practiceId={practiceId}
                date={date}
                CloseAuditRemark={CloseAuditRemark}
                rowDataAudit={helpingStatem.holdSubDosTableData}
              />
            </>
          ) : null}
          {helpingStatem.isSwitchTable ? (
            <UserAuditTable
            getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={state.record ? state.record : []}
              // isPopupOpen={isPopupOpen}
              Array={flagIndi == "vob" ? DosWiseTablevob : DosWiseTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />
          ) : (
            <UserAuditTable
            getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={tableState}
              // isPopupOpen={isPopupOpen}
              Array={flagIndi == "vob" ? MainTableVob : MainTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />
          )}
        </div>
      </div>

      <div className="row">
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        {/* <div class="col-sm-1 col-lg-3 mt-2">
  <div class="form-group ">
    <div className="d-flex justify-content-end mt-4">
      <button
        type="button"
        className="btnClear mr-1 "
        onClick={handleClose}
      >
        Back
      </button>
      
     
    </div>
  </div>
</div> */}
      </div>
    </>
  );
}
