import React, { useEffect } from "react";
import "../Operation/operation.css";
import { useState } from "react";
// import OperationMainForm from "./OperationMainForm";

import { useSelector } from "react-redux";
import {
  FetchTeamLeadPayment,
  fetchProduct,
  getAccVerify,
  getChargeEntryTeamLead,
  getDenialTeamLead,
  getPaymnetTeamLead,
  getTlRecordsApi,
  searchChargeEntry,
} from "../../Utils/ApiManager";
import moment from "moment";

import FormTypeTL from "./FormTypeTl";
import TLMainForm from "./TLMainForm";

function TLInterface() {
  const [formType, setFormType] = useState('TLInterface');
  const [formTypeNoForApi, setFormTypeNoForApi] = useState(null);
  const [practiceId, setPracticeId] = useState(null);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [record, setRecord] = useState(null);
  const { onLoginSignOff } = useSelector((state) => state);
  const [subCheckBox, setSubCheckBoxCharge] = useState("");
  let obj={
    loading:false
  }
  const [heloingObj,setHelpingObjTlinterface]=useState(obj)


  useEffect(() => {
    if (formType && practiceId && date) {
      if (formType == "AmountVerification") {
        getRecordApi();
      }
      if (formType == "DeniedVerification") {
        getRecordApiDenial();
      }
      if (formType == "PaymentsVerification") {
        getRecordPayment()

      }
      if(formType=='AccountRecVerify'){
        getAccVerifyRecord()
      }
        
    }
  }, [formType, practiceId, date]);
  const getAccVerifyRecord=async ()=>{
   // setHelpingObjTlinterface((prev)=>({...prev,loading:true}))
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };

    let result = await getAccVerify(obj);
    if(result && result?.data){
     // setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
      setRecord(result.data);

    }else{
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
    }
 
    

  }
  const getRecordApi = async () => {
   // setHelpingObjTlinterface((prev)=>({...prev,loading:true}))
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: 1,
      submittype: "Yes",
    };

    let result = await searchChargeEntry(obj);
    if(result && result?.data){
   //   setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
      setRecord(result?.data);
    }
    else{
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
    }
   
  };
  const getRecordPayment = async () => {
  //  setHelpingObjTlinterface((prev)=>({...prev,loading:true}))
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };

    let result = await FetchTeamLeadPayment(obj);
    if(result && result?.data){
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
      setRecord(result.data);
    }
    else{
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
    }
    
  };

  const getRecordApiDenial= async () => {
   // setHelpingObjTlinterface((prev)=>({...prev,loading:true}))
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };

    let result = await getDenialTeamLead(obj);
    if(result && result?.data){
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
      setRecord(result.data);
    }
    else{
    //  setHelpingObjTlinterface((prev)=>({...prev,loading:false}))
    }
   
  };

  const handleFormTypeAndPract = ({ name, val }) => {
   
    
   

    if (name == "date") {
      setDate(val);
      return;
    }
    if (name == "formType") {
      if (val == "AmountVerification") {
        setFormTypeNoForApi(11);
        setFormType(val);
      }
      if (val == "PaymentsVerification") {
        setFormTypeNoForApi(12);
        setFormType(val);
      }

      if (val == "DeniedVerification") {
        setFormTypeNoForApi(13);
        setFormType(val);
      }
      if (val == "AccountRecVerify") {
        setFormTypeNoForApi(14);
        setFormType(val);
      }
      if (val == "TLInterface") {
        setFormTypeNoForApi(15);
        setFormType(val);
      }


      return;
    }
    if (name == "practiveVal") {
      setPracticeId(val);
      return;
    }
  };

  const onRecordUpdateOrCreated = (val) => {
    if (val == "AmountVerification") {
      getRecordApi();
      return;
    }
    if(val=='DenialTl'){
      getRecordApiDenial();


    }
    if(val=='PaymentsVerification'){
      getRecordPayment()
    }
    if(val=='AccountRecVerify'){
      getAccVerifyRecord()
    }
    if(val=='AccVerify'){
      getAccVerifyRecord()

    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setProducts(optionList);
    }
  };
  const handleSubCheckBoxChargeEntry = (val) => {
    setSubCheckBoxCharge(val);
  };

  console.log('SYSTEM console',practiceId)

  return (
    <>
     {heloingObj?.loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ):<>
       <div className="row">
        <div className="col-lg-12">
          <div class="">
            <div class="p-4 navcolor bg-white rounded  mb-5">
              <div>
                <TLMainForm
                setPracticeId={setPracticeId}
                setRecord={setRecord}
                handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                  handleFormTypeAndPract={handleFormTypeAndPract}
                  onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                  record={record}
                  practiceId={practiceId}
                  formTypeNoForApi={formTypeNoForApi}
                  date={date}
                  formType={formType}
                  subCheckBox={subCheckBox}
                  products={products}
                />
                {/* <OperationMainForm formType={formType} record={record} date={date} formTypeNoForApi={formTypeNoForApi} /> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4">
          <div class="container">
            <div className="p-4 navcolor bg-white rounded shadow mb-5">
              <FormTypeTL
                handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
                date={date}
                handleFormTypeAndPract={handleFormTypeAndPract}
                products={products}
              />
            </div>
          </div>
        </div> */}
      </div>
      </>
}
    </>
  );
}
export default TLInterface;
