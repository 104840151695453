import React, { useState,useEffect } from "react";
import { Modal, Button } from "rsuite";
import { List } from "rsuite";
import FileUpload2 from "../../../../Common/FileUpload2";
import { getBucketImages } from "../../../../Utils/ApiManager";
import ViewDocument from "../../../../Common/IframeModel";

export default function PopupAttachment({ handleClose, state }) {
  const [data, setData] = React.useState(state?.image);
  let obj = {
    isAttachmentOpen: false,
    selectedRow: null,
  };
  const [helperState, setHelperState] = useState(obj);

  const handleSortEnd = ({ oldIndex, newIndex }) =>
    setData((prvData) => {
      const moveData = prvData.splice(oldIndex, 1);
      const newData = [...prvData];
      newData.splice(newIndex, 0, moveData[0]);
      return newData;
    }, []);
  useEffect(() => {
    if (helperState?.selectedRow != null) {
      setHelperState((prev) => ({
        ...prev,

        isAttachmentOpen: true,
      }));
    }
  }, [helperState?.selectedRow]);

  const onPreview = (ele) => {
    let obj = { bucketImages: ele };
    setHelperState((prev) => ({
      ...prev,
      selectedRow: obj,
    }));
  };

  const openImageViwerModel = () => {
    setHelperState((prev) => ({
      ...prev,

      isAttachmentOpen: false,
    }));
  };
  console.log("state?.image", state?.image);
  return (
    <>
      {helperState?.isAttachmentOpen == true ? (
        <>
          <ViewDocument
            seletedRow={helperState?.selectedRow}
            openImageViwerModel={openImageViwerModel}
          />
        </>
      ) : null}
      <Modal size={"md"} open={true} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List hover className="attachmentEye">
            {data.map((el, index) => (
              <List.Item
                key={index}
                index={index}
                onClick={() => onPreview(el)}
              >
                <span className="fas fa-eye attachmentEye lightGreen"></span>{" "}
                <span className="ml-2 ">{el?.originalname} </span>
              </List.Item>
            ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#0e7490" }}
            appearance="primary"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
