import React from "react";

export default function DashboardCard({ icons, text, data ,backgroundcolor}) {
  return (
    <>
    <>
    <div
        className={`tw-w-full lg:tw-w-7/8 ${backgroundcolor} tw-border tw-border-gray-200 tw-min-h-[80px] tw-rounded-lg tw-shadow tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center`}
      >
        <div className="tw-flex-grow">
          <h5
            className="tw-ml-2 tw-mb-2 tw-text-[24px] tw-font-semibold tw-tracking-tight tw-text-white tw-dark:text-white"
          >
            {data}
          </h5>
          <p
            className="tw-ml-2 tw-mb-3 tw-font-normal tw-text-white tw-text-[17px] tw-overflow-hidden lg:tw-text-left"
          >
            {text}
          </p>
        </div>
        <div className="tw-ml-2 lg:tw-ml-auto lg:tw-mr-2">
          <div className="tw-mb-2 tw-text-2xl">{icons}</div>
        </div>
      </div>
    </>
    </>
  );
}
