import React, { useEffect, useState } from "react";
import { Table, Button } from "rsuite";
import { Modal, ButtonToolbar, Loader, Placeholder } from "rsuite";
import { getTlRecordsApi, onEditTableRowApi } from "../../Utils/ApiManager";
import { toast } from "react-toastify";
import {IconButton} from 'rsuite'
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import DetailIcon from '@rsuite/icons/Detail';
import { debug } from "webpack";

const { Column, HeaderCell, Cell } = Table;
let rowKey = "id";
const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      style={{color:"0e7490"}}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

function EditTable({
  subTableValues,
  open,
  handleClose,
  loadingSub,
  formTypeForPay,
}) {
  const [rows, setRows] = React.useState(0);
  
  let payloadForEditPay = {
    rid: null,
    workedval: "",
    pendingval: "",
    comments: "",
    formtype: "",
    submittype: "rupdate",
  };
  
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  
  const handleExpanded = (rowData, dataKey) => {
   
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach(key => {
   
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };
  const renderRowExpanded = rowData => {
    
   
    return (
      <div>
        <div
          style={{
            width: 60,
            height: 60,
            float: 'left',
            marginRight: 10,
           
          }}
        >
          <DetailIcon  style={{
            width: 60,
            height: 60,
            float: 'left',
            marginRight: 10,
          
            color: '#0e7490'
          }} />
        </div>
        <p>Pid: {rowData.pid}</p>
        <p>Insurance: {rowData.insurance? rowData.insurance:'None'}</p>
      </div>
    );
  };

  const [payloadForEdit, setPayloadForEdit] = useState(payloadForEditPay);

  const handleEntered = () => {
    setTimeout(() => setRows(80), 2000);
  };

  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === "EDIT";
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {editing ? (
          <input
            className="rs-input"
            defaultValue={rowData[dataKey]}
            onChange={(event) => {
              onChange && onChange(rowData.id, dataKey, event.target.value);
            }}
          />
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  };

  const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <Button
          appearance="link"
          onClick={() => {
            onClick(rowData);
          }}
        >
          {rowData.status === "EDIT" ? "Save" : "Edit"}
        </Button>
      </Cell>
    );
  };
  const [data, setData] = React.useState(null);
  console.log(data,'superem data')

  useEffect(() => {
    if (subTableValues) {
      setData(subTableValues);
    }
  }, [subTableValues]);
  useEffect(() => {
    if (formTypeForPay) {
      setPayloadForEdit((prevState) => ({
        ...prevState,
        formtype: formTypeForPay,
      }));
    }
  }, [formTypeForPay]);

  const handleChange = (id, key, value) => {
    if (key == "workedval") {
      setPayloadForEdit((prevState) => ({
        ...prevState,
        workedval: value,
        rid: id,
      }));
    }
    if (key == "comments") {
      setPayloadForEdit((prevState) => ({
        ...prevState,
        comments: value,
        rid: id,
      }));
    }
    if (key == "pendingval") {
      setPayloadForEdit((prevState) => ({
        ...prevState,
        pendingval: value,
        rid: id,
      }));
    }

    const nextData = Object.assign([], data);
    nextData.find((item) => item.id === id)[key] = value;
    setData(nextData);
  };
  
  const handleEditState = async (rowData) => {
 

 
 
    if(rowData.status==null){
      setPayloadForEdit((prevState) => ({
        ...prevState,
        workedval: rowData.workedval,
        formtype: rowData.formtype,
        pendingval:rowData.pendingval

      }));

    }
   if(rowData.status=='EDIT'){

    let obj={
      rid:payloadForEdit.rid,
      workedval:payloadForEdit.workedval,
      pendingval:payloadForEdit.pendingval,
      comments:payloadForEdit.comments,
      formtype:payloadForEdit.formtype,
      submittype:payloadForEdit.submittype


    }

  
    // const formDataForSaveApi = new FormData();
    // formDataForSaveApi.append("rid", payloadForEdit.rid);
    // formDataForSaveApi.append("workedval", payloadForEdit.workedval);
    // formDataForSaveApi.append("pendingval", payloadForEdit.pendingval);
    // formDataForSaveApi.append("comments", payloadForEdit.comments);
    // formDataForSaveApi.append("formtype", payloadForEdit.formtype);
    // formDataForSaveApi.append("submittype", payloadForEdit.submittype);
    let res= await onEditTableRowApi(obj)
    if( res && res.data.message=="Record Successfully Updated"){
      toast.info('Record Updated Successfully')
      handleClose()
    }

   }

    const nextData = Object.assign([], data);
    const activeItem = nextData.find((item) => item.id === rowData.id);
    activeItem.status = activeItem.status ? null : "EDIT";
    setData(nextData);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        onEntered={handleEntered}
        onExited={() => {
          setRows(0);
        }}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title>Audit Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rows ? (
            <>
              <Table
                data={data}
                loading={loadingSub}
                autoHeight={true}
                cellBordered={true}
                headerHeight={37}
                expandedRowKeys={expandedRowKeys}
      onRowClick={data => {
        console.log(data);
      }}
      renderRowExpanded={renderRowExpanded}
      rowKey={rowKey}
              >
                 <Column flexGrow={1.1} align="center">
        <HeaderCell
          style={{
            backgroundColor: "#0e7490",
            color: "white",
            fontSize: "0.805rem",
          }}
        >
          Expand
        </HeaderCell>
        <ExpandCell
          dataKey="available"
          expandedRowKeys={expandedRowKeys}
          onChange={handleExpanded}
        />
      </Column>
                <Column flexGrow={1.7} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Date
                  </HeaderCell>
                  <Cell dataKey={"sdate"} />
                </Column>

                <Column flexGrow={1.3} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Received
                  </HeaderCell>
                  <Cell dataKey={"recievedval"} />
                  {/* <EditableCell dataKey="recievedval" onChange={handleChange} /> */}
                </Column>

                <Column flexGrow={2} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Avaialble to work
                  </HeaderCell>
                  <Cell dataKey={"recievedval"} />
                  {/* <EditableCell dataKey="recievedval" onChange={handleChange} /> */}
                </Column>
                <Column flexGrow={1.1} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Worked
                  </HeaderCell>
                  <EditableCell dataKey="workedval" onChange={handleChange} />
                </Column>
                <Column flexGrow={2.2} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Pending For Work
                  </HeaderCell>
                  {/* <Cell dataKey={"pendingval"} /> */}
                  <EditableCell dataKey="pendingval" onChange={handleChange} />
                </Column>
                <Column flexGrow={1.4} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Comments
                  </HeaderCell>
                  <EditableCell dataKey="comments" onChange={handleChange} />
                </Column>
              

                <Column flexGrow={1.1} align="center">
                  <HeaderCell
                    style={{
                      backgroundColor: "#0e7490",
                      color: "white",
                      fontSize: "0.805rem",
                    }}
                  >
                    Edit
                  </HeaderCell>
                  <ActionCell dataKey="image" onClick={handleEditState} />
                </Column>
              </Table>
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Loader size="md" style={{ color: "#0e7490" }} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
            onClick={handleClose}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EditTable;
