import moment from "moment/moment";
import React, { useState } from "react";
import Select from 'react-select';
import "react-dropdown/style.css";
function FormTypeTL({
  products,
  handleFormTypeAndPract,
  date,
  handleSubCheckBoxChargeEntry,
}) {
  const [checkedType, setCheckedType] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: state.isSelected ? 'white': 'black',
      backgroundColor: state.isSelected ? 'rgb(14, 116, 144)':'' ,
      marginTop:'2px',
      ':hover': {
        backgroundColor: 'rgb(14, 116, 144)', // Background color on hover
        cursor: 'pointer', // Change cursor on hover
        color: 'white'
      }
      
    }),
  };
  const handleFormType = (event) => {
    

  
    const { value } = event.target;
    if (
      value == "Reports" ||
      value == "AmountVerification" ||
      value == "MeetingTl" ||
      value == "PaymentsVerification" ||
      value == "ReportsPayments" ||
      value == "MeetingPayment"||
      value=='AccountPayments'||
      value=='DeniedVerification'||
      value=='MeetingDenied'||
      value=='ReportsDenied'||
      value=='AccountRecVerify'||
      value=='MeetingDeniedAC'||
      value=='ReportsDeniedAC'
      
    ) {
     
      handleSubCheckBoxChargeEntry(value);
      setCheckedType(value);
    }
    setCheckedType(value);
    let obj = { name: "formType", val: value };

    handleFormTypeAndPract(obj);
  };
  const handlePracticeVal = (val) => {
    const { value,label } = val;
   
    setSelectedOption(label);
    let obj = { name: "practiveVal", val: value };
    handleFormTypeAndPract(obj);
  };
  const handleVal = (event) => {
    const { value } = event.target;
    let obj = { name: "date", val: value };
    handleFormTypeAndPract(obj);
  };
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");
  return (
    <>
      <div className="card mb-3">
        <div
          className="card-header border-bottom"
          style={{ backgroundColor: "#0e7490", color: "white" }}
        >
          <h6 className="m-0">Practice Name / Date</h6>
        </div>
        <div class="">
          <div class="form-group mb-2">
            <label class="inputLabel">Date</label>

            <input
              class="form-control inputField"
              type="date"
              name="phone"
              placeholder=""
              max={today}
              min={minDate}
              defaultValue={date}
              onChange={handleVal}
            />
          </div>
        </div>
        <div class="">
          <div class="form-group mb-2">
            <label class="inputLabel">Practice</label>

            <Select
  options={products ? products : []}
  placeholder="Select an option"
  onChange={handlePracticeVal}
  defaultValue={selectedOption.value}
  styles={customStyles}
/>
          </div>
        </div>
        <br></br>
        <div
          className="card-header border-bottom"
          style={{ backgroundColor: "#0e7490", color: "white" }}
        >
          <h6 className="m-0">Form Type</h6>
        </div>
        <div className="card-body p-0">
          <ul className="list-group list-group-flush">
            <li className="list-group-item px-3 pb-2">
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="AmountVerification"
                  checked={
                    checkedType == "chargeEntryTl" ||
                    checkedType == "AmountVerification" ||
                    checkedType == "MeetingTl" ||
                    checkedType == "Reports"
                  }
                  onClick={handleFormType}
                />
                <label className="form-check-label">Charge Entry</label>
              </div>
              {checkedType == "chargeEntryTl" ||
              checkedType == "AmountVerification" ||
              checkedType == "MeetingTl" ||
              checkedType == "Reports" ? (
                <div className="form-check mb-1 payerClass">
                  <div className="form-check mb-1" style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="AmountVerification"
                      checked={checkedType == "AmountVerification"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Amount Verification
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="MeetingTl"
                      checked={checkedType == "MeetingTl"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Meetings
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Reports"
                      checked={checkedType == "Reports"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Reports
                    </label>
                  </div>
                </div>
              ) : null}

              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="PaymentsVerification"
                 
                  checked={checkedType=='ReportsPayments'|| checkedType=='PaymentsVerification'|| checkedType=='MeetingPayment' || checkedType=='AccountPayments'}
                  onClick={handleFormType}
                />
                <label className="form-check-label">Payments</label>
              </div>
              {checkedType=='ReportsPayments'|| checkedType=='PaymentsVerification'|| checkedType=='MeetingPayment' || checkedType=='AccountPayments' ? (
                <div className="form-check mb-1 payerClass">
                  <div className="form-check mb-1" style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="PaymentsVerification"
                      checked={checkedType == "PaymentsVerification"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Payments Verification
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="MeetingPayment"
                      checked={checkedType == "MeetingPayment"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Meetings
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ReportsPayments"
                      checked={checkedType == "ReportsPayments"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Reports
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="DeniedVerification"
                  
                  checked={
                   
                    checkedType == "DeniedVerification" ||
                    checkedType == "MeetingDenied" ||
                    checkedType == "ReportsDenied"
                  }
                  onClick={handleFormType}
                />
                <label className="form-check-label">Denials</label>
              </div>


              {checkedType=='ReportsDenied'|| checkedType=='DeniedVerification'|| checkedType=='MeetingDenied'  ? (
                <div className="form-check mb-1 payerClass">
                  <div className="form-check mb-1" style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="DeniedVerification"
                      checked={checkedType == "DeniedVerification"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Denied Verification
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="MeetingDenied"
                      checked={checkedType == "MeetingDenied"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Meetings
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ReportsDenied"
                      checked={checkedType == "ReportsDenied"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Reports
                    </label>
                  </div>
                </div>
              ) : null}

              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="AccountRecVerify"
                 
                  checked={checkedType=='AccountRecVerify'||  checkedType=='MeetingDeniedAC' || checkedType=='ReportsDeniedAC'}
                  onClick={handleFormType}
                />
                <label className="form-check-label">Account Receivable</label>
              </div>
              {checkedType=='ReportsDeniedAC'|| checkedType=='AccountRecVerify'|| checkedType=='MeetingDeniedAC'  ? (
                <div className="form-check mb-1 payerClass">
                  <div className="form-check mb-1" style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="AccountRecVerify"
                      checked={checkedType == "AccountRecVerify"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Account Receivable Frequency
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="MeetingDeniedAC"
                      checked={checkedType == "MeetingDeniedAC"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Meetings
                    </label>
                  </div>
                  <div className="form-check mb-1 " style={{ left: "30px" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ReportsDeniedAC"
                      checked={checkedType == "ReportsDeniedAC"}
                      onClick={handleFormType}
                    />
                    <label
                      className="form-check-label"
                      style={{ marginLeft: "7px" }}
                    >
                      Reports
                    </label>
                  </div>
                </div>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default FormTypeTL;
