import React, { useState, useEffect } from "react";
import { Table, Tag, Pagination, Button } from "rsuite";


import "rsuite/dist/rsuite.css";
import "rsuite-table/dist/css/rsuite-table.css";
import EditIcon from "@rsuite/icons/Edit";
import AdvancedAnalyticsIcon from "@rsuite/icons/AdvancedAnalytics";
import TrashIcon from "@rsuite/icons/Trash";


import EyeCloseIcon from "@rsuite/icons/EyeClose";
import ViewsAuthorizeIcon from '@rsuite/icons/ViewsAuthorize'

import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import CheckIcon from '@rsuite/icons/Check';

const { Column, HeaderCell, Cell } = Table;

const NotifyTable = ({
  flagFooter,
  Footer,
  isPopupOpen,
  rowShown,
  rowHeight,
  dateForgrid,
  state,
  loader,
  ColumnName,
  Array,
  onChangePop,
  handleEditState,
  handleChange,
  deleteRow,
}) => {
  const [DataForGrid, setDataForGrid] = useState([]);
  const [limit, setLimit] = useState(rowShown ? rowShown : 7);
  const [effect, setrenderingEffect] = useState(false);
  const [page, setPage] = useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  console.log("Array", Array);
  const data =
    DataForGrid?.length > 0 &&
    DataForGrid?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  const PopUpCell = ({
    rowData,

    onChangePop,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 5 }}>
      {/* <CheckIcon
        onClick={() => onChangeVerify(rowData)}
        style={{ color: "#0e7490" }}
      /> */}

      <AdvancedAnalyticsIcon
        onClick={() => onChangePop(rowData)}
        style={{ color: "#0e7490" }}
      />
    </Cell>
  );

  useEffect(() => {
    if (dateForgrid) {
      const data = Object.keys(dateForgrid).map((key) => {
        return {
          CatValue: key,
          ...dateForgrid[key],
        };
      });
      console.log(data, "first check");
      setDataForGrid(dateForgrid);
    }
  }, [dateForgrid, isPopupOpen]);

  console.log(DataForGrid, "DataForGrid");

  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    console.log(rowData.bfRow, "kop");
    const editing = rowData?.status === "EDIT";

    let warningColorRow = rowData?.bfRow ? "warningRowColor" : null;
    return (
      <Cell
        {...props}
        style={
          rowData?.bfRow
            ? { backgroundColor: "#f3efa4" }
            : { backgroundColor: "#F0F9FF" }
        }
        className={editing ? `table-content-editing ` : ""}
      >
        {editing ? (
          <input
            key={"input1"}
            className="rs-input"
            style={{ height: "50x !important" }}
            defaultValue={rowData[dataKey]}
            onChange={(event) => {
              onChange &&
                onChange(rowData.id, dataKey, event.target.value, event);
            }}
          />
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  };

  const ActionCell = ({ item, rowData, dataKey, btnType, ...props }) => {
    console.log(item, "btnType");
    return (
      <Cell
        {...props}
        style={
          rowData?.bfRow
            ? {
                backgroundColor: "#f3efa4",
                padding: "6px",
                height: "31px !important",
              }
            : { padding: "6px", backgroundColor: "#F0F9FF" }
        }
      >
        {item &&
          item?.actions?.map((el, index) => {
            return (
              <Button
                appearance="link"
                key={index}
                onClick={() => el.onClick(rowData)}
              >
                {el.btnType == "Edit" ? (
                  rowData.status === "EDIT" ? (
                    <ViewsAuthorizeIcon style={{ color: "#0e7490" }} />
                  ) : (
                    <EditIcon style={{ color: "#0e7490" }} />
                  )
                ) : el.btnType == "Delete" ? (
                  <TrashIcon style={{ color: "red" }} />
                ):el.btnType=='Audit'?<ViewsAuthorizeIcon /> : el.btnType == "View" ? (
                  <EyeCloseIcon style={{ color: "#0e7490" }} />
                ) :el.btnType=='Bell'?<i style={{ color: "#0e7490" }} className="far fa-bell"></i>:el.btnType=='Verify'?<CheckIcon style={{ color: "#0e7490" }}/>: (
                  el.label
                )}
              </Button>
            );
          })}
      </Cell>
    );
  };

  console.log(data, "investigate", Array);
let arr=[{

}]

  return (
    <>
      <div style={{ height: "200px" }}>
        <Table
        
          affixHeader={true}
          virtualized
          shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
          height={200}
          data={data}
          onRowClick={(data) => {
            console.log(data);
          }}
          //autoHeight={true}
          cellBordered={true}
          headerHeight={37}
          rowHeight={rowHeight ? rowHeight : 30}
          loading={loader}
        >
          {Array &&
            Array.map((item, index) => (
              <Column
                key={index}
                flexGrow={item.flexGrow ? item.flexGrow : 2}
                align="center"
                fixed
              >
                <HeaderCell
                  style={{
                    backgroundColor: isPopupOpen ? "#0e7490" : "#0e7490",
                    color: "white",
                    fontSize: "0.805rem",
                    position: "sticky",
                  }}
                >
                  {item.colName}
                </HeaderCell>

                {item?.popupCell ? (
                  <PopUpCell onChangePop={onChangePop} />
                ) : item?.editRow ? (
                  <ActionCell
                    item={item}
                    onClick={handleEditState}
                    onClickDel={deleteRow}
                  />
                ) : (
                  <EditableCell
                    dataKey={item?.dataKey}
                    onChange={handleChange}
                  />
                )}
              </Column>
            ))}
        </Table>
        
      
        
       
      </div>
      
     
      <br></br>
      {" "}

   

      
        
    </>
  );
};
export default NotifyTable;
