import React, { useEffect, useContext } from "react";
import { Input } from "rsuite";
import { Message } from "rsuite";

export default function OverAllAuditField({
  state,
  DataDos,
  flagIndi,
  setAuditPop,
  popAudit,
}) {
  console.log('popAudit',popAudit,DataDos)
  useEffect(() => {
   
    if (flagIndi == "vob") {
      setAuditPop((prev) => ({
        ...prev,
        ReceivedVobClaim: DataDos.ReceivedVobClaim,
        callsvob: DataDos.callsvob,
        portals: DataDos.portals,
      }));
    }else if(flagIndi=="Payments"){
    

      setAuditPop((prev) => ({
        ...prev,
        Patients: DataDos.Patients,
        PatientsPar: DataDos.PatientsPar,
        insurance: DataDos.insurance,
        insurancePar: DataDos.insurancePar,
        Adjustments: DataDos.Adjustments,
        AdjustmentsPar: DataDos.AdjustmentsPar,
        DenialAmount:DataDos.DenialAmount,
        DenialAmountPar:DataDos.DenialAmountPar
      }));
    }else if(flagIndi=='PatState'){
    
      setAuditPop((prev) => ({
        ...prev,
        recievedAmountCC: DataDos.recievedAmountCC,
        recievedAmount: DataDos.recievedAmount,
        internalNonVerify: DataDos.internalNonVerify,
        AmountDosValCC: DataDos.AmountDosValCC,
        AmountDosVal: DataDos.AmountDosVal,
        pendingAmount: DataDos.pendingAmount,
        // DenialAmount:DataDos.DenialAmount,
        // DenialAmountPar:DataDos.DenialAmountPar
      }));
      


    } else if(flagIndi=='AccForm'){
      setAuditPop((prev) => ({
        ...prev,
        PayerType: DataDos.PayerType,
        payerlabel: DataDos.payerlabel,
        recievedAmountCC: DataDos.recievedAmountCC,
        recievedAmount: DataDos.recievedAmount,
        pending: DataDos.pending,
      
        // DenialAmount:DataDos.DenialAmount,
        // DenialAmountPar:DataDos.DenialAmountPar
      }));
     

    }else {
      setAuditPop((prev) => ({
        ...prev,
        ReceivedClaims: DataDos.ReceivedClaims,
        WorkedClaims: DataDos.WorkedClaims,
        PendingClaims: DataDos.PendingClaims,
        ReceivedAmount: DataDos.ReceivedAmount,
        WorkedAmount: DataDos.WorkedAmount,
        PendingAmount: DataDos.PendingAmount,
      }));
    }
  }, []);

  const onChangeValues = (event, arg) => {
    const { name } = arg.target;
    setAuditPop((prev) => ({ ...prev, [name]: event }));
  };

  const message = (
    <>
      {
        <Message showIcon type={"info"} closable>
          {`info`}: This Audit  is notified And verfied by user .
        </Message>
      }
    </>
  );

  let PaymentHtml=(<><div className="row">
    <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Patient Payment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="Patients"
                placeholder=""
                value={popAudit?.Patients}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Partially Patient Payment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="PatientsPar"
                placeholder=""
                value={popAudit?.PatientsPar}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Ins. Payment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="insurance"
                placeholder=""
                value={popAudit?.insurance}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Partially Ins.Payment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="insurancePar"
                placeholder=""
                value={popAudit?.insurancePar}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Adjustment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="Adjustments"
                placeholder=""
                value={popAudit?.Adjustments}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Partially Adjustment
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="AdjustmentsPar"
                placeholder=""
                value={popAudit?.AdjustmentsPar}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Denied Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="DenialAmount"
                placeholder=""
                value={popAudit?.DenialAmount}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Partially Denied Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="DenialAmountPar"
                placeholder=""
                value={popAudit?.DenialAmountPar}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-12">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Remarks
              </label>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={popAudit?.Remarks}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          
    </div></>)


let Acchtml=(<>
<div className="row">
<div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Recieved Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="recievedAmountCC"
                placeholder=""
                value={popAudit?.recievedAmountCC}
               
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Worked Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="recievedAmount"
                placeholder=""
                value={popAudit?.recievedAmount}
                
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Pending Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="pending"
                placeholder=""
                value={popAudit?.pending}
                
                onChange={onChangeValues}
              />
            </div>
          </div>

          <div class="col-sm-6 col-lg-12">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Remarks
              </label>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={popAudit?.Remarks}
                
                onChange={onChangeValues}
              />
            </div>
          </div>
</div>
</>)


let PatStatHtml=(<>
<div className="row">
<div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Received
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="recievedAmountCC"
                placeholder=""
                value={popAudit?.recievedAmountCC}
              
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Verified
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="recievedAmount"
                placeholder=""
                value={popAudit?.recievedAmount}
                
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Rejected
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="internalNonVerify"
                placeholder=""
                value={popAudit?.internalNonVerify}
               
                onChange={onChangeValues}
              />
            </div>
          </div>

          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Received Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="AmountDosValCC"
                placeholder=""
                value={popAudit?.AmountDosValCC}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>

          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Worked Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="AmountDosVal"
                placeholder=""
                value={popAudit?.AmountDosVal}
               
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
              Pending Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="pendingAmount"
                placeholder=""
                value={popAudit?.pendingAmount}
            
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-12">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Remarks
              </label>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={popAudit?.Remarks}
                onChange={onChangeValues}
                
              />
            </div>
          </div>

</div>
</>)

  console.log("state", state);
  return (
    <>
      {state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
        ? message
        : null}
      {flagIndi=='AccForm'?Acchtml:flagIndi=='PatState'?PatStatHtml:flagIndi=="Payments"?PaymentHtml:flagIndi == "vob" ? (
        <div className="row">
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Received
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="ReceivedVobClaim"
                placeholder=""
                value={popAudit?.ReceivedVobClaim}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Through Calls
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="callsvob"
                placeholder=""
                value={popAudit?.callsvob}
                onChange={onChangeValues}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Through Portals
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="portals"
                placeholder=""
                value={popAudit?.portals}
                onChange={onChangeValues}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
              />
            </div>
          </div>

          <div class="col-sm-6 col-lg-12">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Remarks
              </label>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={popAudit?.Remarks}
                onChange={onChangeValues}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes" ? true : false
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Received Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="ReceivedClaims"
                placeholder=""
                value={popAudit?.ReceivedClaims}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Worked Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="WorkedClaims"
                placeholder=""
                disabled={
                  state.holdOverAllExistingLogs &&
                  state.holdOverAllExistingLogs.length > 0 &&
                  state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                value={popAudit?.WorkedClaims}
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Pending Claims
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="PendingClaims"
                placeholder=""
                value={popAudit.PendingClaims}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Received Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="ReceivedAmount"
                placeholder=""
                value={popAudit.ReceivedAmount}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Worked Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="WorkedAmount"
                placeholder="WorkedAmount"
                value={popAudit.WorkedAmount}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Pending Amount
              </label>

              <Input
                class="form-control inputField"
                type="text"
                name="PendingAmount"
                placeholder=""
                value={popAudit.PendingAmount}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-12">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Remarks
              </label>

              <Input
                class="form-control inputField"
                as="textarea"
                name="Remarks"
                placeholder=""
                value={popAudit?.Remarks}
                disabled={
                  state.holdOverAllExistingLogs &&
      state.holdOverAllExistingLogs.length > 0 &&
      state.holdOverAllExistingLogs[0]?.verify == "Yes"
                    ? true
                    : false
                }
                onChange={onChangeValues}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
