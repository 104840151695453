import React, { useState, useEffect } from "react";
import { Panel } from "rsuite";
import {
  getBucketImages,
  insertFromType3AuthRecord,
  onSaveApi,
} from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Dateoofservice from "../../../Common/Dateoofservice";
import NotifySwal from "../../../Common/NotifySwal";
import { useDispatch } from "react-redux";
import FileUpload2 from "../../../Common/FileUpload2";
import {
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "./CommonDos";
import { handleCloseC } from "./CommonDos";
import { Schema } from "rsuite";
import { createValidationSchema } from "../../../Common/Validation";
import DateAndPractice from "./DateAndPractice";
import DateoofserviceGlobal from "../../../Common/DateofServiceGlobal";
import moment from "moment/moment";
import DateoofserviceBF from "./BFClaims";
import { AllConfirmationOnTab } from "../../../Actions/Actions";
import ViewDocument from "../../../Common/IframeModel";
function AuthorizationForm({
  setisMoveTab,
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  record,
  practiceId,
  formTypeNoForApi,
  date,
  onRecordUpdateOrCreated,
}) {
  let errorobj = {
    recievedAmount: false,
    AmountDosVal: false,
    date: false,
  };
  let breakupObj = {
    providerVal: "",
    codingVal: "",
    credientalingVal: "",
    operationVal: "",
    EdiVal: "",
    total: 0,
  };

  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");
  let objR = {
    filelist: [],
    
  };
  const [objectcluster, setobjCLuster] = useState(objR);

  const [FileList, setFileList] = useState([]);
  const [bfRow, setisThisbfRow] = useState(false);
  const [bfScreenArray, setBfScreenArray] = useState("");
  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);
  const [globalDos, openGlobalDos] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [pendingAmountDos, setpendingAmountDos] = useState(0);
  const [openScreenGlobalTable, setGlobalTable] = useState(false);

  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const { StringType, NumberType, DateType } = Schema.Types;
  const [errorModel, setErrorModel] = useState(errorobj);
  const [bfVal, setBfVal] = useState(0);
  const [bfamount, setBfamount] = useState(0.0);
  const [receivedClaims, setReceivedClaims] = useState(null);
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [workedClaims, setWorkedClaims] = useState(null);
  const [workedAmount, setWorkedAmount] = useState(null);
  const [pendingClaims, setPendingClaims] = useState(null);
  const [pendingAmount, setPendingAmount] = useState(null);
  const [AmountDosRec, setAmountDosRec] = useState("");
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [dosArrayRecieved, setDosArrayRec] = useState([]);

  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [comments, setComments] = useState(null);
  const [totalVal, setTotalVal] = useState(null);
  const [providerVal, setProviderVal] = useState(0);
  const [codingVal, setCodingVal] = useState(0);
  const [credientialVal, setCredientialVal] = useState(0);
  const [ediVal, setEdiVal] = useState(0);
  const [operationVal, setOperationVal] = useState(0);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);

  let footerObj = {
    isAllow: true,
    receivedClaims: 0,
    receivedAmount: 0,
    workedClaims: 0,
    workedAmount: 0,
    pendingClaims: 0,
  };
  const [footerState, setFooterState] = useState(footerObj);

  const [generalTextField, setGeneralTextField] = useState(null);
  const [insuranceRecieved, setInsuranceRecieved] = useState(null);
  const [actionToken, setActionToken] = useState(null);
  const [image, setImage] = useState("");
  const [docViewer, setDocViewer] = useState(false);
  const [dosModel, setDosModel] = useState(false);
  const [dosArray, setDosArray] = useState([]);
  const [renderEffect, setRenderEffect] = useState(false);
  const [handledosDate, setdosDate] = useState("");
  const [handleInputdos, sethandleInput] = useState("");
  const [AmountDos, setAmountDos] = useState("");
  const [openModelPending, setModelPending] = useState(false);
  const { onLoginSignOff } = useSelector((state) => state);
  const [rowID, setRowID] = useState(null);



  useEffect(() => {
   
    if(FileList.length>0){
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data
    
        const blob = new Blob([res.data], { type: el.mimetype });
        var file = new File([blob],  el.originalname);
      // const uint8Array = new Uint8Array(res.data);
      //  const blob = new Blob([uint8Array], { type: el.mimetype });
      //  blob.name = el.originalname;
      //  blob.lastModified = el.lastModified;
         
   
          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original:el.originalname
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
       
       setImage(updatedArr)
       
      // setResult((prev)=>({...prev,image:updatedArr}))
      //  setobjCLuster((prev)=>({
      //   ...prev,
      //   filelist:updatedArr

      // }))
     
      })
  
  
      
    }
   
  }, [FileList])



  useEffect(() => {
    const {
      providerVal,
      codingVal,
      credientalingVal,
      operationVal,
      EdiVal,
      total,
    } = AllBreakupVal;

    if (
      providerVal ||
      codingVal ||
      credientalingVal ||
      EdiVal ||
      operationVal
    ) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(EdiVal) || 0) +
        (parseFloat(credientalingVal) || 0) +
        (parseFloat(operationVal) || 0) +
        (parseFloat(codingVal) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
  }, [
    AllBreakupVal.providerVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.EdiVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.credientalingVal,
    AllBreakupVal.operationVal,
  ]);
  useEffect(() => {
    if (parseFloat(pendingAmountDos) < 0) {
      toast.info(
        "Pending Amount value must be a positive number. Please verify your calculations."
      );
    }
  }, [pendingAmountDos]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (globalDos) {
      dispatch(AllConfirmationOnTab(true));
    } else {
      dispatch(AllConfirmationOnTab(false));
    }
  }, [globalDos]);

  useEffect(() => {
    if (record) {
      handleChargeFieldsOnGetRecord(record);
    }
    if (practiceId == "0") {
      setDescription("");
      setActionToken("");
      setName("");
    }
  }, [record, practiceId]);
  let helpingObj = {
    isSpeakToaster: false,
    loadingn: false,
    isSpeakerToaster: false,
    isBackSpeak: false,
    AllFiles: null,
    renderEffectForUpload:false,
    getSelectedRowDocument:null,
    updatedblodState:[],
    updatedblob:[],
    isFileViewerOpen: null,
    isAllStatesAreSet:false
  };
  const [helpingState, sethelpingState] = useState(helpingObj);

  useEffect(() => {
   
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }

    let countAmountdos = 0;
    let countAmountdosCC = 0;
    let recievedAmountCC = 0;
    let pendingstat = 0;

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el?.recievedAmount); //recieved Claims
        countAmountdos += parseFloat(el?.AmountDosVal); //Received Amount
        countAmountdosCC += parseFloat(el?.AmountDosValCC); //Worked AMount
        recievedAmountCC += parseFloat(el?.recievedAmountCC); //Worked Clai9ms
        pendingstat +=
          parseFloat(el?.recievedAmount) - parseFloat(el?.recievedAmountCC); //pending Claims
      });
    setFooterState((prev) => ({
      ...prev,
      receivedClaims: count,
      receivedAmount: countAmountdos,
      workedClaims: recievedAmountCC,
      workedAmount: countAmountdosCC,
      pendingClaims: pendingstat,
    }));
  }, [dosArrayRecieved]);

  useEffect(() => {
    if (practiceId) {
      onClear();
    }
  }, [practiceId]);

  useEffect(() => {
    if (handleInputdosRec && handleInputdosRec && handleInputdos) {
      setisMoveTab((prev) => ({ ...prev, isTabMove: true, flag: "Auth" }));
    } else {
      setisMoveTab((prev) => ({ ...prev, isTabMove: false, flag: null }));
    }
  }, [handleInputdosRec, handleInputdosRec, handleInputdos]);

  useEffect(() => {
    if (date) {
      onClear();
    }
  }, [date]);

  const renderr = (val) => {
    setRenderEffect(val);
  };

  const handleTaosterSpeak = () => {
    if(dosArrayRecieved && dosArrayRecieved.length==0){
      toast.info('Please Enter Date of Service Wise Record')
      return
     }
    sethelpingState((prev) => ({ ...prev, isSpeakerToaster: true }));
  };

  useEffect(() => {
    
    if (receivedClaims && workedClaims) {
      setPendingClaims(receivedClaims - workedClaims);
    } else {
      setPendingClaims(0);
    }
  }, [receivedClaims, workedClaims]);

 

  useEffect(() => {
    if (providerVal && codingVal && credientialVal && ediVal && operationVal) {
      setTotalVal(
        parseFloat(providerVal) +
          parseFloat(ediVal) +
          parseFloat(credientialVal) +
          parseFloat(codingVal) +
          parseFloat(operationVal),
        10
      );
    } else {
      setTotalVal(0);
    }
  }, [providerVal, codingVal, credientialVal, ediVal, operationVal]);

  useEffect(() => {
   
    console.log('This ececutes')
    if (receivedAmount && workedAmount) {
      setPendingAmount(receivedAmount - workedAmount);
    } else {
      setPendingAmount(0);
    }
  }, [receivedAmount, workedAmount]);

  const onChangeAmount = (val) => {
    const { value, name } = val.target;
    setAmountDos(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosVal: false,
    }));
  };

  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;
    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClosePopPending = () => {
    setNameGeneric("Authorization");
    setModelPending(false);
  };

  const onChangeValues = (event) => {
    const { name, value } = event.target;
    if (name == "workedClaims") {
      setWorkedClaims(value);
      return;
    }
    if (name == "workedAmount") {
      setWorkedAmount(value);
      return;
    }

    if (name == "receivedClaims") {
      setReceivedClaims(value);
      return;
    }
    if (name == "receivedAmount") {
      setReceivedAmount(value);
      return;
    }
    if (name == "pendingClaims") {
      setPendingClaims(value);
      return;
    }
    if (name == "pendingAmount") {
      setPendingAmount(value);
      return;
    }
    if (name == "totalVal") {
      setTotalVal(parseFloat(value, 10));
      return;
    }
    if (name == "providerVal") {
      setProviderVal(parseFloat(value, 10));
      return;
    }
    if (name == "codingVal") {
      setCodingVal(parseFloat(value, 10));
      return;
    }
    if (name == "credientialVal") {
      setCredientialVal(parseFloat(value, 10));
      return;
    }
    if (name == "ediVal") {
      setEdiVal(parseFloat(value, 10));
      return;
    }
    if (name == "operationVal") {
      setOperationVal(parseFloat(value, 10));
      return;
    }
    if (name == "generalTextField") {
      setGeneralTextField(value);
      return;
    }
    if (name == "insuranceRecieved") {
      setInsuranceRecieved(value);
      return;
    }
    if (name == "takenYes") {
      setActionToken(value);
      return;
    }
    if (name == "takenNo") {
      setActionToken(value);
      return;
    }
    if (name == "comments") {
      setComments(value);
      return;
    }
  };
  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }
    if (arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray =
    parseArr &&  parseArr.length > 0 && parseArr.filter((item) => item?.bfVal !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        recievedAmount: item?.bfVal?.toString(),
        AmountDosVal: item?.bfAmount?.toString(),
        recievedAmountCC: "0",
        AmountDosValCC: "0",
        providerVal: "0",
        codingVal: 0,
        credientalingVal: 0,
        operationVal: 0,
        EdiVal: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };

  // const transformArray = (arr) => {
  //   if (arr.length == 0) {
  //     return [];
  //   }

  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);
  //   } catch (error) {
  //     parseArr = arr;
  //   }

  //   const newArray =
  //     parseArr.length > 0 &&
  //     parseArr.map((item) => ({
  //       ...item,

  //       recievedAmount: item?.bfVal?.toString(),
  //       AmountDosVal: item?.bfAmount?.toString(),
  //       recievedAmountCC: "0",
  //       AmountDosValCC: "0",
  //       providerVal: "0",
  //       codingVal: 0,
  //       credientalingVal: 0,
  //       operationVal: 0,
  //       EdiVal: 0,
  //       total: 0,
  //       bfRow: true,
  //     }));

  //   return newArray;
  // };
  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem =
      arr1 &&
      arr2 &&
      arr2.length > 0 &&
      arr1.length > 0 &&
      arr1.find((item1) =>
        arr2.some(
          (item2) =>
            item1.date === item2.date &&
            item1.recievedAmount === item2.recievedAmount
        )
      );

    return !matchingItem;
  };

  const onPreview = (val, row) => {
    let obj = { bucketImages: val };
    sethelpingState((prev) => ({ ...prev, getSelectedRowDocument: obj }));
  
    sethelpingState((prev) => ({
      ...prev,
     
      isFileViewerOpen: true,
    }));
    
  };
  const handleChargeFieldsOnGetRecord = (record) => {
    if (record?.rcount == "0") {
      setFileList([])
      setBfVal(record.bfvalprv);
      setBfamount(record.bfprv);
      setBfScreenArray(record?.dosArrayRecieved);
      const newArray = transformArray(record?.dosArrayRecieved);
      setDosArrayRec(newArray);
    } else {
      let parsedArray;
      let passedArr;
      let passedArr2;

      try {
        parsedArray = JSON.parse(record.result[0]?.dosArrayRecieved);
        passedArr = JSON.parse(record.result[0]?.breakupval);
        passedArr2 = JSON.parse(record.result[0]?.dosArray);
        console.log("The string contains a valid JSON object.");
      } catch (error) {
        parsedArray = record.result[0]?.dosArrayRecieved;
        passedArr = record.result[0]?.breakupval;
        passedArr2 = record.result[0]?.dosArray;
      }
      let obj = passedArr;

      let obj1 = record.result[0];
        let parArr = [];
        try {
          parArr = JSON.parse(obj1.image);
        } catch {
          parArr = obj1.image;
        }
        sethelpingState((prev)=>({...prev,bucketImages:obj1.image,updatedblodState:parArr}))
        setImage(parArr);

      //  sethelpingState((prev) => ({ ...prev, bucketImages: obj.image,updatedblodState:parArr }));
       // setResult((prev)=>({...prev,image:parArr}))//image
        setFileList(parArr)//file

      setReceivedClaims(record.result[0]?.recievedval);
      setWorkedClaims(record.result[0]?.workedval);
      setWorkedAmount(record.result[0]?.workedamount);
      setPendingClaims(record.result[0]?.pendingval);
      setPendingAmount(record.result[0]?.pendingamountval);
      setTotalVal(record.result[0]?.totalval);
      setReceivedAmount(record.result[0]?.recievedamount);
      setProviderVal(obj?.providerval);
      setCodingVal(obj?.codingval);
      setCredientialVal(obj?.credval);
      setEdiVal(obj?.edival);
      setOperationVal(obj?.operval);
      setName(record.result[0]?.GeneralOpen);
      setDescription(record.result[0]?.insurance);
      setActionToken(record.result[0]?.stoken);
      setGeneralTextField(record.result[0]?.GeneralOpen);
      setInsuranceRecieved(record.result[0]?.insurance);
      
      setBfVal(record.bfvalprv);
      setBfamount(record.result[0]?.bfamount);
      setComments(record.result[0]?.comments);
      setDosArray(passedArr2);
     
      const newArray = transformArray(record?.dosArrayRecieved);
      const result = checkArraysHaveSameDateAndAmount(newArray, parsedArray);

      let combineArray;
      if (result == false) {
        combineArray = parsedArray;
      } else {                   
        if(newArray==false){
          combineArray= [].concat(parsedArray)
      
        }else{
          combineArray= newArray.concat(parsedArray)
      
      
        }
        
       
      }
      setDosArrayRec(combineArray);
      setBfScreenArray(record?.dosArrayRecieved);
    }
  };
  const openDosmodel = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Worked Claims");
    setDosModel(true);
  };
  const onSaveDos = () => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArray;
    }
    let countAmountdos = 0;

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount);
        countAmountdos += parseFloat(el.AmountDosVal);
      });
    setisPendingSectionOpen(false);
    setNameGeneric("Authorization");
    setAmountDos(null);
    sethandleInputRec(null);
    setdosDate("");
    setBreakupVal(breakupObj);
    setWorkedClaims(count);
    setWorkedAmount(countAmountdos);
    setDosModel(false);
  };
  const handleEditState = async (rowData) => {
    if (!rowData.hasOwnProperty("status")) {
      return;
    }
    const nextData = Object.assign([], dosArray);
    const activeItem = nextData.find((item) => item.id === rowData.id);
    console.log(activeItem.status, "bug here", nextData);
    activeItem.status = activeItem.status ? null : "EDIT";
    setDosArray(nextData);

    // if(rowData.status=='Save'){
    //   let obj={
    //     date:rowData.date,
    //     recievedAmount:rowData.recievedAmount,
    //     status:'EDIT'
    //   }
    //   setDosArray(prevDosArray => [...prevDosArray, obj]);

    // }

    // const nextData = Object.assign([], data);
    // const activeItem = nextData.find((item) => item.id === rowData.id);
    // activeItem.status = rowData.status === 'Save' ? 'EDIT' : null;
    // setData(nextData);
  };

  let nextData;
  const handleChange = (id, key, value, event) => {
    event.persist();

    nextData = Object.assign([], dosArray);
    nextData.find((item) => item.id === id)[key] = value;
    setDosArray(nextData);
  };
  const styles = {
    marginBottom: 10,
  };
  const CustomInputGroupWidthButton = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={styles}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );

  const onHandlePendingClaimss = (val) => {
    if (parseFloat(val) < 0) {
      toast.info(
        "Pending Claims value must be a positive number. Please verify your calculations."
      );
    }
    setPendingClaimss(val);
  };

  const onChangeInput = (val) => {
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }));
  };
  const onChangeDate = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;

    let result = dosArray.some((el) => el.date === formattedDate);
    if (result) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,
    }));
  };
  const onAdddos = async () => {
    let result = dosArray.some((el) => el.date === handledosDate);
    if (result) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    if (parseFloat(pendingClaimss) != parseFloat(AllBreakupVal.total)) {
      toast.info(
        "Total Pending breakup value should be equal to pending Value "
      );
      return;
    }
    let obj = {
      date: handledosDate,
      recievedAmount: handleInputdos,
      AmountDosVal: AmountDos,
      status: null,
      id: dosArray.length + 1,
      providerVal: AllBreakupVal.providerVal,
      codingVal: AllBreakupVal.codingVal,
      credientalingVal: AllBreakupVal.credientalingVal,
      operationVal: AllBreakupVal.operationVal,
      EdiVal: AllBreakupVal.EdiVal,
      total: AllBreakupVal.total,
    };

    const validationFields = [
      {
        fieldName: "recievedAmount",
        rules: { requiredMessage: "Received amount is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "AmountDosVal",
        rules: { requiredMessage: "AmountDosVal is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "date",
        rules: { requiredMessage: "Date is required." },
        type: { typeError: DateType() },
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);
    if (errors.AmountDosVal.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        AmountDosVal: true,
      }));
    }
    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
      }));
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
      }));
    }
    if (
      !errors.recievedAmount.hasError &&
      !errors.AmountDosVal.hasError &&
      !errors.date.hasError
    ) {
      setDosArray((prevDosArray) => [...prevDosArray, obj]);
    }
  };

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };
  const handleClose = () => {
    openGlobalTable(false);

    setisPendingSectionOpen(false);
    sethandleInput(null);
    setAmountDos(null);

    setBreakupVal(breakupObj);
    setDosModel(false);
    sethandleInput(null);
    setAmountDos(null);
    setNameGeneric("Authorization");

    setdosDate("");
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
      AmountDosVal: false,
      date: false,
    }));
  };

  const onSaveNode = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    }
    sethelpingState((prev) => ({ ...prev, loadingn: true }));
    const breakupval = `{
      "providerval": ${providerVal},
      "codingval": ${codingVal},
      "credval": ${credientialVal},
      "edival": ${ediVal},
      "operval": ${operationVal}
    }`;

    if(dosArrayRecieved.length==0){
      toast.info('For Save! Please Add Some Dos Wise Record')
      sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
      return

    }

    const formDataForSaveApi = new FormData();
    if (image && image.length > 0 && image != "[]") {
      image.forEach((el, index) => {
        formDataForSaveApi.append(`file`, el?.blobFile);
      });
    } else {
      formDataForSaveApi.append(`file`, null);
    }

    formDataForSaveApi.append("userid", onLoginSignOff.userid);
    formDataForSaveApi.append("sdate", date);
    formDataForSaveApi.append("pid", practiceId);
    formDataForSaveApi.append("formtype", formTypeNoForApi);
    formDataForSaveApi.append("submittype", "No");
    formDataForSaveApi.append("bfval", bfVal);
    formDataForSaveApi.append("bfamount", bfamount);
    formDataForSaveApi.append("recievedval", receivedClaims);
    formDataForSaveApi.append("recievedamount", receivedAmount);
    formDataForSaveApi.append("workedval", workedClaims);
    formDataForSaveApi.append("workedamount", workedAmount);
    formDataForSaveApi.append("breakupval", breakupval);
    formDataForSaveApi.append("pendingval", pendingClaims);
    formDataForSaveApi.append("pendingamountval", pendingAmount);
    formDataForSaveApi.append("totalval", totalVal);
    formDataForSaveApi.append("GeneralOpen", generalTextField);
    formDataForSaveApi.append("insurance", insuranceRecieved);
    formDataForSaveApi.append("stoken", actionToken);
    formDataForSaveApi.append("comments", comments);
    formDataForSaveApi.append("dosArray", JSON.stringify(dosArray));
    formDataForSaveApi.append(
      "dosArrayRecieved",
      JSON.stringify(dosArrayRecieved)
    );

    insertFromType3AuthRecord(formDataForSaveApi)
      .then((result) => {
     
        if (
          result?.data &&
          result?.data.message == "Record Updated SuccessFully"
        ) {
          toast.info(result?.data.message);
          onClear();
          onRecordUpdateOrCreated("Auth");
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
        }
        if (
          result?.data &&
          result?.data.message == "Record Created SuccessFully"
        ) {
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
          toast.info(result?.data.message);
          onClear();
          onRecordUpdateOrCreated("Auth");
        }
        sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
      
        onRecordUpdateOrCreated("Auth");
      })
      .catch((err) => {
        if (
          err?.response?.data &&
          err?.response?.data?.message ==
            "Breakup should be equal to pending value!"
        ) {
          toast.info(err?.response?.data?.message);
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
        }
      });
  };

  const handleInputChange = (event) => {
    const selectedImage = event;

    setImage(selectedImage);

    setFileList(selectedImage);
  };

  const openDosmodelPending = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Pending Claims");

    setModelPending(true);
  };

  const openImageViwerModel = (val) => {
    setDocViewer(val);
    sethelpingState((prev) => ({ ...prev, isFileViewerOpen: val }));
  };

  useEffect(() => {
    let paresedArray2;
    try {
      paresedArray2 = JSON.parse(dosArray);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArray;
    }
    if (paresedArray2 && paresedArray2.length > 0) {
      const sumProperty = (property) =>
        paresedArray2.reduce(
          (sum, obj) => sum + (parseFloat(obj[property]) || 0),
          0
        );
      setProviderVal(sumProperty("providerVal"));
      setCodingVal(sumProperty("codingVal"));
      setCredientialVal(sumProperty("credientalingVal"));
      setEdiVal(sumProperty("EdiVal"));
      setOperationVal(sumProperty("operationVal"));
    }
  }, [dosArray]);

  const onClear = () => {
    setReceivedClaims("");
    setReceivedAmount("");
    setWorkedClaims("");
    setWorkedAmount("");
    setPendingClaims(null);
    setPendingAmount(null);
    setTotalVal(null);
    setProviderVal(0);
    setCodingVal(0);
    setCredientialVal(0);
    setEdiVal(0);
    setOperationVal(0);
    setGeneralTextField("");
    setInsuranceRecieved("");
    setActionToken(null);
    setImage("");
    setDosArray([]);
    setDosArrayRec([]);
  };

  let totalValue =
    parseFloat(providerVal) +
    parseFloat(ediVal) +
    parseFloat(credientialVal) +
    parseFloat(codingVal) +
    parseFloat(operationVal);

  console.log("generalTextField", generalTextField);

  //-------------------------
  const openDosmodelWorked = () => {
    setDosModelRec(true);
    setNameGeneric("Recieved Claims");
  };
  const handleCommonEdit = async (rowData) => {
    //   if(!rowData.hasOwnProperty("status"))
    // {
    //   return

    // }
    //   handleEditStateC(rowData, dosArrayRecieved, setDosArrayRec);

    if (rowData.AmountDosVal) {
      if (rowData.bfRow == true) {
        setisThisbfRow(true);
      } else {
        setisThisbfRow(false);
      }

      let obj = {
        isEdit: true,
        rowDataa: rowData,
      };
      setGridEdit(obj);

      sethandleInputRec(rowData.recievedAmount);
      setAmountDosRec(rowData.AmountDosVal);
      setRowID(rowData.id);
      sethandleInput(rowData.recievedAmountCC);
      setAmountDos(rowData.AmountDosValCC);
      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      setBreakupVal((prev) => ({
        ...prev,
        providerVal: rowData.providerVal,
        codingVal: rowData.codingVal,
        credientalingVal: rowData.credientalingVal,
        operationVal: rowData.operationVal,
        EdiVal: rowData.EdiVal,
        total: rowData.total,
      }));
    } else {
      return;
    }
  };
  const handleChangeCommondos = (id, key, value, event) => {
    handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
  };
  const onSaveDosCo = () => {
    if (
      handleInputdosRec == "" &&
      AmountDosRec == "" &&
      handleInputdos == "" &&
      AmountDos == "" &&
      AllBreakupVal?.providerVal == "" &&
      AllBreakupVal?.codingVal == "" &&
      AllBreakupVal?.credientalingVal == "" &&
      AllBreakupVal?.EdiVal == "" &&
      AllBreakupVal?.operationVal == "" &&
      (dosArrayRecieved.length == 0 || dosArrayRecieved.length < 0)
    ) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setpendingAmountDos(0);
      setNameGeneric("Authorization");
      onSaveDosC(
        false,
        dosArrayRecieved,
        setReceivedClaims,
        setDosModelRec,
        setReceivedAmount,
        null,
        null,
        null,
        setWorkedAmount,
        setWorkedClaims,
        null
      );
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
      return;
    } else {
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: true }));
    }
  };
  const onChangeInputCC = (val) => {
    onChangeInputC(val, sethandleInputRec, setErrorModel);
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayRecieved);
  };
  const onChangeDAmountCC = (val) => {
    onChangeAmountC(val, setAmountDosRec, setErrorModel);
  };
  const handleCloseCC = () => {
    sethelpingState((prev) => ({ isBackSpeak: true }));
  };
  const onHandleCloseCall = () => {
    if (gridEdit.isEdit) {
      onAdddosCC();
    }
    callGlobalDos(false);
    setBreakupVal(breakupObj);
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    setpendingAmountDos(0);
    callGlobalDos(false);
    setNameGeneric("Authorization");
    handleCloseC(
      setDosModelRec,
      setErrorModel,
      sethandleInputRec,
      setAmountDosRec,
      setdosDateRec,
      sethandleInput,
      setAmountDos,
      setPendingClaimss
    );
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };
  const onAdddosCC = () => {
    if (parseFloat(pendingClaimss) != parseFloat(AllBreakupVal.total)) {
      toast.info(
        "Total Pending breakup value should be equal to pending Value "
      );
      return;
    }
    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    sethandleInputRec("");
    setAmountDosRec("");
    sethandleInput("");
    setAmountDos("");
    setPendingClaimss(0);
    setpendingAmountDos(0);
    setdosDateRec("");
    setisThisbfRow(false);
    setBreakupVal(breakupObj);
    onAdddosC(
      handledosDateRec,
      handleInputdosRec,
      dosArrayRecieved,
      AmountDosRec,
      setDosArrayRec,
      setErrorModel,
      null,
      null,
      AllBreakupVal,
      null,
      handleInputdos,
      AmountDos,
      setDosArray,
      null,
      gridEdit,
      rowID,
      bfRow,
      bfRow
    );
  };
  const onCleanDateSimple = () => {
    setdosDate("");
  };
  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };

  const [openBf, setBf] = useState(false);
  const openBfScreen = () => {
    setBf(true);
  };
  const handleCloseBfScreen = () => {
    setBf(false);
  };
  const handleKeyPress = (e) => {
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (
      charCode === 8 ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode != 190 && charCode != 110)
    ) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };

  useEffect(() => {
    if(helpingState?.isAllStatesAreSet){
     
      onSaveNode()
  
    }
   
  
   
  }, [helpingState?.isAllStatesAreSet])
  const handleSwalConfirm = (val) => {
    if (val==true) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setpendingAmountDos(0);
      setNameGeneric("Authorization");
      onSaveDosC(
        false,
        dosArrayRecieved,
        setReceivedClaims,
        setDosModelRec,
        setReceivedAmount,
        null,
        null,
        null,
        setWorkedAmount,
        setWorkedClaims,
        null
      );
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false ,isAllStatesAreSet:true}));


    } else if(!val) {
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
     
      
    }
    else if(val=='backToMainScreen'){
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
      setBreakupVal(breakupObj);

      sethandleInputRec("");
         setAmountDosRec("");
         sethandleInput("");
         setAmountDos("");
         setPendingClaimss(0);
         setpendingAmountDos(0);
         setdosDateRec("");
         callGlobalDos(false);
     

    }
  };

  const handleSwalConfirm2 = (val) => {
    if (val==true) {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
      onSaveNode();
    } else if(!val) {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
      sethandleInputRec("");
    setAmountDosRec("");
    sethandleInput("");
    setAmountDos("");
    setPendingClaimss(0);
    setpendingAmountDos(0);
    setdosDateRec("");
    callGlobalDos(false);

    }
    else if(val=='backToMainScreen'){
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));

    }
  };
  const handleSwalConfirmBackButton = (val) => {
    if (val) {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
      onHandleCloseCall();
    } else {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
    }
  };

  const onRemove = (val) => {
    let remainingObjects = FileList.filter(
      (obj) => obj.fileKey !== val.fileKey
    );
    setFileList(remainingObjects);
    // setResult((prevResult) => ({ ...prevResult, image: remainingObjects }));
    setImage(remainingObjects);
  };

  return (
    <>
      {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      {helpingState.isSpeakToaster ? (
        <NotifySwal
        showDenyButton={true}
       

          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
        />
      ) : null}
      {helpingState.isSpeakerToaster ? (
        <NotifySwal
        showDenyButton={true}
       
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm2}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {helpingState.isBackSpeak ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmBackButton}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {openBf == true ? (
        <>
          <DateoofserviceBF
            handleClose={handleCloseBfScreen}
            dosArray={bfScreenArray}
          />
        </>
      ) : openScreenGlobalTable == true ? (
        <Dateoofservice
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={dosArrayRecieved}
          handleClose={handleClose}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"charge"}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal
          handleKeyPress={handleKeyPress}
          recentChange={"Auth"}
          Footer={footerState}
          flagFooter={"charge"}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
          AmountDos={AmountDos}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={setpendingAmountDos}
          pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}
          onChangeInputCC={onChangeInputCC}
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeAmount}
          onChangeAmountCC={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          claimHeading1={"Received Claims"}
          claimAmount1={"Received Amount"}
          practiceId={practiceId}
          onlyShowTable={false}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          recievedClaimArraydos={[]}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          setDosArray={{ state: setDosArray, flag: "1" }}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Received Amount"}
        />
      ) : openModelPending ? (
        <Dateoofservice
          onlyShowTable={true}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={dosArray}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
          showTableCheck={"charge"}
        />
      ) : (
        <>
          {/* <DocViewer
            docViewer={docViewer}
            image={image}
            openImageViwerModel={openImageViwerModel}
          /> */}
          {helpingState?.isFileViewerOpen == true && (
            // <DocViewer
            //   docViewer={true}
            //   image={helpingState?.base64}
            //   openImageViwerModel={openImageViwerModel}
            // />
            <ViewDocument
              seletedRow={helpingState?.getSelectedRowDocument}
              openImageViwerModel={openImageViwerModel}
            />
          )}
          <div class="row">
            <DateAndPractice
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={products}
              date={date}
            />
            <Panel defaultExpanded={true} header="Authorization" collapsible>
              <div className="row mt-4">
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label
                      class="inputLabel"
                      style={{ color: "black" }}
                      onClick={openBfScreen}
                    >
                      B/F Claims{" "}
                      {/* <span>
                    <AddOutlineIcon style={{ color: "#0e7490" }} />
                  </span> */}
                    </label>
                    <input
                      class="form-control inputField"
                      type="text"
                      name="bfVal"
                      placeholder={""}
                      value={bfVal}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Received Claims
                    </label>

                    <input
                      class="form-control inputField"
                      type=""
                      name="receivedClaims"
                      placeholder=""
                      value={receivedClaims}
                      onChange={onChangeValues}
                      disabled
                    />
                    {/* <CustomInputGroupWidthButtonC
              value={receivedClaims}
              disabled={true}
              size="md"
              onClick={openDosmodelWorked}
              placeholder="Medium"
            /> */}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Worked Claims
                    </label>

                    <input
                      class="form-control inputField"
                      type=""
                      name="workedClaims"
                      placeholder=""
                      value={receivedClaims}
                      onChange={onChangeValues}
                      disabled
                    />
                    {/* <CustomInputGroupWidthButton
              value={workedClaims}
              disabled={true}
              size="md"
              onClick={openDosmodel}
              placeholder="Medium"
            /> */}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Pending Claims
                    </label>

                    <input
                      class="form-control inputField"
                      type="text"
                      name="pendingClaims"
                      placeholder=""
                      value={
                        receivedClaims && workedClaims
                          ? receivedClaims - workedClaims
                          : 0
                      }
                      disabled
                    />
                    {/* <CustomInputGroupWidthButton
                  value={
                    receivedClaims && workedClaims
                      ? receivedClaims - workedClaims
                      : 0
                  }
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      B/F Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="bfamount"
                        placeholder="0.00"
                        value={bfamount}
                        disabled
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Received Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="receivedAmount"
                        placeholder=""
                        value={receivedAmount}
                        disabled
                        onChange={onChangeValues}
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Worked Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="workedAmount"
                        placeholder=""
                        value={workedAmount}
                        disabled
                        onChange={onChangeValues}
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Pending Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="pendingAmount"
                        placeholder=""
                        value={
                          receivedAmount && workedAmount
                            ? parseFloat((receivedAmount - workedAmount).toFixed(3))
                            : 0
                        }
                        
                        onChange={onChangeValues}
                        disabled
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mb-2 text-center"></div>
              </div>
            </Panel>

            <div class="col-sm-6 col-lg-3 mt-4"></div>
            <div class="col-sm-6 col-lg-3"></div>
            <div class="col-sm-6 col-lg-3"></div>
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Pending Claims Breakup
              </h4>
            </div> */}
             <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >
              <div className="row mt-4">

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Provider
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="providerVal"
                  placeholder=""
                  value={providerVal}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Coding
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="codingVal"
                  placeholder=""
                  value={codingVal}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Credentialing
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientialVal"
                  placeholder=""
                  value={credientialVal}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  EDI
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="ediVal"
                  placeholder=""
                  value={ediVal}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Operation
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="operationVal"
                  placeholder=""
                  value={operationVal}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="totalVal"
                  placeholder=""
                  value={totalValue}
                  disabled
                  onChange={onChangeValues}
                />
              </div>
            </div>
            </div>
            </Panel>
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div> */}
            <div className="mt-4"></div>
             <Panel defaultExpanded={true} header="Remarks" collapsible={true} >
            

            <div class="col-sm-12 col-lg-12 mt-4">
              <div class="form-group mb-2">
                {/* <label class="inputLabel" style={{ color: "black" }}>
            Other Task
            </label> */}
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  name="generalTextField"
                  placeholder="Enter Remarks Here"
                  class="form-control inputField"
                  value={generalTextField}
                  onChange={onChangeValues}
                ></textarea>

                {/* <input
              class="form-control inputField"
              type="text"
              name="generalTextField"
              placeholder=""
              value={generalTextField}
              onChange={onChangeValues}
            /> */}
              </div>
            </div>
            </Panel>
            {/* <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Insurance Received
            </label>

            <input
              class="form-control inputField"
              type="text"
              name="insuranceRecieved"
              placeholder=""
              value={insuranceRecieved}
              onChange={onChangeValues}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 d-flex ">
          <div class="form-group mb-2 ">
            <label class="inputLabel" style={{ color: "black" }}>
              Action Taken{" "}
            </label>
            <div class="row ml-1">
              <div
                class="form-check form-check-inline "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                <input
                  class="form-check-input mr-2  mb-2"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="Yes"
                  name="takenYes"
                  onChange={onChangeValues}
                  checked={actionToken=='Yes'?true:false}
                />
                <label class="mr-4" for="inlineCheckbox1">
                  Yes{" "}
                </label>
                <input
                  class="form-check-input mr-2  mb-2"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="No"
                  name="takenNo"
                  onChange={onChangeValues}
                  checked={actionToken=='No'?true:false}
                />
                <label class="mr-4" for="inlineCheckbox2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        {actionToken == "Yes" ?<div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
             Remarks
            </label>

            <input
                class="form-control inputField"
                type="text"
                name="comments"
                placeholder=""
                 onChange={onChangeValues}
                  value={comments}
              />
          </div>
        </div>:null} */}
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Attachments
              </h4>
            </div> */}
            {/* <div className="col-lg-12 mb-2 text-center">
          <h4 class="inputLabel " style={{ color: "black" }}>
            Attachments
          </h4>
        </div> */}
        <div className="mt-4"></div>
            <div class="col-sm-12 col-lg-12">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label> */}
                {/* <ImageUploaderr
                  openImageViwerModel={openImageViwerModel}
                  image={image}
                  handleInputChange={handleInputChange}
                /> */}
                 
                <FileUpload2
                  onRemove={onRemove}
                  setobjCLuster={setobjCLuster}
                  objectcluster={objectcluster}
                  helpingState={helpingState}
                  onPreview={onPreview}
                  fileList={FileList}
                  openImageViwerModel={openImageViwerModel}
                  handleInputChange={handleInputChange}
                  image={image}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 "></div>

            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 mt-2">
              <div class="form-group ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    // style={{
                    //   backgroundColor: "white",
                    //   color: "#40E0D0",
                    //   borderRadius: "10px",
                    //   fontSize:18,
                    //   height: "3rem",
                    //   width: "6rem",
                    //   border: "2px solid ",
                    // }}
                    className="btnClear mr-1 "
                    onClick={onClear}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="btnSave"
                    onClick={handleTaosterSpeak}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default AuthorizationForm;
