import { Nav } from "rsuite";
import React from "react";

import IdInfoIcon from "@rsuite/icons/IdInfo";
import { useState } from "react";

import DocPassIcon from "@rsuite/icons/DocPass";
import { useSelector } from "react-redux";


// const navItems = [
//   { eventKey: "userWise", label: "User Productivity", icon: <IdInfoIcon /> },
//   // { eventKey: "overAll", label: "OverAll Productivity", icon: <DocPassIcon /> },
//       { eventKey: "Agging", label: "Agging", icon: <DocPassIcon /> },
//       { eventKey: "Threshold", label: "Threshold", icon: <DocPassIcon /> },
// ];


const Navbar = ({ active, onSelect, handleFormType, ...props }) => {
  const { PermissionReducers } = useSelector((state) => state);

  // const navItems = [
  //   ...PermissionReducers.map(item => {
  //        switch (item.name) {
  //            case "Reports - User Wise Productivity":
  //                return  { eventKey: "userWise", label: "User Productivity", icon: <IdInfoIcon /> };
  //            case "Reports - Agging":
  //                return  { eventKey: "Agging", label: "Agging", icon: <DocPassIcon /> };
  //            case "Reports - Threshold":
  //                return { eventKey: "Threshold", label: "Threshold", icon: <DocPassIcon /> };
                 
                
            
  //            default:
  //                return null;
  //        }
  //    }).filter(Boolean),
   
  //  ];
  const navItems = [
    { eventKey: "userWise", label: "User Productivity", icon: <IdInfoIcon /> },
    // { eventKey: "overAll", label: "OverAll Productivity", icon: <DocPassIcon /> },
        { eventKey: "Agging", label: "Aging", icon: <DocPassIcon /> },
        { eventKey: "Threshold", label: "Practice Threshold", icon: <DocPassIcon /> },
  ];
  const isActive = (eventKey) => active === eventKey;
  const activeStyle = { backgroundColor: "#0e7490", color: "white" };
  const subactiveStyle = { backgroundColor: "#0e7490", color: "white" };
  console.log("Active,", active);

  return (
    <>
      <div className="nav-container rs-nav-item rs-nav-item-active">
        <Nav
          {...props}
          activeKey={active}
          onSelect={onSelect}
          appearance="subtle"
        >
          {navItems.map(({ eventKey, label, icon }) => (
            <Nav.Item
              key={eventKey}
              eventKey={eventKey}
              icon={icon}
              style={isActive(eventKey) ? activeStyle : {}}
              onSelect={handleFormType}
            >
              <span style={{ fontWeight: "normal" }}>{label}</span>
            </Nav.Item>
          ))}

          <span
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
            }}
          ></span>
        </Nav>
        <div className="col-lg-12 mb-2 text-center">
          <h4
            class="inputLabel "
            style={{
              color: "black",
              borderBottom: "2px solid rgb(14, 116, 144)",
            }}
          ></h4>
        </div>
      </div>
    </>
  );
};

const AuditTab = ({ sethelpingObj }) => {
  const [active, setActive] = useState("userWise");
  const [checkedType, setCheckedType] = useState("userWise");
  console.log(active, "moizbug");

  const handleFormType = (event, arg) => {
    sethelpingObj((prev) => ({ ...prev, tabName: event }));
  };

  return (
    <>
      <div class="">
        <div className="">
          <div class=" row mt-1">
            <div
              class=""
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="sticky-container">
                <Navbar
                  // payerType={payerType}
                  appearance="tabs"
                  active={active}
                  onSelect={setActive}
                  handleFormType={handleFormType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditTab;
