import React, { useEffect } from "react";
import "./operation.css";
import { useState } from "react";
import OperationMainForm from "./OperationMainForm";
import FormType from "./FormType/FormType";

import {
  fetchProduct,
  getFirstThreeFormRecord,
  getFormType4ELIBILITY_VOBS,
  getFormType9PatStat,
  getFromType1ChargeEntryRecord,
  getFromType2RejectionRecord,
  getFromType3AuthRecord,
  getFromType6Eras,
  getFromType7AccountReceivable,
} from "../../Utils/ApiManager";
import moment from "moment";
import ChargeEntry from "./AllForms/ChargeEntry";
import { getFromType5Eobs } from "../../Utils/ApiManager";
import { useDispatch, useSelector } from "react-redux";


function Operation() {
    const dispatch = useDispatch();
  const [formType, setFormType] = useState('ELIBILITY/VOBS');
  const [payerType, setPayerType] = useState("AccountReceivablegeneral");
  const [Elibility, setElibility] = useState("");
  const [formTypeNoForApi, setFormTypeNoForApi] = useState(4);
  const [practiceId, setPracticeId] = useState(null);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [record, setRecord] = useState(null);
  const { onLoginSignOff } = useSelector((state) => state);
  const [tab, setTab] = useState(1);
  let obj={value:'AccountReceivablegeneral',label:" A/R General"}
  const [payerSelection,setPayerSelectionType]=useState(obj)
  const [state,setState]=useState(null)

  let objm={
    flag:null,
    isTabMove:false,
    nextTabName:"",
    nextFlag:""
  }
  const [isMoveTab, setisMoveTab] = useState(objm);
  const handleTab = (val) => {
    setTab(val);
  };
  useEffect(() => {
   
   
    if (formType && practiceId && date) {
      if (formType == "chargeEntry") {
        
        getRecordApiNodeChargeEntry();
      }
      if (formType == "Rejection") {
        getRecordApiNodeRejection();
      }
      if (formType == "Auth") {
        getRecordApiNodeAuth();
      }
      if(formType=='EOBS' || state?.value == "EOBS"){
        getRecordApiNodeEob()
        

      }
      if(formType=='ERAS'|| state?.value=='ERAS'){
        getRecordApiNodeERA()
        

      }
      if (formType == "chargeEntryPat") {
        
        getRecordApiNodePatStat();
       
      }
      //CALL aPI
     

      if (formType == "ELIBILITY/VOBS") {
        getRecordEliglibilityApie();
      }
      if(formType=='AccountReceivable'){
        AccountReceivable()   

      }
    }
  }, [formType, practiceId, date]);


  const onChangePayer=(arg)=>{
    setPayerSelectionType(arg)
    setPayerType(arg.value)


  }





  const AccountReceivable = async () => {
  
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType7AccountReceivable(obj);
    setRecord(result.data);
  };
  const onRecordUpdateOrCreated = (val) => {
   
    if (val == "chargeEntry") {
      getRecordApiNodeChargeEntry();
      return;
    }
    if (val == "PatStat") {
      getRecordApiNodePatStat();
      return;
    }
    if (val == "Rejection") {
      getRecordApiNodeRejection();
      return;
    }
    if (val == "Auth") {
      getRecordApiNodeAuth();
      return;
    }
    if(val=='Eobs'){
      getRecordApiNodeEob()
      return

    }
    if(val=='Eras'){
      getRecordApiNodeERA()
      return

    }
    if (val == "ELIBILITY/VOBS") {
      getRecordEliglibilityApie();
      return;
    }
    if(val=='AccountReceivable'){
      AccountReceivable()   

    }
  };
  

  const getRecordEliglibilityApie = async () => {
    
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFormType4ELIBILITY_VOBS(obj);
  
    setRecord(result.data);
    
  };
  const handelAccountReceiveable = (name) => {
   
  
    setPayerType(name);
  };

  const getRecordApi = async () => {
    const formDataForApi = new FormData();
    formDataForApi.append("userid", onLoginSignOff.userid);
    formDataForApi.append("sdate", date);
    formDataForApi.append("pid", practiceId);
    formDataForApi.append("formtype", formTypeNoForApi);
    formDataForApi.append("submittype", "Yes");

    let result = await getFirstThreeFormRecord(formDataForApi);
    setRecord(result.data);
  };

  const getRecordApiNodeChargeEntry = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType1ChargeEntryRecord(obj);
    setRecord(result.data);
  };
  const getRecordApiNodeRejection = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType2RejectionRecord(obj);
    setRecord(result.data);
  };
  const getRecordApiNodeAuth = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType3AuthRecord(obj);
    setRecord(result.data);
  };
  const getRecordApiNodeEob = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType5Eobs(obj);
    setRecord(result.data);
  };
  const getRecordApiNodeERA = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
    let result = await getFromType6Eras(obj);
    setRecord(result.data);
  };
  const getRecordApiNodePatStat = async () => {
    let obj = {
      userid: onLoginSignOff.userid,
      sdate: date,
      pid: practiceId,
      formtype: formTypeNoForApi,
      submittype: "Yes",
    };
   
    let result = await getFormType9PatStat(obj);
   
    setRecord(result.data);
  };

  const handleFormTypeAndPract = ({ name, val }) => {
   
   
   
    

  
    if (name == "date") {
      setDate(val);
      return;
    }
    if (name == "formType") {
      if (val == "chargeEntry" ) {
        setFormTypeNoForApi(1);
        setFormType(val);
      }

      if (val == "chargeEntryPat") {
        
        setFormTypeNoForApi(9);
        setFormType(val);
      }
      if (val == "Rejection" ) {
        setFormTypeNoForApi(2);
        setFormType(val);
      }
                                  
      if (val == "Auth") {
        setFormTypeNoForApi(3);
        setFormType(val);
      }
      // else if((isMoveTab.isTabMove==true &&isMoveTab.flag =='Auth')){
      //   setisMoveTab((prev)=>({...prev,flag:val,nextFlag:val}))
        


      // }
      if (val == "ELIBILITY/VOBS") {
        setFormTypeNoForApi(4);
        setFormType(val);
      }
    
      if(val=='Payment'){
        setFormTypeNoForApi(5);
        setFormType(val);

      }
      if (val == "EOBS") {
        setFormTypeNoForApi(5);
        setFormType(val);
      }
      if (val == "ERAS") {
        setFormTypeNoForApi(6);
        setFormType(val);
      }
      if (val == "AccountReceivable") {
        setFormTypeNoForApi(7);
        setFormType('AccountReceivable');
      }

      return;
    }
    if (name == "practiveVal") {
      setPracticeId(val);
      return;
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setProducts(optionList);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div class=" ">
            <div class="p-4 navcolor bg-white rounded  mb-5">
              <div>
                <OperationMainForm
                state={state}
                setState={setState}
                
                setPracticeId={setPracticeId}
                setRecord={setRecord}
              
                setFormTypeNoForApi={setFormTypeNoForApi}
                isMoveTab={isMoveTab}
                setisMoveTab={setisMoveTab}
                onChangePayer={onChangePayer}
                
                 products={products}
                 payerSelection={payerSelection}

                handleFormTypeAndPract={handleFormTypeAndPract}
                handelAccountReceiveable={handelAccountReceiveable}

                  onRecordUpdateOrCreated={onRecordUpdateOrCreated}
                  practiceId={practiceId}
                  payerType={payerType}
                  Elibility={Elibility}
                  formType={formType}
                  setFormType={setFormType}
                  record={record}
                  date={date}
                  formTypeNoForApi={formTypeNoForApi}
                />
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="col-lg-3">
          <div class="container">
            <div className="p-3 navcolor bg-white rounded shadow">
              <FormType
                handelAccountReceiveable={handelAccountReceiveable}
                date={date}
                handleFormTypeAndPract={handleFormTypeAndPract}
                products={products}
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
export default Operation;
