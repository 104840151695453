import React, { useState, useEffect } from "react";
import { Schema } from "rsuite";

import AddNewHtml from "./AddNewHtml";
import moment from "moment/moment";
import { createValidationSchema } from "../../../../Common/Validation";
import { toast } from "react-toastify";

export default function AddNewCoding({
  parentsetHelpingState,
  parentHelpingState,
  handlePopup,
}) {

  let obj = {
    Dos: moment().format("YYYY-MM-DD"),
    receivedClaims: "",
    workedClaims: "",
    isDemographics: false,
    pendingClaims: "",
    provider: "",
    operation: "",
    total: "",
    footerState: {
      isAllow: true,
      receivedClaims: 0,
      workedClaims: 0,
      total: 0,
      pendingClaims: 0,
    },
    selectedEditRowID: null,
    isgridEdit: false,
    preserveRowData: [],
    errorModel: {
      receivedClaimsError: false,
      workedClaimsError: false,
      DosError: false,
    },
  };

  const [addNewState, setAddNewState] = useState(obj);
  const { StringType, NumberType, DateType } = Schema.Types;

  useEffect(() => {
   
   
      let pending =
        parseInt(addNewState?.receivedClaims || 0) -
        parseInt(addNewState?.workedClaims || 0);
      setAddNewState((prev) => ({ ...prev, pendingClaims: pending }));
    
  }, [addNewState?.receivedClaims, addNewState?.workedClaims]);
  useEffect(() => {
    
      let total =
        parseInt(addNewState?.operation || 0) +
        parseInt(addNewState?.provider || 0);
      setAddNewState((prev) => ({ ...prev, total: total }));
    
  }, [addNewState?.operation, addNewState?.provider]);

  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(parentHelpingState?.internalTableArray);
     
    } catch (error) {
      parsedArray = parentHelpingState?.internalTableArray;
    }

    let receivedClaims = 0;
    let workedClaims = 0;
    let total = 0;
    let pendingClaims = 0;

    parentHelpingState?.internalTableArray &&
      parentHelpingState?.internalTableArray.map((el) => {
        receivedClaims += parseInt(el.receivedClaims); //recieved Claims
        workedClaims += parseInt(el.workedClaims); //Received Amount
        total += parseInt(el.total); //Worked AMount
        pendingClaims += parseInt(el.pendingClaims); //Worked Clai9ms
      });
    setAddNewState((prev) => ({
      ...prev,
      footerState: {
        ...prev.footerState,
        receivedClaims: receivedClaims,
        workedClaims: workedClaims,
        total: total,
        pendingClaims: pendingClaims,
      },
    }));
  }, [parentHelpingState?.internalTableArray]);
  const handleChange = (event, arg, checkboxEvent) => {
                                    
    if (checkboxEvent) {
      const { name } = checkboxEvent.target;
      if (name == "isDemographic") {
        setAddNewState((prev) => ({ ...prev, isDemographics: arg }));
      }
      return;
    }

    if (event == null) {
      setAddNewState((prev) => ({
        ...prev,
        Dos: moment().format("YYYY-MM-DD"),
      }));
    }
   

    if (event) {
      // Check if it's a date picker change (assuming Dos is a property in your state)
      if (event instanceof Date) {
        let date = moment(event).format("YYYY-MM-DD");
        setAddNewState((prev) => ({ ...prev, Dos: date }));
      } else if (event.target) {
        // Check if it's a regular input change
        const { name, value } = event.target;
        
        setAddNewState((prev) => ({ ...prev, [name]: value.replace(/[^0-9]/g, "") }));
      }
    }
  };

  const doValidation = async () => {
    const validationModel = [
      {
        fieldName: "receivedClaims",
        rules: { requiredMessage: "Received Claims is required." },
        type: { typeError: StringType() },
      },

      {
        fieldName: "workedClaims",
        rules: { requiredMessage: "Worked Claims is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "Dos",
        rules: { requiredMessage: "Worked Claims is required." },
        type: { typeError: StringType() },
      },
    ];
    let obj = {
      receivedClaims: addNewState?.receivedClaims,
      workedClaims: addNewState?.workedClaims,
      Dos: addNewState?.Dos,
    };
  

    let validationSchema = await createValidationSchema(validationModel);

    const errors = validationSchema.check(obj);
    setAddNewState((prev) => ({
      ...prev,
      errorModel: {
        ...prev.errorModel,
        receivedClaimsError: errors?.receivedClaims?.hasError,
        workedClaimsError: errors?.workedClaims?.hasError,
        DosError: errors?.Dos?.hasError,
      },
    }));
    if (
      !errors?.receivedClaims?.hasError &&
      !errors?.workedClaims?.hasError &&
      !errors?.Dos?.hasError
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onClear = () => {
    setAddNewState((prev) => ({
      ...prev,
      isgridEdit: false,
      Dos: moment().format("YYYY-MM-DD"),
      receivedClaims: "",
      workedClaims: "",
      isDemographics: false,
      pendingClaims: "",
      total: "",
      operation: "",
      provider: "",
    }));
  };

  const onAdd = async () => {
    
    let isPass = await doValidation();
    
    let result =
    parentHelpingState?.internalTableArray?.length > 0 &&
    parentHelpingState?.internalTableArray?.some((el) => el.Dos === addNewState?.Dos);
  if (result) {
    toast.info("Duplicate Dos not allowed");

    return;
  }
    if (isPass) {
      if (
        parseInt(addNewState?.total) == parseInt(addNewState?.pendingClaims)
      ) {
        //make object
        let obj = {
          id: addNewState?.isgridEdit
            ? addNewState?.selectedEditRowID
            : parentHelpingState?.internalTableArray.length + 1,
          Dos: addNewState?.Dos,
          receivedClaims: addNewState?.receivedClaims || 0,
          workedClaims: addNewState?.workedClaims || 0,
          isDemographics: addNewState?.isDemographics ||0,
          pendingClaims: addNewState?.pendingClaims || 0,
          provider: addNewState?.provider || 0,
          operation: addNewState?.operation  || 0,
          total: addNewState?.total || 0,
        };
        if(addNewState.preserveRowData?.bfRow==true){
          obj.bfRow=true

        }

        parentsetHelpingState((prev) => ({
          ...prev,
          internalTableArray: Array.isArray(prev.internalTableArray)
            ? [...prev.internalTableArray, obj]
            : [obj],
        }));
        await onClear();
      } else {
        toast.info("Pending Claims Should be equal to Total");
        return;
        //make object for Table
      }
    }
  };

  const handleEditState = (rowData) => {
    
    setAddNewState((prev) => ({
      ...prev,
      isgridEdit: true,
      Dos: rowData?.Dos,
      receivedClaims: rowData?.bfRow==true?(rowData?.receivedClaims).toString():rowData?.receivedClaims,
      workedClaims: rowData?.workedClaims,
      isDemographics: rowData?.isDemographics,
      pendingClaims: rowData?.pendingClaims,
      total: rowData?.total,
      operation: rowData?.operation,
      provider: rowData?.provider,
      selectedEditRowID: rowData?.id,
      preserveRowData: rowData,
    }));
    let res = parentHelpingState.internalTableArray.filter(
      (item) => item.id !== rowData?.id
    );
    parentsetHelpingState((prev) => ({ ...prev, internalTableArray: res }));
  };

  const handleDelete = (rowData) => {
    if (addNewState?.isgridEdit) {
      let arr = parentHelpingState?.internalTableArray.filter(
        (item) => item.id !== addNewState?.preserveRowData?.id
      );
      parentsetHelpingState((prev) => ({ ...prev, internalTableArray: arr }));
    } else {
      let arr = parentHelpingState?.internalTableArray.filter(
        (item) => item.id !== rowData?.id
      );
      parentsetHelpingState((prev) => ({ ...prev, internalTableArray: arr }));
    }
  };



  return (
    <>
      <AddNewHtml
        handleEditState={handleEditState}
        handleDelete={handleDelete}
        onAdd={onAdd}
        addNewState={addNewState}
        handleChange={handleChange}
        parentHelpingState={parentHelpingState}
        handlePopup={handlePopup}
      />
    </>
  );
}
