import React,{useEffect,useState} from 'react'
import moment from "moment";
import { useSelector } from "react-redux";

import StaticsCard2 from '../../graphCard/staticsCard2';
import LineGraph from '../../graphCard/graphType/LineGraph';
import { chargeEntryGraph } from '../../../../Utils/ApiManager';
import MountainGraph from '../../graphCard/graphType/MountainGraph';
import DoughnutChart from '../../graphCard/graphType/Doughnut';
import TextImageIcon from '@rsuite/icons/TextImage';


export default function EobDashboard({state,setState}) {

  const { onLoginSignOff } = useSelector((state) => state);
  let obj = {
    loader: false,
    graphSwitchState: "1",
  };

  const [stateChild, setStateChild] = useState(obj);
  useEffect(() => {
    getChargeEntryData();
  }, [state.ActiveTab]);

  const comparsionDate = (startDate, endDate) => {
    const dates = [];

    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "days");
    }

    return dates;
  };
  const getChargeEntryData = () => {
    setStateChild((prev) => ({
      ...prev,
      loader: true,
    }));
    let dateArr = [];
    const currentDate = moment();
    const oneDayBehind = currentDate.subtract(1, "days");
    const formattedDate = oneDayBehind.format("YYYY-MM-DD");
    if (state.ActiveTab == "1") {
      dateArr = [];
      dateArr.push(formattedDate);
    }
    if (state.ActiveTab == "3") {
      let startDate = oneDayBehind.subtract(2, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    if (state.ActiveTab == "7") {
      let startDate = oneDayBehind.subtract(6, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    if (state.ActiveTab == "30") {
      let startDate = oneDayBehind.subtract(29, "days").format("YYYY-MM-DD");

      dateArr = comparsionDate(startDate, formattedDate);
    }
    let obj = {
      formtypee: "5",
      userids: onLoginSignOff?.users?.map((item) => item.userid).join(","),
      dates: dateArr,
    };
    chargeEntryGraph(obj)
      .then((res) => {
      
       
        if (res && res.data) {
          let inputData = res.data?.totalUsers;

          const outputData = {
            totalWorkedAmount:
              inputData &&
              inputData.map((item) => item.totalWorkedAmount?item.totalWorkedAmount.toString():0),
            WorkedClaim: inputData.map((item) => item.WorkedClaim?item.WorkedClaim.toString():0),
            totalUserId: inputData.map((item) =>item.totalUserId?item.totalUserId.toString():0),
          };
          setState((prev) => ({
            ...prev,
            EobData: outputData,
            ChargeLabels: dateArr,
          }));
          setStateChild((prev) => ({
            ...prev,
            loader: false,
          }));
        }
      })
      .catch((err) => {
        setStateChild((prev) => ({
          ...prev,
          loader: false,
        }));
      });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  let labels = state?.ChargeLabels;

  let data = {
    labels,
    datasets: [
      {
        
        label: "Users",
        data: state?.EobData?.totalUserId,
        borderColor: "rgb(223, 99, 132)",
        backgroundColor: "rgb(237, 225, 91)",
      },
      {
        label: "Worked Claims",
        data: state?.EobData.WorkedClaim,
        borderColor: "rgb(223, 99, 132)",
        backgroundColor: "rgb(139, 211, 199)",
      },
      // {
      //   label: "Dataset 1",
      //   data: state?.EobData.totalWorkedAmount,
      //   borderColor: "rgb(223, 99, 132)",
      //   backgroundColor: "rgba(223, 99, 132, 0.5)",
      // },
    ],
    // datasets: [
    //   {
    //     label: 'Worked Amount',
    //     data: state?.EobData.totalWorkedAmount,
    //     borderColor: 'rgb(75, 192, 192)',
    //     backgroundColor: 'rgba(75, 192, 192, 0.5)',
    //   },
    //   {
    //     label: 'Dataset 1',
    //     data: state?.EobData.totalUserId,
    //     borderColor: 'rgb(255, 99, 132)',
    //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //   },
    //   {
    //     label: 'moiz',
    //     data: state?.EobData.WorkedClaim,
    //     borderColor: 'rgb(255, 120, 137)',
    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   },

    // ],
  };

  let data2 = {
    labels,
    datasets: [
     
      {
        fill:true,
        label: "Worked Amount",
        data: state?.EobData.totalWorkedAmount,
        borderColor: "rgb(14, 116, 144)",
        backgroundColor: "rgb(242, 196, 95)",
      },
    ],
    
  };

  const data3 = {
    labels: [`Worked Claim(${labels})`, `Users(${labels})`,],
    datasets: [
      {
        label: "",
        data: [
          state?.EobData.WorkedClaim,
          state?.EobData.totalUserId,
        ],
        backgroundColor: [
          "rgb(242, 196, 95)",

          "rgb(26, 128, 187)",
        ],
        borderColor: ["rgb(242, 196, 95)", "rgb(26, 128, 187)"],
        borderWidth: 1,
      },
    ],
  };

  const data4 = {
    labels: [`Worked Amount(${labels})`, `Worked Claims(${labels})`,],
    datasets: [
      {
        label: "",
        data: [
          state?.EobData.totalWorkedAmount,
          state?.EobData.WorkedClaim,
         
        ],
        backgroundColor: [
          "rgb(242, 196, 95)",

          "rgb(26, 128, 187)",
        ],
        borderColor: ["rgb(242, 196, 95)", "rgb(26, 128, 187)"],
        borderWidth: 1,
      },
    ],
  };

  const handleGraphSwitch = (arg) => {
    setStateChild((prev) => ({
      ...prev,
      graphSwitchState: arg,
    }));
  };
  return (
    <div class="flex flex-wrap  mt-2  ml-4 tw-px-2 tw-py-2 tw-mx-auto ">
      {false ? (
        <></>
      ) :<StaticsCard2
      handleGraphSwitch={handleGraphSwitch}
      disableAmount={false}
      data={state?.AllRecord?.["eob`s"]}
      label={"EOB`s"}
      icon={<TextImageIcon style={{ color: "white" }} />}
      graphComponent={state?.ActiveTab == "1" ? (
        <DoughnutChart options={options}  data={data3} />
      ) :<LineGraph options={options} data={data} />}
      graphComponent2={state?.ActiveTab=="1"? <DoughnutChart options={options}  data={data4} />:<MountainGraph options={options} data={data2} />}
      stateChild={stateChild}
    />}
    
  </div>
  )
}
