import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Reports from "../ChargeEntry/Reports";
import Meeting from "../ChargeEntry/Meeting";
import {
  generatePatStatAuditMainTable,
  generatePaymentAuditMainTable,
  getUsersName,
  insertTlDenialTeamLead,
} from "../../../../Utils/ApiManager";
import DateAndPractice from "../../DateAndPracticeTl";
import PaymentAudit from "./DenialAuditTable";
import { Panel } from "rsuite";
import Dateoofservice from "../../../../Common/Dateoofservice";

function DenialVerification({
  handleSubCheckBoxChargeEntry,
  date,
  handleFormTypeAndPract,
  record,
  products,
  practiceId,
  dateAndP,
  formTypeNoForApi,
  onRecordUpdateOrCreated,
}) {
  let helpingObj = {
    loading: false,
    isSwitchTable: 1,
    holdSubDosTableData: [],
    getAllBellUsers: "",
    isTriggerEffectDos: false,

  };
  const [helpingStatem, setHelpingState] = useState(helpingObj);
  const [tableState, setTableState] = useState([]);



  const { onLoginSignOff } = useSelector((state) => state);
  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    formType: "",
    submittype: "",
    AmountDenied: "",
    totlabreakupAmount:"",
    isEobDosOpen:"",
    parsedPatStateArray:[]
  };
  const [state, setState] = useState(payload);
  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };

  useEffect(() => {
    if (date) {
      onclear();
    }
  }, [date]);
  useEffect(() => {
    getUsersName().then((res) => {
      setHelpingState((prev) => ({ ...prev, getAllBellUsers: res }));
    });
  }, []);

  useEffect(() => {
    if (record) {
      setState((prev) => ({ ...prev, recievedval: record?.recievedval,
        verifiedval:record?.verifiedval,
        nonverifiedval:record?.nonverifiedval,
        pendingval:record?.pendingval,
        receivedamount:record?.receivedamount,
        collectedamount:record?.collectedamount,
        pendingamountval:record?.pendingamountval,
        totalPendingClaim:record?.totalPendingClaim




        

       }));
    }
  }, [record, practiceId]);

  useEffect(() => {
    if(state.totalPendingClaim){
      let sum=parseInt(state.totalPendingClaim.providerVal)+parseInt(state.totalPendingClaim.payerVal)+parseInt(state.totalPendingClaim.otherVal)+parseInt(state.totalPendingClaim.operationVal)+parseInt(state.totalPendingClaim.patVal)
      
      setState((prev)=>({...prev,totlabreakupAmount:sum}))
    }
   
  }, [state.totalPendingClaim])
  

  const onclear = () => {
    setState(payload);
  };

  const convertUseridIntoUserName = async (data) => {
   
    let res = helpingStatem.getAllBellUsers;

    const mergedData = data.map((user) => {
      const matchingData =
        res &&
        res.data.length > 0 &&
        res?.data.find((item) => item.userid === user.userid);
      const total = matchingData
        ? Number(user.patientsp) +
          Number(user.adjustmentp) +
          Number(user.insurancep)
        : 0;

      return {
        ...user,
        ...matchingData,
        total,
      };
    });

    return mergedData;
  };

  const onSearch = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      setHelpingState((prev) => ({ ...prev, loading: true }));
      let obj = {
        userid: onLoginSignOff.userid,
        sdate: date,
        pid: practiceId,
        formtype: 1,
      };

      let resultt = await generatePatStatAuditMainTable(obj);

      if (resultt?.data) {
        if (resultt?.data.PatStat?.length == 0) {
          toast.info("No Record Found");

          return;
        } else {
        
          let convertedData = await convertUseridIntoUserName(resultt?.data?.PatStat);
          console.log('convertedData',convertedData)

          const formattedArrayCharge = convertedData && convertedData.map((item) => {
            return {
              ...item,
              collectedamount: isNaN(Number(item.collectedamount)) ? 0 : Number(item.collectedamount).toLocaleString(),
              receivedamount: isNaN(Number(item.receivedamount)) ? 0 : Number(item.receivedamount).toLocaleString(),
              pendingamountval: isNaN(Number(item.pendingamountval)) ? 0 : Number(item.pendingamountval).toLocaleString(),
              recievedval: isNaN(Number(item.recievedval)) ? 0 : Number(item.recievedval),
              paymentstateAdjusted: isNaN(Number(item.paymentstateAdjusted)) ? 0 : Number(item.paymentstateAdjusted),
              nonverifiedval:isNaN(Number(item.nonverifiedval))?0:Number(item.nonverifiedval)
            };
          });
          setTableState(formattedArrayCharge);
          setHelpingState((prev) => ({ ...prev, loading: false }));
        }
      } else {
        setHelpingState((prev) => ({ ...prev, loading: false }));
        toast.info("Something Went Wrong");
      }
    }
  };

  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [globalDos, openGlobalDos] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const switchTableDetail = () => {
    setHelpingState((prev) => ({ ...prev, isSwitchTable: 3 }));
  };

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };
  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };
  const backToMainScreen = (arg) => {
    if (arg == "mainScreen") {
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 1 }));
    } else {
      setHelpingState((prev) => ({ ...prev, isSwitchTable: 2 }));
    }
  };
  const [renderEffect, setRenderEffect] = useState(false);

  const renderr = (val) => {
    setRenderEffect(val);
  };


  const handleClose = () => {
    setState((prev) => ({ ...prev, isEobDosOpen: false }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: false }));
    setState((prev)=>({...prev,isEobDosOpen:false}))
  };


  console.log(state.parsedPatStateArray,'state.parsedPatStateArray')

  return (
    <>
    {state.isEobDosOpen ? (
         <Dateoofservice
       
         isBlockAllhtml={true}
          
         renderr={renderr}
         renderEffect={renderEffect}
        
         dosArray={state.parsedPatStateArray?state.parsedPatStateArray:[]}
         
         handleClose={handleClose}
        
         claimHeading={'Received'}
             claimAmount={'Received Amount'}
             claimHeading1={"Verified"}
             claimHeading2={"Received Amount"}
             claimHeading3={"Collected Amount"}
             claimHeading4={"Pending  Amount"}
             claimAmount1={"Rejected"}
         breakupName={'Pending Claim Breakup'}
   
   
        
         onlyShowTable={true}
         SecondTableName={'patStat'}
         showTableCheck={'patStat'}
         // flag={"patStat"}
         // changeState={'patState'}
       />
      ):<>
      <div className="row">
        {helpingStatem.isSwitchTable != 1 ? null : (
          <DateAndPractice
            flag={"denial"}
            openGlobalTable={openGlobalTable}
            callGlobalDos={callGlobalDos}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleFormTypeAndPract={handleFormTypeAndPract}
            products={products}
            date={date}
          />
        )}
        {helpingStatem.isSwitchTable != 1 ? null : (
          <>
            <div className="row"></div>

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Received
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.recievedval}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Verified
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.verifiedval}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Rejected
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.nonverifiedval}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.pendingval}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Received Amount
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.receivedamount}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Worked Amount
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.collectedamount}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending Amount
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="credientalingVal"
                  placeholder=""
                   value={state?.pendingamountval}
                  //  onChange={onChangeBreakupClaim}
                  disabled
                />
              </div>
            </div>

            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Total Rejected Breakup
              </h4>
            </div> */}
            <div className="mt-4"></div>
             <Panel defaultExpanded={true} header="Total Rejected Breakup" collapsible={true} >
              <div className="row mt-4">

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Provider End
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="providerVal"
                  placeholder=""
                  disabled
                     value={state?.totalPendingClaim?.providerVal}
                  //    onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Patient End
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="number"
                  name="patVal"
                  placeholder=""
                      value={state?.totalPendingClaim?.patVal}
                  //   onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Operation End
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="number"
                  name="operationVal"
                  placeholder=""
                    value={state?.totalPendingClaim?.operationVal}
                  //  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Payer`s End
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="number"
                  name="payerVal"
                  placeholder=""
                   value={state?.totalPendingClaim?.payerVal}
                  //  onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Other
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="number"
                  name="otherVal"
                  placeholder=""
                    value={state?.totalPendingClaim?.otherVal}
                  //   onChange={onChangeValues}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="number"
                  name="totalVal"
                  placeholder=""
                    value={state.totlabreakupAmount}
                  //  onChange={onChangeValues}
                />
              </div>
            </div>
            </div>
            </Panel>

            <div class="form-group mb-2 ">
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="button"
                  onClick={onSearch}
                  className="btnClear mr-1 "
                >
                  Generate Audit
                </button>
              </div>
            </div>
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Audit Table
              </h4>
            </div> */}
          </>
        )}{" "}
        <PaymentAudit
        flagIndi={'PatState'}
        practiceId={practiceId}
        date={date}
        setStateprev={setState}
          setHelpingState={setHelpingState}
          tableState={tableState}
          helpingStatem={helpingStatem}
        />
        {helpingStatem.isSwitchTable != 1 ? (
          <div class="col-sm-6 col-lg-12 ">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4">
                {helpingStatem.isSwitchTable == 2 ? (
                  // <button
                  //   type="button"
                  //   className="btnClear mr-1 "
                  //   onClick={switchTableDetail}
                  // >
                  //   View Detail
                  // </button>
                  null
                ) : null}

                {helpingStatem.isSwitchTable == 2 ? (
                  <button
                    type="button"
                    className="btnSave"
                    onClick={() => backToMainScreen("mainScreen")}
                  >
                    Back to Main Screen
                  </button>
                ) : (
                  <>
                    {helpingStatem.isTriggerEffectDos == true ? null : (
                      <button
                        type="button"
                        className="btnSave"
                        onClick={() => backToMainScreen("subScreen")}
                      >
                        Back
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div class="col-sm-6 col-lg-12 ">
            <div class="form-group ">
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="button"
                  className="btnClear mr-1 "
                  onClick={onclear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
}
 </> );
}
export default DenialVerification;
