import React from "react";
import ReactDropdown from "react-dropdown";
import AccVerify from "./AccRec/AccRecVerify";
import Reports from "./ChargeEntry/Reports";
import Meeting from "./ChargeEntry/Meeting";

const AccountRecievable = ({ subCheckBox,formTypeNoForApi,date,formType,practiceId,record }) => {
  return (<>
    {subCheckBox == "AccountRecVerify"  ? (
      <AccVerify record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) : subCheckBox == "MeetingDeniedAC" ? (
      <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    ) : subCheckBox == "ReportsDeniedAC" ? (
      <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) : null}
  </>
    
  );
};

export default AccountRecievable;
