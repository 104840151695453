import React from "react";
import ReactDropdown from "react-dropdown";
import PaymentVerification from "./PaymentVerification";
import Meeting from "../ChargeEntry/Meeting";
import Reports from "../ChargeEntry/Reports";
import { useState } from "react";
import { Panel } from "rsuite";

const Payments = ({handleFormTypeAndPract,products,handleSubCheckBoxChargeEntry, subCheckBox,formTypeNoForApi,date,formType,practiceId,record,onRecordUpdateOrCreated}) => {
 
  
  
  return (
    <>
     {subCheckBox == "PaymentsVerification" || subCheckBox=='AccountPayments' ? (
      
        <PaymentVerification   
      handleFormTypeAndPract={handleFormTypeAndPract}
      products={products}
      handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
      onRecordUpdateOrCreated={onRecordUpdateOrCreated}record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}
      />
      
      
    ): subCheckBox == "ReportsPayments" ? (
      null
      // <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ): subCheckBox == "MeetingPayment" ? (
      null
      // <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    )  : null}
    </>
   
  );
};
export default Payments;
