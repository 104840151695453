import React from "react";
import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { IconButton, ButtonToolbar, ButtonGroup } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import VisibleIcon from '@rsuite/icons/Visible';
import { Panel } from 'rsuite';


export default function DateAndPractice({
  state,
  handletlType,
  flag,
  onSearch,
  isShowSearchButton,
  date,
  products,
  handleFormTypeAndPract,
  selectedOption,
  setSelectedOption,
  callGlobalDos,
  openGlobalTable
}) {
  useEffect(() => {
   // setSelectedOption(selectedOption);
  }, []);

  console.log("selectedOption", selectedOption);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  let today = moment().format("YYYY-MM-DD");
  let minDate = moment().subtract(5, "days").format("YYYY-MM-DD");

  const handlePracticeVal = (val) => {
    const { value, label } = val;
    setSelectedOption(val);
    let obj = { name: "practiveVal", val: value };
    handleFormTypeAndPract(obj);
  };
  const handleVal = (event) => {
    const { value } = event.target;
    let obj = { name: "date", val: value };
    handleFormTypeAndPract(obj);
  };

  console.log("flag",flag)

  return (
    <>
    
      <div className="row">
       
      
      <div className="form-group mb-2 mb-0 font-weight-normal dropdown-header" style={{ display: "flex" }}>

      {flag=='tl' &&<div className="col-4 ">
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          TL Type
        </label>
        <br />
        <Select
          options={[{label:'Charge Entry',value:"chargeEntry"},{label:'Payments',value:'Payments'},{label:'Patient Statement',value:'PatientStatement'},{label:'Account Receivable',value:'AccountReceivable'}]}
          placeholder="Select an option"
          onChange={handletlType}
          value={{ label: state.tlType.label, value: state.tlType.value }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>}
      <div className={flag=='charge'||flag=='denial'?"col-3":'col-4'}>
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Practice
        </label>
        <br />
        <Select
          options={products || []}
          placeholder="Select an option"
          onChange={handlePracticeVal}
          value={{ label: selectedOption.label, value: selectedOption.value }}
          styles={customStyles}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>
      <div className={flag=='charge'||flag=='denial'?"col-3":'col-4'}>
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px" }}
        >
          Entry Date
        </label>
        <br />
        <input
          class="form-control inputField"
          type="date"
          name="phone"
          placeholder=""
          max={today}
          min={minDate}
         // defaultValue={date}
          value={date}
          onChange={handleVal}
        />
        <label
          className="inputLabel"
          style={{ color: "black", fontSize: "13px", color: "red" }}
        >
          {/* {errorModel.date == true ? "Date is Required" : null} */}
        </label>
      </div>
     
      </div>
    
     
      

      {isShowSearchButton?<>
      </>:flag!='denial'?<div className="col-4 ">
      
       
       </div>:null}
      </div>

     
    
      
      

     
    </>
  );
}
