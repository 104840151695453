import { Nav } from 'rsuite';
import React from 'react';
import HomeIcon from '@rsuite/icons/legacy/Home';
import DetailIcon from '@rsuite/icons/Detail'
import CouponIcon from '@rsuite/icons/Coupon';
import ChangeListIcon from '@rsuite/icons/ChangeList';
import IdInfoIcon from '@rsuite/icons/IdInfo';
import { useState } from 'react';
import FirefoxIcon from '@rsuite/icons/legacy/Firefox';
import DropboxIcon from '@rsuite/icons/legacy/Dropbox';
import DocPassIcon from '@rsuite/icons/DocPass';
import EllipsisHIcon from '@rsuite/icons/legacy/EllipsisH';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import TextImageIcon from '@rsuite/icons/TextImage';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';
import { useSelector } from "react-redux";
import NotifySwal from "../../../Common/NotifySwal"
import { useDispatch } from "react-redux";
import { AllConfirmationOnTab } from '../../../Actions/Actions';


const navItems = [
  { eventKey: 'ELIBILITY/VOBS', label: 'Eligibility/VOB`s', icon: <IdInfoIcon /> },
  { eventKey: 'Auth', label: 'Authorization', icon: <DocPassIcon /> },
  { eventKey: 'chargeEntry', label: 'Charge Entry', icon: <DetailIcon /> },
  { eventKey: 'Rejection', label: 'Rejection', icon: <ChangeListIcon /> },
 
 
  // { eventKey: 'EOBS', label: 'EOB`s', icon: <TextImageIcon /> },
  // { eventKey: 'ERAS', label: 'ERA`s', icon: <TextImageIcon /> },
  { eventKey: 'Payment', label: 'Payment Posting', icon: <TextImageIcon /> },
  { eventKey: 'chargeEntryPat', label: 'Patient Statement / Verification', icon: <CharacterAuthorizeIcon /> },
  { eventKey: 'AccountReceivable', label: 'Account Receivable', icon: <CharacterAuthorizeIcon />
},
];

const accountReceivableItems = [
  { eventKey: 'AccountReceivablegeneral', label: 'AR General Payer', icon: <TextImageIcon /> },
  { eventKey: 'AccountReceivablespecial', label: 'AR Special Payer', icon: <ChangeListIcon /> },
];


const Navbar = ({ payerType, active, onSelect, handleFormType, ...props }) => {
  const { AllConfirmationonTab } = useSelector((state) => state);
  const isActive = (eventKey) => {
   
    
    
   
      return active==eventKey
    
    
   
  };
 

  const activeStyle = { backgroundColor: "#0e7490", color: "white" };
  const subactiveStyle = { backgroundColor: "#0e7490", color: "white" };
  console.log('Active,',active)
  

  return (
    <>
      <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 30 }}>
        {navItems.map(({ eventKey, label, icon }) => (
          <Nav.Item
            key={eventKey}
            eventKey={eventKey}
            icon={icon}
            style={isActive(eventKey) ? activeStyle : {}}
            onSelect={handleFormType}
            disabled={AllConfirmationonTab?true:false}
          >
            <span style={{fontWeight:'normal'}}>{label}</span>
          </Nav.Item>
        ))}
        
        <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                 
                }}
              >
                
               
              </h4>
            </div>
      </Nav>
    
      
    </>
  );
};

const TabMenus = ({setPracticeId,setRecord, payerType, handelAccountReceiveable, handleFormTypeAndPract }) => {

  const dispatch = useDispatch();
  const { AllConfirmationonTab } = useSelector((state) => state);
  console.log(AllConfirmationonTab,'ddfdfd')
  const [active, setActive] = useState('ELIBILITY/VOBS');
  const [checkedType, setCheckedType] = useState('ELIBILITY/VOBS');
  let obj={
    isSwalOpen:"",
    eventVal:"",
    argVal:""

  }
 const [state,setState]=useState(obj)

 const handleSwalConfirm=(val)=>{
  if(val){
    let value = state.eventVal;
   if(state.eventVal==true){
    value = state.argVal;

   }
    
   
    if (value === 'AccountReceivablegeneral' || value === 'AccountReceivablespecial') {
      handelAccountReceiveable(value);
      setCheckedType(value);
      let obj = { name: 'formType', val: value };

      handleFormTypeAndPract(obj);
    } else {
      if (value === 'AccountReceivable') {
        handelAccountReceiveable('AccountReceivable');
      }
      setCheckedType(value);

      let obj = { name: 'formType', val: value };

      handleFormTypeAndPract(obj);
    }
    dispatch(AllConfirmationOnTab(false))

  }else{

  }

}
  

  const handleFormType = (event,arg) => {
    
  setRecord(null)
  setPracticeId(null)
   
    
   
   
    if(AllConfirmationonTab){
      setState((prev)=>({...prev,isSwalOpen:true,eventVal:event,argVal:arg}))
      
    }else{
      
      setState((prev)=>({...prev,isSwalOpen:false,}))
      let value = event;
   if(event==true){
    value = arg;

   }
    
   
    if (value === 'AccountReceivablegeneral' || value === 'AccountReceivablespecial') {
      handelAccountReceiveable(value);
      setCheckedType(value);
      let obj = { name: 'formType', val: value };

      handleFormTypeAndPract(obj);
    } else {
      if (value === 'AccountReceivable') {
        handelAccountReceiveable('AccountReceivable');
      }
      setCheckedType(value);

      let obj = { name: 'formType', val: value };

      handleFormTypeAndPract(obj);
    }
      
    }
    
   
  
  
    
  };

  return (
    <>
  {state.isSwalOpen?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Leave this Tab'}/>:null}
      <Navbar
        payerType={payerType}
        appearance="tabs"
        active={active}
        onSelect={setActive}
        handleFormType={handleFormType}
      />
    
    </>
  );
};

export default TabMenus;
