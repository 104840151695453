import { ApiCall } from "./ApiCall";

//--------------------------Sign Off---------------------------
let SignOFFUrl = process.env.REACT_APP_SIGN_OFF_ADDRESS;
let getRecord = process.env.REACT_APP_SIGN_OFF_GET_RECORD;
let saveRecord = process.env.REACT_APP_SIGN_OFF_GET_PRODICT;
let getTlRecord = process.env.REACT_APP_SIGN_OFF_GET_TL_RECORD;
let TlAdd = process.env.REACT_APP_SIGN_OFF_TL_ADD;
let localnode = process.env.REACT_APP_LOCAL_SERVER_URL;

console.log("localnode", localnode);
let userIdies = process.env.REACT_APP_USERS_ID;
export const getThresholdFordocuhub = (pidies) => {
  let path = userIdies + `?threshold=1&practice_ids=${pidies}`;
  return ApiCall(path, "post", null);
};
export const LoginSignOFF = (obj) => {
  const queryParam = `/silogin.php`;
  let path = SignOFFUrl + queryParam;
  return ApiCall(path, "post", obj);
};
export const fetchProduct = (obj) => {
  const queryParam = `/silogin.php`;
  let path = SignOFFUrl + queryParam;
  return ApiCall(path, "post", obj);
};

export const getFirstThreeFormRecord = (obj) => {
  return ApiCall(getRecord, "post", obj);
};
export const onSaveApi = (obj) => {
  return ApiCall(saveRecord, "post", obj);
};
export const getTlRecordsApi = (obj) => {
  return ApiCall(getTlRecord, "post", obj);
};
export const tlAdd = (obj) => {
  return ApiCall(TlAdd, "post", obj);
};

export const userIdess = (obj) => {
  return ApiCall(userIdies, "post", obj);
};

export const getThresholdReportFromDoc = (obj) => {
  return ApiCall(userIdies, "post", obj);
};
export const fetchTlRecord = (obj) => {
  return ApiCall(getTlRecord, "post", obj);
};

// All Get Apis
export const getFromType1ChargeEntryRecord = (obj) => {
  const queryParam = `getFormType1ChargeEntryRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getFromType2RejectionRecord = (obj) => {
  const queryParam = `getFromType2RejectionRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getFromType3AuthRecord = (obj) => {
  const queryParam = `getFromType3AuthRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const verifyAuditOverAllDOS = (obj) => {
  const queryParam = `verifyAuditOverAllDOS`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getTeamsInfo = (obj) => {
  const queryParam = `?teams=1`;
  let path = userIdies + queryParam;

  return ApiCall(path, "post", obj);
};

export const getUsersByTeams = (obj) => {
  const queryParam = `?teamusers=${obj}`;
  let path = userIdies + queryParam;

  return ApiCall(path, "post", null);
};

export const getUsersByTeamsRoleBased = (obj) => {
  
  let path = userIdies;

  return ApiCall(path, "post", obj);
};



export const getPracticeByTeams = (obj) => {
  let path = userIdies;

  return ApiCall(path, "post", obj);
};

export const userPracticeWiseFilter = (obj) => {
  let path = userIdies;

  return ApiCall(path, "post", obj);
};

export const SearchIfAuditTriggerFire = (obj) => {
  const queryParam = `getTeamLeadAuditLogsOverAll`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};


export const getAggingRec = (obj) => {
  const queryParam = `getAgging`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getAggingAcc = (obj) => {
  const queryParam = `getAggingAcc`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};




export const getAggingRecPat = (obj) => {
  const queryParam = `getAggingPatSat`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};


export const getActiveUsers = (obj) => {
  const queryParam = `getActiveUsersNo`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const claimsRecord = (obj) => {
  const queryParam = `dashboardRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const chargeEntryGraph = (obj) => {
  const queryParam = `graphDataCharge`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const demoApi = (obj) => {
  const queryParam = `graphDataCharge`;
  let path = 'https://192.168.100.195:40400/api/Departments/GetALLDepartments'

  return ApiCall(path, "post", obj);
};




export const searchbyVictimeid = (obj) => {
  const queryParam = `searchbyVictimeid`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const searchbyVictimeidClaimLevel = (obj) => {
  const queryParam = `searchbyVictimeidClaimLevela`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const searchbyVictimeidOverAll = (obj) => {
  const queryParam = `FetchOverAllNotifyLogs`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const fetchTeamLeadData = (obj) => {
  const queryParam = `fetchTeamLeadData`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertTeamLeadData = (obj) => {
  const queryParam = `insertTeamLeadData`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const dashboardRecord = (obj) => {
  const queryParam = `dashboardRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getDashboardRec = (obj) => {
  const queryParam = `getDashboardLayout`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const callUserLayoutSaveApi = (obj) => {
  const queryParam = `insertDashboardLayout`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const generatePaymentAuditMainTable = (obj) => {
  const queryParam = `generatePaymentAuditMainTable`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const generatePatStatAuditMainTable = (obj) => {
  const queryParam = `PatStatTlRouteAudit`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getBucketImages = (obj) => {
  const queryParam = `RetrieveBucketData`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj, true);
};

export const getAuditDosRec = (obj) => {
  const queryParam = `getAuditDosRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getCodingDosAudit = (obj) => {
  const queryParam = `FetchDosRecordCoding`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const FetchTeamLeadPayment = (obj) => {
  const queryParam = `FetchPaymentsData`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getFromType5Eobs = (obj) => {
  const queryParam = `getFormType5Eobs`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const overAllVerifyWithRating = (obj) => {
  const queryParam = `overAllVerifyWithRating`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const TaskListRecord = (obj) => {
  const queryParam = `taskListRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const fetchPayerList = (obj) => {
  const queryParam = `getPayerList`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getFromType6Eras = (obj) => {
  const queryParam = `getFormType6Eras`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getFormType4ELIBILITY_VOBS = (obj) => {
  const queryParam = "getFormType4ELIBILITY_VOBS";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getFormType9PatStat = (obj) => {
  const queryParam = "getFormType9PatStat";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getFromType7AccountReceivable = (obj) => {
  const queryParam = "getFormType7Account_Receivable";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getChargeEntryTeamLead = (obj) => {
  const queryParam = "getTlChargeEntry";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getDenialTeamLead = (obj) => {
  const queryParam = "FetchPatStatementData";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getPaymnetTeamLead = (obj) => {
  const queryParam = "formTypeTlPayment";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getAccVerify = (obj) => {
  const queryParam = "getAccVerify";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getTlAudit = (obj) => {
  const queryParam = "getAudit";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const getTluserwiseAudit = (obj) => {
  const queryParam = "getuserAudit";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getUserAuditCoding = (obj) => {
  const queryParam = "getAuditCodingData";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getCodingAuditData = (obj) => {
  const queryParam = "getAuditCodingData";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const getAccRecordByUserid = (obj) => {
  const queryParam = "getAccRecordbyUserid";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const onEditTableCallApi = (obj) => {
  const queryParam = `onEditCallTable`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const onEditTableRowApi = (obj) => {
  const queryParam = `onEditRowTable`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const onVerifyAuditTable = (obj) => {
  const queryParam = `onVerifyRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
// All insert
export const insertFromType1ChargeEntryRecord = (obj) => {
  const queryParam = `insertFormType1ChargeEntryRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const insertFromType2RejectionRecord = (obj) => {
  const queryParam = `insertFormType2RejectionRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const insertFromType3AuthRecord = (obj) => {
  const queryParam = `insertFormType3AuthRecord`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertFromType5EobsRecord = (obj) => {
  const queryParam = `insertFormType5Eobs`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertFromType6ErasRecord = (obj) => {
  const queryParam = `insertFormType6Eras`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertFormType4ELIBILITYVOBS = (obj) => {
  const queryParam = "insertFormType4ELIBILITY_VOBS";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const insertFormType9PatStats = (obj) => {
  const queryParam = `insertPatStat`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertFormType7AccountReceivable = (obj) => {
  const queryParam = "insertFormType7AccountReceivable";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const searchChargeEntry = (obj) => {
  const queryParam = "searchChargeEntry";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const searchChargeEntryUser = (obj) => {
  const queryParam = "searchChargeEntryUser";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const insertTlDenialTeamLead = (obj) => {
  const queryParam = "insertTlDenial";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertTlPaymentTeamLead = (obj) => {
  const queryParam = "insertTlPayment";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertAccVerfiy = (obj) => {
  const queryParam = "SearchAccVerify";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getPraqcticeWise = (obj) => {
  const queryParam = "getPracticeWiseRecord";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getPraqcticeWiseCoding = (obj) => {
  const queryParam = "getPracticeWise";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertAuditRemarks = (obj) => {
  const queryParam = "insertAuditRemarks";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const overAllLog = (obj) => {
  const queryParam = "SaveTeamLeadAuditLogsOverAll";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const insertTlAudit = (obj) => {
  const queryParam = "SaveTeamLeadAuditLogs";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const searchFetchTask = (obj) => {
  const queryParam = "FetTaskListOverAll";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const FetchOverAllTaskList = (obj) => {
  const queryParam = "FetchOverAllTaskList";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const getAuditRecord = (obj) => {
  const queryParam = "getAuditRemarks";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getTlAuditRecord = (obj) => {
  const queryParam = "getTlAuditRemarks";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const verifyChargeEntryTl = (obj) => {
  const queryParam = "chargeEntryAuditVerify";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const getUsersName = (obj) => {
  let path = userIdies + `?users=1`;

  return ApiCall(path, "get");
};


export const insertCoding = (obj) => {
  const queryParam = `insertCoding`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const FetchCoding = (obj) => {
  const queryParam = `fetchCoding`;
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};
export const FetchCodingForTl = (obj) => {
  const queryParam = "fetchCoding/fetchCodingDataForTl";
  let path = localnode + queryParam;

  return ApiCall(path, "post", obj);
};

export const generateAuditCodingForTl = (obj) => {
  const queryParam = "fetchCoding/generateAuditCodingForTl";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const createTlwWrkSheetOfCoding = (obj) => {
  const queryParam = "fetchCoding/createTlwWrkSheetOfCoding";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const tlwWrkSheetOfCoding = (obj) => {
  const queryParam = "fetchCoding/tlwWrkSheetOfCoding";
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

