import React, { useState, useEffect } from "react";
import UserAuditTable from "../../../Audit/userTaskViewTable";
import ChargeTypeRemarksPop from "../ChargeEntry/DrawerChargeEntry";

export default function PaymentAudit({
  flagIndi,
  practiceId,
  date,
  isDosOpen,
  setHelpingState,
  helpingStatem,
  loading,
  tableState,
  setDosArray,

  dosArray,
}) {
  let obj = {
    recordEob: [],
    recordEra: [],
    combineRecord: [],
    isToggleRemarks: [],
    Dos: "",

    PayerType: "",
    payerlabel: "",
    recievedAmountCC: "",
    recievedAmount: "",
    pending: "",
  };
  const [state, setState] = useState(obj);
  useEffect(() => {
    let paresedArray2;
    let parsedEra;
    let simp =
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedgen == "" ||
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedgen == null;
    let simp2 =
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedSpe == "" ||
      helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedSpe == null;
    if (simp) {
      setState((prev) => ({ ...prev, recordEob: [] }));
    } else {
      try {
        paresedArray2 = JSON.parse(
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedgen || []
        );

        console.log("The string contains a valid JSON object.");
      } catch (error) {
        paresedArray2 =
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedgen;
      }

      setState((prev) => ({ ...prev, recordEob: paresedArray2 }));
    }
    if (simp2) {
      setState((prev) => ({ ...prev, recordEra: [] }));
    } else {
      try {
        parsedEra = JSON.parse(
          helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedSpe || []
        );
      } catch (error) {
        parsedEra = helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedSpe;
      }

      setState((prev) => ({ ...prev, recordEra: parsedEra }));
    }
    console.log("paresedArray2", paresedArray2, "parsedEra", parsedEra);
  }, [helpingStatem.holdSubDosTableData]);
  useEffect(() => {
    let res = FuncToJoinTwoArrays(state.recordEob, state.recordEra);

    setState((prev) => ({ ...prev, combineRecord: state.recordEob }));
  }, [state.recordEob, state.recordEra]);

  const FuncToJoinTwoArrays = (date, date2) => {
    const result = [];

    // Combine dates from both arrays into a single array for comparison
    const allDates = [...date, ...date2];

    // Create a set of unique dates
    const uniqueDates = new Set(allDates.map((item) => item.date));

    // Iterate over the unique dates and calculate sums for each date
    for (const d of uniqueDates) {
      const matchedDates1 = date.filter((item) => item.date === d);
      const matchedDates2 = date2.filter((item) => item.date === d);

      const receivedAmount1 = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.recievedAmountCC),
        0
      );
      const WorkedClaims1 = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.recievedAmount),
        0
      );
      const pendingClaims1 = matchedDates1.reduce(
        (sum, item) => sum + parseFloat(item.pending),
        0
      );

      const receivedAmount2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.recievedAmountCC),
        0
      );
      const WorkedClaims2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.recievedAmount),
        0
      );
      const pendingClaims2 = matchedDates2.reduce(
        (sum, item) => sum + parseFloat(item.pending),
        0
      );

      const newObj = {
        date: d,
        RecievedClaims: receivedAmount1 + receivedAmount2,
        WorkedClaims: WorkedClaims1 + WorkedClaims2,
        pendingClaims: pendingClaims1 + pendingClaims2,
      };

      result.push(newObj);
    }

    return result;
  };
  const onShowRemakrs = (rowData) => {
    setState((prev) => ({
      ...prev,
      isToggleRemarks: true,
      Dos: rowData.date,
      recievedAmountCC: rowData?.recievedAmountCC,
      recievedAmount: rowData?.recievedAmount,
      pending: rowData?.pending,
    }));
  };

  const CloseAuditRemark = (val) => {
    setState((prev) => ({ ...prev, isToggleRemarks: val }));
  };

  const onShowTable = (rowData) => {
    let matchedArray = tableState.filter(
      (item) => item.userid === String(rowData.userid)
    );
    setHelpingState((prev) => ({
      ...prev,
      isSwitchTable: 2,
      holdSubDosTableData: matchedArray,
    }));
  };

  const handleAttachments=()=>{
    
  }

  const MainTable = [
    { colName: "User", flexGrow: 1.9, dataKey: "name", rowSpan: 1 },
    {
      colName: `Received Claims`,
      flexGrow: 1.9,
      dataKey: "recievedval",
      rowSpan: 1,
    },
    {
      colName: `Worked Claims`,
      flexGrow: 1.9,
      dataKey: "workedval",
      rowSpan: 1,
    },
    {
      colName: `Pending Claims`,
      flexGrow: 1.9,
      dataKey: "pendingval",
      rowSpan: 1,
    },

    {
      colName: "View Detail",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [
        { label: "D", onClick: onShowTable, btnType: "Audit" },
        { label: "D", onClick: isDosOpen, btnType: "info" },
        { label: "D", onClick: handleAttachments, btnType: "view" },
      ],

      editRow: true,
    },
  ];

  const DosWiseTable = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Payer Type`,
      flexGrow: 1.9,
      dataKey: "PayerType",
      rowSpan: 1,
    },
    {
      colName: `Payer Label`,
      flexGrow: 1.9,
      dataKey: "payerlabel",
      rowSpan: 1,
    },
    {
      colName: `Recieved Claims`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Worked Claims`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },

    {
      colName: `Pending Claims`,
      flexGrow: 1.9,
      dataKey: "pending",
      rowSpan: 1,
    },
    {
      colName: "Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowRemakrs, btnType: "View" }],

      editRow: true,
    },

    // {
    //   colName: `Payer Type`,
    //   flexGrow: 1.9,
    //   dataKey: "PayerType",
    //   rowSpan: 1,
    // },
  ];

  const EobDosWiseTable = [
    { colName: "A/R Payer DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: "A/R Payer",
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: "A/R Worked Claims",
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: "A/R Pending Claims",
      flexGrow: 1.9,
      dataKey: "pending",
      rowSpan: 1,
    },
  ];

  const EraDosWiseTable = [
    {
      colName: "A/R Special Payer Dos ",
      flexGrow: 1.9,
      dataKey: "date",
      rowSpan: 1,
    },
    {
      colName: "A/R Special Payer Received Claims ",
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: "A/R Special Payer Worked Claims",
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: "A/R Special Payer Pending Claims",
      flexGrow: 1.9,
      dataKey: "pending",
      rowSpan: 1,
    },
  ];
  const getRequriedPara=()=>{

  }
  console.log("state.combineRecord", state.combineRecord);

  return (
    <>
      {state.isToggleRemarks == true ? (
        <>
          <ChargeTypeRemarksPop
            flagIndi={flagIndi}
            prevstate={state}
            formNamee={"Account Receivable"}
            practiceId={practiceId}
            date={date}
            CloseAuditRemark={CloseAuditRemark}
            rowDataAudit={helpingStatem.holdSubDosTableData}
          />
        </>
      ) : null}
      <div className="row">
        <div class="col-sm-6 col-lg-12 ">
          {helpingStatem.isSwitchTable == 2 ? (
            <>
              <UserAuditTable
              getRequriedPara={getRequriedPara}
                rowHeight={48}
                // handleChange={handleChange}
                // handleEditState={handleEditState}
                dateForgrid={state.combineRecord ? state.combineRecord : []}
                // isPopupOpen={isPopupOpen}
                Array={DosWiseTable}
                rowShown={5}
                loader={helpingStatem.loading}
              />
            </>
          ) : helpingStatem.isSwitchTable == 3 ? (
            <>
              <UserAuditTable
              getRequriedPara={getRequriedPara}
                rowHeight={48}
                // handleChange={handleChange}
                // handleEditState={handleEditState}
                dateForgrid={state.recordEob ? state.recordEob : []}
                // isPopupOpen={isPopupOpen}
                Array={EobDosWiseTable}
                rowShown={5}
                loader={helpingStatem.loading}
              />

              <UserAuditTable
              getRequriedPara={getRequriedPara}
                rowHeight={48}
                // handleChange={handleChange}
                // handleEditState={handleEditState}
                dateForgrid={state.recordEra ? state.recordEra : []}
                // isPopupOpen={isPopupOpen}
                Array={EraDosWiseTable}
                rowShown={5}
                loader={helpingStatem.loading}
              />
            </>
          ) : helpingStatem.isSwitchTable == 1 ? (
            <UserAuditTable
            getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={tableState}
              // isPopupOpen={isPopupOpen}
              Array={MainTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />
          ) : null}
        </div>
      </div>

      <div className="row">
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        {/* <div class="col-sm-1 col-lg-3 mt-2">
  <div class="form-group ">
    <div className="d-flex justify-content-end mt-4">
      <button
        type="button"
        className="btnClear mr-1 "
        onClick={handleClose}
      >
        Back
      </button>
      
     
    </div>
  </div>
</div> */}
      </div>
    </>
  );
}
