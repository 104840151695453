let array= [
	{
		 NAME: "OMNI Administrators",
		 ID: "91617",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scottsdale Insurance Co E&S",
		 ID: "MLNMI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allstate - NJ Only",
		 ID: "J1550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amica Insurance Company",
		 ID: "45488",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chubb (legacy Chubb & Son) - Auto",
		 ID: "J1082",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chubb (legacy Chubb & Son) - WC",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "​Cure Auto Insurance For NJ Auto",
		 ID: "94302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kentucky Health Administrators, Inc.",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Longevity NY",
		 ID: "C1049",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MetLife New Jersey",
		 ID: "MHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mutual of Omaha Medicare Advantage",
		 ID: "18763",
		 time: "< 30 mins"
	},
	{
		 NAME: "​OSF Healthcare Central",
		 ID: "42049",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OSF Healthcare East",
		 ID: "J3964",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Illinois",
		 ID: "IDWH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Auto Mutual Insurance Company",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "University of Maryland Health Advantage",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "VALLEY PRESBYTERIAN HOSPITAL – HCLA",
		 ID: "00932",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VieCare LIFE Butler",
		 ID: "HTHTX",
		 time: "< 30 mins"
	},
	{
		 NAME: "1199 National Benefit Fund",
		 ID: "48330",
		 time: "< 30 mins"
	},
	{
		 NAME: "1st Auto & Casualty",
		 ID: "21415",
		 time: "> 30 Mins"
	},
	{
		 NAME: "21st Century Health and Benefits",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "21st Century Insurance",
		 ID: "J1479",
		 time: "> 30 Mins"
	},
	{
		 NAME: "3P Admin",
		 ID: "66705",
		 time: "< 30 mins"
	},
	{
		 NAME: "6 Degrees Health, Inc.",
		 ID: "37269",
		 time: "> 30 Mins"
	},
	{
		 NAME: "8th District Elec",
		 ID: "MCDND",
		 time: "> 30 Mins"
	},
	{
		 NAME: "90 Degree Benefits",
		 ID: "16146",
		 time: "> 30 Mins"
	},
	{
		 NAME: "A & I Benefit Plan Administrators",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "A.W. Holdings LLC Dba Benchmark - CORVEL",
		 ID: "16928",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AAA Automobile",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AAA Northern California, Nevada & Utah Insurance Exchange",
		 ID: "77799",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AAG - American Administrative Group",
		 ID: "53120",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AAG Benefit Plan Administrators, Inc.",
		 ID: "JMH01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AARP",
		 ID: "12202",
		 time: "< 30 mins"
	},
	{
		 NAME: "AARP by UnitedHealthcare",
		 ID: "J3941",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AARP Dental Insurance Plan",
		 ID: "77160",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AARP Hospital Indemnity Plans insured by UnitedHealthcare Insurance Company",
		 ID: "82357",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AARP Medicare Supplement Plans insured by UnitedHealthcare Insurance Company",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AARP MedicareComplete insured through UnitedHealthcare (formerly AARP MedicareComplete from SecureHorizons)",
		 ID: "77076",
		 time: "< 30 mins"
	},
	{
		 NAME: "AARP MedicareComplete insured through UnitedHealthcare / Oxford Medicare Network -  AARP MedicareComplete",
		 ID: "35245",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AARP MedicareComplete insured through UnitedHealthcare / Oxford Mosaic Network - AARP MedicareComplete  Mosaic",
		 ID: "51020",
		 time: "< 30 mins"
	},
	{
		 NAME: "ABC Const. Company",
		 ID: "81040",
		 time: "< 30 mins"
	},
	{
		 NAME: "AblePay Health",
		 ID: "04332",
		 time: "< 30 mins"
	},
	{
		 NAME: "ABRAZO Advantage Health Plan",
		 ID: "LNC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Absolute Total Care - Medical",
		 ID: "67524",
		 time: "< 30 mins"
	},
	{
		 NAME: "Acadia Insurance",
		 ID: "62181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Access Administrators",
		 ID: "74284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Access Behavioral Care",
		 ID: "31140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Access Community Health Network",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Access Integra",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "Access IPA",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Access Medical Group",
		 ID: "SMPLY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Access Primary Care Medical Group (APCMG)",
		 ID: "37224",
		 time: "< 30 mins"
	},
	{
		 NAME: "Access Santa Monica",
		 ID: "CCHPL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Acclaim",
		 ID: "RAIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Acclaim IPA",
		 ID: "MCDKS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Acclaim IPA (MHCAC)",
		 ID: "STVMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Accountable Healthcare IPA (AHCIPA)",
		 ID: "HCS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Accuride Corporation",
		 ID: "41206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ace Property & Casualty Ins Co",
		 ID: "CB186",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ACIG Insurance Company",
		 ID: "SWHNY",
		 time: "< 30 mins"
	},
	{
		 NAME: "ACS Benefit Services, Inc.",
		 ID: "UNINW",
		 time: "< 30 mins"
	},
	{
		 NAME: "ACTIN Care Groups / Clifton Health Systems",
		 ID: "SB580",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Active Care (UCS)",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Active Care, Inc",
		 ID: "29076",
		 time: "< 30 mins"
	},
	{
		 NAME: "ActivHealthCare",
		 ID: "J1638",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Activia Benefit Services",
		 ID: "MCDMT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Acuity, A Mutual Insurance Company",
		 ID: "23243",
		 time: "< 30 mins"
	},
	{
		 NAME: "AD-COMP - CORVEL",
		 ID: "CAPHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Adelanto Correctional Facility (CA) - CORVEL",
		 ID: "VS402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Administaff",
		 ID: "12967",
		 time: "< 30 mins"
	},
	{
		 NAME: "Administration Systems Research Corp",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Administration Systems Research Health Benefits",
		 ID: "16003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Administrative Claim Service, Inc.",
		 ID: "AMM13",
		 time: "< 30 mins"
	},
	{
		 NAME: "Administrative Concepts Inc.",
		 ID: "59065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Administrative Services Inc",
		 ID: "MCDMO",
		 time: "< 30 mins"
	},
	{
		 NAME: "AdminOne",
		 ID: "33033",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advance America Cash Advance Centers - CORVEL",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advance Services, Inc.",
		 ID: "37330",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advanced Medical Doctors of CA",
		 ID: "GSMHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advanced Physicians IPA",
		 ID: "31400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advanstaff, Inc.",
		 ID: "HBI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advantage Care IPA",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advantage Health Solutions (Careington)",
		 ID: "GSH01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advantage Medical Group",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advantage Preferred Plus",
		 ID: "46187",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advantek Benefit Administrators (SANTE)",
		 ID: "J1703",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advantica",
		 ID: "13189",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advantica Benefits",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advent Health Medical Corporation",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "ADVENTIST HEALTH CARE NETWORK, INC",
		 ID: "05302",
		 time: "< 30 mins"
	},
	{
		 NAME: "Adventist Health Hanford AHP",
		 ID: "SVIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Adventist Health Plan",
		 ID: "86083",
		 time: "< 30 mins"
	},
	{
		 NAME: "Adventist Health System West",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Adventist White Memorial - Crown City Medical Group",
		 ID: "21825",
		 time: "< 30 mins"
	},
	{
		 NAME: "Adventist White Memorial - Southland San Gabriel Valley",
		 ID: "HLPAE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advisory Health Administrators",
		 ID: "59140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Advocare MCO, Inc",
		 ID: "SC003",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advocate Health Centers",
		 ID: "62599",
		 time: "< 30 mins"
	},
	{
		 NAME: "Advocate Health Partners",
		 ID: "42138",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aegis Administrative Services",
		 ID: "IDIND",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Advantage",
		 ID: "CC16E",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Affordable Health Choices - SRC/Boone",
		 ID: "94134",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health - Parkland (TX)",
		 ID: "UGP19",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health Illinois MMAI",
		 ID: "75280",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of California",
		 ID: "CX100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Florida",
		 ID: "ALLCD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Illinois",
		 ID: "95378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Illinois - Foster Care",
		 ID: "89234",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Illinois (Medicaid)",
		 ID: "31500",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Kansas",
		 ID: "128KS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Kentucky",
		 ID: "IHS02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Louisiana",
		 ID: "ONEHC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Maryland",
		 ID: "38308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Michigan",
		 ID: "34108",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of New Jersey",
		 ID: "MPM36",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of New York",
		 ID: "00403",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Ohio",
		 ID: "59331",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Pennsylvania (Medicaid)",
		 ID: "MPM47",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Texas",
		 ID: "SIM02",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of Virginia",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Better Health of Virginia - CCC Plus",
		 ID: "97041",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Better Health of West Virginia",
		 ID: "54154",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Choice Plans",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna DMO",
		 ID: "HPN11",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Encounters",
		 ID: "98022",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Health, Inc.",
		 ID: "77036",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Healthcare",
		 ID: "59065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna HealthFund",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Life & Casualty Company",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Life Insurance Company",
		 ID: "10322",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Medicaid Plans",
		 ID: "00923",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Medicare",
		 ID: "83148",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Senior Plans",
		 ID: "26224",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Senior Supplemental",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna Signature Administrators (ASA)",
		 ID: "SHC30",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aetna Student Health",
		 ID: "PCCMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna TX Medicaid and CHIP",
		 ID: "34009",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aetna US Healthcare - AUSHC",
		 ID: "60230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Affiliated Doctor's of Orange County (ADOC) (Regal)",
		 ID: "68011",
		 time: "< 30 mins"
	},
	{
		 NAME: "Affiliated Partners IPA",
		 ID: "75240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Affiliated Partners IPA SCAN/CleverCare HP Only",
		 ID: "A0033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Affiliated Physicians IPA",
		 ID: "KYCS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Affinity Health Plan",
		 ID: "14163",
		 time: "< 30 mins"
	},
	{
		 NAME: "Affinity Health Plan Essential",
		 ID: "MPM22",
		 time: "< 30 mins"
	},
	{
		 NAME: "Affinity Medical Group",
		 ID: "3833A",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Affordable Benefit Administrators, Inc.",
		 ID: "35198",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AFTRA Health & Retirement Funds",
		 ID: "45282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aftra Health Fund",
		 ID: "36347",
		 time: "> 30 Mins"
	},
	{
		 NAME: "A-G Administrators LLC",
		 ID: "15460",
		 time: "< 30 mins"
	},
	{
		 NAME: "Agency Services Inc.",
		 ID: "MBA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AgeRight Advantage",
		 ID: "89237",
		 time: "< 30 mins"
	},
	{
		 NAME: "AgeWell New York",
		 ID: "TLC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AHF RW",
		 ID: "00801",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AHP Provider Network",
		 ID: "J1750",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AHRMA (IL only) - CORVEL",
		 ID: "1925460",
		 time: "< 30 mins"
	},
	{
		 NAME: "AIDS Healthcare Foundation",
		 ID: "4YC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AIG",
		 ID: "25916",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aither Health",
		 ID: "KOVA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "AKM Medical Group",
		 ID: "35092",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alameda Alliance for Health",
		 ID: "34196",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alamitos IPA ",
		 ID: "IHS02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alarm Contractors",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alaska Children''s Services, Inc. Group #P68",
		 ID: "51020",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alaska Electrical Health & Welfare Fund",
		 ID: "23550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alaska Laborers Construction Industry Trust Group # F23",
		 ID: "81883",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alaska National Insurance",
		 ID: "27277",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alaska Pipe Trades Local 375 Group # F24",
		 ID: "77104",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alaska UFCW Health & Welfare Trust Group # F45",
		 ID: "06603",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Albuquerque Public Schools",
		 ID: "88067",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alcoa Fasteners Systems",
		 ID: "93222",
		 time: "< 30 mins"
	},
	{
		 NAME: "ALICARE",
		 ID: "MR084",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Align Senior Care (FL)",
		 ID: "22603",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Align Senior Care (MI)",
		 ID: "36582",
		 time: "< 30 mins"
	},
	{
		 NAME: "Align Senior Care (VA)",
		 ID: "SB690",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aligned Community Physicians",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alignis",
		 ID: "78521",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alignment Health Care (Fee For Service)",
		 ID: "38265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alignment Health Plan (Fee For Service)",
		 ID: "HVPNI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alivi Health",
		 ID: "CDCR1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alivi Therapy Network",
		 ID: "96436",
		 time: "< 30 mins"
	},
	{
		 NAME: "ALL American Medical Group",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "All Care Medical Group",
		 ID: "65085",
		 time: "< 30 mins"
	},
	{
		 NAME: "All United Medical Group",
		 ID: "22251",
		 time: "< 30 mins"
	},
	{
		 NAME: "All United Medical Group Clever Care HP Only",
		 ID: "67011",
		 time: "< 30 mins"
	},
	{
		 NAME: "AllCare CCO Dental",
		 ID: "04298",
		 time: "< 30 mins"
	},
	{
		 NAME: "AllCare IPA",
		 ID: "J1551",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AllCare PACE",
		 ID: "SCAN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "AllCare PEBB",
		 ID: "60801",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allegeant",
		 ID: "50114",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allegiance Benefit Plan Management Inc.",
		 ID: "52103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allegiance Health Management",
		 ID: "66001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allen Canning",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alliance Behavioral Health",
		 ID: "J1890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alliance Coal Health Plan",
		 ID: "00324",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alliance Health Systems",
		 ID: "SOIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alliance Healthcare - Stones River Regional IPA",
		 ID: "10259",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alliance Physicians High Desert",
		 ID: "J1437",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alliance Physicians Medical Group",
		 ID: "HPSM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alliant Energy",
		 ID: "37284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alliant Health Plans of Georgia",
		 ID: "64071",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allianz ",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allianz Resolution Management (ARM) ",
		 ID: "82288",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allied Benefit Systems, Inc.",
		 ID: "13202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allied Claims Administration, Inc.",
		 ID: "IEHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allied Healthcare",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allied Pacific of California",
		 ID: "43259",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allied Physicians Medical Group",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allied Property and Casualty Ins Co",
		 ID: "47171",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allina Health Aetna",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Allmerica Financial Benefits Insurance Company",
		 ID: "FRBLU",
		 time: "< 30 mins"
	},
	{
		 NAME: "Allstate Insurance",
		 ID: "MCDMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "AL-MED HCLA",
		 ID: "53226",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aloha Care",
		 ID: "J3791",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alpha Care Medical Group NMM",
		 ID: "RPPG1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alta Bates Medical Group",
		 ID: "37330",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ALTA Health & Life Insurance",
		 ID: "81400",
		 time: "< 30 mins"
	},
	{
		 NAME: "AltaMed",
		 ID: "09302",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alternative Opportunities",
		 ID: "NEIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Alterwood Advantage",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Altius Health Plan",
		 ID: "86057",
		 time: "< 30 mins"
	},
	{
		 NAME: "Altus Dental",
		 ID: "66011",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Alvey Systems (AON Risk)",
		 ID: "128MI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amada Health",
		 ID: "66775",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amada Health South",
		 ID: "00210",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amalgamated Insurance Company",
		 ID: "11402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amalgamated Life",
		 ID: "MP340",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amalgamated Life IPA Alicare",
		 ID: "20133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ambetter of Arkansas - Medical",
		 ID: "CB637",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ambetter of Missouri - Medical",
		 ID: "S9637",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ambetter of New Hampshire - Medical",
		 ID: "84555",
		 time: "< 30 mins"
	},
	{
		 NAME: "AMCO Ins Co",
		 ID: "CCHPC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ameriben Solutions, Inc.",
		 ID: "62308      ",
		 time: "< 30 mins"
	},
	{
		 NAME: "America First ",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Americaid Community Care",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Administrative Group",
		 ID: "RGA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Airlines",
		 ID: "J1365",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Behavioral",
		 ID: "HDNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Claims Management (ACM)",
		 ID: "12399",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Coin Merchandising, Inc.",
		 ID: "J1546",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Community Mutual",
		 ID: "82288",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Country Insurance Company / American Service Insurance Company, Inc.",
		 ID: "J1232",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Cyanamid ",
		 ID: "25172",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Family Health Providers",
		 ID: "13337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Family Insurance",
		 ID: "88848",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Family Mutual Insurance Co",
		 ID: "ASHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Family PPO Policies",
		 ID: "RIOS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "American General Life & Accident",
		 ID: "15991",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Health Advantage of FL\t",
		 ID: "13334",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Health Advantage of MS",
		 ID: "STAR1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Health Advantage of TN",
		 ID: "50443",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Health Advantage of TX\t",
		 ID: "00329",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Health Advantage of UT\t",
		 ID: "72126",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Healthcare Alliance",
		 ID: "EIPA8",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Home Craft",
		 ID: "81949",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Insurance Company of Texas",
		 ID: "25169",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Liberty Insurance Company",
		 ID: "77950",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Life Care",
		 ID: "39033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Medical Security",
		 ID: "47165",
		 time: "< 30 mins"
	},
	{
		 NAME: "AMERICAN MEDICAL SECURITY (AMS)                                         ",
		 ID: "00801",
		 time: "< 30 mins"
	},
	{
		 NAME: "American National",
		 ID: "AUMG2",
		 time: "< 30 mins"
	},
	{
		 NAME: "American National Insurance Co.",
		 ID: "PTX01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Plan Administrators",
		 ID: "37253",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Postal Workers Union (APWU)",
		 ID: "EOCCO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Primary Care Alliance IPA ",
		 ID: "07689",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Public Life (APL)",
		 ID: "46045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Republic Insurance",
		 ID: "LDP12",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Republic Insurance Company (ARIC)",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Specialty Companies ",
		 ID: "VB001",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Specialty Health Plan",
		 ID: "PASSE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Specialty Ins. / MIB",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "American Therapy Association (ATA) - Florida",
		 ID: "90001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Trust Administrators, Inc.",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American West Health Care Solution",
		 ID: "CCHPL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "American Worker Health Plan",
		 ID: "84324",
		 time: "> 30 Mins"
	},
	{
		 NAME: "America's Choice Healthplan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "America's Health Choice",
		 ID: "J3937",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Americas Health Plan",
		 ID: "PH001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "America's PPO/America's TPA",
		 ID: "SX107",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriChoice - New York Medicaid",
		 ID: "65894",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriChoice - Pennsylvania Medicaid",
		 ID: "J3876",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriChoice of New Jersey",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "Americo IPA",
		 ID: "66012",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerigroup Community Care",
		 ID: "41147",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerigroup Corporation",
		 ID: "MCDAL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerigroup of Nevada",
		 ID: "15004",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerigroup of Nevada ",
		 ID: "J3738",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerigroup of Nevada (Unicare)",
		 ID: "EXC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Administrator",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Administrators",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Caritas / VIP Care",
		 ID: "128VA",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Caritas / VIP Care Plus - Michigan",
		 ID: "81434",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Caritas Delaware",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Caritas District of Columbia",
		 ID: "00601",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Caritas Healthplan New Hampshire",
		 ID: "QCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Caritas Louisiana",
		 ID: "42137",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Caritas Next",
		 ID: "25831",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Caritas North Carolina",
		 ID: "13010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Caritas NorthEast",
		 ID: "72099",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerihealth Caritas Northeast ",
		 ID: "36575",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerihealth Caritas Ohio Medicaid",
		 ID: "MCDID",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Caritas Ohio Medicaid (Radiology Only)",
		 ID: "128WV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerihealth Caritas Pennsylvania",
		 ID: "BLUEC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerihealth Caritas Pennsylvania ",
		 ID: "DAKCH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amerihealth Casualty Services",
		 ID: "19432",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Delaware - HMO/POS",
		 ID: "J3959",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Delaware (Non-HMO)",
		 ID: "J1025",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth Mercy Health Plan",
		 ID: "C1053",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AmeriHealth New Jersey - HMO/POS",
		 ID: "TREST",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth New Jersey (Non-HMO)",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmeriHealth Pennsylvania - HMO/POS/ERISA",
		 ID: "95433",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ameriprise",
		 ID: "87098",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ameriprise Auto & Home Insurance",
		 ID: "SCACO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerisafe",
		 ID: "12287",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerisafe Risk Services ",
		 ID: "00923",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amerisure Insurance",
		 ID: "28680",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ameritas Life Insurance Corp.",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmFed Companies LLC (Amfed National Insurance)",
		 ID: "31172",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amfirst Insurance Company",
		 ID: "71065",
		 time: "< 30 mins"
	},
	{
		 NAME: "AMG IPA - Ava Medical Group",
		 ID: "DX101",
		 time: "< 30 mins"
	},
	{
		 NAME: "AmGuard Insurance Company - Guard DBA",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amica Mutual Insurance Company",
		 ID: "84105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Amida Care",
		 ID: "06294",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AMPS",
		 ID: "AWNY6",
		 time: "< 30 mins"
	},
	{
		 NAME: "AMPS - CX",
		 ID: "75233",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AMPS America",
		 ID: "66775",
		 time: "< 30 mins"
	},
	{
		 NAME: "Amtrust Insurance Company of Kansas",
		 ID: "52193",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AMVI - Prospect Health Network",
		 ID: "SCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "AMVI Medical Group",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anaheim Memorial IPA (MHCAN)",
		 ID: "HPSM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "ANCHOR BENEFIT CONSULTING ",
		 ID: "FH205",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anchor Benefit Consulting Inc.",
		 ID: "SX107",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anchor Glass Container Corporation",
		 ID: "J2277",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ancillary Benefit Systems - Arizona Foundation for Medical Care",
		 ID: "PCU01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Angel Medical Group",
		 ID: "76048",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Angeles IPA",
		 ID: "77052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Angle Insurance Company of Utah",
		 ID: "MHPMI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anheuser Busch",
		 ID: "MPM65",
		 time: "< 30 mins"
	},
	{
		 NAME: "Antares Management Solutions",
		 ID: "16565",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Antelope Valley Medical Associates (Regal)",
		 ID: "J1701",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Blue Cross CA Encounters",
		 ID: "10145",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Blue Cross of California (BCCA)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Dental (P.O. Box 1115 MN)",
		 ID: "MCDIL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anthem HealthKeepers Plus",
		 ID: "J1633",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Healthy Indiana Plan (HIP)",
		 ID: "34525",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Hoosier HealthWise",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Medicaid - Indiana",
		 ID: "MCDLA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Medicaid - South Carolina",
		 ID: "J1462",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem Medicaid - Wisconsin",
		 ID: "UHC-OHMCD-DNTL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Anthem Medicaid of Ohio",
		 ID: "31059",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem NV Medicaid",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Anthem OMHS",
		 ID: "J1568",
		 time: "> 30 Mins"
	},
	{
		 NAME: "APAC (Pinnacle Health Resources)",
		 ID: "VVMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Apex Benefit Services",
		 ID: "MPM09",
		 time: "< 30 mins"
	},
	{
		 NAME: "Apostrophe",
		 ID: "PEF01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AppleCare Medical Management",
		 ID: "00950",
		 time: "> 30 Mins"
	},
	{
		 NAME: "APWU Health Plan",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ARAZ",
		 ID: "251CC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arbor Health Plan",
		 ID: "J3762",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ARC Administrators",
		 ID: "MCDLA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arcadia Healthcare Solutions - IPG",
		 ID: "59065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arcadia Healthcare Solutions - NPA",
		 ID: "27008",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arcadia Methodist IPA",
		 ID: "54771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arcadian Management Services",
		 ID: "76255",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arch Health Partners/Centre for Healthcare",
		 ID: "81400",
		 time: "< 30 mins"
	},
	{
		 NAME: "ArchCare Advantage",
		 ID: "NCHUR",
		 time: "< 30 mins"
	},
	{
		 NAME: "ARCMI Claims Management",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Areas USA, Inc.",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Argonaut Insurance Company (ARGO)",
		 ID: "00806",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Argus Dental Plans",
		 ID: "MPM36",
		 time: "< 30 mins"
	},
	{
		 NAME: "Argus Vision",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arise Health Plan (WPS)",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arizona Complete Health (formerly Health Net Access)",
		 ID: "ACCOM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arizona Foundation for Medical Care",
		 ID: "54084",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arizona Priority Care Plus",
		 ID: "37314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arkansas Superior Select",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ARM, Ltd",
		 ID: "59299",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arnett Health Plan",
		 ID: "WITH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arnot Ogden Medical Center (UCS)",
		 ID: "75261",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arrowhead Regional Medical Center",
		 ID: "U1439",
		 time: "< 30 mins"
	},
	{
		 NAME: "Arrowpoint",
		 ID: "ASVA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Arroyo Vista Family Health Center",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ascension Complete",
		 ID: "BTSS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Asian American Medical Group",
		 ID: "HLPBC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Asian Community Medical Group, Inc.",
		 ID: "MHHNP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ASONET",
		 ID: "USN01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aspen Medical Associates",
		 ID: "34158",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Aspire Health Plan",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ASRM LLC",
		 ID: "C1087",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Associated Dignity Medical Group Professional Corp",
		 ID: "32002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Associated Hispanic Physicians of Southern California",
		 ID: "81949",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Associated Hyspanic Physicians",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Associates for Health Care, Inc. (AHC)",
		 ID: "12807",
		 time: "< 30 mins"
	},
	{
		 NAME: "Assurant Employee Benefits (Now Sun Life)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Assurant Health Self-Funded (Allied Benefit)",
		 ID: "88027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Assured Benefits Administrators",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Astiva Health",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Astiva Health Encounters",
		 ID: "HCI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Asuris Northwest Health",
		 ID: "35202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Athens",
		 ID: "3519M",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Atlantic Dental Inc",
		 ID: "J1983",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Atlantic Medical",
		 ID: "SJMM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Atlantic PPO to Mercury Insurance - Auto",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Atlantis Eyecare",
		 ID: "35174",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Atlas Life Ins Co (NFIC Plan Only)",
		 ID: "SX158",
		 time: "< 30 mins"
	},
	{
		 NAME: "Atrio Health Plans",
		 ID: "45399",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AudioNet - National Elevator",
		 ID: "83383",
		 time: "< 30 mins"
	},
	{
		 NAME: "Audionet - UAW Ford Retirees/GM Employees (AUDIOSVS)",
		 ID: "38261",
		 time: "< 30 mins"
	},
	{
		 NAME: "Audionet - UAW GM retirees/Chrysler Emps & Retirees (AUDIOABS)",
		 ID: "11303",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AudioNet America (AUDIOCS)",
		 ID: "80141",
		 time: "< 30 mins"
	},
	{
		 NAME: "AultCare/PrimeTime Health Plan",
		 ID: "88058",
		 time: "< 30 mins"
	},
	{
		 NAME: "Aultra Administrative Group",
		 ID: "J1530",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Austin Mutual Insurance",
		 ID: "PASSE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto Club Insurance Association",
		 ID: "86049",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto One Insurance",
		 ID: "MR010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto Owners Insurance Company",
		 ID: "83413",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Automated Benefit Services",
		 ID: "95885",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Automated Group Administration Inc. (AGA)",
		 ID: "HPOUV",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Automobile Dealers Insurance Company Inc",
		 ID: "50383",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto-Owners \\ Home-Owners Insurance Company - CORVEL",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto-Owners \\ Owners Insurance Company - CORVEL",
		 ID: "NM505",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto-Owners \\ Property-Owners Insurance Co - CORVEL",
		 ID: "A0179",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto-Owners \\ Southern-Owners Company - CORVEL",
		 ID: "00661",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auto-Owners Life Insurance Company - CORVEL",
		 ID: "J1730",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Auxiant",
		 ID: "87347",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Avalon Administrative Services - BCBSNC",
		 ID: "38692",
		 time: "< 30 mins"
	},
	{
		 NAME: "Avalon Healthcare Solutions",
		 ID: "23249",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Avalon Healthcare Solutions Capital Blue Cross",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Avalon IPA",
		 ID: "41194",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Avante Health",
		 ID: "DDUT1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Avera Health Plans",
		 ID: "VAPRM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Avera Health Plans for John Morrell",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Avesis (Peach State Health Plan)",
		 ID: "KMG11",
		 time: "< 30 mins"
	},
	{
		 NAME: "Avesis Third Party Administrator",
		 ID: "60495",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AvMed Health Plans",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "AvMed, Inc.",
		 ID: "J1440",
		 time: "> 30 Mins"
	},
	{
		 NAME: "AXA Assistance_USA",
		 ID: "80314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Axminster Medical Group",
		 ID: "85664",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ayers Trucking",
		 ID: "76342",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BadgerCare Plus (CCHP)",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bakersfield Family Medical Center (BFMC) (Regal)",
		 ID: "AC101",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bakery and Confectionery Union and Industry International Health",
		 ID: "EHPSC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Balance Staffing Workforce",
		 ID: "43185",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Baldwin & Lyons, Inc. (Previous Name Protective Insurance Co) - CORVEL",
		 ID: "31405",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bank of America - CORVEL",
		 ID: "ADOCS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bankers Life (ERA Only)",
		 ID: "74323",
		 time: "< 30 mins"
	},
	{
		 NAME: "Banner Health and Aetna Health Insurance Company",
		 ID: "42011",
		 time: "< 30 mins"
	},
	{
		 NAME: "Banner Health Company",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Banner Medicare Advantage Plus PPO",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Banner Medicare Advantage Prime HMO",
		 ID: "CPNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Banner UFC ALTCS",
		 ID: "87452",
		 time: "< 30 mins"
	},
	{
		 NAME: "Baptist Health South Florida",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Baptist Healthcare Systems, Inc - CORVEL",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "BARInet",
		 ID: "SVIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Barnes",
		 ID: "SC050",
		 time: "< 30 mins"
	},
	{
		 NAME: "Barrett Business Services Inc (BBSI)",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Basic Plus (TCC)",
		 ID: "SHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bass Administrators, Inc.",
		 ID: "23243",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bass Pro Group, LLC - CORVEL",
		 ID: "SCAN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bay Bridge Administrators, LLC",
		 ID: "28580",
		 time: "< 30 mins"
	},
	{
		 NAME: "Baycare Life Management",
		 ID: "EXC02",
		 time: "< 30 mins"
	},
	{
		 NAME: "BayCare Select Health Plans Inc",
		 ID: "VMMH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS Empire New York",
		 ID: "AMBLA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS Medicaid Ohio (Anthem)",
		 ID: "15976",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS Mountain States",
		 ID: "10339",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Hawaii",
		 ID: "HLPHN",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Illinois",
		 ID: "HPIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Iowa",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS of Kansas City",
		 ID: "84389",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Louisiana",
		 ID: "PCCMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Mississippi",
		 ID: "02027",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Montana",
		 ID: "84389",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Nebraska",
		 ID: "MCDMA",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of New Mexico",
		 ID: "J3908",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS of New York (Central)",
		 ID: "CPNA1 ",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of New York (Rochester Area)",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of New York (Utica-Watertown)",
		 ID: "TLINS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS of North Carolina",
		 ID: "GOBHI",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Oklahoma",
		 ID: "CDKY1",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Rhode Island",
		 ID: "35206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS of South Carolina",
		 ID: "CH101",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of South Dakota",
		 ID: "PROV1",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Tennessee",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Texas",
		 ID: "96385",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS of Vermont",
		 ID: "89789",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of West Virginia",
		 ID: "85664",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS of Wyoming",
		 ID: "84099",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS South Carolina (FEP - Federal Employee Plan)",
		 ID: "62956",
		 time: "< 30 mins"
	},
	{
		 NAME: "BCBS South Carolina (Preferred Blue)",
		 ID: "LNSTR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS Virgin Islands",
		 ID: "HLTHT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS Western New York Medicaid/CHP",
		 ID: "13383",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBS Wisconsin (Anthem) ",
		 ID: "CX025",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCBSMN Blue Plus Medicaid",
		 ID: "APP01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BCN - NON JVHL Network - JVHL",
		 ID: "24740",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beacon Health Options (Empire Plan Mental Health)",
		 ID: "19191",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beacon Health Options (formerly Value Options)",
		 ID: "58169",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beacon Health Options (General Motors)",
		 ID: "67788",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beacon Mutual",
		 ID: "12X42",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beacon of Life (AcuteCare)",
		 ID: "CAS89",
		 time: "< 30 mins"
	},
	{
		 NAME: "Beall's Inc",
		 ID: "16644",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beaver Medical Group",
		 ID: "MVMM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bed Bath & Beyond - CORVEL",
		 ID: "71065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Behavioral Health Systems",
		 ID: "80314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Belk, Inc. - CORVEL",
		 ID: "48330",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bella Vista Medical Group",
		 ID: "BCTF1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benchmark Insurance",
		 ID: "67829",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BeneBay",
		 ID: "18049",
		 time: "< 30 mins"
	},
	{
		 NAME: "BeneCare Dental Plan",
		 ID: "RR3426",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benefit & Risk Management Services (BRMS)",
		 ID: "J1914",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Administration Services LTD (BAS LTD)",
		 ID: "81400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Administrative Systems (BAS Health)",
		 ID: "J1728",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Coordinators Corporation",
		 ID: "77016",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Management Admin (BMA)",
		 ID: "AMM17",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benefit Management Group-NV",
		 ID: "15243",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Management Inc - Joplin Claims",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Management Services",
		 ID: "LWA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Management Services of Louisiana",
		 ID: "84105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Plan Administrators",
		 ID: "CREA8",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benefit Plan Administrators, Inc.",
		 ID: "88250",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benefit Plan Services",
		 ID: "84320",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benefit Systems & Services, Inc. (BSSI)",
		 ID: "MPM26",
		 time: "< 30 mins"
	},
	{
		 NAME: "BeneSys Inc.",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benveo",
		 ID: "79048",
		 time: "< 30 mins"
	},
	{
		 NAME: "Benveo - First Health Network",
		 ID: "MR002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Benveo - MultiPlan",
		 ID: "SIL01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Berkley Industrial Comp",
		 ID: "84980",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Berkley Mid-Atlantic Group",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Berkley National Insurance Company",
		 ID: "77052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Berkley Net Underwriters, LLC",
		 ID: "J3912",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Berkley Regional Insurance Company",
		 ID: "42139",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Berkley Risk Administrators Company, LLC",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Berkley Specialty Underwriters",
		 ID: "MSO11",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Berkshire Hathaway Direct Insurance Company",
		 ID: "36338",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Berkshire Hathaway Homestate Companies (Member of BHHC)",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Berwind Corporation ",
		 ID: "23281",
		 time: "< 30 mins"
	},
	{
		 NAME: "Best Life & Health Insurance Company",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Better Health",
		 ID: "CX060",
		 time: "< 30 mins"
	},
	{
		 NAME: "Better Health of Florida",
		 ID: "45282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beverly Alianza IPA",
		 ID: "MC051",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Beverly Hospital - Imperial Health Holdings",
		 ID: "HPOUV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Beverly Hospital BEVAHISP",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BIENVIVIR",
		 ID: "EHPSC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bind (PreferredOne)",
		 ID: "71404",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bituminous Fire and Marine",
		 ID: "04326",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Black Hawk",
		 ID: "J3961",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Block Vision - Eye Specialists",
		 ID: "00265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Block Vision - VIPA",
		 ID: "10029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Block Vision Inc.",
		 ID: "WVCHD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Block Vision of Texas",
		 ID: "53120",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Advantage",
		 ID: "56731",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Benefit Administrators of MA",
		 ID: "29076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Card Program",
		 ID: "27048",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Care Family Plan",
		 ID: "MBA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Care Network Advantage of Michigan",
		 ID: "CPPCA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Care Network of Michigan",
		 ID: "J3329",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Choice of South Carolina",
		 ID: "89244",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Choice of South Carolina (Medicaid)",
		 ID: "CCIH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross (Cal-Optima - Medi-Cal)",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield Arkansas",
		 ID: "128WV",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield New Hampshire",
		 ID: "20501",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Alabama",
		 ID: "22248",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Arizona",
		 ID: "75318",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Arizona Advantage (formerly Banner Medisun)",
		 ID: "80314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Arkansas",
		 ID: "J3352",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Colorado",
		 ID: "11370",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Colorado (Anthem)",
		 ID: "128VA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Colorado (Trigon)",
		 ID: "SWHMA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Connecticut",
		 ID: "05003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Connecticut (Anthem)",
		 ID: "FCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of DC (Carefirst)",
		 ID: "MCS03",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Delaware",
		 ID: "57254",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Delaware (UCCI)",
		 ID: "EPF03",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Florida",
		 ID: "45967",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of GA - Federal",
		 ID: "00922",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Georgia",
		 ID: "95444",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Georgia (Anthem)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Hawaii (HMSA)",
		 ID: "FS802",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Illinois",
		 ID: "ASHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Indiana (Anthem)",
		 ID: "J1988",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Iowa",
		 ID: "SC027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Kansas",
		 ID: "CKIL1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Kansas City",
		 ID: "93024",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Kentucky (Anthem)",
		 ID: "38692",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Louisiana",
		 ID: "60054",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Louisiana Advantage Plus Network",
		 ID: "MRIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Maine",
		 ID: "CCMMH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Maine (Anthem)",
		 ID: "39180",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Maryland (CareFirst )",
		 ID: "59266",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Massachusetts",
		 ID: "91313",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Michigan",
		 ID: "SH777",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Minnesota",
		 ID: "22384",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Mississippi",
		 ID: "J3877",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Missouri (Anthem)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Montana",
		 ID: "27357",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Nebraska",
		 ID: "48145",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Nevada (Anthem)",
		 ID: "V360H",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New Hampshire (Anthem)",
		 ID: "65088",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New Mexico",
		 ID: "LOBH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New York - Excellus (Central Region)",
		 ID: "TREST",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New York - Excellus (Rochester Region)",
		 ID: "00760",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New York - Excellus (Utica Region)",
		 ID: "56132",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of New York - Excellus (Utica Watertown Region)",
		 ID: "VAC45",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of North Carolina",
		 ID: "IP079",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of North Dakota",
		 ID: "56756",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Ohio (Anthem)",
		 ID: "DOCSO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Oklahoma",
		 ID: "22771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Oregon (Regence)",
		 ID: "01066",
		 time: "> 1 hours"
	},
	{
		 NAME: "Blue Cross Blue Shield of Pennsylvania (Highmark)",
		 ID: "CX101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Rhode Island",
		 ID: "65062",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of South Carolina",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of South Carolina (State Health Plan)",
		 ID: "PAOH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of South Dakota",
		 ID: "00611",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Tennessee",
		 ID: "31404",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Texas",
		 ID: "CSSD2",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Texas - Medicaid",
		 ID: "MPM44",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Utah (Regence)",
		 ID: "59064",
		 time: "> 1 hours"
	},
	{
		 NAME: "Blue Cross Blue Shield of Vermont",
		 ID: "HVMG1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Virginia - Trigon",
		 ID: "TRP1E",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Virginia (Anthem)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Western New York",
		 ID: "37215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Western New York - Federal Employee Program (FEP)",
		 ID: "ALTAM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Wisconsin (Anthem)",
		 ID: "88337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Wisconsin FEP (Anthem)",
		 ID: "C1090",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Blue Shield of Wyoming",
		 ID: "95827",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Community Centennial (BCBS NM)",
		 ID: "J1829",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Community ICP / FHP",
		 ID: "96400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Community MMAI",
		 ID: "00430",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Community Options",
		 ID: "77050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Community Options of Illinois",
		 ID: "NCHON",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Complete of Michigan",
		 ID: "29018",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Independence (Pennsylvania)",
		 ID: "DCRSS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Medicare Advantage PPO/HMO",
		 ID: "85729",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of California",
		 ID: "J1900",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of California / Wellpoint",
		 ID: "59144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Colorado",
		 ID: "J3757",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Connecticut",
		 ID: "36459",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Georgia",
		 ID: "14829",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Idaho",
		 ID: "56190",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Illinois",
		 ID: "53589",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Indiana",
		 ID: "11302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Kentucky",
		 ID: "83352",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Maine",
		 ID: "13102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Missouri",
		 ID: "7707C",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Of Nevada",
		 ID: "MPM29",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross Of New Hampshire",
		 ID: "CAPMN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of New Jersey",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Ohio",
		 ID: "SB690",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Pennsylvania",
		 ID: "STJOE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Pennsylvania (Capital Blue / CAIC)",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Cross of Virginia",
		 ID: "66917",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Medicare of North Carolina",
		 ID: "80314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield - California",
		 ID: "J2331",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield - California / Blue Shield (FEP)",
		 ID: "00246",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of California",
		 ID: "SLRT1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of California (P.O. Box 272540)",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of California (P.O. Box 272590)",
		 ID: "MDXHI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of California Promise Health Plan",
		 ID: "MRIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of California Promise Health Plan Encounters",
		 ID: "15102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of Idaho (Regence)",
		 ID: "54096",
		 time: "> 1 hours"
	},
	{
		 NAME: "Blue Shield of Pennsylvania (Camp Hill) Provider will need to register with them and inform them that EDI Health group is their clearinghouse 800-633-5430 opt 0)",
		 ID: "J1571",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Blue Shield of Washington (Regence)",
		 ID: "VALOP",
		 time: "> 1 hours"
	},
	{
		 NAME: "BlueChoice HealthPlan South Carolina Medicaid",
		 ID: "DAKCH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BlueShield of Northeastern New York",
		 ID: "78857",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BMC HealthNet Plan",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "BMW North America",
		 ID: "AAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Boilermakers National Health & Welfare Fund",
		 ID: "BHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Boler Company",
		 ID: "48123",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Boncura Health Solutions",
		 ID: "BCSVT",
		 time: "< 30 mins"
	},
	{
		 NAME: "BookMD Inc.",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Boon Chapman Administrators, Inc.",
		 ID: "SCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Boston Medical Center Health Plan (BMCHP)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Boulder Administration Services",
		 ID: "MPM22",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brand New Day (FFS)",
		 ID: "04320",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brand New Day Encounters",
		 ID: "128MD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Braven Health",
		 ID: "CALOP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "BreckPoint",
		 ID: "DCMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brickstreet",
		 ID: "128MD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bridgefield Casualty ",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bridgefield Employers ",
		 ID: "77943",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bridgeport Dental Service",
		 ID: "J1585",
		 time: "> 30 Mins"
	},
	{
		 NAME: "BridgeSpan",
		 ID: "CREA8",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bridgeview Company",
		 ID: "J1685",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bridgeway Health Solutions - Medical",
		 ID: "99789",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bright Health Medicare Advantage",
		 ID: "76343",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bright Health Plan",
		 ID: "MMFCS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brighten Health Plan Solutions",
		 ID: "74227",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bristol Park Medical Group",
		 ID: "BC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bristol West",
		 ID: "Z0005",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Broadspire (Auto)",
		 ID: "TKFMC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Broadspire, a Crawford Company",
		 ID: "47165",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brokerage Concepts",
		 ID: "BOONG",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Brookshire IPA ",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brotherhood Mutual Insurance",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Broward County Board of Commissioners (FL)",
		 ID: "SCNMO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Broward Regional Health Planning Council",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brown & Toland Health Services",
		 ID: "EPF06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brown & Toland Medical Group",
		 ID: "WRPNI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brown & Toland Sutter Select",
		 ID: "00902",
		 time: "< 30 mins"
	},
	{
		 NAME: "Brownyard Group Inc. (Arch Insurance)",
		 ID: "J1809",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Brunswick Corporation",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "BSI Companies",
		 ID: "DREYR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Buckeye Community Health (Ambetter) - Medical",
		 ID: "41124",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Buckeye Ohio Medicaid",
		 ID: "37086",
		 time: "< 30 mins"
	},
	{
		 NAME: "Buenaventura Affiliated Physicians INC",
		 ID: "BVAP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Builders Insurance Group (GA only)",
		 ID: "06105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Builders Mutual Insurance",
		 ID: "22175",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bunch and Associates",
		 ID: "88848",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Bureau For Children With Medical Handicaps",
		 ID: "VB002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Bureau Of Prisons-Apprio\t",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Burlington Coat Factory Warehouse ",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Business First ",
		 ID: "COSNM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Businessmens Assurance",
		 ID: "WHLTH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Butler America Holdings, INC.- Corvel - CORVEL",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Butler Benefit",
		 ID: "00590",
		 time: "> 30 Mins"
	},
	{
		 NAME: "C & O Employees Hospital Association",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "C&S Wholesale Grocers ",
		 ID: "BPA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "C. L. Frates and Company",
		 ID: "OLRMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Caduceus Medical Group (Regal)",
		 ID: "66705",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cahaba GBA (Medicare Part A Rural)",
		 ID: "71066",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cal Care IPA",
		 ID: "SC052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cal Care IPA Encounters",
		 ID: "43090",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cal Viva Health",
		 ID: "47027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cal Water (California Water Service)",
		 ID: "77016",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Care (Humboldt Del Norte)",
		 ID: "00504",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Eye Care",
		 ID: "POD1S",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Fair Services Authority",
		 ID: "72148",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Health and Wellness",
		 ID: "J3938",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California Health and Wellness - Medical",
		 ID: "31125",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California Hospital Medical Center",
		 ID: "53085",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California IPA (Capital MSO)",
		 ID: "26337",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Kids Care (CKC)",
		 ID: "IHS06",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Medical Center",
		 ID: "59279",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California Pacific Medical Center (CPMC)",
		 ID: "84146",
		 time: "< 30 mins"
	},
	{
		 NAME: "California Pacific Physicians Medical Group, Inc.",
		 ID: "UHP01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California Rural Indian Health Board (CRIHB) Cares",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "California Rural Indian Health Board (CRIHB) Options",
		 ID: "79966",
		 time: "< 30 mins"
	},
	{
		 NAME: "CalOptima",
		 ID: "12113",
		 time: "< 30 mins"
	},
	{
		 NAME: "CalOptima Direct",
		 ID: "GHAWH",
		 time: "< 30 mins"
	},
	{
		 NAME: "CalOptima LTC",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "CalPortland Company - CORVEL",
		 ID: "13269",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Calvos SelectCare",
		 ID: "J1678",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cambridge Integrated Services",
		 ID: "SC054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cameron Manufacturing & Design, Inc. (UCS)",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Camp Lejeune Family Member Program",
		 ID: "37282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cannon Cochran Management Services",
		 ID: "SCNUN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cano Health California",
		 ID: "39081",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cap Management",
		 ID: "58600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Capistrano Unified School District (CA) - CORVEL",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Capital Blue Cross",
		 ID: "J3926",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capital Blue Cross of Pennsylvania",
		 ID: "YAMHL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capital Dental",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capital District Physicians Health Plan (CDPHP)",
		 ID: "88848",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capital Health Partners",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capital Health Plan",
		 ID: "RP064",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capitol Administrators",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capitol Dental Care WVCH",
		 ID: "10172",
		 time: "< 30 mins"
	},
	{
		 NAME: "Capitol Indemnity",
		 ID: "TLINS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Capitol Insurance Companies - CORVEL",
		 ID: "00932",
		 time: "< 30 mins"
	},
	{
		 NAME: "CapRock Health Plan - Verity",
		 ID: "95567",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CapRock HealthPlans",
		 ID: "26054",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cardiovascular Care Providers",
		 ID: "09908",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care 1st Health Plan - Arizona",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care 1st Health Plan of Arizona",
		 ID: "86047",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care Advantage",
		 ID: "CMHGF",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care Improvement Plus (CIP) / XL Health",
		 ID: "PAOH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Care Logic",
		 ID: "J3868",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Care Management Resources (CMR)",
		 ID: "251CC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care N' Care",
		 ID: "13150",
		 time: "< 30 mins"
	},
	{
		 NAME: "Care Wisconsin Health Plan",
		 ID: "J1417",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Care1st Health Plan of Arizona",
		 ID: "04332",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareATC",
		 ID: "87065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareCentrix",
		 ID: "12T64",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareCore National",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareCore National LLC (Aetna Radiology)",
		 ID: "73275",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareCore/WCNY RAD",
		 ID: "CB987",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareFactor",
		 ID: "71064",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareFirst BCBS (MD, DC)",
		 ID: "CMA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareFirst BCBS Community Health Plan Maryland",
		 ID: "13193",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareFirst BCBS NCA Region",
		 ID: "62324",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareFirst BCBS of Maryland",
		 ID: "89235",
		 time: "< 30 mins"
	},
	{
		 NAME: "Careington Benefit Solutions",
		 ID: "27905",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carelink Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carelon Behavioral Health",
		 ID: "37278",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Caremark WPAS, Inc., Grp# P62",
		 ID: "00923",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareMore Encounters",
		 ID: "99486",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareMore Health Plan",
		 ID: "46891",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareMore Value Plus (CVP)",
		 ID: "MCDID",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareOregon Inc.",
		 ID: "MCDRI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CarePartners",
		 ID: "C1042",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CarePartners of Connecticut",
		 ID: "J1008",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Careplus (Encounters)",
		 ID: "10557",
		 time: "< 30 mins"
	},
	{
		 NAME: "CarePlus Health Plan",
		 ID: "39114",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareSource Arkansas",
		 ID: "98798",
		 time: "< 30 mins"
	},
	{
		 NAME: "Caresource Health Plan of Oregon",
		 ID: "J1970",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareSource Iowa",
		 ID: "23160",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareSource North Carolina",
		 ID: "93158",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareSource of Georgia",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Caresource of Indiana",
		 ID: "25531",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Caresource of Kentucky",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "CareSource of Ohio",
		 ID: "59140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CareSource Ohio Medicaid",
		 ID: "83926",
		 time: "< 30 mins"
	},
	{
		 NAME: "Caresource West Virginia",
		 ID: "39640",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carewest",
		 ID: "23228",
		 time: "< 30 mins"
	},
	{
		 NAME: "Careworks",
		 ID: "COVON",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carisk Behavioral Health",
		 ID: "10533",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cariten Healthcare",
		 ID: "95308",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cariten Senior Health",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carolina Behavioral Health Alliance",
		 ID: "95192",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carolina Care Plan Inc.",
		 ID: "1928772",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carolina Complete",
		 ID: "128KY",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carolina SeniorCare",
		 ID: "20538",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carpenters Health & Welfare (FCHN)",
		 ID: "J1870",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Carpenters Health & Welfare Fund of Philadelphia & Vicinity",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "Carpenters Trust of Western Washington",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Caryn Health",
		 ID: "45275",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CAS Coastal Administrative Services",
		 ID: "35112",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cascade Health Alliance, Inc",
		 ID: "04286",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CASD/Sanford  (Chiropractic Associates of SD)",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Casitas Municipal Water District (CA) - CORVEL",
		 ID: "CLFRS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Castlepoint Insurance Company",
		 ID: "46045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Castlepoint National Insurance Company",
		 ID: "65088",
		 time: "< 30 mins"
	},
	{
		 NAME: "Catholic Mutual",
		 ID: "BHPP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CBA BLUE",
		 ID: "BVES1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CBHNP - HealthChoices",
		 ID: "DDA10",
		 time: "< 30 mins"
	},
	{
		 NAME: "CC-Development Group, Inc. - CORVEL",
		 ID: "BCSMT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CCMSI",
		 ID: "00840",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CDO Technologies",
		 ID: "39144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cedar Valley Community HealthCare (CVCH)",
		 ID: "00680",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cedars Towers Surgical Medical Group  (Encounters Only,  ID Required)",
		 ID: "39069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cedars-Sinai Medical Group (CSHS) - Capitated/Encounters",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cedars-Sinai Medical Group (CSHS) - Fee for Service",
		 ID: "13550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Celtic Insurance",
		 ID: "03699",
		 time: "< 30 mins"
	},
	{
		 NAME: "CeltiCare Health Plan (Ambetter) - Medical",
		 ID: "66055",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cement Masons & Plasterers Health & Welfare Trust Group #F16",
		 ID: "41600",
		 time: "< 30 mins"
	},
	{
		 NAME: "CenCal Health",
		 ID: "20044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cendant Corporation ",
		 ID: "03036",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cenpatico Behavioral Health – All States",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Center Care PPO (Meritain)",
		 ID: "OMN02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Center for Elders Independence (CEI)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Center For Sight",
		 ID: "KCMD1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Center IPA",
		 ID: "WBHCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "CenterLight Healthcare",
		 ID: "COMMF",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Centers Plan for Healthy Living",
		 ID: "00445",
		 time: "< 30 mins"
	},
	{
		 NAME: "Centinela Valley IPA",
		 ID: "CHECS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Centinela Valley IPA Encounters",
		 ID: "38259",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Centivo",
		 ID: "25463",
		 time: "< 30 mins"
	},
	{
		 NAME: "Centivo Direct Network Access ",
		 ID: "91151",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Benefits Life",
		 ID: "DRHCP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Benefits Mutual",
		 ID: "00210",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Benefits National",
		 ID: "22100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central CA Alliance for Health",
		 ID: "C1040",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central California Alliance for Health                                  ",
		 ID: "76045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central California Alliance for Health (CCAH)",
		 ID: "99208",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Coast Alliance Health",
		 ID: "CARMO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Contra Costa Transit Authority - CORVEL",
		 ID: "AMDC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Florida Health",
		 ID: "13193",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Garden & Pet Company - CORVEL",
		 ID: "73866",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Health Medicare Plan",
		 ID: "BCSSC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Health MSO",
		 ID: "31059",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Hockey League ",
		 ID: "J1626",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Pennsylvania Teamsters Fund",
		 ID: "19431",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Reserve Life",
		 ID: "J3839",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Reserve Life Insurance Company (Medicare Supplement)",
		 ID: "32043",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central States Health & Welfare Funds",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "Central Valley Medical Group",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Central Valley Medical Providers CVMEDPRO",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Centurion",
		 ID: "00621",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Centurypho",
		 ID: "ARISE",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHA Health",
		 ID: "77684",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chaffey Joint Union H. S. District (CA) - CORVEL",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chaffey Medical Group",
		 ID: "35186",
		 time: "< 30 mins"
	},
	{
		 NAME: "Champion Chevrolet",
		 ID: "GLOV1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Champion  Solutions",
		 ID: "F6030500",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHAMPVA",
		 ID: "61146",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHAMPVA - HAC",
		 ID: "95044",
		 time: "< 30 mins"
	},
	{
		 NAME: "Charming Shoppes, Inc. ",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CHC Cares of South Carolina",
		 ID: "MPM33",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHC Texas",
		 ID: "GACS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CHEC - A Subsidiary of Sprint",
		 ID: "30799",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cherokee Insurance",
		 ID: "MCDPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cherokee Nation Comprehensive Care Agency",
		 ID: "GHOKC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chesapeake Life Insurance Co.",
		 ID: "GRAVI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chesterfield Services, Inc.",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chesterfield Technologies",
		 ID: "83148",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chicago Transit Authority - CORVEL",
		 ID: "DHFIE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Childhealth Plus by Healthfirst (CHP)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Children First Medical Group",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Children of Women Vietnam Veterans - VA HAC",
		 ID: "GOBHI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Children’s Hospital Orange County (CHOC) Health Alliance",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Children's Medical Alliance",
		 ID: "95885",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Children's Physicians Medical Group (CPMG)",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Children's Specialists of San Diego II",
		 ID: "J1947",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chinese Community Health Plan",
		 ID: "65088",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chireno ISD",
		 ID: "03964",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ChiroCare",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chiropractic Associates of SD (CASD)",
		 ID: "J1586",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chiropractic Care of Minnesota",
		 ID: "MPM60",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chiropractic Health Plans (CHP)",
		 ID: "SAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chiropractic Health Plans (CHP) - Deseret Mutual Benefits Administration",
		 ID: "HCH01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Chiropractic Health Plans (CHP) - Educators Mutual Insurance",
		 ID: "UC002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chiropractic Health Plans (CHP) - Medicaid",
		 ID: "66170",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chiropractic Health Plans (CHP) - University of Utah Health Plans",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHOC Health Alliance",
		 ID: "3414A",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Medical Group",
		 ID: "00805",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Physicians Net CoachellaMed ",
		 ID: "36149",
		 time: "< 30 mins"
	},
	{
		 NAME: "Choice Physicians Net First Choice",
		 ID: "37281",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Physicians Network",
		 ID: "J1036",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Physicians Network - Amada Health",
		 ID: "J1477",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Physicians Network All United Medical Group",
		 ID: "38219",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Choice Physicians Network Nivano Physicians",
		 ID: "128FL",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHORUS Community Health Plan (CCHP)",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Christian Brothers Services",
		 ID: "77076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Christian Care Ministries (MediShare)",
		 ID: "MPM29",
		 time: "< 30 mins"
	},
	{
		 NAME: "Christiana Care VBR",
		 ID: "J3746",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Christus Health Medicare Advantage",
		 ID: "85016",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Christus Health New Mexico HIX",
		 ID: "DX017",
		 time: "< 30 mins"
	},
	{
		 NAME: "Christus Health Plan",
		 ID: "WITH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "CHRISTUS Health Plan Texas HIX",
		 ID: "75228",
		 time: "< 30 mins"
	},
	{
		 NAME: "Christus Spohn Health Plan",
		 ID: "SPOHN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Chubb Services",
		 ID: "251VA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Church Mutual Insurance Company",
		 ID: "EPNSH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Church Mutual Insurance Company (Auto)",
		 ID: "84041",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CIGNA",
		 ID: "11752",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA - PPO",
		 ID: "98798",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA For Seniors",
		 ID: "60509",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA Health Plan",
		 ID: "45281",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CIGNA HealthCare",
		 ID: "J1204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CIGNA Healthcare for Seniors (Medicare)",
		 ID: "60818",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cigna Healthspring",
		 ID: "03036",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cigna HealthSpring (Bravo)",
		 ID: "98798",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA PPA",
		 ID: "25531",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA Premier Plus",
		 ID: "00210",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIGNA Private",
		 ID: "SYMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "CIM Insurance Corporation",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cincinnati Insurance",
		 ID: "95444",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Citizens Choice Health Plan (Fee For Service)",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "Citizens Insurance Company of America",
		 ID: "56116",
		 time: "< 30 mins"
	},
	{
		 NAME: "Citizens of Humanity - CORVEL",
		 ID: "12402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Citrus Health Plan",
		 ID: "VCHCP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Citrus Memorial Health Foundation Inc.",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Citrus Valley Health Partners",
		 ID: "31059",
		 time: "< 30 mins"
	},
	{
		 NAME: "Citrus Valley IPA",
		 ID: "88030",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Citrus Valley Physicians Group (CVPG)",
		 ID: "83926",
		 time: "< 30 mins"
	},
	{
		 NAME: "Citrus World, Inc.",
		 ID: "RHA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Adelanto",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Amarillo Group Health Plan",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "City Of Arlington",
		 ID: "EHI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Austin",
		 ID: "PARHC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Banning (CA) - CORVEL",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Baytown - CORVEL",
		 ID: "J1605",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Beverly Hills - CORVEL",
		 ID: "88027",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Blythe (CA) - CORVEL",
		 ID: "J3866",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Cambridge",
		 ID: "82347",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Desert Hot Springs (CA)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of El Paso",
		 ID: "83028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Fort Worth (TX)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Fountain Valley (CA) - CORVEL",
		 ID: "UCI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Gardena (CA) - CORVEL",
		 ID: "J1838",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Glendale (AZ)",
		 ID: "SCPR1",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of La Mesa 11 (CA) - CORVEL",
		 ID: "03432",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Laguna Hills (CA) - CORVEL",
		 ID: "64157",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City Of Lake Charles (La) - CORVEL",
		 ID: "16089",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Miami Beach (FL)",
		 ID: "VB001",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Milwaukee (WI) - CORVEL",
		 ID: "70259",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Minneapolis",
		 ID: "DCA62",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Norfolk (VA) - CORVEL",
		 ID: "32620",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Ocoee",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Omaha (NE) - CORVEL",
		 ID: "36396",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Perris (CA) - CORVEL",
		 ID: "74323",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Pompano Beach (FL)",
		 ID: "HOSH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Raleigh - CORVEL",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Redding (CA)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Rialto, CA - CORVEL",
		 ID: "42011",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of San Diego",
		 ID: "61102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of San Jacinto (CA) - CORVEL",
		 ID: "BCSSC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of St. Paul (MN)",
		 ID: "MPM28",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Stanton (CA) - CORVEL",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Topeka (KS) - CORVEL",
		 ID: "WRPNI",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of Torrance",
		 ID: "MCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Venice",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "City of Virginia Beach (VA) - CORVEL",
		 ID: "KEE01",
		 time: "< 30 mins"
	},
	{
		 NAME: "City of West Palm Beach (FL)",
		 ID: "MNAUL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Clackamas County Mental Health",
		 ID: "18768",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clackamas MHO General Fund",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Claimchoice Administrators (formerly AmeraPlan)",
		 ID: "60601",
		 time: "< 30 mins"
	},
	{
		 NAME: "Claims Administrative Services (CAS)",
		 ID: "OBA16",
		 time: "< 30 mins"
	},
	{
		 NAME: "Claims Choice Administrators LLC",
		 ID: "C1032",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Claims Development Corporation",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "ClaimsBridge Custom Provider Network",
		 ID: "10057",
		 time: "< 30 mins"
	},
	{
		 NAME: "Claimsware, Inc. DBA ManageMed",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Classic Residence By Hyatt ",
		 ID: "07040",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Clear One Health Plans",
		 ID: "J3924",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Clear Spring Health",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clever Care Health Plan",
		 ID: "41136",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clever Care Health Plan Encounters",
		 ID: "J1691",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Client First",
		 ID: "NMM06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clinica Medica San Miguel",
		 ID: "PHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clinical Specialties, Inc",
		 ID: "37290",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clinicas del Camino Real",
		 ID: "95202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Clover Health (formerly CarePoint Health Plan)",
		 ID: "95044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CNA Insurance",
		 ID: "37284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CNA Insurance (Auto only)",
		 ID: "APP01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CNS Healthcare",
		 ID: "74238",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coach USA",
		 ID: "MCDRI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coachella Valley Water District (CA) - CORVEL",
		 ID: "J1628",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoachellaMed ",
		 ID: "PHM10",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coastal Administrative Services",
		 ID: "02057",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coastal Communities Physician Network (CCPN) (Regal)",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coastal TPA",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coca-Cola Enterprises, Inc. ",
		 ID: "11081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cochran Canon Management Service, Inc. (CCMSI)",
		 ID: "CCA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coeur Plan Services, LLC",
		 ID: "J1257",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cofinity - Group Resources",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "College Health IPA (CHIPA)",
		 ID: "NEMS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Colonial Medical",
		 ID: "WBHCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Colorado Access",
		 ID: "59322",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Colorado Behavioral Healthcare Inc",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Colorado Casualty ",
		 ID: "DDNV1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Colorado Community Health Alliance",
		 ID: "FCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Colorado Health Insurance Cooperative Inc.",
		 ID: "SCPR1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Colorado Kaiser Permanente",
		 ID: "56001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Colorado Medicaid",
		 ID: "37602",
		 time: "< 30 mins"
	},
	{
		 NAME: "Columbia Mutual Insurance Company",
		 ID: "94352",
		 time: "< 30 mins"
	},
	{
		 NAME: "Columbia Sussex Corporation ",
		 ID: "89243",
		 time: "< 30 mins"
	},
	{
		 NAME: "Columbine Health Plan",
		 ID: "J1551",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoMed - CIGNA",
		 ID: "CHCPI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Commerce & Industry/Greenwood",
		 ID: "LWA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Commerce Benefits Group",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Common Ground Healthcare",
		 ID: "NDX99",
		 time: "< 30 mins"
	},
	{
		 NAME: "Commonwealth Care Alliance",
		 ID: "SC003",
		 time: "< 30 mins"
	},
	{
		 NAME: "Commonwealth Care Alliance ",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Commonwealth Care Alliance - Medicare Advantage\t",
		 ID: "AMIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Commonwealth Care Alliance (CCA)\t",
		 ID: "NEMS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Commonwealth Health Alliance (CHA)",
		 ID: "SPA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CommuniCare Advantage",
		 ID: "J1505",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Alliance of IL",
		 ID: "74266",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Alliance of Illinois",
		 ID: "HSH02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care Associates",
		 ID: "GNPMG",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care BHO",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care Inc. - Family Care (Wisconsin)",
		 ID: "09830",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Inc. (Wisconsin)",
		 ID: "RP016",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care IPA",
		 ID: "62111",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care Managed Health Care Plans of Oklahoma",
		 ID: "89238",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care Plan (Broward Health PPUC)",
		 ID: "22344",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Plan (Commercial)",
		 ID: "93044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Plan (Florida Healthy Kids)",
		 ID: "84146",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Care Plan (Medicaid)",
		 ID: "CPNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Care Plan (Palm Beach District)",
		 ID: "94135",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Development Commission (CA) - CORVEL",
		 ID: "95567",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Family Care",
		 ID: "89236",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community First",
		 ID: "11695",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Alliance",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Alliance of Tennessee",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Center Network - CHCN",
		 ID: "USMBP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Health Choice",
		 ID: "C1033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Choice - Health Insurance Marketplace",
		 ID: "LNY01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Group of San Diego",
		 ID: "AH-CARITAS-OHMCD-RAD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Health Group of SD (CHGSD) - Capitated Claims",
		 ID: "RVC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Group of SD (CHGSD) - FFS Claims",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Health Plan District of Columbia",
		 ID: "HESUN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Health Plan of Washington",
		 ID: "74853",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Health Plan of Washington (CHP of WA)",
		 ID: "64884",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Life",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Medical Centers (CA) - CORVEL",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "Community Medical Group of the West Valley (Regal)",
		 ID: "J3857",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Community Transit (WA only) - CORVEL",
		 ID: "91164",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CommunityCare Oklahoma (CCOK)",
		 ID: "GBHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Companion Property and Casualty Insurance Company",
		 ID: "18764",
		 time: "< 30 mins"
	},
	{
		 NAME: "Compass Group USA, Inc. ",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "CompDent / CompBenefits",
		 ID: "J1946",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Complementary Healthcare Plan (CHP)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Complete Care IPA",
		 ID: "CB733",
		 time: "< 30 mins"
	},
	{
		 NAME: "Complimentary Healthcare Plans",
		 ID: "93235",
		 time: "< 30 mins"
	},
	{
		 NAME: "CompManagement Health",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Comprehensive Care Management",
		 ID: "25850",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Comprehensive Care Services",
		 ID: "38238",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ComPsych",
		 ID: "DHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "CompuSys Inc",
		 ID: "C1089",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Concierge TPA",
		 ID: "BCSMT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Concordia Care, Inc",
		 ID: "39856",
		 time: "< 30 mins"
	},
	{
		 NAME: "Confederation Administration Services & Life Insurance",
		 ID: "06603",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Conifer (Workers Comp)",
		 ID: "73126",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Conifer Health Solutions",
		 ID: "TTUMD",
		 time: "< 30 mins"
	},
	{
		 NAME: "CONNECTICARE",
		 ID: "PCMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Connecticare - Medicare Advantage (Emblem)",
		 ID: "J3722",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Connecticare (Commercial)",
		 ID: "EPF11",
		 time: "< 30 mins"
	},
	{
		 NAME: "Connecticut Carpenters Health Fund",
		 ID: "NPN11",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Connecticut General",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Connecticut General Life",
		 ID: "75309",
		 time: "< 30 mins"
	},
	{
		 NAME: "Connecticut Indemnity Company",
		 ID: "13189",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Consociate Group",
		 ID: "53476",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Consolidated Associates Railroad",
		 ID: "93235",
		 time: "< 30 mins"
	},
	{
		 NAME: "Consolidated Health Plans",
		 ID: "MC006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Consumers Choice Health SC",
		 ID: "J1526",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Container Graphics Corporation",
		 ID: "HVMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contech Construction Products",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contessa Health",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contessa Health - Security Health Plan",
		 ID: "CB987",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Continental Airlines ",
		 ID: "38225",
		 time: "< 30 mins"
	},
	{
		 NAME: "Continental Benefits",
		 ID: "J1018",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Continental General Insurance (Medicare Supplement)",
		 ID: "SB580",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Continental General Insurance Company",
		 ID: "61425",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contra Costa Behavioral Health Plan",
		 ID: "DMELA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contra Costa Health Plan",
		 ID: "30166",
		 time: "< 30 mins"
	},
	{
		 NAME: "Contractors, Laborers, Teamsters & Engineers (Local 14B)",
		 ID: "95378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Convergys Corporation ",
		 ID: "56162",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook Childrens Health Plan",
		 ID: "J3751",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook Childrens Star Plan",
		 ID: "SCAN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cook County Health - Behavioral",
		 ID: "J1541",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook County Health - Medical",
		 ID: "31402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook Group Health Plan",
		 ID: "06302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook Group Solutions",
		 ID: "00243",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cook Medical Group",
		 ID: "36878",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cooperative Benefit Administrators",
		 ID: "19436",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cooperative Benefit Administrators (CBA)",
		 ID: "08044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoOportunity Health",
		 ID: "37279",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Co-ordinated Benefit Plans LLC",
		 ID: "SRS83",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coordinated Care - Medical",
		 ID: "CCA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coordinated Medical Specialists",
		 ID: "36149",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoPatient",
		 ID: "J1453",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Core Administrative Services",
		 ID: "60230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Core Benefits / Multimatic Tennesse, LLC",
		 ID: "75237",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Core Management Resources Group",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreCivic",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreFive (Mega Life & Health)",
		 ID: "VACCN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreFive (SelectCare)",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoreSource - Internal",
		 ID: "10107",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoreSource - Little Rock",
		 ID: "BC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoreSource - MD,PA,IL",
		 ID: "75600",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreSource - OH",
		 ID: "J1557",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreSource - Ohio",
		 ID: "00902",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreSource (AZ, MN)",
		 ID: "10067",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoreSource (MD, PA, IL, IA)",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreSource (NC, IN)",
		 ID: "NCCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoreSource KC (FMH)",
		 ID: "10416",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoreSource Little Rock",
		 ID: "J3899",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Corizon Inc (Formerly Correctional Medical Services)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cornerstone Benefit Administrators",
		 ID: "31478",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cornerstone Preferred Resources",
		 ID: "CB268",
		 time: "< 30 mins"
	},
	{
		 NAME: "Corporate Benefit Service (CBSA)",
		 ID: "95044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Corporate Benefits Service, Inc. (NC)",
		 ID: "11315",
		 time: "< 30 mins"
	},
	{
		 NAME: "Correctcare (GA Correctional Healthcare Adult Health)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CorrectCare Integrated Health (CA Prison Health Care Services)",
		 ID: "10002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Correctcare Integrated Health (Non-LA Jails)",
		 ID: "67895",
		 time: "< 30 mins"
	},
	{
		 NAME: "Correction Health Partners",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Correctional Management Service ",
		 ID: "SCNAC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Correctional Medical Services, Inc. (Now known as Corizon Inc)",
		 ID: "PCU02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Corrections Corporation of America (CCA)",
		 ID: "KCIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Corrigan-Camden ISD",
		 ID: "37116",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cottingham & Butler Claim Services, Inc.",
		 ID: "HPOUV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cottingham and Butler (Equian)",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Country Casualty Insurance Company",
		 ID: "27048",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Country Financial \\ Modern Service Casualty Insur Co - CORVEL",
		 ID: "11328",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Country Insurance \\ Modern Service Casualty Ins Co",
		 ID: "17929",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Country Insurance \\ Mountain States Insurance - CORVEL",
		 ID: "55069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Country Insurance \\ MSI Preferred Insurance Company",
		 ID: "33108",
		 time: "< 30 mins"
	},
	{
		 NAME: "Country Mutual Insurance Company",
		 ID: "C1052",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Country Preferred Insurance Company",
		 ID: "65432",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Countryway Insurance Company",
		 ID: "J3865",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County Care Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "County Medical Services Program (CMSP)",
		 ID: "97041",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Chesterfield, VA",
		 ID: "76342",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Fresno",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "County of Kern - CORVEL",
		 ID: "J1866",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Kern POS",
		 ID: "77033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Los Angeles (1000)",
		 ID: "34196",
		 time: "< 30 mins"
	},
	{
		 NAME: "County of Los Angeles (2000)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "County of Los Angeles (3000)",
		 ID: "CC168",
		 time: "< 30 mins"
	},
	{
		 NAME: "County of Los Angeles (4000/5000)",
		 ID: "38265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Sacramento - EMSF",
		 ID: "59065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Sacramento - Healthy Partners",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "County Of San Diego",
		 ID: "TLINS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "County of Shasta (CA)",
		 ID: "MBA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "County of Tulare (CA) - CORVEL",
		 ID: "93221",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Covenant Administrators Inc.",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "Covenant Management System Employee Benefit Plan",
		 ID: "20416",
		 time: "< 30 mins"
	},
	{
		 NAME: "Covenant Management Systems",
		 ID: "13551",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Health & Life",
		 ID: "10010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Health Care",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Health Care Carelink Medicaid",
		 ID: "38217",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Health Care of  MO, MS, AK, TN(00184)",
		 ID: "02027",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coventry Health Care of Georgia",
		 ID: "36347",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Health Care of Louisiana",
		 ID: "86027",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coventry Health Care of the Carolinas, Inc. (formerly WellPath)",
		 ID: "TAYLR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coventry Health Care of Virginia, Inc. (formerly Southern Health Services)",
		 ID: "IICTX",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coventry Health Care USA (HCUSA)",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Healthcare of Florida (Aetna Medicaid)",
		 ID: "38232",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry National/University of MO(00250)",
		 ID: "CPS01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry National/University of MO(COVON)",
		 ID: "19431",
		 time: "< 30 mins"
	},
	{
		 NAME: "Coventry One",
		 ID: "J1967",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coventry Wellpath Select",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CoventryCares (formerly OmniCare/CareNet/Carelink Medicaid)",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "CoventryCares of West Virginia",
		 ID: "12202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Coworx Staffing, LLC ",
		 ID: "CC304",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Cox Health Plan",
		 ID: "SYMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "CPR Share Plans",
		 ID: "52511",
		 time: "< 30 mins"
	},
	{
		 NAME: "Craftworks Holdings - CORVEL",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Create Healthcare",
		 ID: "95885",
		 time: "< 30 mins"
	},
	{
		 NAME: "Creative Medical Systems",
		 ID: "56205",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Crescent Health Solutions",
		 ID: "67022",
		 time: "< 30 mins"
	},
	{
		 NAME: "Crestbrook Insurance Company",
		 ID: "25172",
		 time: "< 30 mins"
	},
	{
		 NAME: "Crossway HealthShare",
		 ID: "36400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Crown Life - Great West",
		 ID: "IEXTX",
		 time: "< 30 mins"
	},
	{
		 NAME: "Croy - Hall Management, Inc.",
		 ID: "COACC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Crum & Forster Indemnity Co",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Crystal Run Health Plans",
		 ID: "99208",
		 time: "< 30 mins"
	},
	{
		 NAME: "CSAA Insurance Company",
		 ID: "GCHCA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CSEA Employee Benefit Fund",
		 ID: "J1636",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CSI NETWORK SERVICES",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cumberland Insurance Group",
		 ID: "MCC02",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CuraeChoice",
		 ID: "03202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Custody Medical Services Program (CMSP)",
		 ID: "AMM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Custom Benefit Administrators",
		 ID: "45979",
		 time: "< 30 mins"
	},
	{
		 NAME: "Custom Design Benefits, Inc.",
		 ID: "NCPG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "CVS Pharmacy (CAREMARK)",
		 ID: "J3973",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CWI Benefits, Inc.",
		 ID: "46299",
		 time: "> 30 Mins"
	},
	{
		 NAME: "CYPRESS BENEFIT ADMINISTRATORS",
		 ID: "91062",
		 time: "< 30 mins"
	},
	{
		 NAME: "Cypress Security (Member of BHHC)",
		 ID: "J2095",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dairyland Insurance Company",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "DakotaCare",
		 ID: "IHS07",
		 time: "< 30 mins"
	},
	{
		 NAME: "DakotaCare Chiropractic",
		 ID: "16307",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dameron Hospital Association",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Darigold - CORVEL",
		 ID: "251CC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dart Management Corporation",
		 ID: "37315",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dawson ISD",
		 ID: "71412",
		 time: "< 30 mins"
	},
	{
		 NAME: "DBi Services LLC - CORVEL",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "DC Health Ryan White Program",
		 ID: "22264",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DC Risk Solutions",
		 ID: "31125",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DCHP Texas Medicaid",
		 ID: "95444",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DDIC",
		 ID: "76048",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dean Health Plan",
		 ID: "10196",
		 time: "< 30 mins"
	},
	{
		 NAME: "Decent",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Del Taco, LLC",
		 ID: "98205",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delano Regional Medical Group (Managed Care Systems)",
		 ID: "J2276",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delaware First Health",
		 ID: "SHPM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dell Childrens Health Plan",
		 ID: "62119",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dell, Inc. - CORVEL",
		 ID: "PHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental (Northeast - ME, NH, VT)",
		 ID: "NDB01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Classic",
		 ID: "UHA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Clear",
		 ID: "FCMA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Encara",
		 ID: "37290",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Alabama",
		 ID: "23166",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Florida",
		 ID: "18763",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Georgia",
		 ID: "FS802",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Louisiana",
		 ID: "36582",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Mississippi",
		 ID: "82018",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Texas",
		 ID: "13111",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Ins. Co. - Utah",
		 ID: "88888",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental Of Alaska",
		 ID: "OODAH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Arizona",
		 ID: "EXC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Arkansas",
		 ID: "MLNSC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of California",
		 ID: "IHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Colorado",
		 ID: "J1968",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Connecticut",
		 ID: "J1238",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Delaware",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Georgia",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Idaho",
		 ID: "54771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Illinois",
		 ID: "47598",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Illinois - Individual",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Iowa",
		 ID: "J2345",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Iowa - DWP",
		 ID: "KYCS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Kentucky",
		 ID: "SCS17",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Maryland",
		 ID: "37283",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Massachusetts",
		 ID: "31182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Missouri",
		 ID: "VPA18",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Montana",
		 ID: "OSCAR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Nevada",
		 ID: "IHS09",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of New Jersey",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of New York",
		 ID: "OMN02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Oklahoma",
		 ID: "NW002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Pennsylvania",
		 ID: "J1836",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Puerto Rico",
		 ID: "23253",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Rhode Island",
		 ID: "LNY01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of South Carolina in Missouri",
		 ID: "MCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of South Dakota",
		 ID: "59313",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Texas",
		 ID: "24456",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Virginia",
		 ID: "C1093",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of WA",
		 ID: "48100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Washington DC",
		 ID: "UHA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Washington State",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of West Virginia",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Dental of Wisconsin",
		 ID: "J3760",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental of Wyoming",
		 ID: "23045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Delta Dental Texas Childrens Medicaid Services",
		 ID: "CPP09",
		 time: "< 30 mins"
	},
	{
		 NAME: "Delta Health Systems",
		 ID: "99795",
		 time: "< 30 mins"
	},
	{
		 NAME: "DeltaCare USA - Claims",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "DeltaCare USA - Encounters",
		 ID: "27847",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dental Benefit Providers",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dental Choice Plan",
		 ID: "CX091",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dental Choice Plan Plus",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dental Health & Wellness",
		 ID: "05102",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dental Select",
		 ID: "MCDPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DentaQuest",
		 ID: "00840",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DentaQuest –Government",
		 ID: "38337",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dentegra",
		 ID: "46299",
		 time: "< 30 mins"
	},
	{
		 NAME: "Denti-Cal",
		 ID: "BHOVO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Denti-Cal DTI Encounter",
		 ID: "20333",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Denver Health and Hospital Authority",
		 ID: "99320",
		 time: "< 30 mins"
	},
	{
		 NAME: "Denver Health Medical Plan",
		 ID: "39114",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Denver Health Medical Plan Inc.",
		 ID: "DCENT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Denver Health Medical Plan, In. - Medicare Choice",
		 ID: "76578",
		 time: "< 30 mins"
	},
	{
		 NAME: "Department of Corrections - Oklahoma",
		 ID: "SCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Department of Social and Health Services (DSHS)",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "Department Rehabilitative Services - Oklahoma",
		 ID: "ALTAM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Depositors Insurance Co",
		 ID: "56213",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Deseret Mutual Benefit Administrators",
		 ID: "77894",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Desert Medical Group (Regal)",
		 ID: "C1051",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Desert Oasis Healthcare (DOHC) (Regal)",
		 ID: "SC050",
		 time: "< 30 mins"
	},
	{
		 NAME: "Desert Valley Medical Group",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Detego Health",
		 ID: "10488",
		 time: "< 30 mins"
	},
	{
		 NAME: "Detroit Medical Center",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Devoted Health",
		 ID: "H0657",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DHR Provider Management",
		 ID: "25711",
		 time: "< 30 mins"
	},
	{
		 NAME: "Diabetes Heart Care CSNP",
		 ID: "96277",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Diamond Bar Medical Group",
		 ID: "SJHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Diamond Insurance Group, LTD",
		 ID: "J2274",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Diamond Plan",
		 ID: "77082",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Global",
		 ID: "00901",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity HCLA",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dignity Health - Mercy Medical Group / Woodland Clinic",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Health - Sequoia Physicians Network",
		 ID: "UMHCJ",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Health Medical Group - Inland Empire",
		 ID: "46166",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Health Medical Network – Central California",
		 ID: "J1580",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Health Medical Network – Santa Cruz",
		 ID: "14312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dignity Health MSO",
		 ID: "61604",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dignity Health Plan",
		 ID: "412MP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dignity Health Sacramento Hospital SHO",
		 ID: "J1590",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dimension Benefit Management",
		 ID: "86304",
		 time: "< 30 mins"
	},
	{
		 NAME: "Diploma Joint Insurance Fund (SEL)",
		 ID: "10275",
		 time: "< 30 mins"
	},
	{
		 NAME: "Direct Care Administrators",
		 ID: "36401",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Direct Dental Administrators",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Directors Guild",
		 ID: "16107",
		 time: "< 30 mins"
	},
	{
		 NAME: "Director's Guild of America - Producer Health Plans",
		 ID: "MPM06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Discount Tire",
		 ID: "BHOVO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Disneyland Resorts (Conduent - Formerly Bunch CareSolutions)",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Diversified Administration Corporation",
		 ID: "42140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Diversified Group Administrators",
		 ID: "39144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Diversified Group Brokerage",
		 ID: "00910",
		 time: "< 30 mins"
	},
	{
		 NAME: "DMERC Region A Medicare",
		 ID: "73067",
		 time: "< 30 mins"
	},
	{
		 NAME: "DMERC Region B Medicare",
		 ID: "DDMS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "DMERC Region C Medicare",
		 ID: "13550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "DMERC Region D Medicare",
		 ID: "PREHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Doctor’s Professional Services Consultants",
		 ID: "A0701",
		 time: "< 30 mins"
	},
	{
		 NAME: "Doctors Healthcare Plans",
		 ID: "J1379",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Doctors Managed IPA",
		 ID: "16691",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Dominion Dental (115 S. Union St., Ste. 300, Alexandria, VA 22314)",
		 ID: "45048",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Downey Select IPA",
		 ID: "58174",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dreyer Health",
		 ID: "SNTCC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dreyer Medical Clinic",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Driscoll Childrens Health Plan",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dunn & Associates Benefits Administrators, Inc",
		 ID: "47076",
		 time: "< 30 mins"
	},
	{
		 NAME: "Dupage Medical Group",
		 ID: "35605",
		 time: "< 30 mins"
	},
	{
		 NAME: "Early Intervention Central Billing",
		 ID: "95386",
		 time: "> 30 Mins"
	},
	{
		 NAME: "East Bay Drainage Drivers Security Fund",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "East Carolina Behavioral Health",
		 ID: "15060",
		 time: "< 30 mins"
	},
	{
		 NAME: "Eastern Iowa Community Healthcare (EICH)",
		 ID: "RIOSA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Eastern Oregon CCO",
		 ID: "EOCCO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EastGUARD Insurance Company - Guard DBA",
		 ID: "66917",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eastland Medical Group IPA (Regal)",
		 ID: "J2555",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eastman Kodak Company - CORVEL",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "EastPointe Behavioral Health",
		 ID: "21814",
		 time: "< 30 mins"
	},
	{
		 NAME: "Easy Access Care IPA",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Easy Care MSO LLC",
		 ID: "VHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Easy Choice Health Plan of California (Encounters) - ECHP",
		 ID: "88091",
		 time: "< 30 mins"
	},
	{
		 NAME: "Easy Choice Health Plan of California (Fee for Service) - ECHP",
		 ID: "13317",
		 time: "< 30 mins"
	},
	{
		 NAME: "EasyAccess Care IPA (ProCare MSO)",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EBA &amp; M  Employee Benefits Administration and Management Corp",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "EBC (Buffalo Rock) Birmingham",
		 ID: "AUX01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eberle Vivian",
		 ID: "38259",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eberle Vivian (Workers Comp)",
		 ID: "00510",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ebix Health Administration Exchange (EHAE)",
		 ID: "C1101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Edinger Medical Group",
		 ID: "J1713",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Edison Health Solutions",
		 ID: "J1447",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Edwards Claim Administration",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EHI, EHIC",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "EHS Medical Group - Fresno",
		 ID: "128VA",
		 time: "< 30 mins"
	},
	{
		 NAME: "El Camino Health Alliance",
		 ID: "30120",
		 time: "> 30 Mins"
	},
	{
		 NAME: "El Camino Health Medical Network",
		 ID: "00403",
		 time: "> 30 Mins"
	},
	{
		 NAME: "El Paso Community College (TX)",
		 ID: "66901",
		 time: "> 30 Mins"
	},
	{
		 NAME: "El Paso First - CHIP",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "El Paso Water Utilities TX",
		 ID: "HUMANA-OHMCD",
		 time: "< 30 mins"
	},
	{
		 NAME: "El Proyecto De Barrio",
		 ID: "10044",
		 time: "< 30 mins"
	},
	{
		 NAME: "ElderPlan, Inc.",
		 ID: "37257",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Elderwood Health",
		 ID: "MMFUC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Electric Insurance Company",
		 ID: "PODIA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Electrical Welfare Trust Fund",
		 ID: "J3957",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Electrical Workers Health and Welfare Plan for Northern Nevada",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Electrical Workers Insurance Fund Local 5800",
		 ID: "CX015",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Electronic Practice Solutions, Inc.",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Element Care, Inc.",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "ElmCare LLC",
		 ID: "NAELM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Elmira Savings Bank (UCS)",
		 ID: "74240",
		 time: "< 30 mins"
	},
	{
		 NAME: "EMAEHIPA",
		 ID: "12502",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Emanate Health IPA",
		 ID: "FHKC1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Emanate Health Med Center AltaMed",
		 ID: "BS002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Emanate Health Med Center NMM",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Emanate Health Med Center PDT MSO",
		 ID: "50023",
		 time: "< 30 mins"
	},
	{
		 NAME: "Emblem Dental (DentaQuest)",
		 ID: "55768",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EMCASCO Insurance Co - WC ONLY",
		 ID: "COLTC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Emergency Medical Service Fund (EMSF)",
		 ID: "J2104",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Emeritus Corporation",
		 ID: "10174",
		 time: "< 30 mins"
	},
	{
		 NAME: "EMHS Employee Health Plan",
		 ID: "MR089",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EMI Health",
		 ID: "48145",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EMI-KP Ambulance Claims",
		 ID: "22348",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EMPHESYS",
		 ID: "HMA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Empire Dental - Blue Cross Blue Shield (Anthem)",
		 ID: "36404",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Empire Healthcare IPA",
		 ID: "EHI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefit Concepts",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefit Corporation",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Benefit Logistics (EBL)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefit Management Corp (EBMC)",
		 ID: "RCHSD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefit Management Services",
		 ID: "59355",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Benefit Management Services (EBMS)",
		 ID: "MLNNV",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Benefit Management, Inc. (EBSI)",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Benefit Services  - Ft. Mill, South Carolina",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefit Services (EBS of San Antonio)",
		 ID: "62308",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefits administrators (EBA)",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Benefits Consultants",
		 ID: "32002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Benefits Plan Administration (EBPA)",
		 ID: "10322",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employee Health Coalition",
		 ID: "35182",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Health Systems",
		 ID: "DDCA2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employee Plans, LLC",
		 ID: "99726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employer Direct Healthcare",
		 ID: "TCPA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employer Driven Insurance Services",
		 ID: "95019",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Assurance Company",
		 ID: "20149",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Compensation Insurance Company",
		 ID: "HFP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Direct Health",
		 ID: "CHOC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employer's Direct Health - Self",
		 ID: "J3748",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers General Insurance Group",
		 ID: "J1846",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Health Insurance of Wisconsin",
		 ID: "MPM19",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Health Network",
		 ID: "SYMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Insurance (Bunch CareSolutions)",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Insurance Group (EIG)",
		 ID: "64088",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Life Insurance Corporation",
		 ID: "21873",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Mutual Casualty - AUTO ONLY",
		 ID: "13079",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Mutual Casualty - LIAB ONLY",
		 ID: "04911",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employers Mutual Casualty - WC ONLY",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Mutual Inc.",
		 ID: "10956",
		 time: "< 30 mins"
	},
	{
		 NAME: "Employer's Mutual, Inc. (Jacksonville, FL)",
		 ID: "J1563",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Employers Preferred Insurance Company",
		 ID: "PM001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Empower Arkansas",
		 ID: "12956",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Encircle PPO",
		 ID: "RAIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Encompass (Subsidiary of Allstate)",
		 ID: "35161",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Encore Health Network",
		 ID: "10403",
		 time: "< 30 mins"
	},
	{
		 NAME: "Enstar Natural Gas, Group # P61",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Enterprise Group Planning",
		 ID: "J1613",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ENTRUST",
		 ID: "J1447",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eon Health",
		 ID: "CDIA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Epic Assistance",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Epic Specialty Benefits (WPS)",
		 ID: "KYCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EPN (Seton Health Plan)",
		 ID: "CNSOR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Equian",
		 ID: "THRIV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Equicor",
		 ID: "BS059",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Equicor - PPO",
		 ID: "DHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Equitable Plan Services",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ergon, Inc. - CORVEL",
		 ID: "64088",
		 time: "< 30 mins"
	},
	{
		 NAME: "Erin Group Administrators",
		 ID: "13162",
		 time: "< 30 mins"
	},
	{
		 NAME: "Erisa Administrative Services",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "ERMC",
		 ID: "39026",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ES Beveridge & Associates",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ESIS Auto",
		 ID: "SC052",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ESIS Workers Comp",
		 ID: "GCHCA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Esperanza IPA",
		 ID: "13350",
		 time: "< 30 mins"
	},
	{
		 NAME: "Essence Healthcare",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Essential Health Partners",
		 ID: "03036",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Essential Health Partners IPA",
		 ID: "72107",
		 time: "< 30 mins"
	},
	{
		 NAME: "Essex Dental Benefits",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Esurance Insurance Company",
		 ID: "CRI01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Everence",
		 ID: "75273",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Evergreen Health",
		 ID: "84103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Evergreen Health Cooperative",
		 ID: "00243",
		 time: "> 30 Mins"
	},
	{
		 NAME: "EverNorth Behavioral Health",
		 ID: "68047",
		 time: "< 30 mins"
	},
	{
		 NAME: "Evolutions Healthcare Systems",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Exceedent LLC",
		 ID: "23285",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Excel MSO",
		 ID: "SPOHN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Excel MSO UHC PPO",
		 ID: "ONOCI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Exceptional Care Medical Group (ECMG)",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Exclusicare",
		 ID: "22603",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Exclusive Care",
		 ID: "CB951",
		 time: "< 30 mins"
	},
	{
		 NAME: "Exclusive Care - County of Riverside",
		 ID: "EBA42",
		 time: "< 30 mins"
	},
	{
		 NAME: "Exemplar Health",
		 ID: "26545",
		 time: "< 30 mins"
	},
	{
		 NAME: "Exemplar Health Insurance Company",
		 ID: "MCDMT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Express, LLC - CORVEL",
		 ID: "88875",
		 time: "< 30 mins"
	},
	{
		 NAME: "Extended MLTC",
		 ID: "27905",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ExxonMobil Corporation - CORVEL",
		 ID: "31135",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eye Management Inc.",
		 ID: "45437",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eyecare 2020",
		 ID: "J1897",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Eyenez Eye Institute",
		 ID: "99795",
		 time: "< 30 mins"
	},
	{
		 NAME: "EyeQuest",
		 ID: "MMPHB",
		 time: "< 30 mins"
	},
	{
		 NAME: "F40 Alaska Carpenters Trust",
		 ID: "54771",
		 time: "< 30 mins"
	},
	{
		 NAME: "FABOH (CHP RPU)",
		 ID: "MC006",
		 time: "< 30 mins"
	},
	{
		 NAME: "Facey Medical Foundation",
		 ID: "15202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Factory 2 U",
		 ID: "MCDAK",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fair Price",
		 ID: "26158",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Fairchild Fasteners - CORVEL",
		 ID: "22284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Fairfax County Government - CORVEL",
		 ID: "19439",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fairmont Specialty Insurance Company - TX",
		 ID: "39045",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fairview Health Services",
		 ID: "13185",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Falling Colors (BHSD STAR)",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fallon Community Health",
		 ID: "63985",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fallon Health (Transplant Claims ONLY)",
		 ID: "FT254",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fallon Total Care",
		 ID: "TXABA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fallon Transplant",
		 ID: "SC10002123",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Care Medicaid",
		 ID: "68251",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Care Specialists (FCS)",
		 ID: "71804",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Choice Medical Group",
		 ID: "41204",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Dental Care, Inc.",
		 ID: "55843",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Health Alliance",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Health Network",
		 ID: "37278",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Family Medical Network",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Family Practice Medical Group",
		 ID: "MCDDE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Family Seniors Medical Group",
		 ID: "00139",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farm Bureau Mutual Insurance Company",
		 ID: "SVA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmers \\ Civic Property and Casualty Company",
		 ID: "56155",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmers \\ Exact Property and Casualty Company",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Farmers \\ Fire Insurance Exchange",
		 ID: "38238",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Farmers \\ Mid-Century Insurance Company",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Farmers \\ Neighborhood Spirit Property and Casualty Company",
		 ID: "AAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmers \\ Truck Insurance Exchange",
		 ID: "MR025",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Farmers Insurance",
		 ID: "15003",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmer's Insurance (Commercial)",
		 ID: "00186",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmers Insurance Exchange",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Farmers of Flemington",
		 ID: "IVHPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Farmland Mutual Insurance Co",
		 ID: "NW002",
		 time: "< 30 mins"
	},
	{
		 NAME: "FCCI ",
		 ID: "PEAK0",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FCE Benefit Administrators",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FCS Administrators",
		 ID: "21805",
		 time: "< 30 mins"
	},
	{
		 NAME: "Federal Employee Plan of South Carolina",
		 ID: "44602",
		 time: "< 30 mins"
	},
	{
		 NAME: "Federal Employees Plan WA (Regence Blue Shield)",
		 ID: "37287",
		 time: "> 1 hours"
	},
	{
		 NAME: "Federal Mogul - CORVEL",
		 ID: "06105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Federated Mutual Insurance Company",
		 ID: "48888",
		 time: "< 30 mins"
	},
	{
		 NAME: "Federated Mutual Insurance Company (WC only)",
		 ID: "61103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Federated Rural Electric (Tx)",
		 ID: "10202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Federated Services Insurance Company",
		 ID: "19430",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fenix Medical Group",
		 ID: "94265",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fidelis Care New York",
		 ID: "RIOSA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fidelity Mohawk",
		 ID: "J3752",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Firefly",
		 ID: "73780",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Fireman's Fund Insurance Company",
		 ID: "00804",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Ameritas Life Insurance Corp. of NY",
		 ID: "46050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Carolina Care",
		 ID: "40137",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Choice Health Administrators (FCHA)",
		 ID: "MPM60",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Choice Health Network (FCHN)",
		 ID: "SAN01",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Choice MA Plans",
		 ID: "00660",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Choice Medical Group",
		 ID: "37276",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Choice Medical Group/Meritage",
		 ID: "J2361",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Choice VIP Care Plus SC",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Dental Health (CA)",
		 ID: "J1588",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Great West Life & Annuity Ins. Co.",
		 ID: "VAC45",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Health Network (f.k.a. CCN Managed Care Inc. & PPO Oklahoma)",
		 ID: "77814",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Keystone Risk Retention Group",
		 ID: "BSCA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Managed Care Option, Inc",
		 ID: "46923",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Priority Health (FPH) - Highmark",
		 ID: "61115",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Priority Life Insurance Company (FPLIC) - Highmark",
		 ID: "62777",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Reliance Standard",
		 ID: "54763",
		 time: "> 30 Mins"
	},
	{
		 NAME: "First Responder Joint Insurance Fund",
		 ID: "47083",
		 time: "< 30 mins"
	},
	{
		 NAME: "First Source (Endeavor)",
		 ID: "47738",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Firstcare (Medicaid)",
		 ID: "58102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Firstcare and Firstcare Medicaid",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Firstcomp",
		 ID: "SJHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "FirstMedicare Direct",
		 ID: "37321",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FirstNation Health",
		 ID: "76342",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FirstSolutions",
		 ID: "89461",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fitzharris & Company, Inc.",
		 ID: "07689",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FKI Industries, Inc. ",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Blue HMO",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Community Care",
		 ID: "65074",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Complete Care",
		 ID: "54763",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Health Administrators",
		 ID: "81044",
		 time: "< 30 mins"
	},
	{
		 NAME: "Florida Health Care Plan",
		 ID: "45341",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Health Solution HMO Company (Commercial ACA Metal Plans)",
		 ID: "100709",
		 time: "< 30 mins"
	},
	{
		 NAME: "Florida Healthcare Plan",
		 ID: "52132",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florida Hospital Orlando VBR",
		 ID: "HCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Florida Hospital Waterman",
		 ID: "80900",
		 time: "< 30 mins"
	},
	{
		 NAME: "Florida Pace Centers Inc",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Florida True Health",
		 ID: "J3869",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florists Insurance Company",
		 ID: "32043",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Florists Mutual Insurance Company",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Flour Bluff ISD",
		 ID: "41600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Flume Health, Inc",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "FMH Benefit Services, Inc.",
		 ID: "00570",
		 time: "< 30 mins"
	},
	{
		 NAME: "FMI",
		 ID: "78857",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Focus Plan NC",
		 ID: "JCARE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Foothill Eye Care Services",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ford Motor Company",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Foreign Service Benefit Plan",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Forest County Potawatomi Insurance Department",
		 ID: "INCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Foundation for Medical Care of Tulare & Kings County",
		 ID: "77046",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Foundation Health HMO",
		 ID: "SCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fountain Valley IPA (FVIPA)",
		 ID: "13010",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fountain Valley Premier IPA",
		 ID: "37278",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Fox Valley Medicine Site 199",
		 ID: "10629",
		 time: "< 30 mins"
	},
	{
		 NAME: "Franciscan Alliance - CORVEL",
		 ID: "WELM2",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Frankenmuth Mutual",
		 ID: "09830",
		 time: "< 30 mins"
	},
	{
		 NAME: "Franklin Mutual",
		 ID: "AHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Freedom Blue",
		 ID: "82275",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Freedom Claims Management",
		 ID: "14165",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Freedom Health Plan",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Freedom Life Insurance Company",
		 ID: "09830",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fresno PACE",
		 ID: "MPM59",
		 time: "< 30 mins"
	},
	{
		 NAME: "Friday Health Plans",
		 ID: "27034",
		 time: "< 30 mins"
	},
	{
		 NAME: "Friedkin Companies, Inc - CORVEL",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fringe Benefit Group",
		 ID: "36342",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Austin)",
		 ID: "TDDIR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Houston)",
		 ID: "COPAT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fringe Benefit Management (FBMC)",
		 ID: "10045",
		 time: "< 30 mins"
	},
	{
		 NAME: "Fringe Benefits Coordinators",
		 ID: "IP097",
		 time: "< 30 mins"
	},
	{
		 NAME: "FrontPath Health Coalition",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Fullerton Union High School District (CA) - CORVEL",
		 ID: "54138",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gainsco",
		 ID: "ENDVR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Gallagher Bassett Services, Inc.",
		 ID: "MEDM1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Galveston County Indigent Health Care",
		 ID: "MCDAR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Garretson Resolution Group",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gateway Health Plan",
		 ID: "94320",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gateway Health Plan - Medicare Assured",
		 ID: "LIL01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gateway Health Plan (Medicaid PA)",
		 ID: "10064",
		 time: "< 30 mins"
	},
	{
		 NAME: "Gateway IPA (Pinnacle Health Resources)",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Gateway to Better Health Plan - MO Medicaid Plan FFS",
		 ID: "CPNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "GE Auto - CORVEL",
		 ID: "54398",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GEHA – Aetna Signature Administrators",
		 ID: "12T47",
		 time: "< 30 mins"
	},
	{
		 NAME: "GEHA – ASA",
		 ID: "MCRIL",
		 time: "< 30 mins"
	},
	{
		 NAME: "GEHA - Government Employees Hospital Association",
		 ID: "75309",
		 time: "< 30 mins"
	},
	{
		 NAME: "GEHA Group Health (Nevada)",
		 ID: "IHS04",
		 time: "< 30 mins"
	},
	{
		 NAME: "Geico Insurance",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Geisinger Health Plan",
		 ID: "CCMHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Geisinger Health Plans",
		 ID: "73143",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GemCare Health Plan, Kern County EPO",
		 ID: "BHOMA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GEMCare Medical Group (Managed Care Systems)",
		 ID: "63100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Genelco / Gencare Health",
		 ID: "66901",
		 time: "> 30 Mins"
	},
	{
		 NAME: "General Hospital (Humboldt Del Norte)",
		 ID: "62308",
		 time: "< 30 mins"
	},
	{
		 NAME: "General Parts International",
		 ID: "37281",
		 time: "> 30 Mins"
	},
	{
		 NAME: "General Vision",
		 ID: "41202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Generations Healthcare",
		 ID: "11502",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Genesis Healthcare (EMG)",
		 ID: "13193",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Georges Inc. - CORVEL",
		 ID: "J3963",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Georgia Health Advantage",
		 ID: "77070",
		 time: "< 30 mins"
	},
	{
		 NAME: "Georgia Medicaid - Grady Healthcare, Inc.",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GHA Orthopedic Providers Inc.",
		 ID: "VALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "GHI Emblem Health                                                       ",
		 ID: "J1687",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GI Innovative Management",
		 ID: "61425",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Giatros Health IPA",
		 ID: "11730",
		 time: "< 30 mins"
	},
	{
		 NAME: "GIC Indemnity Plan",
		 ID: "62160",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gillette Childrens Specialty Healthcare - CORVEL",
		 ID: "BC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Gilsbar",
		 ID: "22483",
		 time: "< 30 mins"
	},
	{
		 NAME: "Glendale Adventist Medical Center",
		 ID: "87605",
		 time: "< 30 mins"
	},
	{
		 NAME: "Glendale Memorial Group (Regal)",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Glendale Physicians Alliance (Regal)",
		 ID: "68423",
		 time: "< 30 mins"
	},
	{
		 NAME: "Global Benefits Group",
		 ID: "00910",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global Care Inc.",
		 ID: "51062",
		 time: "> 1 hours"
	},
	{
		 NAME: "Global Care Medical Group IPA",
		 ID: "88027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global Excel Management",
		 ID: "NMM02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Global Health",
		 ID: "92579",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global Healthcare Alliance",
		 ID: "J1437",
		 time: "> 1 hours"
	},
	{
		 NAME: "Global Liberty Insurance Company of New York",
		 ID: "44219",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global One Ventures",
		 ID: "94123",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global TBSP",
		 ID: "IHORG",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Global Women's Health Providers Inc.",
		 ID: "PROSP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GMAC Insurance Management Corporation",
		 ID: "77022",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GMMI CC",
		 ID: "J1680",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GMR Healthcare",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "Gold Coast",
		 ID: "47181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Golden Bay Health Plan",
		 ID: "37233",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Golden Coast MSO",
		 ID: "66005",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Golden Eagle ",
		 ID: "31114",
		 time: "< 30 mins"
	},
	{
		 NAME: "Golden Physicians Medical Group",
		 ID: "00960",
		 time: "< 30 mins"
	},
	{
		 NAME: "Golden State Medicare Health Plan",
		 ID: "26375",
		 time: "< 30 mins"
	},
	{
		 NAME: "Golden Triangle Physician Alliance (GTPA)",
		 ID: "ATPA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Golden West Dental (888 W. Vantura Blvd., Camarillo, CA)",
		 ID: "49578",
		 time: "< 30 mins"
	},
	{
		 NAME: "Good Samaritan Hospital (GSH)",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Good Samaritan Medical Practice Association (GSMPA)",
		 ID: "J3860",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Good2go Insurance (aka Personal Service Insurance)",
		 ID: "CARMO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Goodfellow Top Grade Construction LLC",
		 ID: "95112",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Goodrich",
		 ID: "IHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Government Employees Hospital Association (GEHA)",
		 ID: "THS01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Grady Healthcare - Georgia Medicaid",
		 ID: "54771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Grand River Insurance (Auto only)",
		 ID: "86304",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Grand River Insurance (WC only)",
		 ID: "94320",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Grange Mutual Casualty Company",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Grange WC",
		 ID: "UHP01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Granite State - Medical",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "GRAVIE",
		 ID: "MR018",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gravie Inc",
		 ID: "JMH01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Great American Alliance Ins Co. ",
		 ID: "59064",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Great American Assurance Co.",
		 ID: "92805",
		 time: "< 30 mins"
	},
	{
		 NAME: "Great American Ins. Co.",
		 ID: "10322",
		 time: "< 30 mins"
	},
	{
		 NAME: "Great American Ins. Co. of NY",
		 ID: "77153",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Great Divide Insurance",
		 ID: "4YC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Great Lakes PACE",
		 ID: "C1099",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Great Plains Medicare Advantage",
		 ID: "MDXHI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Great States Health IICT",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Great West Health Care",
		 ID: "VAC45",
		 time: "< 30 mins"
	},
	{
		 NAME: "Greater Covina Medical Group (GCMG) (Regal)",
		 ID: "MPM61",
		 time: "< 30 mins"
	},
	{
		 NAME: "Greater L.A. Healthcare",
		 ID: "59064",
		 time: "< 30 mins"
	},
	{
		 NAME: "Greater Newport Physicians",
		 ID: "33632",
		 time: "< 30 mins"
	},
	{
		 NAME: "Greater Orange Medical Group",
		 ID: "58204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Greater Oregon Behavioral Health Inc",
		 ID: "36878",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Greater San Gabriel Medical Group",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Greater San Gabriel Valley Physicians",
		 ID: "ASFL1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Greater Tri-Cities IPA",
		 ID: "74095",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Great-West Life & Annuity Insurance Company",
		 ID: "AMM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Green Diamond WA - CORVEL",
		 ID: "20133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Greentree Administrators",
		 ID: "EAIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Grinnell Reinsurance Company",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Grinnell Select - CORVEL",
		 ID: "36396",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group & Pension Administrators",
		 ID: "64071",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Administrators Ltd.",
		 ID: "U7632",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Benefit Services",
		 ID: "26224",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Benefit Services, Inc. (Missouri)",
		 ID: "HDNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Benefits - Louisiana",
		 ID: "AXM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Health Cooperative",
		 ID: "SCHAR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Health Cooperative (GHC)",
		 ID: "CB159",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Health Cooperative of Eau Claire",
		 ID: "88337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Health Cooperative of South Central Wisconsin (Claims)",
		 ID: "DCRWP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Health Cooperative of South Central Wisconsin (Encounters)",
		 ID: "ANTHEM-OHMCD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Health Options (Pacific Northwest)",
		 ID: "41204",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Health, Inc. (GHI HMO) (Emblem)",
		 ID: "75261",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Health, Inc. (GHI PPO) (Emblem)",
		 ID: "AMG02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Insurance Service Center",
		 ID: "MMFUC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Management Services, Inc. (GMS)",
		 ID: "J3297",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Marketing Services, Inc.",
		 ID: "MHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Group Practice Affiliates",
		 ID: "39112",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Group Resources",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GroupLink",
		 ID: "52429",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Guaranty Fund Management Services",
		 ID: "54763",
		 time: "< 30 mins"
	},
	{
		 NAME: "Guard",
		 ID: "CC16E",
		 time: "< 30 mins"
	},
	{
		 NAME: "Guardian Life (The Guardian)",
		 ID: "J1834",
		 time: "> 30 Mins"
	},
	{
		 NAME: "GuideOne Elite Insurance",
		 ID: "CHBU01653",
		 time: "< 30 mins"
	},
	{
		 NAME: "GuideOne Mutual Insurance",
		 ID: "ADABS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Guidewell Mutual Holding - CORVEL",
		 ID: "CX052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Guildnet",
		 ID: "MCDCT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gulf Coast Physician Network (MedView)",
		 ID: "C1028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gulf Guaranty or MedPlus",
		 ID: "SVA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gulf Quest IPA HMO Blue",
		 ID: "89789",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gulf South",
		 ID: "L0230",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Gulf Stream (CoreSource)",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Guy Yocomo Construction",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "H & A  Administrators",
		 ID: "88051",
		 time: "< 30 mins"
	},
	{
		 NAME: "H & R Block",
		 ID: "RCHN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "H.E.R.E Health Trust",
		 ID: "95379",
		 time: "< 30 mins"
	},
	{
		 NAME: "HAA Preferred Partners",
		 ID: "AMM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Halcyon Behavioral Health",
		 ID: "61115",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hamaspik Choice",
		 ID: "31401",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hana Hou Medical Group",
		 ID: "22603",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hannaford",
		 ID: "BSHS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hanover Insurance Company",
		 ID: "45221",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hanover Lloyd's Insurance Company",
		 ID: "84562",
		 time: "< 30 mins"
	},
	{
		 NAME: "HAP Midwest Health Plans",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HAP/AHL/Curanet",
		 ID: "61325",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Harbor Health Plan (ProCare)",
		 ID: "AVA02",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Harleysville Insurance Company",
		 ID: "48026",
		 time: "< 30 mins"
	},
	{
		 NAME: "Harmony Health Plan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Harrington Health - Kansas (Formerly Fiserv/Willis)",
		 ID: "65085",
		 time: "< 30 mins"
	},
	{
		 NAME: "Harrington Health Non-EPO",
		 ID: "39144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hartford Accident and Indemnity Company",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hartford Casualty Insurance Company",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hartford Insurance Company of the Midwest",
		 ID: "00800",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hartford Life and Accident Insurance Company",
		 ID: "20554",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hartford Life and Annuity Insurance Company",
		 ID: "41201",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hartford Life Insurance Company",
		 ID: "AMG02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hartford Underwriters Insurance Company",
		 ID: "HLTHT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Harvard Community Health Plan (AKA Harvard Pilgrim Stride)",
		 ID: "RCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Harvard Pilgrim Health Care",
		 ID: "VAFSC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Harvard Pilgrim Passport Connect",
		 ID: "52312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hawaii Employers' Mutual Insurance Co., Inc",
		 ID: "95998",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hawaii Management Alliance Association (HMAA)",
		 ID: "11150",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hawaii Medical Alliance Association (HMAA)",
		 ID: "ABRI1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hawaii Medical Assurance Association (HMAA)",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hawaii Western Management Group (HWMG)",
		 ID: "MHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hawkeye ",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "HCA - Hospital Corporation of America ",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HCC Life Insurance",
		 ID: "59266",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HCH Administration - Illinois",
		 ID: "03443",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HCH Administration, Inc.",
		 ID: "80095",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HCS - Health Claims Service",
		 ID: "ALLCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "HDPC - Premier Care (High Desert Primary Care Premier)",
		 ID: "10569",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health 2 Business",
		 ID: "J1998",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Alliance Medical Plan",
		 ID: "RCHN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Alliance Plan of Michigan",
		 ID: "FCMA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Assurance - Health America, Inc.",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Care District Palm Beach",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Care Facilities of Missouri, Inc. - CORVEL",
		 ID: "93688",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Care LA IPA (HCLA)",
		 ID: "55768",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Choice Generations",
		 ID: "99660",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Choice Integrated Care (HCIC)",
		 ID: "09830",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Choice of Arizona",
		 ID: "07202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Choice of Utah",
		 ID: "MPM34",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Choices",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Comp",
		 ID: "CVH01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Cost Solutions (HCS)",
		 ID: "88023",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Design Plus - Hudson, Ohio",
		 ID: "AUMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Economic Livelihood Partnership (HELP)",
		 ID: "GRV01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Economics Group Inc.",
		 ID: "00601",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health EOS",
		 ID: "10693",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Excel IPA",
		 ID: "HPPO1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health First Colorado (Medicaid)",
		 ID: "60995",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health First Health Plans",
		 ID: "00611",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Insurance Plan (HIP) of Greater New York (Emblem)",
		 ID: "47080",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Net - California",
		 ID: "CB951",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Net - Medicare Advantage (MA) / Individual Family Plan (IFP)",
		 ID: "13413",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Net - Missouri",
		 ID: "35174",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Net - Oregon",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Net - Washington",
		 ID: "J1663",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Net National",
		 ID: "13174",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Net of the Northeast",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network One",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - Absolute Total Care SC",
		 ID: "RHP01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - BCBS NC",
		 ID: "SCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - CIGNA NC",
		 ID: "10301",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - CIGNA SC",
		 ID: "75234",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - HealthSpring NC",
		 ID: "95467",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - HealthSpring SC",
		 ID: "MCDVT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - MedCost NC",
		 ID: "56089",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - MedCost SC",
		 ID: "HMC02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) - Select Health SC",
		 ID: "95567",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Network Solutions (HNS) HealthTeam Advantage NC",
		 ID: "DX215",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health New England",
		 ID: "07689",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Now New York",
		 ID: "MCDLA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Options (Florida Blue)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Partners of Minnesota",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Partners Pennsylvania",
		 ID: "00630",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Partners Plans (Philadelphia, PA)",
		 ID: "ARMC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Payment Systems, Inc.",
		 ID: "60065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Plan Management",
		 ID: "FLCCR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Plan of Michigan",
		 ID: "66004",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Plan of Nevada",
		 ID: "IP083",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plan of Nevada - Encounters",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plan of San Joaquin",
		 ID: "37602",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Plan of San Mateo",
		 ID: "93222",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plan of San Mateo Care Advantage (HMO)",
		 ID: "CCHPC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plan Services",
		 ID: "00870",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Plans, Inc.",
		 ID: "89461",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plus PHSP",
		 ID: "HDNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Plus Physician Organization (HPPO)",
		 ID: "41124",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Resources, Inc.",
		 ID: "52147",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Services for Children with Special Needs (HSCSN)",
		 ID: "J1786",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Services Management – HSM",
		 ID: "08680",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Share CCO Clackamas County",
		 ID: "77170",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Source MSO",
		 ID: "COCHA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Health Special Risk, Inc.",
		 ID: "89245",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Systems International - ECOH",
		 ID: "68046",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Texas Medical Group",
		 ID: "36389",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health Tradition Health Plan",
		 ID: "10353",
		 time: "< 30 mins"
	},
	{
		 NAME: "Health York Network",
		 ID: "12475",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthBridge",
		 ID: "99726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthcare Group, The",
		 ID: "91617",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthcare Highways",
		 ID: "MCDOK",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthcare Highways Health Plan",
		 ID: "NCXIX",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthcare Management Administrators (HMA)",
		 ID: "ORDHS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthcare Options",
		 ID: "MPM64",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare Partners IPA - New York",
		 ID: "WVCS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare Partners of Nevada",
		 ID: "SCPR1",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare Resources NW",
		 ID: "66170",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthcare Risk Management Solutions - CORVEL",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare Solutions Group",
		 ID: "30166",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare USA",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCare's Finest Network (HFN)",
		 ID: "11001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthChoice of Arizona",
		 ID: "J1046",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthChoice Oklahoma",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthChoice Select",
		 ID: "89234",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthComp",
		 ID: "IUHPL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthCosmos Medical Group LLC\t",
		 ID: "35202",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCosmos of Nevada PLLC",
		 ID: "10030",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthCosmos of New Mexico LLC",
		 ID: "09908",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthEdge Administrators",
		 ID: "CH00102",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthEQ Westlake Medical Center",
		 ID: "PHMD3",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthEZ",
		 ID: "43471",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthfirst - New York",
		 ID: "20333",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthFirst (HMO)",
		 ID: "87071",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthfirst 65 Plus",
		 ID: "54096",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthfirst Family Health Plus (FHP)",
		 ID: "80141",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthfirst Health Plan of New Jersey",
		 ID: "06202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthfirst Inc",
		 ID: "43905",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthfirst of New Jersey",
		 ID: "C1083",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthfirst of New York",
		 ID: "ARISE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthfirst PHSP",
		 ID: "35193",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthFirst TPA",
		 ID: "75289",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthfirst Tyler, Texas",
		 ID: "TAYLR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthgram",
		 ID: "J1028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthGroup Limited",
		 ID: "TLINS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthlink PPO",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthNet – Gov’t Program",
		 ID: "87020",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthPlan Services",
		 ID: "CCHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthplex",
		 ID: "39156",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthPlus Managed Long Term Care",
		 ID: "63092",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthPlus of Louisiana",
		 ID: "EC999",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthPlus of Michigan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSCOPE Benefits, Inc.",
		 ID: "10088",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthScope UMR",
		 ID: "46045",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthShare CCO Dental",
		 ID: "HSCD1",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthShare of Oregon CCO - Multnomah (Formerly known as Verity)",
		 ID: "00224",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSmart Accel",
		 ID: "MR006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthSmart Benefit Solutions (formerly American Administrative/Gallagher Benefit)",
		 ID: "MPM44",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSmart Benefit Solutions (formerly JSL Administrators)",
		 ID: "J1950",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthSmart Benefit Solutions (Formerly Pittman and Associates)",
		 ID: "95019",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthSmart Benefit Solutions (formerly Wells Fargo TPA/Acordia National)",
		 ID: "C1SCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSmart MSO",
		 ID: "66171",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSmart Preferred Care",
		 ID: "UIC67",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthsource (CIGNA)",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthsource CMHC",
		 ID: "SHCAR",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSpan Inc",
		 ID: "128FL",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSpring (formerly Bravo Health/Elder Health)",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSpring HMO",
		 ID: "94146",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSpring Medicare + Choice",
		 ID: "E4618",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthSpring of Alabama",
		 ID: "TN000214",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthsun Health Plan",
		 ID: "MLNUT",
		 time: "< 30 mins"
	},
	{
		 NAME: "HealthTeam Advantage",
		 ID: "59374",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HealthTeam Advantage Diabetes and Heart Care Plan",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthways WholeHealth Network",
		 ID: "88057",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy Blue LA",
		 ID: "14966",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthy Blue Missouri",
		 ID: "06105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthy Blue Nebraska",
		 ID: "93220",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthy Blue North Carolina",
		 ID: "37264",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthy CT",
		 ID: "CX100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Healthy Equation",
		 ID: "77943",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy Families Program",
		 ID: "CHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy Montana Kids (HMK)",
		 ID: "00661",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy Palm Beaches, Inc.",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy San Francisco",
		 ID: "PHM11",
		 time: "< 30 mins"
	},
	{
		 NAME: "Healthy Valley Provider Network",
		 ID: "MHPMI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Heartland Express - CORVEL",
		 ID: "26375",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Helmsman Management Services",
		 ID: "SPA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hemet Community Medical Group (HCMG)",
		 ID: "J1011",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hennepin Health (formerly Metropolitan Health Plan)",
		 ID: "GHOKC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hereford Insurance",
		 ID: "J1595",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Heritage Consultants",
		 ID: "55204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Heritage IPA",
		 ID: "00710",
		 time: "< 30 mins"
	},
	{
		 NAME: "Heritage Physicians Network (HPN)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Heritage Provider Network (Regal)",
		 ID: "CCHPC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Heritage Victor Valley Medical Group (Regal)",
		 ID: "71064",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hernando Pasco Hospice",
		 ID: "23229",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hershey Healthsmile",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hewitt Coleman",
		 ID: "J1780",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HHS National Disaster Medical System-Apprio",
		 ID: "04293",
		 time: "> 30 Mins"
	},
	{
		 NAME: "High Desert Medical Group (Regal)",
		 ID: "SOLIS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Highline MSO - Molina",
		 ID: "10477",
		 time: "< 30 mins"
	},
	{
		 NAME: "Highmark BCBS of Western New York",
		 ID: "J1906",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Highmark Health Options",
		 ID: "DOCSO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Highmark Senior - Freedom Blue PPO (Pennsylvania)",
		 ID: "12394",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Highmark Senior Health Company (Pennsylvania)",
		 ID: "SC051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Highmark Senior Solutions Company - West Virginia",
		 ID: "61101",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hill Physicians Aetna PPO",
		 ID: "25169",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians Blue Cross PPO",
		 ID: "41124",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians Blue Shield PPO",
		 ID: "31650",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians Cigna PPO",
		 ID: "76045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians Health Net PPO",
		 ID: "RGA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians Medical Group (HPMG)",
		 ID: "03443",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hill Physicians United Healthcare PPO",
		 ID: "BKRFM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hill UHCSR PPO",
		 ID: "C1085",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hilliard Corporation (UCS)",
		 ID: "HESUN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hillshire Brands - CORVEL",
		 ID: "MR008",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hilmar Cheese Company - CORVEL",
		 ID: "24735",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hinsdale Medicare Advantage",
		 ID: "23212",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hinsdale Physicians Healthcare",
		 ID: "UMHCJ",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HIP Healthplan of NY",
		 ID: "J1601",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hispanic Physician IPA (Encounters Only)",
		 ID: "BS001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hispanic Physicians IPA (Fee For Service Only)",
		 ID: "13550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HK Administrative Partners LLC",
		 ID: "ASP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "HMA Hawaii",
		 ID: "86066",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HMC HealthWorks",
		 ID: "ZH2C",
		 time: "< 30 mins"
	},
	{
		 NAME: "HMO LA Blue Advantage",
		 ID: "C1086",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HMO Louisiana Blue Advantage",
		 ID: "J1250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HMS Vineyards - CORVEL",
		 ID: "89247",
		 time: "< 30 mins"
	},
	{
		 NAME: "HMSA Quest",
		 ID: "HWC02",
		 time: "< 30 mins"
	},
	{
		 NAME: "HMSHost - CORVEL",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "HN1 Therapy Network",
		 ID: "99660",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HNS WellCare SC\t",
		 ID: "EIPA9",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hoag Affiliated Physicians",
		 ID: "48143",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hoag Clinic (Hoag Physician Partners)",
		 ID: "MCCVA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hockenberg Equipment",
		 ID: "HCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Holista, LLC",
		 ID: "53684",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hollywood Pres GLOBAL",
		 ID: "CCHPL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hollywood Presbyterian Medical Center - Preferred (HPMC - PMG)",
		 ID: "76498",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hollywood Presbyterian Medical Center - San Judas IPA (HPMC - SJM)",
		 ID: "93092",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hollywood Presbyterian Medical Center - St. Vincent's IPA (HPMC - SVI)",
		 ID: "88029",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hollywood Presbyterian Medical Group",
		 ID: "BS059",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hollywood Presbyterian San Judas",
		 ID: "46594",
		 time: "< 30 mins"
	},
	{
		 NAME: "Home State - Medical",
		 ID: "GARET",
		 time: "< 30 mins"
	},
	{
		 NAME: "Homeland Security (AKA ICE Health)",
		 ID: "37272",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HomeLink",
		 ID: "J3950",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Homelink HealthPartners",
		 ID: "M3CA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hometown Health Plan Nevada",
		 ID: "07503",
		 time: "< 30 mins"
	},
	{
		 NAME: "Homewood Resorts",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Horizon BCBS of New Jersey",
		 ID: "20044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Horizon Casualty Services",
		 ID: "35206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Horizon NJ Health",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "Horizon Valley Medical Group",
		 ID: "77039",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hortica",
		 ID: "00851",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hortica Florist Mutual Insurance",
		 ID: "58213",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hospital Housekeeping Systems (HHS) - CORVEL",
		 ID: "J1562",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hotel Employees & Restaurant Employees Health Trust, Group # F19",
		 ID: "62111",
		 time: "< 30 mins"
	},
	{
		 NAME: "Housing Authority El Paso (TX)",
		 ID: "55413",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Houston Hunters Patrol, Inc.",
		 ID: "00541",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Howard County Government (MD) - CORVEL",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "HSA Health Insurance Company",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "HSI Care Services",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "HSM - Health Services Management",
		 ID: "45289",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hudson Health Plan (now MVP)",
		 ID: "MPM54",
		 time: "< 30 mins"
	},
	{
		 NAME: "Human Behavior Institute",
		 ID: "59140",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana",
		 ID: "95432",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Behavioral Health (formerly LifeSynch)",
		 ID: "65391",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Humana Encounters",
		 ID: "LDP24",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Gold Choice",
		 ID: "VS402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Humana Gold Plus",
		 ID: "CH00102",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Health Plans of Ohio",
		 ID: "75318",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Humana Long Term Care (LTC)",
		 ID: "84103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Humana LTSS Claims",
		 ID: "38310",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Ohio 2",
		 ID: "12251",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Ohio Medicaid",
		 ID: "VPA18",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humana Puerto Rico",
		 ID: "WELM2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Humboldt Del Norte Foundation IPA",
		 ID: "VUIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Huntington Park Mission Medical Group",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Hutchinson Technology - CORVEL",
		 ID: "H0657",
		 time: "< 30 mins"
	},
	{
		 NAME: "Hylton Payroll (Benefit Plan Administrators)",
		 ID: "10207",
		 time: "< 30 mins"
	},
	{
		 NAME: "I. E. Shaffer",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IAC Life",
		 ID: "HMAA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "IBC Personal Choice",
		 ID: "58204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IBEW Local 6  - San Francisco Electrical Workers",
		 ID: "89890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IBEW Local 640 & AZ Chapter NECA Health & Welfare Trust",
		 ID: "CB404",
		 time: "< 30 mins"
	},
	{
		 NAME: "IBEW: Local No. 1 Health and Welfare Fund (Mental Health)",
		 ID: "SYMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "IBG Administrators, LLC",
		 ID: "CPNCO",
		 time: "< 30 mins"
	},
	{
		 NAME: "iCARE (Independent Care Health Plan)",
		 ID: "J1417",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ICare Health Options",
		 ID: "IHS06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ichahn Automotive Group (Pep Boys & AutoPlus) - CORVEL",
		 ID: "60550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "iCircle of New York",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IDS Property Casualty",
		 ID: "06111",
		 time: "< 30 mins"
	},
	{
		 NAME: "IDS Property Casualty Insurance Company",
		 ID: "3519M",
		 time: "< 30 mins"
	},
	{
		 NAME: "IEHP Dental (Admin by LIBERTY Dental Plan)",
		 ID: "45210",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IHC Health Solutions",
		 ID: "C1045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IHG Direct",
		 ID: "F2370600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Illinois Casualty",
		 ID: "USMBP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Illinois Health Plans (IHP)",
		 ID: "52312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ILWU Local 21 (FCHN)",
		 ID: "J3759",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMA (Cotiva)",
		 ID: "J2326",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMA, Inc",
		 ID: "00960",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMCare",
		 ID: "65085",
		 time: "< 30 mins"
	},
	{
		 NAME: "Impact Health Share Inc.",
		 ID: "IP082",
		 time: "< 30 mins"
	},
	{
		 NAME: "Imperial Health Holdings Medical Group",
		 ID: "04312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Imperial Health Plan of California Encounters",
		 ID: "37275",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Imperial Health Plan of California, Inc.",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Imperial Insurance Companies",
		 ID: "CHA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Imperial Insurance Companies INC EXCHANGE AZ",
		 ID: "J3845",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Imperial Insurance Companies INC EXCHANGE TX",
		 ID: "38512",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Imperial Insurance Encounters",
		 ID: "UGP19",
		 time: "< 30 mins"
	},
	{
		 NAME: "IMS - Trial Card (TC-IMS)",
		 ID: "39026",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMS Management Services - Texas",
		 ID: "91313",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMS TPA",
		 ID: "CPNCO",
		 time: "< 30 mins"
	},
	{
		 NAME: "IMS TPA: Parton Lumber (UCS)",
		 ID: "00923",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMS TPA: The Spencer Group",
		 ID: "MMMFL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IMS TPA: Walker White, Inc. (UCS)",
		 ID: "20133",
		 time: "< 30 mins"
	},
	{
		 NAME: "IMT",
		 ID: "NMM07",
		 time: "< 30 mins"
	},
	{
		 NAME: "IN Physician Associates",
		 ID: "HCHHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "In Physicians Associates – ACTY",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IncentiCare",
		 ID: "11695",
		 time: "< 30 mins"
	},
	{
		 NAME: "INDECS Corporation",
		 ID: "MCDAZ",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence Administrators",
		 ID: "54763",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence American, Ins. Co.",
		 ID: "85468",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence Blue Cross",
		 ID: "77032",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence Care Systems / FIDA-MMP",
		 ID: "00851",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence Medical Group - Kern County",
		 ID: "J1810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independence Medical Group - Tulare County ",
		 ID: "84146",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Independent Health",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Independent Living System",
		 ID: "MMFMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indian Health Services / Veteran Affairs",
		 ID: "QCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indiana Farmers Mutual - CORVEL",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indiana Insurance",
		 ID: "UHA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indiana ProHealth Network",
		 ID: "76253",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indiana University (IU) Health Plan – Commercial/Marketplace",
		 ID: "HCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Indiana University (IU) Health Plan – Medicare Advantage",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "Individual Health Insurance Companies",
		 ID: "83028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "iNetico Inc",
		 ID: "71890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Infinity IPA",
		 ID: "PRNLK",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ingham Health Plan Corp.",
		 ID: "FT254",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ingles Markets",
		 ID: "11329",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Inland Empire Electrical Trust (FCHN)",
		 ID: "LIFE1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Inland Empire Health",
		 ID: "17882",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Empire Health Medicare",
		 ID: "PROH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Empire Health Plan",
		 ID: "LDP19",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Empire Medi-cal",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Faculty Medical Group (MV Medical)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Inland Faculty Medical Group Encounters (MV Medical)",
		 ID: "ARGUS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Health Organization (IHO)",
		 ID: "100707",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inland Valley - (Redlands IPA)",
		 ID: "PROH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "InnovAge",
		 ID: "3519M",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovante Benefit Administrator",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovation Health (Aetna)",
		 ID: "J4305",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovative Care Systems (ICS) - San Ramon",
		 ID: "HPPZZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Innovative Claim Solutions (ICS) - Rancho Cordova - CORVEL",
		 ID: "37105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovative Healthware  6",
		 ID: "05202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovative  2",
		 ID: "23334",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Innovative  3",
		 ID: "KELSE",
		 time: "< 30 mins"
	},
	{
		 NAME: "INS Health Services (AKA ICE Health)",
		 ID: "REGAL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Inservco",
		 ID: "INET1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Insurance Administrator of America, Inc. (IAA)",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Insurance Benefit System Administrators",
		 ID: "MCDAR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Insurance Company of the West (ICW)",
		 ID: "78521",
		 time: "< 30 mins"
	},
	{
		 NAME: "Insurance Design Administrators",
		 ID: "20538",
		 time: "< 30 mins"
	},
	{
		 NAME: "Insurance Management Services",
		 ID: "63092",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Insurance Program Managers Group (IPMG)",
		 ID: "J1791",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Insurance Systems",
		 ID: "00590",
		 time: "< 30 mins"
	},
	{
		 NAME: "Insurance TPA",
		 ID: "PFIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Insurers Administrative Corp.",
		 ID: "MPM55",
		 time: "< 30 mins"
	},
	{
		 NAME: "Integon General Insurance Corp.",
		 ID: "09173",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Integra Administrative Group",
		 ID: "38216",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Integra BMS",
		 ID: "13010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Integra Group",
		 ID: "66727",
		 time: "< 30 mins"
	},
	{
		 NAME: "Integral Quality Care",
		 ID: "24818",
		 time: "< 30 mins"
	},
	{
		 NAME: "IntegraNet",
		 ID: "FLCPC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Integrated Eye Care Network",
		 ID: "94999",
		 time: "< 30 mins"
	},
	{
		 NAME: "Integrated Health Partners (IHP)",
		 ID: "22417",
		 time: "< 30 mins"
	},
	{
		 NAME: "Integrated Home Care Services, Inc.\t",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Integrated Medical Solutions",
		 ID: "J1535",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Integrity Mutual Insurance Company",
		 ID: "75261",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Inter Valley Health Plan (IVHP)",
		 ID: "95567",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Interactive Medical Systems",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Inter-Americas Insurance",
		 ID: "J1795",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intercare",
		 ID: "ACPCE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intercare - Sussex",
		 ID: "61184",
		 time: "> 30 Mins"
	},
	{
		 NAME: "InterCommunity Health CCO (IHN)",
		 ID: "68035",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intercommunity Health Net",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intergovernmental Risk Management Agency (IRMA) - CORVEL",
		 ID: "PCU01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Intergroup Services Corporation",
		 ID: "37121",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Interinsurance Exchange of the Automobile Club - CORVEL",
		 ID: "05655",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intermountain Ironworkers Trust Fund",
		 ID: "41178",
		 time: "> 30 Mins"
	},
	{
		 NAME: "International Benefit Administrator",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "International Brotherhood of Boilermakers",
		 ID: "73100",
		 time: "< 30 mins"
	},
	{
		 NAME: "International Educational Exchange Service (IEES)",
		 ID: "66010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "International Medica Group Inc",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "International Medical Group (IMG)",
		 ID: "37307",
		 time: "> 30 Mins"
	},
	{
		 NAME: "International Union of Operating Engineers ~ Local 15, 15A, 15C & 15D",
		 ID: "53122",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Intervalley",
		 ID: "61101",
		 time: "< 30 mins"
	},
	{
		 NAME: "InterWest Health PPO",
		 ID: "SB810",
		 time: "< 30 mins"
	},
	{
		 NAME: "Iowa Health Advantage",
		 ID: "ACC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Iowa Total Care",
		 ID: "LWA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "IPMG Claims Management Services",
		 ID: "38333",
		 time: "> 30 Mins"
	},
	{
		 NAME: "IQVIA",
		 ID: "66043",
		 time: "< 30 mins"
	},
	{
		 NAME: "ISS Facility Services - CORVEL",
		 ID: "00806",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Itasca Medical Center",
		 ID: "36345",
		 time: "> 30 Mins"
	},
	{
		 NAME: "J B Hunt Transport Inc.",
		 ID: "77250",
		 time: "< 30 mins"
	},
	{
		 NAME: "J. F. Molloy and Associates",
		 ID: "31500",
		 time: "> 30 Mins"
	},
	{
		 NAME: "J5 National Part A",
		 ID: "34080",
		 time: "> 30 Mins"
	},
	{
		 NAME: "JADE Health Care Medical Group",
		 ID: "82056",
		 time: "< 30 mins"
	},
	{
		 NAME: "Jai Medical Systems",
		 ID: "63240",
		 time: "< 30 mins"
	},
	{
		 NAME: "Jefferson Pilot Financial",
		 ID: "48330",
		 time: "< 30 mins"
	},
	{
		 NAME: "Jencare Medical",
		 ID: "MHCM2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Jericho Share",
		 ID: "65482",
		 time: "< 30 mins"
	},
	{
		 NAME: "JI Specialty Services",
		 ID: "22771",
		 time: "< 30 mins"
	},
	{
		 NAME: "JLS Family Enterprises (dba League Medical Concepts)",
		 ID: "06102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "John Hancock Health Security Plan",
		 ID: "MPM51",
		 time: "< 30 mins"
	},
	{
		 NAME: "John Muir Physician Network",
		 ID: "CKC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "John Muir Trauma Physicians",
		 ID: "87605",
		 time: "< 30 mins"
	},
	{
		 NAME: "John P. Pearl & Associates, Ltd.",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Johns Hopkins Health Advantage",
		 ID: "35098",
		 time: "< 30 mins"
	},
	{
		 NAME: "Johns Hopkins Healthcare",
		 ID: "48100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Johns Hopkins Healthcare (USFHP)",
		 ID: "13590",
		 time: "< 30 mins"
	},
	{
		 NAME: "Johns Hopkins Home Care Group",
		 ID: "84132",
		 time: "> 30 Mins"
	},
	{
		 NAME: "JP Farley Corporation",
		 ID: "RMC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "JP Specialities",
		 ID: "54828",
		 time: "> 30 Mins"
	},
	{
		 NAME: "JWF Specialty",
		 ID: "73236",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser CSI - California Select for Individuals",
		 ID: "J2134",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Colorado",
		 ID: "THRIV",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Georgia",
		 ID: "L0230",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Mid-Atlantic",
		 ID: "C1038",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Northern CA Region",
		 ID: "25844",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Southern CA Region",
		 ID: "OCN01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of the Mid-Atlantic States",
		 ID: "12963",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of the Northwest",
		 ID: "SC004",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Foundation Health Plan of Washington",
		 ID: "89070",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Permanente Health Plan of Hawaii",
		 ID: "86145",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Permanente of Northern California",
		 ID: "60054",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Permanente of Southern California",
		 ID: "65465",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kaiser Self-Funded",
		 ID: "AMIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kalos Health",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "KAMG Hospital",
		 ID: "J1972",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kane County IPA",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kansas Health Advantage (aka Kansas Superior Select)",
		 ID: "94302",
		 time: "< 30 mins"
	},
	{
		 NAME: "Katy Medical Group",
		 ID: "36149",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kaweah Delta HC District Emp Plan",
		 ID: "43471",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kaweah Delta Medicare Advantage",
		 ID: "36326",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keenan",
		 ID: "76253",
		 time: "< 30 mins"
	},
	{
		 NAME: "Keenan & Associates",
		 ID: "HPSM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Keenan and Associates (Excluding City of Pasadena)",
		 ID: "81400",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kellogg Companies - CORVEL",
		 ID: "33373",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kelsey Seybold",
		 ID: "61102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kempton Company",
		 ID: "AVA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kempton Group Administrators",
		 ID: "JCARE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kempton Group TPA: Kempton Group Administrators (UCS)",
		 ID: "CB752",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kentucky Health Cooperative",
		 ID: "ECMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kentucky Spirit Health - Medical",
		 ID: "83028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kern County Healthy Kids",
		 ID: "45319",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kern Health Care Network",
		 ID: "ITXEN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kern Health Systems",
		 ID: "VALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kern Legacy Health Plan",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Key Benefit Administrators",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "Key Health Medical Solutions, Inc.",
		 ID: "SX105",
		 time: "< 30 mins"
	},
	{
		 NAME: "Key Medical Group",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Key Medical Group - Medicare Advantage",
		 ID: "COCHA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Key Risk Management Services LLC",
		 ID: "J1941",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Key Select",
		 ID: "CCPN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "KeyCare (MD)",
		 ID: "AMM11",
		 time: "< 30 mins"
	},
	{
		 NAME: "Keystone Connect (AmeriHealth)",
		 ID: "36273",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone First Community Health Choices",
		 ID: "66705",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone First Dental - PA",
		 ID: "AMM15",
		 time: "< 30 mins"
	},
	{
		 NAME: "Keystone First VIP Choice",
		 ID: "HSICS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone Health (Lakeside - Glendale, CA) (Regal)",
		 ID: "J1837",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone Health Plan Central",
		 ID: "72128",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone Health Plan East (KHP)",
		 ID: "EC1CA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone Mercy Health Plan",
		 ID: "23284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Keystone West (Highmark)",
		 ID: "84562",
		 time: "< 30 mins"
	},
	{
		 NAME: "KG Administrative Services",
		 ID: "J1799",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kikkoman Foods Inc - CORVEL",
		 ID: "128MD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Kings County Healthy Kids",
		 ID: "00851",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Klais & Company",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "Klais & Company (Repricing for HealthSpan Network Only)",
		 ID: "75250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "KM Strategic Management (KMSM)",
		 ID: "HLTHQ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Koan Risk Solutions",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Koch Foods, Inc - CORVEL",
		 ID: "128KY",
		 time: "< 30 mins"
	},
	{
		 NAME: "Korean American Medical Group",
		 ID: "95386",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kova Healthcare, Inc.",
		 ID: "CCIH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "KPIC Self-Funded Claims Administrator",
		 ID: "57116",
		 time: "> 30 Mins"
	},
	{
		 NAME: "KPS Healthplans",
		 ID: "J1949",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Kuspuk School District",
		 ID: "75261",
		 time: "> 30 Mins"
	},
	{
		 NAME: "LA Care Health Plan",
		 ID: "22240",
		 time: "< 30 mins"
	},
	{
		 NAME: "LACH HealthNet by MedPOINT",
		 ID: "AHC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "LADOC CorrectCare",
		 ID: "ARCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lafayette Consolidated Government",
		 ID: "MCDKY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lake Region Community Health Plan",
		 ID: "99726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lakes Area Community Health Plan (LACH)",
		 ID: "J1206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lakeside Community Healthcare (Regal)",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lakeside Medical Group (Regal Lakeside)",
		 ID: "87716",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lakewood IPA",
		 ID: "66005",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lancaster General Health (LGH)",
		 ID: "92805",
		 time: "< 30 mins"
	},
	{
		 NAME: "Land of Lincoln Health",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Landmark",
		 ID: "59065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Landrys - CORVEL",
		 ID: "00205",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lapoyner ISD",
		 ID: "76251",
		 time: "< 30 mins"
	},
	{
		 NAME: "Larson Group",
		 ID: "C1043",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Las Vegas Firefighters Health and Welfare Trust",
		 ID: "72630",
		 time: "< 30 mins"
	},
	{
		 NAME: "LaSalle Medical Associates",
		 ID: "J1668",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lassen Municipal Utility District",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lasso Healthcare Msa",
		 ID: "22729",
		 time: "< 30 mins"
	},
	{
		 NAME: "Laundry Workers",
		 ID: "26375",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lawndale Christian Health Center",
		 ID: "16117",
		 time: "> 30 Mins"
	},
	{
		 NAME: "LBA Health Plans",
		 ID: "54704",
		 time: "> 30 Mins"
	},
	{
		 NAME: "LCTA Workers Comp",
		 ID: "88848",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Le Mars Insurance Company",
		 ID: "38310",
		 time: "< 30 mins"
	},
	{
		 NAME: "League Medical Concepts (aka JLS Family Enterprises)",
		 ID: "98022",
		 time: "< 30 mins"
	},
	{
		 NAME: "League of Minnesota Cities Insurance Trust",
		 ID: "VHPLA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Leon Health Plans",
		 ID: "ARMC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Leon Medical Center Health Plan",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "LFP Corporation - CORVEL",
		 ID: "23287",
		 time: "> 30 Mins"
	},
	{
		 NAME: "LHP Claims Unit",
		 ID: "PCU03",
		 time: "< 30 mins"
	},
	{
		 NAME: "LHS/ MedCost Solutions LLC",
		 ID: "20172",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Liberty Advantage",
		 ID: "39168",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Liberty Dental Plan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Liberty Health Advantage",
		 ID: "00239",
		 time: "< 30 mins"
	},
	{
		 NAME: "Liberty Mutual - Auto",
		 ID: "CHA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Liberty Mutual Insurance Co",
		 ID: "83245",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Liberty Mutual Middle Markets",
		 ID: "85600",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Liberty Northwest",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Liberty Union",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "Liberty Wausau",
		 ID: "23626",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Life Assurance Company",
		 ID: "RHA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Life Benefit Plan",
		 ID: "10112",
		 time: "< 30 mins"
	},
	{
		 NAME: "Life Investors Insurance of America - Long Term Care",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Life St. Mary (Trinity Health Pace)",
		 ID: "11705",
		 time: "< 30 mins"
	},
	{
		 NAME: "Life Trac",
		 ID: "VACCN",
		 time: "< 30 mins"
	},
	{
		 NAME: "LifeCare Assurance Co",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lifeshield National Insurance Co.",
		 ID: "SCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lifetime Benefit Solutions",
		 ID: "CCMMH",
		 time: "< 30 mins"
	},
	{
		 NAME: "LifeWell Health Plans",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "LifeWise Healthplan of Oregon (Premera)",
		 ID: "32002",
		 time: "> 1 hours"
	},
	{
		 NAME: "LifeWise Healthplan of Washington (Premera)",
		 ID: "04271",
		 time: "> 1 hours"
	},
	{
		 NAME: "LifeWorks Advantage (ISNP plan)",
		 ID: "PMAI2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lighthouse Health Plan",
		 ID: "10049",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lincoln National of Wisconsin",
		 ID: "IHS08",
		 time: "< 30 mins"
	},
	{
		 NAME: "Linden Oaks Behavioral Health",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Link Snacks (Jacks Links)",
		 ID: "SKUT0",
		 time: "< 30 mins"
	},
	{
		 NAME: "Little Company of Mary",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Live Well IPA",
		 ID: "59322",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Local 137 Operating Engineers Welfare Fund",
		 ID: "42172",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Local 670 Engineers",
		 ID: "LACAR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Local 670 Engineers VIP",
		 ID: "00154",
		 time: "< 30 mins"
	},
	{
		 NAME: "Local 670 VIP",
		 ID: "CCHP9",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lockard & Williams",
		 ID: "IP082",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loma Linda University",
		 ID: "AMM17",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loma Linda University Adventist Health Sciences Center",
		 ID: "J2282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Loma Linda University Adventist Health Sciences Centers",
		 ID: "46923",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loma Linda University Behavorial Medicine Center Employee Health Plan",
		 ID: "10301",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loma Linda University Dept of Risk Management",
		 ID: "RENGQ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loma Linda University Healthcare - ManagedCare Claims",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Loma Linda University Healthcare Employee Health Plan",
		 ID: "41556",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lone Star Medical Group PLLC ",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lone Star TPA",
		 ID: "91062",
		 time: "< 30 mins"
	},
	{
		 NAME: "Long Beach Memorial IPA (MHCLB)",
		 ID: "61184",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Longevity CO",
		 ID: "94265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Longevity FL",
		 ID: "N10917",
		 time: "< 30 mins"
	},
	{
		 NAME: "Longevity IL",
		 ID: "00851",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Longevity MI",
		 ID: "20029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Longevity NC",
		 ID: "41212",
		 time: "< 30 mins"
	},
	{
		 NAME: "Longevity NJ",
		 ID: "27034",
		 time: "< 30 mins"
	},
	{
		 NAME: "Longevity OK",
		 ID: "J1042",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Longs Drugs",
		 ID: "56205",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Loomis - SeeChange Health Insurance",
		 ID: "MC006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Loren Cook",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Los Angeles Medical Center (LAMC)",
		 ID: "14829",
		 time: "< 30 mins"
	},
	{
		 NAME: "Los Angeles Medical Center (LAMC) - Encounters",
		 ID: "84109",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Louisiana Healthcare Connections - Medical",
		 ID: "DAK01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Louisiana State University - CORVEL",
		 ID: "PCJOL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Louisiana Workers Compensation Corporation (LWCC)",
		 ID: "SYMED",
		 time: "> 1 hours"
	},
	{
		 NAME: "Lovelace Salud  (Medicaid)",
		 ID: "SCPR1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lower Kuskokwim School District",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Lowe's",
		 ID: "CPNA1 ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lowe's (Bunch CareSolutions)",
		 ID: "35182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Loyal American Life (Medicare Supplement)",
		 ID: "12422",
		 time: "< 30 mins"
	},
	{
		 NAME: "Loyola Physician Partners",
		 ID: "20446",
		 time: "< 30 mins"
	},
	{
		 NAME: "LUCENT HEALTH",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Lumbermens Underwriting Alliance",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Luther Care",
		 ID: "37230",
		 time: "> 30 Mins"
	},
	{
		 NAME: "LYNX",
		 ID: "95411",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mabuhay Medical Group",
		 ID: "62956",
		 time: "< 30 mins"
	},
	{
		 NAME: "MacNeal Health Providers - CHS",
		 ID: "76048",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magellan Behavioral Health - Case Rate",
		 ID: "MCDVA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magellan Behavioral Health Services",
		 ID: "31348",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magellan Behavioral Health Services (Medicaid NE)",
		 ID: "10589",
		 time: "< 30 mins"
	},
	{
		 NAME: "Magellan Behavioral Health Services (Medicaid VA)",
		 ID: "RENRO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Magellan Complete Care Arizona",
		 ID: "08202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magellan Complete Care of Virginia (MCC of VA)",
		 ID: "44412",
		 time: "< 30 mins"
	},
	{
		 NAME: "Magellan Complete Care VA",
		 ID: "99726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magellan Complete Health of Virginia",
		 ID: "33213",
		 time: "< 30 mins"
	},
	{
		 NAME: "Magellan HIA-CA",
		 ID: "14316",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MagnaCare",
		 ID: "31144",
		 time: "< 30 mins"
	},
	{
		 NAME: "MagnaCare - Oscar Network",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Magnolia Health Plan (Ambetter) - Medical",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "MAHP (MAMSI)",
		 ID: "10539",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mail Handlers Benefit Plan (CAC)",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maine Community Health Options",
		 ID: "99726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Major League Baseball 8",
		 ID: "VHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "MAMSI",
		 ID: "EPSDR",
		 time: "< 30 mins"
	},
	{
		 NAME: "MAMSI Health Plan",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Managed Care of North America (Florida)",
		 ID: "06102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Managed Care of North America (Kentucky)",
		 ID: "J1637",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Managed Care Services",
		 ID: "MVCV2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Managed Care Systems",
		 ID: "10090",
		 time: "< 30 mins"
	},
	{
		 NAME: "Managed DentalGuard",
		 ID: "J1902",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Managed Health Network (MHN)",
		 ID: "65062",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Managed Health Services - WI State Employees",
		 ID: "AMDA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Managed Health Services Indiana (Ambetter) - Medical",
		 ID: "BCSMT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Managed Health Services Wisconsin (Ambetter) - Medical",
		 ID: "HLPUH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Managed Healthcare Associates",
		 ID: "09302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mansfield I.S.D.",
		 ID: "45341",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MAPFRE Insurance",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "March Vision Care",
		 ID: "SC050",
		 time: "< 30 mins"
	},
	{
		 NAME: "Maricopa Care Advantage",
		 ID: "J1577",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maricopa Health Plan (UAHP)",
		 ID: "09908",
		 time: "< 30 mins"
	},
	{
		 NAME: "Marpai",
		 ID: "22099",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Marrick Medical Finance, LLC",
		 ID: "20805",
		 time: "< 30 mins"
	},
	{
		 NAME: "Martins Point Health Care",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mary Washington Health Plan",
		 ID: "J1740",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maryland Healthy Smiles - DHMH",
		 ID: "81671",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maryland Physicians Care",
		 ID: "BCBSMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Maryland Physicians Care (MPC)",
		 ID: "FRFLY",
		 time: "< 30 mins"
	},
	{
		 NAME: "Maryland Physicians Care MCO",
		 ID: "34192",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maryland Uninsured Employers Fund - CORVEL",
		 ID: "IP083",
		 time: "< 30 mins"
	},
	{
		 NAME: "Maryland’s Medicaid Public Behavioral Health System",
		 ID: "SHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mashantucket Pequot Tribal Nation",
		 ID: "MPM46",
		 time: "< 30 mins"
	},
	{
		 NAME: "Masonry Industry Trust",
		 ID: "10190",
		 time: "< 30 mins"
	},
	{
		 NAME: "Masonry Welfare Trust Fund",
		 ID: "SIM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mass General Brigham Health Plan",
		 ID: "J1721",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Massachusetts Bay Insurance Company - CORVEL",
		 ID: "41194",
		 time: "< 30 mins"
	},
	{
		 NAME: "Massachusetts Behavioral Health\t",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Masters Mates and Pilots Plan",
		 ID: "EHI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mathis ISD",
		 ID: "GSMPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Matrix Absence Management",
		 ID: "007021305",
		 time: "< 30 mins"
	},
	{
		 NAME: "Maverick Medical Group",
		 ID: "J3756",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Maxor Administrative Services",
		 ID: "95167",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mayo Clinic Recovery & Claims Services ",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MBA Benefit Administrators",
		 ID: "37253",
		 time: "< 30 mins"
	},
	{
		 NAME: "MBA of Wyoming",
		 ID: "60601",
		 time: "< 30 mins"
	},
	{
		 NAME: "MBS (Formerly MedCost Benefit Services)",
		 ID: "74238",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MCA Administrators, Inc.",
		 ID: "39180",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MCA Sedgwick",
		 ID: "43160",
		 time: "> 30 Mins"
	},
	{
		 NAME: "McCreary Corporation",
		 ID: "PARTH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "McGregor PACE",
		 ID: "CHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "McLaren Advantage SNP",
		 ID: "DDPAR",
		 time: "< 30 mins"
	},
	{
		 NAME: "McLaren Health Advantage",
		 ID: "54828",
		 time: "< 30 mins"
	},
	{
		 NAME: "McLaren Health Plan (MHP) Commercial",
		 ID: "SMPLY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "McLaren Health Plan (MHP) Medicaid",
		 ID: "SCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "McMullen County ISD",
		 ID: "84133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MD Anderson Physician Network",
		 ID: "84103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MD Partners IPA",
		 ID: "20446",
		 time: "< 30 mins"
	},
	{
		 NAME: "MDwise Health Indiana Plan (DOS on or After 1/1/2019)",
		 ID: "GHAOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "MDwise Hoosier Care Connect",
		 ID: "AAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "MDwise Hoosier Healthwise (DOS on or after 1/1/2019))",
		 ID: "14165",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MDX Hawaii",
		 ID: "66727",
		 time: "< 30 mins"
	},
	{
		 NAME: "Meadowbrook Insurance Company",
		 ID: "95444",
		 time: "< 30 mins"
	},
	{
		 NAME: "MED3000 California",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedAdmin Solutions",
		 ID: "SB580",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedAdmin Solutions (Self funded plans)",
		 ID: "95567",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedBen",
		 ID: "88056",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedCom",
		 ID: "71070",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedCore HP",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedCost, Inc.",
		 ID: "71499",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedDirect",
		 ID: "SCACO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medequip Inc",
		 ID: "RP063",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medex Health Network Inc",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medfocus",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medica",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medica Government Programs",
		 ID: "00265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medica Health Care Plan - Florida",
		 ID: "63842",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medica Health Plan Solutions",
		 ID: "IHPEN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medica HealthCare Plan of Florida",
		 ID: "LNDMK",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medica2",
		 ID: "BPS77",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Alabama",
		 ID: "04212",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Alaska",
		 ID: "58169",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Arizona",
		 ID: "12572",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Arkansas",
		 ID: "64088",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Colorado",
		 ID: "VALOP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Colorado (Behavioral Health)",
		 ID: "CH101",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Connecticut",
		 ID: "95378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Delaware",
		 ID: "00544",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid District of Columbia",
		 ID: "COMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Florida",
		 ID: "MAMS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Georgia",
		 ID: "RENBL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Hawaii",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Idaho",
		 ID: "MCDID",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Illinois",
		 ID: "95729",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Indiana",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Iowa",
		 ID: "11849",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Kansas",
		 ID: "55731",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Kentucky",
		 ID: "55247",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Louisiana",
		 ID: "PSKW0",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Louisiana Ambulance Only ( ID REQD)",
		 ID: "HPPZZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Louisiana DME Only ( ID REQD)",
		 ID: "53120",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Maine",
		 ID: "20044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Maryland",
		 ID: "94564",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Maryland MHS (PMHS)",
		 ID: "52563",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Massachusetts",
		 ID: "59331",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Michigan",
		 ID: "65241",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Minnesota",
		 ID: "POP07",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Mississippi",
		 ID: "68756",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Missouri",
		 ID: "CLFM1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Montana",
		 ID: "251VA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Nebraska",
		 ID: "C1SCA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Nevada",
		 ID: "99320",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid New Hampshire",
		 ID: "39026",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid New Jersey",
		 ID: "65415",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid New Mexico",
		 ID: "77350",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid New Mexico - Presbyterian Salud",
		 ID: "57254",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid New York",
		 ID: "19450",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid North Carolina",
		 ID: "60230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid North Dakota",
		 ID: "75309",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Alaska",
		 ID: "19437",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Arizona (AHCCCS)",
		 ID: "00932",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Arkansas",
		 ID: "76031",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Connecticut",
		 ID: "37266",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of District of Columbia",
		 ID: "SJHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Georgia",
		 ID: "75190",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Georgia, Grady Healthcare, Inc",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Hawaii",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Idaho",
		 ID: "PCMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Idaho ",
		 ID: "13162",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Illinois",
		 ID: "00890",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Illinois ",
		 ID: "J1026",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Iowa",
		 ID: "00402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Kansas",
		 ID: "86098",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Kentucky",
		 ID: "60230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Kentucky (Doral Dental / Region #3)",
		 ID: "37216",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Louisiana",
		 ID: "99030",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Maine",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Maryland, PhysiciansCare MCO",
		 ID: "99320",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Massachusetts",
		 ID: "39113",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Minnesota",
		 ID: "00510",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Mississippi",
		 ID: "MP001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Missouri",
		 ID: "66008",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Missouri, Blue Advantage",
		 ID: "08202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Missouri, Community Care Plus - St. Louis",
		 ID: "45302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Missouri, Community Health\\ Plan",
		 ID: "J1437",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Missouri, FirstGuard Health Plan",
		 ID: "00213",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Missouri, HealthCare USA - St. Louis",
		 ID: "24077",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Missouri, HealthNet",
		 ID: "MPHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Missouri, TrumanCare - Family Health Partners",
		 ID: "J1992",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Montana",
		 ID: "MPM33",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Nebraska, United HealthCare of the Midlands",
		 ID: "89237",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Nevada",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of New Hampshire",
		 ID: "37050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of New Jersey",
		 ID: "34181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of New Mexico",
		 ID: "65062",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of New Mexico, Cimmaron Health Plan",
		 ID: "MPM62",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of New Mexico, Lovelace Community Health Plan",
		 ID: "L0230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of New Mexico, Presbyterian Salud",
		 ID: "70259",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of New York",
		 ID: "33033",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of New York (Adminstrated by Americhoice)",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of North Carolina",
		 ID: "35182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of North Dakota",
		 ID: "J2199",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Ohio, Emerald HMO and Medicare, Inc.",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Ohio, Health Power HMO, Cincinnati",
		 ID: "95044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Ohio, Personal Physicians Care, Cleveland",
		 ID: "52103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Ohio, United HealthCare, Inc. Medicare Complete",
		 ID: "19434",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Oklahoma",
		 ID: "J3743",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Oklahoma, Community Care",
		 ID: "SCXIX",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Oklahoma, Heartland Health Plan",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Oklahoma, Unicare Health Plan",
		 ID: "MPM62",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Oregon",
		 ID: "41124",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania",
		 ID: "J1796",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania (Administered by Americhoice)",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania, Best Health Care, Pittsburgh",
		 ID: "74323",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania, Gateway Health Plan, Pittsburgh",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania, Health Partners, Philadelphia",
		 ID: "SAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Pennsylvania, Oaktree",
		 ID: "LMI01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of South Carolina",
		 ID: "DDPOK",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of South Dakota",
		 ID: "59140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Tennessee, BlueCare",
		 ID: "HSMSO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Texas",
		 ID: "00885",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Texas - Delta Dental",
		 ID: "52095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Texas - MCNA ",
		 ID: "80314",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Utah",
		 ID: "31059",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Virginia, HealthKeepers Plus",
		 ID: "23284",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid of Wisconsin (Administered by Doral Dental of Wisconsin)",
		 ID: "77003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Wisconsin, Humana / TDC",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid of Wisconsin, PrimeCare",
		 ID: "HPPO1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Ohio",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Oklahoma",
		 ID: "82694",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Oregon",
		 ID: "OMDBH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Pennsylvania",
		 ID: "23283",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Pennsylvania Behavioral Health (Beacon Health Options)",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Rhode Island",
		 ID: "00903",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid South Carolina",
		 ID: "74272",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid South Carolina (Blue Choice)",
		 ID: "AMM03",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid South Dakota",
		 ID: "94135",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Texas",
		 ID: "46120",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Texas (Premier Plan)",
		 ID: "PMAI2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid TN/TennCare",
		 ID: "TXABA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Utah",
		 ID: "15003",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Utah Crossovers",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Vermont",
		 ID: "RR3426",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Virginia",
		 ID: "41150",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Washington",
		 ID: "SCNAN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Washington DC",
		 ID: "22417",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid West Virginia",
		 ID: "60389",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicaid Wisconsin",
		 ID: "25404",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicaid Wyoming",
		 ID: "OMDBH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medi-Cal (California Medicaid)",
		 ID: "30167",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Associates Health Plan",
		 ID: "77010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medical Benefits Administrators, Inc.",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Benefits Companies",
		 ID: "00430",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Benefits Mutual",
		 ID: "15459",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medical Benefits Mutual Life Insurance Co.",
		 ID: "J3944",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medi-Cal Dental",
		 ID: "84137",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Mutual of Ohio",
		 ID: "10261",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Reimbursements of America",
		 ID: "CKC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Resource Network (MRN)",
		 ID: "91078",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medical Safety Net",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medical Service Company (MCS)",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medical Specialties Managers Inc",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medi-Cal UB Type 02 (Long-Term Care)",
		 ID: "37601",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medi-Cal UB Type 03 (Inpatient)",
		 ID: "RCHN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medical Value Plan-MVP-OH",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare – HHH J11 (Palmetto)",
		 ID: "00402",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare – HHH J15 (CGS)",
		 ID: "J3949",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Alabama",
		 ID: "77777",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Alaska",
		 ID: "13141",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Alaska ",
		 ID: "82029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare and Much More Florida",
		 ID: "74240",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Arizona",
		 ID: "DMELA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Arizona ",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Arkansas",
		 ID: "36312",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Arkansas ",
		 ID: "J1394",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Cahaba ",
		 ID: "J1679",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare California (North)",
		 ID: "89242",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare California (Northern)",
		 ID: "81600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare California (South)",
		 ID: "61425",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare California (Southern)",
		 ID: "CX019",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare California Part A",
		 ID: "88022",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Colorado",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Colorado ",
		 ID: "37281",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Connecticut",
		 ID: "MPM04",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Connecticut ",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Delaware",
		 ID: "95655",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare District of Columbia",
		 ID: "EBC18",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare District Of Columbia ",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Florida",
		 ID: "CALSC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Florida ",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Georgia",
		 ID: "95378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Georgia ",
		 ID: "J3864",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Hawaii",
		 ID: "36334",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Idaho",
		 ID: "95433",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Idaho ",
		 ID: "VMMH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Illinois",
		 ID: "12399",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Illinois ",
		 ID: "34192",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Indiana",
		 ID: "77076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Iowa",
		 ID: "11334",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare J04911",
		 ID: "OCN01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare J12901 - WPS Legacy Part A",
		 ID: "FLCPC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Kansas",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Kansas ",
		 ID: "PH001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Kansas City",
		 ID: "91078",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Kentucky",
		 ID: "RR001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Louisiana",
		 ID: "HPOUV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Maine",
		 ID: "TP021",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Maryland",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Maryland ",
		 ID: "33600",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Massachusetts",
		 ID: "93221",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Massachusetts ",
		 ID: "96385",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Michigan",
		 ID: "86027",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Michigan ",
		 ID: "99943",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Minnesota",
		 ID: "13334",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Mississippi",
		 ID: "31140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Mississippi ",
		 ID: "CAPHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Missouri ",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Missouri (East & West)",
		 ID: "13265",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Montana",
		 ID: "SCNHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Montana ",
		 ID: "73866",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Nebraska",
		 ID: "00903",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Nevada",
		 ID: "00950",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Nevada ",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare New Hampshire",
		 ID: "56144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New Jersey",
		 ID: "J3872",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New Jersey ",
		 ID: "TRULI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New Mexico",
		 ID: "39182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New York - Downstate ",
		 ID: "CMHGF",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare New York - Queens ",
		 ID: "95604",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare New York (Downstate)",
		 ID: "NMMAD",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New York (Empire)",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare New York (Queens)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare New York (Upstate)",
		 ID: "CPS01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare New York Upstate Part B",
		 ID: "77050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare North Carolina",
		 ID: "SB580",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare North Dakota",
		 ID: "37272",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Northern Virginia",
		 ID: "13148",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Ohio",
		 ID: "CHCPI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Ohio ",
		 ID: "77002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Oklahoma",
		 ID: "CEC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Oregon",
		 ID: "31150",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Oregon ",
		 ID: "81810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Pennsylvania",
		 ID: "77013",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Pennsylvania ",
		 ID: "14112",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Railroad",
		 ID: "23045",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Rhode Island",
		 ID: "A0102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Rhode Island ",
		 ID: "ADACS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare South Carolina",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare South Dakota",
		 ID: "38308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare South Dakota ",
		 ID: "16107",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Tennessee",
		 ID: "77170",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Tennessee ",
		 ID: "CPP06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Texas",
		 ID: "31458",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Texas ",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Utah",
		 ID: "CB122",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Vermont",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Vermont ",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Virgin Islands",
		 ID: "10594",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Virginia ",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Virginia (Palmetto)",
		 ID: "NWADM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Washington",
		 ID: "DAKCH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare Washington ",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Washington DC",
		 ID: "HSCD1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Medicare West Virginia",
		 ID: "96107",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare West Virginia ",
		 ID: "56144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Wisconsin",
		 ID: "MR034",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicare Wyoming",
		 ID: "77052",
		 time: "< 30 mins"
	},
	{
		 NAME: "MediChoice IPA",
		 ID: "J1417",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medicina Familiar Medical Group",
		 ID: "20907",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medico Insurance",
		 ID: "36215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medigold",
		 ID: "54771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Medigold PPO",
		 ID: "10463",
		 time: "< 30 mins"
	},
	{
		 NAME: "MediShare (Christian Care Ministries)",
		 ID: "44412",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mediview Sendero CHIP & STAR",
		 ID: "EXC02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mediview UTMB Care UB",
		 ID: "AMHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mediview UTMB3",
		 ID: "57610",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mediview Vista 360 Health",
		 ID: "SC008",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedLogix MSO - Allied Health Solutions",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedPartners Administrative Services",
		 ID: "81508",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedPay Assurance",
		 ID: "J1523",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedRisk",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedSave USA Third Party Administration",
		 ID: "SC054",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedSolutions, Inc",
		 ID: "71404",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedStar Family Choice DC",
		 ID: "CCIHJ",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedStar Family Choice MD",
		 ID: "38336",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedStar Family Choice of DC",
		 ID: "COSAZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedStar Family Choice of Maryland",
		 ID: "38343",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MedView (via SmartData)",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "MedXoom",
		 ID: "36331",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mega Life & Health Insurance - OKC",
		 ID: "39144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mega Life and Health (Frisco, TX)",
		 ID: "MCDIL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Meijer Stores",
		 ID: "PBHD1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MEMIC Casualty Company",
		 ID: "CCA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "MEMIC Indemnity Company",
		 ID: "C1056",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Memorial Clinical Associates (MCA)",
		 ID: "31059",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Memorial Healthcare IPA",
		 ID: "CH0000586",
		 time: "< 30 mins"
	},
	{
		 NAME: "Memorial Hermann Health Network (MHHN)",
		 ID: "MR085",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Memorial Integrated Healthcare (Commercial)",
		 ID: "J3954",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Memorial Integrated Healthcare (Medicaid)",
		 ID: "20818",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Memorial Medical Center - Non Sutter",
		 ID: "06941",
		 time: "< 30 mins"
	},
	{
		 NAME: "MemorialCare Medical Foundation",
		 ID: "73143",
		 time: "< 30 mins"
	},
	{
		 NAME: "MemorialCare Medical Foundation Cap Services",
		 ID: "BHOVO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MemorialCare Medical Foundation UCI",
		 ID: "J1521",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MemorialCare Select Health Plan",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MemorialCare Select Health Plan Encounters",
		 ID: "PCACZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mendota Insurance Company",
		 ID: "90096",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Menifee Valley Community Medical Group",
		 ID: "20029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mental Health Consultants Inc.",
		 ID: "MMMFL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mental Health Network (MHNet)",
		 ID: "75250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Merced County Healthy Kids",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "Merchants Insurance Group",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercury",
		 ID: "74289",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercury General Corporation - Auto",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercy Accountable Care Plan",
		 ID: "128KS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mercy Benefit Administration",
		 ID: "V360H",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercy Care Health Plans – Wisconsin & Illinois ",
		 ID: "98010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercy Care Plan (AHCCCS)",
		 ID: "LFL01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercy Care Plan (Arizona)",
		 ID: "14412",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mercy Care RBHA",
		 ID: "00890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mercy Managed Behavioral Health",
		 ID: "NCPG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mercy Physicians Medical Group (MPMG)",
		 ID: "64084",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mercy Provider Network",
		 ID: "11303",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Meridian Health Plan Illinois Complete",
		 ID: "10550",
		 time: "< 30 mins"
	},
	{
		 NAME: "Meridian Health Plan Michigan Complete",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Meridian Health Plan of Illinois",
		 ID: "BS001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Meridian Health Plan of Iowa",
		 ID: "J3952",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Meridian Health Plan of Michigan",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Merit IPA",
		 ID: "84103",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Meritage Medical Network",
		 ID: "00590",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Meritain Health",
		 ID: "62180",
		 time: "< 30 mins"
	},
	{
		 NAME: "Meritain Health (formerly Weyco, Inc)",
		 ID: "36396",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metal Culverts",
		 ID: "00621",
		 time: "< 30 mins"
	},
	{
		 NAME: "Metcare Health Plans",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Methodist First Choice",
		 ID: "CKC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Metis Services",
		 ID: "NMM05",
		 time: "< 30 mins"
	},
	{
		 NAME: "MetLife",
		 ID: "MCDKY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metro Plus Health Plan",
		 ID: "35206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MetroPlus Health Plan",
		 ID: "00950",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metropolitan Council",
		 ID: "J1037",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metropolitan Health Plan (MHP)",
		 ID: "SX141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metropolitan Health Plan (now Hennepin Health)",
		 ID: "37322",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Metropolitan Property and Casualty Insurance Company",
		 ID: "65099",
		 time: "< 30 mins"
	},
	{
		 NAME: "Metropolitan Transit Authority",
		 ID: "13147",
		 time: "< 30 mins"
	},
	{
		 NAME: "Miami Children's Health Plan",
		 ID: "10205",
		 time: "< 30 mins"
	},
	{
		 NAME: "Miami-Dade County (Fl) - CORVEL",
		 ID: "USFHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MIC General Insurance Corp.",
		 ID: "J3956",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Michigan Fidelis Secure Care - Medical",
		 ID: "39167",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Michigan Medicaid BCCCNP",
		 ID: "BCCCP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Michigan Millers Mutual - CORVEL",
		 ID: "SRS83",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mid Cities IPA",
		 ID: "VAC45",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mid Rogue IPA",
		 ID: "MCDDE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mid Rogue Oregon Health Plan",
		 ID: "36400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mid-America Associates Inc.",
		 ID: "42137",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mid-American Benefits",
		 ID: "MLTOT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mid-American TPA: AAI, Inc. (UCS)",
		 ID: "29076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mid-Atlantic Medical Services, Inc. (MAMSI)",
		 ID: "23550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mid-County Physicians Medical Group",
		 ID: "CB733",
		 time: "< 30 mins"
	},
	{
		 NAME: "Middlesex Insurance Company",
		 ID: "00111",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midlands Choice, Inc.",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midwest Drywall Company",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midwest Family Advantage Insurance Co - CORVEL",
		 ID: "85468",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Midwest Family Mutual (Auto Only) - CORVEL",
		 ID: "BCTF1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Midwest Family Mutual Insurance Co",
		 ID: "MCDNY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Midwest Group Benefits",
		 ID: "LCM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Midwest Health Plans, Inc.",
		 ID: "67671",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midwest Insurance",
		 ID: "60818",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midwest Operating Engineers Welfare Fund",
		 ID: "43178",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MidWest Physician Administrative Services (MPAS)",
		 ID: "MCI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Midwest Security",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Miller Grove ISD",
		 ID: "VACCN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mills Peninsula Health Services (SPS - Sutter)",
		 ID: "MPM28",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mills Peninsula Medical Group (SPS - Sutter)",
		 ID: "38232",
		 time: "< 30 mins"
	},
	{
		 NAME: "Minneapolis Public School - CORVEL",
		 ID: "38334",
		 time: "< 30 mins"
	},
	{
		 NAME: "Minnesota Blue Cross Dental (UCCI)",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Minnesota Counties Ins Trust (MCIT)",
		 ID: "09830",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Minnesota Department of Health",
		 ID: "09824",
		 time: "< 30 mins"
	},
	{
		 NAME: "Minnesota Department of Labor",
		 ID: "UC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Minnesota Health Care Program",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Minnesota Insurance Guarantee Association (MIGA)",
		 ID: "00885",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Minnesota WC Plan",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mission Community IPA",
		 ID: "J1993",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mission Heritage Medical Group",
		 ID: "00630",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mission Hospital Affiliated Physicians",
		 ID: "74101",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mississippi Public Entity  Employee Benefit Trust (MPEEBT)",
		 ID: "67136",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mississippi Select Health Care",
		 ID: "BC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Missoula County Medical Benefits Plan",
		 ID: "VAICE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Missouri Care (MC)",
		 ID: "DEVOT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Missouri Medicare Select",
		 ID: "SIL01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mitsui Sumitomo Insurance Group",
		 ID: "PPNZZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mitsui Sumitomo Insurance USA Inc.",
		 ID: "37330",
		 time: "< 30 mins"
	},
	{
		 NAME: "MMM of Florida",
		 ID: "89070",
		 time: "< 30 mins"
	},
	{
		 NAME: "MMSI (Mayo Health)",
		 ID: "64088",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mobilitas Insurance Company",
		 ID: "J1884",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Moda Health (Formerly ODS Health Plan)",
		 ID: "00265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Moda Health (ODS)",
		 ID: "34097",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Complete Care of Virginia",
		 ID: "ROJW1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Health Care of New Mexico ",
		 ID: "22254",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Health Care of Ohio ",
		 ID: "UHNDC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare Florida",
		 ID: "10441",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare Michigan",
		 ID: "J3913",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Arizona",
		 ID: "56240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of CA Encounters",
		 ID: "11440",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of California",
		 ID: "12268",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Florida",
		 ID: "SPOHN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of Idaho",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Illinois",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Michigan",
		 ID: "71063",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of Mississippi",
		 ID: "C1029",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Nevada ",
		 ID: "29370",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of New Mexico",
		 ID: "00402",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of New Mexico ",
		 ID: "41161",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of New Mexico - Salud",
		 ID: "31650",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of New York (formerly Total Care)",
		 ID: "31155",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Ohio",
		 ID: "17516",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Ohio ",
		 ID: "45399",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Puerto Rico",
		 ID: "DHFIE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of South Carolina",
		 ID: "HLPCG",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of Texas",
		 ID: "J1530",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Utah",
		 ID: "SLOS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of Utah (aka American Family Care)",
		 ID: "25924",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of WA Encounters",
		 ID: "EXC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare of Washington",
		 ID: "37279",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare of Wisconsin",
		 ID: "J1653",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Healthcare South Carolina",
		 ID: "CPPTM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare Utah",
		 ID: "CANO1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Healthcare Wisconsin",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Molina Medicaid Solutions - Idaho",
		 ID: "65030",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Medicaid Solutions - Louisiana",
		 ID: "20090",
		 time: "< 30 mins"
	},
	{
		 NAME: "Molina Ohio Medicaid",
		 ID: "11402",
		 time: "< 30 mins"
	},
	{
		 NAME: "Momentum Insurance Plans, Inc.",
		 ID: "05901",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Monarch Healthcare IPA",
		 ID: "BCSMT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mondial Assistance",
		 ID: "RP088",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Montana Health Co-op",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Montana State Fund - WC",
		 ID: "35374",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Montefiore Contract Management Organization (CMO)",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Montgomery",
		 ID: "10434",
		 time: "< 30 mins"
	},
	{
		 NAME: "Montgomery County Self-Insurance Program (MD) - CORVEL",
		 ID: "CXARC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Montgomery County Texas",
		 ID: "MNDH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Monument Administrators - Guardian Comp",
		 ID: "CDCMD",
		 time: "< 30 mins"
	},
	{
		 NAME: "Monument Administrators - Quality Comp",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Monument Administrators - Victory Comp",
		 ID: "54771",
		 time: "< 30 mins"
	},
	{
		 NAME: "Monumental Life Insurance Company (MLIC)",
		 ID: "96396",
		 time: "< 30 mins"
	},
	{
		 NAME: "Morecare",
		 ID: "62777",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Morris Associates",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Motion Picture Industry Health Plan",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "MotivHealth Insurance Company",
		 ID: "52123",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mountain States Administrators",
		 ID: "J4181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MPLAN, Inc. - HealthCare Group",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "MPM Prospect",
		 ID: "MPM16",
		 time: "< 30 mins"
	},
	{
		 NAME: "MRIPA - AllCare Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "MSA Care Guard",
		 ID: "LACAR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mt. Carmel Health Plan",
		 ID: "WPS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Multicare Connected Care",
		 ID: "72187",
		 time: "< 30 mins"
	},
	{
		 NAME: "Multicultural Primary Care Medical Group",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "MultiPlan GEHA",
		 ID: "J1437",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Multiplan Wisconsin Preferred Network",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Multnomah County Other",
		 ID: "51037",
		 time: "< 30 mins"
	},
	{
		 NAME: "Multnomah Treatment Fund (Formerly known as Verity Plus)",
		 ID: "MR004",
		 time: "< 30 mins"
	},
	{
		 NAME: "Municipal Health Benefit Fund",
		 ID: "12956",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mutual Assurance",
		 ID: "C1080",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mutual Health Services",
		 ID: "38225",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mutual of Omaha",
		 ID: "MHPMI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Mutual of Omaha Dental",
		 ID: "94146",
		 time: "< 30 mins"
	},
	{
		 NAME: "Mutually Preferred",
		 ID: "J3928",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MVP - Ohio",
		 ID: "65391",
		 time: "< 30 mins"
	},
	{
		 NAME: "MVP Health Care (Mohawk Valley)",
		 ID: "MC006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MVP Health Plan of New York ",
		 ID: "10010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "My Family Medical Group",
		 ID: "95397",
		 time: "< 30 mins"
	},
	{
		 NAME: "My Health Plan",
		 ID: "23160",
		 time: "< 30 mins"
	},
	{
		 NAME: "MyChoice IPA (ProCare MSO)",
		 ID: "MCDSD",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MyNexus - Aetna Home Health",
		 ID: "35245",
		 time: "> 30 Mins"
	},
	{
		 NAME: "myNEXUS Anthem",
		 ID: "CHPWA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "MyNexus, Inc.",
		 ID: "RR3426",
		 time: "< 30 mins"
	},
	{
		 NAME: "N.W. Ironworkers Health & Security Trust Fund, Group # F15",
		 ID: "MR083",
		 time: "> 30 Mins"
	},
	{
		 NAME: "N.W. Roofers & Employers Health & Security Trust Fund, Group# F26",
		 ID: "86052",
		 time: "< 30 mins"
	},
	{
		 NAME: "N.W. Textile Processors Group # F14",
		 ID: "UC002",
		 time: "< 30 mins"
	},
	{
		 NAME: "NALC HEALTH BENEFIT PLAN                                                ",
		 ID: "CPHL1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nap Tools (via EMC Risk Services)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Naphcare Inc",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Accident & Health General Agency Inc (NAHGA)",
		 ID: "20356",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Allied Workers Union Local 831",
		 ID: "SOLIS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National American Insurance Co (NAICO)",
		 ID: "MEDEX",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Association of Letter Carriers",
		 ID: "47165",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Association of Letter Carriers (NALC)",
		 ID: "AMBLA",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Capital Preferred Provider Organization (NCPPO)",
		 ID: "91151",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Casualty Company",
		 ID: "63114",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Financial Insurance Company",
		 ID: "80520",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Foundation Life Insurance Company",
		 ID: "92649",
		 time: "< 30 mins"
	},
	{
		 NAME: "National General Insurance Online, Inc.",
		 ID: "J1259",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Interstate",
		 ID: "84367",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Liability and Fire Insurance Company (DOI before 7/1/14)",
		 ID: "L0230",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Liability and Fire Insurance Company (DOI on or after 7/1/2014)",
		 ID: "CRSMD",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Loss Prevention, Inc.",
		 ID: "PDT02",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Pacific Dental - CALIFORNIA",
		 ID: "68063",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Pacific Dental - TEXAS",
		 ID: "38337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Rural Electric Cooperative Assoc.",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Rural Letter Carrier Assoc.",
		 ID: "41150",
		 time: "< 30 mins"
	},
	{
		 NAME: "National Telecommunications Cooperative Association (NTCA - Staff)",
		 ID: "MNDH1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "National Telecommunications Cooperative Association (NTCA)",
		 ID: "MR085",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nationwide - Auto Only",
		 ID: "64088",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nationwide Affinity Insurance Company of America",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nationwide Agribusiness Insurance Co",
		 ID: "66917",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nationwide Ins Company of America",
		 ID: "J1655",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nationwide Mutual Fire Company",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nationwide Specialty Health fka Nationwide Health Plan ",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Native Care Health",
		 ID: "J1547",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NBC Universal",
		 ID: "05047",
		 time: "< 30 mins"
	},
	{
		 NAME: "NCA Comp",
		 ID: "BRIDG",
		 time: "< 30 mins"
	},
	{
		 NAME: "NCA Medical Group",
		 ID: "36393",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NCAS",
		 ID: "J1840",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NCAS - Fairfax, Virginia",
		 ID: "4317M",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NCH Healthcare Inc.",
		 ID: "48611",
		 time: "< 30 mins"
	},
	{
		 NAME: "NCLM - North Carolina League of Municipalities",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ND Workforce Safety and Insurance (WSI)",
		 ID: "75232",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nebraska Plastics Group",
		 ID: "PREHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nebraska Total Care - Medical",
		 ID: "03432",
		 time: "< 30 mins"
	},
	{
		 NAME: "Neighborborhood Health Partnership (NHP)",
		 ID: "LCM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Neighborhood Health Partnership",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Neighborhood Health Plan (NHPRI)",
		 ID: "CRIHB",
		 time: "< 30 mins"
	},
	{
		 NAME: "Neighborhood Health Plan (RI)",
		 ID: "65978",
		 time: "< 30 mins"
	},
	{
		 NAME: "Neighborhood Health Plan RI - Exchange Unity Integrity",
		 ID: "PCN12",
		 time: "< 30 mins"
	},
	{
		 NAME: "Neighborhood Health Providers &  Suffolk Health Plan (NHP - SHP)",
		 ID: "J4250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nephrology IPA",
		 ID: "14512",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Netcare Life & Health Insurance - Hagatna, Guam",
		 ID: "75240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NetWell",
		 ID: "SHM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Network Health Insurance (NHIC) Medicare",
		 ID: "CPP10",
		 time: "< 30 mins"
	},
	{
		 NAME: "Network Health of WI - Commercial",
		 ID: "10015",
		 time: "< 30 mins"
	},
	{
		 NAME: "Network Health of WI - Medicare",
		 ID: "96240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Network Health Plan of Wisconsin - Commercial",
		 ID: "30005",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Network Medical Management",
		 ID: "95422",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Network Solutions IPA",
		 ID: "TP108",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NeueHealth",
		 ID: "36393",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nevada Dental Benefits",
		 ID: "MPM10",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nevada Pacific Dental",
		 ID: "VALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Avenues",
		 ID: "J3766",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Arizona Integrated Physicians",
		 ID: "43351",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - Avmed - Oncology",
		 ID: "77010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Avmed – Radiation Oncology",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health – CAC Centers",
		 ID: "98798",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - CarePlus Cardiology",
		 ID: "61325",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - CarePlus Urology",
		 ID: "11370",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - CarePlus Urology Lab",
		 ID: "IP085",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health – Cigna Medical Group AZ",
		 ID: "EPF03",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - Devoted Health Cardiology",
		 ID: "MEDM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - Devoted Health Oncology",
		 ID: "EPIC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - Florida Blue Medicare – Oncology",
		 ID: "10450",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Florida Blue Medicare - Radiation Oncology",
		 ID: "CAPMN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - HealthSun Cardiology",
		 ID: "J3874",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - HealthSun Oncology",
		 ID: "74205",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Humana Oncology",
		 ID: "STARH",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health - Humana Radiation Oncology",
		 ID: "77073",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Simply Health Care Cardiology",
		 ID: "MRIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Simply Health Care Oncology",
		 ID: "11983",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Simply Health Care Radiation Oncology",
		 ID: "84555",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health - Vista Cardiology - Summit",
		 ID: "PA331",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Century Health Solutions - CarePlus Oncology",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health Solutions – CarePlus Radiation Oncology",
		 ID: "J1711",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health Solutions - Vista Oncology",
		 ID: "J1433",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Century Health Solutions – Vista Radiation Oncology",
		 ID: "CHCN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Directions Behavioral Health (NDBH)",
		 ID: "BHOVO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New England Dental Administrators",
		 ID: "25463",
		 time: "< 30 mins"
	},
	{
		 NAME: "New England, The",
		 ID: "NMMAD",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Era Employee Welfare Benefit",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Era Life",
		 ID: "12860",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Era Life - Employee Benefit Plans",
		 ID: "00932",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Era Life Insurance Company",
		 ID: "RCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Hampshire Healthy Families - Medical",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Jersey Manufacturing (NJM)",
		 ID: "COACC",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Jersey Manufacturing (NJM) - Auto",
		 ID: "00760",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New Life Medical Group, Inc.",
		 ID: "59313",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Mexico District Council of Carpenters",
		 ID: "95327",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Mexico Mutual",
		 ID: "00932",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Mexico Painters & Allied Trades Local #823",
		 ID: "CB212",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Mexico West Texas Multi-Craft",
		 ID: "17516",
		 time: "< 30 mins"
	},
	{
		 NAME: "New Source Benefits, LLC",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New York Hotel Trade Council",
		 ID: "53120",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New York Life",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "New York Medical Indemnity Fund",
		 ID: "65978",
		 time: "< 30 mins"
	},
	{
		 NAME: "New York Network Management",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "New York State Insurance Fund (NYSIF)",
		 ID: "14633",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Next Level Administration ",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Next Level Health Partners",
		 ID: "81671",
		 time: "< 30 mins"
	},
	{
		 NAME: "NextBlue",
		 ID: "CKSC1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nexus Health Medical Group",
		 ID: "14315",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nexus IPA (ProCare MSO)",
		 ID: "61115",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NGS American",
		 ID: "37602",
		 time: "< 30 mins"
	},
	{
		 NAME: "NHC Advantage",
		 ID: "39026",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nike, Inc - CORVEL",
		 ID: "61799",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nippon Life Insurance Company of America",
		 ID: "20149",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nivano Physicians IPA",
		 ID: "SFHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "NJ Carpenters Health Fund",
		 ID: "20443",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NJ Property Liability Ins Guaranty",
		 ID: "STM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Noble AMA Select IPA",
		 ID: "25160",
		 time: "< 30 mins"
	},
	{
		 NAME: "Noble Community Medical Associates LA",
		 ID: "ACP17",
		 time: "< 30 mins"
	},
	{
		 NAME: "Noble Mid OC Orange County  (Health Smart)",
		 ID: "MPM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nonprofit Insurance Trust",
		 ID: "MCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nordstrom, Inc.",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Norfolk & Dedham Group",
		 ID: "66005",
		 time: "< 30 mins"
	},
	{
		 NAME: "Norfolk Public Schools (VA) - CORVEL",
		 ID: "SHMS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NorGUARD Insurance Company - Guard DBA",
		 ID: "51037",
		 time: "< 30 mins"
	},
	{
		 NAME: "North America Administrators (NAA) - Nashville, Tennessee",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "North American Administrators",
		 ID: "UHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "North Broward Hospital District",
		 ID: "39126",
		 time: "< 30 mins"
	},
	{
		 NAME: "North Carolina Insurance Guaranty Association",
		 ID: "20538",
		 time: "< 30 mins"
	},
	{
		 NAME: "North East Independent School District (NEISD)",
		 ID: "19435",
		 time: "< 30 mins"
	},
	{
		 NAME: "North East Medical Services\t(NEMS)",
		 ID: "00805",
		 time: "< 30 mins"
	},
	{
		 NAME: "North Iowa Community Health Plan (NICH)",
		 ID: "RCHSD",
		 time: "< 30 mins"
	},
	{
		 NAME: "North River Insurance Company",
		 ID: "J3953",
		 time: "> 30 Mins"
	},
	{
		 NAME: "North Shore - LIJ (Healthfirst)",
		 ID: "AH001",
		 time: "< 30 mins"
	},
	{
		 NAME: "North Shore - LIJ CareConnect Insurance Company",
		 ID: "SYMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "North Star Mutual Insurance Co. - CORVEL",
		 ID: "74289",
		 time: "> 30 Mins"
	},
	{
		 NAME: "North Texas Healthcare Network",
		 ID: "CSI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "North West Administrators (FCHN)",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "North West Orange County Medical Group",
		 ID: "35206",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Northeast Carpenters Funds",
		 ID: "NMM04",
		 time: "< 30 mins"
	},
	{
		 NAME: "NorthEast Delta Dental",
		 ID: "UHA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northeast Georgia Health Services",
		 ID: "03036",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Northeast Iowa Area Agency",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern California Advantage Medical Group  (NCAMG)",
		 ID: "53473",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern California Physicians Grp",
		 ID: "76048",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern Illinois Health Plan",
		 ID: "26375",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern Kentucky University",
		 ID: "33628",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern Nevada Operating Engineers Health and Welfare",
		 ID: "74431",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northern Nevada Trust Fund (Benefit Plan Admin)",
		 ID: "25924",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Northland Auto",
		 ID: "RP039",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Northshore Physician Associates",
		 ID: "90560",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Northwest Community Health Partners",
		 ID: "53589",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northwest Diagnostic Clinic (NWDC)",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northwest Physicians Network",
		 ID: "34097",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northwestern Medicine Physicians Network",
		 ID: "18765",
		 time: "< 30 mins"
	},
	{
		 NAME: "Northwood Healthcare",
		 ID: "10378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nova Healthcare Administrators, Inc.",
		 ID: "16644",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nova Saiph Re",
		 ID: "MMFUC",
		 time: "< 30 mins"
	},
	{
		 NAME: "NovaNet",
		 ID: "DX066",
		 time: "< 30 mins"
	},
	{
		 NAME: "NovaSys Health - Medical",
		 ID: "HDNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "NP PHP Commercial",
		 ID: "00922",
		 time: "< 30 mins"
	},
	{
		 NAME: "NP PHP Medicare",
		 ID: "LNDMK",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NP PHP OHP",
		 ID: "J2089",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NP Yamhill County CCO",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "NTCA - National Telecommunications Cooperative Association",
		 ID: "FCC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Nuestra Familia Medical Group",
		 ID: "77104",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NX Health Network",
		 ID: "J1959",
		 time: "> 30 Mins"
	},
	{
		 NAME: "NXT IPA",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "NYC Law Department",
		 ID: "HCI02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Nyhart",
		 ID: "APCA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oak River Insurance Company (Member of BHHC)",
		 ID: "14163",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oak West Physician Association",
		 ID: "52123",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Oasis IPA (Regal)",
		 ID: "45221",
		 time: "< 30 mins"
	},
	{
		 NAME: "Occupational Eyewear Network, Inc.",
		 ID: "FCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oceans Acquisition, Inc. - CORVEL",
		 ID: "00720",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ogden Benefits Administration (EBC)",
		 ID: "31404",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ohana Health Plan",
		 ID: "MMMFL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ohio Casualty Insurance",
		 ID: "22100",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ohio Health Choice PPO",
		 ID: "34189",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ohio PPO Connect",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ohio Rehab Department of Corrections (ODRC)",
		 ID: "A0106",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OhioRISE - Aetna Better Health of Ohio",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "OhioRISE Aetna Better Health of Ohio",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ojai Valley Community Medical Group",
		 ID: "66005",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Oklahoma DRS DOC",
		 ID: "12K89",
		 time: "< 30 mins"
	},
	{
		 NAME: "Old Republic Insurance Company",
		 ID: "13337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OLOLRMC Uninsured Patient Services Program",
		 ID: "91164",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Olympus Managed Health Care (OMHC)",
		 ID: "77009",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Omni Healthcare - Humboldt Del Norte, California",
		 ID: "95444",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OMNI IPA",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Omnicare Medical Group (OMNI)",
		 ID: "59354",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Oncology Network of Orange County",
		 ID: "J3903",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Oncology Physicians Network CA PC",
		 ID: "99485",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Beacon",
		 ID: "88350",
		 time: "> 30 Mins"
	},
	{
		 NAME: "One Call Medical",
		 ID: "83276",
		 time: "> 30 Mins"
	},
	{
		 NAME: "One Call Medical - Diagnostic",
		 ID: "12502",
		 time: "> 30 Mins"
	},
	{
		 NAME: "One Call Physical Therapy (formerly Align Network)",
		 ID: "65449",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Care Connect (OCC)",
		 ID: "INPA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Care Connect LTC (OCC)",
		 ID: "COLTC",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Health Plan",
		 ID: "91062",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Health Plan of California",
		 ID: "VCIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "One Health Plan of Colorado",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "One HomeCare Solutions\t",
		 ID: "40025",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OneCare (OC)",
		 ID: "83245",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OneCare (OC) ",
		 ID: "J1417",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OneNet PPO (formerly Alliance PPO & MAPSI)",
		 ID: "J1360",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OnLok Senior Health Services, Inc.",
		 ID: "MBA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "OODA Health",
		 ID: "S9637",
		 time: "< 30 mins"
	},
	{
		 NAME: "OPEIU Locals 30& 537",
		 ID: "ALLCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Operating Engineers Health & Welfare Fund ",
		 ID: "75136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Operating Engineers Local #53",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Operating Engineers Local 501 of California",
		 ID: "J1692",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Operating Engineers Locals 302 & 612 Health & Security Fund,  Group # F12",
		 ID: "MSO44",
		 time: "< 30 mins"
	},
	{
		 NAME: "Operating Engineers Locals 302 & 612 Health & Security Fund, Grp. #F12",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ophthalmic Health Alliance",
		 ID: "MCDMD",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OptiCare Managed Vision (Formerly known as Prime Vision Health)",
		 ID: "SCAN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Opticare of Utah",
		 ID: "42344",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optima Insurance Company",
		 ID: "OODAH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optima/Sentara",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OptiMed",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optimum Choice of the Carolina's (OCCI)",
		 ID: "UC002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optimum Healthcare",
		 ID: "56071",
		 time: "< 30 mins"
	},
	{
		 NAME: "Option Services Group",
		 ID: "MMMFL",
		 time: "< 30 mins"
	},
	{
		 NAME: "Options Health Plan",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum – Complex Medical Conditions (CMC) (formerly OptumHealth Care Solutions and United Resource Networks)",
		 ID: "56215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum / Salt Lake County (Medicaid)",
		 ID: "23282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum Care Network",
		 ID: "00630",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum Care Network - Empire Physicians MG",
		 ID: "GRAVI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network - Primecare Medical Group",
		 ID: "GCMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Corona",
		 ID: "88090",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Hemet Valley",
		 ID: "58204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum Care Network–Inland Valley",
		 ID: "14165",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Moreno Valley",
		 ID: "C1041",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum Care Network–Redlands",
		 ID: "30750",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Riverside",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Southwestern Valleys",
		 ID: "87843",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum Care Network–Sun City",
		 ID: "VAPRM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Care Network–Valley Physicians",
		 ID: "CKID1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Maryland Behavioral Health",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Optum San Diego Public Sector",
		 ID: "41600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Optum Speciality",
		 ID: "GEM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OptumCare / AZ, UT (formerly Optum Medical Network & Lifeprint Network)",
		 ID: "MPM09",
		 time: "< 30 mins"
	},
	{
		 NAME: "OptumCare Network of Connecticut",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "OptumHealth / OptumHealth Behavioral Solutions (formerly United Behavioral Health [UBH] and PacifiCare Behavioral Health )",
		 ID: "52149",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OptumHealth / OptumHealth Behavioral Solutions of NM",
		 ID: "36397",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OptumHealth / OptumHealth Physical Health (includes Oxford) (Formerly ACN)",
		 ID: "36215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Opus MedStrategies",
		 ID: "EDHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Orange Coast Memorial IPA",
		 ID: "77002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Orange County Advantage Medical Group",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Orange County Fire Authority - CORVEL",
		 ID: "33108",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oregon State Accident Insurance Fund (SAIF) Corporation",
		 ID: "J1858",
		 time: "> 30 Mins"
	},
	{
		 NAME: "O'Reilly Auto Parts",
		 ID: "NMM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Orthonet - Aetna",
		 ID: "90210",
		 time: "< 30 mins"
	},
	{
		 NAME: "Orthonet - Uniformed Services Family Health Plan",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oscar Health",
		 ID: "COSNV",
		 time: "< 30 mins"
	},
	{
		 NAME: "OSF Health Plans",
		 ID: "RP035",
		 time: "> 30 Mins"
	},
	{
		 NAME: "OSI Group, LLC - CORVEL",
		 ID: "SVIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "OSMA Health (formerly PLICO)",
		 ID: "63092",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Outsourcing Program",
		 ID: "10104",
		 time: "< 30 mins"
	},
	{
		 NAME: "Oxford Health Plans",
		 ID: "CDWY1",
		 time: "< 30 mins"
	},
	{
		 NAME: "P.R.I.M.E. Benefit Systems Inc.",
		 ID: "72690",
		 time: "< 30 mins"
	},
	{
		 NAME: "P.S.E.W. Trust",
		 ID: "CX021",
		 time: "< 30 mins"
	},
	{
		 NAME: "P3 Health Partners Arizona",
		 ID: "HMAA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "P3 Health Partners Nevada",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PA Health and Wellness - Medical",
		 ID: "BKPNT",
		 time: "< 30 mins"
	},
	{
		 NAME: "PA Preferred Health Network (PPHN)",
		 ID: "NDMSA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PACE Center",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PACE Center LTC",
		 ID: "J1525",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PACE Southeast Michigan",
		 ID: "60550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PACEpartner Solutions",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pacific Alliance Medical Center",
		 ID: "AAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pacific Associates IPA",
		 ID: "52429",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific Compensation Insurance Company",
		 ID: "J1855",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific Health MSO",
		 ID: "00071",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pacific Healthcare IPA",
		 ID: "42137",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific IPA",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific Medical Centers (PACMED USFHP)\t",
		 ID: "SMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pacific Source Health Plan",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pacific Southwest Administrators (PSWA)",
		 ID: "82832",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific Sunwear - CORVEL",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacific Union Dental",
		 ID: "00402",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pacifica of the Valley Hospital",
		 ID: "06161",
		 time: "< 30 mins"
	},
	{
		 NAME: "PacifiCare Dental and Vision HMO Claims",
		 ID: "31118",
		 time: "< 30 mins"
	},
	{
		 NAME: "PacifiCare Dental and Vision PPO-IND",
		 ID: "83269",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PacificSource Community Solutions",
		 ID: "78702",
		 time: "< 30 mins"
	},
	{
		 NAME: "PacificSource Health Plans",
		 ID: "00806",
		 time: "< 30 mins"
	},
	{
		 NAME: "PacificSource Medicare",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Painters Union Insurance Fund",
		 ID: "95444",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Paladin Managed Care Services, Inc.",
		 ID: "NCA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "PALMS Casino Resort (Admin by LIBERTY Dental Plan)",
		 ID: "SODRC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Palomar Pomerado Hospital",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pan American Life Insurance",
		 ID: "20501",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pan American Life Insurance Group",
		 ID: "A0046",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Paradigm",
		 ID: "10480",
		 time: "< 30 mins"
	},
	{
		 NAME: "Paragon Benefits, Inc",
		 ID: "11271",
		 time: "< 30 mins"
	},
	{
		 NAME: "Paramount Health Care",
		 ID: "68381",
		 time: "< 30 mins"
	},
	{
		 NAME: "Parity Healthcare",
		 ID: "GTPA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Park District Risk Management Association (PDRMA)",
		 ID: "35202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Parker Services LLC",
		 ID: "MVMM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Parkland Community Health Plan",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Parkland Community Health Plan (Aetna Better Health)",
		 ID: "41178",
		 time: "< 30 mins"
	},
	{
		 NAME: "Partners Behavioral Health",
		 ID: "J1830",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Partners Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Partners in Health",
		 ID: "73099",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Partners National Health Plan of NC",
		 ID: "88848",
		 time: "< 30 mins"
	},
	{
		 NAME: "Partnership Health Plan of California Healthy Kids",
		 ID: "J1642",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Partnership HealthPlan of California",
		 ID: "MHPIL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pasadena Primary Care Physicians Group",
		 ID: "DHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pasco County BOCC",
		 ID: "DAK01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Passport Advantage",
		 ID: "J3936",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Passport Health Plan",
		 ID: "MCDWI",
		 time: "< 30 mins"
	},
	{
		 NAME: "PATH Administrators (Formerly DH Evans)",
		 ID: "IHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Patient Advocates LLC  ",
		 ID: "SNY01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Patient Physician Cooperatives",
		 ID: "AWNY6",
		 time: "< 30 mins"
	},
	{
		 NAME: "Patriot General Insurance Company",
		 ID: "PAS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Paul Mueller",
		 ID: "93029",
		 time: "< 30 mins"
	},
	{
		 NAME: " Compass – ACS Benefits",
		 ID: "00851",
		 time: "> 30 Mins"
	},
	{
		 NAME: " Fusion Holdings",
		 ID: "98366",
		 time: "< 30 mins"
	},
	{
		 NAME: "PBS - Oregon Alaska Industrial Hardware",
		 ID: "84135",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PBS - Oregon Alaska Rubber & Supply",
		 ID: "BKMD1",
		 time: "< 30 mins"
	},
	{
		 NAME: "PBS - Oregon Cascade Rubber Products",
		 ID: "58174",
		 time: "< 30 mins"
	},
	{
		 NAME: "PBS - Oregon Construction Machinery Industrial",
		 ID: "FT254",
		 time: "< 30 mins"
	},
	{
		 NAME: "PBS - Oregon Peninsula Airways",
		 ID: "72091",
		 time: "< 30 mins"
	},
	{
		 NAME: "PBS - Oregon TDX Corporation",
		 ID: "J1646",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PCA Health Plan of Texas (Humana)",
		 ID: "MLAHS",
		 time: "< 30 mins"
	},
	{
		 NAME: "PCMG of San Luis Obispo (formerly San Luis Obispo Select IPA)",
		 ID: "J1618",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PCMG of Santa Maria (formerly Midcoast Care Inc.)",
		 ID: "63092",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PDT - Hollywood Presbyterian-St Vincent",
		 ID: "TP043",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Peach State Health Plan (Ambetter) - Medical",
		 ID: "J1693",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Peak Health",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Peak Pace Solutions",
		 ID: "PROHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Peak Property and Casualty Insurance Corporation",
		 ID: "23228",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pebble Health",
		 ID: "PHCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pediatric Associates",
		 ID: "CAS89",
		 time: "< 30 mins"
	},
	{
		 NAME: "Peerless Insurance",
		 ID: "UHC-OHMCD",
		 time: "< 30 mins"
	},
	{
		 NAME: "PEF Clinic",
		 ID: "AMDA2",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pegasus Medical Group (Regal)",
		 ID: "PCACZ",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pekin Insurance",
		 ID: "RIOS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Peninsula Indemnity Company",
		 ID: "PCMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Penn Behavioral Health",
		 ID: "CCMHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Penn Millers Insurance Co",
		 ID: "23229",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Penn National Insurance",
		 ID: "BRT01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Penn National Insurance - Auto",
		 ID: "78857",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pennsylvania Manufacturing Associates (PMA)",
		 ID: "BS002",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pennsylvania Pace ",
		 ID: "AAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "People 1st Health Strategies",
		 ID: "24147",
		 time: "> 30 Mins"
	},
	{
		 NAME: "People 2.0",
		 ID: "64884",
		 time: "< 30 mins"
	},
	{
		 NAME: "People One Health",
		 ID: "00630",
		 time: "< 30 mins"
	},
	{
		 NAME: "Peoples Health Network",
		 ID: "GHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Perennial Advantage of Colorado",
		 ID: "NEXUS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Perennial Advantage of Ohio",
		 ID: "MR011",
		 time: "< 30 mins"
	},
	{
		 NAME: "Performance Food Group - CORVEL",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PerformCare",
		 ID: "PROGY",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Perlman Medical Group",
		 ID: "34734",
		 time: "< 30 mins"
	},
	{
		 NAME: "Personal Insurance Administrators, Inc. (PIA)",
		 ID: "CHBC00657",
		 time: "< 30 mins"
	},
	{
		 NAME: "PGBA TriWest VA CCN",
		 ID: "J1009",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pharmacist Mutual Insurance Co.",
		 ID: "J1498",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PHCS GEHA",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Philadelphia American Life Insurance Company",
		 ID: "00890",
		 time: "< 30 mins"
	},
	{
		 NAME: "Phoenix Health Plan (Medicare)",
		 ID: "95271",
		 time: "< 30 mins"
	},
	{
		 NAME: "PHP of Michigan",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "PHP of the Carolinas",
		 ID: "22254",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physician Associates of Louisiana",
		 ID: "31415",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physician Healthcare Integration IPA",
		 ID: "73155",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physician Partners IPA - Alameda (ProCare MSO)",
		 ID: "58202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physician Partners IPA - Santa Clara (ProCare MSO)",
		 ID: "11001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physician Partners IPA - South (ProCare MSO)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Care",
		 ID: "53483",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Care Network",
		 ID: "TRUST",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Care Network / The Polyclinic",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Choice Medical Group of San Luis Obispo",
		 ID: "J1746",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Choice Medical Group of Santa Maria",
		 ID: "35174",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Data Trust (PDT)",
		 ID: "13407",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Health Association of Illinois",
		 ID: "36090",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Choice- HCFA Claims",
		 ID: "37256",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Health Network (PHN)",
		 ID: "81600",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Plan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Plan (PHP) - Global Care",
		 ID: "MR003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Health Plan (PHP) - Mid Michigan",
		 ID: "64157",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Health Plan (PHP) - Northern Indiana",
		 ID: "10001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Plan (PHP) - South Michigan",
		 ID: "00630",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Plan (PHP) - Sparrow",
		 ID: "SCNGA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Health Plan of Michigan Medicare",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Healthcare Plans",
		 ID: "80314",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Healthways IPA",
		 ID: "59064",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Management Group",
		 ID: "IP095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Medical Group of San Jose",
		 ID: "79480",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physician's Mutual (Dental)",
		 ID: "UT3F1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians Mutual Insurance Company",
		 ID: "05302",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians of Southwest Washington",
		 ID: "77220",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Plus Insurance Corporation",
		 ID: "91078",
		 time: "< 30 mins"
	},
	{
		 NAME: "Physicians Plus Insurance, Co., Wisconsin State Employees",
		 ID: "00720",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians United Plan",
		 ID: "J1452",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physicians’ Healthways IPA",
		 ID: "57080",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PhysMetrics (DOS prior to 7/1/17)",
		 ID: "95044",
		 time: "< 30 mins"
	},
	{
		 NAME: "PhysMetrics (formerly ChiroMetrics)",
		 ID: "42138",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Physnet",
		 ID: "21062",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pie Insurance Services Inc. - CORVEL",
		 ID: "37257",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Piedmont Community Health Plan",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Piedmont Wellstar Health Plan",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pier 1 Inc. USA",
		 ID: "PCMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pierce County WA - CORVEL",
		 ID: "10105",
		 time: "< 30 mins"
	},
	{
		 NAME: "PIH Health (formerly Bright Health)",
		 ID: "77350",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pinnacle Claims Management, Inc.",
		 ID: "TN200864",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pinnacle Health Resources",
		 ID: "AXM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pinnacle Medical Group",
		 ID: "J3945",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pinnacol Assurance",
		 ID: "77003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pioneer Provider Network",
		 ID: "76342",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pittman and Associates (Now known as HealthSmart Benefit Solutions)",
		 ID: "35605",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Planned Admin Inc P and C Division",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Planned Administrators, Inc. (PAI)",
		 ID: "65391",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Planstin",
		 ID: "J1798",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Plexis Healthcare Systems (UCS)",
		 ID: "NMM07",
		 time: "< 30 mins"
	},
	{
		 NAME: "Plymouth Rock",
		 ID: "06161",
		 time: "< 30 mins"
	},
	{
		 NAME: "PMG of San Jose",
		 ID: "95729",
		 time: "< 30 mins"
	},
	{
		 NAME: "Podi Care Managed Care",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Podiatry First",
		 ID: "43056",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Podiatry Network Florida",
		 ID: "14829",
		 time: "< 30 mins"
	},
	{
		 NAME: "Podiatry Plan Inc\t",
		 ID: "37268",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Poe & Brown",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Point Comfort Underwriters",
		 ID: "42020",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Point Comfort Underwriters (PCU03)",
		 ID: "00562",
		 time: "> 30 Mins"
	},
	{
		 NAME: "POMCO",
		 ID: "00804",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Pomona Valley Medical Group (PVMG)",
		 ID: "61301",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pool Administrators Primary",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pool Administrators Secondary",
		 ID: "04614",
		 time: "< 30 mins"
	},
	{
		 NAME: "Poppins Health",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Populytics (formerly Spectrum Administrators)",
		 ID: "10521",
		 time: "< 30 mins"
	},
	{
		 NAME: "Port of Oakland (CA) - CORVEL",
		 ID: "18766",
		 time: "< 30 mins"
	},
	{
		 NAME: "Porter Scott",
		 ID: "77003",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Positive Health Care (PHC) / FL MCO PHC",
		 ID: "31402",
		 time: "< 30 mins"
	},
	{
		 NAME: "PPO Plus LLC",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prairie States Enterprises",
		 ID: "J1351",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preferred Admin Childrens Hosp",
		 ID: "HCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Administrator",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Benefit Administrators",
		 ID: "PROV1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Blue of South Carolina",
		 ID: "00315",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preferred Care Partners - Florida",
		 ID: "TMMC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Community Choice",
		 ID: "J3907",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preferred Community Choice - PCCSelect - CompMed",
		 ID: "J2175",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preferred Health Care (PHC)",
		 ID: "00950",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Health Plan of the Carolinas",
		 ID: "J1986",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preferred Health Professionals",
		 ID: "86027",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Healthcare System - PPO",
		 ID: "HHS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred IPA",
		 ID: "11202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Mutual",
		 ID: "CM001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preferred Network Access, Inc.",
		 ID: "OCN01",
		 time: "< 30 mins"
	},
	{
		 NAME: "PreferredOne",
		 ID: "14145",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premera Blue Cross Blue Shield of Alaska",
		 ID: "MRIPA",
		 time: "> 1 hours"
	},
	{
		 NAME: "Premera Blue Cross of Washington",
		 ID: "IP097",
		 time: "> 1 hours"
	},
	{
		 NAME: "Premier Access Insurance Company",
		 ID: "87716",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Administrative Solutions",
		 ID: "IH400",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier Care of Northern California",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Eye Care",
		 ID: "20377",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier Group Insurance - CORVEL",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Health Plan (Premier Health Group)",
		 ID: "77076",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier Health Systems",
		 ID: "52189",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - A-G Admin (SOMA group)",
		 ID: "J1648",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - Auxiant",
		 ID: "BCNDC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - BPA – Benefit Plan Administrators",
		 ID: "GRV01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - Commercial Travelers",
		 ID: "75133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - First Agency (FIA)",
		 ID: "MPM37",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - Mayo Clinic FL/GA",
		 ID: "20044",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - Med-Pay",
		 ID: "MR001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX)  - Mississippi Physician Care Network",
		 ID: "MCDMD",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) - BMI Kansas",
		 ID: "63092",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) - Cypress Benefit Admin",
		 ID: "37281",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) - Elmco",
		 ID: "22248",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) - Fox Everett/HUB International",
		 ID: "J1417",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) – Preferred Admin",
		 ID: "61425",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier HealthCare Exchange (PHX) – The Benefit Group",
		 ID: "A0102",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier Patient Care IPA",
		 ID: "25160",
		 time: "< 30 mins"
	},
	{
		 NAME: "Premier Physician Alliance",
		 ID: "77082",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Physician Alliance Inc",
		 ID: "ASMI1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Physician Network",
		 ID: "UTMB3",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Premier Physician Network (PPN)",
		 ID: "82347",
		 time: "< 30 mins"
	},
	{
		 NAME: "PremierCare Health (ProCare MSO)",
		 ID: "11150",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PremierOne Plus IPA",
		 ID: "13129",
		 time: "< 30 mins"
	},
	{
		 NAME: "Presbyterian Health Plan",
		 ID: "73155",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Presbyterian Health Plan, Inc. (Magellan)",
		 ID: "42150",
		 time: "< 30 mins"
	},
	{
		 NAME: "Presbyterian Intercommunity Hospital",
		 ID: "UNINW",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Presbyterian Salud (NM)",
		 ID: "RVC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Presence ERC",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Preserver Insurance Company",
		 ID: "11889",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prestige Health Choice",
		 ID: "LNDMK",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prestige Health Choice (AmeriHealth Caritas)",
		 ID: "LNJ01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Preventative Choice Plan",
		 ID: "246",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prezzo",
		 ID: "MR084",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primary Care & Pediatric IPA (PCP IPA)",
		 ID: "11440",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primary Care Associates Medical Group (PCAMG)",
		 ID: "37330",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Primary Care Associates of California (PCAC)",
		 ID: "PHPMC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Primary Care Coalition",
		 ID: "05029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primary Care of Joliet",
		 ID: "3833R",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Primary Care Practices Of Sacramento - EHS",
		 ID: "C1035",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Primary Health Network",
		 ID: "82048",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primary Health of Josephine County",
		 ID: "89239",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primary Physician Care",
		 ID: "J1574",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prime Community Care Central Valley  Encounters (MV Medical)",
		 ID: "HSICS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prime Community Care Central Valley (MV Medical)",
		 ID: "TMHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prime Healthcare - CORVEL",
		 ID: "CAPH2",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prime Property & Casulty",
		 ID: "99588",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prime West Health Systems",
		 ID: "81671",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PrimeCare Medical Network",
		 ID: "04212",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PrimeCare Medical Network - Chino",
		 ID: "SC016",
		 time: "< 30 mins"
	},
	{
		 NAME: "PrimeCare Medical Network - Citrus Valley",
		 ID: "IHS10",
		 time: "< 30 mins"
	},
	{
		 NAME: "Primecare Medical Network - San Bernardino",
		 ID: "ASFL1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PrimeCare, Wisconsin State and Federal Employees and Medicaid",
		 ID: "82694",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prince Georges County Government (MD) - CORVEL",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Princeton Premier IPA",
		 ID: "J3299",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Principal Financial Group",
		 ID: "26337",
		 time: "< 30 mins"
	},
	{
		 NAME: "Principal Mutual Life Insurance Co.",
		 ID: "STARS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Priority Health",
		 ID: "ARA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prism - Univera",
		 ID: "C1075",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prism Health Networks",
		 ID: "13010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "prnLink-MLK",
		 ID: "SNY01",
		 time: "< 30 mins"
	},
	{
		 NAME: "ProCare - PremierCare IPA",
		 ID: "SB601",
		 time: "< 30 mins"
	},
	{
		 NAME: "ProCare (Prospect)",
		 ID: "CCMMH",
		 time: "< 30 mins"
	},
	{
		 NAME: "ProCare (TX)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Procare Health Plan - Medicaid",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prodegi",
		 ID: "37308",
		 time: "< 30 mins"
	},
	{
		 NAME: "Productive Programming, Inc. (PPI)",
		 ID: "36273",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Professional Benefit Administrators, Inc.",
		 ID: "CX068",
		 time: "< 30 mins"
	},
	{
		 NAME: "Professional Claims Management - Canton, Ohio",
		 ID: "64158",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Professional Health Care Network",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Casualty Insurance Company",
		 ID: "22248",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Classic Insurance Company",
		 ID: "74323",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Commercial Casualty Company",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Progressive Direct Insurance Company",
		 ID: "770395311",
		 time: "< 30 mins"
	},
	{
		 NAME: "Progressive Insurance",
		 ID: "TU127",
		 time: "< 30 mins"
	},
	{
		 NAME: "Progressive Max Insurance Company",
		 ID: "J3844",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Medical Associates, Inc",
		 ID: "37105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Northern Insurance Company",
		 ID: "13343",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Northwestern Insurance Company",
		 ID: "EPSDR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Progressive Preferred Insurance Company",
		 ID: "93142",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progressive Premier Insurance Company",
		 ID: "TRULI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Progressive Specialty Insurance Company",
		 ID: "J3745",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Progyny",
		 ID: "VAPRM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prominence Administrative Services",
		 ID: "TMMC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prominence Health Plan",
		 ID: "GHAOP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prominence Health Plan of Nevada",
		 ID: "J1764",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prominence Health Plan of Texas",
		 ID: "11153",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prominence Healthfirst Florida",
		 ID: "LCO01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prospect Health Services of Texas",
		 ID: "55204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prospect Medical Group",
		 ID: "ICRCL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prospect Provider Group of Rhode Island",
		 ID: "HM006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prospect Sherman Oaks Medical Group",
		 ID: "14163",
		 time: "< 30 mins"
	},
	{
		 NAME: "Prosperity Life",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Protective Insurance",
		 ID: "64090",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Protective Life Insurance",
		 ID: "41205",
		 time: "< 30 mins"
	},
	{
		 NAME: "Protector 360",
		 ID: "07689",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Providence Facility Claims",
		 ID: "MNAUL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Providence Health Assurance",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Providence Health Plan",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Providence PACE CA",
		 ID: "BCCCP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Providence PPO",
		 ID: "31144",
		 time: "< 30 mins"
	},
	{
		 NAME: "Providence Preferred PPO",
		 ID: "PHP00",
		 time: "< 30 mins"
	},
	{
		 NAME: "Providence Risk & Insurance",
		 ID: "COBHI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provident American Life & Health Insurance Company (Medicare Supplement)",
		 ID: "MCDMN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Provident Healthsource (CIGNA)",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provident Life & Accident",
		 ID: "CB624",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provident Life & Casualty",
		 ID: "CALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provident Preferred Network",
		 ID: "36320",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provider Direct Network (HealthSmart Preferred Care)",
		 ID: "MCI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Maryland",
		 ID: "MCDWV",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Missouri",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Ohio",
		 ID: "58204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Pennsylvania",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Provider Partners Health Plan of Texas",
		 ID: "94316",
		 time: "< 30 mins"
	},
	{
		 NAME: "ProviDRs Care Network",
		 ID: "57080",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Prudent Medical Group",
		 ID: "35193",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pruitt Health Premier",
		 ID: "HMSA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Pruitt Health Premier NC & SC",
		 ID: "52132",
		 time: "> 30 Mins"
	},
	{
		 NAME: "PSKW",
		 ID: "45321",
		 time: "< 30 mins"
	},
	{
		 NAME: "Psychiatric Centers at San Diego (PCSD)",
		 ID: "J1620",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Public Alliance Insurance Coverage Fund (PAIC Jif)",
		 ID: "PABLS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Public Employees Health Plan (PEHP)",
		 ID: "ASRM1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Public Employees Health Program",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Public Health Medical Services (PHMS)",
		 ID: "GEHA9",
		 time: "< 30 mins"
	},
	{
		 NAME: "Public WC Program",
		 ID: "IHPEN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Publix Super Markets, Inc.",
		 ID: "17882",
		 time: "< 30 mins"
	},
	{
		 NAME: "Puget Sound Benefits Trust, Group# F25",
		 ID: "95044",
		 time: "< 30 mins"
	},
	{
		 NAME: "Puget Sound Electrical Workers Trust, Group# F33",
		 ID: "PROSP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Puget Sound Workers Compensation Trust",
		 ID: "61425",
		 time: "> 1 hours"
	},
	{
		 NAME: "Pure Insurance",
		 ID: "89247",
		 time: "< 30 mins"
	},
	{
		 NAME: "QCA Health Plan",
		 ID: "38238",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Quad Cities Community Health Plan (QCCH)",
		 ID: "00520",
		 time: "> 30 Mins"
	},
	{
		 NAME: "QualCare (Managed Care Systems)",
		 ID: "64158",
		 time: "> 30 Mins"
	},
	{
		 NAME: "QualCare Alliance Networks, Inc. (QANI)",
		 ID: "MTNST",
		 time: "< 30 mins"
	},
	{
		 NAME: "QualCare IPA",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "QualChoice Advantage",
		 ID: "OMN02",
		 time: "< 30 mins"
	},
	{
		 NAME: "QualChoice Health Insurance",
		 ID: "PMAI2",
		 time: "< 30 mins"
	},
	{
		 NAME: "QualChoice of Arkansas",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Quality Care IPA",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Quality Care Partners",
		 ID: "19439",
		 time: "< 30 mins"
	},
	{
		 NAME: "Quartz",
		 ID: "87815",
		 time: "< 30 mins"
	},
	{
		 NAME: "Quest Behavioral Health",
		 ID: "J1896",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Quest EAP",
		 ID: "66917",
		 time: "< 30 mins"
	},
	{
		 NAME: "Questcare Medical Services",
		 ID: "77073",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Quick Trip Corporation",
		 ID: "60818",
		 time: "< 30 mins"
	},
	{
		 NAME: "QVI Risk Solutions Inc",
		 ID: "44219",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rady Children’s Health Network (RCHN)",
		 ID: "J1702",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rady Childrens Hospital San Diego",
		 ID: "20029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rady’s Children’s Specialists of San Diego",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Raleys Family of Fine Stores - CORVEL",
		 ID: "J1435",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ralph Lauren - CORVEL",
		 ID: "89242",
		 time: "< 30 mins"
	},
	{
		 NAME: "RAM Mutual Insurance Company",
		 ID: "59355",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rampart Insurance Company",
		 ID: "71070",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ramsey County (MN)",
		 ID: "CMSEB",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rancho Santiago Community College District (CA) - CORVEL",
		 ID: "86050",
		 time: "< 30 mins"
	},
	{
		 NAME: "RAPHA IPA INC",
		 ID: "36404",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ravenswood Physician Associates",
		 ID: "MSO33",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Recology - CORVEL",
		 ID: "84076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Recreational Equipment Inc. - CORVEL",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "Red Oak Independent School District",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Redirect Health Administration",
		 ID: "HMSA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Redlands-Yucaipa Medical Group",
		 ID: "44054",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Redwood Community Health Coalition",
		 ID: "23228",
		 time: "< 30 mins"
	},
	{
		 NAME: "Redwood Fire and Casualty Insurance Company (Member of BHHC)",
		 ID: "87020",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Reedy Creek Improvement District",
		 ID: "50653",
		 time: "< 30 mins"
	},
	{
		 NAME: "Regal Medical Group",
		 ID: "00621",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Regence Blue Cross Blue Shield - Oregon",
		 ID: "31626",
		 time: "> 1 hours"
	},
	{
		 NAME: "Regence Blue Shield - Idaho",
		 ID: "CPNNP",
		 time: "> 1 hours"
	},
	{
		 NAME: "Regence Blue Shield of Washington State",
		 ID: "52148",
		 time: "> 1 hours"
	},
	{
		 NAME: "Regence Group Administrators (RGA)",
		 ID: "62181",
		 time: "> 1 hours"
	},
	{
		 NAME: "Regency Employee Benefits",
		 ID: "IHS08",
		 time: "< 30 mins"
	},
	{
		 NAME: "Regent Medical Group, Inc. (Regent Family Practice of Glendale)",
		 ID: "77220",
		 time: "< 30 mins"
	},
	{
		 NAME: "Region 8 Esc",
		 ID: "16535",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Regional Care, Inc.",
		 ID: "52876",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rehn and Associates",
		 ID: "37257",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Reliance HMO, INC.",
		 ID: "75136",
		 time: "< 30 mins"
	},
	{
		 NAME: "Reliance Standard Life",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Religious Order of Jehovah's Witnesses",
		 ID: "J1453",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Renaissance Gulf Quest",
		 ID: "31127",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Renaissance Physicians Organization",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Renassaince - River Oaks",
		 ID: "13407",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Renassaince Riveroaks Blue",
		 ID: "51062",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Republic Franklin Insurance",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "Republic Indemnity Company of America",
		 ID: "INET1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Republic Western Ins. Co",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Reserve National Insurance",
		 ID: "95056",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Resolve Health Plan Administrators, LLC.",
		 ID: "74101",
		 time: "< 30 mins"
	},
	{
		 NAME: "ResourceOne Administrators",
		 ID: "88056",
		 time: "> 30 Mins"
	},
	{
		 NAME: "ResourceOne Administrators Tribal",
		 ID: "38221",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Resurrection Health Care Preferred",
		 ID: "57254",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Resurrection Physicians Group",
		 ID: "49718",
		 time: "< 30 mins"
	},
	{
		 NAME: "Retail Business Services (RBS)",
		 ID: "23287",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Retailer’s Casualty ",
		 ID: "50114",
		 time: "< 30 mins"
	},
	{
		 NAME: "Retailer’s First ",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Retro Insulation INC",
		 ID: "U7632",
		 time: "< 30 mins"
	},
	{
		 NAME: "Return to Work",
		 ID: "J1927",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Revclaims",
		 ID: "TPS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Richardson ISD (Tristar Risk Management)",
		 ID: "37309",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Right Care from Scott & White",
		 ID: "TKFMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Right Choice Benefit Administrators",
		 ID: "52189",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rios Arizona IPA",
		 ID: "84131",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rios Southwest Medical Group",
		 ID: "77052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Risk Administration Services",
		 ID: "81794",
		 time: "< 30 mins"
	},
	{
		 NAME: "Risk Enterprise Management (REM)",
		 ID: "33628",
		 time: "< 30 mins"
	},
	{
		 NAME: "River City Medical Group",
		 ID: "VALOP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "River City Medical Group Senior",
		 ID: "75274",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Riverport Insurance (MN School Board Association)",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Riverside Medical Clinic",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "RiverSpring Health Plans",
		 ID: "39856",
		 time: "< 30 mins"
	},
	{
		 NAME: "RMSCO, Inc.",
		 ID: "93050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Robert F Kennedy Medical Plan",
		 ID: "71065",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rocky Mountain Health Plan - Grand Junction",
		 ID: "87020",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rocky Mountain HMO",
		 ID: "41124",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rocky Mountain Pace",
		 ID: "27327",
		 time: "< 30 mins"
	},
	{
		 NAME: "Roseburg Forest Products - CORVEL",
		 ID: "MCI01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Royal Health Care",
		 ID: "J1632",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Royal Healthcare on behalf of Extended MLTC",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Royale Resources ",
		 ID: "CH00102",
		 time: "< 30 mins"
	},
	{
		 NAME: "RTG Medical Group",
		 ID: "J3733",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Rural Carrier Benefit Plan",
		 ID: "74323",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rush Prudential Health Plans - HMO Only",
		 ID: "52095",
		 time: "< 30 mins"
	},
	{
		 NAME: "Rutgers Casualty Insurance for NJ Auto",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ryan White Network (RWN)",
		 ID: "128MI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ryder Services",
		 ID: "76045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "S & S Healthcare Strategies",
		 ID: "38254",
		 time: "< 30 mins"
	},
	{
		 NAME: "SA Recycling, LLC - CORVEL",
		 ID: "10301",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SafeCo Insurance",
		 ID: "MCDMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Safeco Insurance (Auto Only)",
		 ID: "00903",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SafeGuard",
		 ID: "45289",
		 time: "< 30 mins"
	},
	{
		 NAME: "SafeGuard Health Enterprises, Inc",
		 ID: "96385",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sagamore Health Network",
		 ID: "54771",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sage Program",
		 ID: "SC008",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sage Technologies",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Saint Agnes Medical Group",
		 ID: "IMSMS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Saint Johns Health Clinic",
		 ID: "17003",
		 time: "< 30 mins"
	},
	{
		 NAME: "Salt Lake City Corporation - CORVEL",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Salvasen Health",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Samaritan Advantage (SA01)",
		 ID: "75234",
		 time: "< 30 mins"
	},
	{
		 NAME: "Samaritan Choice (SCP)",
		 ID: "J3922",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Samaritan Health Plans",
		 ID: "20416",
		 time: "< 30 mins"
	},
	{
		 NAME: "Samaritan Healthy Kids (KID)",
		 ID: "87843",
		 time: "< 30 mins"
	},
	{
		 NAME: "Samaritan Ministries International",
		 ID: "BCSMT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SAMBA",
		 ID: "PDT02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Samera Health",
		 ID: "04312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "San Bernardino Medical Group (SBMED)",
		 ID: "128WV",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego Community College District (CA) - CORVEL",
		 ID: "88027",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego County Medical Services (CMS)",
		 ID: "79480",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego County Physician Emergency Services (PES)",
		 ID: "16089",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego County Primary Care Services",
		 ID: "AEC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego County Ryan White Care Act",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego Neighborhood House Association",
		 ID: "52106",
		 time: "> 30 Mins"
	},
	{
		 NAME: "San Diego PACE",
		 ID: "AMDA2",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Diego Physicians Medical Group",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "San Francisco Electrical Workers - IBEW Local 6",
		 ID: "00710",
		 time: "> 30 Mins"
	},
	{
		 NAME: "San Francisco Health Plan",
		 ID: "SHPS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Francisco Healthy Kids",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Francisco Municipal Transportation Agency (SFMTA)",
		 ID: "95386",
		 time: "< 30 mins"
	},
	{
		 NAME: "San Joaquin Health Administrators",
		 ID: "J3946",
		 time: "> 30 Mins"
	},
	{
		 NAME: "San Mateo Healthy Kids",
		 ID: "MCDLA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sana Benefits",
		 ID: "63092",
		 time: "< 30 mins"
	},
	{
		 NAME: "SandHills Center",
		 ID: "78375",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sanford Health Plan",
		 ID: "78857",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sansum Clinic",
		 ID: "J1956",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Santa Barbara Select IPA",
		 ID: "38333",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Santa Clara County IPA ACO",
		 ID: "87042",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Santa Clara County IPA HMO",
		 ID: "CPN01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Santa Clara County IPA PPO",
		 ID: "60601",
		 time: "< 30 mins"
	},
	{
		 NAME: "Santa Clara Family Health Plan",
		 ID: "40026",
		 time: "< 30 mins"
	},
	{
		 NAME: "Santa Clara Family Health Plan (SCFHP)",
		 ID: "00890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SANTA MONICA UNITE HERE HEALTH BENEFIT PLAN",
		 ID: "00451",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sante Community Medical Centers",
		 ID: "CPPTM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sante Community Physicians",
		 ID: "BCWFP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sante Medi-Cal",
		 ID: "SVA01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Saudi Health Mission",
		 ID: "00050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Savage Services - CORVEL",
		 ID: "FOREN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Save Mart - CORVEL",
		 ID: "03036",
		 time: "< 30 mins"
	},
	{
		 NAME: "SBM (Staff Benefits Management & Admin)",
		 ID: "DDCA3",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scan Health Plan - California",
		 ID: "75240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SCCIPA Anthem ACO",
		 ID: "13202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SCCIPA Anthem PPO",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "SCCIPA HMO",
		 ID: "DMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "SCCIPA VHP Covered California",
		 ID: "72187",
		 time: "< 30 mins"
	},
	{
		 NAME: "SCHS ALTA Global Care Medical Group",
		 ID: "46166",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SCHS Alta Pod by MedPOINT - Health Net",
		 ID: "J1264",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SCIF (State Compensation Insurance Fund)",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Scion Dental",
		 ID: "37105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Scott & White Health Care Plan",
		 ID: "J1385",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Screen Actors Guild (SAG AFTRA)",
		 ID: "NCCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Scripps Clinic",
		 ID: "31405",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Scripps Coastal Medical Center",
		 ID: "HAT02",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scripps Health Plan MSO",
		 ID: "DVMC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scripps Health Plan Services",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scripps Medical Plan (Aetna)",
		 ID: "00223",
		 time: "< 30 mins"
	},
	{
		 NAME: "Scripps Physicians Medical Group",
		 ID: "WVS01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seabright (SBIC)",
		 ID: "86050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seattle Area Plumbing",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Seaview IPA",
		 ID: "J2090",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SECURA Insurance A Mutual Company",
		 ID: "BC001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Secure Health",
		 ID: "MPM44",
		 time: "< 30 mins"
	},
	{
		 NAME: "Secure Health Plans of Georgia, LLC",
		 ID: "26337",
		 time: "< 30 mins"
	},
	{
		 NAME: "SecureCare Dental",
		 ID: "23045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SecureOne",
		 ID: "J1930",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Security Administrative Services, LLC (Cornerstone)",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Security Health Plan",
		 ID: "45281",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sedgwick Claims Management Services (CMS)",
		 ID: "46050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sedgwick World Trade Center",
		 ID: "10878",
		 time: "< 30 mins"
	},
	{
		 NAME: "SEIU Local 1 Cleveland Welfare Fund",
		 ID: "21172",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Select Administrative Services (SAS)",
		 ID: "7166",
		 time: "< 30 mins"
	},
	{
		 NAME: "Select Benefits Administrators",
		 ID: "48330",
		 time: "< 30 mins"
	},
	{
		 NAME: "Select Benefits Administrators of America",
		 ID: "63842",
		 time: "< 30 mins"
	},
	{
		 NAME: "Select Energy Services - CORVEL",
		 ID: "49533",
		 time: "< 30 mins"
	},
	{
		 NAME: "Select Health of South Carolina",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Select Health of Utah",
		 ID: "MC006",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Select Healthcare System, Inc.",
		 ID: "CH00102",
		 time: "< 30 mins"
	},
	{
		 NAME: "Select Senior Clinic",
		 ID: "26832",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SelectCare of Texas (GTPA - Golden Triangle)",
		 ID: "VACCN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SelectCare of Texas (Houston)",
		 ID: "77003",
		 time: "< 30 mins"
	},
	{
		 NAME: "SelectCare of Texas (HPN - Heritage Physicians)",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "SelectCare of Texas (Kelsey-Seybold)",
		 ID: "10462",
		 time: "< 30 mins"
	},
	{
		 NAME: "SelectCare of Texas (MCA – Memorial Clinical)",
		 ID: "VALOP",
		 time: "< 30 mins"
	},
	{
		 NAME: "SelectCare of Texas (NWDC)",
		 ID: "62119",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Selective",
		 ID: "APP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Selective Insurance",
		 ID: "TMHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Self Insured Fund – MA",
		 ID: "54704",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Self Insured Fund For FL Florists And Nurseries",
		 ID: "37217",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Self Insured Plans",
		 ID: "15202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Self Insured Services Company (SISCO)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Self-Funded Plans, Inc.",
		 ID: "38265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Self-Insured Schools of California (SISC)",
		 ID: "89249",
		 time: "< 30 mins"
	},
	{
		 NAME: "Selman and Company",
		 ID: "14163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Selman Tricare Supplement Plans",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sendero IdealCare",
		 ID: "GSHTX",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sendero IdealCare SHP",
		 ID: "128KS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Senior First IPA (dba ChoiceOne IPA )",
		 ID: "FDH01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Senior Network Health",
		 ID: "ECL01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Senior Whole Health Massachusetts",
		 ID: "00230",
		 time: "< 30 mins"
	},
	{
		 NAME: "Senior Whole Health New York (MLTC Only)",
		 ID: "C1091",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentara Family Plan / Sentara Health Management",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentara Mental Health Professional",
		 ID: "52461",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentinel Management Services",
		 ID: "86050",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentinel Security Life Insurance Company",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sentry Auto",
		 ID: "36331",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentry Casualty Company",
		 ID: "SCNNM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sentry Insurance",
		 ID: "38238",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sentry Insurance a Mutual Co.",
		 ID: "SX107",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sentry Insurance A Mutual Company",
		 ID: "12422",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentry Insurance Company Employees",
		 ID: "MCDID",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sentry Life Insurance Company",
		 ID: "PAS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sentry Select Insurance Company",
		 ID: "20510",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seoul Medical Group",
		 ID: "42943",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sequoia Health IPA",
		 ID: "USI01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Serendib Healthways",
		 ID: "13978",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Service Lloyds Insurance Co",
		 ID: "NHC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seton Charity Plan",
		 ID: "77076",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seton Health Plan - CHIP",
		 ID: "DSL01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Seton Health Plan (Employee Plans ONLY)",
		 ID: "J1566",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seton Health Plan Care Program",
		 ID: "EPF37",
		 time: "< 30 mins"
	},
	{
		 NAME: "Seton Map Program (Mediview)",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seton Star",
		 ID: "J1017",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Seven Corners",
		 ID: "11502",
		 time: "< 30 mins"
	},
	{
		 NAME: "SGIC",
		 ID: "CCHPC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Share Healthcare",
		 ID: "251CC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Shared Health Mississippi",
		 ID: "11789",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sharp Community Medical Group",
		 ID: "81400",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sharp Health Plan",
		 ID: "59324",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sharp Rees-Stealy Medical Group",
		 ID: "J1564",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Shasta Administrative Services",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "SHC Advantage",
		 ID: "46407",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sheakley Unicomp",
		 ID: "HALCY",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sheboygan Falls Insurance Company",
		 ID: "25169",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sheet Metal Workers Local 91",
		 ID: "J4007",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Shelter Point Life",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sheltered Workshop Insurance Of Missouri - CORVEL",
		 ID: "64556",
		 time: "< 30 mins"
	},
	{
		 NAME: "SIEBA, LTD",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sierra Health and Life - Encounters",
		 ID: "11983",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sierra Health and Life (Claims)",
		 ID: "31059",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sierra Medical Group (Regal)",
		 ID: "IVHPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sierra Sacramento Valley Regional",
		 ID: "16180",
		 time: "< 30 mins"
	},
	{
		 NAME: "Signature Advantage",
		 ID: "00801",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SIHRTE",
		 ID: "31625",
		 time: "< 30 mins"
	},
	{
		 NAME: "Silver Cross Managed Care Organization",
		 ID: "MR057",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SilverSummit Healthplan - Medical",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Simply Health Care Plan",
		 ID: "FEDMI",
		 time: "< 30 mins"
	},
	{
		 NAME: "SIMPRA Advantage",
		 ID: "EC999",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sinclair Health Plan",
		 ID: "NW002",
		 time: "< 30 mins"
	},
	{
		 NAME: "SISCO (RCM&D Self Insured Services Company, Cost Containment Services)",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SISCO Benefits",
		 ID: "00801",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SkyGen: DCMED",
		 ID: "CANO1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Smith Administrators",
		 ID: "00060",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Smoky Mountain Center (now Vaya Health)",
		 ID: "BPS77",
		 time: "< 30 mins"
	},
	{
		 NAME: "Society Insurance",
		 ID: "J3737",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Solarte Health",
		 ID: "LV371",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Solidarity Healthshare",
		 ID: "83269",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Solis Health Plans",
		 ID: "25463",
		 time: "< 30 mins"
	},
	{
		 NAME: "Solstice Benefits",
		 ID: "SX110",
		 time: "< 30 mins"
	},
	{
		 NAME: "SOMOS Anthem New York",
		 ID: "81508",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SOMOS Emblem",
		 ID: "20488",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sound Health & Wellness Trust (Aetna)",
		 ID: "10365",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sound Health (now known as First Choice Health Network)",
		 ID: "35202",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sound Health (Sisters of Providence)",
		 ID: "ECMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sound Health Wellness Trust",
		 ID: "J1913",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Soundpath Health (formerly known as Puget Sound Health Partners)",
		 ID: "89241",
		 time: "< 30 mins"
	},
	{
		 NAME: "South Atlantic Medical Group",
		 ID: "CAHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "South Carolina Healthy Connections",
		 ID: "00265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Central Preferred",
		 ID: "OSFC9",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Coast Area Transit (CA) - CORVEL",
		 ID: "MCCOB",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Country Health Alliance",
		 ID: "74284",
		 time: "< 30 mins"
	},
	{
		 NAME: "South Dakota Behavioral Health - STARS / State Funding",
		 ID: "59324",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Dakota DSS - STARS / State Funding",
		 ID: "10101",
		 time: "< 30 mins"
	},
	{
		 NAME: "South Florida Community Care Network - SFCCN (Commercial)",
		 ID: "J1033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Florida Community Care Network - SFCCN (Medicaid)",
		 ID: "37330",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Florida Musculo Skeletal Care (SFMSC)",
		 ID: "SHPCH",
		 time: "< 30 mins"
	},
	{
		 NAME: "South Plains School WC Program",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "South Tahoe Refuse",
		 ID: "93029",
		 time: "< 30 mins"
	},
	{
		 NAME: "SouthCare/Healthcare Preferred",
		 ID: "37281",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southeastern Indiana Health (SIHO)",
		 ID: "59204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern Benefit Services",
		 ID: "99730",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southern California Children's Healthcare Network",
		 ID: "J2004",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern California Dairy",
		 ID: "47163",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern California Healthcare System (SCHS) Alta Pod by MedPOINT - Health Net",
		 ID: "J4250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern California Lumber",
		 ID: "86048",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southern California Oncology Associates (SCOA)",
		 ID: "CCHP9",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southern California Oncology Associates (SCOA) - Encounters",
		 ID: "VACCN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern California Physicians Managed Care Services (SCPMCS)",
		 ID: "37312",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southern California UFCW Unions & Food Employers",
		 ID: "35174",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern Illinois Healthcare Association",
		 ID: "251CC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern Mutual Insurance Company",
		 ID: "31401",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southern Textile Recycling, Inc. (CA) - CORVEL",
		 ID: "35198",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southland Advantage Medical Group, Inc.",
		 ID: "OSFE9",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southland IPA",
		 ID: "13265",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southland San Gabriel Valley Medical Group, Inc.",
		 ID: "56724",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southwest Key - CORVEL",
		 ID: "SAMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southwest MI Behavioral Health - Mental Health",
		 ID: "PROH2",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southwest MI Behavioral Health Substance Use Disorder",
		 ID: "22284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southwest Oregon IPA (DOCS)",
		 ID: "47046",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southwest Service Administrators",
		 ID: "J1689",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southwest Service Life",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Southwestern Health Resources",
		 ID: "38224",
		 time: "< 30 mins"
	},
	{
		 NAME: "Southwestern Health Resources Clinically Integrated Network",
		 ID: "88023",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Space Exploration Technologies Corp - CORVEL",
		 ID: "37215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Special Agents Mutual Benefit Association",
		 ID: "00790",
		 time: "< 30 mins"
	},
	{
		 NAME: "Spencer Stuart (ARM, LTD)",
		 ID: "00820",
		 time: "< 30 mins"
	},
	{
		 NAME: "Spina Bifida - VA HAC",
		 ID: "LNC01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Spohn Health",
		 ID: "HPSJ1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sprouts Farmers Market, Inc. - CORVEL",
		 ID: "71412",
		 time: "< 30 mins"
	},
	{
		 NAME: "Spruce Grove Inc - CORVEL",
		 ID: "11889",
		 time: "< 30 mins"
	},
	{
		 NAME: "SRC - Strategic Resource Company",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "SRS - Cargill, Inc.",
		 ID: "00241",
		 time: "< 30 mins"
	},
	{
		 NAME: "St James PHO",
		 ID: "42344",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St Mary's IPA",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "St Vincent Medical Center (STVMC)",
		 ID: "14316",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Barnabas System Health Plan",
		 ID: "95271",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Francis - AllCare by MedPOINT",
		 ID: "MCDAL",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Francis - HCLA by MedPOINT",
		 ID: "80241",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Francis IPA",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. John's Claim Administration",
		 ID: "64246",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Johns Health Clinic",
		 ID: "00890",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Joseph Heritage Healthcare",
		 ID: "128VA",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Joseph Heritage Medical Group",
		 ID: "26362",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Joseph Hospital Affiliated",
		 ID: "CCIHJ",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Jude Affiliated Physicians",
		 ID: "15459",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Jude Heritage Medical Group",
		 ID: "SVSVN",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Mary High Desert",
		 ID: "19046",
		 time: "> 30 Mins"
	},
	{
		 NAME: "St. Mary Medical Center",
		 ID: "48603",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Peter Medical Group, Inc.",
		 ID: "46227",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Therese Physician Associates",
		 ID: "ITXEN",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Thomas Med Ntwk Gulfquest",
		 ID: "19432",
		 time: "< 30 mins"
	},
	{
		 NAME: "St. Vincent IPA",
		 ID: "81400",
		 time: "< 30 mins"
	},
	{
		 NAME: "Standard Insurance",
		 ID: "INFIN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Standard Insurance Co. of New York",
		 ID: "TMHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Standard Life & Accident Insurance Company",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Stanley Steemer International Inc - CORVEL",
		 ID: "00870",
		 time: "< 30 mins"
	},
	{
		 NAME: "Stanley Works, Inc.",
		 ID: "DDAK1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Starlife Holdings",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Starmark",
		 ID: "40137",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Compensation Insurance Fund (SCIF) of California",
		 ID: "31074",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Farm",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "State Farm Insurance Company",
		 ID: "J3744",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Farm Property & Casualty",
		 ID: "J1699",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Fund Mutual Insurance",
		 ID: "ALIVI",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State Health Plan of North Carolina",
		 ID: "SKUT0",
		 time: "< 30 mins"
	},
	{
		 NAME: "State Health Plan of SC",
		 ID: "TH131",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State of Idaho",
		 ID: "00851",
		 time: "< 30 mins"
	},
	{
		 NAME: "State of Idaho Women's Health Check\t",
		 ID: "CPP03",
		 time: "< 30 mins"
	},
	{
		 NAME: "State of Minnesota - DOER",
		 ID: "DCMED",
		 time: "< 30 mins"
	},
	{
		 NAME: "State of North Carolina - CORVEL",
		 ID: "CDCMD",
		 time: "< 30 mins"
	},
	{
		 NAME: "State Of Tennessee - CORVEL",
		 ID: "16158",
		 time: "> 30 Mins"
	},
	{
		 NAME: "State of Texas Dental Plan (GEHA)",
		 ID: "IP084",
		 time: "< 30 mins"
	},
	{
		 NAME: "State Office of Risk Management",
		 ID: "13551",
		 time: "< 30 mins"
	},
	{
		 NAME: "Statewide Insurance Fund",
		 ID: "35182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Staywell Health Plan",
		 ID: "36273",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sterling Medicare Advantage",
		 ID: "MMS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sterling Option One",
		 ID: "56089",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Stirling Benefits",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Stone Mountain Risk",
		 ID: "65055",
		 time: "< 30 mins"
	},
	{
		 NAME: "Stowe Associates",
		 ID: "75600",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Student Insurance ",
		 ID: "37264",
		 time: "< 30 mins"
	},
	{
		 NAME: "Student Resources (UnitedHealthcare)",
		 ID: "19430",
		 time: "< 30 mins"
	},
	{
		 NAME: "Style Crest Enterprise",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sulphur Bluff ISD",
		 ID: "HHMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Summacare Health Plan (HMO)",
		 ID: "43160",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Summit Administration Services, Inc.",
		 ID: "56213",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Summit America Insurance Services, Inc.",
		 ID: "CB268",
		 time: "< 30 mins"
	},
	{
		 NAME: "Summit Community Care",
		 ID: "MPM26",
		 time: "< 30 mins"
	},
	{
		 NAME: "Summit Holdings ",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sun Life Financial (United Concordia)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sun Mar Healthcare - CORVEL",
		 ID: "SC050",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sunflower State - Medical",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sunrise Advantage Plan of Illinois",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sunrise Advantage Plan of New York ",
		 ID: "58204",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sunrise Advantage Plan of Pennsylvania",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sunrise Advantage Plan of Virginia",
		 ID: "18768",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sunshine State Health Plan (Ambetter) - Medical",
		 ID: "DMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Superior Choice Medical Group ",
		 ID: "J1619",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Superior Dental Care",
		 ID: "CB404",
		 time: "< 30 mins"
	},
	{
		 NAME: "Superior Health Plan (Ambetter) - Medical",
		 ID: "GNPMG",
		 time: "< 30 mins"
	},
	{
		 NAME: "Superior Insurance Services",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Superior Point",
		 ID: "ACPCE",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Surest",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Surgery One IPA",
		 ID: "SB810",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sussex Insurance Company",
		 ID: "J1453",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sutter Connect - East Bay Region Hospitals - B&T",
		 ID: "A0138",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sutter Connect – East Bay Region Hospitals – Non Sutter Groups",
		 ID: "10153",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter Connect - Palo Alto Medical Foundation",
		 ID: "00601",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sutter Connect - Solano Regional Medical Foundation (SRMF)",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sutter Connect - Sutter Delta Medical Group",
		 ID: "10202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Sutter Connect - Sutter Gould Medical Foundation (SGMF)",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter Connect - Sutter Independent Physicians, Sutter Medical Group, Sutter West Medical Group  (SIP/SMG/SWMG)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter Connect - Sutter Medical Group of the Redwoods, Santa Rosa Network (SMGR/SRN)",
		 ID: "44827",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter East Bay Medical Foundation",
		 ID: "MPM20",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter Health",
		 ID: "F7422700",
		 time: "< 30 mins"
	},
	{
		 NAME: "Sutter Senior Care",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "SVS Vision Inc.",
		 ID: "19450",
		 time: "< 30 mins"
	},
	{
		 NAME: "Swedish Covenant Hospital",
		 ID: "95677",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Swift Glass Corporation (UCS)",
		 ID: "HCC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "SynerMed",
		 ID: "J3765",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Syngenta Corporation",
		 ID: "SC018",
		 time: "< 30 mins"
	},
	{
		 NAME: "T.H.E. Insurance - CORVEL",
		 ID: "94276",
		 time: "< 30 mins"
	},
	{
		 NAME: "T2017 Tricare West (Previously known as UnitedHealthcare Military & Veterans)",
		 ID: "VAPRM",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tailored Brands Inc. - CORVEL",
		 ID: "VACCN",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TakeCare Insurance Company",
		 ID: "TMIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tall Tree Administrators",
		 ID: "C1028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tawa Services, Inc. - CORVEL",
		 ID: "39167",
		 time: "< 30 mins"
	},
	{
		 NAME: "Taylor Benefit Resource",
		 ID: "1925460",
		 time: "< 30 mins"
	},
	{
		 NAME: "TCC (Self-Funded)",
		 ID: "71037",
		 time: "< 30 mins"
	},
	{
		 NAME: "TCC Benefits Administrator - Pre-Med Defender",
		 ID: "65093",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TCU-LA MTA",
		 ID: "65456",
		 time: "< 30 mins"
	},
	{
		 NAME: "TDC (The Dental Companies)",
		 ID: "77013",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Teal Premier",
		 ID: "C1055",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Team Choice PNS",
		 ID: "IP097",
		 time: "< 30 mins"
	},
	{
		 NAME: "Teamcare",
		 ID: "J1241",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Teamsters Miscellaneous Security Trust Fund - Northwest Administrators",
		 ID: "58182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Teamsters Welfare Trust",
		 ID: "PBHD1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Technology Insurance Co",
		 ID: "88052",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tech-Steel, Inc",
		 ID: "MPM06",
		 time: "< 30 mins"
	},
	{
		 NAME: "Teddy Health",
		 ID: "34131",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Telamon",
		 ID: "J3870",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Temecula Valley Medical Group",
		 ID: "00430",
		 time: "< 30 mins"
	},
	{
		 NAME: "TennCare Select/ BlueCare",
		 ID: "95202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tervist",
		 ID: "NCA01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texan Plus",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "TexanPlus of Texas (Houston)",
		 ID: "13857",
		 time: "< 30 mins"
	},
	{
		 NAME: "TexanPlus of Texas (Kelsey-Seybold)",
		 ID: "68035",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Agricultural Cooperative Trust (TACT)",
		 ID: "J1503",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas Association of School Boards",
		 ID: "J1617",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas Childrens Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Children's Health Plan",
		 ID: "CX046",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Childrens Health Plan - Medicaid",
		 ID: "MR046",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas First Health Plans",
		 ID: "68024",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas HealthSpring",
		 ID: "00800",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas Hospital Insurance",
		 ID: "SC051",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Independence Health Plan",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas Medicaid & Healthcare Partnership (TMHP)",
		 ID: "03432",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Texas Municipal League - Intergovernmental Risk Pool",
		 ID: "77950",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Mutual Insurance",
		 ID: "SX155",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Roadhouse, Inc.",
		 ID: "20452",
		 time: "< 30 mins"
	},
	{
		 NAME: "Texas Tech University Medical",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Alliance",
		 ID: "00806",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Benefit Group, Inc.",
		 ID: "MMFMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Boon Group",
		 ID: "43168",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Care Network/The Savannah Business Group",
		 ID: "44054",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Cheesecake Factory - CORVEL",
		 ID: "37278",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Chesterfield Companies",
		 ID: "31182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The CHP Group",
		 ID: "27327",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The City of Odessa",
		 ID: "25923",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Colorado Purchasing Alliance",
		 ID: "92579",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Gap, Inc. - CORVEL",
		 ID: "07689",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Hartford",
		 ID: "BLUEC",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Health Exchange (Cerner Corporation)",
		 ID: "J4253",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Health Plan",
		 ID: "HPOUV",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Health Plan of Upper Ohio Valley",
		 ID: "HLPBS",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Health Plan of West Virginia (Age <20)",
		 ID: "ALTAM",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Health Plan of West Virginia (Age 21+)",
		 ID: "39045",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Health Plan of West Virginia (Medicaid)",
		 ID: "VALOP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Health Plan of Western Illinois (HOWI)",
		 ID: "MPM48",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Jones Financial Companies, LLP - CORVEL",
		 ID: "89486",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Loomis Company",
		 ID: "WVS01",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Macaluso Group",
		 ID: "MR085",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Main Street America Group / Austin Mutual - CORVEL",
		 ID: "58234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Main Street America Group / Grain Dealers Mutual - CORVEL",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Main Street America Group / Great Lakes Casualty - CORVEL",
		 ID: "62171",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Main Street America Group / Main Street America Assurance - CORVEL",
		 ID: "15682",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Main Street America Group / MSA Insurance Protection - CORVEL",
		 ID: "J1729",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Main Street America Group / MSAIC Surplus/Non-Admitted - CORVEL",
		 ID: "SHPS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Main Street America Group / NGM - CORVEL",
		 ID: "10253",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Main Street America Group / Old Dominion Insurance Company - CORVEL",
		 ID: "DMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Mutual Group",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Physicians Alliance Corp.",
		 ID: "CB987",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Preferred Healthcare System - PPO",
		 ID: "USN01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Vitamin Shoppe - CORVEL",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Zenith",
		 ID: "J3929",
		 time: "> 30 Mins"
	},
	{
		 NAME: "The Zenith - YMCA San Diego",
		 ID: "12K89",
		 time: "< 30 mins"
	},
	{
		 NAME: "The Zero Card LLC",
		 ID: "25147",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Therapy Direct Network",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "THIPA",
		 ID: "34192",
		 time: "< 30 mins"
	},
	{
		 NAME: "Thomas H. Cooper",
		 ID: "MPM28",
		 time: "< 30 mins"
	},
	{
		 NAME: "Thomas McGee",
		 ID: "J3747",
		 time: "> 30 Mins"
	},
	{
		 NAME: "THREE",
		 ID: "U1439",
		 time: "< 30 mins"
	},
	{
		 NAME: "Three Rivers Preferred",
		 ID: "PAMPR",
		 time: "< 30 mins"
	},
	{
		 NAME: "Thrivent Financial",
		 ID: "THRIV",
		 time: "< 30 mins"
	},
	{
		 NAME: "Thrivent Financial Aid Association for Lutheran Medicare",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Thrivent Financial Lutheran Brotherhood Medicare",
		 ID: "C1096",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tiffany & Co - CORVEL",
		 ID: "18768",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tift Regional",
		 ID: "38692",
		 time: "< 30 mins"
	},
	{
		 NAME: "TKFMC",
		 ID: "CB148",
		 time: "< 30 mins"
	},
	{
		 NAME: "TLC Advantage of Sioux Falls",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "TLC Benefit Solutions",
		 ID: "63092",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TML IRP - Texas Municipal League - Intergovernmental ",
		 ID: "38217",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Todays Health",
		 ID: "39151",
		 time: "< 30 mins"
	},
	{
		 NAME: "Together with CCHP",
		 ID: "DDAL1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Toll Brothers - CORVEL",
		 ID: "AMM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tongass Timber Trust",
		 ID: "ILDTA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tooling and Manufacturing Assocation",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Torrance Hospital IPA (THIPA)",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Torrance Memorial Medical Center",
		 ID: "11144",
		 time: "< 30 mins"
	},
	{
		 NAME: "Total Community Care",
		 ID: "71499",
		 time: "< 30 mins"
	},
	{
		 NAME: "Total Plan Concepts",
		 ID: "J3862",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Total Plan Services (via SmartData)",
		 ID: "MR002",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Total Senior Care",
		 ID: "31441",
		 time: "< 30 mins"
	},
	{
		 NAME: "Touchpoint Solutions / Touchpoint CRM)",
		 ID: "71890",
		 time: "< 30 mins"
	},
	{
		 NAME: "Touchstone Health PSO",
		 ID: "87065",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tower Energy Group",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tower Insurance Company Of New York",
		 ID: "27726",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tower National Insurance Company",
		 ID: "23266",
		 time: "< 30 mins"
	},
	{
		 NAME: "Town of Colma (CA)",
		 ID: "26375",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TR Paul, Inc.",
		 ID: "HMSA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tractor Supply Company",
		 ID: "29076",
		 time: "< 30 mins"
	},
	{
		 NAME: "Transamerica Life Insurance Company (TLIC)",
		 ID: "RMHMO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Transamerica Premier Life Insurance Company",
		 ID: "AMM03",
		 time: "< 30 mins"
	},
	{
		 NAME: "TransChoice - Key Benefit Administrators",
		 ID: "SCP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Transplant Associates Baylor Billing",
		 ID: "C1028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Transwestern General Agency",
		 ID: "J2181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Transwestern Insurance Administrators, Inc",
		 ID: "87815",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Travelers",
		 ID: "CH00102",
		 time: "< 30 mins"
	},
	{
		 NAME: "Travelers Auto",
		 ID: "PAIPA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Travelers Insurance",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Travis County MAP - Mediview",
		 ID: "SHM01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trellis Health Partners",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "TRIAD HEALTHCARE INC",
		 ID: "HDNFC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Triada Assurance",
		 ID: "SIPA1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Triangle Insurance",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tribado",
		 ID: "TMHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tribute Health Plan of Arkansas",
		 ID: "95166",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tribute Health Plan of Oklahoma",
		 ID: "20270",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tricare Active Reservists",
		 ID: "MPM16",
		 time: "< 30 mins"
	},
	{
		 NAME: "TriCare East",
		 ID: "52629",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tricare for Life",
		 ID: "J1990",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tricare Overseas",
		 ID: "51020",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tricare West",
		 ID: "STVMC",
		 time: "< 30 mins"
	},
	{
		 NAME: "TriCities IPA",
		 ID: "NM505",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tri-City Healthcare District",
		 ID: "91131",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tri-County Schools Insurance Group JPA  ",
		 ID: "63240",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trigon Blue Cross Blue Shield of Colorado",
		 ID: "MC051",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trigon Blue Cross Blue Shield of Virginia",
		 ID: "88250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trigon Insurance Company",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TriHealth Physician Solutions",
		 ID: "ACC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trillium Community - Medicaid",
		 ID: "27034",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trillium Community - Medicare",
		 ID: "22945",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trillium Community Health Plans (Agate Resources/LIPA)",
		 ID: "CB951",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trillium Staffing Solutions",
		 ID: "SM325",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trilogy Network",
		 ID: "34924",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trinity Universal Insurance Company",
		 ID: "94582",
		 time: "< 30 mins"
	},
	{
		 NAME: "TriStar (formerly Select Benefit Administrators)",
		 ID: "67136",
		 time: "< 30 mins"
	},
	{
		 NAME: "TriState Benefit Solutions",
		 ID: "MCCVA",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tri-Valley Medical Group",
		 ID: "64246",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TriWest Healthcare Alliance",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "TRLHN/AS",
		 ID: "09908",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tru Blue TPA",
		 ID: "22823",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TruAssure Dental",
		 ID: "GATHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "True Health New Mexico",
		 ID: "23250",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Truli Health",
		 ID: "ARISE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trusted Health Plan",
		 ID: "SLOS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trustee Plans (FCHN)",
		 ID: "77799",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trusteed Insurance (FCHN)",
		 ID: "68039",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Trusteed Plans Service Corporation",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TRUSTMARK                                                  ",
		 ID: "SJHC1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Trustmark Insurance Company",
		 ID: "65432",
		 time: "> 30 Mins"
	},
	{
		 NAME: "TSRDP Dental Claims",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Tufts Health Plan",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tufts Health Public Plan (Network Health)",
		 ID: "STARS",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Twin City Fire Insurance Co.",
		 ID: "71412",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Tyson Foods Inc.",
		 ID: "13337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UC Davis Health System",
		 ID: "10018",
		 time: "< 30 mins"
	},
	{
		 NAME: "UC Health Plan Administrators",
		 ID: "CCHPC",
		 time: "< 30 mins"
	},
	{
		 NAME: "UC Irvine",
		 ID: "41147",
		 time: "< 30 mins"
	},
	{
		 NAME: "UC Irvine Health (MemorialCare)",
		 ID: "SHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCare Individual & Family Plans (DOS on or After 1/1/2019)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCare Minnesota",
		 ID: "DOCSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCare of Minnesota",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCLA Medical Group",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - Al Fe Heat",
		 ID: "J1422",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - BASI: Lynco",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - BASI: Williams Plumbing & Heating",
		 ID: "59227",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - Coeur: Connectivity Source",
		 ID: "CTPL1",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - Coeur: Genesis Park Management",
		 ID: "ONEHC",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - Coeur: Hermann Sons Life",
		 ID: "60230",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - Coeur: Leonard Holding Company",
		 ID: "84365",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - Coeur: Master Mobile Link",
		 ID: "77078",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - Coeur: Northwest Petroleum",
		 ID: "J1477",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - Coeur: Sercel",
		 ID: "KGA15",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS - Coeur: The Woodlands Christian Academy",
		 ID: "81312",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - Insight Benefit Administrators",
		 ID: "58213",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS - International Medical Group: FBOP Otisville",
		 ID: "70408",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Air Design Heating & Cooling)",
		 ID: "J1779",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: American Biotech Labs)",
		 ID: "CSSD2",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Belue Trucking Company)",
		 ID: "HSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Big Horn Federal)",
		 ID: "80141",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Ceiling Systems)",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: DC Welding & Construction)",
		 ID: "SVIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Dunham Rubber & Belting Corporation)",
		 ID: "C1088",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Empire Lumber Company)",
		 ID: "PARTH",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Golden Eagle Construction)",
		 ID: "86047",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Great Basin Industrial)",
		 ID: "MSO22",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Hanson Trucking)",
		 ID: "PHP00",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Idaho Asphalt Supply)",
		 ID: "J1966",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Inland Construction & Development Co.)",
		 ID: "10258",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Innovative Health Care Concepts)",
		 ID: "73143",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Knight Construction & Supply)",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Nitro Green Helena)",
		 ID: "96436",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Northwest River Supplies)",
		 ID: "36334",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Raphael Health Center)",
		 ID: "00773",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Reading Horizons)",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Rehab Strategies)",
		 ID: "J1688",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Senior Suites at Woodside Village)",
		 ID: "14212",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Somont Oil Company)",
		 ID: "NEXUS",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: South State Trailer Supply)  ",
		 ID: "91051",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Tempest Technologies)",
		 ID: "SNTCC",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Tenowo Inc.)",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: TrailWest Bank)",
		 ID: "75137",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (BASI: Vans Inc)",
		 ID: "95340",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (BASI: Whitefish Credit Union)",
		 ID: "ARISE",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (ELM-CO LLC)",
		 ID: "SA001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS (Kempton Group TPA: Shawnee Milling Company)",
		 ID: "45289",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS (The City of East Chicago)",
		 ID: "06302",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS BASI Hotstart",
		 ID: "AFNTY",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS BASI: Carey Johnson Oil",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS BASI: Consolidated Turbine",
		 ID: "46299",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS BASI: Ellsworth Constructions",
		 ID: "OSFC9",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS BASI: Limric Plumbing, Heating & Air",
		 ID: "95567",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS BASI: Meter Group USA",
		 ID: "16025",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS BASI: Smith Rogers Oil",
		 ID: "36090",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS BASI: T.W. Clark Construction",
		 ID: "62308",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS Benefit Plan Administrators: East Jefferson Hospital",
		 ID: "75133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Benovation",
		 ID: "12422",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Cement Masons",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Coeur Humphrey Associates",
		 ID: "MRCHP",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Core: Nemco",
		 ID: "TCC13",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS Core: TJR Equipment",
		 ID: "MPM27",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS Couer: My Management Consulting inc (MY Wireless)",
		 ID: "J1839",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS ES Beveridge & Associates Inc: Grace Schools",
		 ID: "J4181",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS ES Beveridge & Associates Inc: Worthington Christian School",
		 ID: "02057",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS PBS Oregon",
		 ID: "34525",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Seminole Tribe of Florida",
		 ID: "J3897",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS Tucker Administrators Inc: Cloninger Ford/Toyota",
		 ID: "56162",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS: Benefit Plan Administrators of Eau Claire, Inc.",
		 ID: "61425",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS: CAM Administrative Services, INC.",
		 ID: "37242",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS: Coeur Plan Services- Pinnacle Medical Systems, Inc",
		 ID: "00800",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS: Diversified Benefit Administrators, Inc.",
		 ID: "26748",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCS: E.S. Beveridge & Associate Inc",
		 ID: "J1418",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UCS: Fixtures & Drywall Company of OK, Inc. (FADCO, Inc.)",
		 ID: "INPA1",
		 time: "< 30 mins"
	},
	{
		 NAME: "UCSD Physician Network (UCSDPN)",
		 ID: "72126",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UDR, Inc. - CORVEL",
		 ID: "93093",
		 time: "< 30 mins"
	},
	{
		 NAME: "UFCW (Local Food & Commercial Workers) Local 400 & Employers Health and Welfare Fund",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UFCW Northern California &Drug Employers H & W Trust Fund",
		 ID: "VAPRM",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UFT Welfare Fund",
		 ID: "52193",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UHA Health Insurance",
		 ID: "SIL01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UHP Management",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UHSM-Unite Health Share Ministries",
		 ID: "00019",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UICI Administrators",
		 ID: "75240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ultimate Health Plans",
		 ID: "J3893",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ultra Benefits Inc.",
		 ID: "65113",
		 time: "< 30 mins"
	},
	{
		 NAME: "UMass Medical School Health & Criminal Justice Program",
		 ID: "MR007",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UMC Health Plan",
		 ID: "43168",
		 time: "< 30 mins"
	},
	{
		 NAME: "Umpqua Health Alliance",
		 ID: "HPN11",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UMR (formerly UMR Onalaska/Midwest Security/Harrington)",
		 ID: "59144",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UMR (formerly UMR Wausau)",
		 ID: "23856",
		 time: "< 30 mins"
	},
	{
		 NAME: "UMR Wausau",
		 ID: "93029",
		 time: "< 30 mins"
	},
	{
		 NAME: "Umwa Health & Retirement Funds",
		 ID: "76342",
		 time: "< 30 mins"
	},
	{
		 NAME: "Underwriters Safety and Claims",
		 ID: "92135",
		 time: "< 30 mins"
	},
	{
		 NAME: "Underwriters Services Corporation",
		 ID: "00241",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Unicare",
		 ID: "37267",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Unicare of WV ",
		 ID: "mpm57",
		 time: "< 30 mins"
	},
	{
		 NAME: "UNICARE Special Accounts",
		 ID: "88027",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unified Group Services",
		 ID: "87765",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unified Health Services (Workman's Comp Only)",
		 ID: "22321",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unified Life Insurance Company",
		 ID: "18764",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unified Physicians Network",
		 ID: "10530",
		 time: "< 30 mins"
	},
	{
		 NAME: "Uniform Medical Plan",
		 ID: "FCC20",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Uniformed Services Family Health Plan",
		 ID: "98010",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Unify HealthCare Administrators",
		 ID: "HSMSO",
		 time: "< 30 mins"
	},
	{
		 NAME: "UNIGARD (FCHN)",
		 ID: "00430",
		 time: "< 30 mins"
	},
	{
		 NAME: "Union Construction Workers' Comp Program",
		 ID: "74227",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Union Insurance Company of Providence",
		 ID: "BKMD1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Union Pacific Railroad Employees Health Systems (UPREHS)",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unite Health Share Ministries",
		 ID: "NCHON",
		 time: "< 30 mins"
	},
	{
		 NAME: "Unite HERE Health-LV",
		 ID: "MMS01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Agriculture Benefit Trust",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Benefits (formerly Brown & Brown Benefits)",
		 ID: "PDT01",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Care Medical Group (UCMG) (Regal)",
		 ID: "AXM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Claim Solutions",
		 ID: "00590",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Concordia - Dental - PA Blue Shield (Provider will need to register with ucci and inform them that EDI Health group is their clearinghouse 800-633-5430 opt 0)",
		 ID: "26375",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Concordia - Dental Plus",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Concordia - Dental Plus - PA Blue Shield",
		 ID: "ECHP1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Concordia - Tricare Dental Plan",
		 ID: "41202",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Concordia (Encounters)",
		 ID: "34189",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Concordia Companies, Inc.",
		 ID: "ACP17",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Dominion Realty Trust ",
		 ID: "GMICC",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Employee Benefit Trust (FCHN)",
		 ID: "41178",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Financial Casualty Company",
		 ID: "05102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Fire Group (aka Mercer Insurance)",
		 ID: "59140",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Food & Commercial Workers Unions and Food Employers Benefit Fund",
		 ID: "10095",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Group Programs",
		 ID: "UC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Health Services, Inc. dba Pruitt Health - CORVEL",
		 ID: "37321",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Healthcare",
		 ID: "84962",
		 time: "< 30 mins"
	},
	{
		 NAME: "United HealthOne / Golden Rule",
		 ID: "27357",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Heartland",
		 ID: "75137",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Medical Alliance (UMA)",
		 ID: "C1084",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United Medical Resources",
		 ID: "AHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Medical Resources (UMR)",
		 ID: "68069",
		 time: "< 30 mins"
	},
	{
		 NAME: "United of Omaha",
		 ID: "13360",
		 time: "< 30 mins"
	},
	{
		 NAME: "United Physicians International (UPI)",
		 ID: "37215",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United States Automobile Association (USAA)",
		 ID: "COMMF",
		 time: "> 30 Mins"
	},
	{
		 NAME: "United States Fire Insurance Company",
		 ID: "75386",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare ",
		 ID: "40585",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare (UHC)",
		 ID: "31145",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare / All Savers Alternate Funding",
		 ID: "20416",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare / All Savers Insurance",
		 ID: "95426",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare / Definity Health Plan",
		 ID: "29018",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare / Empire Plan",
		 ID: "CARMO",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare / MAHP - MD IPA, Optimum Choice and MLH (formerly MAMSI)",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare / Oxford",
		 ID: "EV001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare / Spectera Eyecare Networks",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare / UHIS - UnitedHealth Integrated Services",
		 ID: "52214",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
		 ID: "RCHN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare / UnitedHealthcare West (formerly PacifiCare)",
		 ID: "J1027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan - Arizona",
		 ID: "38217",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan - Kansas (KanCare)",
		 ID: "J1550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan - New Jersey",
		 ID: "NMM06",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan - Tennessee",
		 ID: "39113",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / AZ (formerly Arizona Physicians IPA and APIPA)",
		 ID: "52132",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / CA, DE, FL, HI, IA, LA, MA, MD, MS CAN, NC, NE, NM, NY, OH, OK, PA, RI, TX, VA, WA, WI (formerly AmeriChoice or Unison)",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / IA, hawk-i (formerly AmeriChoice of Iowa)",
		 ID: "MCDMO",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / KS (KanCare)",
		 ID: "SAMHP",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / MI (formerly Great Lakes Health Plan)",
		 ID: "86304",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / MS (formerly AmeriChoice MS - CHIP)",
		 ID: "60061",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / NE (formerly Americhoice NE, ShareAdvantage, and UnitedHealthcare of the Midlands)",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / NJ (formerly AmeriChoice NJ Medicaid, NJ Family Care, NJ Personal Care Plus)",
		 ID: "CB212",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / NY (formerly AmeriChoice by UnitedHealthcare, AmeriChoice NY Medicaid & Child Health Plus, AmeriChoice NY Personal Care Plus)",
		 ID: "31441",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / SC (formerly Unison)",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / TN (formerly AmeriChoice TN: TennCare, Secure Plus Complete)",
		 ID: "60601",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete - Oxford Medicare Network",
		 ID: "22264",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete (formerly Evercare)",
		 ID: "77044",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / UnitedHealthcare Long Term Care (formerly Evercare)",
		 ID: "RP075",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
		 ID: "GHIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Community Plan of Missouri",
		 ID: "23284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Medicare Solutions / UnitedHealthcare Chronic Complete  (formerly Evercare)",
		 ID: "37242",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Medicare Solutions / UnitedHealthcare Group Medicare Advantage",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareComplete (formerly SecureHorizons)",
		 ID: "00801",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareDirect  (formerly SecureHorizons)",
		 ID: "CHPWA",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Medicare Solutions / UnitedHealthcare Nursing Home Plan  (formerly Evercare)",
		 ID: "55489",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare NDC Claims",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Ohio Medicaid",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Ohio Medicaid Dental",
		 ID: "J1722",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthcare Shared Services (UHSS)",
		 ID: "CB148",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthcare Student Resources",
		 ID: "J3735",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthOne / Golden Rule",
		 ID: "48117",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UnitedHealthOne / PacifiCare Life and Health Insurance Company",
		 ID: "73143",
		 time: "< 30 mins"
	},
	{
		 NAME: "UnitedHealthOne / UnitedHealthcare Life Insurance Company (formerly American Medical Security)",
		 ID: "64157",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Unity Health Insurance",
		 ID: "62308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Unity Health Partners",
		 ID: "15682",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Univera Healthcare (Excellus)",
		 ID: "74234",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Univera PPO (Excellus)",
		 ID: "59064",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Universal Care (FFS)",
		 ID: "35198",
		 time: "< 30 mins"
	},
	{
		 NAME: "Universal Care Encounters",
		 ID: "26337",
		 time: "< 30 mins"
	},
	{
		 NAME: "Universal Fidelity Administrators Company - Aggregate",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Universal HealthCare IPA",
		 ID: "25133",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Care Advantage",
		 ID: "31456",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Family Care",
		 ID: "38338",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Health Alliance",
		 ID: "IEHP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Healthcare Group",
		 ID: "16003",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Healthcare Marketplace",
		 ID: "18151",
		 time: "> 30 Mins"
	},
	{
		 NAME: "University of Illinois",
		 ID: "BS001",
		 time: "< 30 mins"
	},
	{
		 NAME: "University of Illinois at Chicago Div of Specialized Care for Children",
		 ID: "J3965",
		 time: "> 30 Mins"
	},
	{
		 NAME: "University of Kentucky Health Services - CORVEL",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "University of Miami Behavioral Health",
		 ID: "MPM47",
		 time: "< 30 mins"
	},
	{
		 NAME: "University of Missouri",
		 ID: "J4253",
		 time: "> 30 Mins"
	},
	{
		 NAME: "University of Utah Health Plans",
		 ID: "SX155",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Physicians Care Advantage",
		 ID: "SHPS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Physicians Care Advantage (Arizona)",
		 ID: "HMC01",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Physicians Healthcare Group",
		 ID: "COSNM",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Physicians Healthcare Group (Arizona)",
		 ID: "BS001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "University Texas Medical Board\t",
		 ID: "CB231",
		 time: "< 30 mins"
	},
	{
		 NAME: "University Trust",
		 ID: "77103",
		 time: "< 30 mins"
	},
	{
		 NAME: "Upland Medical Group",
		 ID: "00590",
		 time: "> 30 Mins"
	},
	{
		 NAME: "UPMC Health Plan",
		 ID: "44273",
		 time: "< 30 mins"
	},
	{
		 NAME: "UPMC Vision Advantage",
		 ID: "87726",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Upper Peninsula Health Plan",
		 ID: "CSSD2",
		 time: "< 30 mins"
	},
	{
		 NAME: "US Benefits",
		 ID: "MCS03",
		 time: "< 30 mins"
	},
	{
		 NAME: "US Department of Labor",
		 ID: "C1097",
		 time: "> 30 Mins"
	},
	{
		 NAME: "US Department of Labor - Black Lung",
		 ID: "3833C",
		 time: "> 30 Mins"
	},
	{
		 NAME: "US Department of Labor - Energy",
		 ID: "HTHTX",
		 time: "< 30 mins"
	},
	{
		 NAME: "US Engagement, LLC",
		 ID: "38692",
		 time: "< 30 mins"
	},
	{
		 NAME: "US Family Health Plan of Texas and Louisiana (USFHP)",
		 ID: "87071",
		 time: "> 30 Mins"
	},
	{
		 NAME: "US Imaging Network",
		 ID: "00923",
		 time: "> 30 Mins"
	},
	{
		 NAME: "US Imaging Network II",
		 ID: "36493",
		 time: "< 30 mins"
	},
	{
		 NAME: "US Network and Administrative Services",
		 ID: "J1497",
		 time: "> 30 Mins"
	},
	{
		 NAME: "US Sugar Corporation",
		 ID: "SX105",
		 time: "< 30 mins"
	},
	{
		 NAME: "USAA Auto Only",
		 ID: "CHOC1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "USAA Life Insurance Company",
		 ID: "04320",
		 time: "< 30 mins"
	},
	{
		 NAME: "USFHP - St. Vincent Catholic Medical Centers of New York",
		 ID: "UCCMB",
		 time: "< 30 mins"
	},
	{
		 NAME: "Utah Carpenters",
		 ID: "88300",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Utah Laborers",
		 ID: "23223",
		 time: "< 30 mins"
	},
	{
		 NAME: "Utah Pipe Trades",
		 ID: "7166",
		 time: "< 30 mins"
	},
	{
		 NAME: "Utah Uninsured Employers Fund - CORVEL",
		 ID: "MSM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Utica (Auto only)",
		 ID: "56001",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Utica Mutual Insurance Co",
		 ID: "10395",
		 time: "< 30 mins"
	},
	{
		 NAME: "Utica National Insurance Co of Texas",
		 ID: "62111",
		 time: "< 30 mins"
	},
	{
		 NAME: "UTMB 3 Share Program",
		 ID: "33600",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VA Community Care Network Region 1",
		 ID: "SB580",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VA Community Care Network Region 2",
		 ID: "KEE01",
		 time: "< 30 mins"
	},
	{
		 NAME: "VA Community Care Network Region 3",
		 ID: "J1937",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VA Fee Basis Programs",
		 ID: "22149",
		 time: "< 30 mins"
	},
	{
		 NAME: "VA Financial Services Center",
		 ID: "55204",
		 time: "< 30 mins"
	},
	{
		 NAME: "VA Health Administration Center ",
		 ID: "MPM40",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vacorp",
		 ID: "CC304",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Valir Pace",
		 ID: "A0102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Valitas Health Services, Inc. dba Corizon Health - CORVEL",
		 ID: "73288",
		 time: "< 30 mins"
	},
	{
		 NAME: "Valley Care IPA",
		 ID: "U1439",
		 time: "< 30 mins"
	},
	{
		 NAME: "Valley Health Plan (Commercial)",
		 ID: "13337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Valley Health Plan (Medi-Cal)",
		 ID: "10850",
		 time: "< 30 mins"
	},
	{
		 NAME: "Valley Presbyterian Hospital Community Family Care VPRESCFC",
		 ID: "37266",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Valley Presbyterian Hospital Preferred IPA VPRESPREF",
		 ID: "23045",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vally Health Plan Encounters ",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Valor Health Plan",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "Value Options (Beacon Health Options)",
		 ID: "J1548",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Van Lang IPA",
		 ID: "JPF01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vanliners Insurance Company",
		 ID: "INP12",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vantage Health Plan",
		 ID: "34192",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vantage Health Plan, Inc.",
		 ID: "J1841",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vantage Medical Group",
		 ID: "SCNKF",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vantage Medical Group Encounters",
		 ID: "C1033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vantage Medical Group Encounters ",
		 ID: "95606",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vantage Plus",
		 ID: "HCMG1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Variable Protection Administrators (VPA)",
		 ID: "94999",
		 time: "< 30 mins"
	},
	{
		 NAME: "Varipro",
		 ID: "BKPNT",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vault AMPS Admin Services",
		 ID: "COACC",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vaya Health (formerly Smoky Mountain)",
		 ID: "59069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Velocity Vehicle Group - CORVEL",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Ventura County Health Care Plan (VCHCP)",
		 ID: "PEF01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VentureNet Healthcare",
		 ID: "77027",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Verdugo Hills Medical Group (Regal)",
		 ID: "MCDOH",
		 time: "< 30 mins"
	},
	{
		 NAME: "Verity National Group",
		 ID: "SCOAE",
		 time: "< 30 mins"
	},
	{
		 NAME: "VestaCare",
		 ID: "22149",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vestica Healthcare",
		 ID: "SC020",
		 time: "< 30 mins"
	},
	{
		 NAME: "VGM Homelink",
		 ID: "ASP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vibra Health",
		 ID: "99320",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vida Care",
		 ID: "BRGHT",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VieCare Life Beaver and Life Lawrence Counties",
		 ID: "77799",
		 time: "< 30 mins"
	},
	{
		 NAME: "VieCare LIFE Butler",
		 ID: "TCC93",
		 time: "< 30 mins"
	},
	{
		 NAME: "Viking Insurance Company of Wisconsin",
		 ID: "HVPNI",
		 time: "< 30 mins"
	},
	{
		 NAME: "Village Health Plan",
		 ID: "61101",
		 time: "> 30 Mins"
	},
	{
		 NAME: "VillageCareMAX",
		 ID: "35202",
		 time: "< 30 mins"
	},
	{
		 NAME: "VillageMD of Northern Indiana",
		 ID: "89641",
		 time: "< 30 mins"
	},
	{
		 NAME: "Virginia Health Network, Inc.",
		 ID: "J1430",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Mason Group Health",
		 ID: "06226",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier CompleteCare",
		 ID: "68069",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier Elite",
		 ID: "22175",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier Elite Plus",
		 ID: "77073",
		 time: "< 30 mins"
	},
	{
		 NAME: "Virginia Premier Gold",
		 ID: "25831",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier Health Plan",
		 ID: "37105",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier Health Plan (Medicaid)",
		 ID: "22100",
		 time: "< 30 mins"
	},
	{
		 NAME: "Virginia Premier Individual Health Plan",
		 ID: "C1033",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Virginia Premier Medallion 4.0",
		 ID: "01260",
		 time: "< 30 mins"
	},
	{
		 NAME: "Virginia Risk Sharing (VRSA)",
		 ID: "75284",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vista Health Plan",
		 ID: "56132",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vital Protection",
		 ID: "57116",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vitality Health Plan of CA FFS",
		 ID: "36401",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vitruvian Care Inc",
		 ID: "07202",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Viva Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vivida Health",
		 ID: "55892",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vivida Health Plan",
		 ID: "TTUMD",
		 time: "< 30 mins"
	},
	{
		 NAME: "VNA Homecare Options",
		 ID: "37121",
		 time: "< 30 mins"
	},
	{
		 NAME: "VNS Choice SelectCare",
		 ID: "16109",
		 time: "< 30 mins"
	},
	{
		 NAME: "Volunteer States Health Plan",
		 ID: "59573",
		 time: "< 30 mins"
	},
	{
		 NAME: "Volusia Health Network",
		 ID: "80900",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vons / Safeway",
		 ID: "38308",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Vulcan Materials - CORVEL",
		 ID: "HOSH1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Vytra Health Plans (Emblem)",
		 ID: "MPM53",
		 time: "< 30 mins"
	},
	{
		 NAME: "W.C. Beeler & Company",
		 ID: "LNJ01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WA Teamster",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wadena",
		 ID: "FCD01",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wagner Meinert",
		 ID: "MMFCS",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wal-Mart Corporate",
		 ID: "KGA15",
		 time: "< 30 mins"
	},
	{
		 NAME: "Washington County Health and Human Services",
		 ID: "J1500",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Washington Dental Service",
		 ID: "37135",
		 time: "< 30 mins"
	},
	{
		 NAME: "Washington Gas",
		 ID: "80141",
		 time: "< 30 mins"
	},
	{
		 NAME: "Washington State Health Insurance Pool  (WSHIP)",
		 ID: "KYCS1",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Waterstone Benefit Administrators",
		 ID: "75280",
		 time: "< 30 mins"
	},
	{
		 NAME: "Watts Health Care",
		 ID: "87726",
		 time: "< 30 mins"
	},
	{
		 NAME: "WC Solutions",
		 ID: "INP12",
		 time: "< 30 mins"
	},
	{
		 NAME: "WE Energies",
		 ID: "IHS10",
		 time: "< 30 mins"
	},
	{
		 NAME: "WEA Insurance Group",
		 ID: "47076",
		 time: "< 30 mins"
	},
	{
		 NAME: "WEA TRUST",
		 ID: "33632",
		 time: "< 30 mins"
	},
	{
		 NAME: "Web TPA / Community Health Electronic claims / CHEC",
		 ID: "66004",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wegmans",
		 ID: "J1249",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Weiss Health Providers",
		 ID: "75678",
		 time: "< 30 mins"
	},
	{
		 NAME: "WelbeHealth",
		 ID: "UCDMG",
		 time: "< 30 mins"
	},
	{
		 NAME: "Welcome Health",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Well Sense Health Plan",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellcare (Admin by LIBERTY Dental Plan)",
		 ID: "PROSP",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellcare by Health Net",
		 ID: "86027",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellCare Health Plan",
		 ID: "PHPMI",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellCare Health Plans",
		 ID: "86242",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellcare Health Plans - Encounters",
		 ID: "SMW01",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellCare HMO",
		 ID: "MHPIL",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellCare Private FFS",
		 ID: "MPM04",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellmark BCBS",
		 ID: "CAPMN",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellMed Medical (Claims)",
		 ID: "39081",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WellMed Medical (Encounters)",
		 ID: "38224",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WellNet Health Plans",
		 ID: "00091",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellPay",
		 ID: "76255",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellpoint Dental Services",
		 ID: "SCNCA",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wellpoint Maryland",
		 ID: "58182",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WellSpace Nexus LLC",
		 ID: "BHP01",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellSpan EAP",
		 ID: "77039",
		 time: "< 30 mins"
	},
	{
		 NAME: "WellSpan Employee Assistance Program",
		 ID: "40026",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WellSystems, LLC",
		 ID: "35245",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wenatchee Valley Medical Center",
		 ID: "83028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "West Bend Mutual Insurance",
		 ID: "23282",
		 time: "> 30 Mins"
	},
	{
		 NAME: "West Covina Medical Group (Regal)",
		 ID: "J1899",
		 time: "> 30 Mins"
	},
	{
		 NAME: "West Regional Physicians Network Inc",
		 ID: "J1550",
		 time: "> 30 Mins"
	},
	{
		 NAME: "West Suburban Health Providers",
		 ID: "62171",
		 time: "> 30 Mins"
	},
	{
		 NAME: "West Virginia Senior Choice",
		 ID: "26879",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Agricultural Insurance Company",
		 ID: "61101",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Growers Assurance Trust",
		 ID: "CARMO",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Western Growers Insurance Company",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Health Advantage",
		 ID: "25667",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Health Advantage (PHTech)",
		 ID: "43905",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Western Mutual Insurance (WMI)",
		 ID: "10378",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western National Assurance",
		 ID: "81085",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Western National Insurance \\ Pioneer Specialties",
		 ID: "15460",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Western Oregon Advanced Health",
		 ID: "04218",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Sky Community Care",
		 ID: "58203",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Western Southern Financial Group",
		 ID: "OBA16",
		 time: "< 30 mins"
	},
	{
		 NAME: "Western Utilities or Local 57",
		 ID: "NASCR",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Westfield Insurance",
		 ID: "EYEN1",
		 time: "< 30 mins"
	},
	{
		 NAME: "WestGUARD Insurance Company - Guard DBA",
		 ID: "00590",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Westlake Financial",
		 ID: "44273",
		 time: "> 30 Mins"
	},
	{
		 NAME: "White Memorial Altamed Medical Group",
		 ID: "STJOE",
		 time: "< 30 mins"
	},
	{
		 NAME: "White Memorial Medical Center",
		 ID: "NMM01",
		 time: "< 30 mins"
	},
	{
		 NAME: "WI State Employees - Managed Health Services",
		 ID: "J1835",
		 time: "> 30 Mins"
	},
	{
		 NAME: "WI State Employees - Physicians Plus Insurance Company",
		 ID: "91136",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Willamette Valley Community Health CCOA - Capital Dental Care",
		 ID: "C1028",
		 time: "> 30 Mins"
	},
	{
		 NAME: "William C. Earhart Company",
		 ID: "25924",
		 time: "< 30 mins"
	},
	{
		 NAME: "William J Sutton & Co. Ltd (Toronto)",
		 ID: "10111",
		 time: "< 30 mins"
	},
	{
		 NAME: "WILLIAMSON COUNTY GOVERNMENT - CORVEL",
		 ID: "39640",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Willow Health",
		 ID: "62179",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wilson Mutual Insurance (All States EXCEPT MN/WI)",
		 ID: "J1491",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wilson-McShane",
		 ID: "13337",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wilson-McShane: International Brotherhood of Boilermakers",
		 ID: "FC001",
		 time: "< 30 mins"
	},
	{
		 NAME: "Windsor Sterling Health Plan ",
		 ID: "LMG01",
		 time: "< 30 mins"
	},
	{
		 NAME: "WINhealth Partners",
		 ID: "06102",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Winston Hospitality",
		 ID: "AARP1",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wisconsin Assigned Risk Plan (HIRSP)",
		 ID: "J1465",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wisconsin Department of Corrections",
		 ID: "CMSEB",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Wisconsin Physicians Service (WPS) Commercial",
		 ID: "76251",
		 time: "< 30 mins"
	},
	{
		 NAME: "WJ Cox Associates, Inc.",
		 ID: "GI813",
		 time: "< 30 mins"
	},
	{
		 NAME: "Women's Integrated Network (WIN Health)",
		 ID: "60054",
		 time: "< 30 mins"
	},
	{
		 NAME: "Workers Compensation And Employer Liability",
		 ID: "62308",
		 time: "> 1 hours"
	},
	{
		 NAME: "Worksite Benefit Services, LLC",
		 ID: "47738",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Worthington Industries - CORVEL",
		 ID: "CPP08",
		 time: "< 30 mins"
	},
	{
		 NAME: "WPAS (FCHN)",
		 ID: "PFIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "WPPN- HPO (HealthEOS)",
		 ID: "HPIPA",
		 time: "< 30 mins"
	},
	{
		 NAME: "WPS Arise (Prevea)",
		 ID: "BTHS1",
		 time: "< 30 mins"
	},
	{
		 NAME: "WPS Health Insurance",
		 ID: "N10917",
		 time: "< 30 mins"
	},
	{
		 NAME: "Writer's Guild Industry Health Plan",
		 ID: "IP079",
		 time: "< 30 mins"
	},
	{
		 NAME: "WSHIP - Washington State Health Insurance Pool",
		 ID: "10093",
		 time: "< 30 mins"
	},
	{
		 NAME: "Wyoming Health Solutions",
		 ID: "25133",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Xantus Health Plan",
		 ID: "AMM07",
		 time: "< 30 mins"
	},
	{
		 NAME: "Xcel Energy",
		 ID: "89240",
		 time: "< 30 mins"
	},
	{
		 NAME: "Ximed Medical Group",
		 ID: "ARISE",
		 time: "< 30 mins"
	},
	{
		 NAME: "Xylem Inc. - CORVEL",
		 ID: "45488",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Yamhill CCO",
		 ID: "CX014",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Yamhill COO Physical Health",
		 ID: "MC053",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Yerington Paiute Tribe",
		 ID: "ATRIO",
		 time: "< 30 mins"
	},
	{
		 NAME: "York Claim Services",
		 ID: "25667",
		 time: "< 30 mins"
	},
	{
		 NAME: "YourCare Health Plan",
		 ID: "73147",
		 time: "< 30 mins"
	},
	{
		 NAME: "Zachry Construction",
		 ID: "CCHP9",
		 time: "< 30 mins"
	},
	{
		 NAME: "Zenith (FCHN)",
		 ID: "MR025",
		 time: "< 30 mins"
	},
	{
		 NAME: "Zenith American Solutions - ILWU-PMA",
		 ID: "73147",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Zenith Insurance Co",
		 ID: "J1659",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Zing Health",
		 ID: "96240",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Z-Nat Insurance (Zenith National)",
		 ID: "S9637",
		 time: "< 30 mins"
	},
	{
		 NAME: "Zoe Holding Company Inc. - CORVEL",
		 ID: "21062",
		 time: "> 30 Mins"
	},
	{
		 NAME: "Zurich Farmers",
		 ID: "89677",
		 time: "< 30 mins"
	},
	{
		 NAME: "Zurich Insurance",
		 ID: "NCHOR",
		 time: "< 30 mins"
	}
]
export default array