import React from 'react'
import UserAuditTable from '../../Audit/userTaskViewTable';

export default function DosAudit({Data,handleBack}) {
   console.log("There is dATA ",Data)


    const tableColumn = [
        {
          colName: "Date of Service",
          flexGrow: 4.5,
          dataKey: "Dos",
          rowSpan: 6,
          popEachCell: true,
        },
        {
          colName: "Received Claims",
          flexGrow: 2.2,
          dataKey: "receivedClaims",
          rowSpan: 1,
          popEachCell: true,
        },
        {
          colName: "Worked Claims",
          flexGrow: 2.2,
          dataKey: "workedClaims",
          rowSpan: 1,
          popEachCell: true,
            
        },
        {
          colName: "Pending Claims",
          flexGrow: 1.4,
          dataKey: "pendingClaims",
          popEachCell: true,
           
        },
        {
          colName: "Provider",
          flexGrow: 1.5,
          dataKey: "provider",
          rowSpan: 1,
          popEachCell: true,
            
         
        },
        {
          colName: "Operation",
          flexGrow: 0.7,
          dataKey: "operation",
          rowSpan: 1,
          popEachCell: true,
        
       
        },
        {
            colName: "Total",
            flexGrow: 0.7,
            dataKey: "total",
            rowSpan: 1,
            popEachCell: true,
          
         
          },
        
       
    
        
        
        
      
        
        
        // { colName: "Remarks", flexGrow: 1.7,dataKey:'',rowSpan:1,popupCell:true},
      ];

      const getRequriedPara=()=>{

      }
  return (
    <div>
       <UserAuditTable
                    
                    
                    isPaginationShow={true}
                    rowShown={5}
                    //tabelSizee={tabelSizee}
                  //  sizee={sizee}
                  //  previewButton
                    getRequriedPara={getRequriedPara}
                   // isShowExportButton={true}
                    dateForgrid={Data}
                    Array={
                      tableColumn
                    }
                  //  onChangePop={onChangePop}
                   // loader={loader}
                  //  reportName={"PracticeWise"}
                  />
                 

                 <button
  type="button"
  className="btnClear mr-1"
  style={{ marginTop: '10px' }} // Adjust the value as needed
  onClick={handleBack}
>
  Back
</button>

    </div>
  )
}
