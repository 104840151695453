import React, { useEffect, useState } from "react";

import { Modal, ButtonToolbar, Button, Placeholder } from "rsuite";

import * as XLSX from "xlsx"; // Import the xlsx library
import { toast } from "react-toastify";
import { getBucketImages } from "../Utils/ApiManager";

const UR = process.env.REACT_APP_URL + "/documents";

const ViewDocument = ({ modalRow, seletedRow, openImageViwerModel }) => {
  const [fileData, setFileData] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Set to true initially
  const [fileType, setFileType] = useState("");
  const [hasRendered, setHasRendered] = useState(false); // Flag to control rendering

  const display = (mimeType) => {
    
    getBucketImages(seletedRow)
      .then((res) => {


        debugger

        const blob = new Blob([res.data], { type: mimeType });

        if (fileType === "xlsx") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const sheetData = XLSX.utils.sheet_to_json(
              workbook.Sheets[firstSheetName],
              { header: 1 }
            );
            setFileData(sheetData);
            setIsLoading(false); // Set to false after content is loaded
          };

          reader.readAsArrayBuffer(blob);
        }
       
        if(mimeType=='application/pdf'||mimeType=='image/png'){
          setIsLoading(false);
          const fileExtension = seletedRow.bucketImages.key.split(".");
          setFileType(fileExtension[fileExtension.length - 1]);
          const url = URL.createObjectURL(blob);


          setFileUrl(url);

        }
        if(mimeType=='xlsx'){

        }

        
      })
      .catch((err) => {
        console.error(err);
        toast.info("Error");
        setIsLoading(false); // Set to false on error
      });
  };

  useEffect(() => {
 
    if (seletedRow?.bucketImages?.key) {
      let mimeType = "";
      const fileExtension = seletedRow.bucketImages.key.split(".");

      if (seletedRow ) {
        let a;

        // Determine the file's MIME type based on its extension
        if (fileExtension[fileExtension.length - 1] === "pdf") {
          mimeType = "application/pdf";
          a = "pdf";
          setFileType("pdf");
          setHasRendered(true); // Set the flag to prevent further rendering
        } else if (fileExtension[fileExtension.length - 1] === "png") {
          mimeType = "image/png";
          a = "png";
          setFileType("png");
        } else if (
          fileExtension[fileExtension.length - 1] === "docx" ||
          fileExtension === "doc"
        ) {
          mimeType = "application/msword";
          a = "docx";
          setFileType("docx");
        } else if (
          fileExtension[fileExtension.length - 1] === "xlsx" ||
          fileExtension === "xls"
        ) {
          mimeType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          a = "xlsx";
          setFileType("xlsx");
        } else {
          // Unsupported file format
          toast.error("Unsupported file format.");
          setIsLoading(false); // Set to false for unsupported formats
          return;
        }

        display(mimeType, a);
      }
    } else {
      const fileExtension = seletedRow.bucketImages.name.split(".");
      setFileType(fileExtension[fileExtension.length - 1]);
      const url = URL.createObjectURL(seletedRow?.bucketImages?.blobFile);
      if (
        fileExtension[fileExtension.length - 1] === "xlsx" ||
        fileExtension[fileExtension.length - 1] === "doc"
      ) {
        setIsLoading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName],
            { header: 1 }
          );
          setFileData(sheetData);
          setIsLoading(false);
        };
        let blob = seletedRow?.bucketImages?.blobFile;

        reader.readAsArrayBuffer(blob);
      }
      setFileUrl(url);
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <Modal size={"lg"} open={true} onClose={() => openImageViwerModel(false)}>
        <Modal.Header>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-lightGray mt-auto p-3 py-5">
            {isLoading && (
              <div className="loaderbgpopup">
                <div className="loaderdivpopup"></div>
              </div>
            )}

            {["pdf", "docx", "xlsx"].includes(fileType) ? (
              fileType === "xlsx" ? (
                <table className="min-w-full h-16 divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {fileData &&
                      fileData?.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={rowIndex % 2 === 0 ? "bg-gray-50" : ""}
                        >
                          {row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (

                <iframe
                src={fileUrl}
                  title="Embedded Document"
                  width="100%"
                  height="700"
                  
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              )
            ) : fileType === "png" ? (
              <img
              
                src={fileUrl}
                alt="EmbeddedImage"
                style={{ width: "100%", height: "55vh" }}
                resizemode="contain"
              />
            ) : (
              <div>Unsupported file format</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => openImageViwerModel(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            onClick={() => openImageViwerModel(false)}
            style={{ backgroundColor: "#0e7490", color: "white" }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewDocument;