import { Nav } from 'rsuite';
import React from 'react';
import HomeIcon from '@rsuite/icons/legacy/Home';
import DetailIcon from '@rsuite/icons/Detail'
import { useState } from 'react';
import ChangeListIcon from '@rsuite/icons/ChangeList';
import CouponIcon from '@rsuite/icons/Coupon';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';


const navItems = [
  { eventKey: 'TLInterface', label: 'TL WorkSheet', icon: <ReviewPassIcon /> },
  { eventKey: 'AmountVerification', label: 'Charge Entry', icon: <DetailIcon /> },
  { eventKey: 'PaymentsVerification', label: 'Payments', icon: <CouponIcon /> },
  { eventKey: 'DeniedVerification', label: 'Patient Statement/PHD', icon: <ChangeListIcon /> },
  { eventKey: 'AccountRecVerify', label: 'Account Receivable', icon: <ReviewPassIcon /> },
  
 
];



const Navbar = ({ payerType, active, onSelect, handleFormType, ...props }) => {
  const isActive = (eventKey) => active === eventKey;
  const activeStyle = { backgroundColor: "#0e7490", color: "white" };
  console.log('Active,',active)

  return (
    <>
      <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 5 }}>
        {navItems.map(({ eventKey, label, icon }) => (
          <Nav.Item
            key={eventKey}
            eventKey={eventKey}
            icon={icon}
            style={isActive(eventKey) ? activeStyle : {color:"#0e7490"}}
            onSelect={handleFormType}
          >
            <span style={{fontWeight:'normal'}}>{label}</span>
          </Nav.Item>
        ))}
       
        
        <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                 
                }}
              >
                
               
              </h4>
            </div>
      </Nav>
      
    </>
  );
};

const TabMenus = ({setPracticeId,setRecord, payerType, handleSubCheckBoxChargeEntry, handleFormTypeAndPract }) => {
  const [active, setActive] = useState('TLInterface');
  const [checkedType, setCheckedType] = useState('TLInterface');
  console.log(active,'moizbug')

  const handleFormType = (event) => {
  


    setRecord(null)
    setPracticeId(null)
    let value = event;
    if (
      value == "Reports" ||
      value == "AmountVerification" ||
      value == "MeetingTl" ||
      value == "PaymentsVerification" ||
      value == "ReportsPayments" ||
      value == "MeetingPayment" ||
      value == 'AccountPayments' ||
      value == 'DeniedVerification' ||
      value == 'MeetingDenied' ||
      value == 'ReportsDenied' ||
      value == 'AccountRecVerify' ||
      value == 'MeetingDeniedAC' ||
      value == 'ReportsDeniedAC'

    ) {

      handleSubCheckBoxChargeEntry(value);
      setCheckedType(value);
    }
    setCheckedType(value);
    let obj = { name: "formType", val: value };

    handleFormTypeAndPract(obj);
  };
  return (
    <>
      <Navbar
        payerType={payerType}
        appearance="tabs"
        active={active}
        onSelect={setActive}
        handleFormType={handleFormType}
      />
    
    </>
  );
};

export default TabMenus;
