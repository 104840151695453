import React from "react";
import ReactDropdown from "react-dropdown";
import PaymentVerification from "./PaymentVerification";
import Meeting from "../ChargeEntry/Meeting";
import Reports from "../ChargeEntry/Reports";

const Payments = ({ subCheckBox,formTypeNoForApi,date,formType,practiceId,record }) => {
  console.log('bug',subCheckBox)
  
  
  return (
    <>
     {subCheckBox == "PaymentsVerification" || subCheckBox=='AccountPayments' ? (
      <PaymentVerification record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) : subCheckBox == "MeetingPayment" ? (
      <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    ) : subCheckBox == "ReportsPayments" ? (
      <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) : null}
    </>
   
  );
};
export default Payments;
