let arr=[
    {
        "label": "OMNI Administrators",
        "value": "91617",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scottsdale Insurance Co E&S",
        "value": "MLNMI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allstate - NJ Only",
        "value": "J1550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amica Insurance Company",
        "value": "45488",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chubb (legacy Chubb & Son) - Auto",
        "value": "J1082",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chubb (legacy Chubb & Son) - WC",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cure Auto Insurance For NJ Auto",
        "value": "94302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kentucky Health Administrators, Inc.",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Longevity NY",
        "value": "C1049",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MetLife New Jersey",
        "value": "MHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mutual of Omaha Medicare Advantage",
        "value": "18763",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OSF Healthcare Central",
        "value": "42049",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OSF Healthcare East",
        "value": "J3964",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Provider Partners Health Plan of Illinois",
        "value": "IDWH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Auto Mutual Insurance Company",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "University of Maryland Health Advantage",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VALLEY PRESBYTERIAN HOSPITAL – HCLA",
        "value": "00932",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VieCare LIFE Butler",
        "value": "HTHTX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "1199 National Benefit Fund",
        "value": "48330",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "1st Auto & Casualty",
        "value": "21415",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "21st Century Health and Benefits",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "21st Century Insurance",
        "value": "J1479",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "3P Admin",
        "value": "66705",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "6 Degrees Health, Inc.",
        "value": "37269",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "8th District Elec",
        "value": "MCDND",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "90 Degree Benefits",
        "value": "16146",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "A & I Benefit Plan Administrators",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "A.W. Holdings LLC Dba Benchmark - CORVEL",
        "value": "16928",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AAA Automobile",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AAA Northern California, Nevada & Utah Insurance Exchange",
        "value": "77799",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AAG - American Administrative Group",
        "value": "53120",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AAG Benefit Plan Administrators, Inc.",
        "value": "JMH01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AARP",
        "value": "12202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AARP by UnitedHealthcare",
        "value": "J3941",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AARP Dental Insurance Plan",
        "value": "77160",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AARP Hospital Indemnity Plans insured by UnitedHealthcare Insurance Company",
        "value": "82357",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AARP Medicare Supplement Plans insured by UnitedHealthcare Insurance Company",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AARP MedicareComplete insured through UnitedHealthcare (formerly AARP MedicareComplete from SecureHorizons)",
        "value": "77076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AARP MedicareComplete insured through UnitedHealthcare / Oxford Medicare Network -  AARP MedicareComplete",
        "value": "35245",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AARP MedicareComplete insured through UnitedHealthcare / Oxford Mosaic Network - AARP MedicareComplete  Mosaic",
        "value": "51020",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ABC Const. Company",
        "value": "81040",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AblePay Health",
        "value": "04332",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ABRAZO Advantage Health Plan",
        "value": "LNC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Absolute Total Care - Medical",
        "value": "67524",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Acadia Insurance",
        "value": "62181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Access Administrators",
        "value": "74284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Access Behavioral Care",
        "value": "31140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Access Community Health Network",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Access Integra",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Access IPA",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Access Medical Group",
        "value": "SMPLY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Access Primary Care Medical Group (APCMG)",
        "value": "37224",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Access Santa Monica",
        "value": "CCHPL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Acclaim",
        "value": "RAIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Acclaim IPA",
        "value": "MCDKS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Acclaim IPA (MHCAC)",
        "value": "STVMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Accountable Healthcare IPA (AHCIPA)",
        "value": "HCS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Accuride Corporation",
        "value": "41206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ace Property & Casualty Ins Co",
        "value": "CB186",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ACIG Insurance Company",
        "value": "SWHNY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ACS Benefit Services, Inc.",
        "value": "UNINW",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ACTIN Care Groups / Clifton Health Systems",
        "value": "SB580",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Active Care (UCS)",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Active Care, Inc",
        "value": "29076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ActivHealthCare",
        "value": "J1638",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Activia Benefit Services",
        "value": "MCDMT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Acuity, A Mutual Insurance Company",
        "value": "23243",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AD-COMP - CORVEL",
        "value": "CAPHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Adelanto Correctional Facility (CA) - CORVEL",
        "value": "VS402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Administaff",
        "value": "12967",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Administration Systems Research Corp",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Administration Systems Research Health Benefits",
        "value": "16003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Administrative Claim Service, Inc.",
        "value": "AMM13",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Administrative Concepts Inc.",
        "value": "59065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Administrative Services Inc",
        "value": "MCDMO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AdminOne",
        "value": "33033",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advance America Cash Advance Centers - CORVEL",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advance Services, Inc.",
        "value": "37330",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advanced Medical Doctors of CA",
        "value": "GSMHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advanced Physicians IPA",
        "value": "31400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advanstaff, Inc.",
        "value": "HBI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advantage Care IPA",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advantage Health Solutions (Careington)",
        "value": "GSH01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advantage Medical Group",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advantage Preferred Plus",
        "value": "46187",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advantek Benefit Administrators (SANTE)",
        "value": "J1703",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advantica",
        "value": "13189",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advantica Benefits",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advent Health Medical Corporation",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ADVENTIST HEALTH CARE NETWORK, INC",
        "value": "05302",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Adventist Health Hanford AHP",
        "value": "SVIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Adventist Health Plan",
        "value": "86083",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Adventist Health System West",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Adventist White Memorial - Crown City Medical Group",
        "value": "21825",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Adventist White Memorial - Southland San Gabriel Valley",
        "value": "HLPAE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advisory Health Administrators",
        "value": "59140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Advocare MCO, Inc",
        "value": "SC003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advocate Health Centers",
        "value": "62599",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Advocate Health Partners",
        "value": "42138",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aegis Administrative Services",
        "value": "IDIND",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Advantage",
        "value": "CC16E",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Affordable Health Choices - SRC/Boone",
        "value": "94134",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health - Parkland (TX)",
        "value": "UGP19",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health Illinois MMAI",
        "value": "75280",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of California",
        "value": "CX100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Florida",
        "value": "ALLCD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Illinois",
        "value": "95378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Illinois - Foster Care",
        "value": "89234",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Illinois (Medicaid)",
        "value": "31500",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Kansas",
        "value": "128KS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Kentucky",
        "value": "IHS02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Louisiana",
        "value": "ONEHC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Maryland",
        "value": "38308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Michigan",
        "value": "34108",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of New Jersey",
        "value": "MPM36",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of New York",
        "value": "00403",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Ohio",
        "value": "59331",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Pennsylvania (Medicaid)",
        "value": "MPM47",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Texas",
        "value": "SIM02",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of Virginia",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Better Health of Virginia - CCC Plus",
        "value": "97041",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Better Health of West Virginia",
        "value": "54154",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Choice Plans",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna DMO",
        "value": "HPN11",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Encounters",
        "value": "98022",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Health, Inc.",
        "value": "77036",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Healthcare",
        "value": "59065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna HealthFund",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Life & Casualty Company",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Life Insurance Company",
        "value": "10322",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Medicaid Plans",
        "value": "00923",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Medicare",
        "value": "83148",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Senior Plans",
        "value": "26224",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Senior Supplemental",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna Signature Administrators (ASA)",
        "value": "SHC30",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aetna Student Health",
        "value": "PCCMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna TX Medicaid and CHIP",
        "value": "34009",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aetna US Healthcare - AUSHC",
        "value": "60230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Affiliated Doctor's of Orange County (ADOC) (Regal)",
        "value": "68011",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Affiliated Partners IPA",
        "value": "75240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Affiliated Partners IPA SCAN/CleverCare HP Only",
        "value": "A0033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Affiliated Physicians IPA",
        "value": "KYCS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Affinity Health Plan",
        "value": "14163",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Affinity Health Plan Essential",
        "value": "MPM22",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Affinity Medical Group",
        "value": "3833A",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Affordable Benefit Administrators, Inc.",
        "value": "35198",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AFTRA Health & Retirement Funds",
        "value": "45282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aftra Health Fund",
        "value": "36347",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "A-G Administrators LLC",
        "value": "15460",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Agency Services Inc.",
        "value": "MBA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AgeRight Advantage",
        "value": "89237",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AgeWell New York",
        "value": "TLC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AHF RW",
        "value": "00801",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AHP Provider Network",
        "value": "J1750",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AHRMA (IL only) - CORVEL",
        "value": "1925460",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AIDS Healthcare Foundation",
        "value": "4YC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AIG",
        "value": "25916",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aither Health",
        "value": "KOVA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AKM Medical Group",
        "value": "35092",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alameda Alliance for Health",
        "value": "34196",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alamitos IPA ",
        "value": "IHS02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alarm Contractors",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alaska Children''s Services, Inc. Group #P68",
        "value": "51020",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alaska Electrical Health & Welfare Fund",
        "value": "23550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alaska Laborers Construction Industry Trust Group # F23",
        "value": "81883",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alaska National Insurance",
        "value": "27277",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alaska Pipe Trades Local 375 Group # F24",
        "value": "77104",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alaska UFCW Health & Welfare Trust Group # F45",
        "value": "06603",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Albuquerque Public Schools",
        "value": "88067",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alcoa Fasteners Systems",
        "value": "93222",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ALICARE",
        "value": "MR084",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Align Senior Care (FL)",
        "value": "22603",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Align Senior Care (MI)",
        "value": "36582",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Align Senior Care (VA)",
        "value": "SB690",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aligned Community Physicians",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alignis",
        "value": "78521",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alignment Health Care (Fee For Service)",
        "value": "38265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alignment Health Plan (Fee For Service)",
        "value": "HVPNI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alivi Health",
        "value": "CDCR1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alivi Therapy Network",
        "value": "96436",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ALL American Medical Group",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "All Care Medical Group",
        "value": "65085",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "All United Medical Group",
        "value": "22251",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "All United Medical Group Clever Care HP Only",
        "value": "67011",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AllCare CCO Dental",
        "value": "04298",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AllCare IPA",
        "value": "J1551",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AllCare PACE",
        "value": "SCAN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AllCare PEBB",
        "value": "60801",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allegeant",
        "value": "50114",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allegiance Benefit Plan Management Inc.",
        "value": "52103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allegiance Health Management",
        "value": "66001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allen Canning",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alliance Behavioral Health",
        "value": "J1890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alliance Coal Health Plan",
        "value": "00324",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alliance Health Systems",
        "value": "SOIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alliance Healthcare - Stones River Regional IPA",
        "value": "10259",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alliance Physicians High Desert",
        "value": "J1437",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alliance Physicians Medical Group",
        "value": "HPSM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alliant Energy",
        "value": "37284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alliant Health Plans of Georgia",
        "value": "64071",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allianz ",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allianz Resolution Management (ARM) ",
        "value": "82288",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allied Benefit Systems, Inc.",
        "value": "13202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allied Claims Administration, Inc.",
        "value": "IEHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allied Healthcare",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allied Pacific of California",
        "value": "43259",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allied Physicians Medical Group",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allied Property and Casualty Ins Co",
        "value": "47171",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allina Health Aetna",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Allmerica Financial Benefits Insurance Company",
        "value": "FRBLU",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Allstate Insurance",
        "value": "MCDMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AL-MED HCLA",
        "value": "53226",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aloha Care",
        "value": "J3791",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alpha Care Medical Group NMM",
        "value": "RPPG1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alta Bates Medical Group",
        "value": "37330",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ALTA Health & Life Insurance",
        "value": "81400",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AltaMed",
        "value": "09302",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alternative Opportunities",
        "value": "NEIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Alterwood Advantage",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Altius Health Plan",
        "value": "86057",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Altus Dental",
        "value": "66011",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Alvey Systems (AON Risk)",
        "value": "128MI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amada Health",
        "value": "66775",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amada Health South",
        "value": "00210",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amalgamated Insurance Company",
        "value": "11402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amalgamated Life",
        "value": "MP340",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amalgamated Life IPA Alicare",
        "value": "20133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ambetter of Arkansas - Medical",
        "value": "CB637",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ambetter of Missouri - Medical",
        "value": "S9637",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ambetter of New Hampshire - Medical",
        "value": "84555",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AMCO Ins Co",
        "value": "CCHPC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ameriben Solutions, Inc.",
        "value": "62308      ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "America First ",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Americaid Community Care",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Administrative Group",
        "value": "RGA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Airlines",
        "value": "J1365",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Behavioral",
        "value": "HDNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Claims Management (ACM)",
        "value": "12399",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Coin Merchandising, Inc.",
        "value": "J1546",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Community Mutual",
        "value": "82288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Country Insurance Company / American Service Insurance Company, Inc.",
        "value": "J1232",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Cyanamid ",
        "value": "25172",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Family Health Providers",
        "value": "13337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Family Insurance",
        "value": "88848",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Family Mutual Insurance Co",
        "value": "ASHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Family PPO Policies",
        "value": "RIOS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American General Life & Accident",
        "value": "15991",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"American Health Advantage of FL",
        "value": "\"",
        "AveCallholdtime": "13334"
    },
    {
        "label": "American Health Advantage of MS",
        "value": "STAR1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Health Advantage of TN",
        "value": "50443",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"American Health Advantage of TX",
        "value": "\"",
        "AveCallholdtime": "00329"
    },
    {
        "label": "\"American Health Advantage of UT",
        "value": "\"",
        "AveCallholdtime": "72126"
    },
    {
        "label": "American Healthcare Alliance",
        "value": "EIPA8",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Home Craft",
        "value": "81949",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Insurance Company of Texas",
        "value": "25169",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Liberty Insurance Company",
        "value": "77950",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Life Care",
        "value": "39033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Medical Security",
        "value": "47165",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AMERICAN MEDICAL SECURITY (AMS)                                         ",
        "value": "00801",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American National",
        "value": "AUMG2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American National Insurance Co.",
        "value": "PTX01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Plan Administrators",
        "value": "37253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Postal Workers Union (APWU)",
        "value": "EOCCO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Primary Care Alliance IPA ",
        "value": "07689",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Public Life (APL)",
        "value": "46045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Republic Insurance",
        "value": "LDP12",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Republic Insurance Company (ARIC)",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Specialty Companies ",
        "value": "VB001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Specialty Health Plan",
        "value": "PASSE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Specialty Ins. / MIB",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "American Therapy Association (ATA) - Florida",
        "value": "90001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Trust Administrators, Inc.",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American West Health Care Solution",
        "value": "CCHPL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "American Worker Health Plan",
        "value": "84324",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "America's Choice Healthplan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "America's Health Choice",
        "value": "J3937",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Americas Health Plan",
        "value": "PH001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "America's PPO/America's TPA",
        "value": "SX107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriChoice - New York Medicaid",
        "value": "65894",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriChoice - Pennsylvania Medicaid",
        "value": "J3876",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriChoice of New Jersey",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Americo IPA",
        "value": "66012",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerigroup Community Care",
        "value": "41147",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerigroup Corporation",
        "value": "MCDAL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerigroup of Nevada",
        "value": "15004",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerigroup of Nevada ",
        "value": "J3738",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerigroup of Nevada (Unicare)",
        "value": "EXC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Administrator",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Administrators",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Caritas / VIP Care",
        "value": "128VA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Caritas / VIP Care Plus - Michigan",
        "value": "81434",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Caritas Delaware",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Caritas District of Columbia",
        "value": "00601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Caritas Healthplan New Hampshire",
        "value": "QCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Caritas Louisiana",
        "value": "42137",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Caritas Next",
        "value": "25831",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Caritas North Carolina",
        "value": "13010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Caritas NorthEast",
        "value": "72099",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerihealth Caritas Northeast ",
        "value": "36575",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerihealth Caritas Ohio Medicaid",
        "value": "MCDID",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Caritas Ohio Medicaid (Radiology Only)",
        "value": "128WV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerihealth Caritas Pennsylvania",
        "value": "BLUEC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerihealth Caritas Pennsylvania ",
        "value": "DAKCH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amerihealth Casualty Services",
        "value": "19432",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Delaware - HMO/POS",
        "value": "J3959",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Delaware (Non-HMO)",
        "value": "J1025",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth Mercy Health Plan",
        "value": "C1053",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AmeriHealth New Jersey - HMO/POS",
        "value": "TREST",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth New Jersey (Non-HMO)",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmeriHealth Pennsylvania - HMO/POS/ERISA",
        "value": "95433",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ameriprise",
        "value": "87098",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ameriprise Auto & Home Insurance",
        "value": "SCACO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerisafe",
        "value": "12287",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerisafe Risk Services ",
        "value": "00923",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amerisure Insurance",
        "value": "28680",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ameritas Life Insurance Corp.",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmFed Companies LLC (Amfed National Insurance)",
        "value": "31172",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amfirst Insurance Company",
        "value": "71065",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AMG IPA - Ava Medical Group",
        "value": "DX101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AmGuard Insurance Company - Guard DBA",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amica Mutual Insurance Company",
        "value": "84105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Amida Care",
        "value": "06294",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AMPS",
        "value": "AWNY6",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AMPS - CX",
        "value": "75233",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AMPS America",
        "value": "66775",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Amtrust Insurance Company of Kansas",
        "value": "52193",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AMVI - Prospect Health Network",
        "value": "SCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AMVI Medical Group",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anaheim Memorial IPA (MHCAN)",
        "value": "HPSM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ANCHOR BENEFIT CONSULTING ",
        "value": "FH205",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anchor Benefit Consulting Inc.",
        "value": "SX107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anchor Glass Container Corporation",
        "value": "J2277",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ancillary Benefit Systems - Arizona Foundation for Medical Care",
        "value": "PCU01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Angel Medical Group",
        "value": "76048",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Angeles IPA",
        "value": "77052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Angle Insurance Company of Utah",
        "value": "MHPMI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anheuser Busch",
        "value": "MPM65",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Antares Management Solutions",
        "value": "16565",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Antelope Valley Medical Associates (Regal)",
        "value": "J1701",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Blue Cross CA Encounters",
        "value": "10145",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Blue Cross of California (BCCA)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Dental (P.O. Box 1115 MN)",
        "value": "MCDIL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anthem HealthKeepers Plus",
        "value": "J1633",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Healthy Indiana Plan (HIP)",
        "value": "34525",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Hoosier HealthWise",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Medicaid - Indiana",
        "value": "MCDLA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Medicaid - South Carolina",
        "value": "J1462",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem Medicaid - Wisconsin",
        "value": "UHC-OHMCD-DNTL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Anthem Medicaid of Ohio",
        "value": "31059",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem NV Medicaid",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Anthem OMHS",
        "value": "J1568",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "APAC (Pinnacle Health Resources)",
        "value": "VVMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Apex Benefit Services",
        "value": "MPM09",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Apostrophe",
        "value": "PEF01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AppleCare Medical Management",
        "value": "00950",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "APWU Health Plan",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ARAZ",
        "value": "251CC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arbor Health Plan",
        "value": "J3762",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ARC Administrators",
        "value": "MCDLA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arcadia Healthcare Solutions - IPG",
        "value": "59065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arcadia Healthcare Solutions - NPA",
        "value": "27008",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arcadia Methodist IPA",
        "value": "54771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arcadian Management Services",
        "value": "76255",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arch Health Partners/Centre for Healthcare",
        "value": "81400",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ArchCare Advantage",
        "value": "NCHUR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ARCMI Claims Management",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Areas USA, Inc.",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Argonaut Insurance Company (ARGO)",
        "value": "00806",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Argus Dental Plans",
        "value": "MPM36",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Argus Vision",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arise Health Plan (WPS)",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arizona Complete Health (formerly Health Net Access)",
        "value": "ACCOM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arizona Foundation for Medical Care",
        "value": "54084",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arizona Priority Care Plus",
        "value": "37314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arkansas Superior Select",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ARM, Ltd",
        "value": "59299",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arnett Health Plan",
        "value": "WITH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arnot Ogden Medical Center (UCS)",
        "value": "75261",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arrowhead Regional Medical Center",
        "value": "U1439",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Arrowpoint",
        "value": "ASVA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Arroyo Vista Family Health Center",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ascension Complete",
        "value": "BTSS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Asian American Medical Group",
        "value": "HLPBC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Asian Community Medical Group, Inc.",
        "value": "MHHNP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ASONET",
        "value": "USN01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aspen Medical Associates",
        "value": "34158",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Aspire Health Plan",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ASRM LLC",
        "value": "C1087",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Associated Dignity Medical Group Professional Corp",
        "value": "32002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Associated Hispanic Physicians of Southern California",
        "value": "81949",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Associated Hyspanic Physicians",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Associates for Health Care, Inc. (AHC)",
        "value": "12807",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Assurant Employee Benefits (Now Sun Life)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Assurant Health Self-Funded (Allied Benefit)",
        "value": "88027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Assured Benefits Administrators",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Astiva Health",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Astiva Health Encounters",
        "value": "HCI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Asuris Northwest Health",
        "value": "35202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Athens",
        "value": "3519M",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Atlantic Dental Inc",
        "value": "J1983",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Atlantic Medical",
        "value": "SJMM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Atlantic PPO to Mercury Insurance - Auto",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Atlantis Eyecare",
        "value": "35174",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Atlas Life Ins Co (NFIC Plan Only)",
        "value": "SX158",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Atrio Health Plans",
        "value": "45399",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AudioNet - National Elevator",
        "value": "83383",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Audionet - UAW Ford Retirees/GM Employees (AUDIOSVS)",
        "value": "38261",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Audionet - UAW GM retirees/Chrysler Emps & Retirees (AUDIOABS)",
        "value": "11303",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AudioNet America (AUDIOCS)",
        "value": "80141",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AultCare/PrimeTime Health Plan",
        "value": "88058",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Aultra Administrative Group",
        "value": "J1530",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Austin Mutual Insurance",
        "value": "PASSE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto Club Insurance Association",
        "value": "86049",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto One Insurance",
        "value": "MR010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto Owners Insurance Company",
        "value": "83413",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Automated Benefit Services",
        "value": "95885",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Automated Group Administration Inc. (AGA)",
        "value": "HPOUV",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Automobile Dealers Insurance Company Inc",
        "value": "50383",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto-Owners \\ Home-Owners Insurance Company - CORVEL",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto-Owners \\ Owners Insurance Company - CORVEL",
        "value": "NM505",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto-Owners \\ Property-Owners Insurance Co - CORVEL",
        "value": "A0179",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto-Owners \\ Southern-Owners Company - CORVEL",
        "value": "00661",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auto-Owners Life Insurance Company - CORVEL",
        "value": "J1730",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Auxiant",
        "value": "87347",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Avalon Administrative Services - BCBSNC",
        "value": "38692",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Avalon Healthcare Solutions",
        "value": "23249",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Avalon Healthcare Solutions Capital Blue Cross",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Avalon IPA",
        "value": "41194",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Avante Health",
        "value": "DDUT1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Avera Health Plans",
        "value": "VAPRM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Avera Health Plans for John Morrell",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Avesis (Peach State Health Plan)",
        "value": "KMG11",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Avesis Third Party Administrator",
        "value": "60495",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AvMed Health Plans",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "AvMed, Inc.",
        "value": "J1440",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "AXA Assistance_USA",
        "value": "80314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Axminster Medical Group",
        "value": "85664",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ayers Trucking",
        "value": "76342",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BadgerCare Plus (CCHP)",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bakersfield Family Medical Center (BFMC) (Regal)",
        "value": "AC101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bakery and Confectionery Union and Industry International Health",
        "value": "EHPSC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Balance Staffing Workforce",
        "value": "43185",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Baldwin & Lyons, Inc. (Previous Name Protective Insurance Co) - CORVEL",
        "value": "31405",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bank of America - CORVEL",
        "value": "ADOCS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bankers Life (ERA Only)",
        "value": "74323",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Banner Health and Aetna Health Insurance Company",
        "value": "42011",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Banner Health Company",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Banner Medicare Advantage Plus PPO",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Banner Medicare Advantage Prime HMO",
        "value": "CPNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Banner UFC ALTCS",
        "value": "87452",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Baptist Health South Florida",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Baptist Healthcare Systems, Inc - CORVEL",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BARInet",
        "value": "SVIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Barnes",
        "value": "SC050",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Barrett Business Services Inc (BBSI)",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Basic Plus (TCC)",
        "value": "SHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bass Administrators, Inc.",
        "value": "23243",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bass Pro Group, LLC - CORVEL",
        "value": "SCAN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bay Bridge Administrators, LLC",
        "value": "28580",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Baycare Life Management",
        "value": "EXC02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BayCare Select Health Plans Inc",
        "value": "VMMH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS Empire New York",
        "value": "AMBLA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS Medicaid Ohio (Anthem)",
        "value": "15976",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS Mountain States",
        "value": "10339",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Hawaii",
        "value": "HLPHN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Illinois",
        "value": "HPIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Iowa",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS of Kansas City",
        "value": "84389",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Louisiana",
        "value": "PCCMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Mississippi",
        "value": "02027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Montana",
        "value": "84389",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Nebraska",
        "value": "MCDMA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of New Mexico",
        "value": "J3908",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS of New York (Central)",
        "value": "CPNA1 ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of New York (Rochester Area)",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of New York (Utica-Watertown)",
        "value": "TLINS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS of North Carolina",
        "value": "GOBHI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Oklahoma",
        "value": "CDKY1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Rhode Island",
        "value": "35206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS of South Carolina",
        "value": "CH101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of South Dakota",
        "value": "PROV1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Tennessee",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Texas",
        "value": "96385",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS of Vermont",
        "value": "89789",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of West Virginia",
        "value": "85664",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS of Wyoming",
        "value": "84099",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS South Carolina (FEP - Federal Employee Plan)",
        "value": "62956",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BCBS South Carolina (Preferred Blue)",
        "value": "LNSTR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS Virgin Islands",
        "value": "HLTHT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS Western New York Medicaid/CHP",
        "value": "13383",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBS Wisconsin (Anthem) ",
        "value": "CX025",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCBSMN Blue Plus Medicaid",
        "value": "APP01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BCN - NON JVHL Network - JVHL",
        "value": "24740",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beacon Health Options (Empire Plan Mental Health)",
        "value": "19191",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beacon Health Options (formerly Value Options)",
        "value": "58169",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beacon Health Options (General Motors)",
        "value": "67788",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beacon Mutual",
        "value": "12X42",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beacon of Life (AcuteCare)",
        "value": "CAS89",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Beall's Inc",
        "value": "16644",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beaver Medical Group",
        "value": "MVMM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bed Bath & Beyond - CORVEL",
        "value": "71065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Behavioral Health Systems",
        "value": "80314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Belk, Inc. - CORVEL",
        "value": "48330",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bella Vista Medical Group",
        "value": "BCTF1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benchmark Insurance",
        "value": "67829",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BeneBay",
        "value": "18049",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BeneCare Dental Plan",
        "value": "RR3426",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benefit & Risk Management Services (BRMS)",
        "value": "J1914",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Administration Services LTD (BAS LTD)",
        "value": "81400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Administrative Systems (BAS Health)",
        "value": "J1728",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Coordinators Corporation",
        "value": "77016",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Management Admin (BMA)",
        "value": "AMM17",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benefit Management Group-NV",
        "value": "15243",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Management Inc - Joplin Claims",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Management Services",
        "value": "LWA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Management Services of Louisiana",
        "value": "84105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Plan Administrators",
        "value": "CREA8",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benefit Plan Administrators, Inc.",
        "value": "88250",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benefit Plan Services",
        "value": "84320",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benefit Systems & Services, Inc. (BSSI)",
        "value": "MPM26",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BeneSys Inc.",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benveo",
        "value": "79048",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Benveo - First Health Network",
        "value": "MR002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Benveo - MultiPlan",
        "value": "SIL01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Berkley Industrial Comp",
        "value": "84980",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Berkley Mid-Atlantic Group",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Berkley National Insurance Company",
        "value": "77052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Berkley Net Underwriters, LLC",
        "value": "J3912",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Berkley Regional Insurance Company",
        "value": "42139",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Berkley Risk Administrators Company, LLC",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Berkley Specialty Underwriters",
        "value": "MSO11",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Berkshire Hathaway Direct Insurance Company",
        "value": "36338",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Berkshire Hathaway Homestate Companies (Member of BHHC)",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Berwind Corporation ",
        "value": "23281",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Best Life & Health Insurance Company",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Better Health",
        "value": "CX060",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Better Health of Florida",
        "value": "45282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beverly Alianza IPA",
        "value": "MC051",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Beverly Hospital - Imperial Health Holdings",
        "value": "HPOUV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Beverly Hospital BEVAHISP",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BIENVIVIR",
        "value": "EHPSC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bind (PreferredOne)",
        "value": "71404",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bituminous Fire and Marine",
        "value": "04326",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Black Hawk",
        "value": "J3961",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Block Vision - Eye Specialists",
        "value": "00265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Block Vision - VIPA",
        "value": "10029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Block Vision Inc.",
        "value": "WVCHD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Block Vision of Texas",
        "value": "53120",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Advantage",
        "value": "56731",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Benefit Administrators of MA",
        "value": "29076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Card Program",
        "value": "27048",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Care Family Plan",
        "value": "MBA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Care Network Advantage of Michigan",
        "value": "CPPCA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Care Network of Michigan",
        "value": "J3329",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Choice of South Carolina",
        "value": "89244",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Choice of South Carolina (Medicaid)",
        "value": "CCIH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross (Cal-Optima - Medi-Cal)",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield Arkansas",
        "value": "128WV",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield New Hampshire",
        "value": "20501",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Alabama",
        "value": "22248",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Arizona",
        "value": "75318",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Arizona Advantage (formerly Banner Medisun)",
        "value": "80314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Arkansas",
        "value": "J3352",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Colorado",
        "value": "11370",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Colorado (Anthem)",
        "value": "128VA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Colorado (Trigon)",
        "value": "SWHMA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Connecticut",
        "value": "05003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Connecticut (Anthem)",
        "value": "FCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of DC (Carefirst)",
        "value": "MCS03",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Delaware",
        "value": "57254",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Delaware (UCCI)",
        "value": "EPF03",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Florida",
        "value": "45967",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of GA - Federal",
        "value": "00922",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Georgia",
        "value": "95444",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Georgia (Anthem)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Hawaii (HMSA)",
        "value": "FS802",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Illinois",
        "value": "ASHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Indiana (Anthem)",
        "value": "J1988",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Iowa",
        "value": "SC027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Kansas",
        "value": "CKIL1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Kansas City",
        "value": "93024",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Kentucky (Anthem)",
        "value": "38692",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Louisiana",
        "value": "60054",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Louisiana Advantage Plus Network",
        "value": "MRIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Maine",
        "value": "CCMMH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Maine (Anthem)",
        "value": "39180",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Maryland (CareFirst )",
        "value": "59266",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Massachusetts",
        "value": "91313",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Michigan",
        "value": "SH777",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Minnesota",
        "value": "22384",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Mississippi",
        "value": "J3877",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Missouri (Anthem)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Montana",
        "value": "27357",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Nebraska",
        "value": "48145",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Nevada (Anthem)",
        "value": "V360H",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New Hampshire (Anthem)",
        "value": "65088",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New Mexico",
        "value": "LOBH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New York - Excellus (Central Region)",
        "value": "TREST",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New York - Excellus (Rochester Region)",
        "value": "00760",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New York - Excellus (Utica Region)",
        "value": "56132",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of New York - Excellus (Utica Watertown Region)",
        "value": "VAC45",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of North Carolina",
        "value": "IP079",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of North Dakota",
        "value": "56756",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Ohio (Anthem)",
        "value": "DOCSO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Oklahoma",
        "value": "22771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Oregon (Regence)",
        "value": "01066",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Pennsylvania (Highmark)",
        "value": "CX101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Rhode Island",
        "value": "65062",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of South Carolina",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of South Carolina (State Health Plan)",
        "value": "PAOH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of South Dakota",
        "value": "00611",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Tennessee",
        "value": "31404",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Texas",
        "value": "CSSD2",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Texas - Medicaid",
        "value": "MPM44",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Utah (Regence)",
        "value": "59064",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Vermont",
        "value": "HVMG1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Virginia - Trigon",
        "value": "TRP1E",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Virginia (Anthem)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Western New York",
        "value": "37215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Western New York - Federal Employee Program (FEP)",
        "value": "ALTAM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Wisconsin (Anthem)",
        "value": "88337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Wisconsin FEP (Anthem)",
        "value": "C1090",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Blue Shield of Wyoming",
        "value": "95827",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Community Centennial (BCBS NM)",
        "value": "J1829",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Community ICP / FHP",
        "value": "96400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Community MMAI",
        "value": "00430",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Community Options",
        "value": "77050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Community Options of Illinois",
        "value": "NCHON",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Complete of Michigan",
        "value": "29018",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Independence (Pennsylvania)",
        "value": "DCRSS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Medicare Advantage PPO/HMO",
        "value": "85729",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of California",
        "value": "J1900",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of California / Wellpoint",
        "value": "59144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Colorado",
        "value": "J3757",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Connecticut",
        "value": "36459",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Georgia",
        "value": "14829",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Idaho",
        "value": "56190",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Illinois",
        "value": "53589",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Indiana",
        "value": "11302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Kentucky",
        "value": "83352",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Maine",
        "value": "13102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Missouri",
        "value": "7707C",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Of Nevada",
        "value": "MPM29",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross Of New Hampshire",
        "value": "CAPMN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of New Jersey",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Ohio",
        "value": "SB690",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Pennsylvania",
        "value": "STJOE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Pennsylvania (Capital Blue / CAIC)",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Cross of Virginia",
        "value": "66917",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Medicare of North Carolina",
        "value": "80314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield - California",
        "value": "J2331",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield - California / Blue Shield (FEP)",
        "value": "00246",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of California",
        "value": "SLRT1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of California (P.O. Box 272540)",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of California (P.O. Box 272590)",
        "value": "MDXHI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of California Promise Health Plan",
        "value": "MRIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of California Promise Health Plan Encounters",
        "value": "15102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of Idaho (Regence)",
        "value": "54096",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of Pennsylvania (Camp Hill) Provider will need to register with them and inform them that EDI Health group is their clearinghouse 800-633-5430 opt 0)",
        "value": "J1571",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Blue Shield of Washington (Regence)",
        "value": "VALOP",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "BlueChoice HealthPlan South Carolina Medicaid",
        "value": "DAKCH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BlueShield of Northeastern New York",
        "value": "78857",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BMC HealthNet Plan",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BMW North America",
        "value": "AAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Boilermakers National Health & Welfare Fund",
        "value": "BHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Boler Company",
        "value": "48123",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Boncura Health Solutions",
        "value": "BCSVT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BookMD Inc.",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Boon Chapman Administrators, Inc.",
        "value": "SCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Boston Medical Center Health Plan (BMCHP)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Boulder Administration Services",
        "value": "MPM22",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brand New Day (FFS)",
        "value": "04320",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brand New Day Encounters",
        "value": "128MD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Braven Health",
        "value": "CALOP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BreckPoint",
        "value": "DCMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brickstreet",
        "value": "128MD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bridgefield Casualty ",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bridgefield Employers ",
        "value": "77943",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bridgeport Dental Service",
        "value": "J1585",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "BridgeSpan",
        "value": "CREA8",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bridgeview Company",
        "value": "J1685",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bridgeway Health Solutions - Medical",
        "value": "99789",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bright Health Medicare Advantage",
        "value": "76343",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bright Health Plan",
        "value": "MMFCS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brighten Health Plan Solutions",
        "value": "74227",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bristol Park Medical Group",
        "value": "BC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bristol West",
        "value": "Z0005",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Broadspire (Auto)",
        "value": "TKFMC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Broadspire, a Crawford Company",
        "value": "47165",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brokerage Concepts",
        "value": "BOONG",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Brookshire IPA ",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brotherhood Mutual Insurance",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Broward County Board of Commissioners (FL)",
        "value": "SCNMO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Broward Regional Health Planning Council",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brown & Toland Health Services",
        "value": "EPF06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brown & Toland Medical Group",
        "value": "WRPNI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brown & Toland Sutter Select",
        "value": "00902",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Brownyard Group Inc. (Arch Insurance)",
        "value": "J1809",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Brunswick Corporation",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "BSI Companies",
        "value": "DREYR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Buckeye Community Health (Ambetter) - Medical",
        "value": "41124",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Buckeye Ohio Medicaid",
        "value": "37086",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Buenaventura Affiliated Physicians INC",
        "value": "BVAP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Builders Insurance Group (GA only)",
        "value": "06105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Builders Mutual Insurance",
        "value": "22175",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Bunch and Associates",
        "value": "88848",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Bureau For Children With Medical Handicaps",
        "value": "VB002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"Bureau Of Prisons-Apprio",
        "value": "\"",
        "AveCallholdtime": "CALOP"
    },
    {
        "label": "Burlington Coat Factory Warehouse ",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Business First ",
        "value": "COSNM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Businessmens Assurance",
        "value": "WHLTH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Butler America Holdings, INC.- Corvel - CORVEL",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Butler Benefit",
        "value": "00590",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "C & O Employees Hospital Association",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "C&S Wholesale Grocers ",
        "value": "BPA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "C. L. Frates and Company",
        "value": "OLRMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Caduceus Medical Group (Regal)",
        "value": "66705",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cahaba GBA (Medicare Part A Rural)",
        "value": "71066",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cal Care IPA",
        "value": "SC052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cal Care IPA Encounters",
        "value": "43090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cal Viva Health",
        "value": "47027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cal Water (California Water Service)",
        "value": "77016",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Care (Humboldt Del Norte)",
        "value": "00504",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Eye Care",
        "value": "POD1S",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Fair Services Authority",
        "value": "72148",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Health and Wellness",
        "value": "J3938",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California Health and Wellness - Medical",
        "value": "31125",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California Hospital Medical Center",
        "value": "53085",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California IPA (Capital MSO)",
        "value": "26337",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Kids Care (CKC)",
        "value": "IHS06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Medical Center",
        "value": "59279",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California Pacific Medical Center (CPMC)",
        "value": "84146",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "California Pacific Physicians Medical Group, Inc.",
        "value": "UHP01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California Rural Indian Health Board (CRIHB) Cares",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "California Rural Indian Health Board (CRIHB) Options",
        "value": "79966",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CalOptima",
        "value": "12113",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CalOptima Direct",
        "value": "GHAWH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CalOptima LTC",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CalPortland Company - CORVEL",
        "value": "13269",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Calvos SelectCare",
        "value": "J1678",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cambridge Integrated Services",
        "value": "SC054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cameron Manufacturing & Design, Inc. (UCS)",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Camp Lejeune Family Member Program",
        "value": "37282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cannon Cochran Management Services",
        "value": "SCNUN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cano Health California",
        "value": "39081",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cap Management",
        "value": "58600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Capistrano Unified School District (CA) - CORVEL",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Capital Blue Cross",
        "value": "J3926",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capital Blue Cross of Pennsylvania",
        "value": "YAMHL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capital Dental",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capital District Physicians Health Plan (CDPHP)",
        "value": "88848",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capital Health Partners",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capital Health Plan",
        "value": "RP064",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capitol Administrators",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capitol Dental Care WVCH",
        "value": "10172",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Capitol Indemnity",
        "value": "TLINS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Capitol Insurance Companies - CORVEL",
        "value": "00932",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CapRock Health Plan - Verity",
        "value": "95567",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CapRock HealthPlans",
        "value": "26054",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cardiovascular Care Providers",
        "value": "09908",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care 1st Health Plan - Arizona",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care 1st Health Plan of Arizona",
        "value": "86047",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care Advantage",
        "value": "CMHGF",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care Improvement Plus (CIP) / XL Health",
        "value": "PAOH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Care Logic",
        "value": "J3868",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Care Management Resources (CMR)",
        "value": "251CC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care N' Care",
        "value": "13150",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Care Wisconsin Health Plan",
        "value": "J1417",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Care1st Health Plan of Arizona",
        "value": "04332",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareATC",
        "value": "87065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareCentrix",
        "value": "12T64",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareCore National",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareCore National LLC (Aetna Radiology)",
        "value": "73275",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareCore/WCNY RAD",
        "value": "CB987",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareFactor",
        "value": "71064",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareFirst BCBS (MD, DC)",
        "value": "CMA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareFirst BCBS Community Health Plan Maryland",
        "value": "13193",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareFirst BCBS NCA Region",
        "value": "62324",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareFirst BCBS of Maryland",
        "value": "89235",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Careington Benefit Solutions",
        "value": "27905",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carelink Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carelon Behavioral Health",
        "value": "37278",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Caremark WPAS, Inc., Grp# P62",
        "value": "00923",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareMore Encounters",
        "value": "99486",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareMore Health Plan",
        "value": "46891",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareMore Value Plus (CVP)",
        "value": "MCDID",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareOregon Inc.",
        "value": "MCDRI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CarePartners",
        "value": "C1042",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CarePartners of Connecticut",
        "value": "J1008",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Careplus (Encounters)",
        "value": "10557",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CarePlus Health Plan",
        "value": "39114",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareSource Arkansas",
        "value": "98798",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Caresource Health Plan of Oregon",
        "value": "J1970",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareSource Iowa",
        "value": "23160",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareSource North Carolina",
        "value": "93158",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareSource of Georgia",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Caresource of Indiana",
        "value": "25531",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Caresource of Kentucky",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CareSource of Ohio",
        "value": "59140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CareSource Ohio Medicaid",
        "value": "83926",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Caresource West Virginia",
        "value": "39640",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carewest",
        "value": "23228",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Careworks",
        "value": "COVON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carisk Behavioral Health",
        "value": "10533",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cariten Healthcare",
        "value": "95308",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cariten Senior Health",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carolina Behavioral Health Alliance",
        "value": "95192",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carolina Care Plan Inc.",
        "value": "1928772",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carolina Complete",
        "value": "128KY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carolina SeniorCare",
        "value": "20538",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carpenters Health & Welfare (FCHN)",
        "value": "J1870",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Carpenters Health & Welfare Fund of Philadelphia & Vicinity",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Carpenters Trust of Western Washington",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Caryn Health",
        "value": "45275",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CAS Coastal Administrative Services",
        "value": "35112",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cascade Health Alliance, Inc",
        "value": "04286",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CASD/Sanford  (Chiropractic Associates of SD)",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Casitas Municipal Water District (CA) - CORVEL",
        "value": "CLFRS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Castlepoint Insurance Company",
        "value": "46045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Castlepoint National Insurance Company",
        "value": "65088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Catholic Mutual",
        "value": "BHPP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CBA BLUE",
        "value": "BVES1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CBHNP - HealthChoices",
        "value": "DDA10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CC-Development Group, Inc. - CORVEL",
        "value": "BCSMT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CCMSI",
        "value": "00840",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CDO Technologies",
        "value": "39144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cedar Valley Community HealthCare (CVCH)",
        "value": "00680",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cedars Towers Surgical Medical Group  (Encounters Only, Payer ID Required)",
        "value": "39069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cedars-Sinai Medical Group (CSHS) - Capitated/Encounters",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cedars-Sinai Medical Group (CSHS) - Fee for Service",
        "value": "13550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Celtic Insurance",
        "value": "03699",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CeltiCare Health Plan (Ambetter) - Medical",
        "value": "66055",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cement Masons & Plasterers Health & Welfare Trust Group #F16",
        "value": "41600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CenCal Health",
        "value": "20044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cendant Corporation ",
        "value": "03036",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cenpatico Behavioral Health – All States",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Center Care PPO (Meritain)",
        "value": "OMN02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Center for Elders Independence (CEI)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Center For Sight",
        "value": "KCMD1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Center IPA",
        "value": "WBHCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CenterLight Healthcare",
        "value": "COMMF",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Centers Plan for Healthy Living",
        "value": "00445",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Centinela Valley IPA",
        "value": "CHECS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Centinela Valley IPA Encounters",
        "value": "38259",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Centivo",
        "value": "25463",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Centivo Direct Network Access ",
        "value": "91151",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Benefits Life",
        "value": "DRHCP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Benefits Mutual",
        "value": "00210",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Benefits National",
        "value": "22100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central CA Alliance for Health",
        "value": "C1040",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central California Alliance for Health                                  ",
        "value": "76045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central California Alliance for Health (CCAH)",
        "value": "99208",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Coast Alliance Health",
        "value": "CARMO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Contra Costa Transit Authority - CORVEL",
        "value": "AMDC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Florida Health",
        "value": "13193",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Garden & Pet Company - CORVEL",
        "value": "73866",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Health Medicare Plan",
        "value": "BCSSC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Health MSO",
        "value": "31059",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Hockey League ",
        "value": "J1626",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Pennsylvania Teamsters Fund",
        "value": "19431",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Reserve Life",
        "value": "J3839",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Reserve Life Insurance Company (Medicare Supplement)",
        "value": "32043",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central States Health & Welfare Funds",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Central Valley Medical Group",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Central Valley Medical Providers CVMEDPRO",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Centurion",
        "value": "00621",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Centurypho",
        "value": "ARISE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHA Health",
        "value": "77684",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chaffey Joint Union H. S. District (CA) - CORVEL",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chaffey Medical Group",
        "value": "35186",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Champion Chevrolet",
        "value": "GLOV1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Champion Payer Solutions",
        "value": "F6030500",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHAMPVA",
        "value": "61146",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHAMPVA - HAC",
        "value": "95044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Charming Shoppes, Inc. ",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CHC Cares of South Carolina",
        "value": "MPM33",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHC Texas",
        "value": "GACS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CHEC - A Subsidiary of Sprint",
        "value": "30799",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cherokee Insurance",
        "value": "MCDPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cherokee Nation Comprehensive Care Agency",
        "value": "GHOKC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chesapeake Life Insurance Co.",
        "value": "GRAVI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chesterfield Services, Inc.",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chesterfield Technologies",
        "value": "83148",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chicago Transit Authority - CORVEL",
        "value": "DHFIE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Childhealth Plus by Healthfirst (CHP)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Children First Medical Group",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Children of Women Vietnam Veterans - VA HAC",
        "value": "GOBHI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Children’s Hospital Orange County (CHOC) Health Alliance",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Children's Medical Alliance",
        "value": "95885",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Children's Physicians Medical Group (CPMG)",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Children's Specialists of San Diego II",
        "value": "J1947",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chinese Community Health Plan",
        "value": "65088",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chireno ISD",
        "value": "03964",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ChiroCare",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chiropractic Associates of SD (CASD)",
        "value": "J1586",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chiropractic Care of Minnesota",
        "value": "MPM60",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chiropractic Health Plans (CHP)",
        "value": "SAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chiropractic Health Plans (CHP) - Deseret Mutual Benefits Administration",
        "value": "HCH01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Chiropractic Health Plans (CHP) - Educators Mutual Insurance",
        "value": "UC002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chiropractic Health Plans (CHP) - Medicaid",
        "value": "66170",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chiropractic Health Plans (CHP) - University of Utah Health Plans",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHOC Health Alliance",
        "value": "3414A",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Medical Group",
        "value": "00805",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Physicians Net CoachellaMed ",
        "value": "36149",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Choice Physicians Net First Choice",
        "value": "37281",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Physicians Network",
        "value": "J1036",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Physicians Network - Amada Health",
        "value": "J1477",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Physicians Network All United Medical Group",
        "value": "38219",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Choice Physicians Network Nivano Physicians",
        "value": "128FL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHORUS Community Health Plan (CCHP)",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Christian Brothers Services",
        "value": "77076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Christian Care Ministries (MediShare)",
        "value": "MPM29",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Christiana Care VBR",
        "value": "J3746",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Christus Health Medicare Advantage",
        "value": "85016",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Christus Health New Mexico HIX",
        "value": "DX017",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Christus Health Plan",
        "value": "WITH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CHRISTUS Health Plan Texas HIX",
        "value": "75228",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Christus Spohn Health Plan",
        "value": "SPOHN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Chubb Services",
        "value": "251VA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Church Mutual Insurance Company",
        "value": "EPNSH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Church Mutual Insurance Company (Auto)",
        "value": "84041",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CIGNA",
        "value": "11752",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA - PPO",
        "value": "98798",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA For Seniors",
        "value": "60509",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA Health Plan",
        "value": "45281",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CIGNA HealthCare",
        "value": "J1204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CIGNA Healthcare for Seniors (Medicare)",
        "value": "60818",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cigna Healthspring",
        "value": "03036",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cigna HealthSpring (Bravo)",
        "value": "98798",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA PPA",
        "value": "25531",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA Premier Plus",
        "value": "00210",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIGNA Private",
        "value": "SYMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CIM Insurance Corporation",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cincinnati Insurance",
        "value": "95444",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Citizens Choice Health Plan (Fee For Service)",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Citizens Insurance Company of America",
        "value": "56116",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Citizens of Humanity - CORVEL",
        "value": "12402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Citrus Health Plan",
        "value": "VCHCP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Citrus Memorial Health Foundation Inc.",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Citrus Valley Health Partners",
        "value": "31059",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Citrus Valley IPA",
        "value": "88030",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Citrus Valley Physicians Group (CVPG)",
        "value": "83926",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Citrus World, Inc.",
        "value": "RHA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Adelanto",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Amarillo Group Health Plan",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City Of Arlington",
        "value": "EHI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Austin",
        "value": "PARHC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Banning (CA) - CORVEL",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Baytown - CORVEL",
        "value": "J1605",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Beverly Hills - CORVEL",
        "value": "88027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Blythe (CA) - CORVEL",
        "value": "J3866",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Cambridge",
        "value": "82347",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Desert Hot Springs (CA)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of El Paso",
        "value": "83028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Fort Worth (TX)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Fountain Valley (CA) - CORVEL",
        "value": "UCI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Gardena (CA) - CORVEL",
        "value": "J1838",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Glendale (AZ)",
        "value": "SCPR1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of La Mesa 11 (CA) - CORVEL",
        "value": "03432",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Laguna Hills (CA) - CORVEL",
        "value": "64157",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City Of Lake Charles (La) - CORVEL",
        "value": "16089",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Miami Beach (FL)",
        "value": "VB001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Milwaukee (WI) - CORVEL",
        "value": "70259",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Minneapolis",
        "value": "DCA62",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Norfolk (VA) - CORVEL",
        "value": "32620",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Ocoee",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Omaha (NE) - CORVEL",
        "value": "36396",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Perris (CA) - CORVEL",
        "value": "74323",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Pompano Beach (FL)",
        "value": "HOSH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Raleigh - CORVEL",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Redding (CA)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Rialto, CA - CORVEL",
        "value": "42011",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of San Diego",
        "value": "61102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of San Jacinto (CA) - CORVEL",
        "value": "BCSSC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of St. Paul (MN)",
        "value": "MPM28",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Stanton (CA) - CORVEL",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Topeka (KS) - CORVEL",
        "value": "WRPNI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of Torrance",
        "value": "MCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Venice",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "City of Virginia Beach (VA) - CORVEL",
        "value": "KEE01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "City of West Palm Beach (FL)",
        "value": "MNAUL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Clackamas County Mental Health",
        "value": "18768",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clackamas MHO General Fund",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Claimchoice Administrators (formerly AmeraPlan)",
        "value": "60601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Claims Administrative Services (CAS)",
        "value": "OBA16",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Claims Choice Administrators LLC",
        "value": "C1032",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Claims Development Corporation",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ClaimsBridge Custom Provider Network",
        "value": "10057",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Claimsware, Inc. DBA ManageMed",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Classic Residence By Hyatt ",
        "value": "07040",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Clear One Health Plans",
        "value": "J3924",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Clear Spring Health",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clever Care Health Plan",
        "value": "41136",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clever Care Health Plan Encounters",
        "value": "J1691",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Client First",
        "value": "NMM06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clinica Medica San Miguel",
        "value": "PHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clinical Specialties, Inc",
        "value": "37290",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clinicas del Camino Real",
        "value": "95202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Clover Health (formerly CarePoint Health Plan)",
        "value": "95044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CNA Insurance",
        "value": "37284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CNA Insurance (Auto only)",
        "value": "APP01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CNS Healthcare",
        "value": "74238",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coach USA",
        "value": "MCDRI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coachella Valley Water District (CA) - CORVEL",
        "value": "J1628",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoachellaMed ",
        "value": "PHM10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coastal Administrative Services",
        "value": "02057",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coastal Communities Physician Network (CCPN) (Regal)",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coastal TPA",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coca-Cola Enterprises, Inc. ",
        "value": "11081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cochran Canon Management Service, Inc. (CCMSI)",
        "value": "CCA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coeur Plan Services, LLC",
        "value": "J1257",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cofinity - Group Resources",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "College Health IPA (CHIPA)",
        "value": "NEMS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Colonial Medical",
        "value": "WBHCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Colorado Access",
        "value": "59322",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Colorado Behavioral Healthcare Inc",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Colorado Casualty ",
        "value": "DDNV1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Colorado Community Health Alliance",
        "value": "FCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Colorado Health Insurance Cooperative Inc.",
        "value": "SCPR1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Colorado Kaiser Permanente",
        "value": "56001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Colorado Medicaid",
        "value": "37602",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Columbia Mutual Insurance Company",
        "value": "94352",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Columbia Sussex Corporation ",
        "value": "89243",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Columbine Health Plan",
        "value": "J1551",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoMed - CIGNA",
        "value": "CHCPI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Commerce & Industry/Greenwood",
        "value": "LWA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Commerce Benefits Group",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Common Ground Healthcare",
        "value": "NDX99",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Commonwealth Care Alliance",
        "value": "SC003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Commonwealth Care Alliance ",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "\"Commonwealth Care Alliance - Medicare Advantage",
        "value": "\"",
        "AveCallholdtime": "AMIPA"
    },
    {
        "label": "\"Commonwealth Care Alliance (CCA)",
        "value": "\"",
        "AveCallholdtime": "NEMS1"
    },
    {
        "label": "Commonwealth Health Alliance (CHA)",
        "value": "SPA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CommuniCare Advantage",
        "value": "J1505",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Alliance of IL",
        "value": "74266",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Alliance of Illinois",
        "value": "HSH02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care Associates",
        "value": "GNPMG",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care BHO",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care Inc. - Family Care (Wisconsin)",
        "value": "09830",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Inc. (Wisconsin)",
        "value": "RP016",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care IPA",
        "value": "62111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care Managed Health Care Plans of Oklahoma",
        "value": "89238",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care Plan (Broward Health PPUC)",
        "value": "22344",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Plan (Commercial)",
        "value": "93044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Plan (Florida Healthy Kids)",
        "value": "84146",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Care Plan (Medicaid)",
        "value": "CPNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Care Plan (Palm Beach District)",
        "value": "94135",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Development Commission (CA) - CORVEL",
        "value": "95567",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Family Care",
        "value": "89236",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community First",
        "value": "11695",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Alliance",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Alliance of Tennessee",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Center Network - CHCN",
        "value": "USMBP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Health Choice",
        "value": "C1033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Choice - Health Insurance Marketplace",
        "value": "LNY01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Group of San Diego",
        "value": "AH-CARITAS-OHMCD-RAD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Health Group of SD (CHGSD) - Capitated Claims",
        "value": "RVC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Group of SD (CHGSD) - FFS Claims",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Health Plan District of Columbia",
        "value": "HESUN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Health Plan of Washington",
        "value": "74853",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Health Plan of Washington (CHP of WA)",
        "value": "64884",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Life",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Medical Centers (CA) - CORVEL",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Community Medical Group of the West Valley (Regal)",
        "value": "J3857",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Community Transit (WA only) - CORVEL",
        "value": "91164",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CommunityCare Oklahoma (CCOK)",
        "value": "GBHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Companion Property and Casualty Insurance Company",
        "value": "18764",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Compass Group USA, Inc. ",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CompDent / CompBenefits",
        "value": "J1946",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Complementary Healthcare Plan (CHP)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Complete Care IPA",
        "value": "CB733",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Complimentary Healthcare Plans",
        "value": "93235",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CompManagement Health",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Comprehensive Care Management",
        "value": "25850",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Comprehensive Care Services",
        "value": "38238",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ComPsych",
        "value": "DHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CompuSys Inc",
        "value": "C1089",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Concierge TPA",
        "value": "BCSMT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Concordia Care, Inc",
        "value": "39856",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Confederation Administration Services & Life Insurance",
        "value": "06603",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Conifer (Workers Comp)",
        "value": "73126",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Conifer Health Solutions",
        "value": "TTUMD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CONNECTICARE",
        "value": "PCMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Connecticare - Medicare Advantage (Emblem)",
        "value": "J3722",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Connecticare (Commercial)",
        "value": "EPF11",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Connecticut Carpenters Health Fund",
        "value": "NPN11",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Connecticut General",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Connecticut General Life",
        "value": "75309",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Connecticut Indemnity Company",
        "value": "13189",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Consociate Group",
        "value": "53476",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Consolidated Associates Railroad",
        "value": "93235",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Consolidated Health Plans",
        "value": "MC006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Consumers Choice Health SC",
        "value": "J1526",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Container Graphics Corporation",
        "value": "HVMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contech Construction Products",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contessa Health",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contessa Health - Security Health Plan",
        "value": "CB987",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Continental Airlines ",
        "value": "38225",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Continental Benefits",
        "value": "J1018",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Continental General Insurance (Medicare Supplement)",
        "value": "SB580",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Continental General Insurance Company",
        "value": "61425",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contra Costa Behavioral Health Plan",
        "value": "DMELA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contra Costa Health Plan",
        "value": "30166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Contractors, Laborers, Teamsters & Engineers (Local 14B)",
        "value": "95378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Convergys Corporation ",
        "value": "56162",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook Childrens Health Plan",
        "value": "J3751",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook Childrens Star Plan",
        "value": "SCAN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cook County Health - Behavioral",
        "value": "J1541",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook County Health - Medical",
        "value": "31402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook Group Health Plan",
        "value": "06302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook Group Solutions",
        "value": "00243",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cook Medical Group",
        "value": "36878",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cooperative Benefit Administrators",
        "value": "19436",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cooperative Benefit Administrators (CBA)",
        "value": "08044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoOportunity Health",
        "value": "37279",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Co-ordinated Benefit Plans LLC",
        "value": "SRS83",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coordinated Care - Medical",
        "value": "CCA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coordinated Medical Specialists",
        "value": "36149",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoPatient",
        "value": "J1453",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Core Administrative Services",
        "value": "60230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Core Benefits / Multimatic Tennesse, LLC",
        "value": "75237",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Core Management Resources Group",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreCivic",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreFive (Mega Life & Health)",
        "value": "VACCN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreFive (SelectCare)",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoreSource - Internal",
        "value": "10107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoreSource - Little Rock",
        "value": "BC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoreSource - MD,PA,IL",
        "value": "75600",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreSource - OH",
        "value": "J1557",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreSource - Ohio",
        "value": "00902",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreSource (AZ, MN)",
        "value": "10067",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoreSource (MD, PA, IL, IA)",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreSource (NC, IN)",
        "value": "NCCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoreSource KC (FMH)",
        "value": "10416",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoreSource Little Rock",
        "value": "J3899",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Corizon Inc (Formerly Correctional Medical Services)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cornerstone Benefit Administrators",
        "value": "31478",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cornerstone Preferred Resources",
        "value": "CB268",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Corporate Benefit Service (CBSA)",
        "value": "95044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Corporate Benefits Service, Inc. (NC)",
        "value": "11315",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Correctcare (GA Correctional Healthcare Adult Health)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CorrectCare Integrated Health (CA Prison Health Care Services)",
        "value": "10002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Correctcare Integrated Health (Non-LA Jails)",
        "value": "67895",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Correction Health Partners",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Correctional Management Service ",
        "value": "SCNAC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Correctional Medical Services, Inc. (Now known as Corizon Inc)",
        "value": "PCU02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Corrections Corporation of America (CCA)",
        "value": "KCIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Corrigan-Camden ISD",
        "value": "37116",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cottingham & Butler Claim Services, Inc.",
        "value": "HPOUV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cottingham and Butler (Equian)",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Country Casualty Insurance Company",
        "value": "27048",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Country Financial \\ Modern Service Casualty Insur Co - CORVEL",
        "value": "11328",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Country Insurance \\ Modern Service Casualty Ins Co",
        "value": "17929",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Country Insurance \\ Mountain States Insurance - CORVEL",
        "value": "55069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Country Insurance \\ MSI Preferred Insurance Company",
        "value": "33108",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Country Mutual Insurance Company",
        "value": "C1052",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Country Preferred Insurance Company",
        "value": "65432",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Countryway Insurance Company",
        "value": "J3865",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County Care Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County Medical Services Program (CMSP)",
        "value": "97041",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Chesterfield, VA",
        "value": "76342",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Fresno",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County of Kern - CORVEL",
        "value": "J1866",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Kern POS",
        "value": "77033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Los Angeles (1000)",
        "value": "34196",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County of Los Angeles (2000)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County of Los Angeles (3000)",
        "value": "CC168",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County of Los Angeles (4000/5000)",
        "value": "38265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Sacramento - EMSF",
        "value": "59065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Sacramento - Healthy Partners",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County Of San Diego",
        "value": "TLINS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "County of Shasta (CA)",
        "value": "MBA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "County of Tulare (CA) - CORVEL",
        "value": "93221",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Covenant Administrators Inc.",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Covenant Management System Employee Benefit Plan",
        "value": "20416",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Covenant Management Systems",
        "value": "13551",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Health & Life",
        "value": "10010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Health Care",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Health Care Carelink Medicaid",
        "value": "38217",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Health Care of  MO, MS, AK, TN(00184)",
        "value": "02027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coventry Health Care of Georgia",
        "value": "36347",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Health Care of Louisiana",
        "value": "86027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coventry Health Care of the Carolinas, Inc. (formerly WellPath)",
        "value": "TAYLR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coventry Health Care of Virginia, Inc. (formerly Southern Health Services)",
        "value": "IICTX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coventry Health Care USA (HCUSA)",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Healthcare of Florida (Aetna Medicaid)",
        "value": "38232",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry National/University of MO(00250)",
        "value": "CPS01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry National/University of MO(COVON)",
        "value": "19431",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Coventry One",
        "value": "J1967",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coventry Wellpath Select",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CoventryCares (formerly OmniCare/CareNet/Carelink Medicaid)",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CoventryCares of West Virginia",
        "value": "12202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Coworx Staffing, LLC ",
        "value": "CC304",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Cox Health Plan",
        "value": "SYMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CPR Share Plans",
        "value": "52511",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Craftworks Holdings - CORVEL",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Create Healthcare",
        "value": "95885",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Creative Medical Systems",
        "value": "56205",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Crescent Health Solutions",
        "value": "67022",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Crestbrook Insurance Company",
        "value": "25172",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Crossway HealthShare",
        "value": "36400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Crown Life - Great West",
        "value": "IEXTX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Croy - Hall Management, Inc.",
        "value": "COACC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Crum & Forster Indemnity Co",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Crystal Run Health Plans",
        "value": "99208",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CSAA Insurance Company",
        "value": "GCHCA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CSEA Employee Benefit Fund",
        "value": "J1636",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CSI NETWORK SERVICES",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cumberland Insurance Group",
        "value": "MCC02",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CuraeChoice",
        "value": "03202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Custody Medical Services Program (CMSP)",
        "value": "AMM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Custom Benefit Administrators",
        "value": "45979",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Custom Design Benefits, Inc.",
        "value": "NCPG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "CVS Pharmacy (CAREMARK)",
        "value": "J3973",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CWI Benefits, Inc.",
        "value": "46299",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "CYPRESS BENEFIT ADMINISTRATORS",
        "value": "91062",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Cypress Security (Member of BHHC)",
        "value": "J2095",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dairyland Insurance Company",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DakotaCare",
        "value": "IHS07",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DakotaCare Chiropractic",
        "value": "16307",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dameron Hospital Association",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Darigold - CORVEL",
        "value": "251CC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dart Management Corporation",
        "value": "37315",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dawson ISD",
        "value": "71412",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DBi Services LLC - CORVEL",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DC Health Ryan White Program",
        "value": "22264",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DC Risk Solutions",
        "value": "31125",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DCHP Texas Medicaid",
        "value": "95444",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DDIC",
        "value": "76048",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dean Health Plan",
        "value": "10196",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Decent",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Del Taco, LLC",
        "value": "98205",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delano Regional Medical Group (Managed Care Systems)",
        "value": "J2276",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delaware First Health",
        "value": "SHPM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dell Childrens Health Plan",
        "value": "62119",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dell, Inc. - CORVEL",
        "value": "PHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental (Northeast - ME, NH, VT)",
        "value": "NDB01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Classic",
        "value": "UHA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Clear",
        "value": "FCMA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Encara",
        "value": "37290",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental Ins. Co. - Alabama",
        "value": "23166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Ins. Co. - Florida",
        "value": "18763",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Ins. Co. - Georgia",
        "value": "FS802",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental Ins. Co. - Louisiana",
        "value": "36582",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Ins. Co. - Mississippi",
        "value": "82018",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental Ins. Co. - Texas",
        "value": "13111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Ins. Co. - Utah",
        "value": "88888",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental Of Alaska",
        "value": "OODAH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Arizona",
        "value": "EXC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Arkansas",
        "value": "MLNSC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of California",
        "value": "IHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Colorado",
        "value": "J1968",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Connecticut",
        "value": "J1238",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Delaware",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Georgia",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Idaho",
        "value": "54771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Illinois",
        "value": "47598",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Illinois - Individual",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Iowa",
        "value": "J2345",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Iowa - DWP",
        "value": "KYCS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Kentucky",
        "value": "SCS17",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Maryland",
        "value": "37283",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Massachusetts",
        "value": "31182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Missouri",
        "value": "VPA18",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Montana",
        "value": "OSCAR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Nevada",
        "value": "IHS09",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of New Jersey",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of New York",
        "value": "OMN02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Oklahoma",
        "value": "NW002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Pennsylvania",
        "value": "J1836",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Puerto Rico",
        "value": "23253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Rhode Island",
        "value": "LNY01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of South Carolina in Missouri",
        "value": "MCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of South Dakota",
        "value": "59313",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Texas",
        "value": "24456",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Virginia",
        "value": "C1093",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of WA",
        "value": "48100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Washington DC",
        "value": "UHA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Washington State",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of West Virginia",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Dental of Wisconsin",
        "value": "J3760",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental of Wyoming",
        "value": "23045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Delta Dental Texas Childrens Medicaid Services",
        "value": "CPP09",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Delta Health Systems",
        "value": "99795",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DeltaCare USA - Claims",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DeltaCare USA - Encounters",
        "value": "27847",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dental Benefit Providers",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dental Choice Plan",
        "value": "CX091",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dental Choice Plan Plus",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dental Health & Wellness",
        "value": "05102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dental Select",
        "value": "MCDPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DentaQuest",
        "value": "00840",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DentaQuest –Government",
        "value": "38337",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dentegra",
        "value": "46299",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Denti-Cal",
        "value": "BHOVO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Denti-Cal DTI Encounter",
        "value": "20333",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Denver Health and Hospital Authority",
        "value": "99320",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Denver Health Medical Plan",
        "value": "39114",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Denver Health Medical Plan Inc.",
        "value": "DCENT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Denver Health Medical Plan, In. - Medicare Choice",
        "value": "76578",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Department of Corrections - Oklahoma",
        "value": "SCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Department of Social and Health Services (DSHS)",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Department Rehabilitative Services - Oklahoma",
        "value": "ALTAM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Depositors Insurance Co",
        "value": "56213",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Deseret Mutual Benefit Administrators",
        "value": "77894",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Desert Medical Group (Regal)",
        "value": "C1051",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Desert Oasis Healthcare (DOHC) (Regal)",
        "value": "SC050",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Desert Valley Medical Group",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Detego Health",
        "value": "10488",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Detroit Medical Center",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Devoted Health",
        "value": "H0657",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DHR Provider Management",
        "value": "25711",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Diabetes Heart Care CSNP",
        "value": "96277",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Diamond Bar Medical Group",
        "value": "SJHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Diamond Insurance Group, LTD",
        "value": "J2274",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Diamond Plan",
        "value": "77082",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Global",
        "value": "00901",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity HCLA",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dignity Health - Mercy Medical Group / Woodland Clinic",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Health - Sequoia Physicians Network",
        "value": "UMHCJ",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Health Medical Group - Inland Empire",
        "value": "46166",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Health Medical Network – Central California",
        "value": "J1580",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Health Medical Network – Santa Cruz",
        "value": "14312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dignity Health MSO",
        "value": "61604",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dignity Health Plan",
        "value": "412MP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dignity Health Sacramento Hospital SHO",
        "value": "J1590",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dimension Benefit Management",
        "value": "86304",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Diploma Joint Insurance Fund (SEL)",
        "value": "10275",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Direct Care Administrators",
        "value": "36401",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Direct Dental Administrators",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Directors Guild",
        "value": "16107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Director's Guild of America - Producer Health Plans",
        "value": "MPM06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Discount Tire",
        "value": "BHOVO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Disneyland Resorts (Conduent - Formerly Bunch CareSolutions)",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Diversified Administration Corporation",
        "value": "42140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Diversified Group Administrators",
        "value": "39144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Diversified Group Brokerage",
        "value": "00910",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DMERC Region A Medicare",
        "value": "73067",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DMERC Region B Medicare",
        "value": "DDMS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "DMERC Region C Medicare",
        "value": "13550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "DMERC Region D Medicare",
        "value": "PREHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Doctor’s Professional Services Consultants",
        "value": "A0701",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Doctors Healthcare Plans",
        "value": "J1379",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Doctors Managed IPA",
        "value": "16691",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Dominion Dental (115 S. Union St., Ste. 300, Alexandria, VA 22314)",
        "value": "45048",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Downey Select IPA",
        "value": "58174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dreyer Health",
        "value": "SNTCC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dreyer Medical Clinic",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Driscoll Childrens Health Plan",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dunn & Associates Benefits Administrators, Inc",
        "value": "47076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Dupage Medical Group",
        "value": "35605",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Early Intervention Central Billing",
        "value": "95386",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "East Bay Drainage Drivers Security Fund",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "East Carolina Behavioral Health",
        "value": "15060",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Eastern Iowa Community Healthcare (EICH)",
        "value": "RIOSA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Eastern Oregon CCO",
        "value": "EOCCO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EastGUARD Insurance Company - Guard DBA",
        "value": "66917",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eastland Medical Group IPA (Regal)",
        "value": "J2555",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eastman Kodak Company - CORVEL",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EastPointe Behavioral Health",
        "value": "21814",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Easy Access Care IPA",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Easy Care MSO LLC",
        "value": "VHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Easy Choice Health Plan of California (Encounters) - ECHP",
        "value": "88091",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Easy Choice Health Plan of California (Fee for Service) - ECHP",
        "value": "13317",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EasyAccess Care IPA (ProCare MSO)",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EBA &amp; M  Employee Benefits Administration and Management Corp",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EBC (Buffalo Rock) Birmingham",
        "value": "AUX01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eberle Vivian",
        "value": "38259",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eberle Vivian (Workers Comp)",
        "value": "00510",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ebix Health Administration Exchange (EHAE)",
        "value": "C1101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Edinger Medical Group",
        "value": "J1713",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Edison Health Solutions",
        "value": "J1447",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Edwards Claim Administration",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EHI, EHIC",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EHS Medical Group - Fresno",
        "value": "128VA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "El Camino Health Alliance",
        "value": "30120",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "El Camino Health Medical Network",
        "value": "00403",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "El Paso Community College (TX)",
        "value": "66901",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "El Paso First - CHIP",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "El Paso Water Utilities TX",
        "value": "HUMANA-OHMCD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "El Proyecto De Barrio",
        "value": "10044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ElderPlan, Inc.",
        "value": "37257",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Elderwood Health",
        "value": "MMFUC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Electric Insurance Company",
        "value": "PODIA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Electrical Welfare Trust Fund",
        "value": "J3957",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Electrical Workers Health and Welfare Plan for Northern Nevada",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Electrical Workers Insurance Fund Local 5800",
        "value": "CX015",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Electronic Practice Solutions, Inc.",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Element Care, Inc.",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ElmCare LLC",
        "value": "NAELM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Elmira Savings Bank (UCS)",
        "value": "74240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EMAEHIPA",
        "value": "12502",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Emanate Health IPA",
        "value": "FHKC1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Emanate Health Med Center AltaMed",
        "value": "BS002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Emanate Health Med Center NMM",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Emanate Health Med Center PDT MSO",
        "value": "50023",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Emblem Dental (DentaQuest)",
        "value": "55768",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EMCASCO Insurance Co - WC ONLY",
        "value": "COLTC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Emergency Medical Service Fund (EMSF)",
        "value": "J2104",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Emeritus Corporation",
        "value": "10174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EMHS Employee Health Plan",
        "value": "MR089",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EMI Health",
        "value": "48145",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EMI-KP Ambulance Claims",
        "value": "22348",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EMPHESYS",
        "value": "HMA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Empire Dental - Blue Cross Blue Shield (Anthem)",
        "value": "36404",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Empire Healthcare IPA",
        "value": "EHI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefit Concepts",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefit Corporation",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Benefit Logistics (EBL)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefit Management Corp (EBMC)",
        "value": "RCHSD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefit Management Services",
        "value": "59355",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Benefit Management Services (EBMS)",
        "value": "MLNNV",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Benefit Management, Inc. (EBSI)",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Benefit Services  - Ft. Mill, South Carolina",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefit Services (EBS of San Antonio)",
        "value": "62308",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefits administrators (EBA)",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Benefits Consultants",
        "value": "32002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Benefits Plan Administration (EBPA)",
        "value": "10322",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employee Health Coalition",
        "value": "35182",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Health Systems",
        "value": "DDCA2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employee Plans, LLC",
        "value": "99726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employer Direct Healthcare",
        "value": "TCPA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employer Driven Insurance Services",
        "value": "95019",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Assurance Company",
        "value": "20149",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Compensation Insurance Company",
        "value": "HFP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Direct Health",
        "value": "CHOC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employer's Direct Health - Self",
        "value": "J3748",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers General Insurance Group",
        "value": "J1846",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Health Insurance of Wisconsin",
        "value": "MPM19",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Health Network",
        "value": "SYMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Insurance (Bunch CareSolutions)",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Insurance Group (EIG)",
        "value": "64088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Life Insurance Corporation",
        "value": "21873",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Mutual Casualty - AUTO ONLY",
        "value": "13079",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Mutual Casualty - LIAB ONLY",
        "value": "04911",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employers Mutual Casualty - WC ONLY",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Mutual Inc.",
        "value": "10956",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Employer's Mutual, Inc. (Jacksonville, FL)",
        "value": "J1563",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Employers Preferred Insurance Company",
        "value": "PM001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Empower Arkansas",
        "value": "12956",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Encircle PPO",
        "value": "RAIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Encompass (Subsidiary of Allstate)",
        "value": "35161",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Encore Health Network",
        "value": "10403",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Enstar Natural Gas, Group # P61",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Enterprise Group Planning",
        "value": "J1613",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ENTRUST",
        "value": "J1447",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eon Health",
        "value": "CDIA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Epic Assistance",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Epic Specialty Benefits (WPS)",
        "value": "KYCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EPN (Seton Health Plan)",
        "value": "CNSOR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Equian",
        "value": "THRIV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Equicor",
        "value": "BS059",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Equicor - PPO",
        "value": "DHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Equitable Plan Services",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ergon, Inc. - CORVEL",
        "value": "64088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Erin Group Administrators",
        "value": "13162",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Erisa Administrative Services",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ERMC",
        "value": "39026",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ES Beveridge & Associates",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ESIS Auto",
        "value": "SC052",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ESIS Workers Comp",
        "value": "GCHCA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Esperanza IPA",
        "value": "13350",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Essence Healthcare",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Essential Health Partners",
        "value": "03036",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Essential Health Partners IPA",
        "value": "72107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Essex Dental Benefits",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Esurance Insurance Company",
        "value": "CRI01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Everence",
        "value": "75273",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Evergreen Health",
        "value": "84103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Evergreen Health Cooperative",
        "value": "00243",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "EverNorth Behavioral Health",
        "value": "68047",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Evolutions Healthcare Systems",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Exceedent LLC",
        "value": "23285",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Excel MSO",
        "value": "SPOHN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Excel MSO UHC PPO",
        "value": "ONOCI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Exceptional Care Medical Group (ECMG)",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Exclusicare",
        "value": "22603",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Exclusive Care",
        "value": "CB951",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Exclusive Care - County of Riverside",
        "value": "EBA42",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Exemplar Health",
        "value": "26545",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Exemplar Health Insurance Company",
        "value": "MCDMT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Express, LLC - CORVEL",
        "value": "88875",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Extended MLTC",
        "value": "27905",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ExxonMobil Corporation - CORVEL",
        "value": "31135",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eye Management Inc.",
        "value": "45437",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eyecare 2020",
        "value": "J1897",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Eyenez Eye Institute",
        "value": "99795",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "EyeQuest",
        "value": "MMPHB",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "F40 Alaska Carpenters Trust",
        "value": "54771",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "FABOH (CHP RPU)",
        "value": "MC006",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Facey Medical Foundation",
        "value": "15202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Factory 2 U",
        "value": "MCDAK",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fair Price",
        "value": "26158",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Fairchild Fasteners - CORVEL",
        "value": "22284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Fairfax County Government - CORVEL",
        "value": "19439",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fairmont Specialty Insurance Company - TX",
        "value": "39045",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fairview Health Services",
        "value": "13185",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Falling Colors (BHSD STAR)",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fallon Community Health",
        "value": "63985",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fallon Health (Transplant Claims ONLY)",
        "value": "FT254",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fallon Total Care",
        "value": "TXABA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fallon Transplant",
        "value": "SC10002123",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Care Medicaid",
        "value": "68251",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Care Specialists (FCS)",
        "value": "71804",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Choice Medical Group",
        "value": "41204",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Dental Care, Inc.",
        "value": "55843",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Health Alliance",
        "value": "CALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Health Network",
        "value": "37278",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Family Medical Network",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Family Practice Medical Group",
        "value": "MCDDE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Family Seniors Medical Group",
        "value": "00139",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farm Bureau Mutual Insurance Company",
        "value": "SVA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmers \\ Civic Property and Casualty Company",
        "value": "56155",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmers \\ Exact Property and Casualty Company",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Farmers \\ Fire Insurance Exchange",
        "value": "38238",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Farmers \\ Mid-Century Insurance Company",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Farmers \\ Neighborhood Spirit Property and Casualty Company",
        "value": "AAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmers \\ Truck Insurance Exchange",
        "value": "MR025",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Farmers Insurance",
        "value": "15003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmer's Insurance (Commercial)",
        "value": "00186",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmers Insurance Exchange",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Farmers of Flemington",
        "value": "IVHPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Farmland Mutual Insurance Co",
        "value": "NW002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "FCCI ",
        "value": "PEAK0",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FCE Benefit Administrators",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FCS Administrators",
        "value": "21805",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Federal Employee Plan of South Carolina",
        "value": "44602",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Federal Employees Plan WA (Regence Blue Shield)",
        "value": "37287",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Federal Mogul - CORVEL",
        "value": "06105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Federated Mutual Insurance Company",
        "value": "48888",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Federated Mutual Insurance Company (WC only)",
        "value": "61103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Federated Rural Electric (Tx)",
        "value": "10202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Federated Services Insurance Company",
        "value": "19430",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fenix Medical Group",
        "value": "94265",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fidelis Care New York",
        "value": "RIOSA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fidelity Mohawk",
        "value": "J3752",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Firefly",
        "value": "73780",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Fireman's Fund Insurance Company",
        "value": "00804",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Ameritas Life Insurance Corp. of NY",
        "value": "46050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Carolina Care",
        "value": "40137",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Choice Health Administrators (FCHA)",
        "value": "MPM60",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Choice Health Network (FCHN)",
        "value": "SAN01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Choice MA Plans",
        "value": "00660",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Choice Medical Group",
        "value": "37276",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Choice Medical Group/Meritage",
        "value": "J2361",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Choice VIP Care Plus SC",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Dental Health (CA)",
        "value": "J1588",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Great West Life & Annuity Ins. Co.",
        "value": "VAC45",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Health Network (f.k.a. CCN Managed Care Inc. & PPO Oklahoma)",
        "value": "77814",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Keystone Risk Retention Group",
        "value": "BSCA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Managed Care Option, Inc",
        "value": "46923",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Priority Health (FPH) - Highmark",
        "value": "61115",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Priority Life Insurance Company (FPLIC) - Highmark",
        "value": "62777",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Reliance Standard",
        "value": "54763",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "First Responder Joint Insurance Fund",
        "value": "47083",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "First Source (Endeavor)",
        "value": "47738",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Firstcare (Medicaid)",
        "value": "58102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Firstcare and Firstcare Medicaid",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Firstcomp",
        "value": "SJHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "FirstMedicare Direct",
        "value": "37321",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FirstNation Health",
        "value": "76342",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FirstSolutions",
        "value": "89461",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fitzharris & Company, Inc.",
        "value": "07689",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FKI Industries, Inc. ",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Blue HMO",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Community Care",
        "value": "65074",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Complete Care",
        "value": "54763",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Health Administrators",
        "value": "81044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Florida Health Care Plan",
        "value": "45341",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Health Solution HMO Company (Commercial ACA Metal Plans)",
        "value": "100709",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Florida Healthcare Plan",
        "value": "52132",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florida Hospital Orlando VBR",
        "value": "HCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Florida Hospital Waterman",
        "value": "80900",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Florida Pace Centers Inc",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Florida True Health",
        "value": "J3869",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florists Insurance Company",
        "value": "32043",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Florists Mutual Insurance Company",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Flour Bluff ISD",
        "value": "41600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Flume Health, Inc",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "FMH Benefit Services, Inc.",
        "value": "00570",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "FMI",
        "value": "78857",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Focus Plan NC",
        "value": "JCARE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Foothill Eye Care Services",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ford Motor Company",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Foreign Service Benefit Plan",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Forest County Potawatomi Insurance Department",
        "value": "INCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Foundation for Medical Care of Tulare & Kings County",
        "value": "77046",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Foundation Health HMO",
        "value": "SCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fountain Valley IPA (FVIPA)",
        "value": "13010",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fountain Valley Premier IPA",
        "value": "37278",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Fox Valley Medicine Site 199",
        "value": "10629",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Franciscan Alliance - CORVEL",
        "value": "WELM2",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Frankenmuth Mutual",
        "value": "09830",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Franklin Mutual",
        "value": "AHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Freedom Blue",
        "value": "82275",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Freedom Claims Management",
        "value": "14165",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Freedom Health Plan",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Freedom Life Insurance Company",
        "value": "09830",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fresno PACE",
        "value": "MPM59",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Friday Health Plans",
        "value": "27034",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Friedkin Companies, Inc - CORVEL",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fringe Benefit Group",
        "value": "36342",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Austin)",
        "value": "TDDIR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Houston)",
        "value": "COPAT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fringe Benefit Management (FBMC)",
        "value": "10045",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Fringe Benefits Coordinators",
        "value": "IP097",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "FrontPath Health Coalition",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Fullerton Union High School District (CA) - CORVEL",
        "value": "54138",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gainsco",
        "value": "ENDVR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Gallagher Bassett Services, Inc.",
        "value": "MEDM1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Galveston County Indigent Health Care",
        "value": "MCDAR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Garretson Resolution Group",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gateway Health Plan",
        "value": "94320",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gateway Health Plan - Medicare Assured",
        "value": "LIL01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gateway Health Plan (Medicaid PA)",
        "value": "10064",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Gateway IPA (Pinnacle Health Resources)",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Gateway to Better Health Plan - MO Medicaid Plan FFS",
        "value": "CPNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GE Auto - CORVEL",
        "value": "54398",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GEHA – Aetna Signature Administrators",
        "value": "12T47",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GEHA – ASA",
        "value": "MCRIL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GEHA - Government Employees Hospital Association",
        "value": "75309",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GEHA Group Health (Nevada)",
        "value": "IHS04",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Geico Insurance",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Geisinger Health Plan",
        "value": "CCMHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Geisinger Health Plans",
        "value": "73143",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GemCare Health Plan, Kern County EPO",
        "value": "BHOMA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GEMCare Medical Group (Managed Care Systems)",
        "value": "63100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Genelco / Gencare Health",
        "value": "66901",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "General Hospital (Humboldt Del Norte)",
        "value": "62308",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "General Parts International",
        "value": "37281",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "General Vision",
        "value": "41202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Generations Healthcare",
        "value": "11502",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Genesis Healthcare (EMG)",
        "value": "13193",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Georges Inc. - CORVEL",
        "value": "J3963",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Georgia Health Advantage",
        "value": "77070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Georgia Medicaid - Grady Healthcare, Inc.",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GHA Orthopedic Providers Inc.",
        "value": "VALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GHI Emblem Health                                                       ",
        "value": "J1687",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GI Innovative Management",
        "value": "61425",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Giatros Health IPA",
        "value": "11730",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GIC Indemnity Plan",
        "value": "62160",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gillette Childrens Specialty Healthcare - CORVEL",
        "value": "BC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Gilsbar",
        "value": "22483",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Glendale Adventist Medical Center",
        "value": "87605",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Glendale Memorial Group (Regal)",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Glendale Physicians Alliance (Regal)",
        "value": "68423",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Global Benefits Group",
        "value": "00910",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global Care Inc.",
        "value": "51062",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Global Care Medical Group IPA",
        "value": "88027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global Excel Management",
        "value": "NMM02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Global Health",
        "value": "92579",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global Healthcare Alliance",
        "value": "J1437",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Global Liberty Insurance Company of New York",
        "value": "44219",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global One Ventures",
        "value": "94123",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global TBSP",
        "value": "IHORG",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Global Women's Health Providers Inc.",
        "value": "PROSP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GMAC Insurance Management Corporation",
        "value": "77022",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GMMI CC",
        "value": "J1680",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GMR Healthcare",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Gold Coast",
        "value": "47181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Golden Bay Health Plan",
        "value": "37233",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Golden Coast MSO",
        "value": "66005",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Golden Eagle ",
        "value": "31114",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Golden Physicians Medical Group",
        "value": "00960",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Golden State Medicare Health Plan",
        "value": "26375",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Golden Triangle Physician Alliance (GTPA)",
        "value": "ATPA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Golden West Dental (888 W. Vantura Blvd., Camarillo, CA)",
        "value": "49578",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Good Samaritan Hospital (GSH)",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Good Samaritan Medical Practice Association (GSMPA)",
        "value": "J3860",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Good2go Insurance (aka Personal Service Insurance)",
        "value": "CARMO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Goodfellow Top Grade Construction LLC",
        "value": "95112",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Goodrich",
        "value": "IHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Government Employees Hospital Association (GEHA)",
        "value": "THS01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Grady Healthcare - Georgia Medicaid",
        "value": "54771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Grand River Insurance (Auto only)",
        "value": "86304",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Grand River Insurance (WC only)",
        "value": "94320",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Grange Mutual Casualty Company",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Grange WC",
        "value": "UHP01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Granite State - Medical",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GRAVIE",
        "value": "MR018",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gravie Inc",
        "value": "JMH01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Great American Alliance Ins Co. ",
        "value": "59064",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Great American Assurance Co.",
        "value": "92805",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Great American Ins. Co.",
        "value": "10322",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Great American Ins. Co. of NY",
        "value": "77153",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Great Divide Insurance",
        "value": "4YC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Great Lakes PACE",
        "value": "C1099",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Great Plains Medicare Advantage",
        "value": "MDXHI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Great States Health IICT",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Great West Health Care",
        "value": "VAC45",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Greater Covina Medical Group (GCMG) (Regal)",
        "value": "MPM61",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Greater L.A. Healthcare",
        "value": "59064",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Greater Newport Physicians",
        "value": "33632",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Greater Orange Medical Group",
        "value": "58204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Greater Oregon Behavioral Health Inc",
        "value": "36878",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Greater San Gabriel Medical Group",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Greater San Gabriel Valley Physicians",
        "value": "ASFL1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Greater Tri-Cities IPA",
        "value": "74095",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Great-West Life & Annuity Insurance Company",
        "value": "AMM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Green Diamond WA - CORVEL",
        "value": "20133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Greentree Administrators",
        "value": "EAIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Grinnell Reinsurance Company",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Grinnell Select - CORVEL",
        "value": "36396",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group & Pension Administrators",
        "value": "64071",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Administrators Ltd.",
        "value": "U7632",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Benefit Services",
        "value": "26224",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Benefit Services, Inc. (Missouri)",
        "value": "HDNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Benefits - Louisiana",
        "value": "AXM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Health Cooperative",
        "value": "SCHAR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Health Cooperative (GHC)",
        "value": "CB159",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Health Cooperative of Eau Claire",
        "value": "88337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Health Cooperative of South Central Wisconsin (Claims)",
        "value": "DCRWP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Health Cooperative of South Central Wisconsin (Encounters)",
        "value": "ANTHEM-OHMCD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Health Options (Pacific Northwest)",
        "value": "41204",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Health, Inc. (GHI HMO) (Emblem)",
        "value": "75261",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Health, Inc. (GHI PPO) (Emblem)",
        "value": "AMG02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Insurance Service Center",
        "value": "MMFUC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Management Services, Inc. (GMS)",
        "value": "J3297",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Marketing Services, Inc.",
        "value": "MHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Group Practice Affiliates",
        "value": "39112",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Group Resources",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GroupLink",
        "value": "52429",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Guaranty Fund Management Services",
        "value": "54763",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Guard",
        "value": "CC16E",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Guardian Life (The Guardian)",
        "value": "J1834",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "GuideOne Elite Insurance",
        "value": "CHBU01653",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "GuideOne Mutual Insurance",
        "value": "ADABS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Guidewell Mutual Holding - CORVEL",
        "value": "CX052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Guildnet",
        "value": "MCDCT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gulf Coast Physician Network (MedView)",
        "value": "C1028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gulf Guaranty or MedPlus",
        "value": "SVA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gulf Quest IPA HMO Blue",
        "value": "89789",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gulf South",
        "value": "L0230",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Gulf Stream (CoreSource)",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Guy Yocomo Construction",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "H & A  Administrators",
        "value": "88051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "H & R Block",
        "value": "RCHN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "H.E.R.E Health Trust",
        "value": "95379",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HAA Preferred Partners",
        "value": "AMM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Halcyon Behavioral Health",
        "value": "61115",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hamaspik Choice",
        "value": "31401",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hana Hou Medical Group",
        "value": "22603",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hannaford",
        "value": "BSHS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hanover Insurance Company",
        "value": "45221",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hanover Lloyd's Insurance Company",
        "value": "84562",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HAP Midwest Health Plans",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HAP/AHL/Curanet",
        "value": "61325",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Harbor Health Plan (ProCare)",
        "value": "AVA02",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Harleysville Insurance Company",
        "value": "48026",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Harmony Health Plan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Harrington Health - Kansas (Formerly Fiserv/Willis)",
        "value": "65085",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Harrington Health Non-EPO",
        "value": "39144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hartford Accident and Indemnity Company",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hartford Casualty Insurance Company",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hartford Insurance Company of the Midwest",
        "value": "00800",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hartford Life and Accident Insurance Company",
        "value": "20554",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hartford Life and Annuity Insurance Company",
        "value": "41201",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hartford Life Insurance Company",
        "value": "AMG02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hartford Underwriters Insurance Company",
        "value": "HLTHT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Harvard Community Health Plan (AKA Harvard Pilgrim Stride)",
        "value": "RCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Harvard Pilgrim Health Care",
        "value": "VAFSC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Harvard Pilgrim Passport Connect",
        "value": "52312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hawaii Employers' Mutual Insurance Co., Inc",
        "value": "95998",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hawaii Management Alliance Association (HMAA)",
        "value": "11150",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hawaii Medical Alliance Association (HMAA)",
        "value": "ABRI1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hawaii Medical Assurance Association (HMAA)",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hawaii Western Management Group (HWMG)",
        "value": "MHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hawkeye ",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HCA - Hospital Corporation of America ",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HCC Life Insurance",
        "value": "59266",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HCH Administration - Illinois",
        "value": "03443",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HCH Administration, Inc.",
        "value": "80095",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HCS - Health Claims Service",
        "value": "ALLCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HDPC - Premier Care (High Desert Primary Care Premier)",
        "value": "10569",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health 2 Business",
        "value": "J1998",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Alliance Medical Plan",
        "value": "RCHN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Alliance Plan of Michigan",
        "value": "FCMA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Assurance - Health America, Inc.",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Care District Palm Beach",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Care Facilities of Missouri, Inc. - CORVEL",
        "value": "93688",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Care LA IPA (HCLA)",
        "value": "55768",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Choice Generations",
        "value": "99660",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Choice Integrated Care (HCIC)",
        "value": "09830",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Choice of Arizona",
        "value": "07202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Choice of Utah",
        "value": "MPM34",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Choices",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Comp",
        "value": "CVH01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Cost Solutions (HCS)",
        "value": "88023",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Design Plus - Hudson, Ohio",
        "value": "AUMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Economic Livelihood Partnership (HELP)",
        "value": "GRV01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Economics Group Inc.",
        "value": "00601",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health EOS",
        "value": "10693",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Excel IPA",
        "value": "HPPO1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health First Colorado (Medicaid)",
        "value": "60995",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health First Health Plans",
        "value": "00611",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Insurance Plan (HIP) of Greater New York (Emblem)",
        "value": "47080",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Net - California",
        "value": "CB951",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Net - Medicare Advantage (MA) / Individual Family Plan (IFP)",
        "value": "13413",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Net - Missouri",
        "value": "35174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Net - Oregon",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Net - Washington",
        "value": "J1663",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Net National",
        "value": "13174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Net of the Northeast",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network One",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Network Solutions (HNS) - Absolute Total Care SC",
        "value": "RHP01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Network Solutions (HNS) - BCBS NC",
        "value": "SCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network Solutions (HNS) - CIGNA NC",
        "value": "10301",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network Solutions (HNS) - CIGNA SC",
        "value": "75234",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network Solutions (HNS) - HealthSpring NC",
        "value": "95467",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Network Solutions (HNS) - HealthSpring SC",
        "value": "MCDVT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Network Solutions (HNS) - MedCost NC",
        "value": "56089",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network Solutions (HNS) - MedCost SC",
        "value": "HMC02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Network Solutions (HNS) - Select Health SC",
        "value": "95567",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Network Solutions (HNS) HealthTeam Advantage NC",
        "value": "DX215",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health New England",
        "value": "07689",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Now New York",
        "value": "MCDLA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Options (Florida Blue)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Partners of Minnesota",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Partners Pennsylvania",
        "value": "00630",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Partners Plans (Philadelphia, PA)",
        "value": "ARMC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Payment Systems, Inc.",
        "value": "60065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Plan Management",
        "value": "FLCCR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Plan of Michigan",
        "value": "66004",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Plan of Nevada",
        "value": "IP083",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plan of Nevada - Encounters",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plan of San Joaquin",
        "value": "37602",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Plan of San Mateo",
        "value": "93222",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plan of San Mateo Care Advantage (HMO)",
        "value": "CCHPC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plan Services",
        "value": "00870",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Plans, Inc.",
        "value": "89461",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plus PHSP",
        "value": "HDNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Plus Physician Organization (HPPO)",
        "value": "41124",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Resources, Inc.",
        "value": "52147",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Services for Children with Special Needs (HSCSN)",
        "value": "J1786",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Services Management – HSM",
        "value": "08680",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Share CCO Clackamas County",
        "value": "77170",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Source MSO",
        "value": "COCHA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Health Special Risk, Inc.",
        "value": "89245",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Systems International - ECOH",
        "value": "68046",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Texas Medical Group",
        "value": "36389",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health Tradition Health Plan",
        "value": "10353",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Health York Network",
        "value": "12475",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthBridge",
        "value": "99726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthcare Group, The",
        "value": "91617",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthcare Highways",
        "value": "MCDOK",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthcare Highways Health Plan",
        "value": "NCXIX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthcare Management Administrators (HMA)",
        "value": "ORDHS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthcare Options",
        "value": "MPM64",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare Partners IPA - New York",
        "value": "WVCS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare Partners of Nevada",
        "value": "SCPR1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare Resources NW",
        "value": "66170",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthcare Risk Management Solutions - CORVEL",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare Solutions Group",
        "value": "30166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare USA",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCare's Finest Network (HFN)",
        "value": "11001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthChoice of Arizona",
        "value": "J1046",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthChoice Oklahoma",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthChoice Select",
        "value": "89234",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthComp",
        "value": "IUHPL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "\"HealthCosmos Medical Group LLC",
        "value": "\"",
        "AveCallholdtime": "35202"
    },
    {
        "label": "HealthCosmos of Nevada PLLC",
        "value": "10030",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthCosmos of New Mexico LLC",
        "value": "09908",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthEdge Administrators",
        "value": "CH00102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthEQ Westlake Medical Center",
        "value": "PHMD3",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthEZ",
        "value": "43471",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthfirst - New York",
        "value": "20333",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthFirst (HMO)",
        "value": "87071",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthfirst 65 Plus",
        "value": "54096",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthfirst Family Health Plus (FHP)",
        "value": "80141",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthfirst Health Plan of New Jersey",
        "value": "06202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthfirst Inc",
        "value": "43905",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthfirst of New Jersey",
        "value": "C1083",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthfirst of New York",
        "value": "ARISE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthfirst PHSP",
        "value": "35193",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthFirst TPA",
        "value": "75289",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthfirst Tyler, Texas",
        "value": "TAYLR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthgram",
        "value": "J1028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthGroup Limited",
        "value": "TLINS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthlink PPO",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthNet – Gov’t Program",
        "value": "87020",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthPlan Services",
        "value": "CCHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthplex",
        "value": "39156",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthPlus Managed Long Term Care",
        "value": "63092",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthPlus of Louisiana",
        "value": "EC999",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthPlus of Michigan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSCOPE Benefits, Inc.",
        "value": "10088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthScope UMR",
        "value": "46045",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthShare CCO Dental",
        "value": "HSCD1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthShare of Oregon CCO - Multnomah (Formerly known as Verity)",
        "value": "00224",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSmart Accel",
        "value": "MR006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthSmart Benefit Solutions (formerly American Administrative/Gallagher Benefit)",
        "value": "MPM44",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSmart Benefit Solutions (formerly JSL Administrators)",
        "value": "J1950",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthSmart Benefit Solutions (Formerly Pittman and Associates)",
        "value": "95019",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthSmart Benefit Solutions (formerly Wells Fargo TPA/Acordia National)",
        "value": "C1SCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSmart MSO",
        "value": "66171",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSmart Preferred Care",
        "value": "UIC67",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthsource (CIGNA)",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthsource CMHC",
        "value": "SHCAR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSpan Inc",
        "value": "128FL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSpring (formerly Bravo Health/Elder Health)",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSpring HMO",
        "value": "94146",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSpring Medicare + Choice",
        "value": "E4618",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthSpring of Alabama",
        "value": "TN000214",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthsun Health Plan",
        "value": "MLNUT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HealthTeam Advantage",
        "value": "59374",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HealthTeam Advantage Diabetes and Heart Care Plan",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthways WholeHealth Network",
        "value": "88057",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy Blue LA",
        "value": "14966",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthy Blue Missouri",
        "value": "06105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthy Blue Nebraska",
        "value": "93220",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthy Blue North Carolina",
        "value": "37264",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthy CT",
        "value": "CX100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Healthy Equation",
        "value": "77943",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy Families Program",
        "value": "CHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy Montana Kids (HMK)",
        "value": "00661",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy Palm Beaches, Inc.",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy San Francisco",
        "value": "PHM11",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Healthy Valley Provider Network",
        "value": "MHPMI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Heartland Express - CORVEL",
        "value": "26375",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Helmsman Management Services",
        "value": "SPA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hemet Community Medical Group (HCMG)",
        "value": "J1011",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hennepin Health (formerly Metropolitan Health Plan)",
        "value": "GHOKC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hereford Insurance",
        "value": "J1595",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Heritage Consultants",
        "value": "55204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Heritage IPA",
        "value": "00710",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Heritage Physicians Network (HPN)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Heritage Provider Network (Regal)",
        "value": "CCHPC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Heritage Victor Valley Medical Group (Regal)",
        "value": "71064",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hernando Pasco Hospice",
        "value": "23229",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hershey Healthsmile",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hewitt Coleman",
        "value": "J1780",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HHS National Disaster Medical System-Apprio",
        "value": "04293",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "High Desert Medical Group (Regal)",
        "value": "SOLIS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Highline MSO - Molina",
        "value": "10477",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Highmark BCBS of Western New York",
        "value": "J1906",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Highmark Health Options",
        "value": "DOCSO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Highmark Senior - Freedom Blue PPO (Pennsylvania)",
        "value": "12394",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Highmark Senior Health Company (Pennsylvania)",
        "value": "SC051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Highmark Senior Solutions Company - West Virginia",
        "value": "61101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hill Physicians Aetna PPO",
        "value": "25169",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians Blue Cross PPO",
        "value": "41124",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians Blue Shield PPO",
        "value": "31650",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians Cigna PPO",
        "value": "76045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians Health Net PPO",
        "value": "RGA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians Medical Group (HPMG)",
        "value": "03443",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hill Physicians United Healthcare PPO",
        "value": "BKRFM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hill UHCSR PPO",
        "value": "C1085",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hilliard Corporation (UCS)",
        "value": "HESUN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hillshire Brands - CORVEL",
        "value": "MR008",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hilmar Cheese Company - CORVEL",
        "value": "24735",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hinsdale Medicare Advantage",
        "value": "23212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hinsdale Physicians Healthcare",
        "value": "UMHCJ",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HIP Healthplan of NY",
        "value": "J1601",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hispanic Physician IPA (Encounters Only)",
        "value": "BS001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hispanic Physicians IPA (Fee For Service Only)",
        "value": "13550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HK Administrative Partners LLC",
        "value": "ASP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HMA Hawaii",
        "value": "86066",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HMC HealthWorks",
        "value": "ZH2C",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HMO LA Blue Advantage",
        "value": "C1086",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HMO Louisiana Blue Advantage",
        "value": "J1250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HMS Vineyards - CORVEL",
        "value": "89247",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HMSA Quest",
        "value": "HWC02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HMSHost - CORVEL",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HN1 Therapy Network",
        "value": "99660",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "\"HNS WellCare SC",
        "value": "\"",
        "AveCallholdtime": "EIPA9"
    },
    {
        "label": "Hoag Affiliated Physicians",
        "value": "48143",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hoag Clinic (Hoag Physician Partners)",
        "value": "MCCVA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hockenberg Equipment",
        "value": "HCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Holista, LLC",
        "value": "53684",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hollywood Pres GLOBAL",
        "value": "CCHPL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hollywood Presbyterian Medical Center - Preferred (HPMC - PMG)",
        "value": "76498",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hollywood Presbyterian Medical Center - San Judas IPA (HPMC - SJM)",
        "value": "93092",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hollywood Presbyterian Medical Center - St. Vincent's IPA (HPMC - SVI)",
        "value": "88029",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hollywood Presbyterian Medical Group",
        "value": "BS059",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hollywood Presbyterian San Judas",
        "value": "46594",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Home State - Medical",
        "value": "GARET",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Homeland Security (AKA ICE Health)",
        "value": "37272",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HomeLink",
        "value": "J3950",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Homelink HealthPartners",
        "value": "M3CA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hometown Health Plan Nevada",
        "value": "07503",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Homewood Resorts",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Horizon BCBS of New Jersey",
        "value": "20044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Horizon Casualty Services",
        "value": "35206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Horizon NJ Health",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Horizon Valley Medical Group",
        "value": "77039",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hortica",
        "value": "00851",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hortica Florist Mutual Insurance",
        "value": "58213",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hospital Housekeeping Systems (HHS) - CORVEL",
        "value": "J1562",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hotel Employees & Restaurant Employees Health Trust, Group # F19",
        "value": "62111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Housing Authority El Paso (TX)",
        "value": "55413",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Houston Hunters Patrol, Inc.",
        "value": "00541",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Howard County Government (MD) - CORVEL",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HSA Health Insurance Company",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "HSI Care Services",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "HSM - Health Services Management",
        "value": "45289",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hudson Health Plan (now MVP)",
        "value": "MPM54",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Human Behavior Institute",
        "value": "59140",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana",
        "value": "95432",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Behavioral Health (formerly LifeSynch)",
        "value": "65391",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Humana Encounters",
        "value": "LDP24",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Gold Choice",
        "value": "VS402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Humana Gold Plus",
        "value": "CH00102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Health Plans of Ohio",
        "value": "75318",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Humana Long Term Care (LTC)",
        "value": "84103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Humana LTSS Claims",
        "value": "38310",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Ohio 2",
        "value": "12251",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Ohio Medicaid",
        "value": "VPA18",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humana Puerto Rico",
        "value": "WELM2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Humboldt Del Norte Foundation IPA",
        "value": "VUIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Huntington Park Mission Medical Group",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Hutchinson Technology - CORVEL",
        "value": "H0657",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Hylton Payroll (Benefit Plan Administrators)",
        "value": "10207",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "I. E. Shaffer",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IAC Life",
        "value": "HMAA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IBC Personal Choice",
        "value": "58204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IBEW Local 6  - San Francisco Electrical Workers",
        "value": "89890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IBEW Local 640 & AZ Chapter NECA Health & Welfare Trust",
        "value": "CB404",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IBEW: Local No. 1 Health and Welfare Fund (Mental Health)",
        "value": "SYMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IBG Administrators, LLC",
        "value": "CPNCO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "iCARE (Independent Care Health Plan)",
        "value": "J1417",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ICare Health Options",
        "value": "IHS06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ichahn Automotive Group (Pep Boys & AutoPlus) - CORVEL",
        "value": "60550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "iCircle of New York",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IDS Property Casualty",
        "value": "06111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IDS Property Casualty Insurance Company",
        "value": "3519M",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IEHP Dental (Admin by LIBERTY Dental Plan)",
        "value": "45210",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IHC Health Solutions",
        "value": "C1045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IHG Direct",
        "value": "F2370600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Illinois Casualty",
        "value": "USMBP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Illinois Health Plans (IHP)",
        "value": "52312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ILWU Local 21 (FCHN)",
        "value": "J3759",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMA (Cotiva)",
        "value": "J2326",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMA, Inc",
        "value": "00960",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMCare",
        "value": "65085",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Impact Health Share Inc.",
        "value": "IP082",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Imperial Health Holdings Medical Group",
        "value": "04312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Imperial Health Plan of California Encounters",
        "value": "37275",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Imperial Health Plan of California, Inc.",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Imperial Insurance Companies",
        "value": "CHA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Imperial Insurance Companies INC EXCHANGE AZ",
        "value": "J3845",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Imperial Insurance Companies INC EXCHANGE TX",
        "value": "38512",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Imperial Insurance Encounters",
        "value": "UGP19",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IMS - Trial Card (TC-IMS)",
        "value": "39026",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMS Management Services - Texas",
        "value": "91313",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMS TPA",
        "value": "CPNCO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IMS TPA: Parton Lumber (UCS)",
        "value": "00923",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMS TPA: The Spencer Group",
        "value": "MMMFL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IMS TPA: Walker White, Inc. (UCS)",
        "value": "20133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IMT",
        "value": "NMM07",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IN Physician Associates",
        "value": "HCHHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "In Physicians Associates – ACTY",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IncentiCare",
        "value": "11695",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "INDECS Corporation",
        "value": "MCDAZ",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence Administrators",
        "value": "54763",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence American, Ins. Co.",
        "value": "85468",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence Blue Cross",
        "value": "77032",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence Care Systems / FIDA-MMP",
        "value": "00851",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence Medical Group - Kern County",
        "value": "J1810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independence Medical Group - Tulare County ",
        "value": "84146",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Independent Health",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Independent Living System",
        "value": "MMFMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indian Health Services / Veteran Affairs",
        "value": "QCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indiana Farmers Mutual - CORVEL",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indiana Insurance",
        "value": "UHA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indiana ProHealth Network",
        "value": "76253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indiana University (IU) Health Plan – Commercial/Marketplace",
        "value": "HCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Indiana University (IU) Health Plan – Medicare Advantage",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Individual Health Insurance Companies",
        "value": "83028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "iNetico Inc",
        "value": "71890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Infinity IPA",
        "value": "PRNLK",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ingham Health Plan Corp.",
        "value": "FT254",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ingles Markets",
        "value": "11329",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Inland Empire Electrical Trust (FCHN)",
        "value": "LIFE1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Inland Empire Health",
        "value": "17882",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Empire Health Medicare",
        "value": "PROH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Empire Health Plan",
        "value": "LDP19",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Empire Medi-cal",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Faculty Medical Group (MV Medical)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Inland Faculty Medical Group Encounters (MV Medical)",
        "value": "ARGUS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Health Organization (IHO)",
        "value": "100707",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inland Valley - (Redlands IPA)",
        "value": "PROH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "InnovAge",
        "value": "3519M",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovante Benefit Administrator",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovation Health (Aetna)",
        "value": "J4305",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovative Care Systems (ICS) - San Ramon",
        "value": "HPPZZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Innovative Claim Solutions (ICS) - Rancho Cordova - CORVEL",
        "value": "37105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovative Healthware Payer 6",
        "value": "05202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovative Payer 2",
        "value": "23334",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Innovative Payer 3",
        "value": "KELSE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "INS Health Services (AKA ICE Health)",
        "value": "REGAL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Inservco",
        "value": "INET1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Insurance Administrator of America, Inc. (IAA)",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Insurance Benefit System Administrators",
        "value": "MCDAR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Insurance Company of the West (ICW)",
        "value": "78521",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Insurance Design Administrators",
        "value": "20538",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Insurance Management Services",
        "value": "63092",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Insurance Program Managers Group (IPMG)",
        "value": "J1791",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Insurance Systems",
        "value": "00590",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Insurance TPA",
        "value": "PFIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Insurers Administrative Corp.",
        "value": "MPM55",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Integon General Insurance Corp.",
        "value": "09173",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Integra Administrative Group",
        "value": "38216",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Integra BMS",
        "value": "13010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Integra Group",
        "value": "66727",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Integral Quality Care",
        "value": "24818",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IntegraNet",
        "value": "FLCPC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Integrated Eye Care Network",
        "value": "94999",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Integrated Health Partners (IHP)",
        "value": "22417",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"Integrated Home Care Services, Inc.",
        "value": "\"",
        "AveCallholdtime": "68069"
    },
    {
        "label": "Integrated Medical Solutions",
        "value": "J1535",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Integrity Mutual Insurance Company",
        "value": "75261",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Inter Valley Health Plan (IVHP)",
        "value": "95567",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Interactive Medical Systems",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Inter-Americas Insurance",
        "value": "J1795",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intercare",
        "value": "ACPCE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intercare - Sussex",
        "value": "61184",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "InterCommunity Health CCO (IHN)",
        "value": "68035",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intercommunity Health Net",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intergovernmental Risk Management Agency (IRMA) - CORVEL",
        "value": "PCU01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Intergroup Services Corporation",
        "value": "37121",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Interinsurance Exchange of the Automobile Club - CORVEL",
        "value": "05655",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intermountain Ironworkers Trust Fund",
        "value": "41178",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "International Benefit Administrator",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "International Brotherhood of Boilermakers",
        "value": "73100",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "International Educational Exchange Service (IEES)",
        "value": "66010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "International Medica Group Inc",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "International Medical Group (IMG)",
        "value": "37307",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "International Union of Operating Engineers ~ Local 15, 15A, 15C & 15D",
        "value": "53122",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Intervalley",
        "value": "61101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "InterWest Health PPO",
        "value": "SB810",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Iowa Health Advantage",
        "value": "ACC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Iowa Total Care",
        "value": "LWA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "IPMG Claims Management Services",
        "value": "38333",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "IQVIA",
        "value": "66043",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ISS Facility Services - CORVEL",
        "value": "00806",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Itasca Medical Center",
        "value": "36345",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "J B Hunt Transport Inc.",
        "value": "77250",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "J. F. Molloy and Associates",
        "value": "31500",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "J5 National Part A",
        "value": "34080",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "JADE Health Care Medical Group",
        "value": "82056",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Jai Medical Systems",
        "value": "63240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Jefferson Pilot Financial",
        "value": "48330",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Jencare Medical",
        "value": "MHCM2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Jericho Share",
        "value": "65482",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "JI Specialty Services",
        "value": "22771",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "JLS Family Enterprises (dba League Medical Concepts)",
        "value": "06102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "John Hancock Health Security Plan",
        "value": "MPM51",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "John Muir Physician Network",
        "value": "CKC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "John Muir Trauma Physicians",
        "value": "87605",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "John P. Pearl & Associates, Ltd.",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Johns Hopkins Health Advantage",
        "value": "35098",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Johns Hopkins Healthcare",
        "value": "48100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Johns Hopkins Healthcare (USFHP)",
        "value": "13590",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Johns Hopkins Home Care Group",
        "value": "84132",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "JP Farley Corporation",
        "value": "RMC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "JP Specialities",
        "value": "54828",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "JWF Specialty",
        "value": "73236",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser CSI - California Select for Individuals",
        "value": "J2134",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Colorado",
        "value": "THRIV",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Georgia",
        "value": "L0230",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Mid-Atlantic",
        "value": "C1038",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Northern CA Region",
        "value": "25844",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Southern CA Region",
        "value": "OCN01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of the Mid-Atlantic States",
        "value": "12963",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of the Northwest",
        "value": "SC004",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Foundation Health Plan of Washington",
        "value": "89070",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Permanente Health Plan of Hawaii",
        "value": "86145",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Permanente of Northern California",
        "value": "60054",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Permanente of Southern California",
        "value": "65465",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kaiser Self-Funded",
        "value": "AMIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kalos Health",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "KAMG Hospital",
        "value": "J1972",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kane County IPA",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kansas Health Advantage (aka Kansas Superior Select)",
        "value": "94302",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Katy Medical Group",
        "value": "36149",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kaweah Delta HC District Emp Plan",
        "value": "43471",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kaweah Delta Medicare Advantage",
        "value": "36326",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keenan",
        "value": "76253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Keenan & Associates",
        "value": "HPSM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Keenan and Associates (Excluding City of Pasadena)",
        "value": "81400",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kellogg Companies - CORVEL",
        "value": "33373",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kelsey Seybold",
        "value": "61102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kempton Company",
        "value": "AVA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kempton Group Administrators",
        "value": "JCARE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kempton Group TPA: Kempton Group Administrators (UCS)",
        "value": "CB752",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kentucky Health Cooperative",
        "value": "ECMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kentucky Spirit Health - Medical",
        "value": "83028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kern County Healthy Kids",
        "value": "45319",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kern Health Care Network",
        "value": "ITXEN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kern Health Systems",
        "value": "VALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kern Legacy Health Plan",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Key Benefit Administrators",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Key Health Medical Solutions, Inc.",
        "value": "SX105",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Key Medical Group",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Key Medical Group - Medicare Advantage",
        "value": "COCHA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Key Risk Management Services LLC",
        "value": "J1941",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Key Select",
        "value": "CCPN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "KeyCare (MD)",
        "value": "AMM11",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Keystone Connect (AmeriHealth)",
        "value": "36273",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone First Community Health Choices",
        "value": "66705",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone First Dental - PA",
        "value": "AMM15",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Keystone First VIP Choice",
        "value": "HSICS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone Health (Lakeside - Glendale, CA) (Regal)",
        "value": "J1837",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone Health Plan Central",
        "value": "72128",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone Health Plan East (KHP)",
        "value": "EC1CA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone Mercy Health Plan",
        "value": "23284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Keystone West (Highmark)",
        "value": "84562",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "KG Administrative Services",
        "value": "J1799",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kikkoman Foods Inc - CORVEL",
        "value": "128MD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Kings County Healthy Kids",
        "value": "00851",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Klais & Company",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Klais & Company (Repricing for HealthSpan Network Only)",
        "value": "75250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "KM Strategic Management (KMSM)",
        "value": "HLTHQ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Koan Risk Solutions",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Koch Foods, Inc - CORVEL",
        "value": "128KY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Korean American Medical Group",
        "value": "95386",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kova Healthcare, Inc.",
        "value": "CCIH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "KPIC Self-Funded Claims Administrator",
        "value": "57116",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "KPS Healthplans",
        "value": "J1949",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Kuspuk School District",
        "value": "75261",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "LA Care Health Plan",
        "value": "22240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LACH HealthNet by MedPOINT",
        "value": "AHC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LADOC CorrectCare",
        "value": "ARCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lafayette Consolidated Government",
        "value": "MCDKY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lake Region Community Health Plan",
        "value": "99726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lakes Area Community Health Plan (LACH)",
        "value": "J1206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lakeside Community Healthcare (Regal)",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lakeside Medical Group (Regal Lakeside)",
        "value": "87716",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lakewood IPA",
        "value": "66005",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lancaster General Health (LGH)",
        "value": "92805",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Land of Lincoln Health",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Landmark",
        "value": "59065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Landrys - CORVEL",
        "value": "00205",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lapoyner ISD",
        "value": "76251",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Larson Group",
        "value": "C1043",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Las Vegas Firefighters Health and Welfare Trust",
        "value": "72630",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LaSalle Medical Associates",
        "value": "J1668",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lassen Municipal Utility District",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lasso Healthcare Msa",
        "value": "22729",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Laundry Workers",
        "value": "26375",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lawndale Christian Health Center",
        "value": "16117",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "LBA Health Plans",
        "value": "54704",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "LCTA Workers Comp",
        "value": "88848",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Le Mars Insurance Company",
        "value": "38310",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "League Medical Concepts (aka JLS Family Enterprises)",
        "value": "98022",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "League of Minnesota Cities Insurance Trust",
        "value": "VHPLA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Leon Health Plans",
        "value": "ARMC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Leon Medical Center Health Plan",
        "value": "CALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LFP Corporation - CORVEL",
        "value": "23287",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "LHP Claims Unit",
        "value": "PCU03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LHS/ MedCost Solutions LLC",
        "value": "20172",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Liberty Advantage",
        "value": "39168",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Liberty Dental Plan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Liberty Health Advantage",
        "value": "00239",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Liberty Mutual - Auto",
        "value": "CHA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Liberty Mutual Insurance Co",
        "value": "83245",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Liberty Mutual Middle Markets",
        "value": "85600",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Liberty Northwest",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Liberty Union",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Liberty Wausau",
        "value": "23626",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Life Assurance Company",
        "value": "RHA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Life Benefit Plan",
        "value": "10112",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Life Investors Insurance of America - Long Term Care",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Life St. Mary (Trinity Health Pace)",
        "value": "11705",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Life Trac",
        "value": "VACCN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LifeCare Assurance Co",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lifeshield National Insurance Co.",
        "value": "SCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lifetime Benefit Solutions",
        "value": "CCMMH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LifeWell Health Plans",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LifeWise Healthplan of Oregon (Premera)",
        "value": "32002",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "LifeWise Healthplan of Washington (Premera)",
        "value": "04271",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "LifeWorks Advantage (ISNP plan)",
        "value": "PMAI2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lighthouse Health Plan",
        "value": "10049",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lincoln National of Wisconsin",
        "value": "IHS08",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Linden Oaks Behavioral Health",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Link Snacks (Jacks Links)",
        "value": "SKUT0",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Little Company of Mary",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Live Well IPA",
        "value": "59322",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Local 137 Operating Engineers Welfare Fund",
        "value": "42172",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Local 670 Engineers",
        "value": "LACAR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Local 670 Engineers VIP",
        "value": "00154",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Local 670 VIP",
        "value": "CCHP9",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lockard & Williams",
        "value": "IP082",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loma Linda University",
        "value": "AMM17",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loma Linda University Adventist Health Sciences Center",
        "value": "J2282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Loma Linda University Adventist Health Sciences Centers",
        "value": "46923",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loma Linda University Behavorial Medicine Center Employee Health Plan",
        "value": "10301",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loma Linda University Dept of Risk Management",
        "value": "RENGQ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loma Linda University Healthcare - ManagedCare Claims",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Loma Linda University Healthcare Employee Health Plan",
        "value": "41556",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lone Star Medical Group PLLC ",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lone Star TPA",
        "value": "91062",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Long Beach Memorial IPA (MHCLB)",
        "value": "61184",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Longevity CO",
        "value": "94265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Longevity FL",
        "value": "N10917",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Longevity IL",
        "value": "00851",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Longevity MI",
        "value": "20029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Longevity NC",
        "value": "41212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Longevity NJ",
        "value": "27034",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Longevity OK",
        "value": "J1042",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Longs Drugs",
        "value": "56205",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Loomis - SeeChange Health Insurance",
        "value": "MC006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Loren Cook",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Los Angeles Medical Center (LAMC)",
        "value": "14829",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Los Angeles Medical Center (LAMC) - Encounters",
        "value": "84109",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Louisiana Healthcare Connections - Medical",
        "value": "DAK01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Louisiana State University - CORVEL",
        "value": "PCJOL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Louisiana Workers Compensation Corporation (LWCC)",
        "value": "SYMED",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Lovelace Salud  (Medicaid)",
        "value": "SCPR1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lower Kuskokwim School District",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Lowe's",
        "value": "CPNA1 ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lowe's (Bunch CareSolutions)",
        "value": "35182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Loyal American Life (Medicare Supplement)",
        "value": "12422",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Loyola Physician Partners",
        "value": "20446",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "LUCENT HEALTH",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Lumbermens Underwriting Alliance",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Luther Care",
        "value": "37230",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "LYNX",
        "value": "95411",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mabuhay Medical Group",
        "value": "62956",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MacNeal Health Providers - CHS",
        "value": "76048",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magellan Behavioral Health - Case Rate",
        "value": "MCDVA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magellan Behavioral Health Services",
        "value": "31348",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magellan Behavioral Health Services (Medicaid NE)",
        "value": "10589",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Magellan Behavioral Health Services (Medicaid VA)",
        "value": "RENRO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Magellan Complete Care Arizona",
        "value": "08202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magellan Complete Care of Virginia (MCC of VA)",
        "value": "44412",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Magellan Complete Care VA",
        "value": "99726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magellan Complete Health of Virginia",
        "value": "33213",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Magellan HIA-CA",
        "value": "14316",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MagnaCare",
        "value": "31144",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MagnaCare - Oscar Network",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Magnolia Health Plan (Ambetter) - Medical",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MAHP (MAMSI)",
        "value": "10539",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mail Handlers Benefit Plan (CAC)",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maine Community Health Options",
        "value": "99726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Major League Baseball 8",
        "value": "VHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MAMSI",
        "value": "EPSDR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MAMSI Health Plan",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Managed Care of North America (Florida)",
        "value": "06102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Managed Care of North America (Kentucky)",
        "value": "J1637",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Managed Care Services",
        "value": "MVCV2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Managed Care Systems",
        "value": "10090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Managed DentalGuard",
        "value": "J1902",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Managed Health Network (MHN)",
        "value": "65062",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Managed Health Services - WI State Employees",
        "value": "AMDA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Managed Health Services Indiana (Ambetter) - Medical",
        "value": "BCSMT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Managed Health Services Wisconsin (Ambetter) - Medical",
        "value": "HLPUH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Managed Healthcare Associates",
        "value": "09302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mansfield I.S.D.",
        "value": "45341",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MAPFRE Insurance",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "March Vision Care",
        "value": "SC050",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Maricopa Care Advantage",
        "value": "J1577",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maricopa Health Plan (UAHP)",
        "value": "09908",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Marpai",
        "value": "22099",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Marrick Medical Finance, LLC",
        "value": "20805",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Martins Point Health Care",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mary Washington Health Plan",
        "value": "J1740",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maryland Healthy Smiles - DHMH",
        "value": "81671",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maryland Physicians Care",
        "value": "BCBSMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Maryland Physicians Care (MPC)",
        "value": "FRFLY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Maryland Physicians Care MCO",
        "value": "34192",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maryland Uninsured Employers Fund - CORVEL",
        "value": "IP083",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Maryland’s Medicaid Public Behavioral Health System",
        "value": "SHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mashantucket Pequot Tribal Nation",
        "value": "MPM46",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Masonry Industry Trust",
        "value": "10190",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Masonry Welfare Trust Fund",
        "value": "SIM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mass General Brigham Health Plan",
        "value": "J1721",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Massachusetts Bay Insurance Company - CORVEL",
        "value": "41194",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"Massachusetts Behavioral Health",
        "value": "\"",
        "AveCallholdtime": "PROSP"
    },
    {
        "label": "Masters Mates and Pilots Plan",
        "value": "EHI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mathis ISD",
        "value": "GSMPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Matrix Absence Management",
        "value": "007021305",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Maverick Medical Group",
        "value": "J3756",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Maxor Administrative Services",
        "value": "95167",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mayo Clinic Recovery & Claims Services ",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MBA Benefit Administrators",
        "value": "37253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MBA of Wyoming",
        "value": "60601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MBS (Formerly MedCost Benefit Services)",
        "value": "74238",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MCA Administrators, Inc.",
        "value": "39180",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MCA Sedgwick",
        "value": "43160",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "McCreary Corporation",
        "value": "PARTH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "McGregor PACE",
        "value": "CHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "McLaren Advantage SNP",
        "value": "DDPAR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "McLaren Health Advantage",
        "value": "54828",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "McLaren Health Plan (MHP) Commercial",
        "value": "SMPLY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "McLaren Health Plan (MHP) Medicaid",
        "value": "SCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "McMullen County ISD",
        "value": "84133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MD Anderson Physician Network",
        "value": "84103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MD Partners IPA",
        "value": "20446",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MDwise Health Indiana Plan (DOS on or After 1/1/2019)",
        "value": "GHAOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MDwise Hoosier Care Connect",
        "value": "AAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MDwise Hoosier Healthwise (DOS on or after 1/1/2019))",
        "value": "14165",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MDX Hawaii",
        "value": "66727",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Meadowbrook Insurance Company",
        "value": "95444",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MED3000 California",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedAdmin Solutions",
        "value": "SB580",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedAdmin Solutions (Self funded plans)",
        "value": "95567",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedBen",
        "value": "88056",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedCom",
        "value": "71070",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedCore HP",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedCost, Inc.",
        "value": "71499",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedDirect",
        "value": "SCACO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medequip Inc",
        "value": "RP063",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medex Health Network Inc",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medfocus",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medica",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medica Government Programs",
        "value": "00265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medica Health Care Plan - Florida",
        "value": "63842",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medica Health Plan Solutions",
        "value": "IHPEN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medica HealthCare Plan of Florida",
        "value": "LNDMK",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medica2",
        "value": "BPS77",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Alabama",
        "value": "04212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Alaska",
        "value": "58169",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Arizona",
        "value": "12572",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Arkansas",
        "value": "64088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Colorado",
        "value": "VALOP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Colorado (Behavioral Health)",
        "value": "CH101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Connecticut",
        "value": "95378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Delaware",
        "value": "00544",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid District of Columbia",
        "value": "COMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Florida",
        "value": "MAMS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Georgia",
        "value": "RENBL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Hawaii",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Idaho",
        "value": "MCDID",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Illinois",
        "value": "95729",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Indiana",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Iowa",
        "value": "11849",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Kansas",
        "value": "55731",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Kentucky",
        "value": "55247",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Louisiana",
        "value": "PSKW0",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Louisiana Ambulance Only (PAYER ID REQD)",
        "value": "HPPZZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Louisiana DME Only (PAYER ID REQD)",
        "value": "53120",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Maine",
        "value": "20044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Maryland",
        "value": "94564",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Maryland MHS (PMHS)",
        "value": "52563",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Massachusetts",
        "value": "59331",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Michigan",
        "value": "65241",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Minnesota",
        "value": "POP07",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Mississippi",
        "value": "68756",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Missouri",
        "value": "CLFM1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Montana",
        "value": "251VA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Nebraska",
        "value": "C1SCA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Nevada",
        "value": "99320",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid New Hampshire",
        "value": "39026",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid New Jersey",
        "value": "65415",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid New Mexico",
        "value": "77350",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid New Mexico - Presbyterian Salud",
        "value": "57254",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid New York",
        "value": "19450",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid North Carolina",
        "value": "60230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid North Dakota",
        "value": "75309",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Alaska",
        "value": "19437",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Arizona (AHCCCS)",
        "value": "00932",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Arkansas",
        "value": "76031",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Connecticut",
        "value": "37266",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of District of Columbia",
        "value": "SJHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Georgia",
        "value": "75190",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Georgia, Grady Healthcare, Inc",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Hawaii",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Idaho",
        "value": "PCMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Idaho ",
        "value": "13162",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Illinois",
        "value": "00890",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Illinois ",
        "value": "J1026",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Iowa",
        "value": "00402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Kansas",
        "value": "86098",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Kentucky",
        "value": "60230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Kentucky (Doral Dental / Region #3)",
        "value": "37216",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Louisiana",
        "value": "99030",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Maine",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Maryland, PhysiciansCare MCO",
        "value": "99320",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Massachusetts",
        "value": "39113",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Minnesota",
        "value": "00510",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Mississippi",
        "value": "MP001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Missouri",
        "value": "66008",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Missouri, Blue Advantage",
        "value": "08202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Missouri, Community Care Plus - St. Louis",
        "value": "45302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Missouri, Community Health\\ Plan",
        "value": "J1437",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Missouri, FirstGuard Health Plan",
        "value": "00213",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Missouri, HealthCare USA - St. Louis",
        "value": "24077",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Missouri, HealthNet",
        "value": "MPHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Missouri, TrumanCare - Family Health Partners",
        "value": "J1992",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Montana",
        "value": "MPM33",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Nebraska, United HealthCare of the Midlands",
        "value": "89237",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Nevada",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of New Hampshire",
        "value": "37050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of New Jersey",
        "value": "34181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of New Mexico",
        "value": "65062",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of New Mexico, Cimmaron Health Plan",
        "value": "MPM62",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of New Mexico, Lovelace Community Health Plan",
        "value": "L0230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of New Mexico, Presbyterian Salud",
        "value": "70259",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of New York",
        "value": "33033",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of New York (Adminstrated by Americhoice)",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of North Carolina",
        "value": "35182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of North Dakota",
        "value": "J2199",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Ohio, Emerald HMO and Medicare, Inc.",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Ohio, Health Power HMO, Cincinnati",
        "value": "95044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Ohio, Personal Physicians Care, Cleveland",
        "value": "52103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Ohio, United HealthCare, Inc. Medicare Complete",
        "value": "19434",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Oklahoma",
        "value": "J3743",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Oklahoma, Community Care",
        "value": "SCXIX",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Oklahoma, Heartland Health Plan",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Oklahoma, Unicare Health Plan",
        "value": "MPM62",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Oregon",
        "value": "41124",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Pennsylvania",
        "value": "J1796",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Pennsylvania (Administered by Americhoice)",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Pennsylvania, Best Health Care, Pittsburgh",
        "value": "74323",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Pennsylvania, Gateway Health Plan, Pittsburgh",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Pennsylvania, Health Partners, Philadelphia",
        "value": "SAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Pennsylvania, Oaktree",
        "value": "LMI01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of South Carolina",
        "value": "DDPOK",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of South Dakota",
        "value": "59140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Tennessee, BlueCare",
        "value": "HSMSO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Texas",
        "value": "00885",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Texas - Delta Dental",
        "value": "52095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Texas - MCNA ",
        "value": "80314",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Utah",
        "value": "31059",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Virginia, HealthKeepers Plus",
        "value": "23284",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid of Wisconsin (Administered by Doral Dental of Wisconsin)",
        "value": "77003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Wisconsin, Humana / TDC",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid of Wisconsin, PrimeCare",
        "value": "HPPO1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Ohio",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Oklahoma",
        "value": "82694",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Oregon",
        "value": "OMDBH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Pennsylvania",
        "value": "23283",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Pennsylvania Behavioral Health (Beacon Health Options)",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Rhode Island",
        "value": "00903",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid South Carolina",
        "value": "74272",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid South Carolina (Blue Choice)",
        "value": "AMM03",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid South Dakota",
        "value": "94135",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Texas",
        "value": "46120",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Texas (Premier Plan)",
        "value": "PMAI2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid TN/TennCare",
        "value": "TXABA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Utah",
        "value": "15003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Utah Crossovers",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Vermont",
        "value": "RR3426",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Virginia",
        "value": "41150",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Washington",
        "value": "SCNAN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Washington DC",
        "value": "22417",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid West Virginia",
        "value": "60389",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicaid Wisconsin",
        "value": "25404",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicaid Wyoming",
        "value": "OMDBH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medi-Cal (California Medicaid)",
        "value": "30167",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Associates Health Plan",
        "value": "77010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medical Benefits Administrators, Inc.",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Benefits Companies",
        "value": "00430",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Benefits Mutual",
        "value": "15459",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medical Benefits Mutual Life Insurance Co.",
        "value": "J3944",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medi-Cal Dental",
        "value": "84137",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Mutual of Ohio",
        "value": "10261",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Reimbursements of America",
        "value": "CKC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Resource Network (MRN)",
        "value": "91078",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medical Safety Net",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medical Service Company (MCS)",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medical Specialties Managers Inc",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medi-Cal UB Type 02 (Long-Term Care)",
        "value": "37601",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medi-Cal UB Type 03 (Inpatient)",
        "value": "RCHN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medical Value Plan-MVP-OH",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare – HHH J11 (Palmetto)",
        "value": "00402",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare – HHH J15 (CGS)",
        "value": "J3949",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Alabama",
        "value": "77777",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Alaska",
        "value": "13141",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Alaska ",
        "value": "82029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare and Much More Florida",
        "value": "74240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Arizona",
        "value": "DMELA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Arizona ",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Arkansas",
        "value": "36312",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Arkansas ",
        "value": "J1394",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Cahaba ",
        "value": "J1679",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare California (North)",
        "value": "89242",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare California (Northern)",
        "value": "81600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare California (South)",
        "value": "61425",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare California (Southern)",
        "value": "CX019",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare California Part A",
        "value": "88022",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Colorado",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Colorado ",
        "value": "37281",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Connecticut",
        "value": "MPM04",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Connecticut ",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Delaware",
        "value": "95655",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare District of Columbia",
        "value": "EBC18",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare District Of Columbia ",
        "value": "CALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Florida",
        "value": "CALSC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Florida ",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Georgia",
        "value": "95378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Georgia ",
        "value": "J3864",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Hawaii",
        "value": "36334",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Idaho",
        "value": "95433",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Idaho ",
        "value": "VMMH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Illinois",
        "value": "12399",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Illinois ",
        "value": "34192",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Indiana",
        "value": "77076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Iowa",
        "value": "11334",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare J04911",
        "value": "OCN01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare J12901 - WPS Legacy Part A",
        "value": "FLCPC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Kansas",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Kansas ",
        "value": "PH001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Kansas City",
        "value": "91078",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Kentucky",
        "value": "RR001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Louisiana",
        "value": "HPOUV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Maine",
        "value": "TP021",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Maryland",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Maryland ",
        "value": "33600",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Massachusetts",
        "value": "93221",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Massachusetts ",
        "value": "96385",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Michigan",
        "value": "86027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Michigan ",
        "value": "99943",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Minnesota",
        "value": "13334",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Mississippi",
        "value": "31140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Mississippi ",
        "value": "CAPHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Missouri ",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Missouri (East & West)",
        "value": "13265",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Montana",
        "value": "SCNHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Montana ",
        "value": "73866",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Nebraska",
        "value": "00903",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Nevada",
        "value": "00950",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Nevada ",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare New Hampshire",
        "value": "56144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New Jersey",
        "value": "J3872",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New Jersey ",
        "value": "TRULI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New Mexico",
        "value": "39182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New York - Downstate ",
        "value": "CMHGF",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare New York - Queens ",
        "value": "95604",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare New York (Downstate)",
        "value": "NMMAD",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New York (Empire)",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare New York (Queens)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare New York (Upstate)",
        "value": "CPS01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare New York Upstate Part B",
        "value": "77050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare North Carolina",
        "value": "SB580",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare North Dakota",
        "value": "37272",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Northern Virginia",
        "value": "13148",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Ohio",
        "value": "CHCPI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Ohio ",
        "value": "77002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Oklahoma",
        "value": "CEC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Oregon",
        "value": "31150",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Oregon ",
        "value": "81810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Pennsylvania",
        "value": "77013",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Pennsylvania ",
        "value": "14112",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Railroad",
        "value": "23045",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Rhode Island",
        "value": "A0102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Rhode Island ",
        "value": "ADACS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare South Carolina",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare South Dakota",
        "value": "38308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare South Dakota ",
        "value": "16107",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Tennessee",
        "value": "77170",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Tennessee ",
        "value": "CPP06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Texas",
        "value": "31458",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Texas ",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Utah",
        "value": "CB122",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Vermont",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Vermont ",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Virgin Islands",
        "value": "10594",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Virginia ",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Virginia (Palmetto)",
        "value": "NWADM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Washington",
        "value": "DAKCH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare Washington ",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Washington DC",
        "value": "HSCD1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Medicare West Virginia",
        "value": "96107",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare West Virginia ",
        "value": "56144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Wisconsin",
        "value": "MR034",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicare Wyoming",
        "value": "77052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MediChoice IPA",
        "value": "J1417",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medicina Familiar Medical Group",
        "value": "20907",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medico Insurance",
        "value": "36215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medigold",
        "value": "54771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Medigold PPO",
        "value": "10463",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MediShare (Christian Care Ministries)",
        "value": "44412",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mediview Sendero CHIP & STAR",
        "value": "EXC02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mediview UTMB Care UB",
        "value": "AMHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mediview UTMB3",
        "value": "57610",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mediview Vista 360 Health",
        "value": "SC008",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedLogix MSO - Allied Health Solutions",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedPartners Administrative Services",
        "value": "81508",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedPay Assurance",
        "value": "J1523",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedRisk",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedSave USA Third Party Administration",
        "value": "SC054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedSolutions, Inc",
        "value": "71404",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedStar Family Choice DC",
        "value": "CCIHJ",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedStar Family Choice MD",
        "value": "38336",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedStar Family Choice of DC",
        "value": "COSAZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedStar Family Choice of Maryland",
        "value": "38343",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MedView (via SmartData)",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MedXoom",
        "value": "36331",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mega Life & Health Insurance - OKC",
        "value": "39144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mega Life and Health (Frisco, TX)",
        "value": "MCDIL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Meijer Stores",
        "value": "PBHD1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MEMIC Casualty Company",
        "value": "CCA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MEMIC Indemnity Company",
        "value": "C1056",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Memorial Clinical Associates (MCA)",
        "value": "31059",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Memorial Healthcare IPA",
        "value": "CH0000586",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Memorial Hermann Health Network (MHHN)",
        "value": "MR085",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Memorial Integrated Healthcare (Commercial)",
        "value": "J3954",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Memorial Integrated Healthcare (Medicaid)",
        "value": "20818",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Memorial Medical Center - Non Sutter",
        "value": "06941",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MemorialCare Medical Foundation",
        "value": "73143",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MemorialCare Medical Foundation Cap Services",
        "value": "BHOVO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MemorialCare Medical Foundation UCI",
        "value": "J1521",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MemorialCare Select Health Plan",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MemorialCare Select Health Plan Encounters",
        "value": "PCACZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mendota Insurance Company",
        "value": "90096",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Menifee Valley Community Medical Group",
        "value": "20029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mental Health Consultants Inc.",
        "value": "MMMFL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mental Health Network (MHNet)",
        "value": "75250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Merced County Healthy Kids",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Merchants Insurance Group",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercury",
        "value": "74289",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercury General Corporation - Auto",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercy Accountable Care Plan",
        "value": "128KS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mercy Benefit Administration",
        "value": "V360H",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercy Care Health Plans – Wisconsin & Illinois ",
        "value": "98010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercy Care Plan (AHCCCS)",
        "value": "LFL01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercy Care Plan (Arizona)",
        "value": "14412",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mercy Care RBHA",
        "value": "00890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mercy Managed Behavioral Health",
        "value": "NCPG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mercy Physicians Medical Group (MPMG)",
        "value": "64084",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mercy Provider Network",
        "value": "11303",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Meridian Health Plan Illinois Complete",
        "value": "10550",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Meridian Health Plan Michigan Complete",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Meridian Health Plan of Illinois",
        "value": "BS001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Meridian Health Plan of Iowa",
        "value": "J3952",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Meridian Health Plan of Michigan",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Merit IPA",
        "value": "84103",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Meritage Medical Network",
        "value": "00590",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Meritain Health",
        "value": "62180",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Meritain Health (formerly Weyco, Inc)",
        "value": "36396",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metal Culverts",
        "value": "00621",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Metcare Health Plans",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Methodist First Choice",
        "value": "CKC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Metis Services",
        "value": "NMM05",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MetLife",
        "value": "MCDKY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metro Plus Health Plan",
        "value": "35206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MetroPlus Health Plan",
        "value": "00950",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metropolitan Council",
        "value": "J1037",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metropolitan Health Plan (MHP)",
        "value": "SX141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metropolitan Health Plan (now Hennepin Health)",
        "value": "37322",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Metropolitan Property and Casualty Insurance Company",
        "value": "65099",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Metropolitan Transit Authority",
        "value": "13147",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Miami Children's Health Plan",
        "value": "10205",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Miami-Dade County (Fl) - CORVEL",
        "value": "USFHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MIC General Insurance Corp.",
        "value": "J3956",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Michigan Fidelis Secure Care - Medical",
        "value": "39167",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Michigan Medicaid BCCCNP",
        "value": "BCCCP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Michigan Millers Mutual - CORVEL",
        "value": "SRS83",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mid Cities IPA",
        "value": "VAC45",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mid Rogue IPA",
        "value": "MCDDE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mid Rogue Oregon Health Plan",
        "value": "36400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mid-America Associates Inc.",
        "value": "42137",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mid-American Benefits",
        "value": "MLTOT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mid-American TPA: AAI, Inc. (UCS)",
        "value": "29076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mid-Atlantic Medical Services, Inc. (MAMSI)",
        "value": "23550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mid-County Physicians Medical Group",
        "value": "CB733",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Middlesex Insurance Company",
        "value": "00111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midlands Choice, Inc.",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midwest Drywall Company",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midwest Family Advantage Insurance Co - CORVEL",
        "value": "85468",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Midwest Family Mutual (Auto Only) - CORVEL",
        "value": "BCTF1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Midwest Family Mutual Insurance Co",
        "value": "MCDNY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Midwest Group Benefits",
        "value": "LCM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Midwest Health Plans, Inc.",
        "value": "67671",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midwest Insurance",
        "value": "60818",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midwest Operating Engineers Welfare Fund",
        "value": "43178",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MidWest Physician Administrative Services (MPAS)",
        "value": "MCI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Midwest Security",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Miller Grove ISD",
        "value": "VACCN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mills Peninsula Health Services (SPS - Sutter)",
        "value": "MPM28",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mills Peninsula Medical Group (SPS - Sutter)",
        "value": "38232",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Minneapolis Public School - CORVEL",
        "value": "38334",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Minnesota Blue Cross Dental (UCCI)",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Minnesota Counties Ins Trust (MCIT)",
        "value": "09830",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Minnesota Department of Health",
        "value": "09824",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Minnesota Department of Labor",
        "value": "UC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Minnesota Health Care Program",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Minnesota Insurance Guarantee Association (MIGA)",
        "value": "00885",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Minnesota WC Plan",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mission Community IPA",
        "value": "J1993",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mission Heritage Medical Group",
        "value": "00630",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mission Hospital Affiliated Physicians",
        "value": "74101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mississippi Public Entity  Employee Benefit Trust (MPEEBT)",
        "value": "67136",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mississippi Select Health Care",
        "value": "BC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Missoula County Medical Benefits Plan",
        "value": "VAICE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Missouri Care (MC)",
        "value": "DEVOT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Missouri Medicare Select",
        "value": "SIL01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mitsui Sumitomo Insurance Group",
        "value": "PPNZZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mitsui Sumitomo Insurance USA Inc.",
        "value": "37330",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MMM of Florida",
        "value": "89070",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MMSI (Mayo Health)",
        "value": "64088",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mobilitas Insurance Company",
        "value": "J1884",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Moda Health (Formerly ODS Health Plan)",
        "value": "00265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Moda Health (ODS)",
        "value": "34097",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Complete Care of Virginia",
        "value": "ROJW1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Health Care of New Mexico ",
        "value": "22254",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Health Care of Ohio ",
        "value": "UHNDC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare Florida",
        "value": "10441",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare Michigan",
        "value": "J3913",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Arizona",
        "value": "56240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of CA Encounters",
        "value": "11440",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of California",
        "value": "12268",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Florida",
        "value": "SPOHN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of Idaho",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Illinois",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Michigan",
        "value": "71063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of Mississippi",
        "value": "C1029",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Nevada ",
        "value": "29370",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of New Mexico",
        "value": "00402",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of New Mexico ",
        "value": "41161",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of New Mexico - Salud",
        "value": "31650",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of New York (formerly Total Care)",
        "value": "31155",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Ohio",
        "value": "17516",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Ohio ",
        "value": "45399",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Puerto Rico",
        "value": "DHFIE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of South Carolina",
        "value": "HLPCG",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of Texas",
        "value": "J1530",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Utah",
        "value": "SLOS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of Utah (aka American Family Care)",
        "value": "25924",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of WA Encounters",
        "value": "EXC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare of Washington",
        "value": "37279",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare of Wisconsin",
        "value": "J1653",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Healthcare South Carolina",
        "value": "CPPTM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare Utah",
        "value": "CANO1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Healthcare Wisconsin",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Molina Medicaid Solutions - Idaho",
        "value": "65030",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Medicaid Solutions - Louisiana",
        "value": "20090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Molina Ohio Medicaid",
        "value": "11402",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Momentum Insurance Plans, Inc.",
        "value": "05901",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Monarch Healthcare IPA",
        "value": "BCSMT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mondial Assistance",
        "value": "RP088",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Montana Health Co-op",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Montana State Fund - WC",
        "value": "35374",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Montefiore Contract Management Organization (CMO)",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Montgomery",
        "value": "10434",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Montgomery County Self-Insurance Program (MD) - CORVEL",
        "value": "CXARC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Montgomery County Texas",
        "value": "MNDH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Monument Administrators - Guardian Comp",
        "value": "CDCMD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Monument Administrators - Quality Comp",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Monument Administrators - Victory Comp",
        "value": "54771",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Monumental Life Insurance Company (MLIC)",
        "value": "96396",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Morecare",
        "value": "62777",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Morris Associates",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Motion Picture Industry Health Plan",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MotivHealth Insurance Company",
        "value": "52123",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mountain States Administrators",
        "value": "J4181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MPLAN, Inc. - HealthCare Group",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MPM Prospect",
        "value": "MPM16",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MRIPA - AllCare Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MSA Care Guard",
        "value": "LACAR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mt. Carmel Health Plan",
        "value": "WPS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Multicare Connected Care",
        "value": "72187",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Multicultural Primary Care Medical Group",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MultiPlan GEHA",
        "value": "J1437",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Multiplan Wisconsin Preferred Network",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Multnomah County Other",
        "value": "51037",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Multnomah Treatment Fund (Formerly known as Verity Plus)",
        "value": "MR004",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Municipal Health Benefit Fund",
        "value": "12956",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mutual Assurance",
        "value": "C1080",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mutual Health Services",
        "value": "38225",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mutual of Omaha",
        "value": "MHPMI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Mutual of Omaha Dental",
        "value": "94146",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Mutually Preferred",
        "value": "J3928",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MVP - Ohio",
        "value": "65391",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MVP Health Care (Mohawk Valley)",
        "value": "MC006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MVP Health Plan of New York ",
        "value": "10010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "My Family Medical Group",
        "value": "95397",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "My Health Plan",
        "value": "23160",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "MyChoice IPA (ProCare MSO)",
        "value": "MCDSD",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MyNexus - Aetna Home Health",
        "value": "35245",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "myNEXUS Anthem",
        "value": "CHPWA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "MyNexus, Inc.",
        "value": "RR3426",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "N.W. Ironworkers Health & Security Trust Fund, Group # F15",
        "value": "MR083",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "N.W. Roofers & Employers Health & Security Trust Fund, Group# F26",
        "value": "86052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "N.W. Textile Processors Group # F14",
        "value": "UC002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NALC HEALTH BENEFIT PLAN                                                ",
        "value": "CPHL1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nap Tools (via EMC Risk Services)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Naphcare Inc",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Accident & Health General Agency Inc (NAHGA)",
        "value": "20356",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Allied Workers Union Local 831",
        "value": "SOLIS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National American Insurance Co (NAICO)",
        "value": "MEDEX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Association of Letter Carriers",
        "value": "47165",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Association of Letter Carriers (NALC)",
        "value": "AMBLA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Capital Preferred Provider Organization (NCPPO)",
        "value": "91151",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Casualty Company",
        "value": "63114",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Financial Insurance Company",
        "value": "80520",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Foundation Life Insurance Company",
        "value": "92649",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National General Insurance Online, Inc.",
        "value": "J1259",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Interstate",
        "value": "84367",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Liability and Fire Insurance Company (DOI before 7/1/14)",
        "value": "L0230",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Liability and Fire Insurance Company (DOI on or after 7/1/2014)",
        "value": "CRSMD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Loss Prevention, Inc.",
        "value": "PDT02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Pacific Dental - CALIFORNIA",
        "value": "68063",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Pacific Dental - TEXAS",
        "value": "38337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Rural Electric Cooperative Assoc.",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Rural Letter Carrier Assoc.",
        "value": "41150",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "National Telecommunications Cooperative Association (NTCA - Staff)",
        "value": "MNDH1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "National Telecommunications Cooperative Association (NTCA)",
        "value": "MR085",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nationwide - Auto Only",
        "value": "64088",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nationwide Affinity Insurance Company of America",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nationwide Agribusiness Insurance Co",
        "value": "66917",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nationwide Ins Company of America",
        "value": "J1655",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nationwide Mutual Fire Company",
        "value": "CALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nationwide Specialty Health fka Nationwide Health Plan ",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Native Care Health",
        "value": "J1547",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NBC Universal",
        "value": "05047",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NCA Comp",
        "value": "BRIDG",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NCA Medical Group",
        "value": "36393",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NCAS",
        "value": "J1840",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NCAS - Fairfax, Virginia",
        "value": "4317M",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NCH Healthcare Inc.",
        "value": "48611",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NCLM - North Carolina League of Municipalities",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ND Workforce Safety and Insurance (WSI)",
        "value": "75232",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nebraska Plastics Group",
        "value": "PREHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nebraska Total Care - Medical",
        "value": "03432",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Neighborborhood Health Partnership (NHP)",
        "value": "LCM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Neighborhood Health Partnership",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Neighborhood Health Plan (NHPRI)",
        "value": "CRIHB",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Neighborhood Health Plan (RI)",
        "value": "65978",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Neighborhood Health Plan RI - Exchange Unity Integrity",
        "value": "PCN12",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Neighborhood Health Providers &  Suffolk Health Plan (NHP - SHP)",
        "value": "J4250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nephrology IPA",
        "value": "14512",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Netcare Life & Health Insurance - Hagatna, Guam",
        "value": "75240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NetWell",
        "value": "SHM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Network Health Insurance (NHIC) Medicare",
        "value": "CPP10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Network Health of WI - Commercial",
        "value": "10015",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Network Health of WI - Medicare",
        "value": "96240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Network Health Plan of Wisconsin - Commercial",
        "value": "30005",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Network Medical Management",
        "value": "95422",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Network Solutions IPA",
        "value": "TP108",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NeueHealth",
        "value": "36393",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nevada Dental Benefits",
        "value": "MPM10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nevada Pacific Dental",
        "value": "VALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Avenues",
        "value": "J3766",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Arizona Integrated Physicians",
        "value": "43351",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - Avmed - Oncology",
        "value": "77010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Avmed – Radiation Oncology",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health – CAC Centers",
        "value": "98798",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - CarePlus Cardiology",
        "value": "61325",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - CarePlus Urology",
        "value": "11370",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - CarePlus Urology Lab",
        "value": "IP085",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health – Cigna Medical Group AZ",
        "value": "EPF03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - Devoted Health Cardiology",
        "value": "MEDM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - Devoted Health Oncology",
        "value": "EPIC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - Florida Blue Medicare – Oncology",
        "value": "10450",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Florida Blue Medicare - Radiation Oncology",
        "value": "CAPMN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - HealthSun Cardiology",
        "value": "J3874",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - HealthSun Oncology",
        "value": "74205",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Humana Oncology",
        "value": "STARH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health - Humana Radiation Oncology",
        "value": "77073",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Simply Health Care Cardiology",
        "value": "MRIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Simply Health Care Oncology",
        "value": "11983",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Simply Health Care Radiation Oncology",
        "value": "84555",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health - Vista Cardiology - Summit",
        "value": "PA331",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Century Health Solutions - CarePlus Oncology",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health Solutions – CarePlus Radiation Oncology",
        "value": "J1711",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health Solutions - Vista Oncology",
        "value": "J1433",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Century Health Solutions – Vista Radiation Oncology",
        "value": "CHCN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Directions Behavioral Health (NDBH)",
        "value": "BHOVO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New England Dental Administrators",
        "value": "25463",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New England, The",
        "value": "NMMAD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Era Employee Welfare Benefit",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Era Life",
        "value": "12860",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Era Life - Employee Benefit Plans",
        "value": "00932",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Era Life Insurance Company",
        "value": "RCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Hampshire Healthy Families - Medical",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Jersey Manufacturing (NJM)",
        "value": "COACC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Jersey Manufacturing (NJM) - Auto",
        "value": "00760",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New Life Medical Group, Inc.",
        "value": "59313",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Mexico District Council of Carpenters",
        "value": "95327",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Mexico Mutual",
        "value": "00932",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Mexico Painters & Allied Trades Local #823",
        "value": "CB212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Mexico West Texas Multi-Craft",
        "value": "17516",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New Source Benefits, LLC",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New York Hotel Trade Council",
        "value": "53120",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New York Life",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New York Medical Indemnity Fund",
        "value": "65978",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "New York Network Management",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "New York State Insurance Fund (NYSIF)",
        "value": "14633",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Next Level Administration ",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Next Level Health Partners",
        "value": "81671",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NextBlue",
        "value": "CKSC1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nexus Health Medical Group",
        "value": "14315",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nexus IPA (ProCare MSO)",
        "value": "61115",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NGS American",
        "value": "37602",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NHC Advantage",
        "value": "39026",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nike, Inc - CORVEL",
        "value": "61799",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nippon Life Insurance Company of America",
        "value": "20149",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nivano Physicians IPA",
        "value": "SFHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NJ Carpenters Health Fund",
        "value": "20443",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NJ Property Liability Ins Guaranty",
        "value": "STM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Noble AMA Select IPA",
        "value": "25160",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Noble Community Medical Associates LA",
        "value": "ACP17",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Noble Mid OC Orange County  (Health Smart)",
        "value": "MPM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nonprofit Insurance Trust",
        "value": "MCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nordstrom, Inc.",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Norfolk & Dedham Group",
        "value": "66005",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Norfolk Public Schools (VA) - CORVEL",
        "value": "SHMS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NorGUARD Insurance Company - Guard DBA",
        "value": "51037",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North America Administrators (NAA) - Nashville, Tennessee",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "North American Administrators",
        "value": "UHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North Broward Hospital District",
        "value": "39126",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North Carolina Insurance Guaranty Association",
        "value": "20538",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North East Independent School District (NEISD)",
        "value": "19435",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"North East Medical Services",
        "value": "(NEMS)\"",
        "AveCallholdtime": "00805"
    },
    {
        "label": "North Iowa Community Health Plan (NICH)",
        "value": "RCHSD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North River Insurance Company",
        "value": "J3953",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "North Shore - LIJ (Healthfirst)",
        "value": "AH001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North Shore - LIJ CareConnect Insurance Company",
        "value": "SYMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North Star Mutual Insurance Co. - CORVEL",
        "value": "74289",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "North Texas Healthcare Network",
        "value": "CSI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "North West Administrators (FCHN)",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "North West Orange County Medical Group",
        "value": "35206",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Northeast Carpenters Funds",
        "value": "NMM04",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NorthEast Delta Dental",
        "value": "UHA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northeast Georgia Health Services",
        "value": "03036",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Northeast Iowa Area Agency",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern California Advantage Medical Group  (NCAMG)",
        "value": "53473",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern California Physicians Grp",
        "value": "76048",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern Illinois Health Plan",
        "value": "26375",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern Kentucky University",
        "value": "33628",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern Nevada Operating Engineers Health and Welfare",
        "value": "74431",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northern Nevada Trust Fund (Benefit Plan Admin)",
        "value": "25924",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Northland Auto",
        "value": "RP039",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Northshore Physician Associates",
        "value": "90560",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Northwest Community Health Partners",
        "value": "53589",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northwest Diagnostic Clinic (NWDC)",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northwest Physicians Network",
        "value": "34097",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northwestern Medicine Physicians Network",
        "value": "18765",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Northwood Healthcare",
        "value": "10378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nova Healthcare Administrators, Inc.",
        "value": "16644",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nova Saiph Re",
        "value": "MMFUC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NovaNet",
        "value": "DX066",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NovaSys Health - Medical",
        "value": "HDNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NP PHP Commercial",
        "value": "00922",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NP PHP Medicare",
        "value": "LNDMK",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NP PHP OHP",
        "value": "J2089",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NP Yamhill County CCO",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NTCA - National Telecommunications Cooperative Association",
        "value": "FCC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Nuestra Familia Medical Group",
        "value": "77104",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NX Health Network",
        "value": "J1959",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "NXT IPA",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "NYC Law Department",
        "value": "HCI02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Nyhart",
        "value": "APCA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oak River Insurance Company (Member of BHHC)",
        "value": "14163",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oak West Physician Association",
        "value": "52123",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Oasis IPA (Regal)",
        "value": "45221",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Occupational Eyewear Network, Inc.",
        "value": "FCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oceans Acquisition, Inc. - CORVEL",
        "value": "00720",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ogden Benefits Administration (EBC)",
        "value": "31404",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ohana Health Plan",
        "value": "MMMFL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ohio Casualty Insurance",
        "value": "22100",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ohio Health Choice PPO",
        "value": "34189",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ohio PPO Connect",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ohio Rehab Department of Corrections (ODRC)",
        "value": "A0106",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OhioRISE - Aetna Better Health of Ohio",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OhioRISE Aetna Better Health of Ohio",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ojai Valley Community Medical Group",
        "value": "66005",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Oklahoma DRS DOC",
        "value": "12K89",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Old Republic Insurance Company",
        "value": "13337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OLOLRMC Uninsured Patient Services Program",
        "value": "91164",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Olympus Managed Health Care (OMHC)",
        "value": "77009",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Omni Healthcare - Humboldt Del Norte, California",
        "value": "95444",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OMNI IPA",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Omnicare Medical Group (OMNI)",
        "value": "59354",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Oncology Network of Orange County",
        "value": "J3903",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Oncology Physicians Network CA PC",
        "value": "99485",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Beacon",
        "value": "88350",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "One Call Medical",
        "value": "83276",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "One Call Medical - Diagnostic",
        "value": "12502",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "One Call Physical Therapy (formerly Align Network)",
        "value": "65449",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Care Connect (OCC)",
        "value": "INPA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Care Connect LTC (OCC)",
        "value": "COLTC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Health Plan",
        "value": "91062",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Health Plan of California",
        "value": "VCIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "One Health Plan of Colorado",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"One HomeCare Solutions",
        "value": "\"",
        "AveCallholdtime": "40025"
    },
    {
        "label": "OneCare (OC)",
        "value": "83245",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OneCare (OC) ",
        "value": "J1417",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OneNet PPO (formerly Alliance PPO & MAPSI)",
        "value": "J1360",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OnLok Senior Health Services, Inc.",
        "value": "MBA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OODA Health",
        "value": "S9637",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OPEIU Locals 30& 537",
        "value": "ALLCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Operating Engineers Health & Welfare Fund ",
        "value": "75136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Operating Engineers Local #53",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Operating Engineers Local 501 of California",
        "value": "J1692",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Operating Engineers Locals 302 & 612 Health & Security Fund,  Group # F12",
        "value": "MSO44",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Operating Engineers Locals 302 & 612 Health & Security Fund, Grp. #F12",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ophthalmic Health Alliance",
        "value": "MCDMD",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OptiCare Managed Vision (Formerly known as Prime Vision Health)",
        "value": "SCAN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Opticare of Utah",
        "value": "42344",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optima Insurance Company",
        "value": "OODAH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optima/Sentara",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OptiMed",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optimum Choice of the Carolina's (OCCI)",
        "value": "UC002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optimum Healthcare",
        "value": "56071",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Option Services Group",
        "value": "MMMFL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Options Health Plan",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum – Complex Medical Conditions (CMC) (formerly OptumHealth Care Solutions and United Resource Networks)",
        "value": "56215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum / Salt Lake County (Medicaid)",
        "value": "23282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum Care Network",
        "value": "00630",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum Care Network - Empire Physicians MG",
        "value": "GRAVI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network - Primecare Medical Group",
        "value": "GCMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Corona",
        "value": "88090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Hemet Valley",
        "value": "58204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum Care Network–Inland Valley",
        "value": "14165",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Moreno Valley",
        "value": "C1041",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum Care Network–Redlands",
        "value": "30750",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Riverside",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Southwestern Valleys",
        "value": "87843",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum Care Network–Sun City",
        "value": "VAPRM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Care Network–Valley Physicians",
        "value": "CKID1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Maryland Behavioral Health",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Optum San Diego Public Sector",
        "value": "41600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Optum Speciality",
        "value": "GEM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OptumCare / AZ, UT (formerly Optum Medical Network & Lifeprint Network)",
        "value": "MPM09",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OptumCare Network of Connecticut",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OptumHealth / OptumHealth Behavioral Solutions (formerly United Behavioral Health [UBH] and PacifiCare Behavioral Health )",
        "value": "52149",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OptumHealth / OptumHealth Behavioral Solutions of NM",
        "value": "36397",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OptumHealth / OptumHealth Physical Health (includes Oxford) (Formerly ACN)",
        "value": "36215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Opus MedStrategies",
        "value": "EDHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Orange Coast Memorial IPA",
        "value": "77002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Orange County Advantage Medical Group",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Orange County Fire Authority - CORVEL",
        "value": "33108",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oregon State Accident Insurance Fund (SAIF) Corporation",
        "value": "J1858",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "O'Reilly Auto Parts",
        "value": "NMM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Orthonet - Aetna",
        "value": "90210",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Orthonet - Uniformed Services Family Health Plan",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oscar Health",
        "value": "COSNV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OSF Health Plans",
        "value": "RP035",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "OSI Group, LLC - CORVEL",
        "value": "SVIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "OSMA Health (formerly PLICO)",
        "value": "63092",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Outsourcing Program",
        "value": "10104",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Oxford Health Plans",
        "value": "CDWY1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "P.R.I.M.E. Benefit Systems Inc.",
        "value": "72690",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "P.S.E.W. Trust",
        "value": "CX021",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "P3 Health Partners Arizona",
        "value": "HMAA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "P3 Health Partners Nevada",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PA Health and Wellness - Medical",
        "value": "BKPNT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PA Preferred Health Network (PPHN)",
        "value": "NDMSA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PACE Center",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PACE Center LTC",
        "value": "J1525",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PACE Southeast Michigan",
        "value": "60550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PACEpartner Solutions",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pacific Alliance Medical Center",
        "value": "AAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pacific Associates IPA",
        "value": "52429",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacific Compensation Insurance Company",
        "value": "J1855",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacific Health MSO",
        "value": "00071",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pacific Healthcare IPA",
        "value": "42137",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacific IPA",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "\"Pacific Medical Centers (PACMED USFHP)",
        "value": "\"",
        "AveCallholdtime": "SMG01"
    },
    {
        "label": "Pacific Source Health Plan",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pacific Southwest Administrators (PSWA)",
        "value": "82832",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacific Sunwear - CORVEL",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacific Union Dental",
        "value": "00402",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pacifica of the Valley Hospital",
        "value": "06161",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PacifiCare Dental and Vision HMO Claims",
        "value": "31118",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PacifiCare Dental and Vision PPO-IND",
        "value": "83269",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PacificSource Community Solutions",
        "value": "78702",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PacificSource Health Plans",
        "value": "00806",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PacificSource Medicare",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Painters Union Insurance Fund",
        "value": "95444",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Paladin Managed Care Services, Inc.",
        "value": "NCA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PALMS Casino Resort (Admin by LIBERTY Dental Plan)",
        "value": "SODRC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Palomar Pomerado Hospital",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pan American Life Insurance",
        "value": "20501",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pan American Life Insurance Group",
        "value": "A0046",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Paradigm",
        "value": "10480",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Paragon Benefits, Inc",
        "value": "11271",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Paramount Health Care",
        "value": "68381",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Parity Healthcare",
        "value": "GTPA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Park District Risk Management Association (PDRMA)",
        "value": "35202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Parker Services LLC",
        "value": "MVMM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Parkland Community Health Plan",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Parkland Community Health Plan (Aetna Better Health)",
        "value": "41178",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Partners Behavioral Health",
        "value": "J1830",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Partners Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Partners in Health",
        "value": "73099",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Partners National Health Plan of NC",
        "value": "88848",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Partnership Health Plan of California Healthy Kids",
        "value": "J1642",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Partnership HealthPlan of California",
        "value": "MHPIL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pasadena Primary Care Physicians Group",
        "value": "DHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pasco County BOCC",
        "value": "DAK01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Passport Advantage",
        "value": "J3936",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Passport Health Plan",
        "value": "MCDWI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PATH Administrators (Formerly DH Evans)",
        "value": "IHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Patient Advocates LLC  ",
        "value": "SNY01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Patient Physician Cooperatives",
        "value": "AWNY6",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Patriot General Insurance Company",
        "value": "PAS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Paul Mueller",
        "value": "93029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Payer Compass – ACS Benefits",
        "value": "00851",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Payer Fusion Holdings",
        "value": "98366",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PBS - Oregon Alaska Industrial Hardware",
        "value": "84135",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PBS - Oregon Alaska Rubber & Supply",
        "value": "BKMD1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PBS - Oregon Cascade Rubber Products",
        "value": "58174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PBS - Oregon Construction Machinery Industrial",
        "value": "FT254",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PBS - Oregon Peninsula Airways",
        "value": "72091",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PBS - Oregon TDX Corporation",
        "value": "J1646",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PCA Health Plan of Texas (Humana)",
        "value": "MLAHS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PCMG of San Luis Obispo (formerly San Luis Obispo Select IPA)",
        "value": "J1618",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PCMG of Santa Maria (formerly Midcoast Care Inc.)",
        "value": "63092",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PDT - Hollywood Presbyterian-St Vincent",
        "value": "TP043",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Peach State Health Plan (Ambetter) - Medical",
        "value": "J1693",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Peak Health",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Peak Pace Solutions",
        "value": "PROHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Peak Property and Casualty Insurance Corporation",
        "value": "23228",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pebble Health",
        "value": "PHCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pediatric Associates",
        "value": "CAS89",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Peerless Insurance",
        "value": "UHC-OHMCD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PEF Clinic",
        "value": "AMDA2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pegasus Medical Group (Regal)",
        "value": "PCACZ",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pekin Insurance",
        "value": "RIOS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Peninsula Indemnity Company",
        "value": "PCMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Penn Behavioral Health",
        "value": "CCMHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Penn Millers Insurance Co",
        "value": "23229",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Penn National Insurance",
        "value": "BRT01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Penn National Insurance - Auto",
        "value": "78857",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pennsylvania Manufacturing Associates (PMA)",
        "value": "BS002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pennsylvania Pace ",
        "value": "AAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "People 1st Health Strategies",
        "value": "24147",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "People 2.0",
        "value": "64884",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "People One Health",
        "value": "00630",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Peoples Health Network",
        "value": "GHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Perennial Advantage of Colorado",
        "value": "NEXUS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Perennial Advantage of Ohio",
        "value": "MR011",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Performance Food Group - CORVEL",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PerformCare",
        "value": "PROGY",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Perlman Medical Group",
        "value": "34734",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Personal Insurance Administrators, Inc. (PIA)",
        "value": "CHBC00657",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PGBA TriWest VA CCN",
        "value": "J1009",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pharmacist Mutual Insurance Co.",
        "value": "J1498",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PHCS GEHA",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Philadelphia American Life Insurance Company",
        "value": "00890",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Phoenix Health Plan (Medicare)",
        "value": "95271",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PHP of Michigan",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PHP of the Carolinas",
        "value": "22254",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physician Associates of Louisiana",
        "value": "31415",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physician Healthcare Integration IPA",
        "value": "73155",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physician Partners IPA - Alameda (ProCare MSO)",
        "value": "58202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physician Partners IPA - Santa Clara (ProCare MSO)",
        "value": "11001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physician Partners IPA - South (ProCare MSO)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Care",
        "value": "53483",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Care Network",
        "value": "TRUST",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Care Network / The Polyclinic",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Choice Medical Group of San Luis Obispo",
        "value": "J1746",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Choice Medical Group of Santa Maria",
        "value": "35174",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Data Trust (PDT)",
        "value": "13407",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Health Association of Illinois",
        "value": "36090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Choice- HCFA Claims",
        "value": "37256",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Health Network (PHN)",
        "value": "81600",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Plan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Plan (PHP) - Global Care",
        "value": "MR003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Health Plan (PHP) - Mid Michigan",
        "value": "64157",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Health Plan (PHP) - Northern Indiana",
        "value": "10001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Plan (PHP) - South Michigan",
        "value": "00630",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Plan (PHP) - Sparrow",
        "value": "SCNGA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Health Plan of Michigan Medicare",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Healthcare Plans",
        "value": "80314",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Healthways IPA",
        "value": "59064",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Management Group",
        "value": "IP095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Medical Group of San Jose",
        "value": "79480",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physician's Mutual (Dental)",
        "value": "UT3F1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians Mutual Insurance Company",
        "value": "05302",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians of Southwest Washington",
        "value": "77220",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Plus Insurance Corporation",
        "value": "91078",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Physicians Plus Insurance, Co., Wisconsin State Employees",
        "value": "00720",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians United Plan",
        "value": "J1452",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physicians’ Healthways IPA",
        "value": "57080",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PhysMetrics (DOS prior to 7/1/17)",
        "value": "95044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PhysMetrics (formerly ChiroMetrics)",
        "value": "42138",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Physnet",
        "value": "21062",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pie Insurance Services Inc. - CORVEL",
        "value": "37257",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Piedmont Community Health Plan",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Piedmont Wellstar Health Plan",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pier 1 Inc. USA",
        "value": "PCMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pierce County WA - CORVEL",
        "value": "10105",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PIH Health (formerly Bright Health)",
        "value": "77350",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pinnacle Claims Management, Inc.",
        "value": "TN200864",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pinnacle Health Resources",
        "value": "AXM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pinnacle Medical Group",
        "value": "J3945",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pinnacol Assurance",
        "value": "77003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pioneer Provider Network",
        "value": "76342",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pittman and Associates (Now known as HealthSmart Benefit Solutions)",
        "value": "35605",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Planned Admin Inc P and C Division",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Planned Administrators, Inc. (PAI)",
        "value": "65391",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Planstin",
        "value": "J1798",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Plexis Healthcare Systems (UCS)",
        "value": "NMM07",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Plymouth Rock",
        "value": "06161",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PMG of San Jose",
        "value": "95729",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Podi Care Managed Care",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Podiatry First",
        "value": "43056",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Podiatry Network Florida",
        "value": "14829",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"Podiatry Plan Inc",
        "value": "\"",
        "AveCallholdtime": "37268"
    },
    {
        "label": "Poe & Brown",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Point Comfort Underwriters",
        "value": "42020",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Point Comfort Underwriters (PCU03)",
        "value": "00562",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "POMCO",
        "value": "00804",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Pomona Valley Medical Group (PVMG)",
        "value": "61301",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pool Administrators Primary",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pool Administrators Secondary",
        "value": "04614",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Poppins Health",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Populytics (formerly Spectrum Administrators)",
        "value": "10521",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Port of Oakland (CA) - CORVEL",
        "value": "18766",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Porter Scott",
        "value": "77003",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Positive Health Care (PHC) / FL MCO PHC",
        "value": "31402",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PPO Plus LLC",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prairie States Enterprises",
        "value": "J1351",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preferred Admin Childrens Hosp",
        "value": "HCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Administrator",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Benefit Administrators",
        "value": "PROV1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Blue of South Carolina",
        "value": "00315",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preferred Care Partners - Florida",
        "value": "TMMC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Community Choice",
        "value": "J3907",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preferred Community Choice - PCCSelect - CompMed",
        "value": "J2175",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preferred Health Care (PHC)",
        "value": "00950",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Health Plan of the Carolinas",
        "value": "J1986",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preferred Health Professionals",
        "value": "86027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Healthcare System - PPO",
        "value": "HHS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred IPA",
        "value": "11202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Mutual",
        "value": "CM001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preferred Network Access, Inc.",
        "value": "OCN01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PreferredOne",
        "value": "14145",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premera Blue Cross Blue Shield of Alaska",
        "value": "MRIPA",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Premera Blue Cross of Washington",
        "value": "IP097",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Premier Access Insurance Company",
        "value": "87716",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Administrative Solutions",
        "value": "IH400",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier Care of Northern California",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Eye Care",
        "value": "20377",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier Group Insurance - CORVEL",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Health Plan (Premier Health Group)",
        "value": "77076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier Health Systems",
        "value": "52189",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - A-G Admin (SOMA group)",
        "value": "J1648",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - Auxiant",
        "value": "BCNDC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - BPA – Benefit Plan Administrators",
        "value": "GRV01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - Commercial Travelers",
        "value": "75133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - First Agency (FIA)",
        "value": "MPM37",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - Mayo Clinic FL/GA",
        "value": "20044",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - Med-Pay",
        "value": "MR001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier HealthCare Exchange (PHX)  - Mississippi Physician Care Network",
        "value": "MCDMD",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) - BMI Kansas",
        "value": "63092",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) - Cypress Benefit Admin",
        "value": "37281",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) - Elmco",
        "value": "22248",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) - Fox Everett/HUB International",
        "value": "J1417",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) – Preferred Admin",
        "value": "61425",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier HealthCare Exchange (PHX) – The Benefit Group",
        "value": "A0102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier Patient Care IPA",
        "value": "25160",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Premier Physician Alliance",
        "value": "77082",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Physician Alliance Inc",
        "value": "ASMI1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Physician Network",
        "value": "UTMB3",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Premier Physician Network (PPN)",
        "value": "82347",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PremierCare Health (ProCare MSO)",
        "value": "11150",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PremierOne Plus IPA",
        "value": "13129",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Presbyterian Health Plan",
        "value": "73155",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Presbyterian Health Plan, Inc. (Magellan)",
        "value": "42150",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Presbyterian Intercommunity Hospital",
        "value": "UNINW",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Presbyterian Salud (NM)",
        "value": "RVC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Presence ERC",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Preserver Insurance Company",
        "value": "11889",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prestige Health Choice",
        "value": "LNDMK",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prestige Health Choice (AmeriHealth Caritas)",
        "value": "LNJ01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Preventative Choice Plan",
        "value": "246",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prezzo",
        "value": "MR084",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primary Care & Pediatric IPA (PCP IPA)",
        "value": "11440",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primary Care Associates Medical Group (PCAMG)",
        "value": "37330",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Primary Care Associates of California (PCAC)",
        "value": "PHPMC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Primary Care Coalition",
        "value": "05029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primary Care of Joliet",
        "value": "3833R",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Primary Care Practices Of Sacramento - EHS",
        "value": "C1035",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Primary Health Network",
        "value": "82048",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primary Health of Josephine County",
        "value": "89239",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primary Physician Care",
        "value": "J1574",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prime Community Care Central Valley  Encounters (MV Medical)",
        "value": "HSICS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prime Community Care Central Valley (MV Medical)",
        "value": "TMHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prime Healthcare - CORVEL",
        "value": "CAPH2",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prime Property & Casulty",
        "value": "99588",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prime West Health Systems",
        "value": "81671",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PrimeCare Medical Network",
        "value": "04212",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PrimeCare Medical Network - Chino",
        "value": "SC016",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "PrimeCare Medical Network - Citrus Valley",
        "value": "IHS10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Primecare Medical Network - San Bernardino",
        "value": "ASFL1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PrimeCare, Wisconsin State and Federal Employees and Medicaid",
        "value": "82694",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prince Georges County Government (MD) - CORVEL",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Princeton Premier IPA",
        "value": "J3299",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Principal Financial Group",
        "value": "26337",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Principal Mutual Life Insurance Co.",
        "value": "STARS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Priority Health",
        "value": "ARA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prism - Univera",
        "value": "C1075",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prism Health Networks",
        "value": "13010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "prnLink-MLK",
        "value": "SNY01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ProCare - PremierCare IPA",
        "value": "SB601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ProCare (Prospect)",
        "value": "CCMMH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ProCare (TX)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Procare Health Plan - Medicaid",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prodegi",
        "value": "37308",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Productive Programming, Inc. (PPI)",
        "value": "36273",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Professional Benefit Administrators, Inc.",
        "value": "CX068",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Professional Claims Management - Canton, Ohio",
        "value": "64158",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Professional Health Care Network",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Casualty Insurance Company",
        "value": "22248",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Classic Insurance Company",
        "value": "74323",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Commercial Casualty Company",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Progressive Direct Insurance Company",
        "value": "770395311",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Progressive Insurance",
        "value": "TU127",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Progressive Max Insurance Company",
        "value": "J3844",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Medical Associates, Inc",
        "value": "37105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Northern Insurance Company",
        "value": "13343",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Northwestern Insurance Company",
        "value": "EPSDR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Progressive Preferred Insurance Company",
        "value": "93142",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progressive Premier Insurance Company",
        "value": "TRULI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Progressive Specialty Insurance Company",
        "value": "J3745",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Progyny",
        "value": "VAPRM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prominence Administrative Services",
        "value": "TMMC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prominence Health Plan",
        "value": "GHAOP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prominence Health Plan of Nevada",
        "value": "J1764",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prominence Health Plan of Texas",
        "value": "11153",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prominence Healthfirst Florida",
        "value": "LCO01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prospect Health Services of Texas",
        "value": "55204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prospect Medical Group",
        "value": "ICRCL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prospect Provider Group of Rhode Island",
        "value": "HM006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prospect Sherman Oaks Medical Group",
        "value": "14163",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Prosperity Life",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Protective Insurance",
        "value": "64090",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Protective Life Insurance",
        "value": "41205",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Protector 360",
        "value": "07689",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Providence Facility Claims",
        "value": "MNAUL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Providence Health Assurance",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Providence Health Plan",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Providence PACE CA",
        "value": "BCCCP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Providence PPO",
        "value": "31144",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Providence Preferred PPO",
        "value": "PHP00",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Providence Risk & Insurance",
        "value": "COBHI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provident American Life & Health Insurance Company (Medicare Supplement)",
        "value": "MCDMN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Provident Healthsource (CIGNA)",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provident Life & Accident",
        "value": "CB624",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provident Life & Casualty",
        "value": "CALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provident Preferred Network",
        "value": "36320",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provider Direct Network (HealthSmart Preferred Care)",
        "value": "MCI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provider Partners Health Plan of Maryland",
        "value": "MCDWV",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Provider Partners Health Plan of Missouri",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Provider Partners Health Plan of Ohio",
        "value": "58204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Provider Partners Health Plan of Pennsylvania",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Provider Partners Health Plan of Texas",
        "value": "94316",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ProviDRs Care Network",
        "value": "57080",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Prudent Medical Group",
        "value": "35193",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pruitt Health Premier",
        "value": "HMSA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Pruitt Health Premier NC & SC",
        "value": "52132",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "PSKW",
        "value": "45321",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Psychiatric Centers at San Diego (PCSD)",
        "value": "J1620",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Public Alliance Insurance Coverage Fund (PAIC Jif)",
        "value": "PABLS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Public Employees Health Plan (PEHP)",
        "value": "ASRM1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Public Employees Health Program",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Public Health Medical Services (PHMS)",
        "value": "GEHA9",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Public WC Program",
        "value": "IHPEN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Publix Super Markets, Inc.",
        "value": "17882",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Puget Sound Benefits Trust, Group# F25",
        "value": "95044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Puget Sound Electrical Workers Trust, Group# F33",
        "value": "PROSP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Puget Sound Workers Compensation Trust",
        "value": "61425",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Pure Insurance",
        "value": "89247",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QCA Health Plan",
        "value": "38238",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Quad Cities Community Health Plan (QCCH)",
        "value": "00520",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "QualCare (Managed Care Systems)",
        "value": "64158",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "QualCare Alliance Networks, Inc. (QANI)",
        "value": "MTNST",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QualCare IPA",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QualChoice Advantage",
        "value": "OMN02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QualChoice Health Insurance",
        "value": "PMAI2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QualChoice of Arkansas",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Quality Care IPA",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Quality Care Partners",
        "value": "19439",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Quartz",
        "value": "87815",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Quest Behavioral Health",
        "value": "J1896",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Quest EAP",
        "value": "66917",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Questcare Medical Services",
        "value": "77073",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Quick Trip Corporation",
        "value": "60818",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "QVI Risk Solutions Inc",
        "value": "44219",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rady Children’s Health Network (RCHN)",
        "value": "J1702",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rady Childrens Hospital San Diego",
        "value": "20029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rady’s Children’s Specialists of San Diego",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Raleys Family of Fine Stores - CORVEL",
        "value": "J1435",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ralph Lauren - CORVEL",
        "value": "89242",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "RAM Mutual Insurance Company",
        "value": "59355",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rampart Insurance Company",
        "value": "71070",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ramsey County (MN)",
        "value": "CMSEB",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rancho Santiago Community College District (CA) - CORVEL",
        "value": "86050",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "RAPHA IPA INC",
        "value": "36404",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ravenswood Physician Associates",
        "value": "MSO33",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Recology - CORVEL",
        "value": "84076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Recreational Equipment Inc. - CORVEL",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Red Oak Independent School District",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Redirect Health Administration",
        "value": "HMSA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Redlands-Yucaipa Medical Group",
        "value": "44054",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Redwood Community Health Coalition",
        "value": "23228",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Redwood Fire and Casualty Insurance Company (Member of BHHC)",
        "value": "87020",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Reedy Creek Improvement District",
        "value": "50653",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Regal Medical Group",
        "value": "00621",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Regence Blue Cross Blue Shield - Oregon",
        "value": "31626",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Regence Blue Shield - Idaho",
        "value": "CPNNP",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Regence Blue Shield of Washington State",
        "value": "52148",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Regence Group Administrators (RGA)",
        "value": "62181",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Regency Employee Benefits",
        "value": "IHS08",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Regent Medical Group, Inc. (Regent Family Practice of Glendale)",
        "value": "77220",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Region 8 Esc",
        "value": "16535",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Regional Care, Inc.",
        "value": "52876",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rehn and Associates",
        "value": "37257",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Reliance HMO, INC.",
        "value": "75136",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Reliance Standard Life",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Religious Order of Jehovah's Witnesses",
        "value": "J1453",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Renaissance Gulf Quest",
        "value": "31127",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Renaissance Physicians Organization",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Renassaince - River Oaks",
        "value": "13407",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Renassaince Riveroaks Blue",
        "value": "51062",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Republic Franklin Insurance",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Republic Indemnity Company of America",
        "value": "INET1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Republic Western Ins. Co",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Reserve National Insurance",
        "value": "95056",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Resolve Health Plan Administrators, LLC.",
        "value": "74101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "ResourceOne Administrators",
        "value": "88056",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "ResourceOne Administrators Tribal",
        "value": "38221",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Resurrection Health Care Preferred",
        "value": "57254",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Resurrection Physicians Group",
        "value": "49718",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Retail Business Services (RBS)",
        "value": "23287",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Retailer’s Casualty ",
        "value": "50114",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Retailer’s First ",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Retro Insulation INC",
        "value": "U7632",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Return to Work",
        "value": "J1927",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Revclaims",
        "value": "TPS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Richardson ISD (Tristar Risk Management)",
        "value": "37309",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Right Care from Scott & White",
        "value": "TKFMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Right Choice Benefit Administrators",
        "value": "52189",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rios Arizona IPA",
        "value": "84131",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rios Southwest Medical Group",
        "value": "77052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Risk Administration Services",
        "value": "81794",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Risk Enterprise Management (REM)",
        "value": "33628",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "River City Medical Group",
        "value": "VALOP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "River City Medical Group Senior",
        "value": "75274",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Riverport Insurance (MN School Board Association)",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Riverside Medical Clinic",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "RiverSpring Health Plans",
        "value": "39856",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "RMSCO, Inc.",
        "value": "93050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Robert F Kennedy Medical Plan",
        "value": "71065",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rocky Mountain Health Plan - Grand Junction",
        "value": "87020",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rocky Mountain HMO",
        "value": "41124",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rocky Mountain Pace",
        "value": "27327",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Roseburg Forest Products - CORVEL",
        "value": "MCI01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Royal Health Care",
        "value": "J1632",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Royal Healthcare on behalf of Extended MLTC",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Royale Resources ",
        "value": "CH00102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "RTG Medical Group",
        "value": "J3733",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Rural Carrier Benefit Plan",
        "value": "74323",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rush Prudential Health Plans - HMO Only",
        "value": "52095",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Rutgers Casualty Insurance for NJ Auto",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ryan White Network (RWN)",
        "value": "128MI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ryder Services",
        "value": "76045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "S & S Healthcare Strategies",
        "value": "38254",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SA Recycling, LLC - CORVEL",
        "value": "10301",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SafeCo Insurance",
        "value": "MCDMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Safeco Insurance (Auto Only)",
        "value": "00903",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SafeGuard",
        "value": "45289",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SafeGuard Health Enterprises, Inc",
        "value": "96385",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sagamore Health Network",
        "value": "54771",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sage Program",
        "value": "SC008",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sage Technologies",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Saint Agnes Medical Group",
        "value": "IMSMS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Saint Johns Health Clinic",
        "value": "17003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Salt Lake City Corporation - CORVEL",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Salvasen Health",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Samaritan Advantage (SA01)",
        "value": "75234",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Samaritan Choice (SCP)",
        "value": "J3922",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Samaritan Health Plans",
        "value": "20416",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Samaritan Healthy Kids (KID)",
        "value": "87843",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Samaritan Ministries International",
        "value": "BCSMT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SAMBA",
        "value": "PDT02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Samera Health",
        "value": "04312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "San Bernardino Medical Group (SBMED)",
        "value": "128WV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego Community College District (CA) - CORVEL",
        "value": "88027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego County Medical Services (CMS)",
        "value": "79480",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego County Physician Emergency Services (PES)",
        "value": "16089",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego County Primary Care Services",
        "value": "AEC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego County Ryan White Care Act",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego Neighborhood House Association",
        "value": "52106",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "San Diego PACE",
        "value": "AMDA2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Diego Physicians Medical Group",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "San Francisco Electrical Workers - IBEW Local 6",
        "value": "00710",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "San Francisco Health Plan",
        "value": "SHPS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Francisco Healthy Kids",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Francisco Municipal Transportation Agency (SFMTA)",
        "value": "95386",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "San Joaquin Health Administrators",
        "value": "J3946",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "San Mateo Healthy Kids",
        "value": "MCDLA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sana Benefits",
        "value": "63092",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SandHills Center",
        "value": "78375",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sanford Health Plan",
        "value": "78857",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sansum Clinic",
        "value": "J1956",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Santa Barbara Select IPA",
        "value": "38333",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Santa Clara County IPA ACO",
        "value": "87042",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Santa Clara County IPA HMO",
        "value": "CPN01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Santa Clara County IPA PPO",
        "value": "60601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Santa Clara Family Health Plan",
        "value": "40026",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Santa Clara Family Health Plan (SCFHP)",
        "value": "00890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SANTA MONICA UNITE HERE HEALTH BENEFIT PLAN",
        "value": "00451",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sante Community Medical Centers",
        "value": "CPPTM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sante Community Physicians",
        "value": "BCWFP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sante Medi-Cal",
        "value": "SVA01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Saudi Health Mission",
        "value": "00050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Savage Services - CORVEL",
        "value": "FOREN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Save Mart - CORVEL",
        "value": "03036",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SBM (Staff Benefits Management & Admin)",
        "value": "DDCA3",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scan Health Plan - California",
        "value": "75240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SCCIPA Anthem ACO",
        "value": "13202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SCCIPA Anthem PPO",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SCCIPA HMO",
        "value": "DMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SCCIPA VHP Covered California",
        "value": "72187",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SCHS ALTA Global Care Medical Group",
        "value": "46166",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SCHS Alta Pod by MedPOINT - Health Net",
        "value": "J1264",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SCIF (State Compensation Insurance Fund)",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Scion Dental",
        "value": "37105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Scott & White Health Care Plan",
        "value": "J1385",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Screen Actors Guild (SAG AFTRA)",
        "value": "NCCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Scripps Clinic",
        "value": "31405",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Scripps Coastal Medical Center",
        "value": "HAT02",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scripps Health Plan MSO",
        "value": "DVMC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scripps Health Plan Services",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scripps Medical Plan (Aetna)",
        "value": "00223",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Scripps Physicians Medical Group",
        "value": "WVS01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seabright (SBIC)",
        "value": "86050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seattle Area Plumbing",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Seaview IPA",
        "value": "J2090",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SECURA Insurance A Mutual Company",
        "value": "BC001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Secure Health",
        "value": "MPM44",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Secure Health Plans of Georgia, LLC",
        "value": "26337",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SecureCare Dental",
        "value": "23045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SecureOne",
        "value": "J1930",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Security Administrative Services, LLC (Cornerstone)",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Security Health Plan",
        "value": "45281",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sedgwick Claims Management Services (CMS)",
        "value": "46050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sedgwick World Trade Center",
        "value": "10878",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SEIU Local 1 Cleveland Welfare Fund",
        "value": "21172",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Select Administrative Services (SAS)",
        "value": "7166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Select Benefits Administrators",
        "value": "48330",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Select Benefits Administrators of America",
        "value": "63842",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Select Energy Services - CORVEL",
        "value": "49533",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Select Health of South Carolina",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Select Health of Utah",
        "value": "MC006",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Select Healthcare System, Inc.",
        "value": "CH00102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Select Senior Clinic",
        "value": "26832",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SelectCare of Texas (GTPA - Golden Triangle)",
        "value": "VACCN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SelectCare of Texas (Houston)",
        "value": "77003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SelectCare of Texas (HPN - Heritage Physicians)",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SelectCare of Texas (Kelsey-Seybold)",
        "value": "10462",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SelectCare of Texas (MCA – Memorial Clinical)",
        "value": "VALOP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SelectCare of Texas (NWDC)",
        "value": "62119",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Selective",
        "value": "APP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Selective Insurance",
        "value": "TMHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Self Insured Fund – MA",
        "value": "54704",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Self Insured Fund For FL Florists And Nurseries",
        "value": "37217",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Self Insured Plans",
        "value": "15202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Self Insured Services Company (SISCO)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Self-Funded Plans, Inc.",
        "value": "38265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Self-Insured Schools of California (SISC)",
        "value": "89249",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Selman and Company",
        "value": "14163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Selman Tricare Supplement Plans",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sendero IdealCare",
        "value": "GSHTX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sendero IdealCare SHP",
        "value": "128KS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Senior First IPA (dba ChoiceOne IPA )",
        "value": "FDH01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Senior Network Health",
        "value": "ECL01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Senior Whole Health Massachusetts",
        "value": "00230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Senior Whole Health New York (MLTC Only)",
        "value": "C1091",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentara Family Plan / Sentara Health Management",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentara Mental Health Professional",
        "value": "52461",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentinel Management Services",
        "value": "86050",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentinel Security Life Insurance Company",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sentry Auto",
        "value": "36331",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentry Casualty Company",
        "value": "SCNNM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sentry Insurance",
        "value": "38238",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sentry Insurance a Mutual Co.",
        "value": "SX107",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sentry Insurance A Mutual Company",
        "value": "12422",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentry Insurance Company Employees",
        "value": "MCDID",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sentry Life Insurance Company",
        "value": "PAS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sentry Select Insurance Company",
        "value": "20510",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seoul Medical Group",
        "value": "42943",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sequoia Health IPA",
        "value": "USI01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Serendib Healthways",
        "value": "13978",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Service Lloyds Insurance Co",
        "value": "NHC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seton Charity Plan",
        "value": "77076",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seton Health Plan - CHIP",
        "value": "DSL01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Seton Health Plan (Employee Plans ONLY)",
        "value": "J1566",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seton Health Plan Care Program",
        "value": "EPF37",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Seton Map Program (Mediview)",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seton Star",
        "value": "J1017",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Seven Corners",
        "value": "11502",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SGIC",
        "value": "CCHPC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Share Healthcare",
        "value": "251CC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Shared Health Mississippi",
        "value": "11789",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sharp Community Medical Group",
        "value": "81400",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sharp Health Plan",
        "value": "59324",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sharp Rees-Stealy Medical Group",
        "value": "J1564",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Shasta Administrative Services",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SHC Advantage",
        "value": "46407",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sheakley Unicomp",
        "value": "HALCY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sheboygan Falls Insurance Company",
        "value": "25169",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sheet Metal Workers Local 91",
        "value": "J4007",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Shelter Point Life",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sheltered Workshop Insurance Of Missouri - CORVEL",
        "value": "64556",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SIEBA, LTD",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sierra Health and Life - Encounters",
        "value": "11983",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sierra Health and Life (Claims)",
        "value": "31059",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sierra Medical Group (Regal)",
        "value": "IVHPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sierra Sacramento Valley Regional",
        "value": "16180",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Signature Advantage",
        "value": "00801",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SIHRTE",
        "value": "31625",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Silver Cross Managed Care Organization",
        "value": "MR057",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SilverSummit Healthplan - Medical",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Simply Health Care Plan",
        "value": "FEDMI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SIMPRA Advantage",
        "value": "EC999",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sinclair Health Plan",
        "value": "NW002",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SISCO (RCM&D Self Insured Services Company, Cost Containment Services)",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SISCO Benefits",
        "value": "00801",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SkyGen: DCMED",
        "value": "CANO1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Smith Administrators",
        "value": "00060",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Smoky Mountain Center (now Vaya Health)",
        "value": "BPS77",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Society Insurance",
        "value": "J3737",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Solarte Health",
        "value": "LV371",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Solidarity Healthshare",
        "value": "83269",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Solis Health Plans",
        "value": "25463",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Solstice Benefits",
        "value": "SX110",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SOMOS Anthem New York",
        "value": "81508",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SOMOS Emblem",
        "value": "20488",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sound Health & Wellness Trust (Aetna)",
        "value": "10365",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sound Health (now known as First Choice Health Network)",
        "value": "35202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sound Health (Sisters of Providence)",
        "value": "ECMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sound Health Wellness Trust",
        "value": "J1913",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Soundpath Health (formerly known as Puget Sound Health Partners)",
        "value": "89241",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "South Atlantic Medical Group",
        "value": "CAHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "South Carolina Healthy Connections",
        "value": "00265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Central Preferred",
        "value": "OSFC9",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Coast Area Transit (CA) - CORVEL",
        "value": "MCCOB",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Country Health Alliance",
        "value": "74284",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "South Dakota Behavioral Health - STARS / State Funding",
        "value": "59324",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Dakota DSS - STARS / State Funding",
        "value": "10101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "South Florida Community Care Network - SFCCN (Commercial)",
        "value": "J1033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Florida Community Care Network - SFCCN (Medicaid)",
        "value": "37330",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Florida Musculo Skeletal Care (SFMSC)",
        "value": "SHPCH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "South Plains School WC Program",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "South Tahoe Refuse",
        "value": "93029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SouthCare/Healthcare Preferred",
        "value": "37281",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southeastern Indiana Health (SIHO)",
        "value": "59204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern Benefit Services",
        "value": "99730",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southern California Children's Healthcare Network",
        "value": "J2004",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern California Dairy",
        "value": "47163",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern California Healthcare System (SCHS) Alta Pod by MedPOINT - Health Net",
        "value": "J4250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern California Lumber",
        "value": "86048",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southern California Oncology Associates (SCOA)",
        "value": "CCHP9",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southern California Oncology Associates (SCOA) - Encounters",
        "value": "VACCN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern California Physicians Managed Care Services (SCPMCS)",
        "value": "37312",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southern California UFCW Unions & Food Employers",
        "value": "35174",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern Illinois Healthcare Association",
        "value": "251CC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern Mutual Insurance Company",
        "value": "31401",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southern Textile Recycling, Inc. (CA) - CORVEL",
        "value": "35198",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southland Advantage Medical Group, Inc.",
        "value": "OSFE9",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southland IPA",
        "value": "13265",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southland San Gabriel Valley Medical Group, Inc.",
        "value": "56724",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southwest Key - CORVEL",
        "value": "SAMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southwest MI Behavioral Health - Mental Health",
        "value": "PROH2",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southwest MI Behavioral Health Substance Use Disorder",
        "value": "22284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southwest Oregon IPA (DOCS)",
        "value": "47046",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southwest Service Administrators",
        "value": "J1689",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southwest Service Life",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Southwestern Health Resources",
        "value": "38224",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Southwestern Health Resources Clinically Integrated Network",
        "value": "88023",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Space Exploration Technologies Corp - CORVEL",
        "value": "37215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Special Agents Mutual Benefit Association",
        "value": "00790",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Spencer Stuart (ARM, LTD)",
        "value": "00820",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Spina Bifida - VA HAC",
        "value": "LNC01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Spohn Health",
        "value": "HPSJ1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sprouts Farmers Market, Inc. - CORVEL",
        "value": "71412",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Spruce Grove Inc - CORVEL",
        "value": "11889",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SRC - Strategic Resource Company",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SRS - Cargill, Inc.",
        "value": "00241",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St James PHO",
        "value": "42344",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St Mary's IPA",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St Vincent Medical Center (STVMC)",
        "value": "14316",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Barnabas System Health Plan",
        "value": "95271",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Francis - AllCare by MedPOINT",
        "value": "MCDAL",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Francis - HCLA by MedPOINT",
        "value": "80241",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Francis IPA",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. John's Claim Administration",
        "value": "64246",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Johns Health Clinic",
        "value": "00890",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Joseph Heritage Healthcare",
        "value": "128VA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Joseph Heritage Medical Group",
        "value": "26362",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Joseph Hospital Affiliated",
        "value": "CCIHJ",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Jude Affiliated Physicians",
        "value": "15459",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Jude Heritage Medical Group",
        "value": "SVSVN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Mary High Desert",
        "value": "19046",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "St. Mary Medical Center",
        "value": "48603",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Peter Medical Group, Inc.",
        "value": "46227",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Therese Physician Associates",
        "value": "ITXEN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Thomas Med Ntwk Gulfquest",
        "value": "19432",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "St. Vincent IPA",
        "value": "81400",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Standard Insurance",
        "value": "INFIN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Standard Insurance Co. of New York",
        "value": "TMHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Standard Life & Accident Insurance Company",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Stanley Steemer International Inc - CORVEL",
        "value": "00870",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Stanley Works, Inc.",
        "value": "DDAK1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Starlife Holdings",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Starmark",
        "value": "40137",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Compensation Insurance Fund (SCIF) of California",
        "value": "31074",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Farm",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "State Farm Insurance Company",
        "value": "J3744",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Farm Property & Casualty",
        "value": "J1699",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Fund Mutual Insurance",
        "value": "ALIVI",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State Health Plan of North Carolina",
        "value": "SKUT0",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "State Health Plan of SC",
        "value": "TH131",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State of Idaho",
        "value": "00851",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "\"State of Idaho Women's Health Check",
        "value": "\"",
        "AveCallholdtime": "CPP03"
    },
    {
        "label": "State of Minnesota - DOER",
        "value": "DCMED",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "State of North Carolina - CORVEL",
        "value": "CDCMD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "State Of Tennessee - CORVEL",
        "value": "16158",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "State of Texas Dental Plan (GEHA)",
        "value": "IP084",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "State Office of Risk Management",
        "value": "13551",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Statewide Insurance Fund",
        "value": "35182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Staywell Health Plan",
        "value": "36273",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sterling Medicare Advantage",
        "value": "MMS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sterling Option One",
        "value": "56089",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Stirling Benefits",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Stone Mountain Risk",
        "value": "65055",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Stowe Associates",
        "value": "75600",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Student Insurance ",
        "value": "37264",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Student Resources (UnitedHealthcare)",
        "value": "19430",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Style Crest Enterprise",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sulphur Bluff ISD",
        "value": "HHMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Summacare Health Plan (HMO)",
        "value": "43160",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Summit Administration Services, Inc.",
        "value": "56213",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Summit America Insurance Services, Inc.",
        "value": "CB268",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Summit Community Care",
        "value": "MPM26",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Summit Holdings ",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sun Life Financial (United Concordia)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sun Mar Healthcare - CORVEL",
        "value": "SC050",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sunflower State - Medical",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sunrise Advantage Plan of Illinois",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sunrise Advantage Plan of New York ",
        "value": "58204",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sunrise Advantage Plan of Pennsylvania",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sunrise Advantage Plan of Virginia",
        "value": "18768",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sunshine State Health Plan (Ambetter) - Medical",
        "value": "DMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Superior Choice Medical Group ",
        "value": "J1619",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Superior Dental Care",
        "value": "CB404",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Superior Health Plan (Ambetter) - Medical",
        "value": "GNPMG",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Superior Insurance Services",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Superior Point",
        "value": "ACPCE",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Surest",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Surgery One IPA",
        "value": "SB810",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sussex Insurance Company",
        "value": "J1453",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sutter Connect - East Bay Region Hospitals - B&T",
        "value": "A0138",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sutter Connect – East Bay Region Hospitals – Non Sutter Groups",
        "value": "10153",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter Connect - Palo Alto Medical Foundation",
        "value": "00601",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sutter Connect - Solano Regional Medical Foundation (SRMF)",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sutter Connect - Sutter Delta Medical Group",
        "value": "10202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Sutter Connect - Sutter Gould Medical Foundation (SGMF)",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter Connect - Sutter Independent Physicians, Sutter Medical Group, Sutter West Medical Group  (SIP/SMG/SWMG)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter Connect - Sutter Medical Group of the Redwoods, Santa Rosa Network (SMGR/SRN)",
        "value": "44827",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter East Bay Medical Foundation",
        "value": "MPM20",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter Health",
        "value": "F7422700",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Sutter Senior Care",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "SVS Vision Inc.",
        "value": "19450",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Swedish Covenant Hospital",
        "value": "95677",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Swift Glass Corporation (UCS)",
        "value": "HCC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "SynerMed",
        "value": "J3765",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Syngenta Corporation",
        "value": "SC018",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "T.H.E. Insurance - CORVEL",
        "value": "94276",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "T2017 Tricare West (Previously known as UnitedHealthcare Military & Veterans)",
        "value": "VAPRM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tailored Brands Inc. - CORVEL",
        "value": "VACCN",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TakeCare Insurance Company",
        "value": "TMIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tall Tree Administrators",
        "value": "C1028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tawa Services, Inc. - CORVEL",
        "value": "39167",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Taylor Benefit Resource",
        "value": "1925460",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TCC (Self-Funded)",
        "value": "71037",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TCC Benefits Administrator - Pre-Med Defender",
        "value": "65093",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TCU-LA MTA",
        "value": "65456",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TDC (The Dental Companies)",
        "value": "77013",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Teal Premier",
        "value": "C1055",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Team Choice PNS",
        "value": "IP097",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Teamcare",
        "value": "J1241",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Teamsters Miscellaneous Security Trust Fund - Northwest Administrators",
        "value": "58182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Teamsters Welfare Trust",
        "value": "PBHD1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Technology Insurance Co",
        "value": "88052",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tech-Steel, Inc",
        "value": "MPM06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Teddy Health",
        "value": "34131",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Telamon",
        "value": "J3870",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Temecula Valley Medical Group",
        "value": "00430",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TennCare Select/ BlueCare",
        "value": "95202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tervist",
        "value": "NCA01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texan Plus",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TexanPlus of Texas (Houston)",
        "value": "13857",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TexanPlus of Texas (Kelsey-Seybold)",
        "value": "68035",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Agricultural Cooperative Trust (TACT)",
        "value": "J1503",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas Association of School Boards",
        "value": "J1617",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas Childrens Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Children's Health Plan",
        "value": "CX046",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Childrens Health Plan - Medicaid",
        "value": "MR046",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas First Health Plans",
        "value": "68024",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas HealthSpring",
        "value": "00800",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas Hospital Insurance",
        "value": "SC051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Independence Health Plan",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas Medicaid & Healthcare Partnership (TMHP)",
        "value": "03432",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Texas Municipal League - Intergovernmental Risk Pool",
        "value": "77950",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Mutual Insurance",
        "value": "SX155",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Roadhouse, Inc.",
        "value": "20452",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Texas Tech University Medical",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Alliance",
        "value": "00806",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Benefit Group, Inc.",
        "value": "MMFMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Boon Group",
        "value": "43168",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Care Network/The Savannah Business Group",
        "value": "44054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Cheesecake Factory - CORVEL",
        "value": "37278",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Chesterfield Companies",
        "value": "31182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The CHP Group",
        "value": "27327",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The City of Odessa",
        "value": "25923",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Colorado Purchasing Alliance",
        "value": "92579",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Gap, Inc. - CORVEL",
        "value": "07689",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Hartford",
        "value": "BLUEC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Health Exchange (Cerner Corporation)",
        "value": "J4253",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Health Plan",
        "value": "HPOUV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Health Plan of Upper Ohio Valley",
        "value": "HLPBS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Health Plan of West Virginia (Age <20)",
        "value": "ALTAM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Health Plan of West Virginia (Age 21+)",
        "value": "39045",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Health Plan of West Virginia (Medicaid)",
        "value": "VALOP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Health Plan of Western Illinois (HOWI)",
        "value": "MPM48",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Jones Financial Companies, LLP - CORVEL",
        "value": "89486",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Loomis Company",
        "value": "WVS01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Macaluso Group",
        "value": "MR085",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Main Street America Group / Austin Mutual - CORVEL",
        "value": "58234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Main Street America Group / Grain Dealers Mutual - CORVEL",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Main Street America Group / Great Lakes Casualty - CORVEL",
        "value": "62171",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Main Street America Group / Main Street America Assurance - CORVEL",
        "value": "15682",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Main Street America Group / MSA Insurance Protection - CORVEL",
        "value": "J1729",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Main Street America Group / MSAIC Surplus/Non-Admitted - CORVEL",
        "value": "SHPS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Main Street America Group / NGM - CORVEL",
        "value": "10253",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Main Street America Group / Old Dominion Insurance Company - CORVEL",
        "value": "DMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Mutual Group",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Physicians Alliance Corp.",
        "value": "CB987",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Preferred Healthcare System - PPO",
        "value": "USN01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Vitamin Shoppe - CORVEL",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Zenith",
        "value": "J3929",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "The Zenith - YMCA San Diego",
        "value": "12K89",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "The Zero Card LLC",
        "value": "25147",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Therapy Direct Network",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "THIPA",
        "value": "34192",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Thomas H. Cooper",
        "value": "MPM28",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Thomas McGee",
        "value": "J3747",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "THREE",
        "value": "U1439",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Three Rivers Preferred",
        "value": "PAMPR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Thrivent Financial",
        "value": "THRIV",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Thrivent Financial Aid Association for Lutheran Medicare",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Thrivent Financial Lutheran Brotherhood Medicare",
        "value": "C1096",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tiffany & Co - CORVEL",
        "value": "18768",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tift Regional",
        "value": "38692",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TKFMC",
        "value": "CB148",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TLC Advantage of Sioux Falls",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TLC Benefit Solutions",
        "value": "63092",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TML IRP - Texas Municipal League - Intergovernmental ",
        "value": "38217",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Todays Health",
        "value": "39151",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Together with CCHP",
        "value": "DDAL1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Toll Brothers - CORVEL",
        "value": "AMM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tongass Timber Trust",
        "value": "ILDTA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tooling and Manufacturing Assocation",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Torrance Hospital IPA (THIPA)",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Torrance Memorial Medical Center",
        "value": "11144",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Total Community Care",
        "value": "71499",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Total Plan Concepts",
        "value": "J3862",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Total Plan Services (via SmartData)",
        "value": "MR002",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Total Senior Care",
        "value": "31441",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Touchpoint Solutions / Touchpoint CRM)",
        "value": "71890",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Touchstone Health PSO",
        "value": "87065",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tower Energy Group",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tower Insurance Company Of New York",
        "value": "27726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tower National Insurance Company",
        "value": "23266",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Town of Colma (CA)",
        "value": "26375",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TR Paul, Inc.",
        "value": "HMSA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tractor Supply Company",
        "value": "29076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Transamerica Life Insurance Company (TLIC)",
        "value": "RMHMO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Transamerica Premier Life Insurance Company",
        "value": "AMM03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TransChoice - Key Benefit Administrators",
        "value": "SCP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Transplant Associates Baylor Billing",
        "value": "C1028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Transwestern General Agency",
        "value": "J2181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Transwestern Insurance Administrators, Inc",
        "value": "87815",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Travelers",
        "value": "CH00102",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Travelers Auto",
        "value": "PAIPA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Travelers Insurance",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Travis County MAP - Mediview",
        "value": "SHM01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trellis Health Partners",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TRIAD HEALTHCARE INC",
        "value": "HDNFC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Triada Assurance",
        "value": "SIPA1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Triangle Insurance",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tribado",
        "value": "TMHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tribute Health Plan of Arkansas",
        "value": "95166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tribute Health Plan of Oklahoma",
        "value": "20270",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tricare Active Reservists",
        "value": "MPM16",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TriCare East",
        "value": "52629",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tricare for Life",
        "value": "J1990",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tricare Overseas",
        "value": "51020",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tricare West",
        "value": "STVMC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TriCities IPA",
        "value": "NM505",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tri-City Healthcare District",
        "value": "91131",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tri-County Schools Insurance Group JPA  ",
        "value": "63240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trigon Blue Cross Blue Shield of Colorado",
        "value": "MC051",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trigon Blue Cross Blue Shield of Virginia",
        "value": "88250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trigon Insurance Company",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TriHealth Physician Solutions",
        "value": "ACC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trillium Community - Medicaid",
        "value": "27034",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trillium Community - Medicare",
        "value": "22945",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trillium Community Health Plans (Agate Resources/LIPA)",
        "value": "CB951",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trillium Staffing Solutions",
        "value": "SM325",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trilogy Network",
        "value": "34924",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trinity Universal Insurance Company",
        "value": "94582",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TriStar (formerly Select Benefit Administrators)",
        "value": "67136",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TriState Benefit Solutions",
        "value": "MCCVA",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tri-Valley Medical Group",
        "value": "64246",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TriWest Healthcare Alliance",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "TRLHN/AS",
        "value": "09908",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tru Blue TPA",
        "value": "22823",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TruAssure Dental",
        "value": "GATHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "True Health New Mexico",
        "value": "23250",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Truli Health",
        "value": "ARISE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trusted Health Plan",
        "value": "SLOS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trustee Plans (FCHN)",
        "value": "77799",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trusteed Insurance (FCHN)",
        "value": "68039",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Trusteed Plans Service Corporation",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TRUSTMARK                                                  ",
        "value": "SJHC1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Trustmark Insurance Company",
        "value": "65432",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "TSRDP Dental Claims",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Tufts Health Plan",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tufts Health Public Plan (Network Health)",
        "value": "STARS",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Twin City Fire Insurance Co.",
        "value": "71412",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Tyson Foods Inc.",
        "value": "13337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UC Davis Health System",
        "value": "10018",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UC Health Plan Administrators",
        "value": "CCHPC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UC Irvine",
        "value": "41147",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UC Irvine Health (MemorialCare)",
        "value": "SHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCare Individual & Family Plans (DOS on or After 1/1/2019)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCare Minnesota",
        "value": "DOCSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCare of Minnesota",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCLA Medical Group",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - Al Fe Heat",
        "value": "J1422",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - BASI: Lynco",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - BASI: Williams Plumbing & Heating",
        "value": "59227",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - Coeur: Connectivity Source",
        "value": "CTPL1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - Coeur: Genesis Park Management",
        "value": "ONEHC",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - Coeur: Hermann Sons Life",
        "value": "60230",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - Coeur: Leonard Holding Company",
        "value": "84365",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - Coeur: Master Mobile Link",
        "value": "77078",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - Coeur: Northwest Petroleum",
        "value": "J1477",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - Coeur: Sercel",
        "value": "KGA15",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS - Coeur: The Woodlands Christian Academy",
        "value": "81312",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - Insight Benefit Administrators",
        "value": "58213",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS - International Medical Group: FBOP Otisville",
        "value": "70408",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Air Design Heating & Cooling)",
        "value": "J1779",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: American Biotech Labs)",
        "value": "CSSD2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Belue Trucking Company)",
        "value": "HSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Big Horn Federal)",
        "value": "80141",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Ceiling Systems)",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: DC Welding & Construction)",
        "value": "SVIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Dunham Rubber & Belting Corporation)",
        "value": "C1088",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Empire Lumber Company)",
        "value": "PARTH",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Golden Eagle Construction)",
        "value": "86047",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Great Basin Industrial)",
        "value": "MSO22",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Hanson Trucking)",
        "value": "PHP00",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Idaho Asphalt Supply)",
        "value": "J1966",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Inland Construction & Development Co.)",
        "value": "10258",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Innovative Health Care Concepts)",
        "value": "73143",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Knight Construction & Supply)",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Nitro Green Helena)",
        "value": "96436",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Northwest River Supplies)",
        "value": "36334",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Raphael Health Center)",
        "value": "00773",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Reading Horizons)",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Rehab Strategies)",
        "value": "J1688",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Senior Suites at Woodside Village)",
        "value": "14212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Somont Oil Company)",
        "value": "NEXUS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: South State Trailer Supply)  ",
        "value": "91051",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Tempest Technologies)",
        "value": "SNTCC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Tenowo Inc.)",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: TrailWest Bank)",
        "value": "75137",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (BASI: Vans Inc)",
        "value": "95340",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (BASI: Whitefish Credit Union)",
        "value": "ARISE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (ELM-CO LLC)",
        "value": "SA001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS (Kempton Group TPA: Shawnee Milling Company)",
        "value": "45289",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS (The City of East Chicago)",
        "value": "06302",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS BASI Hotstart",
        "value": "AFNTY",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS BASI: Carey Johnson Oil",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS BASI: Consolidated Turbine",
        "value": "46299",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS BASI: Ellsworth Constructions",
        "value": "OSFC9",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS BASI: Limric Plumbing, Heating & Air",
        "value": "95567",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS BASI: Meter Group USA",
        "value": "16025",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS BASI: Smith Rogers Oil",
        "value": "36090",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS BASI: T.W. Clark Construction",
        "value": "62308",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS Benefit Plan Administrators: East Jefferson Hospital",
        "value": "75133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Benovation",
        "value": "12422",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Cement Masons",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Coeur Humphrey Associates",
        "value": "MRCHP",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Core: Nemco",
        "value": "TCC13",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS Core: TJR Equipment",
        "value": "MPM27",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS Couer: My Management Consulting inc (MY Wireless)",
        "value": "J1839",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS ES Beveridge & Associates Inc: Grace Schools",
        "value": "J4181",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS ES Beveridge & Associates Inc: Worthington Christian School",
        "value": "02057",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS PBS Oregon",
        "value": "34525",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Seminole Tribe of Florida",
        "value": "J3897",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS Tucker Administrators Inc: Cloninger Ford/Toyota",
        "value": "56162",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS: Benefit Plan Administrators of Eau Claire, Inc.",
        "value": "61425",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS: CAM Administrative Services, INC.",
        "value": "37242",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS: Coeur Plan Services- Pinnacle Medical Systems, Inc",
        "value": "00800",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS: Diversified Benefit Administrators, Inc.",
        "value": "26748",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCS: E.S. Beveridge & Associate Inc",
        "value": "J1418",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UCS: Fixtures & Drywall Company of OK, Inc. (FADCO, Inc.)",
        "value": "INPA1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UCSD Physician Network (UCSDPN)",
        "value": "72126",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UDR, Inc. - CORVEL",
        "value": "93093",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UFCW (Local Food & Commercial Workers) Local 400 & Employers Health and Welfare Fund",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UFCW Northern California &Drug Employers H & W Trust Fund",
        "value": "VAPRM",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UFT Welfare Fund",
        "value": "52193",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UHA Health Insurance",
        "value": "SIL01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UHP Management",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UHSM-Unite Health Share Ministries",
        "value": "00019",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UICI Administrators",
        "value": "75240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ultimate Health Plans",
        "value": "J3893",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ultra Benefits Inc.",
        "value": "65113",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UMass Medical School Health & Criminal Justice Program",
        "value": "MR007",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UMC Health Plan",
        "value": "43168",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Umpqua Health Alliance",
        "value": "HPN11",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UMR (formerly UMR Onalaska/Midwest Security/Harrington)",
        "value": "59144",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UMR (formerly UMR Wausau)",
        "value": "23856",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UMR Wausau",
        "value": "93029",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Umwa Health & Retirement Funds",
        "value": "76342",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Underwriters Safety and Claims",
        "value": "92135",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Underwriters Services Corporation",
        "value": "00241",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Unicare",
        "value": "37267",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Unicare of WV ",
        "value": "mpm57",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UNICARE Special Accounts",
        "value": "88027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unified Group Services",
        "value": "87765",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unified Health Services (Workman's Comp Only)",
        "value": "22321",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unified Life Insurance Company",
        "value": "18764",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unified Physicians Network",
        "value": "10530",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Uniform Medical Plan",
        "value": "FCC20",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Uniformed Services Family Health Plan",
        "value": "98010",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Unify HealthCare Administrators",
        "value": "HSMSO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UNIGARD (FCHN)",
        "value": "00430",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Union Construction Workers' Comp Program",
        "value": "74227",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Union Insurance Company of Providence",
        "value": "BKMD1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Union Pacific Railroad Employees Health Systems (UPREHS)",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unite Health Share Ministries",
        "value": "NCHON",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Unite HERE Health-LV",
        "value": "MMS01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Agriculture Benefit Trust",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Benefits (formerly Brown & Brown Benefits)",
        "value": "PDT01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Care Medical Group (UCMG) (Regal)",
        "value": "AXM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Claim Solutions",
        "value": "00590",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Concordia - Dental - PA Blue Shield (Provider will need to register with ucci and inform them that EDI Health group is their clearinghouse 800-633-5430 opt 0)",
        "value": "26375",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Concordia - Dental Plus",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Concordia - Dental Plus - PA Blue Shield",
        "value": "ECHP1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Concordia - Tricare Dental Plan",
        "value": "41202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Concordia (Encounters)",
        "value": "34189",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Concordia Companies, Inc.",
        "value": "ACP17",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Dominion Realty Trust ",
        "value": "GMICC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Employee Benefit Trust (FCHN)",
        "value": "41178",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Financial Casualty Company",
        "value": "05102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Fire Group (aka Mercer Insurance)",
        "value": "59140",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Food & Commercial Workers Unions and Food Employers Benefit Fund",
        "value": "10095",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Group Programs",
        "value": "UC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Health Services, Inc. dba Pruitt Health - CORVEL",
        "value": "37321",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Healthcare",
        "value": "84962",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United HealthOne / Golden Rule",
        "value": "27357",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Heartland",
        "value": "75137",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Medical Alliance (UMA)",
        "value": "C1084",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United Medical Resources",
        "value": "AHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Medical Resources (UMR)",
        "value": "68069",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United of Omaha",
        "value": "13360",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "United Physicians International (UPI)",
        "value": "37215",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United States Automobile Association (USAA)",
        "value": "COMMF",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "United States Fire Insurance Company",
        "value": "75386",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare ",
        "value": "40585",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare (UHC)",
        "value": "31145",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare / All Savers Alternate Funding",
        "value": "20416",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare / All Savers Insurance",
        "value": "95426",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare / Definity Health Plan",
        "value": "29018",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare / Empire Plan",
        "value": "CARMO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare / MAHP - MD IPA, Optimum Choice and MLH (formerly MAMSI)",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare / Oxford",
        "value": "EV001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare / Spectera Eyecare Networks",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare / UHIS - UnitedHealth Integrated Services",
        "value": "52214",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
        "value": "RCHN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare / UnitedHealthcare West (formerly PacifiCare)",
        "value": "J1027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan - Arizona",
        "value": "38217",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan - Kansas (KanCare)",
        "value": "J1550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan - New Jersey",
        "value": "NMM06",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan - Tennessee",
        "value": "39113",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / AZ (formerly Arizona Physicians IPA and APIPA)",
        "value": "52132",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan / CA, DE, FL, HI, IA, LA, MA, MD, MS CAN, NC, NE, NM, NY, OH, OK, PA, RI, TX, VA, WA, WI (formerly AmeriChoice or Unison)",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / IA, hawk-i (formerly AmeriChoice of Iowa)",
        "value": "MCDMO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / KS (KanCare)",
        "value": "SAMHP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / MI (formerly Great Lakes Health Plan)",
        "value": "86304",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / MS (formerly AmeriChoice MS - CHIP)",
        "value": "60061",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan / NE (formerly Americhoice NE, ShareAdvantage, and UnitedHealthcare of the Midlands)",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / NJ (formerly AmeriChoice NJ Medicaid, NJ Family Care, NJ Personal Care Plus)",
        "value": "CB212",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / NY (formerly AmeriChoice by UnitedHealthcare, AmeriChoice NY Medicaid & Child Health Plus, AmeriChoice NY Personal Care Plus)",
        "value": "31441",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan / SC (formerly Unison)",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / TN (formerly AmeriChoice TN: TennCare, Secure Plus Complete)",
        "value": "60601",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete - Oxford Medicare Network",
        "value": "22264",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete (formerly Evercare)",
        "value": "77044",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan / UnitedHealthcare Long Term Care (formerly Evercare)",
        "value": "RP075",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Community Plan / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
        "value": "GHIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Community Plan of Missouri",
        "value": "23284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Medicare Solutions / UnitedHealthcare Chronic Complete  (formerly Evercare)",
        "value": "37242",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Medicare Solutions / UnitedHealthcare Group Medicare Advantage",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareComplete (formerly SecureHorizons)",
        "value": "00801",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareDirect  (formerly SecureHorizons)",
        "value": "CHPWA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Medicare Solutions / UnitedHealthcare Nursing Home Plan  (formerly Evercare)",
        "value": "55489",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare NDC Claims",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Ohio Medicaid",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Ohio Medicaid Dental",
        "value": "J1722",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthcare Shared Services (UHSS)",
        "value": "CB148",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthcare Student Resources",
        "value": "J3735",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthOne / Golden Rule",
        "value": "48117",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UnitedHealthOne / PacifiCare Life and Health Insurance Company",
        "value": "73143",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UnitedHealthOne / UnitedHealthcare Life Insurance Company (formerly American Medical Security)",
        "value": "64157",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Unity Health Insurance",
        "value": "62308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Unity Health Partners",
        "value": "15682",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Univera Healthcare (Excellus)",
        "value": "74234",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Univera PPO (Excellus)",
        "value": "59064",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Universal Care (FFS)",
        "value": "35198",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Universal Care Encounters",
        "value": "26337",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Universal Fidelity Administrators Company - Aggregate",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Universal HealthCare IPA",
        "value": "25133",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Care Advantage",
        "value": "31456",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Family Care",
        "value": "38338",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Health Alliance",
        "value": "IEHP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Healthcare Group",
        "value": "16003",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Healthcare Marketplace",
        "value": "18151",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "University of Illinois",
        "value": "BS001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University of Illinois at Chicago Div of Specialized Care for Children",
        "value": "J3965",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "University of Kentucky Health Services - CORVEL",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University of Miami Behavioral Health",
        "value": "MPM47",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University of Missouri",
        "value": "J4253",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "University of Utah Health Plans",
        "value": "SX155",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Physicians Care Advantage",
        "value": "SHPS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Physicians Care Advantage (Arizona)",
        "value": "HMC01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Physicians Healthcare Group",
        "value": "COSNM",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "University Physicians Healthcare Group (Arizona)",
        "value": "BS001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "\"University Texas Medical Board",
        "value": "\"",
        "AveCallholdtime": "CB231"
    },
    {
        "label": "University Trust",
        "value": "77103",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Upland Medical Group",
        "value": "00590",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "UPMC Health Plan",
        "value": "44273",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UPMC Vision Advantage",
        "value": "87726",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Upper Peninsula Health Plan",
        "value": "CSSD2",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "US Benefits",
        "value": "MCS03",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "US Department of Labor",
        "value": "C1097",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "US Department of Labor - Black Lung",
        "value": "3833C",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "US Department of Labor - Energy",
        "value": "HTHTX",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "US Engagement, LLC",
        "value": "38692",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "US Family Health Plan of Texas and Louisiana (USFHP)",
        "value": "87071",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "US Imaging Network",
        "value": "00923",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "US Imaging Network II",
        "value": "36493",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "US Network and Administrative Services",
        "value": "J1497",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "US Sugar Corporation",
        "value": "SX105",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "USAA Auto Only",
        "value": "CHOC1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "USAA Life Insurance Company",
        "value": "04320",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "USFHP - St. Vincent Catholic Medical Centers of New York",
        "value": "UCCMB",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Utah Carpenters",
        "value": "88300",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Utah Laborers",
        "value": "23223",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Utah Pipe Trades",
        "value": "7166",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Utah Uninsured Employers Fund - CORVEL",
        "value": "MSM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Utica (Auto only)",
        "value": "56001",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Utica Mutual Insurance Co",
        "value": "10395",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Utica National Insurance Co of Texas",
        "value": "62111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "UTMB 3 Share Program",
        "value": "33600",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VA Community Care Network Region 1",
        "value": "SB580",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VA Community Care Network Region 2",
        "value": "KEE01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VA Community Care Network Region 3",
        "value": "J1937",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VA Fee Basis Programs",
        "value": "22149",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VA Financial Services Center",
        "value": "55204",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VA Health Administration Center ",
        "value": "MPM40",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vacorp",
        "value": "CC304",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Valir Pace",
        "value": "A0102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Valitas Health Services, Inc. dba Corizon Health - CORVEL",
        "value": "73288",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Valley Care IPA",
        "value": "U1439",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Valley Health Plan (Commercial)",
        "value": "13337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Valley Health Plan (Medi-Cal)",
        "value": "10850",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Valley Presbyterian Hospital Community Family Care VPRESCFC",
        "value": "37266",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Valley Presbyterian Hospital Preferred IPA VPRESPREF",
        "value": "23045",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vally Health Plan Encounters ",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Valor Health Plan",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Value Options (Beacon Health Options)",
        "value": "J1548",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Van Lang IPA",
        "value": "JPF01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vanliners Insurance Company",
        "value": "INP12",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vantage Health Plan",
        "value": "34192",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vantage Health Plan, Inc.",
        "value": "J1841",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vantage Medical Group",
        "value": "SCNKF",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vantage Medical Group Encounters",
        "value": "C1033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vantage Medical Group Encounters ",
        "value": "95606",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vantage Plus",
        "value": "HCMG1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Variable Protection Administrators (VPA)",
        "value": "94999",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Varipro",
        "value": "BKPNT",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vault AMPS Admin Services",
        "value": "COACC",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vaya Health (formerly Smoky Mountain)",
        "value": "59069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Velocity Vehicle Group - CORVEL",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Ventura County Health Care Plan (VCHCP)",
        "value": "PEF01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VentureNet Healthcare",
        "value": "77027",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Verdugo Hills Medical Group (Regal)",
        "value": "MCDOH",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Verity National Group",
        "value": "SCOAE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VestaCare",
        "value": "22149",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vestica Healthcare",
        "value": "SC020",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VGM Homelink",
        "value": "ASP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vibra Health",
        "value": "99320",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vida Care",
        "value": "BRGHT",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VieCare Life Beaver and Life Lawrence Counties",
        "value": "77799",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VieCare LIFE Butler",
        "value": "TCC93",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Viking Insurance Company of Wisconsin",
        "value": "HVPNI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Village Health Plan",
        "value": "61101",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "VillageCareMAX",
        "value": "35202",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VillageMD of Northern Indiana",
        "value": "89641",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Virginia Health Network, Inc.",
        "value": "J1430",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Mason Group Health",
        "value": "06226",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier CompleteCare",
        "value": "68069",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier Elite",
        "value": "22175",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier Elite Plus",
        "value": "77073",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Virginia Premier Gold",
        "value": "25831",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier Health Plan",
        "value": "37105",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier Health Plan (Medicaid)",
        "value": "22100",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Virginia Premier Individual Health Plan",
        "value": "C1033",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Virginia Premier Medallion 4.0",
        "value": "01260",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Virginia Risk Sharing (VRSA)",
        "value": "75284",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vista Health Plan",
        "value": "56132",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vital Protection",
        "value": "57116",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vitality Health Plan of CA FFS",
        "value": "36401",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vitruvian Care Inc",
        "value": "07202",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Viva Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vivida Health",
        "value": "55892",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vivida Health Plan",
        "value": "TTUMD",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VNA Homecare Options",
        "value": "37121",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "VNS Choice SelectCare",
        "value": "16109",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Volunteer States Health Plan",
        "value": "59573",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Volusia Health Network",
        "value": "80900",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vons / Safeway",
        "value": "38308",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Vulcan Materials - CORVEL",
        "value": "HOSH1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Vytra Health Plans (Emblem)",
        "value": "MPM53",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "W.C. Beeler & Company",
        "value": "LNJ01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WA Teamster",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wadena",
        "value": "FCD01",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wagner Meinert",
        "value": "MMFCS",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wal-Mart Corporate",
        "value": "KGA15",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Washington County Health and Human Services",
        "value": "J1500",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Washington Dental Service",
        "value": "37135",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Washington Gas",
        "value": "80141",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Washington State Health Insurance Pool  (WSHIP)",
        "value": "KYCS1",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Waterstone Benefit Administrators",
        "value": "75280",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Watts Health Care",
        "value": "87726",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WC Solutions",
        "value": "INP12",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WE Energies",
        "value": "IHS10",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WEA Insurance Group",
        "value": "47076",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WEA TRUST",
        "value": "33632",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Web TPA / Community Health Electronic claims / CHEC",
        "value": "66004",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wegmans",
        "value": "J1249",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Weiss Health Providers",
        "value": "75678",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WelbeHealth",
        "value": "UCDMG",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Welcome Health",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Well Sense Health Plan",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellcare (Admin by LIBERTY Dental Plan)",
        "value": "PROSP",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellcare by Health Net",
        "value": "86027",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellCare Health Plan",
        "value": "PHPMI",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellCare Health Plans",
        "value": "86242",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellcare Health Plans - Encounters",
        "value": "SMW01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellCare HMO",
        "value": "MHPIL",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellCare Private FFS",
        "value": "MPM04",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellmark BCBS",
        "value": "CAPMN",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellMed Medical (Claims)",
        "value": "39081",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WellMed Medical (Encounters)",
        "value": "38224",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WellNet Health Plans",
        "value": "00091",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellPay",
        "value": "76255",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellpoint Dental Services",
        "value": "SCNCA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wellpoint Maryland",
        "value": "58182",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WellSpace Nexus LLC",
        "value": "BHP01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellSpan EAP",
        "value": "77039",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WellSpan Employee Assistance Program",
        "value": "40026",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WellSystems, LLC",
        "value": "35245",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wenatchee Valley Medical Center",
        "value": "83028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "West Bend Mutual Insurance",
        "value": "23282",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "West Covina Medical Group (Regal)",
        "value": "J1899",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "West Regional Physicians Network Inc",
        "value": "J1550",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "West Suburban Health Providers",
        "value": "62171",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "West Virginia Senior Choice",
        "value": "26879",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Agricultural Insurance Company",
        "value": "61101",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Growers Assurance Trust",
        "value": "CARMO",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Western Growers Insurance Company",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Health Advantage",
        "value": "25667",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Health Advantage (PHTech)",
        "value": "43905",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Western Mutual Insurance (WMI)",
        "value": "10378",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western National Assurance",
        "value": "81085",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Western National Insurance \\ Pioneer Specialties",
        "value": "15460",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Western Oregon Advanced Health",
        "value": "04218",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Sky Community Care",
        "value": "58203",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Western Southern Financial Group",
        "value": "OBA16",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Western Utilities or Local 57",
        "value": "NASCR",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Westfield Insurance",
        "value": "EYEN1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WestGUARD Insurance Company - Guard DBA",
        "value": "00590",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Westlake Financial",
        "value": "44273",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "White Memorial Altamed Medical Group",
        "value": "STJOE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "White Memorial Medical Center",
        "value": "NMM01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WI State Employees - Managed Health Services",
        "value": "J1835",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "WI State Employees - Physicians Plus Insurance Company",
        "value": "91136",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Willamette Valley Community Health CCOA - Capital Dental Care",
        "value": "C1028",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "William C. Earhart Company",
        "value": "25924",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "William J Sutton & Co. Ltd (Toronto)",
        "value": "10111",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WILLIAMSON COUNTY GOVERNMENT - CORVEL",
        "value": "39640",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Willow Health",
        "value": "62179",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wilson Mutual Insurance (All States EXCEPT MN/WI)",
        "value": "J1491",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wilson-McShane",
        "value": "13337",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wilson-McShane: International Brotherhood of Boilermakers",
        "value": "FC001",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Windsor Sterling Health Plan ",
        "value": "LMG01",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WINhealth Partners",
        "value": "06102",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Winston Hospitality",
        "value": "AARP1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wisconsin Assigned Risk Plan (HIRSP)",
        "value": "J1465",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wisconsin Department of Corrections",
        "value": "CMSEB",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Wisconsin Physicians Service (WPS) Commercial",
        "value": "76251",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WJ Cox Associates, Inc.",
        "value": "GI813",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Women's Integrated Network (WIN Health)",
        "value": "60054",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Workers Compensation And Employer Liability",
        "value": "62308",
        "AveCallholdtime": "> 1 hours",
        "PayerType": "special"
    },
    {
        "label": "Worksite Benefit Services, LLC",
        "value": "47738",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Worthington Industries - CORVEL",
        "value": "CPP08",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WPAS (FCHN)",
        "value": "PFIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WPPN- HPO (HealthEOS)",
        "value": "HPIPA",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WPS Arise (Prevea)",
        "value": "BTHS1",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WPS Health Insurance",
        "value": "N10917",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Writer's Guild Industry Health Plan",
        "value": "IP079",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "WSHIP - Washington State Health Insurance Pool",
        "value": "10093",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Wyoming Health Solutions",
        "value": "25133",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Xantus Health Plan",
        "value": "AMM07",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Xcel Energy",
        "value": "89240",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Ximed Medical Group",
        "value": "ARISE",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Xylem Inc. - CORVEL",
        "value": "45488",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Yamhill CCO",
        "value": "CX014",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Yamhill COO Physical Health",
        "value": "MC053",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Yerington Paiute Tribe",
        "value": "ATRIO",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "York Claim Services",
        "value": "25667",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "YourCare Health Plan",
        "value": "73147",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Zachry Construction",
        "value": "CCHP9",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Zenith (FCHN)",
        "value": "MR025",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Zenith American Solutions - ILWU-PMA",
        "value": "73147",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Zenith Insurance Co",
        "value": "J1659",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Zing Health",
        "value": "96240",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Z-Nat Insurance (Zenith National)",
        "value": "S9637",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Zoe Holding Company Inc. - CORVEL",
        "value": "21062",
        "AveCallholdtime": "> 30 Mins",
        "PayerType": "special"
    },
    {
        "label": "Zurich Farmers",
        "value": "89677",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    },
    {
        "label": "Zurich Insurance",
        "value": "NCHOR",
        "AveCallholdtime": "< 30 mins",
        "PayerType": "general"
    }
]



module.exports=arr