import React, { useEffect } from "react";
import "../Operation/operation.css";
import { useState } from "react";
// import OperationMainForm from "./OperationMainForm";

import { useSelector } from "react-redux";
import { fetchProduct, getFirstThreeFormRecord, getTlRecordsApi } from "../../Utils/ApiManager";
import moment from "moment";
import FormType from "../Operation/FormType/FormType";
import FormTypeTL from "./FormTypeTl";
import TLMainForm from "./TLMainForm";


function TLInterface() {
  const [formType, setFormType] = useState(null);
  const [formTypeNoForApi, setFormTypeNoForApi] = useState(null);
  const [practiceId, setPracticeId] = useState(null);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [record, setRecord] = useState(null);
  const { onLoginSignOff } = useSelector((state) => state);
  const [subCheckBox,setSubCheckBoxCharge]=useState('')
  const [tab, setTab] = useState(1);
  const handleTab = (val) => {
    setTab(val);
  };
  useEffect(() => {
   
    if (formType && practiceId && date) {
      //CALL aPI
      if (
        formType == "AmountVerification" || formType=="PaymentsVerification"
       
      ) {
        getRecordApi();
      }
      
    }
  }, [formType, practiceId, date]);
  const getRecordApi = async () => {
    const formDataForApi = new FormData();
    formDataForApi.append("userid", onLoginSignOff.userid);
    formDataForApi.append("sdate", date);
    formDataForApi.append("pid", practiceId);
    formDataForApi.append("formtype", formTypeNoForApi);
    formDataForApi.append("submittype", 'Yes');
   

    let result = await getTlRecordsApi(formDataForApi);
    setRecord(result.data);
  };

  const handleFormTypeAndPract = ({ name, val }) => {
  
   
   
    if (name == "date") {
      setDate(val);
      return;
    }
    if (name == "formType") {
      
      if (val == "AmountVerification") {
        setFormTypeNoForApi(11);
        setFormType(val);
      }
      if(val=="PaymentsVerification"){
        setFormTypeNoForApi(12);
        setFormType(val);

      }
    
      
      if (val == "DeniedVerification") {
        setFormTypeNoForApi(13);
        setFormType(val);
      }
      if (val == "AccountRecVerify") {
        setFormTypeNoForApi(14);
        setFormType(val);
      }
      
      
     

      return;
    }
    if (name == "practiveVal") {
      setPracticeId(val);
      return;
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      setProducts(optionList);
    }
  };
  const handleSubCheckBoxChargeEntry=(val)=>{
    setSubCheckBoxCharge(val)

  }

  return (
    <>
      <div className="row">
        <div className="col-lg-9">
          <div class="container ">
            <div class="p-4 navcolor bg-white rounded  mb-5">
              <div>
              <TLMainForm  record={record} practiceId={practiceId}  formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} subCheckBox={subCheckBox}/>
                {/* <OperationMainForm formType={formType} record={record} date={date} formTypeNoForApi={formTypeNoForApi} /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div class="container">
            <div className="p-3 navcolor bg-white rounded shadow ">
              <FormTypeTL
              handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry}
              
                date={date}
                handleFormTypeAndPract={handleFormTypeAndPract}
                products={products}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TLInterface;
