import React from "react";


import Meeting from "../ChargeEntry/Meeting";
import Reports from "../ChargeEntry/Reports";
import DenialVerification from "./DenialVerification";

const Denials = ({ subCheckBox,formTypeNoForApi,date,formType,practiceId,record }) => {
  console.log('subCheckBox',subCheckBox)
  return (
  
   <>
   { subCheckBox == "DeniedVerification" ? (
     <DenialVerification />
    ) : subCheckBox == "MeetingDenied" ? (
      <Meeting  record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    ) : subCheckBox == "ReportsDenied" ? (
      <Reports record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId}/>
    ) : null}
   </>
    
     
  );
};

export default Denials;
