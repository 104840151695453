import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  insertFormType7AccountReceivable,
  onSaveApi,
} from "../../../../Utils/ApiManager";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import ImageUploaderr from "../../../../Common/FileUpload";



import Dateoofservice from "../../../../Common/Dateoofservice";
import { useDispatch } from "react-redux";
import {
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "../../AllForms/CommonDos";
import { handleCloseC } from "../../AllForms/CommonDos";
import { Schema } from "rsuite";
import { createValidationSchema } from "../../../../Common/Validation";
import DateAndPractice from "../../AllForms/DateAndPractice";
import DateoofserviceGlobal from "../../../../Common/DateofServiceGlobal";
import NotifySwal from "../../../../Common/NotifySwal"
import moment from "moment/moment";
import { AllConfirmationOnTab } from "../../../../Actions/Actions";
const SpecialPayers = ({
  onChangePayer,
  payerSelection,
  handleFormTypeAndPract,
  productss,
  setNameGeneric,
  formTypeNoForApi,
  date,
  record,
  practiceId,
  onRecordUpdateOrCreated,
}) => {
  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");
  let helpingObj={
    isSpeakToaster:false,
    loadingn:false,
    isSpeakerToaster:false,
    isBackSpeak:false
  }
  const [helpingState,sethelpingState]=useState(helpingObj)

  let errorobj = {
    recievedAmount: false,
    AmountDosVal: false,
    date: false,
  };
  let footerObj = {
    isAllow: true,
    receivedClaims: 0,
    receivedAmount:0,
    CollectedAmount:0,
   

    workedClaims: 0,

    pendingClaims: 0,
    workedBreakupTotal: 0,
   
    PendingAmount:0,
    workedBreakupTotal: 0,
  };
  const [footerState, setFooterState] = useState(footerObj);
  const { StringType, NumberType, DateType } = Schema.Types;

  const [errorModel, setErrorModel] = useState(errorobj);
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const { onLoginSignOff } = useSelector((state) => state);
  const [docViewer, setDocViewer] = useState(false);
  const [AmountDosRec, setAmountDosRec] = useState([]);
  const [dosModel, setDosModel] = useState(false);
  const [handledosDate, setdosDate] = useState("");
  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [handleInputdos, sethandleInput] = useState("");
  const [renderEffect, setRenderEffect] = useState(false);
  const [TotalAll, setTotalAll] = useState(0);
  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);
  const [openModelPending, setModelPending] = useState(false);
  const [bfRow, setisThisbfRow] = useState(false);

  // new thingd

  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [totalClaims, setTotalClaims] = useState(false);

  const [globalDos, openGlobalDos] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [rowID, setRowID] = useState(null);
  let breakupObj = {
    scp: 0,
    sca: 0,
    scr: 0,
    sct: 0,
    scs: 0,
    total: 0,

    scpa: 0,
    scaa: 0,
    scra: 0,
    scta: 0,
    scsa: 0,
    totala: 0,
  };
  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);

  let objAmount = {
    recAmountGen: "",
    CollectAmountGen: "",
    pendAmuontGen: "",
  };

 const [stateAmount, setStateAmount] = useState(objAmount);

  useEffect(() => {
    const { scp, sca, scr, sct, scs, total,scpa,scaa,scra,scta,scsa,totala } = AllBreakupVal;

    if (scp || sca || scr || sct || scs) {
      let totalValue =
        (parseInt(scp) || 0) +
        (parseInt(sca) || 0) +
        (parseInt(scr) || 0) +
        (parseInt(sct) || 0) +
        (parseInt(scs) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }

    if (scpa || scaa || scra || scta || scsa) {
      let totalValue =
        (parseInt(scpa) || 0) +
        (parseInt(scaa) || 0) +
        (parseInt(scra) || 0) +
        (parseInt(scta) || 0) +
        (parseInt(scsa) || 0);
      setBreakupVal((prev) => ({ ...prev, totala: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, totala: 0 }));
    }
  }, [
    AllBreakupVal.scp,
    AllBreakupVal.sca,
    AllBreakupVal.scr,
    AllBreakupVal.sct,
    AllBreakupVal.scs,
    AllBreakupVal.total,


    AllBreakupVal.scpa,
    AllBreakupVal.scaa,
    AllBreakupVal.scra,
    AllBreakupVal.scta,
    AllBreakupVal.scsa,
    AllBreakupVal.totala,
  ]);


  const handleChangeAmount = (e) => {
    const { name, value } = e.target;

    setStateAmount((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;
    if (value == "") {
      setBreakupVal((prevState) => ({
        ...prevState,
        [name]: 0,
      }));
      return;
    }
    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onHandlePendingClaimss = (val) => {
    setPendingClaimss(val);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: false ? "white" : "black",
      textSize: "14px",
      backgroundColor: false ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  let obj = {
    userid: "",
    sdate: "",
    pid: "",
    formtype: "", //
    submittype: "",
    generalpayers: 0,
    generalpayerName: "",
    generalpayerID: "",

    specialpayerName: "",
    specialpayerID: "",
    bfval: "", //
    recievedval: 0, //
    workedval: 0,
    pendingval: 0, //
    specialpayers: "",
    sbf: "",
    srecieved: "",
    sworked: "",
    spendingval: "",
    comments: "",
    breakupval: {
      gcp: "0",
      gca: "0",
      gcr: "0",
      gct: "0",
      gcs: "0",
    },
    sbreakval: {
      scp: 0,
      sca: 0,
      scr: 0,
      sct: 0,
      scs: 0,

      scpa: 0,
      scaa: 0,
      scra: 0,
      scta: 0,
      scsa: 0,
    },

    GeneralOpen: "",
    insurance: "",
    stoken: "",
    image: "",
    totalval: "",
    dosArray: [],
  };
  const [result, setResult] = useState(obj);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [products, setProducts] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const openDosmodel = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setDosModel(true);
    setNameGeneric("Worked Claims");
  };
  const onSaveDos = () => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(result.dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = result.dosArray;
    }

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el.recievedAmount);
      });

    setResult((prevResult) => ({
      ...prevResult,
      sworked: count,
    }));
    setNameGeneric("Account Receivable");

    setBreakupVal(breakupObj);
    setDosModel(false);
    onHandlePendingClaimss(0);
  };

  console.log("pending", pendingClaimss);

  const openDosmodelPending = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Pending Claims");

    setModelPending(true);
  };



  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }
  

    let pending = 0;
    let recievedAmountCC = 0;
    let totalBreakup = 0;
    
    let RecievdAmount=0;
    let collectedAmount=0;
    let pendingAmount=0

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el.recievedAmountCC); //REC PAT STAT

        recievedAmountCC += parseInt(el.recievedAmount); //WORKED
        pending += parseInt(el.pending); //dENEID
        totalBreakup += parseInt(el.pending);
        RecievdAmount += parseInt(el?.recievedAmountgGen);
        collectedAmount += parseInt(el?.collectedAmountgGen);
        pendingAmount += parseInt(el?.pendinGen);
      });

    setFooterState((prev) => ({
      ...prev,
      receivedClaims: count,
      //  workedBreakupTotal:totalBreakup,
      workedClaims: recievedAmountCC,

      pendingClaims: pending,

      receivedAmount:result?.RecievdAmount,

      CollectedAmount: result?.collectedAmount,
      PendingAmount:result?.pendingAmount

    }));
  }, [dosArrayRecieved]);
  const handleEditState = async (rowData) => {
    if (!rowData.hasOwnProperty("status")) {
      return;
    }
    const nextData = Object.assign([], result.dosArray);
    const activeItem = nextData.find((item) => item.id === rowData.id);
    console.log(activeItem.status, "bug here", nextData);
    activeItem.status = activeItem.status ? null : "EDIT";
    // setDosArray(nextData);
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };
  const CustomInputGroupWidthButton = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={styles}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );
  const onChangeInput = (val) => {
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }));
  };
  const onChangeDate = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;

    let results = result?.dosArray.some((el) => el.date === formattedDate);
    if (results) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }

    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,
    }));
  };
  let nextData;
  const handleChangeeob = (id, key, value, event) => {
    event.persist();

    nextData = Object.assign([], result.dosArray);
    nextData.find((item) => item.id === id)[key] = value;
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };
  const styles = {
    marginBottom: 10,
  };
  const onAdddos = async () => {
    let results = result.dosArray.some((el) => el.date === handledosDate);
    if (results) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }
    if (parseInt(handleInputdos) != parseInt(AllBreakupVal.total)) {
      toast.info("Worked Claims should be equal to total Worked Breakup ");
      return;
    }

    let obj = {
      date: handledosDate,
      recievedAmount: handleInputdos,
      status: null,
      id: result.dosArray.length + 1,

      scp: AllBreakupVal.scp,
      sca: AllBreakupVal.sca,
      scr: AllBreakupVal.scr,
      sct: AllBreakupVal.sct,
      scs: AllBreakupVal.scs,
      total: AllBreakupVal.total,
      Pending: pendingClaimss,
    };

    const validationFields = [
      {
        fieldName: "recievedAmount",
        rules: { requiredMessage: "Received amount is required." },
        type: { typeError: StringType() },
      },
      // {
      //   fieldName: 'AmountDosVal',
      //   rules: { requiredMessage: 'AmountDosVal is required.' },
      //   type: { typeError: StringType() }

      // },
      {
        fieldName: "date",
        rules: { requiredMessage: "Date is required." },
        type: { typeError: DateType() },
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);
    const errors = validationSchema.check(obj);
    // if (errors.AmountDosVal.hasError) {
    //   setErrorModel((prevErrorModel) => ({
    //     ...prevErrorModel,
    //     AmountDosVal: true,

    //   }));

    // }
    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
      }));
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
      }));
    }
    if (!errors.recievedAmount.hasError && !errors.date.hasError) {
      setResult((prevResult) => ({
        ...prevResult,
        dosArray: [...prevResult.dosArray, obj],
      }));
    }
  };
  const handleClose = () => {
    openGlobalTable(false);
    setdosDate("");
    sethandleInput(null);
    setPendingClaimss(0);
    setisPendingSectionOpen(false);
    setBreakupVal(breakupObj);
    setDosModel(false);
    sethandleInput("");
    setNameGeneric("Account Receivable");

    setdosDate("");
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
      AmountDosVal: false,
      date: false,
    }));
  };
  useEffect(() => {
    if (date) {
      onclear();
    }
  }, [date]);



  const dispatch = useDispatch();

  useEffect(() => {
    if(globalDos){
      dispatch(AllConfirmationOnTab(true))

    }else{
      dispatch(AllConfirmationOnTab(false))

    }

    
  }, [globalDos])

  useEffect(() => {
    let check = result.sbreakval;
    let totalVal;
    if (check.scp || check.sca || check.scs || check.scr || check.sct  || check.scpa || check.scaa || check.scsa || check.scra || check.scta) {
      totalVal =
        (parseInt(check.scp) || 0) +
        (parseInt(check.sca) || 0) +
        (parseInt(check.scs) || 0) +
        (parseInt(check.scr) || 0) +
        (parseInt(check.sct) || 0);

      setResult((prevResult) => ({
        ...prevResult,

        totalval: totalVal,
      }));
    } else {
      setResult((prevResult) => ({
        ...prevResult,

        totalval: totalVal,
      }));
    }
  }, [
    result.sbreakval.scp,
    result.sbreakval.sca,
    result.sbreakval.scr,
    result.sbreakval.sct,
    result.sbreakval.scs,
  ]);
  console.log("result?.srecieved", result?.srecieved);
  useEffect(() => {
   
    if (result?.srecieved || result?.sworked) {
      let val = Number(result?.srecieved) + Number(result?.sbf);
      const pendingval = val - Number(result?.sworked);
      console.log(pendingval, "pendingval");
      setResult((prevResult) => ({ ...prevResult, spendingval: pendingval }));
    }
  }, [result?.srecieved, result?.sworked]);

  const onChange = (e) => {
    let { name, value } = e.target;
    if (name == "name") {
      setName(value);
      // setResult((prev) => ({
      //   ...prev,
      //   specialpayerName: value,
      // }));
    } else {
      setId(value);
      // setResult((prev) => ({
      //   ...prev,
      //   specialpayerName: value,
      // }));
    }
  };

  const saveList = () => {
    

    if (name !== "" && id != "") {
      let label = `${name}-${id}`;
      setProducts((prev) => [...prev, { label, id }]);
      setId("");
      setName("");
      setMinimize((prev) => !prev);
    } else {
      toast.info("Please Enter Name and ID");
    }
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      let a = 0;
      const sbreakval = `{
        "scp": "${parseInt(result.sbreakval?.scp, 10)}",
        "sca": "${parseInt(result.sbreakval?.sca, 10)}",
        "scr":  "${parseInt(result.sbreakval?.scr, 10)}",
        "sct":  "${parseInt(result.sbreakval?.sct, 10)}",
        "scs":  "${parseInt(result.sbreakval?.scs, 10)}"
      }`;

      const breakupval = `{
        "gcp": "${parseInt(a)}",
        "gca": "${parseInt(a)}",
        "gcr": "${parseInt(a)}",
        "gct": "${parseInt(a)}",
        "gcs": "${parseInt(a)}"
      }`;

      const formDataForSaveApi = new FormData();
      formDataForSaveApi.append("userid", onLoginSignOff.userid);
      formDataForSaveApi.append("sdate", date);
      formDataForSaveApi.append("pid", practiceId);
      formDataForSaveApi.append("formtype", formTypeNoForApi);
      formDataForSaveApi.append("generalpayers", "no");

      formDataForSaveApi.append("bfval", result?.bfval);

      formDataForSaveApi.append("recievedval", result.recievedval);
      formDataForSaveApi.append("insurance", result.insurance);

      formDataForSaveApi.append("workedval", 0);
      formDataForSaveApi.append("GeneralOpen", result.GeneralOpen);
      formDataForSaveApi.append("pendingval", result.pendingval);
      formDataForSaveApi.append("stoken", result.stoken);
      formDataForSaveApi.append("submittype", "yes");
      formDataForSaveApi.append("file", result.image);
      formDataForSaveApi.append("specialpayers", "yes");
      formDataForSaveApi.append("sbf", result.sbf);
      formDataForSaveApi.append("srecieved", result.srecieved);
      formDataForSaveApi.append("sworked", result.sworked);
      formDataForSaveApi.append("spendingval", result.spendingval);
      formDataForSaveApi.append("breakupval", breakupval);
      formDataForSaveApi.append("sbreakval", sbreakval);

      formDataForSaveApi.append("generalpayerName", result?.generalpayerName);
      formDataForSaveApi.append("generalpayerID", result?.generalpayerID);

      formDataForSaveApi.append("specialpayerName", result?.specialpayerName);
      formDataForSaveApi.append("specialpayerID", result?.specialpayerID);
      formDataForSaveApi.append("comments", result?.comments);
      formDataForSaveApi.append("total", result?.totalval);
      formDataForSaveApi.append("dosArray", JSON.stringify(result?.dosArray));
      formDataForSaveApi.append(
        "dosArrayRecieved",
        JSON.stringify(dosArrayRecieved)
      );

      formDataForSaveApi.append("ReceivedAmountTotal", result?.ReceivedAmountTotal);
    formDataForSaveApi.append("CollectedAmountTotal", result?.CollectedAmountTotal);
    formDataForSaveApi.append("pendingAmountTotal", result?.pendingAmountTotal);

      insertFormType7AccountReceivable(formDataForSaveApi)
        .then((result) => {
          console.log(result, "'''''''''''''");
          if (result?.data.message == "Record Updated SuccessFully") {
            toast.info(result?.data.message);
            onclear();
            onRecordUpdateOrCreated("AccountReceivable");
          }
          if (result?.data.message == "Record Created SuccessFully") {
            toast.info(result?.data.message);
            onclear();
            onRecordUpdateOrCreated("AccountReceivable");
          }
        })
        .catch((err) => {
          console.log("then", err);
          if (
            err?.response?.data &&
            err?.response?.data?.message ==
              "Portals and Calls values should be equal to worked value!"
          ) {
            toast.info(err?.response?.data?.message);
          }
        });
    }
    const handleInputChange = (event) => {
      const selectedImage = event[0];
      setResult((prevResult) => ({ ...prevResult, image: selectedImage }));
    };
  };
  // const transformArray=(arr)=>{

   
  //   if(arr.length==0){
  //     return []
  //   }
 
  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);
      
  //   } catch (error) {
      
  //     parseArr = arr;
      

  //   }
    
  //   const newArray = parseArr.length>0 && parseArr.map(item => ({
  //     ...item,
      
  //     recievedAmountCC:item?.bfVal?.toString(),
     
  //     payerlabel: "0",
  //     payerId:"0",
  //     recievedAmount: "0",
  //     total: "0",
      
  //     bfRow:true
  //   }));
  
  //   return newArray;
  // }
  const transformArray = (arr) => {
    if(arr==undefined){
      return []
    }
    if (!arr && arr.length === 0) {
      return [];
    }
  
    let parseArr;
  
    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }
  
    const filteredArray = parseArr.filter(item => item?.bfVal !== 0);
  
    const newArray = filteredArray.length > 0 && filteredArray.map(item => ({
      ...item,
      recievedAmountCC: item?.bfVal?.toString(),
      //AmountDosVal: item?.bfAmount?.toString(),
      recievedAmount: "0",
      AmountDosValCC: "0",
      providerVal: "0",
      recievedAmountgGen:"0",
      collectedAmountgGen:"0",
      pendinGen:"0",
      codingVal: 0,
      credientalingVal: 0,
      operationVal: 0,
      EdiVal: 0,
      total: 0,
      bfRow: true
    }));
  
    return newArray;
  };
  
  const checkArraysHaveSameDateAndAmount=(arr1, arr2)=> {
    const matchingItem = arr1.find(item1 =>
      arr2.some(item2 => item1.date === item2.date && item1.recievedAmount === item2.recievedAmount)
    );
  
    return !matchingItem;
  }



  useEffect(() => {
    if (stateAmount.recAmountGen || stateAmount.CollectAmountGen) {
      let pending =
        parseInt(stateAmount.recAmountGen) -
        parseInt(stateAmount.CollectAmountGen);
      setStateAmount((prev) => ({ ...prev, pendAmuontGen: pending }));
    }
  }, [stateAmount.recAmountGen, stateAmount.CollectAmountGen]);

  useEffect(() => {
   
    if (record) {
      
      if (record[0]?.id) {
        setResult(record.result[0]);
        let obj = JSON.parse(record.result[0]?.sbreakval);
        setResult((prevResult) => ({
          ...prevResult,

          sbreakval: {
            ...prevResult.sbreakval,
            scp: obj?.scp,
            sca: obj?.sca,
            scr: obj?.scr,
            sct: obj?.sct,
            scs: obj?.scs,
          },
          dosArrayRecieved: JSON.parse(record.result[0]?.dosArrayRecieved),
          dosArray: JSON.parse(record.result[0]?.dosArray),
        }));
        setResult((prevResult) => ({
          ...prevResult,
          sbf: record?.bfvalprv,
        }));
      } else {
        setResult(obj);
        
      }
      let arrPar=[]
      try{
        arrPar=JSON.parse(record.result[0]?.sbreakval)


      }catch(err){
        arrPar=record.result[0]?.sbreakval

      }

      if (record?.rcount === "0" && !record[0]?.id) {
        setResult((prevResult) => ({
          ...prevResult,
          sbf: record?.bfvalprv,
        }));
        const newArray = transformArray(record?.dosArrayRecieved);
        setDosArrayRec(newArray)
      } else {
        setResult(record.result[0]);
        let obj = arrPar;

        setResult((prevResult) => ({
          ...prevResult,
          sbreakval: {
            ...prevResult.sbreakval,
            scp: obj?.scp,
            sca: obj?.sca,
            scr: obj?.scr,
            sct: obj?.sct,
            scs: obj?.scs,
          },

          specialpayerName: record.result[0]?.specialpayerName,
          sbf: record?.bfvalprv,
        }));
        const newArray = transformArray(record.result[0]?.dosArrayRecieved);

        const result = checkArraysHaveSameDateAndAmount(newArray, JSON.parse(record.result[0]?.dosArrayRecieved));
        let combineArray;
      if(result==false){
        combineArray=JSON.parse(record.result[0]?.dosArrayRecieved)
 
 }else{
  combineArray=newArray.concat(JSON.parse(record.result[0]?.dosArrayRecieved))

 }
       
         
        setDosArrayRec(combineArray);
      }
    }
  }, [record, practiceId]);

  useEffect(() => {
    onclear();
  }, [practiceId, date]);
  const onclear = () => {
    setResult(obj);
  };

  const handleInputChange = (event) => {
    const selectedImage = event[0];
    setResult((prevResult) => ({ ...prevResult, image: selectedImage }));
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "scp" ||
      name === "sca" ||
      name === "scr" ||
      name === "sct" ||
      name === "scs"
    ) {
      setResult((prevResult) => ({
        ...prevResult,
        sbreakval: {
          ...prevResult.sbreakval,
          [name]: value,
        },
      }));
    } else {
      setResult((prevResult) => ({ ...prevResult, [name]: value }));
    }
  };

  const handlePracticeVal = (val) => {
    const { value, label } = val;

    setResult((prevResult) => ({
      ...prevResult,

      specialpayerName: label,
      specialpayerID: value,
    }));
  };

  // useEffect(() => {
  //   if (result?.srecieved && result?.sworked && result?.sbf) {
  //     const val = Number(result.sworked) + Number(result.sbf);
  //     const spendingval = val - Number(result?.sworked);

  //     setResult((prevResult) => ({ ...prevResult, spendingval: spendingval }));
  //   }
  // }, [result?.srecieved, result?.sworked]);
  const [TotalAll2, setTotalAll2] = useState(0);
  useEffect(() => {
    let paresedArray2;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArrayRecieved;
    }
    if (paresedArray2.length > 0) {
      const sumProperty = (property) =>
        paresedArray2.reduce(
          (sum, obj) => sum + (parseInt(obj[property]) || 0),
          0
        );
      let scp = sumProperty("scp");
      let sca = sumProperty("sca");
      let scr = sumProperty("scr");
      let sct = sumProperty("sct");
      let scs = sumProperty("scs");
    

      let scpa = sumProperty("scpa");
      let scaa = sumProperty("scaa");
      let scra = sumProperty("scra");
      let scta = sumProperty("scta");
      let scsa = sumProperty("scsa");
      setTotalAll(scp + sca + scr + sct + scs);
      setTotalAll2(scpa+scaa+scra+scta+scsa)
      setResult((prev) => ({
        ...prev,
        sbreakval: {
          ...prev.sbreakval,
          scp: scp,
          sca: sca,
          scr: scr,
          sct: sct,
          scs: scs,



          scpa: scpa,
          scaa: scaa,
          scra: scra,
          scta: scta,
          scsa: scsa,
        },
      }));
    }
  }, [dosArrayRecieved]);
  const openImageViwerModel = (val) => {
    setDocViewer(val);
  };

  const handleClosePopPending = () => {
    setNameGeneric("Account Receivable");
    setModelPending(false);
  };

  const openDosmodelWorked = () => {
    setNameGeneric("Recieved Claims");
    setDosModelRec(true);
  };
  //--------------------
  const handleCommonEdit = async (rowData) => {
    if (rowData.recievedAmount) {
      if(rowData.bfRow==true){
        setisThisbfRow(true)

      }else{
        setisThisbfRow(false)
      }
      let obj = {
        isEdit: true,
        rowDataa: rowData,
      };
      setGridEdit(obj);

      sethandleInputRec(rowData.recievedAmountCC); // rECEIVED AMOUNT
      setPendingClaimss(rowData.pending); //PAID
      setRowID(rowData.id);
      sethandleInput(rowData.recievedAmount); //DENEID

      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      setBreakupVal((prev) => ({
        ...prev,
        scp: rowData.scp,
        sca: rowData.sca,
        scr: rowData.scr,
        sct: rowData.sct,
        scs: rowData.scs,


        scpa:rowData.scpa,
        scaa:rowData.scaa,
        scra:rowData.scra,
        scta:rowData.scta,
        scsa:rowData.scsa
      }));
    } else {
      return;
    }
  };
  const handleChangeCommondos = (id, key, value, event) => {
    handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
  };
  const onSaveDosCo = () => {
    sethelpingState((prev)=>({...prev,isSpeakToaster:true}))
  };
  const onChangeInputCC = (val) => {
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmountCC: false,
    }));
    onChangeInputC(
      val,
      sethandleInputRec,
      setErrorModel,
      "stopEecutingErrModel"
    );
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayRecieved);
  };
  const onChangeDAmountCC = (val) => {
    onChangeAmountC(val, setAmountDosRec);
  };
  const handleCloseCC = () => {

    sethelpingState((prev)=>({isBackSpeak:true}))
   
  };
  const onAdddosCC = () => {
    if (handleInputdos != "") {
      if (parseInt(handleInputdos) != parseInt(AllBreakupVal.total)) {
        toast.info(
          "Worked Claims should be equal to worked claims breakup total"
        );
        return;
      }
    }

    onAdddosC(
      handledosDateRec,
      handleInputdosRec,
      dosArrayRecieved,
      AmountDosRec,
      setDosArrayRec,
      setErrorModel,
      "sp",
      "payer",
      AllBreakupVal,
      null,
      handleInputdos,
      result,
      null,
      null,
      gridEdit,
      rowID,
      null,
        bfRow,
        stateAmount
    );

    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    sethandleInputRec("");
    setisThisbfRow(false)

    sethandleInput("");
    setPendingClaimss(0);
    //  setTotalClaims(0)
    setBreakupVal(breakupObj);
  };
  const renderr = (val) => {
    setRenderEffect(val);
  };

  const onCleanDateSimple = () => {
    setdosDate("");
  };
  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const onHandleCloseCall=()=>{
    if (gridEdit.isEdit) {
      onAdddosCC();
    }
    callGlobalDos(false);

    setErrorModel(errorobj);

  }



  const handleSwalConfirm=(val)=>{
    if(val){
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      callGlobalDos(false);
      setGridEdit(obj);
  
      onSaveDosC(
        true,
        dosArrayRecieved,
        setResult,
        setDosModelRec,
        setResult,
        "specialpayer",null,null,null,stateAmount
      );

     
      sethelpingState((prev)=>({...prev,isSpeakToaster:false}))
    }
    else{
      sethelpingState((prev)=>({...prev,isSpeakToaster:false}))
    }

  }

  const handleSwalConfirm2=(val)=>{
    if(val){
      

      sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))
      onSave()

    }else{
      sethelpingState((prev)=>({...prev,isSpeakerToaster:false}))

    }

  }
  const handleSwalConfirmBackButton=(val)=>{
    if(val){
      

      sethelpingState((prev)=>({...prev,isBackSpeak:false}))
      onHandleCloseCall()

    }else{
      sethelpingState((prev)=>({...prev,isBackSpeak:false}))

    }

  }

  const handleTaosterSpeak=()=>{
    
    sethelpingState((prev)=>({...prev,isSpeakerToaster:true}))
  }

  return (
    <>
     {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}

{helpingState.isSpeakToaster?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm}  titleSweet={'Are you sure?'} text={'Do you want to Save Values'}/>:null}
   {helpingState.isSpeakerToaster?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirm2}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
   {helpingState.isBackSpeak?<NotifySwal triggerNotify={true} handleCloseSwal={handleSwalConfirmBackButton}  titleSweet={'Are you sure?'} text={'Do you want to Save this Form'}/>:null}
      {openScreenGlobalTable == true ? (
        <Dateoofservice
        isAllowThirdTable={{allow:true,flagThirdTable:'generalPayer'}}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          // handleChange={handleChange}
          // handleEditState={handleEditState}
          // onSaveDos={onSaveDos}
          dosArray={dosArrayRecieved}
          // setDosArray={{ state: setDosArray, flag: "1" }}
          // onAdddos={onAdddos}
          //  onChangeDate={onChangeDate}
          //  onChangeInput={onChangeInput}
          //  onChangeInputCC={onChangeInputCC}
          handleClose={handleClose}
          //  showAmount={true}
          //  showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          //  practiceId={practiceId}
          //  dosPendingBreakup={{ isAllow: true, onChangeValues: onChangeValues }}

          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"payer"}
          flag={"sp"}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal

        
 stateAmount={stateAmount}
 handleChangeAmount={handleChangeAmount}
        Footer={footerState}
        flagFooter={"generalPayer"}
       
          spayerName={result?.specialpayerName}
          spayerid={result?.specialpayerID}
          handlePracticeVal={handlePracticeVal}
          minimize={minimize}
          setMinimize={setMinimize}
          spayer={name}
          sid={id}
          resultPayer={result}
          onChangeName={onChange}
          saveList={saveList}
          changeState={"Sp"}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
          AmountDos={totalClaims}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={setTotalClaims}
          // pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}
          onChangeInputCC={onChangeInputCC}
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          // onChangeAmount={onChangeAmount}
          onChangeAmountCC={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          // claimHeading={"Denied"}
          claimAmount={"Worked Claims"}
          claimHeading1={"Received Claims"}
          //  claimAmount1={"Paid"}
          practiceId={practiceId}
          onlyShowTable={false}
          flag={"payer"}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          recievedClaimArraydos={[]}
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Received Amount"}
        />
      ) : openModelPending ? (
        <Dateoofservice
          onlyShowTable={true}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={result.dosArray}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
          showTableCheck={"vob"}
        />
      ) : (
        <>
          {/* <DocViewer
            docViewer={docViewer}
            image={result?.image}
            openImageViwerModel={openImageViwerModel}
          /> */}
          <div class="row">
            {/* <div className="col-lg-12 mb-2 text-center">
        <h4 class="inputLabel " style={{ color: "black" }}>
          Special Payers
        </h4>
      </div> */}
            <DateAndPractice
             nowShowExtraDiv={true}
              onChangePayer={onChangePayer}
              payerSelection={payerSelection}
              showPayer={true}
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={productss}
              date={date}
            />

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  B/F
                </label>
                <input
                  class="form-control inputField"
                  type="text"
                  value={result?.sbf}
                  onChange={handelChange}
                  name="sbf"
                  placeholder=""
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Received Claims
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  value={result?.srecieved}
                  onChange={handelChange}
                  name="srecieved"
                  placeholder=""
                  disabled
                />
                {/* <CustomInputGroupWidthButtonC
              value={result?.srecieved}
              disabled={true}
              size="md"
              onClick={openDosmodelWorked}
              placeholder="Medium"
            /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Worked Claims
                </label>
                {/* <CustomInputGroupWidthButton
              value={result?.sworked}
              disabled={true}
              size="md"
              onClick={openDosmodel}
              placeholder="Medium"
            /> */}
                <input
                  class="form-control inputField"
                  type=""
                  value={result?.sworked}
                  onChange={handelChange}
                  name="srecieved"
                  placeholder=""
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Pending Claims
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  value={result?.spendingval}
                  onChange={handelChange}
                  name="spendingval"
                  placeholder=""
                  disabled
                />
                {/* <CustomInputGroupWidthButton
                  value={result?.spendingval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Special Payers
          </label>
          <Select
            options={products || []}
            // placeholder="Select an option"
            onChange={handlePracticeVal}
           
        value={[{label:result?.specialpayerName,value:result?.specialpayerID}]}
            
           styles={customStyles}
          />
          <button
            style={{
              marginTop: "-32px",
              marginRight: "-30px",
              background: "none",
            }}
            onClick={() => {
              setMinimize((prev) => !prev);
            }}
            className={
              minimize === false
                ? "practiceShow "
                : "practiceShow minimizeColor"
            }
          >
            {minimize === false ? (
              <ExpandOutlineIcon />
            ) : (
              <CollaspedOutlineIcon />
            )}
          </button>
        </div>
      </div>
      {minimize === true ? (
        <>
          <div class="col-sm-6 col-lg-3">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Payer Name
              </label>
              <input
                class="form-control inputField"
                type="text"
                value={name}
                onChange={onChange}
                name="name"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-2">
            <div class="form-group mb-2">
              <label class="inputLabel" style={{ color: "black" }}>
                Payer Id
              </label>
              <input
                class="form-control inputField"
                type="text"
                value={id}
                onChange={onChange}
                name="id"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-2 pb-2">
            <div class="form-group mb-2 ">
              <label
                class="form-control"
                style={{ color: "black", border: "white", marginTop: "-8" }}
              ></label>
              <button type="button" onClick={saveList} className="btnSave ">
                Add
              </button>
            </div>
          </div>
        </>
      ) : null} */}

<div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Received Claims Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.ReceivedAmountTotal}
                  disabled
                />
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Collected Claims Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                 
                  value={result?.CollectedAmountTotal}
                  disabled
                />
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                Pending Claims Amount
                </label>

                <input
                  class="form-control inputField"
                  type=""
                  name="recievedval"
                  placeholder=""
                  onChange={handelChange}
                  value={result?.pendingAmountTotal}
                  disabled
                />
                {/* <CustomInputGroupWidthButtonC
                  value={result?.recievedval}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
              </div>
            </div>

            <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Total Breakup of worked claims:
              </h4>
            </div>


            <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scp}
                  onChange={handelChange}
                  name="scp"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scpa}
                  onChange={handelChange}
                  name="scp"
                  placeholder=""
                />
                <i className="lightGreen">$</i>

</div>
                  </div>

                  </div>
                  </div>

                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scaa}
                  onChange={handelChange}
                  name="scaa"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scaa}
                  onChange={handelChange}
                  name="scaa"
                  placeholder=""
                />
                <i className="lightGreen">$</i>

</div>
                  </div>

                  </div>
                  </div>




                  <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scr}
                  onChange={handelChange}
                  name="scr"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scra}
                  onChange={handelChange}
                  name="scra"
                  placeholder=""
                />
                <i className="lightGreen">$</i>
                </div>
                  </div>

                  </div>
                  </div>


            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Paid
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scp}
                  onChange={handelChange}
                  name="scp"
                  placeholder=""
                />
              </div>
            </div> */}
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Claims Adjusted
                </label>
                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.sca}
                  onChange={handelChange}
                  name="sca"
                  placeholder=""
                />
              </div>
            </div> */}


            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Sent for Resubmission
                </label>
                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scr}
                  onChange={handelChange}
                  name="scr"
                  placeholder=""
                />
              </div>
            </div> */}


<div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.sct}
                  onChange={handelChange}
                  name="sct"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.sct}
                  onChange={handelChange}
                  name="sct"
                  placeholder=""
                />
                <i className="lightGreen">$</i>

</div>

                  </div>


                  </div>
                  </div>

            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Sent to Provider
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.sct}
                  onChange={handelChange}
                  name="sct"
                  placeholder=""
                />
              </div>
            </div> */}
            <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scs}
                  onChange={handelChange}
                  name="scs"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scsa}
                  onChange={handelChange}
                  name="scsa"
                  placeholder=""
                />
                 <i className="lightGreen">$</i>

</div>
                  </div>
                  </div>
                  </div>
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  No. of Apeals Sent
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={result?.sbreakval?.scs}
                  onChange={handelChange}
                  name="scs"
                  placeholder=""
                />
              </div>
            </div> */}
            <div class="col-sm-3">
              <div className="ebos">
                <div class="form-group mb-2 ">
                  <label class="inputLabel" style={{ color: "black" }}>
                  Total{" "}
                  </label>
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={TotalAll}
                  onChange={handelChange}
                  name="total"
                  placeholder=""
                />
                  </div>
                  <div class="form-group ">
                  <label class="inputLabel" style={{ color: "black" }}>
                    Total Amount{" "}
                  </label>
                  <div  class="form-group input-icon">
                  <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={TotalAll2}
                  onChange={handelChange}
                  name="total"
                  placeholder=""
                />
                <i className="lightGreen">$</i>

</div>
                  </div>
                  </div>
                  </div>
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  disabled
                  class="form-control inputField"
                  type="text"
                  value={TotalAll}
                  onChange={handelChange}
                  name="total"
                  placeholder=""
                />
              </div>
            </div> */}
            <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div>
            <div class="col-sm-3 col-lg-3"></div>
            <div class="col-sm-6 col-lg-6">
              <div class="form-group mb-2">
                {/* <label class="inputLabel" style={{ color: "black" }}>
            Other Task
          </label> */}

                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  name="GeneralOpen"
                  placeholder="Enter Remarks Here"
                  class="form-control inputField"
                  value={result?.GeneralOpen}
                  onChange={handelChange}
                ></textarea>
                {/* <input
            class="form-control inputField"
            type="text"
            value={result?.GeneralOpen}
            onChange={handelChange}
            name="GeneralOpen"
            placeholder=""
          /> */}
              </div>
            </div>

            {/* <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Insurance Correspondence Recieved
          </label>

          <input
            class="form-control inputField"
            type="text"
            value={result?.insurance}
            onChange={handelChange}
            name="insurance"
            placeholder=""
          />
        </div>
      </div> */}

            {/* <div class="col-sm-6 col-lg-3 d-flex ">
        <div class="form-group mb-2 ">
          <label class="inputLabel" style={{ color: "black" }}>
            Action Taken{" "}
          </label>
          <div class="row ml-1">
            <div
              class="form-check form-check-inline "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                alignContent: "center",
              }}
            >
              <input
                class="form-check-input mr-2  mb-2"
                type="checkbox"
                id="inlineCheckbox1"
                value="Yes"
                onChange={handelChange}
                name="stoken"
                checked={result?.stoken == "Yes" ? true : false}
              />
              <label class="mr-4" for="inlineCheckbox1">
                Yes{" "}
              </label>
              <input
                class="form-check-input mr-2  mb-2"
                type="checkbox"
                id="inlineCheckbox2"
                value="No"
                onChange={handelChange}
                name="stoken"
                checked={result?.stoken == "No" ? true : false}
              />
              <label class="mr-4" for="inlineCheckbox2">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
      {result?.stoken == "Yes" ? (
        <div class="col-sm-6 col-lg-3">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Remarks
            </label>

            <input
              class="form-control inputField"
              type="text"
              name="comments"
              placeholder=""
              onChange={handelChange}
              value={result?.comments}
            />
          </div>
        </div>
      ) : null} */}

            <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Attachments
              </h4>
            </div>
            <div class="col-sm-12 col-lg-12">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label> */}
                <ImageUploaderr
                  openImageViwerModel={openImageViwerModel}
                  handleInputChange={handleInputChange}
                  image={result?.image}
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-12 mt-2">
              <div class="form-group ">
                <div className="d-flex justify-content-end mt-4">
                  <button onClick={onclear} className="btnClear mr-1 ">
                    Clear
                  </button>
                  <button type="button" onClick={handleTaosterSpeak} className="btnSave">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SpecialPayers;
