
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { tlAdd } from "../../../../Utils/ApiManager";

const PaymentVerification = ({ record, practiceId, date, formTypeNoForApi }) => {
  const { onLoginSignOff } = useSelector((state) => state);
  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    submittype: "No",
    formtype: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  };
  const [state, setState] = useState(payload);

  useEffect(() => {
    if (record) {
      setState((prevResult) => ({
        ...prevResult,
        field1: record?.bamount,
        field2: record?.cworked,
        field3: record?.ramount,
      }));
    }
  }, [record, practiceId]);

  const onclear = () => {
    setState(payload);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setState((prevResult) => ({ ...prevResult, [name]: value }));
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      const formDataForSaveApi = new FormData();
      formDataForSaveApi.append("userid", onLoginSignOff.userid);
      formDataForSaveApi.append("sdate", date);
      formDataForSaveApi.append("pid", practiceId);
      formDataForSaveApi.append("formtype", formTypeNoForApi);
      formDataForSaveApi.append("submittype", "No");
      formDataForSaveApi.append("field1", state?.field1);
      formDataForSaveApi.append("field2", state?.field2);
      formDataForSaveApi.append("field3", state?.field3);
      formDataForSaveApi.append("field4", state?.field4);
      formDataForSaveApi.append("field5", state?.field5);

      let resultt = await tlAdd(formDataForSaveApi);
      if (
        resultt?.data &&
        resultt?.data.message === "Record Updated Successfully!"
      ) {
        onclear();
        toast.info("Record Updated Successfully");
      }
      if (
        resultt?.data &&
        resultt?.data.message == "Record Created Successfully!"
      ) {
        onclear();
        toast.info("Record Created Successfully!");
      }
    }
  };

  return (
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Patient Payment
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field1"
           value={state.field1}
           onChange={handelChange}
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Ins. Payment
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field2"
           value={state.field2}
           onChange={handelChange}
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-3">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Adjustment
          </label>
          <input
            class="form-control inputField"
            type="text"
            name="field3"
           value={state.field3}
           onChange={handelChange}
          />
        </div>
      </div>{" "}
       <div
          class="col-sm-4 col-lg-3 d-flex "
        >
          <div class="form-group mb-1 ">
            <label class="inputLabel" style={{ color: "black" }}>
            Verified{" "}
            </label>
            <div class="row ml-1">
              <div
                class="form-check form-check-inline "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                <input
                  class="form-check-input mr-2  mb-2"
                  type="checkbox"
                  id="inlineCheckbox1"
                  defaultValue="Yes"
                  name="field4"
                  onChange={handelChange}
                  checked={state.field4 == "Yes" ? true : false}
                />
                <label class="mr-4" for="inlineCheckbox1">
                  Yes{" "}
                </label>
                <input
                  class="form-check-input mr-2  mb-2"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="No"
                  name="field4"
                  onChange={handelChange}
                  checked={state.field4 == 'No' ? true : false}
                />
                <label class="mr-4" for="inlineCheckbox2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      <div class="col-sm-6 col-lg-12 mt-2">
        <div class="form-group mb-2">
          <textarea
            class="form-control inputField"
            name={"field5"}
            onChange={handelChange}
            value={state.field5}
            placeholder="Comments if any!"
          />
        </div>
      </div>
    
     
      <div class="col-sm-6 col-lg-12 ">
        <div class="form-group ">
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btnClear mr-1 "
            onClick={onclear}>
              Clear
            </button>
            <button type="button" className="btnSave"
            onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentVerification