import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { toast } from "react-toastify";


import {
  getBucketImages,
  insertFormType4ELIBILITYVOBS,
} from "../../../Utils/ApiManager";

import Dateoofservice from "../../../Common/Dateoofservice";
import { Input, InputGroup, Grid, Row, Col } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import ViewDocument from "../../../Common/IframeModel";
import {
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "./CommonDos";
import { handleCloseC } from "./CommonDos";
import { Schema } from "rsuite";
import { createValidationSchema } from "../../../Common/Validation";
import DateAndPractice from "./DateAndPractice";
import moment from "moment/moment";
import DateoofserviceGlobal from "../../../Common/DateofServiceGlobal";
import DateoofserviceBF from "./BFClaims";
import NotifySwal from "../../../Common/NotifySwal";
import { useDispatch, useSelector } from "react-redux";
import { AllConfirmationOnTab } from "../../../Actions/Actions";
import FileUpload2 from "../../../Common/FileUpload2";

import { Panel } from "rsuite";

const EligibilityVobsForm = ({
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  record,
  practiceId,
  date,
  formTypeNoForApi,
  onRecordUpdateOrCreated,
}) => {
  let obj = {
    dosArray: [],
    GeneralOpen: "",
    bfval: 0,
    calls: "",
    comments: null,
    created_at: "",
    formtype: "",
    id: "",
    image: [],
    insurance: "",
    pendingval: "",
    pid: "",
    portals: "",
    recievedval: "",
    sdate: "",
    stoken: "",
    submittype: "",
    updated_at: "",
    userid: "",
    verify: null,
    workedval: "",
    bfvalprv: "",
    rcount: "",
  };

  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");
  let footerObj = {
    isAllow: true,
    receivedClaims: 0,
    ThroughPortal: 0,
    ThroughCalls: 0,
    Pending: 0,
    Total: 0,
  };
  const dispatch = useDispatch();
  let breakupObj = {
    providerVal: "",
    codingVal: "",
    credientalingVal: "",
    operationVal: "",
    EdiVal: "",
    total: "",
  };

  let objR = {
    filelist: [],
  };
  const [objectcluster, setobjCLuster] = useState(objR);

  const [FileList, setFileList] = useState([]);
  const [footerState, setFooterState] = useState(footerObj);
  const [bfRow, setisThisbfRow] = useState(false);
  const [result, setResult] = useState(obj);
  const [bfScreenArray, setBfScreenArray] = useState("");
  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);

  const { onLoginSignOff } = useSelector((state) => state);
  const [openModelPending, setModelPending] = useState(false);
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const [docViewer, setDocViewer] = useState(false);
  const [dosModel, setDosModel] = useState(false);
  const [receivedClaims, setReceivedClaims] = useState(null);
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [handledosDate, setdosDate] = useState("");
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [handleDosPortal, sethandleDosPortal] = useState("");
  const [handleDosCalls, sethandleDosCalls] = useState("");
  const [handleInputdos, sethandleInput] = useState("");
  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [renderEffect, setRenderEffect] = useState(false);
  const [AmountDosRec, setAmountDosRec] = useState([]);
  const [totalVal, setTotalVal] = useState(null);
  const [providerVal, setProviderVal] = useState(0);
  const [codingVal, setCodingVal] = useState(0);
  const [credientialVal, setCredientialVal] = useState(0);
  const [ediVal, setEdiVal] = useState(0);
  const [operationVal, setOperationVal] = useState(0);
  console.log(formTypeNoForApi, "formTypeNoForApi");
  let errorobj = {
    recievedAmountCC: false, //RECEIVED
    AmountDosVal: false, //CALLS
    date: false,
    AmountDosValCC: false, //PORTAL
    // calls: false,
  };
  const { StringType, NumberType, DateType } = Schema.Types;
  const [errorModel, setErrorModel] = useState(errorobj);

  //new things

  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  const [totalClaims, setTotalClaims] = useState(false);

  const [globalDos, openGlobalDos] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [rowID, setRowID] = useState(null);
  let helpingObj = {
    isSpeakToaster: false,
    loadingn: false,
    isSpeakerToaster: false,
    isBackSpeak: false,
    bucketImages: null,
    base64: null,
    isFileViewerOpen: null,
    AllFiles: null,
    renderEffectForUpload: false,
    getSelectedRowDocument: null,
    updatedblodState: [],
    updatedblob: [],
    isAllStatesAreSet:false
  };
  const [helpingState, sethelpingState] = useState(helpingObj);

  const copyResult = { ...result };
  console.log("copyResult", copyResult, result);

  const openDosmodel = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    setNameGeneric("Worked Claims");
    setDosModel(true);
  };
  const renderr = (val) => {
    setRenderEffect(val);
  };

  useEffect(() => {
    if (FileList.length > 0) {
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data

          const blob = new Blob([res.data], { type: el.mimetype });
          var file = new File([blob], el.originalname);
          // const uint8Array = new Uint8Array(res.data);
          //  const blob = new Blob([uint8Array], { type: el.mimetype });
          //  blob.name = el.originalname;
          //  blob.lastModified = el.lastModified;

          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original: el.originalname,
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
        setResult((prev) => ({ ...prev, image: updatedArr }));
        //  setobjCLuster((prev)=>({
        //   ...prev,
        //   filelist:updatedArr

        // }))
      });
    }
  }, [FileList]);

  useEffect(() => {
    if (FileList.length > 0) {
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data

          const blob = new Blob([res.data], { type: el.mimetype });
          var file = new File([blob], el.originalname);
          // const uint8Array = new Uint8Array(res.data);
          //  const blob = new Blob([uint8Array], { type: el.mimetype });
          //  blob.name = el.originalname;
          //  blob.lastModified = el.lastModified;

          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original: el.originalname,
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
        setResult((prev) => ({ ...prev, image: updatedArr }));
        //  setobjCLuster((prev)=>({
        //   ...prev,
        //   filelist:updatedArr

        // }))
      });
    }
  }, [FileList]);
  console.log("helpingState", helpingState);

  useEffect(() => {
    if (globalDos) {
      dispatch(AllConfirmationOnTab(true));
    } else {
      dispatch(AllConfirmationOnTab(false));
    }
  }, [globalDos]);

  useEffect(() => {
    if (helpingState?.bucketImages) {
      let arr = [];
      try {
        arr = JSON.parse(helpingState?.bucketImages);
      } catch {
        arr = helpingState?.bucketImages;
      }
      setFileList(arr);
    }
  }, [helpingState?.bucketImages]);

  let totalValue =
    parseInt(providerVal) +
    parseInt(ediVal) +
    parseInt(credientialVal) +
    parseInt(codingVal) +
    parseInt(operationVal);

  useEffect(() => {
    const {
      providerVal,
      codingVal,
      credientalingVal,
      operationVal,
      EdiVal,
      total,
    } = AllBreakupVal;

    if (
      providerVal ||
      codingVal ||
      credientalingVal ||
      EdiVal ||
      operationVal
    ) {
      let totalValue =
        (parseInt(providerVal) || 0) +
        (parseInt(EdiVal) || 0) +
        (parseInt(credientalingVal) || 0) +
        (parseInt(operationVal) || 0) +
        (parseInt(codingVal) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
  }, [
    AllBreakupVal.providerVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.EdiVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.credientalingVal,
    AllBreakupVal.operationVal,
  ]);
  console.log("breakupValYUMM", AllBreakupVal);

  useEffect(() => {
    let paresedArray2 = dosArrayRecieved;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArrayRecieved;
    }
    if (paresedArray2.length > 0) {
      const sumProperty = (property) =>
        paresedArray2.reduce(
          (sum, obj) => sum + (parseInt(obj[property]) || 0),
          0
        );
      setProviderVal(sumProperty("providerVal"));
      setCodingVal(sumProperty("codingVal"));
      setCredientialVal(sumProperty("credientalingVal"));
      setEdiVal(sumProperty("EdiVal"));
      setOperationVal(sumProperty("operationVal"));
    }
  }, [dosArrayRecieved]);

  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }

    let countAmountdosCC = 0;
    let recievedAmountCC = 0;
    let worked = 0;
    let pending = 0;

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el.recievedAmountCC); //REC PAT STAT

        countAmountdosCC += parseInt(el.AmountDosValCC); //Portal
        recievedAmountCC += parseInt(el.AmountDosVal); //calls
        worked += parseInt(el.totalClaimWorked); //worked
        pending += parseInt(el.pendingClaim); //pending claims
      });
    // callBack2((prevResult) => ({
    //   ...prevResult,
    //   recievedval: count,
    //   portals:countAmountdosCC,
    //   workedval:worked,
    //   calls:recievedAmountCC,
    //   pendingval:pending

    // }))
    setFooterState((prev) => ({
      ...prev,
      receivedClaims: count,
      ThroughPortal: countAmountdosCC,
      ThroughCalls: recievedAmountCC,
      Pending: pending,
      Total: worked,
    }));
  }, [dosArrayRecieved]);

  const onSaveDos = () => {
    let parsedArray = [];

    try {
      parsedArray = JSON.parse(result.dosArray);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = result.dosArray;
    }

    let portalCount = 0;
    let callsCount = 0;
    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseInt(el.recievedAmount);
        portalCount += parseInt(el.portals);
        callsCount += parseInt(el.calls);
      });
    setNameGeneric("VOB`s");
    setResult((prevResult) => ({
      ...prevResult,
      workedval: count,
      calls: callsCount,
      portals: portalCount,
    }));

    setDosModel(false);
  };

  const handleEditState = async (rowData) => {
    if (!rowData.hasOwnProperty("status")) {
      return;
    }

    const nextData = Object.assign([], result.dosArray);
    const activeItem = nextData.find((item) => item.id === rowData.id);

    activeItem.status = activeItem.status ? null : "EDIT";
    // setDosArray(nextData);
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };

  const CustomInputGroupWidthButton = ({
    value,
    disabled,
    placeholder,
    ...props
  }) => (
    <InputGroup {...props} inside style={styles}>
      <Input value={value} disabled />
      <InputGroup.Button>
        <AddOutlineIcon style={{ color: "#0e7490" }} />
      </InputGroup.Button>
    </InputGroup>
  );
  const onChangeInput = (val) => {
    sethandleInput(val);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
    }));
  };
  const onChangeDate = (val) => {
    let dateObj = new Date(val);
    let year = dateObj.getFullYear();
    let month = String(dateObj.getMonth() + 1).padStart(2, "0");
    let day = String(dateObj.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;

    let results = result?.dosArray.some((el) => el.date === formattedDate);
    if (results) {
      toast.info("Duplicate dates not allowed");
      setdosDate(null);
      return;
    }

    setdosDate(formattedDate);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      date: false,
    }));
  };

  let nextData;
  const handleChangeeob = (id, key, value, event) => {
    event.persist();

    nextData = Object.assign([], result.dosArray);
    nextData.find((item) => item.id === id)[key] = value;
    setResult((prevResult) => ({
      ...prevResult,
      dosArray: nextData,
    }));
  };
  const styles = {
    marginBottom: 10,
  };

  const onAdddos = async () => {
    if (
      parseInt(handleInputdos) !=
      parseInt(handleDosPortal) + parseInt(handleDosCalls)
    ) {
      toast.info("Worked Claims should be equal to calls and portal ");
      return;
    }

    let obj = {
      date: handledosDate,

      recievedAmount: handleInputdos,
      portals: handleDosPortal,
      calls: handleDosCalls,
      status: null,
      id: result?.dosArray?.length + 1,
      Pending: pendingClaimss,
    };

    const validationFields = [
      {
        fieldName: "recievedAmount",
        rules: { requiredMessage: "Received amount is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "portals",
        rules: { requiredMessage: "portals is required." },
        type: { typeError: StringType() },
      },
      {
        fieldName: "date",
        rules: { requiredMessage: "Date is required." },
        type: { typeError: DateType() },
      },
      {
        fieldName: "calls",
        rules: { requiredMessage: "calls is required." },
        type: { typeError: StringType() },
      },
      // Add more fields and their rules as needed
    ];
    const validationSchema = await createValidationSchema(validationFields);

    const errors = validationSchema.check(obj);

    if (errors.recievedAmount.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        recievedAmount: true,
      }));
    }
    if (errors.date.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        date: true,
      }));
    }
    if (errors.portals.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        portals: true,
      }));
    }

    if (errors.calls.hasError) {
      setErrorModel((prevErrorModel) => ({
        ...prevErrorModel,
        calls: true,
      }));
    }
    if (
      !errors.recievedAmount.hasError &&
      !errors.date.hasError &&
      !errors.calls.hasError &&
      !errors.portals.hasError
    ) {
      setResult((prevResult) => ({
        ...prevResult,
        dosArray: [...prevResult.dosArray, obj],
      }));
    }
  };
  const handleClose = () => {
    
    openGlobalTable(false);
    setDosModel(false);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmountCC: false,
      AmountDosVal: false,
      AmountDosValCC: false,
      date: false,
    }));
    setBreakupVal(breakupObj);
  };

  const onSave = async () => {
    let val = Number(result?.calls) + Number(result?.portals);
    if (val != Number(result?.workedval)) {
      toast.info("Worked value should be equal to sum of portal and calls");
    }

    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      sethelpingState((prev) => ({ ...prev, loadingn: true }));
      let dosArrayStrigfiy = JSON.stringify(result?.dosArray);
      if(dosArrayRecieved.length==0){
        toast.info('For Save! Please Add Some Dos Wise Record')
        sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
        return

      }
      let dosArrayRec = JSON.stringify(dosArrayRecieved);

      const formDataForSaveApi = new FormData();
      formDataForSaveApi.append("userid", onLoginSignOff.userid);
      formDataForSaveApi.append("sdate", date);
      formDataForSaveApi.append("comments", result?.comments);
      formDataForSaveApi.append("pid", practiceId);
      formDataForSaveApi.append("formtype", formTypeNoForApi);
      formDataForSaveApi.append("bfval", 0);
      formDataForSaveApi.append("recievedval", result?.recievedval);
      formDataForSaveApi.append("portals", result?.portals);
      formDataForSaveApi.append("insurance", result?.insurance);
      formDataForSaveApi.append("workedval", result?.workedval);
      formDataForSaveApi.append("GeneralOpen", result?.GeneralOpen);
      formDataForSaveApi.append("pendingval", result?.pendingval);
      formDataForSaveApi.append("stoken", result?.stoken);
      formDataForSaveApi.append("calls", result?.calls);
      formDataForSaveApi.append("submittype", "No");
      if (result?.image && result?.image.length > 0 && result?.image != "[]") {
        result?.image.forEach((el, index) => {
          formDataForSaveApi.append(`file`, el?.blobFile);
        });
      } else {
        formDataForSaveApi.append(`file`, null);
      }

      formDataForSaveApi.append("dosArray", dosArrayStrigfiy);
      formDataForSaveApi.append("dosArrayRecieved", dosArrayRec);

      console.log("-------eligibility", record);

      insertFormType4ELIBILITYVOBS(formDataForSaveApi)
        .then((result) => {
          sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
          console.log("then", result.data.message);

          if (result?.data.message == "Record Updated Successfully") {
            console.log(result, "=-=--=------=-=-");
            toast.info(result?.data.message);
            onclear();
            sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
            onRecordUpdateOrCreated("ELIBILITY/VOBS");
          }
          if (result?.data.message == "Record Created Successfully") {
            toast.info(result?.data.message);
            onclear();
            sethelpingState((prev) => ({ ...prev, loadingn: false,isAllStatesAreSet:false }));
            onRecordUpdateOrCreated("ELIBILITY/VOBS");
          }
        })
        .catch((err) => {
          console.log("then", err);
          if (
            err?.response?.data &&
            err?.response?.data?.message ==
              "Portals and Calls values should be equal to worked value!"
          ) {
            sethelpingState((prev) => ({ ...prev, loadingn: false ,isAllStatesAreSet:false}));
            toast.info(err?.response?.data?.message);
          }
        });
    }
  };

  const onRemove = (val) => {
    let remainingObjects = FileList.filter(
      (obj) => obj.fileKey !== val.fileKey
    );
    setFileList(remainingObjects);
    setResult((prevResult) => ({ ...prevResult, image: remainingObjects }));
    //setImage(remainingObjects)
  };

  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }

    if (!arr || arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray =
    parseArr && parseArr.length > 0 && parseArr.filter((item) => item?.bfVal !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        recievedAmountCC: item?.bfVal?.toString(), //Received
        //AmountDosVal: item?.bfAmount?.toString(),
        AmountDosValCC: "0", //portals
        AmountDosVal: "0", //calls
        totalClaimWorked: "0",
        providerVal: "0",

        pendingClaim: "0",
        codingVal: 0,
        credientalingVal: 0,
        operationVal: 0,
        EdiVal: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };

  console.log("result?.image", result?.image);

  // const transformArray=(arr)=>{

  //   if(arr.length==0){
  //     return []
  //   }

  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);

  //   } catch (error) {

  //     parseArr = arr;

  //   }

  //   const newArray = parseArr.length>0 && parseArr.map(item => ({
  //     ...item,

  //     recievedAmountCC:item?.bfVal?.toString(),

  //     AmountDosVal: "0",
  //     AmountDosValCC: "0",
  //     pendingClaim:'0',
  //     totalClaimWorked:'0',

  //     bfRow:true
  //   }));

  //   return newArray;
  // }

  const onChangeValues = (event) => {
    const { name, value } = event.target;

    if (name == "totalVal") {
      setTotalVal(parseInt(value, 10));
      return;
    }
    if (name == "providerVal") {
      setProviderVal(parseInt(value, 10));
      return;
    }
    if (name == "codingVal") {
      setCodingVal(parseInt(value, 10));
      return;
    }
    if (name == "credientialVal") {
      setCredientialVal(parseInt(value, 10));
      return;
    }
    if (name == "ediVal") {
      setEdiVal(parseInt(value, 10));
      return;
    }
    if (name == "operationVal") {
      setOperationVal(parseInt(value, 10));
      return;
    }
  };

  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem =
      arr1 &&
      arr1 != undefined &&
      arr1.length > 0 &&
      arr1.find((item1) =>
        arr2.some(
          (item2) =>
            item1.date === item2.date &&
            item1.recievedAmount === item2.recievedAmount
        )
      );

    return !matchingItem;
  };

  useEffect(() => {
    if (result?.portals !== "" && result?.calls !== "") {
      const workedval = Number(result?.portals) + Number(result?.calls);
      const pendingval = Number(result?.recievedval) - workedval;
      setResult((prevResult) => ({ ...prevResult, pendingval }));
    }
  }, [result?.portals, result?.calls, result?.recievedval]);

  useEffect(() => {
    // setResult(copyResult)

    if (record) {
      if (record?.result.length == 0) {
        setResult((prevResult) => ({
          ...prevResult,
          bfval: record?.bfvalprv,
          bfvalprv: record?.bfvalprv,
        }));
        let bug;

        try {
          bug = JSON.parse(record?.dosArrayRecieved);
        } catch (error) {
          bug = record?.dosArrayRecieved;
        }
        setFileList([]);
        const newArray = transformArray(bug);
        setDosArrayRec(newArray);
        // setBfScreenArray(record?.dosArrayRecieved )
      } else {
        let obj = record.result[0];
        let parArr = [];
        try {
          parArr = JSON.parse(obj.image);
        } catch {
          parArr = obj.image;
        }

        console.log(parArr);
        sethelpingState((prev) => ({
          ...prev,
          bucketImages: obj.image,
          updatedblodState: parArr,
        }));
        setResult((prev) => ({ ...prev, image: parArr })); //image
        setFileList(parArr); //file
        // setFileList(parArr) //only dos value

        //  setResult((prev)=>({...prev,image:obj.image}))

        try {
          obj.dosArray = JSON.parse(obj.dosArray);
        } catch (error) {
          if (obj.dosArray == "") {
            obj.dosArray = [];
          }
        }
        let parsedForFun;
        let bug2;

        try {
          obj.dosArrayRecieved = JSON.parse(obj.dosArrayRecieved);
          parsedForFun = JSON.parse(record.result[0]?.dosArrayRecieved);
          bug2 = JSON.parse(record?.dosArrayRecieved);
        } catch (error) {
          if (obj.dosArrayRecieved == "") {
            obj.dosArrayRecieved = [];
          }
          parsedForFun = record.result[0]?.dosArrayRecieved;
          bug2 = record?.dosArrayRecieved;
        }

        const newArray = transformArray(bug2);
        const result = checkArraysHaveSameDateAndAmount(newArray, parsedForFun);

        let combineArray;
        if (result == false) {
          combineArray = parsedForFun;
        } else {
          if(newArray==false){
            combineArray= [].concat(parsedForFun)
        
          }else{
            combineArray= newArray.concat(parsedForFun)
        
        
          }
         
        }

        setDosArrayRec(combineArray);

        setResult(obj);
        // setDosArrayRec(obj.dosArrayRecieved)
        setBfScreenArray(record?.dosArrayRecieved);

        //setResult(copyResult);
      }
    }
  }, [record, practiceId]);
  useEffect(() => {
    if (practiceId || date) {
      onclear();
    }
  }, [practiceId, date]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setResult((prevResult) => ({ ...prevResult, [name]: value }));
  };

  const openImageViwerModel = (val) => {
    setDocViewer(val);
    sethelpingState((prev) => ({ ...prev, isFileViewerOpen: val }));
  };

  const onclear = () => {
    // setobjCLuster((prev)=>({
    //   ...prev,
    //   filelist:[]

    // }))
    setResult({
      GeneralOpen: "",
      bfval: 0,
      calls: "",
      comments: null,
      created_at: "",
      formtype: "",
      id: "",
      image: "",
      insurance: "",
      pendingval: "",
      pid: "",
      portals: "",
      recievedval: "",
      sdate: "",
      stoken: "",
      submittype: "",
      updated_at: "",
      userid: "",
      verify: null,
      workedval: "",
      bfvalprv: "",
      rcount: "",
      dosArray: [],
    });
  };
  const handleTaosterSpeak = () => {
   if(dosArrayRecieved && dosArrayRecieved.length==0){
    toast.info('Please Enter Date of Service Wise Record')
    return
   }
    console.log(dosArrayRecieved,'dosArrayReceived')
    sethelpingState((prev) => ({ ...prev, isSpeakerToaster: true }));
  };
  const handleInputChange = (event) => {
    const selectedImage = event;

    setResult((prevResult) => ({
      ...prevResult,
      image: selectedImage,
    }));

    setFileList(selectedImage);
  };

  // const handleInputChange = (event) => {
  //   const selectedImage = event;
  //   setResult((prevResult) => ({ ...prevResult, image: selectedImage }));
  //   setFileList(selectedImage)
  // };
  const onChangePortals = (val) => {
    const { value, name } = val.target;
    sethandleDosPortal(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosValCC: false,
    }));
  };
  const onPreview = (val, row) => {
    let obj = { bucketImages: val };
    sethelpingState((prev) => ({ ...prev, getSelectedRowDocument: obj }));
    // getBucketImages(obj).then((res) => {

    //   const blob = new Blob([res], { type: el.mimetype });
    //   var blobUrl = window.URL.createObjectURL(blob);

    //   let objRes = {
    //     name: val.originalName,
    //     mimetype: val.mimetype,
    //     url: val.url,
    //     fileKey: val.fileKey,
    //     base64String: blobUrl,
    //   };
    sethelpingState((prev) => ({
      ...prev,
      //  base64: objRes.base64String,
      isFileViewerOpen: true,
    }));
    //});
  };

  const onChangeCalls = (val) => {
    const { value, name } = val.target;
    sethandleDosCalls(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosVal: false,
    }));
  };

  //---------------
  const handleCommonEdit = async (rowData) => {
    if (rowData.AmountDosValCC) {
      if (rowData.bfRow == true) {
        setisThisbfRow(true);
      } else {
        setisThisbfRow(false);
      }

      let obj = {
        isEdit: true,
        rowDataa: rowData,
      };
      setGridEdit(obj);

      sethandleInputRec(rowData.recievedAmountCC);
      setPendingClaimss(rowData.pendingClaim);
      // rECEIVED AMOUNT
      sethandleDosPortal(rowData.AmountDosValCC); //portal
      setRowID(rowData.id);
      sethandleDosCalls(rowData.AmountDosVal); //Calls

      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      sethandleInput(rowData.totalClaimWorked);

      setBreakupVal((prev) => ({
        ...prev,
        providerVal: rowData.providerVal,
        codingVal: rowData.codingVal,
        credientalingVal: rowData.credientalingVal,
        operationVal: rowData.operationVal,
        EdiVal: rowData.EdiVal,
        total: rowData.total,
      }));
    } else {
      return;
    }
  };
  const handleChangeCommondos = (id, key, value, event) => {
    handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
  };
  const onSaveDosCo = () => {
 
    console.log(dosArrayRecieved, "dosArrayRecieved");
    //in case if user do not enter any value
    if (
      handleDosPortal == "" &&
      handleInputdosRec == "" &&
      handleDosCalls == "" &&
      AllBreakupVal?.providerVal == "" &&
      AllBreakupVal?.operationVal == "" &&
      (dosArrayRecieved.length == 0 || dosArrayRecieved.length < 0)
    ) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);

      onSaveDosC(
        true,
        dosArrayRecieved,
        setResult,
        setDosModelRec,
        setResult,
        "vobs",
        setAmountDosRec,
        setAmountDosRec
      );
      setBreakupVal(breakupObj);
       sethelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
      return;
    } else {
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: true }));
    }

   

  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (charCode === 8 || (charCode >= 48 && charCode <= 57)) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };
  const onChangeInputCC = (val) => {
    setErrorModel((prev) => ({ ...prev, recievedAmountCC: false }));

    onChangeInputC(
      val,
      sethandleInputRec,
      setErrorModel,
      "stopEecutingErrModel"
    );
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayRecieved);
  };
  const onChangeDAmountCC = (val) => {
    onChangeAmountC(val, setAmountDosRec, setErrorModel);
  };
  const handleCloseCC = () => {
  
    sethelpingState((prev) => ({ isBackSpeak: true }));

    // handleCloseC(
    //   setDosModelRec,
    //   setErrorModel,
    //   sethandleInputRec,
    //   setAmountDosRec,
    //   setdosDateRec
    // );
  };
  const onAdddosCC = () => {
    if (parseInt(pendingClaimss) != parseInt(AllBreakupVal.total)) {
      toast.info(
        "Total Pending breakup value should be equal to pending"
      );
      return;
    }
    onAdddosC(
      handledosDateRec,
      handleInputdosRec,
      dosArrayRecieved,
      handleDosPortal,
      setDosArrayRec,
      setErrorModel,
      "vobs",
      "vobs",
      AllBreakupVal,
      null,
      handleInputdos,
      handleDosCalls,
      null,
      null,
      gridEdit,
      rowID,
      null,
      bfRow
    );
    let obj = {
      isEdit: false,
      rowDataa: null,
    };

    setisThisbfRow(false);
    setGridEdit(obj);
    sethandleInputRec("");
    sethandleDosCalls("");
    sethandleDosPortal("");
    setPendingClaimss(0);
    sethandleInput(0);
    setBreakupVal(breakupObj);
  };
  const openDosmodelWorked = () => {
    setNameGeneric("Received Claims");
    setDosModelRec(true);
  };
  const onCleanDateSimple = () => {
    setdosDate("");
  };
  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };

  const openDosmodelPending = () => {
    if (dosArrayRecieved.length == 0) {
      toast.info("Please Enter Received Claims");
      return;
    }
    //  setNameGeneric('Pending Claims')

    setModelPending(true);
  };

  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;

    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClosePopPending = () => {
    //  setNameGeneric("Charge Entry")
    setModelPending(false);
  };
  const onHandlePendingClaimss = (val) => {
    if (parseInt(val) < 0) {
      toast.info(
        "Pending value must be a positive number. Please verify your calculations."
      );
    }
    setPendingClaimss(val);
  };

  console.log("result.dosArray", result.dosArray, "received", dosArrayRecieved);

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const [openBf, setBf] = useState(false);
  const openBfScreen = () => {
    setBf(true);
  };
  const handleCloseBfScreen = () => {
    setBf(false);
  };
  const onHandleCloseCall = () => {
    if (gridEdit.isEdit) {
      onAdddosCC();
    }
    setBreakupVal(breakupObj);
    callGlobalDos(false);

    let obj = {
      isEdit: false,
      rowDataa: null,
    };
    setGridEdit(obj);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosVal: false,
      recievedAmountCC: false,
      AmountDosValCC: false,
      date: false,
    }));
  };


  const handleSwalConfirm = (val) => {
    if (val==true) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);

    onSaveDosC(
        true,
        dosArrayRecieved,
        setResult,
        setDosModelRec,
        setResult,
        "vobs",
        setAmountDosRec,
        setAmountDosRec
      );
      setBreakupVal(breakupObj);
    sethelpingState((prev) => ({ ...prev, isSpeakToaster: false,isAllStatesAreSet:true }));
     
    } else if(!val) {
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false, }));
    }else if(val=='backToMainScreen'){
      sethandleInputRec("");
      sethandleDosCalls("");
      sethandleDosPortal("");
      setPendingClaimss(0);
      sethandleInput(0);
      setBreakupVal(breakupObj);
      sethelpingState((prev) => ({ ...prev, isSpeakToaster: false, }));
      callGlobalDos(false);


    }
  };
useEffect(() => {
  if(helpingState?.isAllStatesAreSet){
    onSave()

  }
 

 
}, [helpingState?.isAllStatesAreSet])

  const handleSwalConfirm2 = (val) => {
    if (val==true) {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
      onSave();
    } else  {
      sethelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
     
    }
    
  };
  const handleSwalConfirmBackButton = (val) => {
    if (val) {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
      onHandleCloseCall();
    } else {
      sethelpingState((prev) => ({ ...prev, isBackSpeak: false }));
    }
  };

  console.log(FileList, "filelist");

  return (
    <>
      {/* <FilePreviewer 
            file={{
                data: "<base64 string>",
                mimeType: 'application/pdf',
                name: 'sample.pdf' // for download
            }}
        /> */}
      {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}

      {helpingState.isSpeakToaster==true ? (
        <NotifySwal
         showDenyButton={true}
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
         
        />
      ) : null}
      {helpingState.isSpeakerToaster==true ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm2}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {helpingState.isBackSpeak ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmBackButton}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {openBf == true ? (
        <>
          <DateoofserviceBF
            handleClose={handleCloseBfScreen}
            dosArray={bfScreenArray}
          />
        </>
      ) : openScreenGlobalTable == true ? (
        <Dateoofservice
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={dosArrayRecieved}
          handleClose={handleClose}
          claimHeading={"Claims Received"}
          claimAmount={"Portal"}
          columnHeader1={"Calls"}
          columnHeader2={"Worked"}
          columnHeader3={"Pending "}
          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"vobb"}
          flag={"vobb"}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal
          handleKeyPress={handleKeyPress}
          onChangeBreakupClaim={onChangeBreakupClaim}
          Footer={footerState}
          flagFooter={"vob"}
          changeState={"vobb"}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AmountDos={handleDosCalls}
          AmountDosRec={handleInputdosRec}
          setpendingAmountDos={setTotalClaims}
          // pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleDosPortal}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          AllBreakupVal={AllBreakupVal}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangePortals}
          onChangeInputCC={onChangeInputCC} //received claims
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeInput} //worked
          onChangeAmountCC={onChangeCalls}
          showAmount={true}
          showPortal={false}
          claimHeading={"Received"}
          claimAmount={"Through Calls"}
          claimHeading1={"Received"}
          claimAmount1={"Total"}
          practiceId={practiceId}
          onlyShowTable={false}
          flag={"vobb"}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          practiceId={practiceId}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeDate={onChangeDateCC}
          onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Received Amount"}
        />
      ) : openModelPending ? (
        <Dateoofservice
          onlyShowTable={true}
          showTableCheck={"vob"}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          //  setDosArray={{state:setDosArrayRec,flag:'1'}}
          renderr={renderr}
          renderEffect={renderEffect}
          //   handleChange={handleChangeCommondos}
          //   handleEditState={handleCommonEdit}
          //  onSaveDos={onSaveDosCo}
          dosArray={result.dosArray}
          //   onAdddos={onAdddosCC}
          //   onChangeDate={onChangeDateCC}
          //   onChangeInput={onChangeInputCC}
          handleClose={handleClosePopPending}
          //    onChangeAmount={onChangeDAmountCC}
          showAmount={false}
          showPortal={false}
          // claimHeading={'Received Claims'}
          // claimAmount={'Received Amount'}
          practiceId={practiceId}
        />
      ) : (
        <>
          {helpingState?.isFileViewerOpen == true && (
            // <DocViewer
            //   docViewer={true}
            //   image={helpingState?.base64}
            //   openImageViwerModel={openImageViwerModel}
            // />
            <ViewDocument
              seletedRow={helpingState?.getSelectedRowDocument}
              openImageViwerModel={openImageViwerModel}
            />
          )}
          <div class="row">
            <DateAndPractice
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={products}
              date={date}
            />
            <Panel
              defaultExpanded={true}
              header="Eligibility/VOB`s"
              collapsible
            >
              <div className="row mt-3">
                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label
                      class="inputLabel"
                      style={{ color: "black" }}
                      onClick={openBfScreen}
                    >
                      B/F
                      {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
                    </label>
                    <input
                      class="form-control inputField"
                     // onChange={handelChange}
                      value={result?.bfval}
                      type="number"
                      name="bfval"
                      placeholder=""
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Received
                    </label>

                    <input
                      class="form-control inputField"
                      onChange={handelChange}
                      value={result?.recievedval}
                      type="number"
                      name="recievedval"
                      placeholder=""
                      disabled
                    />
                    {/* <CustomInputGroupWidthButtonC
          value={result?.recievedval}
          disabled={true}
          size="md"
          onClick={openDosmodelWorked}
          placeholder="Medium"
        /> */}
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Through Portal
                    </label>
                    <input
                      disabled
                      class="form-control inputField"
                     // onChange={handelChange}
                      value={result?.portals}
                      type="number"
                      name="portals"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Through Calls{" "}
                    </label>

                    <input
                      disabled
                      class="form-control inputField"
                     // onChange={handelChange}
                      value={result?.calls}
                      type="number"
                      name="calls"
                      placeholder=""
                    />
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Total{" "}
                    </label>

                    <input
                      class="form-control inputField"
                    //  onChange={handelChange}
                      value={result?.workedval}
                      type="number"
                      name="recievedval"
                      placeholder=""
                      disabled
                    />
                    {/* <CustomInputGroupWidthButton
          value={result.workedval}
          disabled={true}
          size="md"
          onClick={openDosmodel}
          placeholder="Medium"
        /> */}
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Pending
                    </label>

                    <input
                      class="form-control inputField"
                      value={result?.pendingval}
                      type="text"
                      name="pendingval"
                      placeholder=""
                      disabled
                    />
                    {/* <CustomInputGroupWidthButton
          value={result?.pendingval}
          disabled={true}
          size="md"
          onClick={openDosmodelPending}
          placeholder="Medium"
        /> */}
                  </div>
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-3"></div>
                <div class="col-sm-3"></div>
                <br></br>
                <br></br>
              </div>
            </Panel>

            <div className="mt-4"></div>
{/* 
            <div className="col-lg-12 mb-2 text-center mt-4">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Pending Claims Breakup
              </h4>
            </div> */}
             <Panel defaultExpanded={true} header="Pending Claims Breakup" collapsible={true} >
              <div className="row mt-4">

            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Provider
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="providerVal"
                  placeholder=""
                  value={providerVal}
                  disabled
                //  onChange={onChangeValues}
                />
              </div>
            </div>
            {/* <div class="col-sm-6 col-lg-3">
      <div class="form-group mb-2">
        <label class="inputLabel" style={{ color: "black" }}>
          Coding
        </label>

        <input
          class="form-control inputField"
          type="number"
          name="codingVal"
          placeholder=""
          value={codingVal}
          onChange={onChangeValues}
          disabled
        />
      </div>
    </div> */}
            {/* <div class="col-sm-6 col-lg-3">
      <div class="form-group mb-2">
        <label class="inputLabel" style={{ color: "black" }}>
          Credentialing
        </label>

        <input
          class="form-control inputField"
          type="number"
          name="credientialVal"
          placeholder=""
          value={credientialVal}
          onChange={onChangeValues}
          disabled
        />
      </div>
    </div> */}
            {/* <div class="col-sm-6 col-lg-3">
      <div class="form-group mb-2">
        <label class="inputLabel" style={{ color: "black" }}>
          EDI
        </label>

        <input
          class="form-control inputField"
          type="number"
          name="ediVal"
          placeholder=""
          value={ediVal}
          onChange={onChangeValues}
          disabled
        />
      </div>
    </div> */}
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Operation
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="operationVal"
                  placeholder=""
                  value={operationVal}
                 // onChange={onChangeValues}
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Total
                </label>

                <input
                  class="form-control inputField"
                  type="number"
                  name="totalVal"
                  placeholder=""
                  value={totalValue}
                //  onChange={onChangeValues}
                  disabled
                />
              </div>
            </div>
            </div>
            </Panel>
            <div className="mt-4"></div>

            {/* <div className="col-lg-12 mb-2 mt-4 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div> */}
             <Panel defaultExpanded={true} header="Remarks" collapsible={true} >

           
            <div class="col-sm-12 col-lg-12 mt-4">
              <div class="form-group mb-2 ml-4">
                {/* <label class="inputLabel" style={{ color: "black" }}>
          Other Task
        </label> */}

                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  name="GeneralOpen"
                  placeholder="Enter Remarks Here"
                  class="form-control inputField"
                  value={result?.GeneralOpen}
                  onChange={handelChange}
                ></textarea>

                {/* <input
          class="form-control inputField"
          onChange={handelChange}
          value={result?.GeneralOpen}
          type="text"
          name="GeneralOpen"
          placeholder=""
        /> */}
              </div>
            </div>
            </Panel>

            {/* <div class="col-sm-6 col-lg-3">
      <div class="form-group mb-2">
        <label class="inputLabel" style={{ color: "black" }}>
          Insurance Correspondence Received
        </label>

        <input
          class="form-control inputField"
          onChange={handelChange}
          value={result?.insurance}
          type="text"
          name="insurance"
          placeholder=""
        />
      </div>
    </div>

    <div class="col-sm-2 col-lg-2 d-flex ">
      <div class="form-group mb-2 ">
        <label class="inputLabel" style={{ color: "black" }}>
          Action Taken{" "}
        </label>
        <div class="row ml-1">
          <div
            class="form-check form-check-inline "
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            <input
              class="form-check-input mr-2  mb-2"
              type="checkbox"
              id="inlineCheckbox1"
              value="Yes"
              name="stoken"
              onChange={handelChange}
              checked={result?.stoken == "Yes" ? true : false}
            />
            <label class="mr-4" for="inlineCheckbox1">
              Yes{" "}
            </label>
            <input
              class="form-check-input mr-2  mb-2"
              type="checkbox"
              id="inlineCheckbox2"
              value="No"
              name="stoken"
              onChange={handelChange}
              checked={result?.stoken == "No" ? true : false}
            />
            <label class="mr-4" for="inlineCheckbox2">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    {result?.stoken == "Yes" ? (
      <div class="col-sm-3 col-lg-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Remarks
          </label>

          <input
            class="form-control inputField"
            type="text"
            name="comments"
            placeholder=""
            onChange={handelChange}
            value={result?.comments}
          />
        </div>
      </div>
    ) : null} */}

            {/* <div class="col-sm-6 col-lg-6">
  <div class="form-group ">
    <label class="inputLabel" style={{ color: "black" }}>
      Select File{" "}
    </label>
    <form >

      <div>
        <input style={{ width: "81%" }}
          
          type="file"
        />
       
      </div>
    </form>
  </div>
</div> */}

            {/* <div className="col-lg-12 mb-2 text-center">
      <h4
        class="inputLabel "
        style={{
          color: "black",
          borderBottom: "2px solid rgb(14, 116, 144)",
          paddingBottom: "10px",
        }}
      >
         Attachments
      </h4>
    </div> */}
            <div class="col-sm-12 col-lg-12 mt-3">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
      Select File{" "}
    </label> */}
                <FileUpload2
                  onRemove={onRemove}
                  setobjCLuster={setobjCLuster}
                  objectcluster={objectcluster}
                  helpingState={helpingState}
                  onPreview={onPreview}
                  fileList={FileList}
                  openImageViwerModel={openImageViwerModel}
                  handleInputChange={handleInputChange}
                  image={result?.image}
                />
              </div>
            </div>

            <div class="col-sm-6 col-lg-12 mt-2">
              <div class="form-group mb-2 ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    onClick={onclear}
                    className="btnClear mr-1 "
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    onClick={handleTaosterSpeak}
                    className="btnSave"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EligibilityVobsForm;
