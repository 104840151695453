import React,{useState}from 'react'
import TaskListTabComp from './TaskListTab'
import OverAll from './OverAll'
import ClaimLevel from './ClaimLevel'
import {  Panel,  } from "rsuite";

export default function TaskListComp() {
  let obj={
    tabName:'ClaimLevel',
    products:[],
    practiceSelectedVal:null,
    practiceWiseUserlist:[],
    sdate:null,
    dosDate:null,
    overAllArray:[],
    selectedArray:[],
    isOverAllPopupOpen:false,
    claimLevelRecordArray:[]
  }

 


 

  

  const [helpingObj,sethelpingObj]=useState(obj)
  console.log('helpingObj',helpingObj)
  return (
    <div className="row">
    <div className="col-lg-12">
      <div class=" ">
        <div class="p-4 navcolor bg-white rounded  mb-5">
        <div class=" row mt-1">
        <TaskListTabComp sethelpingObj={sethelpingObj} />
          <Panel>
          

{helpingObj.tabName=='OverAll'?<OverAll helpingObj={helpingObj}  sethelpingObj={sethelpingObj}/>:<ClaimLevel helpingObj={helpingObj}  sethelpingObj={sethelpingObj} />}
          </Panel>
    
      
      </div>
      </div>
      </div>
      </div>
      </div>
      
   
  )
}
