import React,{useState,useEffect} from "react";

import GeneralPayers from "./FormType/AccountReceivablepayers/GeneralPayers";
import SpecialPayers from "./FormType/AccountReceivablepayers/SpecialPayers";

const AccountReciecableForm = ({
  onChangePayer,
  
  payerSelection,
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  payerType,
  record,
  formTypeNoForApi,
  date,
  practiceId,
  onRecordUpdateOrCreated,
}) => {
  const [executeRender,setRender]=useState(false)
  console.log("this render", products);
  useEffect(() => {
    setRender(!executeRender)
   
  }, [payerType])
  
  return (
    <>
      {payerType == "AccountReceivablegeneral" || payerType=="AccountReceivable" ? (
        <>
       
        <GeneralPayers
       payerSelection={payerSelection}
        onChangePayer={onChangePayer}
        handleFormTypeAndPract={handleFormTypeAndPract}
        productss={products}
          setNameGeneric={setNameGeneric}
          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
          record={record}
          practiceId={practiceId}
          formTypeNoForApi={formTypeNoForApi}
          date={date}
        />
        </>
        
      ) :(payerType == "AccountReceivablespecial") && (
        <SpecialPayers
        onChangePayer={onChangePayer}
        payerSelection={payerSelection}
        handleFormTypeAndPract={handleFormTypeAndPract}
        productss={products}
          setNameGeneric={setNameGeneric}
          onRecordUpdateOrCreated={onRecordUpdateOrCreated}
          record={record}
          practiceId={practiceId}
          formTypeNoForApi={formTypeNoForApi}
          date={date}
        />
      )}
    </>
  );
};

export default AccountReciecableForm;
