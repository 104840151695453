import React, { useState, useEffect } from "react";
import {
  getBucketImages,
  insertFromType1ChargeEntryRecord,
  onSaveApi,
} from "../../../Utils/ApiManager";
import { Input, InputGroup, Grid, Row, Col, Panel } from "rsuite";

import { toast } from "react-toastify";

import AddOutlineIcon from "@rsuite/icons/AddOutline";

import Dateoofservice from "../../../Common/Dateoofservice";
import { useDispatch, useSelector } from "react-redux";
import FileUpload2 from "../../../Common/FileUpload2";
import ViewDocument from "../../../Common/IframeModel";

import {
  CustomInputGroupWidthButtonC,
  handleChangeC,
  onChangeDateC,
  onSaveDosC,
  onAdddosC,
  handleEditStateC,
  onChangeInputC,
  onChangeAmountC,
} from "./CommonDos";
import { handleCloseC } from "./CommonDos";
import { Schema } from "rsuite";
import { createValidationSchema } from "../../../Common/Validation";
import DateAndPractice from "./DateAndPractice";
import DateoofserviceGlobal from "../../../Common/DateofServiceGlobal";
import moment from "moment/moment";
import DateoofserviceBF from "./BFClaims";
import NotifySwal from "../../../Common/NotifySwal";
import {
  AllConfirmationOnTab,
  isConfirmationSpeak,
} from "../../../Actions/Actions";
function ChargeEntry({
  handleFormTypeAndPract,
  products,
  setNameGeneric,
  record,
  practiceId,
  formTypeNoForApi,
  date,
  onRecordUpdateOrCreated,
}) {
  let errorobj = {
    recievedAmount: false,
    AmountDosVal: false,
    date: false,

    recievedAmountCC: false,
    AmountDosValCC: false,
  };
  const dispatch = useDispatch();
  let yourDate = new Date();
  let datee = moment(yourDate).format("YYYY-MM-DD");

  const [selectedOption, setSelectedOption] = useState("");
  const [bfScreenArray, setBfScreenArray] = useState("");

  const [gridEdit, setGridEdit] = useState({ isEdit: false, rowDataa: null });
  const [globalDos, openGlobalDos] = useState("");
  const { StringType, NumberType, DateType } = Schema.Types;
  const [errorModel, setErrorModel] = useState(errorobj);

  const [bfVal, setBfVal] = useState(0);
  const [pendingAmountDos, setpendingAmountDos] = useState(0);
  const [FileList, setFileList] = useState([]);
  const [rowID, setRowID] = useState(null);
  const [pendingClaimss, setPendingClaimss] = useState(0);
  const [bfamount, setBfamount] = useState(0);
  const [receivedClaims, setReceivedClaims] = useState(null);
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [workedClaims, setWorkedClaims] = useState(null);
  const [workedAmount, setWorkedAmount] = useState(null);
  const [pendingClaims, setPendingClaims] = useState(null);
  const [pendingAmount, setPendingAmount] = useState(null);
  const [totalVal, setTotalVal] = useState(null);
  const [providerVal, setProviderVal] = useState(0);
  const [codingVal, setCodingVal] = useState(0);
  const [credientialVal, setCredientialVal] = useState(0);
  const [ediVal, setEdiVal] = useState(0);
  const [operationVal, setOperationVal] = useState(0);
  const [name, setName] = useState(null);
  const [comments, setComments] = useState(null);
  const [description, setDescription] = useState(null);

  const [generalTextField, setGeneralTextField] = useState(null);
  const [insuranceRecieved, setInsuranceRecieved] = useState(null);
  const [actionToken, setActionToken] = useState(null);
  const [image, setImage] = useState("");
  const [docViewer, setDocViewer] = useState(false);
  const [dosModel, setDosModel] = useState(false);
  const [renderEffect, setRenderEffect] = useState(false);

  const [openDosmodelRecState, setDosModelRec] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [handledosDate, setdosDate] = useState("");
  const [handledosDateRec, setdosDateRec] = useState(`${datee}`);
  const [datePickerVal, setDatePickerVal] = useState(new Date());
  const [handleInputdos, sethandleInput] = useState("");
  const [handleInputdosRec, sethandleInputRec] = useState("");
  const [data, setData] = React.useState(null);
  const [dosArray, setDosArray] = useState([]);
  const [dosArrayRecieved, setDosArrayRec] = useState([]);
  const [AmountDos, setAmountDos] = useState("");
  const [AmountDosRec, setAmountDosRec] = useState("");
  const [isPendingSectionOpen, setisPendingSectionOpen] = useState(false);
  const [bfRow, setisThisbfRow] = useState(false);
  let footerObj = {
    isAllow: true,
    receivedClaims: 0,
    receivedAmount: 0,
    workedClaims: 0,
    workedAmount: 0,
    pendingClaims: 0,
  };
  let helpingObj = {
    isSpeakToaster: false,
    isSpeakerToaster: false,
    loadingn: false,
    isBackSpeak: false,
    bucketImages: null,
    base64: null,
    isFileViewerOpen: null,
    AllFiles: null,
    renderEffectForUpload: false,
    getSelectedRowDocument: null,
    noofClaims: { label: "Paper", value: "Paper" },
    isDemographic: false,
    isAllStatesAreSet: false,
  };

  let objR = {
    filelist: [],
  };
  const [objectcluster, setobjCLuster] = useState(objR);
  const [helpingState, setHelpingState] = useState(helpingObj);
  const [footerState, setFooterState] = useState(footerObj);

  const [openScreenGlobalTable, setGlobalTable] = useState(false);
  let breakupObj = {
    providerVal: "",
    codingVal: "",
    credientalingVal: "",
    operationVal: "",
    EdiVal: "",
    total: 0,
  };
  const [openModelPending, setModelPending] = useState(false);
  const [AllBreakupVal, setBreakupVal] = useState(breakupObj);

  const { onLoginSignOff, isConfirmationSpeakTab } = useSelector(
    (state) => state
  );

  useEffect(() => {
    if (helpingState?.bucketImages) {
      let arr = [];
      try {
        arr = JSON.parse(helpingState?.bucketImages);
      } catch {
        arr = helpingState?.bucketImages;
      }

      setFileList(arr);
    }
  }, [helpingState?.bucketImages]);

  useEffect(() => {
    if (parseFloat(pendingAmountDos) < 0) {
      toast.info(
        "Pending Amount value must be a positive number. Please verify your calculations."
      );
    }
  }, [pendingAmountDos]);




  console.log("new Bug Reported",dosArrayRecieved)

  useEffect(() => {
    if (globalDos) {
      dispatch(AllConfirmationOnTab(true));
    } else {
      dispatch(AllConfirmationOnTab(false));
    }
  }, [globalDos]);

  useEffect(() => {
    if (record) {
      handleChargeFieldsOnGetRecord(record);
    }
    if (practiceId == "0") {
      setDescription("");
      setActionToken("");
      setName("");
    }
  }, [record, practiceId]);
  useEffect(() => {
    if (practiceId) {
      onClear();
    }
  }, [practiceId]);
  useEffect(() => {
    if (date) {
      onClear();
    }
  }, [date]);
  useEffect(() => {
    let parsedArray = [];
    try {
      parsedArray = JSON.parse(dosArrayRecieved);
      console.log("The string contains a valid JSON object.");
    } catch (error) {
      parsedArray = dosArrayRecieved;
    }

    let countAmountdos = 0;
    let countAmountdosCC = 0;
    let recievedAmountCC = 0;
    let pendingstat = 0;

    let count = 0;
    parsedArray &&
      parsedArray.map((el) => {
        count += parseFloat(el.recievedAmount); //recieved Claims
        countAmountdos += parseFloat(el.AmountDosVal); //Received Amount
        countAmountdosCC += parseFloat(el.AmountDosValCC); //Worked AMount
        recievedAmountCC += parseFloat(el.recievedAmountCC); //Worked Clai9ms
        pendingstat +=
          parseFloat(el.recievedAmount) - parseFloat(el.recievedAmountCC); //pending Claims
      });
    setFooterState((prev) => ({
      ...prev,
      receivedClaims: count,
      receivedAmount: countAmountdos,
      workedClaims: recievedAmountCC,
      workedAmount: countAmountdosCC,
      pendingClaims: pendingstat,
    }));
  }, [dosArrayRecieved]);

  useEffect(() => {
    let check =
      generalTextField != null ||
      receivedClaims != null ||
      receivedAmount != null ||
      workedClaims != null ||
      workedAmount != null;
    let stringCheck =
      generalTextField != "" ||
      receivedClaims != "" ||
      receivedAmount != "" ||
      workedClaims != "" ||
      workedAmount != "";
    if (check && stringCheck) {
      dispatch(isConfirmationSpeak(true));
    } else {
      dispatch(isConfirmationSpeak(false));
    }
  }, [
    generalTextField,
    receivedClaims,
    receivedAmount,
    workedClaims,
    workedAmount,
  ]);

  useEffect(() => {
    if (receivedClaims && workedClaims) {
      // dispatch(AllConfirmationOnTab(true))

      setPendingClaims(receivedClaims - workedClaims);
    } else {
      setPendingClaims(0);
    }
  }, [receivedClaims, workedClaims]);

  useEffect(() => {
    if (receivedClaims && workedClaims) {
      setPendingClaims(receivedClaims - workedClaims);
    } else {
      setPendingClaims(0);
    }
  }, [receivedClaims, workedClaims]);
  useEffect(() => {
    let paresedArray2 = dosArrayRecieved;
    try {
      paresedArray2 = JSON.parse(dosArrayRecieved);

      console.log("The string contains a valid JSON object.");
    } catch (error) {
      paresedArray2 = dosArrayRecieved;
    }
    if (paresedArray2 && paresedArray2.length > 0) {
      const sumProperty = (property) =>
        paresedArray2.reduce(
          (sum, obj) => sum + (parseFloat(obj[property]) || 0),
          0
        );
      setProviderVal(sumProperty("providerVal"));
      setCodingVal(sumProperty("codingVal"));
      setCredientialVal(sumProperty("credientalingVal"));
      setEdiVal(sumProperty("EdiVal"));
      setOperationVal(sumProperty("operationVal"));
    }
  }, [dosArrayRecieved]);

  useEffect(() => {
    const {
      providerVal,
      codingVal,
      credientalingVal,
      operationVal,
      EdiVal,
      total,
    } = AllBreakupVal;

    if (
      providerVal ||
      codingVal ||
      credientalingVal ||
      EdiVal ||
      operationVal
    ) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(EdiVal) || 0) +
        (parseFloat(credientalingVal) || 0) +
        (parseFloat(operationVal) || 0) +
        (parseFloat(codingVal) || 0);
      setBreakupVal((prev) => ({ ...prev, total: totalValue }));
    } else {
      setBreakupVal((prev) => ({ ...prev, total: 0 }));
    }
  }, [
    AllBreakupVal.providerVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.EdiVal,
    AllBreakupVal.codingVal,
    AllBreakupVal.credientalingVal,
    AllBreakupVal.operationVal,
  ]);

  useEffect(() => {
    if (FileList.length > 0) {
      let arr = FileList.map(async (el) => {
        if (!el?.blobFile) {
          let obj = { bucketImages: el };
          let res = await getBucketImages(obj); // Use await to fetch the blob data

          const blob = new Blob([res.data], { type: el.mimetype });
          var file = new File([blob], el.originalname);
          // const uint8Array = new Uint8Array(res.data);
          //  const blob = new Blob([uint8Array], { type: el.mimetype });
          //  blob.name = el.originalname;
          //  blob.lastModified = el.lastModified;

          return {
            blobFile: file,
            name: el.originalname,
            fileKey: el.key,
            original: el.originalname,
          };
        } else {
          return el; // If blobFile is present, return the original object
        }
      });
      Promise.all(arr).then((updatedArr) => {
        //  setResult((prev)=>({...prev,image:updatedArr}))
        setImage(updatedArr);
        //  setobjCLuster((prev)=>({
        //   ...prev,
        //   filelist:updatedArr

        // }))
      });
    }
  }, [FileList]);

  useEffect(() => {
    if (providerVal || codingVal || credientialVal || ediVal || operationVal) {
      let totalValue =
        (parseFloat(providerVal) || 0) +
        (parseFloat(ediVal) || 0) +
        (parseFloat(credientialVal) || 0) +
        (parseFloat(codingVal) || 0) +
        (parseFloat(operationVal) || 0);

      setTotalVal(totalValue);
    } else {
      setTotalVal(0);
    }
  }, [providerVal, codingVal, credientialVal, ediVal, operationVal]);

  useEffect(() => {
    if (receivedAmount && workedAmount) {
      setPendingAmount(receivedAmount - workedAmount);
    } else {
      setPendingAmount(0);
    }
  }, [receivedAmount, workedAmount]);

  const handleKeyPress = (e) => {
    const charCode = e.charCode;

    // Allow backspace (charCode 8) and numeric digits (charCode 48 to 57)
    if (
      charCode === 8 ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode != 190 && charCode != 110)
    ) {
      return;
    }

    // Prevent other key presses
    e.preventDefault();
  };

  const onChangeDemo = (arg, e) => {
    setHelpingState((prev) => ({
      ...prev,
      isDemographic: !helpingState?.isDemographic,
    }));
  };
  const onChangeValues = (event) => {
    const { name, value } = event.target;
    if (name == "workedClaims") {
      setWorkedClaims(value);
      return;
    }
    if (name == "workedAmount") {
      setWorkedAmount(value);
      return;
    }

    if (name == "receivedClaims") {
      setReceivedClaims(value);
      return;
    }
    if (name == "receivedAmount") {
      setReceivedAmount(value);
      return;
    }
    if (name == "pendingClaims") {
      setPendingClaims(value);
      return;
    }
    if (name == "pendingAmount") {
      setPendingAmount(value);
      return;
    }
    if (name == "totalVal") {
      setTotalVal(parseFloat(value, 10));
      return;
    }
    if (name == "providerVal") {
      setProviderVal(parseFloat(value, 10));
      return;
    }
    if (name == "codingVal") {
      setCodingVal(parseFloat(value, 10));
      return;
    }
    if (name == "credientialVal") {
      setCredientialVal(parseFloat(value, 10));
      return;
    }
    if (name == "ediVal") {
      setEdiVal(parseFloat(value, 10));
      return;
    }
    if (name == "operationVal") {
      setOperationVal(parseFloat(value, 10));
      return;
    }
    if (name == "generalTextField") {
      setGeneralTextField(value);
      return;
    }
    if (name == "insuranceRecieved") {
      setInsuranceRecieved(value);
      return;
    }
    if (name == "takenYes") {
      setActionToken(value);
      return;
    }
    if (name == "takenNo") {
      setActionToken(value);
      return;
    }
    if (name == "comments") {
      setComments(value);
      return;
    }
  };
  const onChangeBreakupClaim = (e) => {
    const { name, value } = e.target;

    setBreakupVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log("dosArray", dosArrayRecieved);

  //new Function For Testing
  const transformArray = (arr) => {
    if (arr == undefined) {
      return [];
    }
    if (arr.length === 0) {
      return [];
    }

    let parseArr;

    try {
      parseArr = JSON.parse(arr);
    } catch (error) {
      parseArr = arr;
    }

    const filteredArray =
      parseArr && parseArr.filter((item) => item?.bfVal !== 0);

    const newArray =
      filteredArray.length > 0 &&
      filteredArray.map((item) => ({
        ...item,
        recievedAmount: item?.bfVal?.toString(),
        AmountDosVal: item?.bfAmount?.toString(),
        recievedAmountCC: "0",
        AmountDosValCC: "0",
        providerVal: "0",
        codingVal: 0,
        credientalingVal: 0,
        operationVal: 0,
        EdiVal: 0,
        total: 0,
        bfRow: true,
      }));

    return newArray;
  };

  // const transformArray=(arr)=>{

  //   if(arr.length==0){
  //     return []
  //   }

  //   let parseArr;

  //   try {
  //     parseArr = JSON.parse(arr);

  //   } catch (error) {

  //     parseArr = arr;

  //   }

  //   const newArray = parseArr.length>0 && parseArr.map(item => ({
  //     ...item,

  //     recievedAmount:item?.bfVal?.toString(),
  //     AmountDosVal: item?.bfAmount?.toString(),
  //     recievedAmountCC: "0",
  //     AmountDosValCC: "0",
  //     providerVal: "0",
  //     codingVal: 0,
  //     credientalingVal: 0,
  //     operationVal: 0,
  //     EdiVal: 0,
  //     total: 0,
  //     bfRow:true
  //   }));

  //   return newArray;
  // }

  const checkArraysHaveSameDateAndAmount = (arr1, arr2) => {
    const matchingItem =
      arr2 &&
      arr2.length > 0 &&
      arr1 &&
      arr1.length > 0 &&
      arr1.find((item1) =>
        arr2.some(
          (item2) =>
            item1.date === item2.date &&
            item1.recievedAmount === item2.recievedAmount
        )
      );

    return !matchingItem;
  };

  const onRemove = (val) => {
    let remainingObjects = FileList.filter(
      (obj) => obj.fileKey !== val.fileKey
    );
    setFileList(remainingObjects);
    // setResult((prevResult) => ({ ...prevResult, image: remainingObjects }));
    setImage(remainingObjects);
  };
  const handleChargeFieldsOnGetRecord = (record) => {
  
    if (record?.rcount == "0") {
      setBfVal(record.bfvalprv);
      setBfamount(record.bfprv);

      const newArray = transformArray(record?.dosArrayRecieved);
      setDosArrayRec(newArray);
      setImage([]);
      setFileList([]);
    } else {
      let obj = {};
      try {
        obj = JSON.parse(record.result[0]?.breakupval);
      } catch {
        obj = record.result[0]?.breakupval;
      }

      const newArray = transformArray(record?.dosArrayRecieved);

      console.log(record.result[0]?.dosArrayRecieved);
      let parsedArray;
      let dosArrayParsed;
      let dosParsedArray2;
      try {
        parsedArray = JSON.parse(record.result[0]?.dosArrayRecieved);
        dosArrayParsed = JSON.parse(record.result[0]?.dosArray);
        dosParsedArray2 = JSON.parse(record?.dosArrayRecieved);
        console.log("The string contains a valid JSON object.");
      } catch (error) {
        dosArrayParsed = record.result[0]?.dosArray;
        parsedArray = record.result[0]?.dosArrayRecieved;
        dosParsedArray2 = record?.dosArrayRecieved;
      }

      // setBfScreenArray(record?.dosArrayRecieved )

      setReceivedClaims(record.result[0]?.recievedval);
      setWorkedClaims(record.result[0]?.workedval);
      setWorkedAmount(record.result[0]?.workedamount);
      setPendingClaims(record.result[0]?.pendingval);
      setPendingAmount(record.result[0]?.pendingamountval);
      setTotalVal(record.result[0]?.totalval);
      setReceivedAmount(record.result[0]?.recievedamount);
      setProviderVal(obj?.providerval);
      setCodingVal(obj?.codingval);
      setCredientialVal(obj?.credval);
      setEdiVal(obj?.edival);
      setOperationVal(obj?.operval);
      setName(record.result[0]?.GeneralOpen);
      setDescription(record.result[0]?.insurance);
      setActionToken(record.result[0]?.stoken);
      setGeneralTextField(record.result[0]?.GeneralOpen);
      setInsuranceRecieved(record.result[0]?.insurance);
      setHelpingState((prev) => ({
        ...prev,
        noofClaims: {
          label: record.result[0]?.noofClaims,
          value: record.result[0]?.noofClaims,
        },
      }));

      let arr = [];
      try {
        arr = JSON.parse(record.result[0]?.image);
      } catch {
        arr = record.result[0]?.image;
      }
      setImage(arr);
      setDosArray(dosArrayParsed);
      setHelpingState((prev) => ({
        ...prev,
        bucketImages: record.result[0]?.image,
        updatedblodState: arr,
      }));
      setFileList(arr);
      let parsedArrDum = [];
      try {
        parsedArrDum = JSON.parse(record.result[0]?.dosArrayRecieved);
      } catch {
        parsedArrDum = record.result[0]?.dosArrayRecieved;
      }

      const result = checkArraysHaveSameDateAndAmount(newArray, parsedArrDum);
      //if matching objects then it return false
      let combineArray;
      if (result == false) {
        combineArray = JSON.parse(record.result[0]?.dosArrayRecieved);
      } else {
        let arr = [];
        try {
          arr = JSON.parse(record.result[0]?.dosArrayRecieved);
        } catch {
          arr = record.result[0]?.dosArrayRecieved;
        }
        console.log(newArray);
        if (newArray == false) {
          combineArray = [].concat(arr);
        } else {
          combineArray = newArray.concat(arr);
        }
      }
      setDosArrayRec(combineArray);

      setBfVal(record.bfvalprv);
      setBfamount(record.result[0].bfamount);
      setComments(record.result[0]?.comments);
    }
  };
  console.log("moiz here", dosArrayRecieved);

  const onPreview = (val, row) => {
    let obj = { bucketImages: val };
    setHelpingState((prev) => ({ ...prev, getSelectedRowDocument: obj }));
    // getBucketImages(obj).then((res) => {

    //   let blob = new Blob([res.data], { type: val.mimetype });
    //   var blobUrl = window.URL.createObjectURL(blob);

    //   let objRes = {
    //     name: val.originalName,
    //     mimetype: val.mimetype,
    //     url: val.url,
    //     fileKey: val.fileKey,
    //     base64String: blobUrl,
    //   };
    //   setHelpingState((prev) => ({
    //     ...prev,
    //     base64: objRes.base64String,
    //     isFileViewerOpen: true,
    //   }));
    // });
    setHelpingState((prev) => ({
      ...prev,
      //  base64: objRes.base64String,
      isFileViewerOpen: true,
    }));
  };
  useEffect(() => {
    if (helpingState?.isAllStatesAreSet) {
      onSaveNode();
    }
  }, [helpingState?.isAllStatesAreSet]);

  const onSaveNode = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    }

    if (dosArrayRecieved.length == 0) {
      toast.info("For Save! Please Add Some Dos Wise Record");
      setHelpingState((prev) => ({
        ...prev,
        loadingn: false,
        isAllStatesAreSet: false,
      }));
      return;
    }

    setHelpingState((prev) => ({ ...prev, loadingn: true }));
    const breakupval = `{
      "providerval": ${providerVal},
      "codingval": ${codingVal},
      "credval": ${credientialVal},
      "edival": ${ediVal},
      "operval": ${operationVal}
    }`;

    const formDataForSaveApi = new FormData();
    if (image && image.length > 0 && image != "[]") {
      image.forEach((el, index) => {
        formDataForSaveApi.append(`file`, el?.blobFile);
      });
    } else {
      formDataForSaveApi.append(`file`, null);
    }
   

    formDataForSaveApi.append("userid", onLoginSignOff.userid);
    formDataForSaveApi.append("sdate", date);
    formDataForSaveApi.append("noofClaims", helpingState?.noofClaims?.label);
    formDataForSaveApi.append("pid", practiceId);
    formDataForSaveApi.append("formtype", formTypeNoForApi);
    formDataForSaveApi.append("submittype", "No");
    formDataForSaveApi.append("bfval", bfVal);
    formDataForSaveApi.append("bfamount", bfamount);
    formDataForSaveApi.append("recievedval", receivedClaims);
    formDataForSaveApi.append("recievedamount", receivedAmount);
    formDataForSaveApi.append("workedval", workedClaims);
    formDataForSaveApi.append("workedamount", workedAmount);
    formDataForSaveApi.append("breakupval", breakupval);
    formDataForSaveApi.append("pendingval", pendingClaims);
    formDataForSaveApi.append("pendingamountval", pendingAmount);
    formDataForSaveApi.append("totalval", totalVal);
    formDataForSaveApi.append("GeneralOpen", generalTextField);
    formDataForSaveApi.append("insurance", insuranceRecieved);
    formDataForSaveApi.append("stoken", actionToken);
    formDataForSaveApi.append("comments", comments);
    formDataForSaveApi.append("dosArray", JSON.stringify(dosArray));
    formDataForSaveApi.append(
      "dosArrayRecieved",
      JSON.stringify(dosArrayRecieved)
    );

    insertFromType1ChargeEntryRecord(formDataForSaveApi)
      .then((result) => {
        if (
          result?.data &&
          result?.data.message == "Record Updated SuccessFully"
        ) {
          toast.info(result?.data.message);
          onClear();
          setHelpingState((prev) => ({
            ...prev,
            loadingn: false,
            isAllStatesAreSet: false,
          }));
          onRecordUpdateOrCreated("chargeEntry");
        }
        if (
          result?.data &&
          result?.data.message == "Record Created SuccessFully"
        ) {
          setHelpingState((prev) => ({
            ...prev,
            loadingn: false,
            isAllStatesAreSet: false,
          }));
          toast.info(result?.data.message);
          onClear();
          onRecordUpdateOrCreated("chargeEntry");
        }
      })
      .catch((err) => {
        setHelpingState((prev) => ({
          ...prev,
          loadingn: false,
          isAllStatesAreSet: false,
        }));
        if (
          err?.response?.data &&
          err?.response?.data?.message ==
            "Breakup should be equal to pending value!"
        ) {
          toast.info(err?.response?.data?.message);
        }
      });
  };

  const handleInputChangeF = (event) => {
    const selectedImage = event;
    setImage(selectedImage);

    setFileList(selectedImage);
  };

  const onClear = () => {
    setReceivedClaims("");
    setReceivedAmount("");
    setWorkedClaims("");
    setWorkedAmount("");
    setPendingClaims(null);
    setPendingAmount(null);
    setTotalVal(null);
    setProviderVal(0);
    setCodingVal(0);
    setCredientialVal(0);
    setEdiVal(0);
    setOperationVal(0);
    setGeneralTextField("");
    setInsuranceRecieved("");
    setActionToken(null);
    setImage("");
    setComments("");
    setDosArray([]);
    setDosArrayRec([]);
  };

  let totalValue =
    parseFloat(providerVal) +
    parseFloat(ediVal) +
    parseFloat(credientialVal) +
    parseFloat(codingVal) +
    parseFloat(operationVal);

  const openImageViwerModel = (val) => {
    setDocViewer(val);
    setHelpingState((prev) => ({ ...prev, isFileViewerOpen: val }));
  };
  const handleClose = () => {
    openGlobalTable(false);
    setBreakupVal(breakupObj);
    setDosModel(false);

    setdosDate("");
    setNameGeneric("Charge Entry");
    setisPendingSectionOpen(false);

    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmount: false,
      AmountDosVal: false,
      date: false,
    }));
  };

  const onChangeInput = (val) => {
    const { value, name } = val.target;

    sethandleInput(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      recievedAmountCC: false,
    }));
  };
  const onChangeAmount = (val) => {
    const { value, name } = val.target;
    setAmountDos(value);
    setErrorModel((prevErrorModel) => ({
      ...prevErrorModel,
      AmountDosValCC: false,
    }));
  };

  //--------------------

  const handleCommonEdit = async (rowData) => {
    //setPendingClaimss(rowData)

    if (rowData.AmountDosVal) {
      if (rowData.bfRow == true) {
        setisThisbfRow(true);
      } else {
        setisThisbfRow(false);
      }

      setGridEdit((prev) => ({ ...prev, isEdit: true, rowDataa: rowData }));

      sethandleInputRec(rowData.recievedAmount);

      setAmountDosRec(rowData.AmountDosVal);
      setRowID(rowData.id);
      sethandleInput(rowData.recievedAmountCC);
      setAmountDos(rowData.AmountDosValCC);
      setDatePickerVal(moment(rowData.date, "YYYY-MM-DD").toDate());
      setdosDateRec(rowData.date);
      setBreakupVal((prev) => ({
        ...prev,
        providerVal: rowData.providerVal,
        codingVal: rowData.codingVal,
        credientalingVal: rowData.credientalingVal,
        operationVal: rowData.operationVal,
        EdiVal: rowData.EdiVal,
        total: rowData.total,
      }));
      setHelpingState((prev) => ({
        ...prev,
        isDemographic: rowData?.isDemographic,
      }));
    } else {
      return;
    }

    // if (!rowData.hasOwnProperty("status")) {
    //   return;
    // }

    // handleEditStateC(rowData, dosArrayRecieved, setDosArrayRec);
  };
  console.log("dosArray", bfScreenArray);
  const handleChangeCommondos = (id, key, value, event) => {
    handleChangeC(id, key, value, event, dosArrayRecieved, setDosArrayRec);
  };
  const onSaveDosCo = () => {
    if (
      handleInputdosRec == "" &&
      AmountDosRec == "" &&
      handleInputdos == "" &&
      AmountDos == "" &&
      AllBreakupVal?.providerVal == "" &&
      AllBreakupVal?.codingVal == "" &&
      AllBreakupVal?.credientalingVal == "" &&
      AllBreakupVal?.EdiVal == "" &&
      AllBreakupVal?.operationVal == "" &&
      (dosArrayRecieved.length == 0 || dosArrayRecieved.length < 0)
    ) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setpendingAmountDos(0);

      onSaveDosC(
        false,
        dosArrayRecieved,
        setReceivedClaims,
        setDosModelRec,
        setReceivedAmount,
        null,
        null,
        null,
        setWorkedAmount,
        setWorkedClaims,
        null,
        null,
        null
        // setBfVal,
        // setBfamount
      );
      setHelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
    } else {
      setHelpingState((prev) => ({ ...prev, isSpeakerToaster: true }));
    }
  };
  const onChangeInputCC = (val) => {
    onChangeInputC(val, sethandleInputRec, setErrorModel);
  };
  const onChangeDateCC = (val) => {
    setDatePickerVal(val);
    onChangeDateC(val, setdosDateRec, setErrorModel, dosArrayRecieved);
  };
  const onChangeDAmountCC = (val) => {
    onChangeAmountC(val, setAmountDosRec, setErrorModel);
  };
  const handleCloseCC = () => {
    if (
      handleInputdosRec.length !== 0 ||
      AmountDosRec.length !== 0 ||
      handleInputdos.length !== 0 ||
      AmountDos.length !== 0
    ) {
      setHelpingState((prev) => ({ isBackSpeak: true }));
    } else {
      setHelpingState((prev) => ({ ...prev, isBackSpeak: false }));
      if (gridEdit.isEdit) {
        onAdddosCC();
      }

      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setNameGeneric("Charge Entry");
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      setpendingAmountDos(0);

      handleCloseC(
        setDosModelRec,
        setErrorModel,
        sethandleInputRec,
        setAmountDosRec,
        setdosDateRec,
        sethandleInput,
        setAmountDos,
        setPendingClaimss
      );
    }
  };

  // THIS IS aLL fIELD vALUE TAKER
  const onAdddosCC = async (e, arg) => {
    if (parseFloat(pendingClaimss) != parseFloat(AllBreakupVal.total)) {
      toast.info(
        "Pending Claims Breakup Total value should be equal to Pending Amount"
      );
      return;
    }

    let result =
      dosArrayRecieved.length > 0 &&
      dosArrayRecieved.some((el) => el.date === handledosDateRec);
    if (result) {
      toast.info("Duplicate dates not allowed");

      return;
    }

    await onAdddosC(
      handledosDateRec,
      handleInputdosRec,
      dosArrayRecieved,
      AmountDosRec,
      setDosArrayRec,
      setErrorModel,
      "charge",
      helpingState?.isDemographic,
      AllBreakupVal,
      null,
      handleInputdos,
      AmountDos,
      setDosArray,
      null,
      gridEdit,
      rowID,
      bfVal,
      bfRow
    );

    setisThisbfRow(false);
    sethandleInputRec("");
    setAmountDosRec("");
    sethandleInput("");
    setAmountDos("");
    setPendingClaimss(0);
    setpendingAmountDos(0);
    setdosDateRec("");
    setGridEdit((prev) => ({ ...prev, isEdit: false, rowDataa: null }));
    setHelpingState((prev) => ({ ...prev, isDemographic: false }));

    // setdosDateRec(moment(null).format('YYYY-MM-DD'))

    setBreakupVal(breakupObj);
  };
  console.log("dosArrayRecieved", dosArrayRecieved);
  const renderr = (val) => {
    setRenderEffect(val);
  };

  const onCleanDateSimpleC = () => {
    setdosDateRec("");
  };
  const onHandlePendingClaimss = (val) => {
    if (parseFloat(val) < 0) {
      toast.info(
        "Pending Claims value must be a positive number. Please verify your calculations."
      );
    }
    setPendingClaimss(val);
  };

  const handleClosePopPending = () => {
    setNameGeneric("Charge Entry");
    setModelPending(false);
  };

  const callGlobalDos = (val) => {
    openGlobalDos(val);
  };

  const openGlobalTable = (arg) => {
    setGlobalTable(arg);
  };

  const [openBf, setBf] = useState(false);
  const openBfScreen = () => {
    setBf(true);
  };
  const handleCloseBfScreen = () => {
    setBf(false);
  };
  const handleTaosterSpeak = () => {
    if (dosArrayRecieved && dosArrayRecieved.length == 0) {
      toast.info("Please Enter Date of Service Wise Record");
      return;
    }

    setHelpingState((prev) => ({ ...prev, isSpeakToaster: true }));
  };
  const handleSwalConfirm = (val) => {
    if (val) {
      setHelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
      onSaveNode();
    } else {
      setHelpingState((prev) => ({ ...prev, isSpeakToaster: false }));
    }
  };


  const handleSwalConfirmOnInternalSave = (val) => {
   

    if (val==true) {
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setpendingAmountDos(0);

      onSaveDosC(
        false,
        dosArrayRecieved,
        setReceivedClaims,
        setDosModelRec,
        setReceivedAmount,
        null,
        null,
        null,
        setWorkedAmount,
        setWorkedClaims,
        null,
        null,
        null
        // setBfVal,
        // setBfamount
      );

      setHelpingState((prev) => ({
        ...prev,
        isSpeakerToaster: false,
        isAllStatesAreSet: true,
      }));
    } else if (!val) {
      
      setHelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));
     
      
     
    } else if (val == "backToMainScreen") {
      callGlobalDos(false);
      setHelpingState((prev) => ({ ...prev, isSpeakerToaster: false }));

      sethandleInputRec("");
      setAmountDosRec("");
      sethandleInput("");
      setAmountDos("");
      setPendingClaimss(0);
      setpendingAmountDos(0);
      setdosDateRec("");
      setHelpingState((prev) => ({ ...prev, isDemographic: false }));
      setBreakupVal(breakupObj)
     
      
      
    }
  };

  const handleSwalConfirmBackButton = (val) => {
    if (val) {
      setHelpingState((prev) => ({ ...prev, isBackSpeak: false }));
      if (gridEdit.isEdit) {
        onAdddosCC();
      }

      callGlobalDos(false);
      setBreakupVal(breakupObj);
      setNameGeneric("Charge Entry");
      let obj = {
        isEdit: false,
        rowDataa: null,
      };
      setGridEdit(obj);
      setpendingAmountDos(0);

      handleCloseC(
        setDosModelRec,
        setErrorModel,
        sethandleInputRec,
        setAmountDosRec,
        setdosDateRec,
        sethandleInput,
        setAmountDos,
        setPendingClaimss
      );
    } else {
      setHelpingState((prev) => ({ ...prev, isBackSpeak: false }));
    }
  };

  const handlePpaperSubmission = (event) => {
    setHelpingState((prev) => ({ ...prev, noofClaims: event }));
  };

  return (
    <>
      {/* worked */}
      {helpingState?.loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      {helpingState.isSpeakToaster ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirm}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
        />
      ) : null}
      {helpingState.isSpeakerToaster ? (
        <NotifySwal
          showDenyButton={true}
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmOnInternalSave}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save Values"}
        />
      ) : null}
      {helpingState.isBackSpeak ? (
        <NotifySwal
          triggerNotify={true}
          handleCloseSwal={handleSwalConfirmBackButton}
          titleSweet={"Are you sure?"}
          text={"Do you want to Save this Form"}
        />
      ) : null}
      {openBf == true ? (
        <>
          <DateoofserviceBF
            handleClose={handleCloseBfScreen}
            dosArray={bfScreenArray}
          />
        </>
      ) : openScreenGlobalTable == true ? (
        <Dateoofservice
          bfVal={bfVal}
          isBlockAllhtml={true}
          renderr={renderr}
          renderEffect={renderEffect}
          // handleChange={handleChange}
          // handleEditState={handleEditState}
          // onSaveDos={onSaveDos}
          dosArray={dosArrayRecieved}
          // setDosArray={{ state: setDosArray, flag: "1" }}
          // onAdddos={onAdddos}
          //  onChangeDate={onChangeDate}
          //  onChangeInput={onChangeInput}
          //  onChangeInputCC={onChangeInputCC}
          handleClose={handleClose}
          //  showAmount={true}
          //  showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          columnHeader1={"Received Claims"}
          columnHeader2={"Received Amount"}
          columnHeader3={"Pending Claims"}
          columnHeader4={"Pending Amount"}
          breakupName={"Pending Claim Breakup"}
          //  practiceId={practiceId}
          //  dosPendingBreakup={{ isAllow: true, onChangeValues: onChangeValues }}

          setdosDate={setdosDate}
          onlyShowTable={true}
          SecondTableName={"charge"}
        />
      ) : openDosmodelRecState == true ? (
        <Dateoofservice
          isBlockAllhtml={true}
          // recievedClaimArraydos={[]}
          // onCleanDate={onCleanDateSimpleC}
          //  errorModel={errorModel}
          // setDosArray={{ state: setDosArrayRec, flag: "1" }}
          renderr={renderr}
          renderEffect={renderEffect}
          // handleChange={handleChangeCommondos}
          // handleEditState={handleCommonEdit}
          // onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          // onAdddos={onAdddosCC}
          //onChangeDate={onChangeDateCC}
          // onChangeInput={onChangeInputCC}
          handleClose={handleCloseCC}
          // onChangeAmount={onChangeDAmountCC}
          //  showAmount={true}
          //  showPortal={false}
          claimHeading={"Received Claims"}
          claimAmount={"Received Amount"}
          //  practiceId={practiceId}
          onlyShowTable={false}
        />
      ) : globalDos == true ? (
        <DateoofserviceGlobal
          helpingState={helpingState}
          onChangeDemo={onChangeDemo}
          handleKeyPress={handleKeyPress}
          Footer={footerState}
          flagFooter={"charge"}
          bfScreenArray={bfScreenArray}
          handleCloseBfScreen={handleCloseBfScreen}
          bfVal={bfVal}
          datePickerVal={datePickerVal}
          openGlobalTable={openGlobalTable}
          gridEdit={gridEdit}
          setGridEdit={setGridEdit}
          AllBreakupVal={AllBreakupVal}
          onChangeBreakupClaim={onChangeBreakupClaim}
          AmountDos={AmountDos}
          AmountDosRec={AmountDosRec}
          setpendingAmountDos={setpendingAmountDos}
          pendingAmountDos={pendingAmountDos}
          pendingClaimss={pendingClaimss}
          setPendingClaimm={onHandlePendingClaimss}
          handleInputdos={handleInputdos}
          handleInputdosCC={handleInputdosRec}
          allowPending={true}
          isPendingSectionOpen={true}
          handleFormTypeAndPract={handleFormTypeAndPract}
          products={products}
          date={date}
          recievedClaimArraydos={dosArrayRecieved}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          setDosArray={{
            state: setDosArrayRec,
            flag: "1",
            statebf: setBfScreenArray,
          }}
          renderr={renderr}
          renderEffect={renderEffect}
          handleChange={handleChangeCommondos}
          handleEditState={handleCommonEdit}
          onSaveDos={onSaveDosCo}
          dosArray={dosArrayRecieved}
          onAdddos={onAdddosCC}
          onChangeInput={onChangeInput}
          onChangeInputCC={onChangeInputCC}
          onChangeDate={onChangeDateCC}
          handledosDate={handledosDateRec}
          handleClose={handleCloseCC}
          onChangeAmount={onChangeAmount}
          onChangeAmountCC={onChangeDAmountCC}
          showAmount={true}
          showPortal={false}
          claimHeading={"Worked Claims"}
          claimAmount={"Worked Amount"}
          claimHeading1={"Received Claims"}
          claimAmount1={"Received Amount"}
          practiceId={practiceId}
          onlyShowTable={false}
        />
      ) : openModelPending ? (
        <Dateoofservice
          isBlockAllhtml={true}
          onlyShowTable={true}
          recievedClaimArraydos={[]}
          onCleanDate={onCleanDateSimpleC}
          errorModel={errorModel}
          renderr={renderr}
          renderEffect={renderEffect}
          dosArray={dosArray}
          handleClose={handleClosePopPending}
          showAmount={false}
          showPortal={false}
          practiceId={practiceId}
          showTableCheck={"charge"}
        />
      ) : (
        <>
          {helpingState?.isFileViewerOpen == true && (
            <ViewDocument
              seletedRow={helpingState?.getSelectedRowDocument}
              openImageViwerModel={openImageViwerModel}
            />
          )}
          {/* <DocViewer
            docViewer={docViewer}
            image={image}
            openImageViwerModel={openImageViwerModel}
          /> */}
          <div class="row">
            <DateAndPractice
              openGlobalTable={openGlobalTable}
              callGlobalDos={callGlobalDos}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleFormTypeAndPract={handleFormTypeAndPract}
              products={products}
              date={date}
              showChargeType={true}
              onChangePatStatType={handlePpaperSubmission}
              PatTypeValue={helpingState?.noofClaims}
            />

            <Panel
              defaultExpanded={true}
              header="Charge Entry Fields"
              collapsible={true}
            >
              <div className="row mt-4">
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label
                      class="inputLabel"
                      style={{ color: "black" }}
                      onClick={openBfScreen}
                    >
                      B/F Claims
                      {/* <span><AddOutlineIcon  style={{color:"#0e7490"}}/></span> */}
                    </label>
                    {
                      <input
                        class="form-control inputField"
                        type="text"
                        name="bfVal"
                        placeholder={""}
                        value={bfVal}
                        disabled
                      />
                    }
                    {/* <input
                  class="form-control inputField"
                  type="text"
                  name="bfVal"
                  placeholder={""}
                  value={bfVal}
                  disabled
                /> */}
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Received Claims
                    </label>

                    <input
                      class="form-control inputField"
                      type=""
                      name="receivedClaims"
                      placeholder=""
                      value={receivedClaims}
                      onChange={onChangeValues}
                      disabled
                    />
                    {/* <CustomInputGroupWidthButtonC
                  value={receivedClaims}
                  disabled={true}
                  size="md"
                  onClick={openDosmodelWorked}
                  placeholder="Medium"
                /> */}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Worked Claims
                    </label>
                    {/* <CustomInputGroupWidthButton
                  value={workedClaims}
                  disabled={true}
                  size="md"
                  onClick={openDosmodel}
                  placeholder="Medium"
                /> */}
                    <input
                      class="form-control inputField"
                      type="text"
                      name="workedClaims"
                      placeholder=""
                      value={workedClaims}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Pending Claims
                    </label>

                    <input
                      class="form-control inputField"
                      type="text"
                      name="pendingClaims"
                      placeholder=""
                      value={
                        receivedClaims && workedClaims
                          ? receivedClaims - workedClaims
                          : 0
                      }
                      disabled
                    />

                    {/* <CustomInputGroupWidthButton
                  value={
                    receivedClaims && workedClaims
                      ? receivedClaims - workedClaims
                      : 0
                  }
                  disabled={true}
                  size="md"
                  onClick={openDosmodelPending}
                  placeholder="Medium"
                /> */}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      B/F Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="bfamount"
                        placeholder=""
                        value={bfamount}
                        disabled
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Received Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        disabled
                        class="form-control inputField"
                        type="text"
                        name="receivedAmount"
                        placeholder=""
                        value={receivedAmount}
                        onChange={onChangeValues}
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Worked Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="workedAmount"
                        placeholder=""
                        disabled
                        value={workedAmount}
                        onChange={onChangeValues}
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Pending Amount
                    </label>
                    <div class="form-group input-icon">
                      <input
                        class="form-control inputField"
                        type="text"
                        name="pendingAmount"
                        placeholder=""
                        value={
                          receivedAmount && workedAmount
                            ? receivedAmount - workedAmount
                            : 0
                        }
                        onChange={onChangeValues}
                        disabled
                      />
                      <i className="lightGreen">$</i>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>

            <div className="col-lg-12 mb-2 text-center"></div>

            <div class="col-sm-6 col-lg-3"></div>
            <div class="col-sm-6 col-lg-3"></div>
            <div class="col-sm-6 col-lg-3"></div>
            <div className="col-lg-12 mb-2 text-center">
              {/* <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Pending Claims Breakup
              </h4> */}
            </div>
            <Panel
              defaultExpanded={true}
              header="Pending Claims Breakup"
              collapsible
            >
              <div className="row mt-3">
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Provider
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="providerVal"
                      placeholder=""
                      value={providerVal}
                      disabled
                      onChange={onChangeValues}
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Coding
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="codingVal"
                      placeholder=""
                      value={codingVal}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Credentialing
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="credientialVal"
                      placeholder=""
                      value={credientialVal}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      EDI
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="ediVal"
                      placeholder=""
                      value={ediVal}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Operation
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="operationVal"
                      placeholder=""
                      value={operationVal}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group mb-2">
                    <label class="inputLabel" style={{ color: "black" }}>
                      Total
                    </label>

                    <input
                      class="form-control inputField"
                      type="number"
                      name="totalVal"
                      placeholder=""
                      value={totalValue}
                      onChange={onChangeValues}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Panel>

            {/* <div className="col-lg-12 mt-4 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                Remarks
              </h4>
            </div> */}
            <div class="mt-4"></div>
            <Panel defaultExpanded={true} header="Remarks" collapsible={true}>
              {
                <div class="col-sm-12 col-lg-12 mt-4">
                  <div class="form-group mb-2">
                    <label
                      class="inputLabel"
                      style={{ color: "black" }}
                    ></label>
                    <textarea
                      rows="4"
                      cols="50"
                      type="text"
                      name="generalTextField"
                      placeholder="Enter Remarks Here"
                      class="form-control inputField"
                      value={generalTextField}
                      onChange={onChangeValues}
                    ></textarea>
                  </div>
                </div>
              }
            </Panel>
            <div className="mt-4"></div>

            <FileUpload2
              onRemove={onRemove}
              setobjCLuster={setobjCLuster}
              objectcluster={objectcluster}
              onPreview={onPreview}
              fileList={FileList}
              openImageViwerModel={openImageViwerModel}
              handleInputChange={handleInputChangeF}
              image={image}
            />
            {/* <div class="col-sm-6 col-lg-3">
              <div class="form-group mb-2">
                <label class="inputLabel" style={{ color: "black" }}>
                  Insurance Received
                </label>

                <input
                  class="form-control inputField"
                  type="text"
                  name="insuranceRecieved"
                  placeholder=""
                  value={insuranceRecieved}
                  onChange={onChangeValues}
                />
              </div>
            </div> */}
            {/* <div class="col-sm-6 col-lg-3 d-flex ">
              <div class="form-group mb-2 ">
                <label class="inputLabel" style={{ color: "black" }}>
                  Action Taken{" "}
                </label>
                <div class="row ml-1">
                  <div
                    class="form-check form-check-inline "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      alignContent: "center",
                    }}
                  >
                    <input
                      class="form-check-input mr-2  mb-2"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="Yes"
                      name="takenYes"
                      onChange={onChangeValues}
                      checked={actionToken == "Yes" ? true : false}
                    />
                    <label class="mr-4" for="inlineCheckbox1">
                      Yes{" "}
                    </label>
                    <input
                      class="form-check-input mr-2  mb-2"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="No"
                      name="takenNo"
                      onChange={onChangeValues}
                      checked={actionToken == "No" ? true : false}
                    />
                    <label class="mr-4" for="inlineCheckbox2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {actionToken == "Yes" ? (
              <div class="col-sm-6 col-lg-3">
                <div class="form-group mb-2">
                  <label class="inputLabel" style={{ color: "black" }}>
                    (if yes? Remarks)
                  </label>

                  <input
                    class="form-control inputField"
                    type="text"
                    name="comments"
                    placeholder=""
                    onChange={onChangeValues}
                    value={comments}
                  />
                </div>
              </div>
            ) : null} */}
            {/* <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                  paddingBottom: "10px",
                }}
              >
                 Attachments
              </h4>
            </div> */}

            {/* <div className="col-lg-12 mb-2 text-center">
              <h4 class="inputLabel " style={{ color: "black" }}>
                Attachments
              </h4>
            </div> */}
            <div class="col-sm-12 col-lg-12">
              <div class="form-group ">
                {/* <label class="inputLabel" style={{ color: "black" }}>
              Select File{" "}
            </label> */}
                {/* <ImageUploaderr
                  openImageViwerModel={openImageViwerModel}
                  image={image}
                  handleInputChange={handleInputChange}
                /> */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 "></div>

            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 "></div>
            <div class="col-sm-6 col-lg-3 mt-2">
              <div class="form-group ">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    // style={{
                    //   backgroundColor: "white",
                    //   color: "#40E0D0",
                    //   borderRadius: "10px",
                    //   fontSize:18,
                    //   height: "3rem",
                    //   width: "6rem",
                    //   border: "2px solid ",
                    // }}
                    className="btnClear mr-1 "
                    onClick={onClear}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="btnSave"
                    onClick={handleTaosterSpeak}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default ChargeEntry;
