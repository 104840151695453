import ReactDropdown from "react-dropdown";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { tlAdd } from "../../../../Utils/ApiManager";

function Meeting({ record, practiceId, date, formTypeNoForApi }) {
    const { onLoginSignOff } = useSelector((state) => state);
  let payload = {
    userid: "",
    sdate: "",
    pid: "",
    submittype: "No",
    formtype: "",
    field1: "",
    field2: "",
    field3: 0,
    field4: 0,
    field5: "",
  };
  const [state, setState] = useState(payload);
  const handleFrequency = (val) => {
    setState((prevResult) => ({
      ...prevResult,
      field1: val.value,
    }));
  };
  const handleStatus = (val) => {
    setState((prevResult) => ({
      ...prevResult,
      field2: val.value,
    }));
  };
  const handletext = (e) => {
    const { name, value } = e.target;
    if (name == "comment") {
      setState((prevResult) => ({
        ...prevResult,
        field5: value,
      }));
      return;
    }
  };
  const onclear = () => {
    setState(payload);
  };

  const onSave = async () => {
    if (practiceId == null) {
      toast.info("Please Select Practice Name");
      return;
    } else {
      const formDataForSaveApi = new FormData();
      formDataForSaveApi.append("userid", onLoginSignOff.userid);
      formDataForSaveApi.append("sdate", date);
      formDataForSaveApi.append("pid", practiceId);
      formDataForSaveApi.append("formtype", formTypeNoForApi);
      formDataForSaveApi.append("submittype", "No");
      formDataForSaveApi.append("field1", state?.field1);
      formDataForSaveApi.append("field2", state?.field2);
      formDataForSaveApi.append("field3", state?.field3);
      formDataForSaveApi.append("field4", state?.field4);
      formDataForSaveApi.append("field5", state?.field5);

      let resultt = await tlAdd(formDataForSaveApi);
      if (
        resultt?.data &&
        resultt?.data.message === "Record Updated Successfully!"
      ) {
        onclear();
        toast.info("Record Updated Successfully");
      }
      if (
        resultt?.data &&
        resultt?.data.message == "Record Created Successfully!"
      ) {
        onclear()
        toast.info("Record Created Successfully!");
      }
    }
  };

  return (
    <>
      <div className="row">
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Meeting
            </label>
            <ReactDropdown
              value={"please select"}
              placeholder="Select an option"
              // onChange={handlePracticeVal}
              options={["Meeting"]}
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Frequency
            </label>

            <ReactDropdown
              value={"please select"}
              placeholder="Select an option"
              onChange={handleFrequency}
              options={["Daily", "Weekly", "Bi-Weekly", "Monthly", "Other"]}
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-2">
            <label class="inputLabel" style={{ color: "black" }}>
              Status
            </label>
            <ReactDropdown
              value={"please select"}
              placeholder="Select an option"
              onChange={handleStatus}
              options={["Done", "Postponed", "Cancelled", "No Show"]}
            />
          </div>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="form-group  mb-3 mt-3 ">
            <textarea
              class="form-control inputField"
              placeholder="Comments if any!"
              name={"comment"}
              onChange={handletext}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
         
        </div>
        <div class="col-sm-6 col-lg-3 mt-2">
          <div class="form-group mb-2 ">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                onClick={onclear}
                className="btnClear mr-1 "
              >
                Clear
              </button>
              <button type="button" onClick={onSave} className="btnSave">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Meeting;
