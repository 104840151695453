import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
  
 
  
 
  
 

export default function LineGraph({data,options}) {
 
  console.log(data,'data For party')
    
  return (
    <Bar options={options} data={data} />
  )
}
