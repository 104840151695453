import React, { useEffect, } from "react";
import "../Operation/operation.css";
import { useState } from "react";
// import OperationMainForm from "./OperationMainForm";

import { useSelector } from "react-redux";
import { fetchProduct, getFirstThreeFormRecord } from "../../Utils/ApiManager";
import { Panel } from "rsuite";
import moment from "moment";

import FormTypeAudit from "./FormAudit";
import AuditMainForm from "./AuditMainForm";
import axios from "axios";

function Audit() {
 

  
  const [formType, setFormType] = useState(null);
  const [startDatee, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const { onLoginSignOff } = useSelector((state) => state);
  const [tab, setTab] = useState(1);

  


  const handleFormVal = (val) => {
  
   
    if (val.name == "startDate") {
      setStartDate(val.val);
      return;
    }
    if (val.name == "endDate") {
      setEndDate(val.val);
      return;
    }
    if (val.name == "formType") {
      setFormType(val.value);

      return;
    }
  };
  console.log('statDtae',startDatee,endDate)
  useEffect(() => {
    fetchProducts();
 
  }, []);
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
       
        const allOption = { id: "selectAll", label: "Select All Practices" };

      setProducts([...optionList]);
    }
  };

  return (
    <>
      <div className="row"  >
        <div className="col-lg-12">
        
            <div class="p-4 navcolor bg-white rounded  mb-5">
              <div>
              
                <AuditMainForm    date={date} handleFormVal={handleFormVal} setProducts={setProducts} products={products} startDatee={startDatee} endDate={endDate} formType={formType}  />
                
                {/* <TLMainForm   formType={formType}/> */}
                {/* <OperationMainForm formType={formType} record={record} date={date} formTypeNoForApi={formTypeNoForApi} /> */}
              </div>
           
          </div>
        </div>

        {/* <div className="col-lg-3">
          <div class="container">
            <div className="p-2 navcolor bg-white rounded shadow ">
              <FormTypeAudit
                date={date}
                handleFormVal={handleFormVal}
                products={products}
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
export default Audit;
