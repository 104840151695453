import React, { useEffect, useState } from "react";
import UserAuditTable from "../Audit/userTaskViewTable2";
import {Panel} from "rsuite"
import Select from "react-select";
import {
  TaskListRecord,
  fetchProduct,
  searchFetchTask,
  userIdess,
} from "../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { CheckPicker } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { DatePicker, Stack } from "rsuite";
import moment from "moment";
import DrawerNotification from "../layout/DrwaerNotification";
import userRolesObject from "../../Common/AllRolesInfo";

export default function ClaimLevel({ sethelpingObj, helpingObj }) {
  const { onLoginSignOff } = useSelector((state) => state);
  useEffect(() => {
    fetchProducts();
  }, []);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    callFetchRecordApi();
  }, [page, limit]);

  useEffect(() => {
    
    if (helpingObj?.isOverAllPopupOpen == false) {
      callFetchRecordApi();
    }
  }, [helpingObj?.isOverAllPopupOpen]);

  const callFetchRecordApi = () => {
    let obj = {
      loggedid: onLoginSignOff.userid,
      page: page,
      pagesize: limit,
    };
    searchFetchTask(obj)
      .then((res) => {
        if (res && res.data) {
          let name = "";
          let userbiller = "";

          if (
            onLoginSignOff.type == 19 ||
            onLoginSignOff.type == userRolesObject.tl ||
            onLoginSignOff.type == userRolesObject.backupTeam
          ) {
            name = onLoginSignOff.name;
          } else {
            userbiller = onLoginSignOff.name;
          }

          let arr = res.data?.data.map((item) => {
            // Replace taskStatus with 'Complete' or 'Incomplete'
            item.taskStatus = item.taskStatus ? "Complete" : "Incomplete";

            // Check if the given name is in userName or victimName
            if (item.userName === name || item.victimName === name) {
              item.realUser =
                item.userName === name ? item.victimName : item.userName;
            }

            // Check if the given userbiller is in userName or victimName
            if (
              item.userName === userbiller ||
              item.victimName === userbiller
            ) {
              item.realTeamLead =
                item.userName === userbiller ? item.victimName : item.userName;
            }

            return item;
          });
          sethelpingObj((prev) => ({ ...prev, claimLevelRecordArray: arr }));
        }
      })
      .catch((err) => {
        // toast.info('SomeThing Went Wrong')
      });
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const onPageChange = (val) => {
    setPage(val);
  };

  const handlePracticeVal = (val) => {
    sethelpingObj((prev) => ({ ...prev, practiceSelectedVal: val }));
    onSelectPractice(val);
  };

  const onSelectPractice = async (selectedOption) => {
    let pulist = [selectedOption].map((item) => item.value).join(",");

    const formDatag = new FormData();
    formDatag.append("pidies", pulist);
    let res = await userIdess(formDatag);
    let userLists =
      res &&
      res.data &&
      res.data.map((item) => ({
        value: item.id,
        label: `${item.fname} ${item.lname}`,
      }));
    sethelpingObj((prev) => ({ ...prev, practiceWiseUserlist: userLists }));
  };
  const onShowRemakrs = (row) => {
    sethelpingObj((prev) => ({
      ...prev,
      selectedArray: row,
      isOverAllPopupOpen: true,
    }));
  };

  const DosWiseTable = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "Dos", rowSpan: 1 },
    {
      colName: `User`,
      flexGrow: 1.9,
      dataKey: "victimName",
      rowSpan: 1,
    },
    {
      colName: `Team Lead`,
      flexGrow: 1.9,
      dataKey: "realTeamLead",
      rowSpan: 1,
    },

    {
      colName: `Form Name`,
      flexGrow: 1.9,
      dataKey: "formName",
      rowSpan: 1,
    },
    {
      colName: `Task Status`,
      flexGrow: 1.9,
      dataKey: "taskStatus",
      rowSpan: 1,
    },
    {
      colName: `Acknowledged`,
      flexGrow: 1.9,
      dataKey: "acknowledged",
      rowSpan: 1,
    },
    {
      colName: "View Details",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowRemakrs, btnType: "info" }],

      editRow: true,
    },
  ];

  const DosWiseTableTl = [
    { colName: "DOS", flexGrow: 1.9, dataKey: "Dos", rowSpan: 1 },
    {
      colName: `User`,
      flexGrow: 1.9,
      dataKey: "realUser",
      rowSpan: 1,
    },

    {
      colName: `FormName`,
      flexGrow: 1.9,
      dataKey: "formName",
      rowSpan: 1,
    },
    {
      colName: `Task Status`,
      flexGrow: 1.9,
      dataKey: "taskStatus",
      rowSpan: 1,
    },
    {
      colName: `Acknowledged`,
      flexGrow: 1.9,
      dataKey: "acknowledged",
      rowSpan: 1,
    },
    {
      colName: "View Details",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowRemakrs, btnType: "info" }],

      editRow: true,
    },
  ];
  const fetchProducts = async () => {
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);
    let res = await fetchProduct(formData);
    if (res?.data) {
      let optionList =
        res &&
        res?.data.map((item) => ({
          value: item.pid,
          label: `${item.pname}-${item.pcode}`,
        }));
      sethelpingObj((prev) => ({ ...prev, products: optionList }));
      // setProducts(optionList);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      textSize: "14px",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      zIndex: 99999999999,
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)", // Background color on hover
        cursor: "pointer", // Change cursor on hover
        color: "white",
      },
    }),
  };
  const renderMenu = (menu) => {
    if (helpingObj.practiceWiseUserlist === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> loading...
        </p>
      );
    }
    return menu;
  };

  const onChangeDate = (val, arg) => {
    const parsedDate = moment(val);
    const formattedDate = parsedDate.format("YYYY-MM-DD");

    sethelpingObj((prev) => ({ ...prev, sdate: formattedDate }));
  };

  const onChangeDateDos = (val, arg) => {
    const parsedDate = moment(val);
    const formattedDate = parsedDate.format("YYYY-MM-DD");

    sethelpingObj((prev) => ({ ...prev, dosDate: formattedDate }));
  };
  const onCleanDate = (val, arg) => {
    sethelpingObj((prev) => ({ ...prev, sdate: null }));
  };
  const onCleanDateDos = (val, arg) => {
    sethelpingObj((prev) => ({ ...prev, dosDate: null }));
  };

  const onChangeCalendarDate = (val) => {};

  const onSearch = () => {
    let obj;
    if (onLoginSignOff.type == 19) {
      obj = {
        victimid: onLoginSignOff.userid,
        pid: helpingObj?.practiceSelectedVal?.value,
        sdate: helpingObj?.sdate,
        Dos: helpingObj?.dosDate,
        role: "ClaimLevel",
      };
    } else {
      obj = {
        victimid: onLoginSignOff.userid,
        pid: helpingObj?.practiceSelectedVal?.value,
        sdate: helpingObj?.sdate,
        Dos: helpingObj?.dosDate,
        role: "ClaimLevel",
      };
    }

    TaskListRecord(obj)
      .then((res) => {
        if (res && res.data) {
          if (res && res.data) {
          
            let name = "";
            let userbiller = "";

            if (onLoginSignOff.type == 19) {
              name = onLoginSignOff.name;
            } else {
              userbiller = onLoginSignOff.name;
            }

            let arr =
              res.data &&
              res.data.length > 0 &&
              res.data?.map((item) => {
                // Replace taskStatus with 'Complete' or 'Incomplete'
                item.taskStatus = item.taskStatus ? "Complete" : "Incomplete";

                // Check if the given name is in userName or victimName
                if (item.userName === name || item.victimName === name) {
                  item.realUser =
                    item.userName === name ? item.victimName : item.userName;
                }

                // Check if the given userbiller is in userName or victimName
                if (
                  item.userName === userbiller ||
                  item.victimName === userbiller
                ) {
                  item.realTeamLead =
                    item.userName === userbiller
                      ? item.victimName
                      : item.userName;
                }

                return item;
              });
            if (arr == false) {
              sethelpingObj((prev) => ({ ...prev, claimLevelRecordArray: [] }));
            } else {
              sethelpingObj((prev) => ({
                ...prev,
                claimLevelRecordArray: arr,
              }));
            }
          }
        }
      })
      .catch((err) => {});
  };
  const searchOverAllbyonlyvictimid = () => {};
  const isDrwaerOnNotification = () => {
    sethelpingObj((prev) => ({ ...prev, isOverAllPopupOpen: false }));
  };

  console.log(
    "helpingObj.claimLevelRecordArray",
    helpingObj.claimLevelRecordArray
  );
  const getRequriedPara=()=>{

  }

  return (
    <div className="row">
      {helpingObj.isOverAllPopupOpen ? (
        <DrawerNotification
          searchOverAllbyonlyvictimid={searchOverAllbyonlyvictimid}
          stateHelping={helpingObj}
          isDrwaerOnNotification={isDrwaerOnNotification}
        />
      ) : null}
      <Panel defaultExpanded={true} header="Claim Level Audit" collapsible >
        <div className="row mt-4">
        <div class="col-sm-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Practice Name
          </label>
          <Select
            options={helpingObj.products || []}
            placeholder="Select an option"
            onChange={handlePracticeVal}
            value={{
              label: helpingObj.practiceSelectedVal?.label,
              value: helpingObj.practiceSelectedVal?.value,
            }}
            styles={customStyles}
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Entry Date
          </label>
          <br></br>
          <DatePicker
            name={"Entry Date"}
            onChange={onChangeDate}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDate}
            style={{ width: 600 }}
            oneTap
            defaultValue={null}
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group mb-2">
          <label class="inputLabel" style={{ color: "black" }}>
            Dos Date
          </label>
          <br></br>
          <DatePicker
            name={"Dos Date"}
            onChange={onChangeDateDos}
            onChangeCalendarDate={onChangeCalendarDate}
            onClean={onCleanDateDos}
            style={{ width: 600 }}
            oneTap
            defaultValue={null}
          />
        </div>
      </div>
      <div class="form-group mb-2 ">
        <div class="d-flex justify-content-end mt-4">
          <button type="button" class="btnClear mr-1 ">
            Clear
          </button>
          <button type="button" class="btnSave" onClick={onSearch}>
            Search
          </button>
        </div>
      </div>
        </div>
      
      </Panel>
      <br>
      </br>

      

      <div className="col-lg-12 mb-2 mt-3 text-center">
        <h4
          class="inputLabel "
          style={{
            color: "black",
            borderBottom: "2px solid rgb(14, 116, 144)",
            paddingBottom: "10px",
          }}
        >
          Claim Level Audit
        </h4>
        <UserAuditTable
         getRequriedPara={getRequriedPara}

        tableHeight={400}
          onPageChange={onPageChange}
          handleChangeLimit={handleChangeLimit}
          page={page}
          limit={limit}
          rowHeight={48}
          dateForgrid={helpingObj.claimLevelRecordArray}
          Array={onLoginSignOff.type == 19 ? DosWiseTableTl : DosWiseTable}
          rowShown={5}
          loader={false}
        />
      </div>
    </div>
  );
}
