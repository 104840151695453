import React, { useState, useEffect } from "react";
import UserAuditTable from "../../../Audit/userTaskViewTable";
import Dateoofservice from "../../../../Common/Dateoofservice";
import ChargeTypeRemarksPop from "../ChargeEntry/DrawerChargeEntry";

export default function PaymentAudit({
  flagIndi,
  practiceId,
  date,
  setStateprev,
  setHelpingState,
  helpingStatem,
  loading,
  tableState,
  setDosArray,

  dosArray,
}) {
  let obj={
    recordEob:[],
    recordEra:[],
    combineRecord:[],
    isEobDosOpen:false,
    isEraDosOpen: false,
    parsedPatStateArray:[],
    isToggleRemarks:false,


    //Ar
    recievedAmountCC:"",
    recievedAmount:"",
    pending:""



  }
  const [state,setState]=useState(obj)
  useEffect(() => {
    
 
    let paresedArray2;
    let parsedEra;
    let simp=helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved==""||helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved==null
   // let simp2=helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra==""||helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra==null
    if(simp){
      setState((prev)=>({...prev,recordEob:[]}))
    }
    else{
      try {
        paresedArray2 = JSON.parse(helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved||[]);
       
  
        console.log("The string contains a valid JSON object.");
      } catch (error) {
        paresedArray2 = helpingStatem.holdSubDosTableData[0]?.dosArrayRecieved
       
      }
     
      setState((prev)=>({...prev,recordEob:paresedArray2}))
      setStateprev((prev)=>({...prev,parsedPatStateArray:paresedArray2}))
     
    }
    //  if(simp2){
    //   setState((prev)=>({...prev,recordEra:[]}))

    // }
    // else{
    //   try {
       
    //     parsedEra = JSON.parse(helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra ||[]);
  
       
    //   } catch (error) {
       
    //     parsedEra = helpingStatem.holdSubDosTableData[0]?.dosArrayRecievedEra;
    //   }
     
    //   setState((prev)=>({...prev,recordEra:parsedEra}))
      
    // }
    console.log('paresedArray2',paresedArray2,'parsedEra',parsedEra)
   
 
  }, [helpingStatem.holdSubDosTableData])
  useEffect(() => {
   
    let res=FuncToJoinTwoArrays(state.recordEob,state.recordEra)
    setState((prev)=>({...prev,combineRecord:res}))



    
  }, [state.recordEob,state.recordEra])



  
  const FuncToJoinTwoArrays=(date,date2)=>{
    const result = [];

  // Combine dates from both arrays into a single array for comparison
  const allDates = [...date, ...date2];

  // Create a set of unique dates
  const uniqueDates = new Set(allDates.map((item) => item.date));

  // Iterate over the unique dates and calculate sums for each date
  for (const d of uniqueDates) {
    const matchedDates1 = date.filter((item) => item.date === d);
    const matchedDates2 = date2.filter((item) => item.date === d);
   
    const insuranceSum = matchedDates1.reduce((sum, item) => sum + parseFloat(item.insurance), 0);
    const patientsSum = matchedDates1.reduce((sum, item) => sum + parseFloat(item.Patients), 0);
    const adjustmentsSum = matchedDates1.reduce((sum, item) => sum + parseFloat(item.Adjustments), 0);
    const DenialAmountSum = matchedDates1.reduce((sum, item) => sum + parseFloat(item.DenialAmount), 0);

    const insuranceSum2 = matchedDates2.reduce((sum, item) => sum + parseFloat(item.insurance), 0);
    const patientsSum2 = matchedDates2.reduce((sum, item) => sum + parseFloat(item.Patients), 0);
    const adjustmentsSum2 = matchedDates2.reduce((sum, item) => sum + parseFloat(item.Adjustments), 0);
    const DenialAmountSum2 = matchedDates2.reduce((sum, item) => sum + parseFloat(item.DenialAmount), 0);

    const newObj = {
      date: d,
      insurance: insuranceSum + insuranceSum2,
      Patients: patientsSum + patientsSum2,
      Adjustments: adjustmentsSum + adjustmentsSum2,
      DenialAmount:DenialAmountSum+DenialAmountSum2
    };

    result.push(newObj);
  }

  return result;

  }
  
  
  const onShowTable = (rowData) => {
    
    let matchedArray = tableState.filter(item => item.userid === String(rowData.userid));
    setHelpingState((prev) => ({ ...prev, isSwitchTable: 2 ,holdSubDosTableData:matchedArray}));
  };

  const onShowEobDos=(rowData)=>{

    let matchedArray = tableState.filter(item => item.userid === String(rowData.userid));
    setHelpingState((prev) => ({ ...prev, isSwitchTable: 2 ,holdSubDosTableData:matchedArray}));
    setState((prev)=>({...prev,isEobDosOpen:true}))
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: true }));
    setStateprev((prev)=>({...prev,isEobDosOpen:true}))

}
const onShowEraDos=()=>{
  setState((prev)=>({...prev,isEraDosOpen:true}))
  setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: true }));

}


const onShowRemakrs = (rowData) => {

 
  setState((prev) => ({ ...prev, isToggleRemarks: true, Dos: rowData.date,
    recievedAmountCC:rowData.recievedAmountCC,
    recievedAmount:rowData.recievedAmount,
    internalNonVerify:rowData.internalNonVerify,
    AmountDosValCC:rowData.AmountDosValCC,
    AmountDosVal:rowData.AmountDosVal,
    pendingAmount:rowData.pendingAmount

  
  }));
};

const CloseAuditRemark = (val) => {
  setState((prev) => ({ ...prev, isToggleRemarks: val }));
};




  const MainTable = [
    {
      colName: `User`,
      flexGrow: 1.9,
      dataKey: "name",
      rowSpan: 1,
    },
   
    {
      colName: `Received`,
      flexGrow: 1.9,
      dataKey: "recievedval",
      rowSpan: 1,
    },
    {
      colName: `Verified`,
      flexGrow: 1.9,
      dataKey: "verifiedval",
      rowSpan: 1,
    },
    {
      colName: `Rejected`,
      flexGrow: 1.9,
      dataKey: "nonverifiedval",
      rowSpan: 1,
    },
    // {
    //   colName: `Pending`,
    //   flexGrow: 1.9,
    //   dataKey: "pendingval",
    //   rowSpan: 1,
    // },
    {
      colName: `Received Amount`,
      flexGrow: 1.9,
      dataKey: "receivedamount",
      rowSpan: 1,
    },

    {
      colName: `Worked Amount`,
      flexGrow: 1.9,
      dataKey: "collectedamount",
      rowSpan: 1,
    },
    {
      colName: `Pending Amount`,
      flexGrow: 1.9,
      dataKey: "pendingamountval",
      rowSpan: 1,
    },

    {
      colName: "View By Dos",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowTable, btnType: "View" },{ label: "D", onClick: onShowEobDos, btnType: "info" }],
     

      editRow: true,
    }


   
   
   
    
  ];


  const DosWiseTable = [

    { colName: "DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: `Received`,
      flexGrow: 1.9,
      dataKey: "recievedAmountCC",
      rowSpan: 1,
    },
    {
      colName: `Verified`,
      flexGrow: 1.9,
      dataKey: "recievedAmount",
      rowSpan: 1,
    },
    {
      colName: `Rejected`,
      flexGrow: 1.9,
      dataKey: "internalNonVerify",
      rowSpan: 1,
    },
   
    {
      colName: `Received Amount`,
      flexGrow: 1.9,
      dataKey: "AmountDosValCC",
      rowSpan: 1,
    },

    {
      colName: `Worked Amount`,
      flexGrow: 1.9,
      dataKey: "AmountDosVal",
      rowSpan: 1,
    },
    {
      colName: `Pending Amount`,
      flexGrow: 1.9,
      dataKey: "pendingAmount",
      rowSpan: 1,
    },
    {
      colName: "Audit",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D",
       onClick: onShowRemakrs,
        btnType: "View" }],

      editRow: true,
    },


   
   
   
    
  ];

  const [renderEffect, setRenderEffect] = useState(false);


  const renderr = (val) => {
    setRenderEffect(val);
  };

  const EobDosWiseTable = [
    { colName: "EOB`s DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    {
      colName: "EOB`s Denials Amount",
      flexGrow: 1.9,
      dataKey: "DenialAmount",
      rowSpan: 1,
    },
    {
      colName: "View Detail",
      flexGrow: 1.6,
      dataKey: "id",
      rowSpan: 1,
      actions: [{ label: "D", onClick: onShowEobDos, btnType: "info" }],

      editRow: true,
    },
    
   
    
  ];

  const EraDosWiseTable = [
    { colName: "ERA`s DOS", flexGrow: 1.9, dataKey: "date", rowSpan: 1 },
    
    {
        colName: "ERA`s Denials Amount",
        flexGrow: 1.9,
        dataKey: "DenialAmount",
        rowSpan: 1,
      },
      {
        colName: "View Detail",
        flexGrow: 1.6,
        dataKey: "id",
        rowSpan: 1,
        actions: [{ label: "D", onClick: onShowEraDos, btnType: "info" }],
  
        editRow: true,
      },
      
   
    
  ];

  const handleClose = () => {
    setState((prev) => ({ ...prev, isEobDosOpen: false }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: false }));
  };

  const handleCloseEra = () => {
    setState((prev) => ({ ...prev, isEraDosOpen: false }));
    setHelpingState((prev) => ({ ...prev, isTriggerEffectDos: false }));
  };

  const getRequriedPara=()=>{

  }



  console.log('state.recordEob',tableState)

  return (
    <>
    {state.isToggleRemarks == true ? (
            <>
              <ChargeTypeRemarksPop
                prevstate={state}
                formNamee={"Patient Statement"}
                practiceId={practiceId}
                date={date}
                flagIndi={flagIndi}
                CloseAuditRemark={CloseAuditRemark}
                rowDataAudit={helpingStatem.holdSubDosTableData}
              />
            </>
          ) : null}
    
      <div className="row">
        <div class="col-sm-6 col-lg-12 ">
          {helpingStatem.isSwitchTable==2? <>
            <UserAuditTable
             getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={state.recordEob?state.recordEob:[]}
              // isPopupOpen={isPopupOpen}
              Array={DosWiseTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />
             
           
           
          </>:helpingStatem.isSwitchTable==3?<>
            

<UserAuditTable
getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={state.recordEob?state.recordEob:[]}
              // isPopupOpen={isPopupOpen}
              Array={EobDosWiseTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />

<UserAuditTable
getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={state.recordEra?state.recordEra:[]}
              // isPopupOpen={isPopupOpen}
              Array={EraDosWiseTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />
          </>
            
           : 
           helpingStatem.isSwitchTable==1?<UserAuditTable
           getRequriedPara={getRequriedPara}
              rowHeight={48}
              // handleChange={handleChange}
              // handleEditState={handleEditState}
              dateForgrid={tableState}
              // isPopupOpen={isPopupOpen}
              Array={MainTable}
              rowShown={5}
              loader={helpingStatem.loading}
            />:null

            
          }
        </div>
      </div>

      <div className="row">
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        <div class="col-sm-6 col-lg-3 "></div>
        {/* <div class="col-sm-1 col-lg-3 mt-2">
  <div class="form-group ">
    <div className="d-flex justify-content-end mt-4">
      <button
        type="button"
        className="btnClear mr-1 "
        onClick={handleClose}
      >
        Back
      </button>
      
     
    </div>
  </div>
</div> */}
      </div>
      </> 
      
    
  );
}
