import React from 'react'

export default function NavTab({isVob,state,setState}) {
  const handleonClickActiveTab=(arg)=>{
    setState((prev)=>({
      ...prev,
      ActiveTab:arg

    }))

  }
  let activeClass="tw-bg-[#0e7490] tw-rounded-full tw-shadow tw-text-white"
  return (
    <>
    <div className='tw-grid tw-grid-cols-12 mt-4'>
      <div className='tw-col-span-2'></div>
      <div className='tw-col-span-8'>
      <ul className="ml-4 tw-grid tw-grid-flow-col tw-text-center tw-text-gray-500 tw-bg-gray-100 tw-rounded-full tw-p-1">
  <li>
    <a  className={`tw-flex tw-justify-center ${state.ActiveTab=="1" && activeClass}  tw-py-2`}onClick={()=>handleonClickActiveTab(1)}>Last Day</a>
  </li>
  <li>
    <a className={`tw-flex tw-justify-center ${state.ActiveTab=="3" && activeClass}  tw-py-2`} onClick={()=>handleonClickActiveTab(3)}>Last 3 Days</a>
  </li>
  <li>
    <a  className={`tw-flex tw-justify-center ${state.ActiveTab=="7" && activeClass}  tw-py-2`} onClick={()=>handleonClickActiveTab(7)}>Weekly</a>
  </li>
  <li>
    <a  className={`tw-flex tw-justify-center ${state.ActiveTab=="30" && activeClass}  tw-py-2`}onClick={()=>handleonClickActiveTab(30)}>Monthly</a>
  </li>
 
</ul>
      </div>
   
<div className='tw-col-span-2'></div>

    </div>
   

    </>
  )
}
