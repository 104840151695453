import React, { useState, useEffect } from "react";
import { Modal, ButtonToolbar, Loader, Placeholder, Button } from "rsuite";
import { getAuditDosRec, getPraqcticeWise, getThresholdFordocuhub } from "../../Utils/ApiManager";
import UserAuditTable from "./userTaskViewTable";
import PopUpAudit from "./ForPopUpAudit";
import AuditTypeRemarksPop from "./AuditTypeRemarksPop";
import Dateoofservice from "../../Common/Dateoofservice";
import SmallModel from "./smallModel";

function PracticeView({
  helpingStatePrev,
  handleClosePracticeWisePopup,
  practiceWiseID,
  pracPopup,
  startDatee,
  endDate,
  state,
  products,
}) {
  const [Rercord, setRecord] = useState([]);
  const [loading, setloading] = useState(false);
  const [rowDataAudit, setRowData] = useState();
  let obj = {
    containingDosArray: [],
    isDosViewScreenOpen: false,
    renderEffect: false,
    formTypeIndicator: null,
    contiansThres:[]
  };
  const [helpingState, setHelpingState] = useState(obj);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    let obj = {
      sdate: startDatee,
      endDate: endDate,
      userid: practiceWiseID.userid,
      practiceIDs: state.pidies,
      products: products,
    };
    if (obj.userid) {
      setloading(true);
      getPraqcticeWise(obj)
        .then(async (res) => {
        
          // let z=await makeObj(res.data.result)
          let resFull = res  && res?.data && res.data?.result.length>0 && res.data?.result.map((el) => {
            let sum = parseInt(el.eobdenials) + parseInt(el.eradenials);
            el.sumDenials = sum;
            return el;
          });

         
          const pidValues = resFull.map(item => item.pid);
          let reso = await getThresholdFordocuhub(pidValues.join(','));
          const mergedArray = resFull.map((resItem) => {
            const matchingThresItem = reso?.data.find(
              (thresItem) =>
                thresItem.pname === resItem.label.split("-")[0] &&
                thresItem.pcode === resItem.label.split("-")[1]
            );
          
            return {
              ...resItem,
              ...matchingThresItem
            };
          });
         

          

          let updatedArr = mergedArray.map((item) => {
          
            // Divide attributes by hours and create new attributes
            return {
              ...item,
              VobPortalHoursF: parseInt(item.vobPortal)/ helpingStatePrev?.daysDifference,
             CallsHoursF: parseInt(item.vobCalls) / helpingStatePrev?.daysDifference,
    
              RejectionsHoursF: parseInt(item.rejectionsworkedval) / helpingStatePrev?.daysDifference,
              AuthHoursF: parseInt(item.Authworkedval) / helpingStatePrev?.daysDifference ,
    
              ManualHoursF: parseInt(item.eobworkedval) / helpingStatePrev?.daysDifference ,
              electronicallyHoursF: parseInt(item.eraworkedval) / helpingStatePrev?.daysDifference ,
              denialshoursF:
                parseInt(item.sumDenials)/ helpingStatePrev?.daysDifference ,
              generalPayerhoursF: parseInt(item.generalPayerworkedval) / helpingStatePrev?.daysDifference,
              specialPayerhoursF: parseInt(item.spayerworkedval) / helpingStatePrev?.daysDifference,
            };
          });
          

          const resultForThreshold =await updatedArr.map((item) => {
            debugger
            const percentage = (value, criteria) => {
              let val = parseFloat(value);
              let thresh = parseFloat(criteria);
              const result = (val / thresh) * 100;
  
              if (thresh !== 0) {
                return result.toFixed(1);
              } else {
                return 0; // or any other appropriate value
              }
            };
  
            return {
              ...item,
              rejectionsTool: percentage(item.RejectionsHoursF, parseInt(item.thresh_rejections)),
              portalsTool: percentage(item.VobPortalHoursF, parseInt(item.thresh_portal)),
              callsTool: percentage(item.CallsHoursF, parseInt(item.thresh_eligibility)),
              denialsTool: percentage(
                item.denialshoursF,
                parseInt(item.thresh_denials)
              ),
              AuthTool: percentage(item.AuthHoursF,  parseInt(item.thresh_authorization)),
              manualTool: percentage(item.ManualHoursF, parseInt(item.thresh_posting)),
              electonicallyTool: percentage(item.electronicallyHoursF, parseInt(item.thresh_posting_electronically)),
              generalPayerTool: percentage(
                item.generalPayerhoursF,
                parseInt(item.claims_per_hours)
              ),
              specialPayerTool: percentage(
                item.specialPayerhoursF,
                parseInt(item.claims_review_per_hours)
              ),
              
  
              
            };
          });
          setRecord(resultForThreshold);
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    }
  }, []);
  const handleUserWiseRowData = (row, val) => {
    let obj = {
      userid: practiceWiseID.userid,
      pid: row.pid,
      sdate: row.date,
      formType: val,
    };
    getAuditDosRec(obj)
      .then((res) => {
        let parsedArray = [];

        try {
          parsedArray = JSON.parse(res.data[0].dosArrayRecieved);
        } catch {
          parsedArray = res.data[0].dosArrayRecieved;
        }
        setHelpingState((prev) => ({
          ...prev,
          containingDosArray: parsedArray,
          isDosViewScreenOpen: true,
          formTypeIndicator: val,
        }));
      })
      .catch((err) => {});
  };

  const makeObj = (DataForGrid) => {
    let totalRows = 0;
    let initialKeys = [];
    Object.keys(DataForGrid || {}).forEach((key) => {
      if (DataForGrid[key].length > totalRows)
        totalRows = DataForGrid[key].length;
    });
    let row = {};
    Object.keys(DataForGrid || {}).map((key) => {
      // At least one of them has the data
      if (
        Object.keys(DataForGrid).some((item) => DataForGrid[item].length > 0)
      ) {
        // Take keys
        if (!initialKeys.length > 0) {
          initialKeys = Object.keys(DataForGrid[key].find((a) => a));
        }
        // Make the row with all the possible values
        for (let i = 0; i < totalRows; i++) {
          if (!row[i]) {
            row[i] = {};
          }
          initialKeys.forEach((objKey) => {
            const data = DataForGrid[key][i];
            if (data) {
              row[i][`${key}${objKey}`] = data[objKey];
              if (objKey === "sdate") {
                row[i]["date"] = data[objKey]; // Add a common "date" property
              }
            } else {
              row[i][`${key}${objKey}`] = 0;
            }
          });
        }
      }
    });
    return Object.values(row);
  };

  const arr = [
    {
      colName: "Practice Name",
      flexGrow: 4.5,
      dataKey: "label",
      rowSpan: 6,
      popEachCell: true,
    },
    {
      colName: "Date of Entry",
      flexGrow: 2.2,
      dataKey: "date",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Charges",
      flexGrow: 1.4,
      dataKey: "chargesworkedval",
      popEachCell: true,
    },
    {
      colName: "Rejections",
      flexGrow: 1.5,
      dataKey: "rejectionsworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Auth",
      flexGrow: 0.7,
      dataKey: "Authworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Vobs",
      flexGrow: 0.7,
      dataKey: "vobworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eobs",
      flexGrow: 0.8,
      dataKey: "eobworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eras",
      flexGrow: 0.8,
      dataKey: "eraworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Account Receivable",
      flexGrow: 1.7,
      dataKey: "generalPayerworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    //  { colName: "Special Payers", flexGrow: 1.7,dataKey:'spayerworkedval',rowSpan:1},
    {
      colName: "Patient Statments",
      flexGrow: 1.7,
      dataKey: "patStatWorkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    // { colName: "Remarks", flexGrow: 1.7,dataKey:'',rowSpan:1,popupCell:true},
  ];
  const combineDetailReport=[
    {
      colName: "Practice Name",
      flexGrow: 4.5,
      dataKey: "label",
      rowSpan: 6,
      popEachCell: true,
    },
    {
      colName: "Date of Service",
      flexGrow: 2.2,
      dataKey: "date",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Charges",
      flexGrow: 1.4,
      dataKey: "chargesworkedval",
      popEachCell: true,
    },
    {
      colName: "Charges $",
      flexGrow: 1.4,
      dataKey: "chargeamountval",
      popEachCell: true,
    },
    {
      colName: "Rejections",
      flexGrow: 1.5,
      dataKey: "rejectionsworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Rejections $",
      flexGrow: 1.5,
      dataKey: "rejectionsamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Auth",
      flexGrow: 0.7,
      dataKey: "Authworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Auth $",
      flexGrow: 0.7,
      dataKey: "Authamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Vobs",
      flexGrow: 0.7,
      dataKey: "vobworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eobs",
      flexGrow: 0.8,
      dataKey: "eobworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eobs $",
      flexGrow: 0.8,
      dataKey: "eobamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eras",
      flexGrow: 0.8,
      dataKey: "eraworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eras $",
      flexGrow: 0.8,
      dataKey: "eraamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Account Receivable",
      flexGrow: 1.7,
      dataKey: "generalPayerworkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Account Receivable $",
      flexGrow: 1.7,
      dataKey: "generalamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Patient Statments",
      flexGrow: 1.7,
      dataKey: "patStatWorkedval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Patient Statments $",
      flexGrow: 1.7,
      dataKey: "patStatamountval",
      rowSpan: 1,
      popEachCell: true,
    },

  ]
  const thresholdPracticeWise = [
    {
      colName: "Practice Name",
      flexGrow: 4.5,
      dataKey: "label",
      rowSpan: 6,
      popEachCell: true,
    },
    {
      colName: "Date of Service",
      flexGrow: 2.2,
      dataKey: "date",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Portals",
      flexGrow: 2.2,
      dataKey: "vobPortal",
      rowSpan: 1,
      popEachCell: true,
        isShowToolTip:true ,
     toolTipKey:"portalsTool"
    },
    {
      colName: "Calls",
      flexGrow: 1.4,
      dataKey: "vobCalls",
      popEachCell: true,
         isShowToolTip:true,
      toolTipKey:'callsTool'
    },
    {
      colName: "Rejections",
      flexGrow: 1.5,
      dataKey: "rejectionsworkedval",
      rowSpan: 1,
      popEachCell: true,
         isShowToolTip:true,
      toolTipKey:'rejectionsTool'
    },
    {
      colName: "No of Pre Authorization",
      flexGrow: 0.7,
      dataKey: "Authworkedval",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'AuthTool'
    },
    {
      colName: "Manual",
      flexGrow: 0.7,
      dataKey: "eobworkedval",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'manualTool'
    },
    {
      colName: "Electronically",
      flexGrow: 0.8,
      dataKey: "eraworkedval",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'electonicallyTool'
    },

    
    {
      colName: "No of Denials",
      flexGrow: 0.8,
      dataKey: "sumDenials",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'denialsTool'
    },
    {
      colName: "General Payers",
      flexGrow: 0.8,
      dataKey: "generalPayerworkedval",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'generalPayerTool'
    },
    {
      colName: "Special Payers",
      flexGrow: 0.8,
      dataKey: "spayerworkedval",
      rowSpan: 1,
      popEachCell: true,
      isShowToolTip:true,
      toolTipKey:'specialPayerTool'
    },
    {
      colName: "Action",
      flexGrow: 1.5,
      dataKey: "",
      popupCell: true,
      isAllow: true,
      isShowToolTip:true
    },
    
    
    // { colName: "Remarks", flexGrow: 1.7,dataKey:'',rowSpan:1,popupCell:true},
  ];

  

  const amountarrDateWise = [
    {
      colName: "Practice Name",
      flexGrow: 4.5,
      dataKey: "label",
      rowSpan: 6,
      popEachCell: true,
    },
    {
      colName: "Date of Entry $",
      flexGrow: 2.2,
      dataKey: "date",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Charges $",
      flexGrow: 1.4,
      dataKey: "chargeamountval",
      popEachCell: true,
    },
    {
      colName: "Rejections $",
      flexGrow: 1.5,
      dataKey: "rejectionsamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Auth $",
      flexGrow: 0.7,
      dataKey: "Authamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    // {
    //   colName: "Vobs",
    //   flexGrow: 0.7,
    //   dataKey: "vobworkedval",
    //   rowSpan: 1,
    //   popEachCell: true,
    // },
    {
      colName: "Eobs $",
      flexGrow: 0.8,
      dataKey: "eobamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Eras $",
      flexGrow: 0.8,
      dataKey: "eraamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    {
      colName: "Account Receivable $",
      flexGrow: 1.7,
      dataKey: "generalamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    //  { colName: "Special Payers", flexGrow: 1.7,dataKey:'spayerworkedval',rowSpan:1},
    {
      colName: "Patient Statments $",
      flexGrow: 1.7,
      dataKey: "patStatamountval",
      rowSpan: 1,
      popEachCell: true,
    },
    // { colName: "Remarks", flexGrow: 1.7,dataKey:'',rowSpan:1,popupCell:true},
  ];
  const onChangePop = async(rowData) => {
    let reso = await getThresholdFordocuhub(rowData?.pid);
         setHelpingState((prev)=>({...prev,contiansThres:reso?.data}))
    
    
    setRowData(rowData);
    setShowPopup(true);
  };
  const CloseAuditRemark = (val) => {
    setShowPopup(val);
  };

  const renderr = (val) => {
    setHelpingState((prev) => ({ ...prev, renderEffect: val }));
    // setRenderEffect(val)
  };
  const handleClose = () => {
    setHelpingState((prev) => ({ ...prev, isDosViewScreenOpen: false }));
  };
  console.log("practiceWiseID", helpingState.containingDosArray);
  let chargeAuthRejectionCheck =
    helpingState?.formTypeIndicator == "chargesworkedval" ||
    helpingState?.formTypeIndicator == "rejectionsworkedval" ||
    helpingState?.formTypeIndicator == "Authworkedval"||
    helpingState?.formTypeIndicator == "chargeamountval" ||
    helpingState?.formTypeIndicator == "rejectionsamountval" ||
    helpingState?.formTypeIndicator == "Authamountval"


    let eoberaCheck=helpingState?.formTypeIndicator == "eraworkedval" ||
    helpingState?.formTypeIndicator == "eobworkedval"||
    helpingState?.formTypeIndicator == "eobamountval"||
    helpingState?.formTypeIndicator == "eraamountval"


    console.log('RercordRercord',Rercord)
   


  return (
    <>
      <div className="pt-9">
        {" "}
        {showPopup ? (
          // <AuditTypeRemarksPop
          //   practiceIDs={state.pidies}
          //   rowDataAudit={rowDataAudit}
          //   practiceWiseID={practiceWiseID}
          //   setShowPopup={setShowPopup}
          //   CloseAuditRemark={CloseAuditRemark}
          // />
          <SmallModel data={helpingState?.contiansThres} handleClose={CloseAuditRemark} />
        ) : null}
      </div>
      <Modal
        open={true}
        onClose={() => handleClosePracticeWisePopup(false)}
        // onEntered={handleEntered}
        // onExited={() => {
        //   setRows(0);
        // }}
        size={"full"}
      >
        <Modal.Header>
          <Modal.Title>{practiceWiseID.label} (Date Wise Records)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {true ? (
            <>
              {helpingState.isDosViewScreenOpen &&
              (helpingState?.formTypeIndicator == "patStatWorkedval" ||
                helpingState?.formTypeIndicator == "patStatamountval") ? (
                <>
                  <Dateoofservice
                    isBlockAllhtml={true}
                    renderr={renderr}
                    renderEffect={helpingState.renderEffect}
                    dosArray={helpingState.containingDosArray}
                    handleClose={handleClose}
                    claimHeading={"Received"}
                    claimAmount={"Received Amount"}
                    claimHeading1={"Verified"}
                    claimHeading2={"Received Amount"}
                    claimHeading3={"Collected Amount"}
                    claimHeading4={"Pending  Amount"}
                    claimAmount1={"Rejected"}
                    breakupName={"Pending Claim Breakup"}
                    //  setdosDate={setdosDate}
                    onlyShowTable={true}
                    SecondTableName={"patStat"}
                    showTableCheck={"patStat"}
                    // flag={"patStat"}
                    // changeState={'patState'}
                    
                  />
                </>
              ) : helpingState.isDosViewScreenOpen &&
                chargeAuthRejectionCheck ? (
                <>
                  <Dateoofservice
                    isBlockAllhtml={true}
                    renderr={renderr}
                    renderEffect={helpingState.renderEffect}
                    dosArray={helpingState.containingDosArray}
                    handleClose={handleClose}
                    claimHeading={"Worked Claims"}
                    claimAmount={"Worked Amount"}
                    columnHeader1={"Received Claims"}
                    columnHeader2={"Received Amount"}
                    columnHeader3={"Pending Claims"}
                    columnHeader4={"Pending Amount"}
                    breakupName={"Pending Claim Breakup"}
                    // setdosDate={setdosDate}
                    onlyShowTable={true}
                    SecondTableName={"charge"}
                  />
                </>
              ) : helpingState.isDosViewScreenOpen &&
                helpingState?.formTypeIndicator == "vobsModel" ? (
                <>
                  <Dateoofservice
                    isBlockAllhtml={true}
                    renderr={renderr}
                    renderEffect={helpingState.renderEffect}
                    dosArray={helpingState.containingDosArray}
                    handleClose={handleClose}
                    claimHeading={"Claims Received"}
                    claimAmount={"Portal"}
                    columnHeader1={"Calls"}
                    columnHeader2={"Worked"}
                    columnHeader3={"Pending "}
                    // setdosDate={setdosDate}
                    onlyShowTable={true}
                    SecondTableName={"vobb"}
                    flag={"vobb"}
                  />
                </>
              ) : helpingState.isDosViewScreenOpen &&
                (eoberaCheck) ? (
                <>
                  <Dateoofservice
                    DenialClaimBreakup={true}
                    isBlockAllhtml={true}
                    renderr={renderr}
                    renderEffect={helpingState.renderEffect}
                    dosArray={helpingState.containingDosArray}
                    handleClose={handleClose}
                    claimHeading={"Claims Received"}
                    claimAmount={"Paid"}
                    columnHeader1={"Denied"}
                    columnHeader2={"Total Worked Claims"}
                    columnHeader3={"Pending Claims"}
                    // setdosDate={setdosDate}
                    onlyShowTable={true}
                    SecondTableName={"Eob"}
                    flag={"Eob"}
                  />
                </>
              ) : helpingState.isDosViewScreenOpen &&
                (helpingState?.formTypeIndicator == "generalPayerworkedval" || helpingState?.formTypeIndicator == "generalamountval") ? (
                <>
                  <Dateoofservice
                    isAllowThirdTable={{
                      allow: true,
                      flagThirdTable: "generalPayer",
                    }}
                    isBlockAllhtml={true}
                    renderr={renderr}
                    renderEffect={helpingState.renderEffect}
                    dosArray={helpingState.containingDosArray}
                    handleClose={handleClose}
                    claimHeading={"Worked Claims"}
                    claimAmount={"Worked Amount"}
                    columnHeader1={"Received Claims"}
                    columnHeader2={"Received Amount"}
                    columnHeader3={"Pending Claims"}
                    columnHeader4={"Pending Amount"}
                    breakupName={"Pending Claim Breakup"}
                    onlyShowTable={true}
                    SecondTableName={"payer"}
                    flag={"gp"}
                  />
                </>
              ) : (
                <PopUpAudit
                 flag="DateWiseRec"
                  handleUserWiseRowData={handleUserWiseRowData}
                  isShowExportButton={true}
                  practiceIDs={state.pidies}
                  showPopup={showPopup}
                  loading={loading}
                  onChangePop={onChangePop}
                  setRecord={setRecord}
                  dateForgrid={Rercord}
                  Array={helpingStatePrev?.practiceWisePopType=='thres'?thresholdPracticeWise:
                  (helpingStatePrev?.headerName=="both"?combineDetailReport:
                    helpingStatePrev.headerName == "Worked"
                      ? arr
                      : amountarrDateWise)
                  }
                />
              )}
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Loader size="md" style={{ color: "#0e7490" }} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
            onClick={() => handleClosePracticeWisePopup(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PracticeView;
