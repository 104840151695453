import React from "react";

import DenialVerification from "./DenialVerification";

const Denials = ({handleFormTypeAndPract,products,handleSubCheckBoxChargeEntry,onRecordUpdateOrCreated, subCheckBox,formTypeNoForApi,date,formType,practiceId,record }) => {
  console.log('subCheckBox',subCheckBox)
  return (
  
   <>
   { subCheckBox == "DeniedVerification" && (
     <DenialVerification handleFormTypeAndPract={handleFormTypeAndPract} handleSubCheckBoxChargeEntry={handleSubCheckBoxChargeEntry} products={products} onRecordUpdateOrCreated={onRecordUpdateOrCreated}record={record} formTypeNoForApi={formTypeNoForApi} date={date} formType={formType} practiceId={practiceId} />
    ) }
   </>
    
     
  );
};

export default Denials;
