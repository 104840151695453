import React, { useEffect, useState, useRef } from "react";

import moment from "moment";
import { useSelector } from "react-redux";

import CharacterAuthorizeIcon from "@rsuite/icons/CharacterAuthorize";


import DashboardCard from "./DashboardCards";


import {
  claimsRecord,
  fetchProduct,
  getActiveUsers,
  getTeamsInfo,
} from "../../Utils/ApiManager";
import NavTab from "./navTab/navTab";

import ChargeEntryDashboard from "./Components/ChargeEntry";
import RejectionDashboard from "./Components/RejectionDashboard";
import PatStatementDashboard from "./Components/PatientStatement";
import EobDashboard from "./Components/EobDashboard";
import EraDashboard from "./Components/EraDashboard";
import Auth from "./Components/Auth";
import AccRecDashboard from "./Components/AccRec";
import VobsDashboard from "./Components/VobsDashboard";
import userRolesObject from "../../Common/AllRolesInfo";
import { CheckPicker, Checkbox } from "rsuite";

function Dashboard({}) {
  const { onLoginSignOff } = useSelector((state) => state);
  const mountRef = useRef();
  console.log("onLoginSignOff", onLoginSignOff);

  let obj = {
    activePractices:0,
    practicesTotal: 0,
    teamsTotal: 0,
    totalActiveUsers: 0,
    ActiveTab: "1",
    ChargeEntryGrpahData: [],
    ChargeLabels: [],
    rejectionData: [],

    EobData: [],
    EraData: [],
    PatData: [],
    AuthData: [],
    AccData: [],
    AllRecord: {},
    VobData: [],
    widgetSelection: [
      "Charge Entry",
      "Rejection",
      "Patient And Statement",
      "EOB`s",
      "ERA`s",
      "Authorization",
      "Account Receivable",
      "Eligibilty/VOB`s"
  ],
    checkAllSelection:false
  };
  const [state, setState] = useState(obj);
  useEffect(() => {
    getActiveUsersNumber();

    getTeamsRecord();
    setState((prev) => ({
      ...prev,
      practicesTotal: onLoginSignOff?.idies?.split(",")?.length,
    }));
  }, [onLoginSignOff]);
  useEffect(() => {
    getActivePractices()

    getClaimsRecord();
  }, [state.ActiveTab]);
  const getActivePractices=async()=>{
   
    const formData = new FormData();
    formData.append("idies", onLoginSignOff.idies);

    let res = await fetchProduct(formData);
    setState((prev)=>({...prev,activePractices:res.data.length}))
  }
  
  const getClaimsRecord = async () => {
    let sdate = "";
    let edate = "";
    const currentDate = moment();
    const oneDayBehind = currentDate.subtract(1, "days");
    const formattedDate = oneDayBehind.format("YYYY-MM-DD");
    if (state.ActiveTab == "1") {
      sdate = formattedDate;
      edate = formattedDate;
    } else if (state.ActiveTab == "3") {
      let threeDaysDate = oneDayBehind.subtract(2, "days").format("YYYY-MM-DD");

      sdate = threeDaysDate;
      edate = formattedDate;
    } else if (state.ActiveTab == "7") {
      let SevenDaysDate = oneDayBehind.subtract(6, "days").format("YYYY-MM-DD");

      sdate = SevenDaysDate;
      edate = formattedDate;
    } else if (state.ActiveTab) {
      let MonthDate = oneDayBehind.subtract(29, "days").format("YYYY-MM-DD");

      sdate = MonthDate;
      edate = formattedDate;
    }
    let obj = {
      userids: onLoginSignOff?.users?.map((item) => item.userid).join(","),
      startDate: sdate,
      endDate: edate,
    };
    claimsRecord(obj)
      .then(async (res) => {
        if (res?.data) {
          ArraytoObject(res?.data);
        }
      })
      .catch((err) => {});
  };
  const handleWdgets = (arg) => {
    
   if(arg?.length==0){
    setState((prev)=>({...prev,checkAllSelection:false}))
    
   }
    setState((prev) => ({
      ...prev,
      widgetSelection: arg,
    }));
  };
  const ArraytoObject = (data) => {
    const result = {};

    data &&
      data.forEach((item) => {
        const label = item.label.toLowerCase();
        result[label] = result[label] || {};
        if (item.workedAmount !== undefined) {
          result[label].workedAmount = item.workedAmount;
        }
        if (item.workedClaim !== undefined) {
          result[label].workedClaim = item.workedClaim;
        }
      });
    setState((prev) => ({ ...prev, AllRecord: result }));
  };

  const getTeamsRecord = async () => {
    let formData = new FormData();
    formData.append("get_user", onLoginSignOff.userid);

    let resAllTeam = await getTeamsInfo(formData);

    setState((prev) => ({
      ...prev,
      teamsTotal: resAllTeam?.data?.length,
    }));
  };
  const getActiveUsersNumber = () => {
    const currentDate = moment();
    const oneDayBehind = currentDate.subtract(1, "days");
    const formattedDate = oneDayBehind.format("YYYY-MM-DD");
    let useid = onLoginSignOff?.users?.map((item) => item.userid).join(",");
    let obj = {
      sdate: formattedDate,
      userids: useid,
    };
    getActiveUsers(obj)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          totalActiveUsers: res.data?.totalUsers,
        }));
      })
      .catch((err) => {});
  };

  let arr = [
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Total Assigned Teams ",
      Data: state?.teamsTotal,
      backgroundcolor: "tw-bg-[#298C8C]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Total Active Practices ",
      Data: state?.activePractices,
      backgroundcolor: "tw-bg-[#0e7490]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Total Active Users ",
      Data: `${state?.totalActiveUsers}/${onLoginSignOff.users?.length}`,
      backgroundcolor: "tw-bg-[#298C8C]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Active Team Leads ",
      Data: "N/A",
      backgroundcolor: "tw-bg-[#0e7490]",
    },
  ];
  let teamLead=[
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Employee id",
      Data: onLoginSignOff?.employee_id,
      backgroundcolor: "tw-bg-[#298C8C]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Active Practices ",
      Data: state?.activePractices,
      backgroundcolor: "tw-bg-[#0e7490]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Active Users ",
      Data: state?.totalActiveUsers,
      backgroundcolor: "tw-bg-[#298C8C]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Team Name",
      Data: onLoginSignOff?.team,
      backgroundcolor: "tw-bg-[#0e7490]",
    },
  ];
  let User=[
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Employee id",
      Data: onLoginSignOff?.employee_id,
      backgroundcolor: "tw-bg-[#298C8C]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Active Practices ",
      Data: state?.activePractices,
      backgroundcolor: "tw-bg-[#0e7490]",
    },
    
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Team Name",
      Data: onLoginSignOff?.team,
      backgroundcolor: "tw-bg-[#0e7490]",
    },
    {
      icon: (
        <CharacterAuthorizeIcon
          style={{ height: "50px", width: "40px", color: "white" }}
        />
      ),
      text: "Last Updated",
      Data: "N/A",
      backgroundcolor: "tw-bg-[#298C8C]",
    },
  ];
  const footerStyles = {
    border: "1px solid #e5e5e5",
    position: "absolute",
    marginTop: "45px",
    borderBottom: "1px solid rgb(229, 229, 229)", // Matching the border color
    // padding: "9px 67px", // Adjusted padding values
    // paddingLeft: "9px",
    paddingRight: "30%",
    width: "100%",
    backgroundColor: "aliceblue",
    borderRadius: "5px",
    marginLeft: "10px",
  };
  const checkAllWidgets = (e, checked) => {
   
    if (checked) {
      let arr =[
        "Charge Entry",
        "Rejection",
        "Patient And Statement",
        "EOB`s",
        "ERA`s",
        "Authorization",
        "Account Receivable",
        "Eligibilty/VOB`s"
    ];
      setState((prev) => ({ ...prev, widgetSelection: arr,checkAllSelection:checked }));
    } else {
      setState((prev) => ({ ...prev, widgetSelection: [],checkAllSelection:checked }));
    }
  };

  // console.log("onLoginSignOff", onLoginSignOff.idies.split(",").length);
  let condition =
    onLoginSignOff?.type != userRolesObject?.biller &&
    onLoginSignOff?.type != userRolesObject?.teamlead &&
   
    onLoginSignOff?.type != userRolesObject?.backupTeam;
    let conditonTl=onLoginSignOff?.type == userRolesObject?.teamlead ||onLoginSignOff?.type ==userRolesObject?.backupTeam;
    let conditionUser=onLoginSignOff?.type == userRolesObject?.biller

  return (
    <>
      {true ? (
        <>
          <div className="tw-grid tw-grid-cols-4 tw-bg-gray-100 ">
            <div className="tw-col-span-4 tw-mt-6">
              {/* <FilterNavbar
            handleLayoutDash={handleLayoutDash}
            onLoginSignOff={onLoginSignOff}
            state={state}
            setState={setState}
          /> */}
            </div>
            {condition && arr &&
              arr.map((el) => {
                return (
                  <>
                    <div className="tw-col-span-1">
                      <div class="tw-w-full tw-px-2 tw-py-2 tw-mx-auto">
                        <div class="tw-flex tw-flex-wrap tw-mx-3">
                          <DashboardCard
                            icons={el.icon}
                            data={el.Data}
                            text={el.text}
                            backgroundcolor={el.backgroundcolor}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              {conditonTl && teamLead &&
              teamLead.map((el) => {
                return (
                  <>
                    <div className="tw-col-span-1">
                      <div class="tw-w-full tw-px-2 tw-py-2 tw-mx-auto">
                        <div class="tw-flex tw-flex-wrap tw-mx-3">
                          <DashboardCard
                            icons={el.icon}
                            data={el.Data}
                            text={el.text}
                            backgroundcolor={el.backgroundcolor}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {conditionUser && User &&
              User.map((el) => {
                return (
                  <>
                    <div className="tw-col-span-1">
                      <div class="tw-w-full tw-px-2 tw-py-2 tw-mx-auto">
                        <div class="tw-flex tw-flex-wrap tw-mx-3">
                          <DashboardCard
                            icons={el.icon}
                            data={el.Data}
                            text={el.text}
                            backgroundcolor={el.backgroundcolor}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            

            {/* <div className="tw-col-span-4">
          <div class="tw-w-full tw-px-2 tw-py-2 tw-mx-auto">
            <div class="tw-flex tw-flex-wrap tw-mx-3">
              {arr &&
                arr.map((el) => {
                  return (
                    <DashboardCard
                      icons={el.icon}
                      data={el.Data}
                      text={el.text}
                    />
                  );
                })}
            </div>
          </div>
        </div> */}
        

            <div className="flex tw-col-span-2 tw-justify-items-center "  >
              
            <div className="col-sm-8 mt-8" ref={mountRef}>
           
           
              <div  className="tw-mt-8 tw-ml-4"  >
             
                
                <CheckPicker
                  container={() => mountRef.current}
                  virtualized
                  label="Select Graph "
                  data={[
                    { label: "Charge Entry", value: "Charge Entry" },
                    { label: "Rejection", value: "Rejection" },
                    {
                      label: "Patient And Statement",
                      value: "Patient And Statement",
                    },

                    { label: "EOB`s", value: "EOB`s" },
                    { label: "ERA`s", value: "ERA`s" },
                    { label: "Authorization", value: "Authorization" },
                    {
                      label: "Account Receivable",
                      value: "Account Receivable",
                    },
                    { label: "Eligibilty/VOB`s", value: "Eligibilty/VOB`s" },
                  ]}
                  style={{ width: 1000 }}
                  onChange={handleWdgets}
                  block={false}
                  value={state?.widgetSelection ? state?.widgetSelection : []}
                  countable
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        container={() => mountRef.current}
                        indeterminate={
                          state?.widgetSelection &&
                          state?.widgetSelection.length > 0 &&
                          state?.widgetSelection.length <
                            state?.widgetSelection.length
                        }
                        checked={state?.checkAllSelection}
                        onChange={checkAllWidgets}
                      >
                        Select All
                      </Checkbox>
                    </div>
                  )}
                />
                
               
               
               
                
               
              </div>
              
             
              </div>
              
            </div>
            <div className="tw-col-span-2 tw-justify-items-center ">
              <NavTab setState={setState} state={state} />
            </div>
            {state?.widgetSelection.includes('Charge Entry') && <>
            <div className="tw-col-span-2 ">
              <ChargeEntryDashboard state={state} setState={setState} />
            </div>
            </>}

            {state?.widgetSelection.includes('Rejection') && <>
            <div className="tw-col-span-2">
              <RejectionDashboard state={state} setState={setState} />
            </div>
            </>}

            {state?.widgetSelection.includes('Patient And Statement') &&  <div className="tw-col-span-2">
              <PatStatementDashboard state={state} setState={setState} />
            </div> }
            {state?.widgetSelection.includes('EOB`s') && <div className="tw-col-span-2">
              <EobDashboard state={state} setState={setState} />
            </div> }
            {state?.widgetSelection.includes('ERA`s') && <div className="tw-col-span-2">
              <EraDashboard state={state} setState={setState} />
            </div>}

            {state?.widgetSelection.includes('Authorization') &&  <div className="tw-col-span-2">
              <Auth state={state} setState={setState} />
            </div> }
            {state?.widgetSelection.includes('Account Receivable') && <div className="tw-col-span-2">
              <AccRecDashboard state={state} setState={setState} />
            </div>}
            {state?.widgetSelection.includes('Eligibilty/VOB`s') && <div className="tw-col-span-2">
              <VobsDashboard state={state} setState={setState} />
            </div> }

           
           

           
            
            

           
            
            
          </div>

          <div className="mb-4"></div>
        </>
      ) : (
        "Dashboard"
      )}
    </>
  );
}

export default Dashboard;
