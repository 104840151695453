import React from "react";
import { Table, Tag, Pagination, Button } from "rsuite";

export default function FooterComp({ Footer, Array, data, flagFooter }) {
  const { Column, HeaderCell, Cell } = Table;

  console.log("flagFooter", flagFooter == "Eob");
  console.log('arrayarray',Array)



  const footerMapping = {
    charge: {
      'Received Claims': 'receivedClaims',
      'Received Amount ($)': 'receivedAmount',
      'Worked Claims': 'workedClaims',
      'Worked Amount ($)': 'workedAmount',
      'Pending Claims': 'pendingClaims',
      'Pending Amount':'pendingAmount'
    },
    vob: {
      Received: 'receivedClaims',
      'Through Portal': 'ThroughPortal',
      'Through Calls': 'ThroughCalls',
      Pending: 'Pending',
      Total: 'Total',
    },
    Eob: {
      'Recieved Claims': 'RecievedClaims',
      'Paid Claims': 'PaidClaims',
      'Denied Claims': 'DeniedClaims',
      'Partially Paid Claims':'partially',
      'Total Worked Claims': 'TotalWorkedClaims',
      'Pending Claims': 'pendingClaims',
      'Received Claims Amount':'receivedAmount',
      'Collected Claims Amount':'CollectedAmount',
      'Pending Claims Amount':'PendingAmount'

      

     
    },
    PatStat: {
      'Received': 'recievedPatStat',
      'Verified': 'Verified',
      'Adjusted': 'Adjusted',
      'Rejected': 'Rejected',
      'Received Amount ($)': 'ReceivedAmount',
      'Worked Amount ($)':'WorkedAmount',
      'Pending':'PendingClaims',
      'Pending Amount ($)':'pendingAmount'
    },
    generalPayer:{
      'Received Claims':'receivedClaims',
  
      'Worked Claims':'workedClaims',
    
      'Pending Claims':'pendingClaims',
      'Received Claims Amount ($)':'receivedAmount',
      'Collected Claims Amount ($)':'CollectedAmount',
      'Pending Claims Amount ($)':'PendingAmount'


      
    },
    Report:{
      'Charges':'total_charges',
      'Rejections':'total_Rejections',
      'Auth':'total_Auth',
      'VOB`s':'total_Vobs',
      'EOB`s':'total_Eobs',
      'ERA`s':'total_Era',
      'Account Receivable':'total_Arr',
      'Patient Statement':'totalPatStat'
    },
    ReportCombine:{
      'Charges':'total_charges',
      'Charges $':'total_charges_amount',
      'Rejections':'total_Rejections',
      'Rejections $':'total_Rejections_amount',
      'Auth':'total_Auth',
      'Auth $':'total_Auth_amount',
      'VOB`s':'total_Vobs',
      'EOB`s':'total_Eobs',
      'EOB`s $':'total_Eobs_amount',
      'ERA`s':'total_Era',
      'ERA`s $':'total_Era_amount',
      'Account Receivable':'total_Arr',
      'Account Receivable $':'total_Arr_amount',
      'Patient Statement':'totalPatStat',
      'Patient Statement $':'totalPatStat_amount'
    },
    ReportAmount:{
      'Charges $':'total_charges_amount',
'Rejections $':'total_Rejections_amount',
'Auth $':'total_Auth_amount',
 'EOB`s $':'total_Eobs_amount',
'ERA`s $':'total_Era_amount',
 'Account Receivable $':'total_Arr_amount',
 'Patient Statement $':'totalPatStat_amount'
    },
    coding:{
      'Received Claim':'receivedClaims',
      'Worked Claims':'workedClaims',
      'Pending Claims':'pendingClaims',
      'Total Breakup':'total'

    }
  };
  const selectedFooter = footerMapping[flagFooter];

  return (
    <>
      {Footer?.isAllow == true ? (
        <div style={{ height: "100px" }}>
          <Table
            affixHeader={true}
            virtualized
            shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
            height={100}
            data={["1"]}
            onRowClick={(data) => {
              console.log(data);
            }}
            hover={false}
            bordered={false}
            //autoHeight={true}
            cellBordered={false}
            headerHeight={37}
            rowHeight={30}
            loading={false}
            wordWrap={false}
          >
            {Array &&
              Array.map((item, index) => (
                <Column
                  key={index}
                  flexGrow={item.flexGrow ? item.flexGrow : 2}
                  align="center"
                  fixed
                >
                  <HeaderCell
                    style={{
                      backgroundColor: "#C3DCE3",
                      color: "grey",
                      fontSize: "0.805rem",
                      position: "sticky",
                      fontSize:"15px",
                      fontWeight:'bold'

                    }}
                  >
                   
                    
                      {
  selectedFooter &&
  item.colName !== 'DOS' &&
  item.colName !== 'Action' &&
  Footer &&
  Footer[selectedFooter[item.colName]]
}
                  </HeaderCell>

                  <span>
                    <Cell
                      style={
                        true
                          ? {
                              backgroundColor: "#f0f9ff",
                              height: "0px !important",
                            }
                          : { backgroundColor: "#F0F9FF" }
                      }
                    ></Cell>
                  </span>
                </Column>
              ))}
          </Table>
        </div>
      ) : null}
    </>
  );
}
