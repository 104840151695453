import React, { useState, useEffect } from "react";
import { Table, Tag } from "rsuite";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import CheckIcon from "@rsuite/icons/Check";
import "rsuite/dist/rsuite.css";
import "rsuite-table/dist/css/rsuite-table.css";
import EditIcon from "@rsuite/icons/Edit";
import { IconButton } from "rsuite";
import { getTlRecordsApi, onEditTableCallApi, onVerifyAuditTable } from "../../Utils/ApiManager";
import EditTable from "./EditableTable";

import { useSelector } from "react-redux";
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';

const { Column, HeaderCell, Cell } = Table;

let rowKey = "CatValue";

const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <EditIcon
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      style={{ color: "0e7490" }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

const VerifyCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChangeVerify,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    {/* <CheckIcon
      onClick={() => onChangeVerify(rowData)}
      style={{ color: "#0e7490" }}
    /> */}

    <CreditCardPlusIcon 
     onClick={() => onChangeVerify(rowData)}
     style={{ color: "#0e7490" }}
    />
  </Cell>
);
const EditCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChangeEdit,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <EditIcon style={{ color: "#0e7490" }} />
  </Cell>
);

const AuditTable = ({ dateForgrid, state, loader }) => {
  const [formTypeForPay, setFormTypeSub] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
  const [DataForGrid, setDataForGrid] = useState([]);
  const [loadingSub, setLoadingSub] = useState(false);
  const { onLoginSignOff } = useSelector((state) => state);

  let payloadForSubTable = {
    pidies: "",
    uidies: "",
    tuidies: "",
    formtype: "ucharges",
    sdate: null,
    edate: null,
    fvalue: null,
  };

  const [stateNu, setState] = useState(payloadForSubTable);
  const [subTableValues, setSubTableValues] = useState([]);
  const [isShowModal, setIsShowModal] = useState(null);

  const renderRowExpanded = (rowData) => {
    return (
      <>
        {/* <div>
        <div
          style={{
            width: 60,
            height: 60,
            float: "left",
            marginRight: 10,
            background: "#eee",
          }}
        >
          <img src={rowData.avatar} style={{ width: 60 }} />
        </div>
        <p>Email: {rowData.email}</p>
        <p>Phone: {rowData.phone}</p>
        
      </div> */}
        <div>
          {subTableValues ? (
            <EditTable
              subTableValues={subTableValues}
              loadingSub={loadingSub}
              formTypeForPay={formTypeForPay}
            />
          ) : null}
        </div>
      </>
    );
  };
  useEffect(() => {
    if (state) {
      setState((prevState) => ({
        ...prevState,
        pidies: state.pidies,
        uidies: state.uidies,
        tuidies: state.tuidies,
        sdate: state.sdate,
        edate: state.edate,
      }));
    }
  }, [state]);

  useEffect(() => {
    if (dateForgrid) {

     
      const data = Object.keys(dateForgrid).map((key) => {
        return {
          CatValue: key,
          ...dateForgrid[key],
        };
      });
      setDataForGrid(data);
    }
  }, [dateForgrid]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      // nextExpandedRowKeys.push(rowData[rowKey]);
    }
    if (!open) {
      //Call Api
      callApiForSubTable(rowData);
      setIsShowModal(true);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };
  const callApiForSubTable = async (rowData) => {
    if (rowData.CatValue == "chargeentry") {
      setFormTypeSub(1);
      setState((prevState) => ({
        ...prevState,
        fvalue: 1,
      }));
    }
    if (rowData.CatValue == "rejection") {
      setFormTypeSub(2);
      setState((prevState) => ({
        ...prevState,
        fvalue: 2,
      }));
    }
    if (rowData.CatValue == "authorization") {
      setFormTypeSub(3);
      setState((prevState) => ({
        ...prevState,
        fvalue: 3,
      }));
    }
    if (rowData.CatValue == "vobs") {
      setFormTypeSub(4);
      setState((prevState) => ({
        ...prevState,
        fvalue: 4,
      }));
    }
    if (rowData.CatValue == "eobs") {
      setFormTypeSub(5);
      setState((prevState) => ({
        ...prevState,
        fvalue: 5,
      }));
    }
    if (rowData.CatValue == "aras") {
      setFormTypeSub(6);
      setState((prevState) => ({
        ...prevState,
        fvalue: 6,
      }));
    }
    if (rowData.CatValue == "ars") {
      setFormTypeSub(7);
      setState((prevState) => ({
        ...prevState,
        fvalue: 7,
      }));
    }
  };
  useEffect(() => {
    if (stateNu.fvalue) {
      getCallApi();
    }
  }, [stateNu.fvalue]);
  const getCallApi = async () => {
    setLoadingSub(true);
    let res = await onEditTableCallApi(stateNu);
    //let res = await getTlRecordsApi(stateNu);
    setLoadingSub(false);
    setSubTableValues(res.data.results);
  };
  const handleClose = () => {
    setIsShowModal(false);
    // setState(payloadForSubTable)
    setState((prev) => ({
      ...prev,
      fvalue: 0,
    }));
  };
  const onChangeVerify = async (rowData) => {
   
    let fvalue = null;

    switch (rowData.CatValue) {
      case "chargeentry":
        fvalue = 1;
        break;
      case "rejection":
        fvalue = 2;
        break;
      case "authorization":
        fvalue = 3;
        break;
      case "vobs":
        fvalue = 4;
        break;
      case "eobs":
        fvalue = 5;
        break;
      case "aras":
        fvalue = 6;
        break;
      case "ars":
        fvalue = 7;
        break;
    }
    const willNotVerified=false;

    // const willNotVerified = await swal({
    //   title: "Are you sure?",
    //   text: "Are you sure that you want to verify this record?",
    //   icon: "warning",
    //   dangerMode: true,
    //   buttons: ["Confirm", "Cancel"],
    // });

    if (!willNotVerified) {
      let obj={
        pidies:stateNu.pidies,
        uidies:stateNu.uidies,
        tuidies:stateNu.tuidies,
        formtype:state.formtype,
        sdate:state.sdate,
        edate:state.edate,
        verify:1,
        userid:onLoginSignOff.userid,
        fvalue: fvalue


      }
      // const formDataForSaveApi = new FormData();
      // formDataForSaveApi.append("pidies", stateNu.pidies);
      // formDataForSaveApi.append("uidies", stateNu.uidies);
      // formDataForSaveApi.append("tuidies", stateNu.tuidies);
      // formDataForSaveApi.append("formtype", state.formtype);
      // formDataForSaveApi.append("sdate", state.sdate);
      // formDataForSaveApi.append("edate", state.edate);
      // formDataForSaveApi.append("verify", 1);
      // formDataForSaveApi.append("userid", onLoginSignOff.userid);
      // formDataForSaveApi.append("fvalue", fvalue);
      //call Api
   //   let resp = await getTlRecordsApi(formDataForSaveApi);
     let resp=await onVerifyAuditTable(obj)
      if (resp.data) {
      //  swal("Verified!", "Record has been Verified!", "success");
      }
    }
  };

  return (
    <>
      {isShowModal ? (
        <EditTable
          subTableValues={subTableValues}
          open={setIsShowModal}
          handleClose={handleClose}
        />
      ) : null}
      <Table
        virtualized
        shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
        height={300}
        data={DataForGrid}
        rowKey={rowKey}
        expandedRowKeys={expandedRowKeys}
        onRowClick={(data) => {
          console.log(data);
        }}
        autoHeight={true}
        cellBordered={true}
        headerHeight={37}
        rowHeight={30}
        loading={loader}
        renderRowExpanded={renderRowExpanded}
      >
       

        <Column flexGrow={1.5} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Tasks
          </HeaderCell>
          <Cell dataKey={"CatValue"} />
        </Column>

        <Column flexGrow={1.5} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Received
          </HeaderCell>
          <Cell dataKey="recievedval" />
        </Column>

        <Column flexGrow={1.9} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Available to work
          </HeaderCell>
          <Cell dataKey="available" />
        </Column>

        <Column flexGrow={1.5} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Worked
          </HeaderCell>
          <Cell dataKey="workedval" />
        </Column>
        <Column flexGrow={1.9} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Pending to work
          </HeaderCell>
          <Cell dataKey="pendingval" />
        </Column>
        <Column flexGrow={1.1} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Edit
          </HeaderCell>
          <ExpandCell
            dataKey="available"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Column>
        <Column flexGrow={1.5} align="center">
          <HeaderCell
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
          >
            Verify
          </HeaderCell>
          {/* <Cell dataKey="pendingval" /> */}
          <VerifyCell onChangeVerify={onChangeVerify} />
        </Column>
       
        {/* <Column width={80}>
        <HeaderCell
          style={{
            backgroundColor: "#0e7490",
            color: "white",
            fontSize: "0.805rem",
          }}
        >
          Edit
        </HeaderCell>
        
        <EditCell />
      </Column> */}
      </Table>
     
    </>
  );
};
export default AuditTable;
