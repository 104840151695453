import { Nav } from 'rsuite';
import React from 'react';
import HomeIcon from '@rsuite/icons/legacy/Home';
import DetailIcon from '@rsuite/icons/Detail'
import CouponIcon from '@rsuite/icons/Coupon';
import ChangeListIcon from '@rsuite/icons/ChangeList';
import IdInfoIcon from '@rsuite/icons/IdInfo';
import { useState } from 'react';
import FirefoxIcon from '@rsuite/icons/legacy/Firefox';
import DropboxIcon from '@rsuite/icons/legacy/Dropbox';
import DocPassIcon from '@rsuite/icons/DocPass';
import EllipsisHIcon from '@rsuite/icons/legacy/EllipsisH';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import TextImageIcon from '@rsuite/icons/TextImage';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';

const navItems = [
  { eventKey: 'ClaimLevel', label: 'Claim Level Audit', icon: <IdInfoIcon /> },
  { eventKey: 'OverAll', label: 'OverAll Audit', icon: <DocPassIcon /> },
 
 
 
  

];



const Navbar = ({  active, onSelect, handleFormType, ...props }) => {
  const isActive = (eventKey) => active === eventKey;
  const activeStyle = { backgroundColor: "#0e7490", color: "white" };
  const subactiveStyle = { backgroundColor: "#0e7490", color: "white" };
  console.log('Active,',active)

  return (
    <>
    <div className="nav-container rs-nav-item rs-nav-item-active">
    <Nav {...props} activeKey={active} onSelect={onSelect}  appearance="subtle">
        {navItems.map(({ eventKey, label, icon }) => (
          <Nav.Item
            key={eventKey}
            eventKey={eventKey}
            icon={icon}
            style={isActive(eventKey) ? activeStyle : {}}
            onSelect={handleFormType}
          >
            <span style={{fontWeight:'normal'}}>{label}</span>
          </Nav.Item>
        ))}
        
       
              <span
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                 
                }}
              >
                
               
              </span>
            
      </Nav>
      <div className="col-lg-12 mb-2 text-center">
              <h4
                class="inputLabel "
                style={{
                  color: "black",
                  borderBottom: "2px solid rgb(14, 116, 144)",
                 
                }}
              >
                
               
              </h4>
            </div>

    </div>
      
    
      
    </>
  );
};

const TaskListTabComp = ({ sethelpingObj }) => {
  const [active, setActive] = useState('ClaimLevel');
  const [checkedType, setCheckedType] = useState('ClaimLevel');
  console.log(active,'moizbug')

  const handleFormType = (event,arg) => {
    sethelpingObj((prev)=>({...prev,tabName:event}))
   
  
  
   
   
   
  };

  return (
    <>
     <div class="">
        <div className="">
          <div class=" row mt-1">
            <div
              class=""
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="sticky-container">
              <Navbar
       // payerType={payerType}
        appearance="tabs"
        active={active}
        onSelect={setActive}
        handleFormType={handleFormType}
      />
              </div>
              </div></div></div></div>
      
    
    </>
  );
};

export default TaskListTabComp;




